import { Component, forwardRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldErrorsComponent } from '../field-errors/field-errors.component';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SamiIconComponent } from '../../icon/icon.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-password-input',
  standalone: true,
  imports: [CommonModule, FieldErrorsComponent, SamiIconComponent],
  templateUrl: './password-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SamiPasswordInputComponent), multi: true,
    }
  ],
})
export class SamiPasswordInputComponent implements ControlValueAccessor {

  @Input() parentForm!: FormGroup;
  @Input() fieldName!: string;
  @Input() label!: string;

  public isDisabled = false;
  public passwordFieldType = 'password';
  public value = '';
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public changed: (value: string) => void = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public touched: () => void = () => {};

  get formField (): FormControl {
    return this.parentForm.get(this.fieldName) as FormControl;
  }


  writeValue(obj: any): void {
    this.value = obj;
    throw new Error('Method not implemented.');
  }

  public onChange( event: Event ): void {
    const value: string = (event.target as HTMLInputElement).value;
    this.changed( value );
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
    throw new Error('Method not implemented.');
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
    throw new Error('Method not implemented.');
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    throw new Error('Method not implemented.');
  }

  public togglePasswordVisible (): void {
		this.passwordFieldType =
			this.passwordFieldType === 'text'
				? 'password'
				: 'text';
	}
}
