import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChildren,
} from '@angular/core';

import Player from '@vimeo/player';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { increment, serverTimestamp } from '@angular/fire/firestore';

import {
  BehaviorSubject,
  interval,
  lastValueFrom,
  of,
  startWith,
  Subscription,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { AuthService, LibraryService, VideoService } from '@sami/features';

@Component({
  selector: 'app-vimeo-player',
  templateUrl: './vimeo-player.component.html',
})
export class VimeoPlayerComponent
  implements AfterViewInit, OnChanges, OnDestroy {
  @Input() videoId = 123456;
  @Input() playVideoAutomatically: boolean | null = false;
  @Input() videoCtrl$?: BehaviorSubject<string | null>;
  @Output() videoEnd: EventEmitter<any> = new EventEmitter();
  @Output() videoPlay: EventEmitter<any> = new EventEmitter();
  @Output() videoPause: EventEmitter<any> = new EventEmitter();
  @Output() currentTime: EventEmitter<any> = new EventEmitter();
  @ViewChildren('playerContainer') playerContainer: any;

  player: Player | undefined;

  viewPctThreshold = 0.2;
  watchState$ = new BehaviorSubject<boolean>(false);
  clipDuration!: number | undefined;
  clip!: any;
  watchTime = 0;
  watchPct = 0;
  flaggedAsViewed = false;
  flaggedAsViewedForHistory = false;

  subscriptions: Subscription[] = [];

  constructor(
    private afs: AngularFirestore,
    private library: LibraryService,
    private auth: AuthService,
    private video: VideoService,
  ) {
    this.subscriptions.push(
      this.watchState$
        .pipe(
          switchMap((state: boolean) =>
            state ? interval(1000).pipe(startWith(1)) : of(null)
          ),
          tap((tick: number | null) => tick && this.watchTime++)
        )
        .subscribe(() => {
          if (this.clipDuration) {
            this.watchPct = this.watchTime / this.clipDuration;
            if (
              this.watchPct >= this.viewPctThreshold &&
              !this.flaggedAsViewed
            ) {
              this.flaggedAsViewed = true;
              this.flagClipAsViewed();
            }
          }
        })
    );
  }

  ngAfterViewInit() {
    this.player = new Player(this.playerContainer.first.nativeElement, {
      id: this.videoId,
      color: '001427',
      title: false,
      portrait: false,
      byline: false,
    });
    this.player.setVolume(this.video.volume$.getValue());

    this.loadVimeoVideo(this.videoId);

    this.player.on('play', (data: any) => {
      this.clipDuration = data.duration;
      this.videoPlay.emit(data);
      this.watchState$.next(true);
    });

    this.player.on('pause', (data: any) => {
      this.saveClipToUsersHistory();
      this.videoPause.emit(data);
      this.watchState$.next(false);
    });

    this.player.on('ended', (data: any) => {
      this.saveClipToUsersHistory();
      this.videoEnd.emit(data);
      this.watchState$.next(false);
    });

    this.player.on('timeupdate', (data: any) => {
      this.currentTime.emit(data);
    });

    this.player.on('volumechange', (data: any) => {
      this.video.volume$.next(data.volume);
    });

    if (this.videoCtrl$) {
      this.subscriptions.push(
        this.videoCtrl$.subscribe((action: string | null) => {
          if (action === 'pause') {
            this.player?.pause();
          } else if (action === 'play') {
            this.player?.play();
          }
        })
      );
    }
  }

  async ngOnChanges(): Promise<void> {
    // save current video progress, before next one is loaded (this.clip should only be overwritten, once this finishes!)
    await this.saveClipToUsersHistory();

    this.flaggedAsViewed = false;
    this.flaggedAsViewedForHistory = false;
    this.clipDuration = undefined;
    this.watchTime = 0;
    this.watchPct = 0;
    this.clip = undefined;

    if (this.player) {
      this.loadVimeoVideo(this.videoId);
      this.player.setVolume(this.video.volume$.getValue());
    }
  }

  ngOnDestroy(): void {
    this.saveClipToUsersHistory();
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  async loadVimeoVideo(id: number) {
    if (this.player) {
      await this.player.loadVideo(id);
      if (this.video.autoplay$.getValue()) {
        await this.player.play().catch(() => null);
        this.clip = await lastValueFrom(
          this.library.getClipByVideoId$('' + id).pipe(take(1))
        );
      }
    }
  }

  async saveClipToUsersHistory(): Promise<void> {
    try {
      const user = await lastValueFrom(this.auth.authUser$.pipe(take(1)));
      const uid = user?.uid;

      if (!uid || !this.clipDuration) {
        return;
      }

      await this.afs
        .doc(`users/${uid}/systemsClipsWatchHistory/${this.clip?._id || this.videoId}`)
        .set(
          {
            id: this.clip?._id || this.videoId,
            title: this.clip?.title || null,
            lastWatched: serverTimestamp(),
            userId: uid,
            thumbnails: this.clip?.thumbnails || null, // does not exists on videoLibraryClips
            style: this.clip?.style || null, // does not exists on videoLibraryClips
            videoId: this.videoId,
            duration: this.clipDuration,
            percent: this.watchPct,
            ...(!this.flaggedAsViewedForHistory && { views: increment(1) }),
          },
          { merge: true }
        );

      this.flaggedAsViewedForHistory = true;
    } catch (error) {
      console.error(error);
    }
  }

  async flagClipAsViewed(): Promise<void> {
    try {
      const clip = await lastValueFrom(
        this.library.getClipByVideoId$('' + this.videoId).pipe(take(1))
      );
      if (clip) {
        await this.afs
          .doc(`systemsClips/${clip['_id']}`)
          .update({ views: increment(1) });
      }
    } catch (error) {
      console.error(error);
    }
  }

  jumpToPositionInSeconds(seconds: number) {
    if (this.player) {
      this.player.setCurrentTime(seconds);
    }
  }
}
