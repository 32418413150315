<div class="flex flex-col justify-start items-center space-y-2" *ngIf="{ mobile: ui.isMobileLayout$ | async} as vm">
  <button
    (click)="openPaywallDialog(vm.mobile || false)"
    class="px-7 py-3.5 w-full md:w-auto font-sans bg-statusColors-red hover:bg-opacity-80 leading-normal text-statusColors-lightRed font-semibold flex text-base flex-row justify-center items-center space-x-2"
  >
    <svg class="w-4 h-4" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.5 22C6.5 20.8954 7.39543 20 8.5 20H40.5C41.6046 20 42.5 20.8954 42.5 22V46C42.5 47.1046 41.6046 48 40.5 48H8.5C7.39543 48 6.5 47.1046 6.5 46V22ZM10.5 24V44H38.5V24H10.5Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M24.5 4C21.8478 4 19.3043 5.05357 17.4289 6.92893C15.5536 8.8043 14.5 11.3478 14.5 14V22H10.5V14C10.5 10.287 11.975 6.72602 14.6005 4.10051C17.226 1.47499 20.787 0 24.5 0C28.213 0 31.7739 1.47497 34.3994 4.10046C34.3993 4.10044 34.3994 4.10048 34.3994 4.10046M34.3994 4.10046C37.0251 6.726 38.5 10.287 38.5 14V22H34.5V14C34.5 11.3478 33.4465 8.80431 31.571 6.92898C29.6957 5.05363 27.1522 4 24.5 4" fill="currentColor"/>
      <path d="M24.5 38C26.7092 38 28.5 36.2092 28.5 34C28.5 31.7908 26.7092 30 24.5 30C22.2908 30 20.5 31.7908 20.5 34C20.5 36.2092 22.2908 38 24.5 38Z" fill="currentColor"/>
    </svg>
    <span> Get access </span>
  </button>
</div>