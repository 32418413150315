import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
import { SamiCenter } from 'interfaces';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    EditorModule
  ],
  templateUrl: './about.component.html',
})
export class AboutComponent implements OnChanges {
  @Input() center: SamiCenter | undefined;
  isBusy$ = new BehaviorSubject<boolean>(false);

  form = this.fb.group({
    description: ['', Validators.required],
  });

  constructor(private fb: FormBuilder, private afs: AngularFirestore) {}

  ngOnChanges(): void {
    if (this.center) {
      this.form.patchValue({
        description: this.center.description,
      });
    }
  }

  async submit() {
    if (this.form.valid) {
      this.isBusy$.next(true);
      try {
        await this.afs.doc(`centers/${this.center?.id}`).update({
          description: this.form.value.description,
        });

        this.isBusy$.next(false);
      } catch (error) {
        console.error(error);
        this.isBusy$.next(false);
      }
    }
  }
}
