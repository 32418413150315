<div class="flex flex-col justify-start space-y-4 @container" *ngIf="user">
  <span class="font-sans text-base font-semibold text-richBlack">
    Profile image
  </span>

  <div [ngClass]="displayHint ? '@xl:grid-cols-2 @xl:gap-8' : ''" class="grid grid-cols-1 gap-4 font-sans" *ngIf="user">
    <div class="hidden lg:flex flex-col sm:flex-row justify-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-4">
      <div class="relative rounded-full overflow-hidden block shrink-0">
        <ng-container
          *ngIf="profileImageRef || user.photoURL as image; else noPhoto"
        >
          <ng-container *ngIf="image | stor | async as img">
            <img
              class="relative w-32 h-32 rounded-full object-cover"
              [src]="img"
              [alt]="user.displayName"
            />
          </ng-container>
        </ng-container>
        <ng-template #noPhoto>
          <span
            class="inline-flex items-center justify-center w-32 h-32 rounded-full bg-gray-400"
            *ngIf="user && user.displayName"
          >
            <span class="font-medium leading-none text-white">{{
              user.displayName ? (user.displayName | slice: 0:1) : ''
            }}</span>
          </span>
        </ng-template>
        <label
          for="user-photo"
          class="rounded-full absolute inset-0 w-32 h-32 bg-black bg-opacity-75 flex items-center justify-center text-sm font-medium text-white opacity-0 hover:opacity-100 focus-within:opacity-100"
        >
          <span (click)="file2.click()">Change</span>
          <span class="sr-only"> user photo</span>
          <input
            (change)="onUploadImage($event)"
            #file2
            type="file"
            id="user-photo"
            name="user-photo"
            class="absolute inset-0 w-40 h-40 opacity-0 cursor-pointer border-gray-300 rounded-full"
          />
        </label>
      </div>
      <span class="text-xs text-cadetGrey-400 font-body">
        Recommended image size: 1080 x 1080px |  Maximum file size: 10MB  |  Supported image files: JPEG or PNG
      </span>
    </div>
    <div class="lg:hidden">
      <div class="flex items-center">
        <div
          class="flex-shrink-0 inline-block rounded-full overflow-hidden h-12 w-12"
          aria-hidden="true"
        >
          <ng-container
            *ngIf="
              user.photoURL &&
                (user.photoURL | stor | async) as userURL;
              else noPhotoSmall
            "
          >
            <img
              class="mx-auto h-full w-full rounded-full object-cover"
              [src]="userURL"
              alt=""
            />
          </ng-container>
          <ng-template #noPhotoSmall>
            <span
              class="inline-flex items-center justify-center h-full w-full rounded-full bg-gray-400"
            >
              <span class="font-medium leading-none text-white">{{
                user.displayName
                  ? (user.displayName | slice : 0 : 1)
                  : ''
              }}</span>
            </span>
          </ng-template>
        </div>
        <div class="ml-5 rounded-none">
          <div
            class="group relative border border-gray-300 rounded-none py-2 px-3 flex items-center justify-center hover:bg-gray-50 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-light-gray-500"
          >
            <label
              for="user_photo"
              class="relative text-sm leading-4 font-medium text-gray-700 pointer-events-none"
            >
              <span (click)="file1.click()">Change</span>
              <span class="sr-only"> user photo</span>
            </label>
            <input
              (change)="onUploadImage($event)"
              #file1
              id="user_photo"
              name="user_photo"
              type="file"
              class="absolute w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-none"
            />
          </div>
        </div>
      </div>
    </div>
    <p class="text-sm font-body text-cadetGrey-600" *ngIf="displayHint">
      Select and upload your personal profile image. This image will be shown next to your account and may me seen by others. If you are an instructor, this picture will also be used for your instructor profile.
    </p>
  </div>
</div>
