import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage, GetDownloadURLPipeModule } from '@angular/fire/compat/storage';
import { arrayRemove, arrayUnion } from '@angular/fire/firestore';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { BehaviorSubject, finalize, Observable } from 'rxjs';
import { ImagePipe } from '../../../pipes/transformImage.pipe';

@Component({
  selector: 'app-upload-gallery',
  standalone: true,
  imports: [
    CommonModule,
    NgxDropzoneModule,
    GetDownloadURLPipeModule,
    ImagePipe
  ],
  templateUrl: './upload-gallery.component.html',
})
export class UploadGalleryComponent implements OnInit {
  @Input() center: any | undefined = undefined;
  uploadPercent: Observable<number> | any;
  isBusy$ = new BehaviorSubject<boolean>(false);
  files: File[] = [];
  constructor(
    private storage: AngularFireStorage,
    private afs: AngularFirestore
  ) {}

  ngOnInit(): void {}

  uploadFiles() {
    this.isBusy$.next(true);
    if (this.files && this.files.length > 0) {
      let i = this.files.length;
      while (i--) {
        const file = this.files[i];
        const filePath = `centers/${this.center.id}/gallery/${file.name}`;
        const task = this.storage.upload(filePath, file);
        this.uploadPercent = task.percentageChanges();
        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              this.addImageToGallery(filePath);
              this.files.splice(i, 1);
              if (this.files.length === 0) {
                this.isBusy$.next(false);
                this.uploadPercent = new Observable();
              }
            })
          )
          .subscribe();
      }
    }
  }
  onSelect(event: any) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  async addImageToGallery(path: string) {
    if (this.center && this.center.id) {
      this.afs.doc(`centers/${this.center.id}`).update({
        galleryImagePaths: arrayUnion(path),
      });
    }
  }

  async removeImage(path: string) {
    if (this.center && this.center.id) {
      this.afs.doc(`centers/${this.center.id}`).update({
        galleryImagePaths: arrayRemove(path),
      });
    }
  }
}
