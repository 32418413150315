<div class="px-5 bg-white rounded-lg border-2 border-defensiveGreen-500 justify-start items-center gap-5 inline-flex">
  <div class="self-stretch py-4 flex-col justify-center items-center flex">
    <div class="justify-start items-start gap-2.5 inline-flex">
      <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.7027 51.4054C40.0695 51.4054 50.9054 40.5695 50.9054 27.2027C50.9054 13.8359 40.0695 3 26.7027 3C13.3359 3 2.5 13.8359 2.5 27.2027C2.5 40.5695 13.3359 51.4054 26.7027 51.4054Z" fill="#0E1625" stroke="#7B7D60" stroke-width="5" stroke-miterlimit="10"/>
        <path d="M9.73437 29.5C10.8389 29.5 11.7344 28.6046 11.7344 27.5C11.7344 26.3954 10.8389 25.5 9.73437 25.5C8.62981 25.5 7.73438 26.3954 7.73438 27.5C7.73438 28.6046 8.62981 29.5 9.73437 29.5Z" fill="white"/>
        <path d="M44.2617 29.5C45.3663 29.5 46.2617 28.6046 46.2617 27.5C46.2617 26.3954 45.3663 25.5 44.2617 25.5C43.1571 25.5 42.2617 26.3954 42.2617 27.5C42.2617 28.6046 43.1571 29.5 44.2617 29.5Z" fill="white"/>
        <path d="M26.8986 37.8C23.2686 37.8 21.3186 35.85 21.3186 32.22V30.75H24.8586V32.28C24.8586 33.75 25.5186 34.47 26.8086 34.47C28.0986 34.47 28.7586 33.81 28.7586 32.1V30.99C28.7586 28.98 28.0686 28.23 26.2986 28.23H25.0386V24.9H26.3886C27.8586 24.9 28.6386 24.27 28.6386 22.56V21.6C28.6386 20.22 28.0086 19.53 26.7786 19.53C25.5786 19.53 24.9786 20.19 24.9786 21.48V22.59H21.4386V21.78C21.4386 18.18 23.3586 16.2 26.9286 16.2C30.4986 16.2 32.3886 18.15 32.3886 21.6V22.14C32.3886 24.3 31.5186 25.71 29.7486 26.34V26.4C31.5786 27.03 32.4786 28.59 32.4786 31.02V32.19C32.4786 35.94 30.6186 37.8 26.8986 37.8Z" fill="#ECEFEF"/>
      </svg>
    </div>
  </div>
  <div class="grow shrink basis-0 py-4 space-y-4 flex-col justify-center items-start">
    <div class="justify-start items-center gap-3 inline-flex">
      <div class="text-slate-900 text-2xl font-extrabold font-sans leading-7">Krav Maga - Level 3</div>
      <div class="px-2.5 py-px bg-amber-100 rounded-xl justify-center items-center flex">
        <div class="text-center text-statusColors-darkYellow text-xs font-normal font-body uppercase leading-tight">NEXT LEVEL</div>
      </div>
    </div>
    <div class="self-stretch flex-col font-body justify-center items-start gap-1.5 flex">
      <div class="self-stretch justify-start items-start gap-2.5 inline-flex">
        <div class="py-0.5 justify-start items-start gap-2.5 flex">
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.88 12.18L12.52 6.54L11.4 5.42L6.88 9.94L4.6 7.66L3.48 8.78L6.88 12.18Z" fill="#135626"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16.5C6.89333 16.5 5.85333 16.29 4.88 15.87C3.90667 15.45 3.06 14.88 2.34 14.16C1.62 13.44 1.05 12.5933 0.63 11.62C0.21 10.6467 0 9.60667 0 8.5C0 7.39333 0.21 6.35333 0.63 5.38C1.05 4.40667 1.62 3.56 2.34 2.84C3.06 2.12 3.90667 1.55 4.88 1.13C5.85333 0.71 6.89333 0.5 8 0.5C9.10667 0.5 10.1467 0.71 11.12 1.13C12.0933 1.55 12.94 2.12 13.66 2.84C14.38 3.56 14.95 4.40667 15.37 5.38C15.79 6.35333 16 7.39333 16 8.5C16 9.60667 15.79 10.6467 15.37 11.62C14.95 12.5933 14.38 13.44 13.66 14.16C12.94 14.88 12.0933 15.45 11.12 15.87C10.1467 16.29 9.10667 16.5 8 16.5ZM12.52 6.54L6.88 12.18L3.48 8.78L4.6 7.66L6.88 9.94L11.4 5.42L12.52 6.54Z" fill="#D7F6E0"/>
          </svg>
        </div>
        <div class="grow shrink basis-0 text-slate-900 text-sm font-normal leading-tight">Waited 6 months since last exam (2 months to go)</div>
      </div>
      <div class="self-stretch justify-start items-start gap-2.5 inline-flex">
        <div class="py-0.5 justify-start items-start gap-2.5 flex">
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 16.5C6.89333 16.5 5.85333 16.29 4.88 15.87C3.90667 15.45 3.06 14.88 2.34 14.16C1.62 13.44 1.05 12.5933 0.63 11.62C0.21 10.6467 0 9.60667 0 8.5C0 7.39333 0.21 6.35333 0.63 5.38C1.05 4.40667 1.62 3.56 2.34 2.84C3.06 2.12 3.90667 1.55 4.88 1.13C5.85333 0.71 6.89333 0.5 8 0.5C9.10667 0.5 10.1467 0.71 11.12 1.13C12.0933 1.55 12.94 2.12 13.66 2.84C14.38 3.56 14.95 4.40667 15.37 5.38C15.79 6.35333 16 7.39333 16 8.5C16 9.60667 15.79 10.6467 15.37 11.62C14.95 12.5933 14.38 13.44 13.66 14.16C12.94 14.88 12.0933 15.45 11.12 15.87C10.1467 16.29 9.10667 16.5 8 16.5ZM8 14.9C9.78667 14.9 11.3 14.28 12.54 13.04C13.78 11.8 14.4 10.2867 14.4 8.5C14.4 6.71333 13.78 5.2 12.54 3.96C11.3 2.72 9.78667 2.1 8 2.1C6.21333 2.1 4.7 2.72 3.46 3.96C2.22 5.2 1.6 6.71333 1.6 8.5C1.6 10.2867 2.22 11.8 3.46 13.04C4.7 14.28 6.21333 14.9 8 14.9Z" fill="#D8E0E4"/>
          </svg>
        </div>
        <div class="grow shrink basis-0 text-black text-sm font-normal leading-tight">Completed course</div>
      </div>
      <div class="self-stretch justify-start items-start gap-2.5 inline-flex">
        <div class="py-0.5 justify-start items-start gap-2.5 flex">
          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 16.5C6.89333 16.5 5.85333 16.29 4.88 15.87C3.90667 15.45 3.06 14.88 2.34 14.16C1.62 13.44 1.05 12.5933 0.63 11.62C0.21 10.6467 0 9.60667 0 8.5C0 7.39333 0.21 6.35333 0.63 5.38C1.05 4.40667 1.62 3.56 2.34 2.84C3.06 2.12 3.90667 1.55 4.88 1.13C5.85333 0.71 6.89333 0.5 8 0.5C9.10667 0.5 10.1467 0.71 11.12 1.13C12.0933 1.55 12.94 2.12 13.66 2.84C14.38 3.56 14.95 4.40667 15.37 5.38C15.79 6.35333 16 7.39333 16 8.5C16 9.60667 15.79 10.6467 15.37 11.62C14.95 12.5933 14.38 13.44 13.66 14.16C12.94 14.88 12.0933 15.45 11.12 15.87C10.1467 16.29 9.10667 16.5 8 16.5ZM8 14.9C9.78667 14.9 11.3 14.28 12.54 13.04C13.78 11.8 14.4 10.2867 14.4 8.5C14.4 6.71333 13.78 5.2 12.54 3.96C11.3 2.72 9.78667 2.1 8 2.1C6.21333 2.1 4.7 2.72 3.46 3.96C2.22 5.2 1.6 6.71333 1.6 8.5C1.6 10.2867 2.22 11.8 3.46 13.04C4.7 14.28 6.21333 14.9 8 14.9Z" fill="#D8E0E4"/>
          </svg>
        </div>
        <div class="grow shrink basis-0 text-black text-sm font-normal leading-tight">Passed exam</div>
      </div>
    </div>
  </div>
</div>