import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-stats-card',
  templateUrl: './stats-card.component.html',
  standalone: true,
  imports: [ CommonModule ]
})
export class StatsCardComponent {
  @Input() number = 0;
  @Input() description: string | undefined;
  @Input() color: 'text-statusColors-green' | ' text-statusColors-red' | undefined = undefined;
}
