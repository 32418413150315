import { SamiStyleModel } from './sami-style.model';

export interface SamiSubscription {
  [key: string]: any;
  id?: string;
  subscriptionId?: string;
  subscriptionPlan?: string;
  cancel_at_period_end?: boolean;
  productId?: string;
  userId?: string;
  planId?: string; // TODO: deprecated
  plan?: string;
  planDuration?: 'year' | 'month';
  styles?: SamiStyleModel[];
  addOns?: any[]; // TODO: deprecated
  nextBillAmount?: number; // in cents
  nextBillingDate?: Date | any;
  cancelReason?: string;
  invoiceUrls?: {
    date: string;
    url: string;
  }[];
  stripePlan?: {
    active: boolean;
    aggregated_usage: any;
    amount: number;
    amount_decimal: string;
    billing_schema: string;
    created: number;
    currency: string;
    id: string;
    interval: string;
    interval_count: number;
    livemode: boolean;
    metadata: any;
    nickname: string | null;
    object: string;
    product: string;
    tiers_mode: string | null;
    transform_usage: string | null;
    trail_period_days: any;
    usage_type: string;
  };
  status?: 'pending' | 'active' | 'past_due' | 'canceled' | 'trialing';
  expiresAt?: Date | any;
}

export interface SamiSubscriptionDigest {
  styles?: any[];
  hasSubscription?: {
    instructor?: boolean;
    plus?: boolean;
    basic?: boolean;
  };
  hadSubscription?: {
    instructor?: boolean;
    plus?: boolean;
    basic?: boolean;
  };
  subscriptionStatus?: {
    instructor?: string | null;
    plus?: string | null;
    basic?: string | null;
  };
}
