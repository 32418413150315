import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { SamiStyleModel, SamiUser } from 'interfaces';
import { Observable, map, of } from 'rxjs';
import { SamiCombatSystemTagComponent } from '../../combat-system-tag/combat-system-tag.component';
import { ManageCollectionButtonModule } from '../../manage-collection-button/manage-collection-button.module';
import { MembershipBadgeComponent } from '../../badges/membership-badge/membership-badge.component';
import { PurchasedBadgeComponent } from '../../badges/purchased-badge/purchased-badge.component';
import { UiService } from '@sami/features';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-systems-library-clip-card',
  standalone: true,
  imports: [CommonModule, ManageCollectionButtonModule, SamiCombatSystemTagComponent, MembershipBadgeComponent, PurchasedBadgeComponent],
  templateUrl: './systems-library-clip-card.component.html'
})
export class SystemsLibraryClipCardComponent implements OnInit {
  @Input() user: SamiUser | undefined;
  @Input() layout: 'auto' | 'column' = 'auto';
  @Input() purchases: any[] | undefined;
  @Input() loading: boolean | undefined;
  @Input() id: string | undefined;
  @Input() hasAccess = false;
  @Input() status: 'new' | 'updated' | undefined;
  @Input() showManageCollectionButton = false;
  @Input() cardBackground: 'white' | 'culturedWhite' = 'white';

  clip$ = new Observable<any>();
  history$ = new Observable<any>();

  @Output() clickEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(private afs: AngularFirestore, public ui: UiService) {}

  ngOnInit(): void {
    this.clip$ = this.afs.doc(`systemsClips/${this.id}`).valueChanges({ idField: 'id' }).pipe(
      map((clip: any) => {
        // Compare user and clip accessScopes
        const hasAccess = this.user && this.user.accessScope && clip.accessScope
          ? this.user.accessScope.some(scope => clip.accessScope.includes(scope))
          : false;

        return {
          ...clip,
          hasPurchased: this.purchases?.find((p: any) => p.docId === clip.id),
          hasAccess: this.hasAccess ? this.hasAccess : (clip.isFree ? clip.isFree : hasAccess),
          showLock: this.getStatus(clip, this.hasAccess ? this.hasAccess : (clip.isFree ? clip.isFree : hasAccess)),
          durationInSeconds: clip.muxVideoElement ? clip.muxVideoElement?.asset?.duration : clip.duration
        }
      }),
    );

    this.history$ = this.user?.uid
      ? this.afs
        .doc<any>(`users/${this.user.uid}/systemsClipsWatchHistory/${this.id}`)
        .valueChanges()
      : of(null);
  }

  getStatus(clip: any, hasAccess: boolean): boolean {
    const defaultCombatSystems = ['knife', 'stick', 'axe', 'kravMaga', 'panantukan'];

    // if the clip is free, then do not show the lock
    if (clip.isFree) {
      return false;
    }

    // if the user has access to the clip, then do not show the lock
    if (hasAccess) {
      return false;
    }

    // if the user has purchased the clip, then do not show the lock
    if (this.purchases?.find((p: any) => p.docId === clip.id)) {
      return false;
    }

    // if the clip is a default combat system, then only show the lock if the level is above 6 and the user does not have access to the level
    const userLevel = this.getUserLevel(clip.style?.identifier);
    if (defaultCombatSystems.includes(clip.style?.identifier)) {
      return clip.level > 6 && userLevel < clip.level ? true : false;
    }

    return true;
  }

  getUserLevel(styleIdentifier: string): number {
    // Look for any scope that starts with the given style identifier
    const matchingScope = this.user?.accessScope?.find(
      (scope: string) => scope.startsWith(`${styleIdentifier}_`)
    );

    // If no matching scope is found, return 0
    if (!matchingScope) {
      return 0;
    }

    // Extract the level, which is always the last part of the scope (e.g., kravMaga_i_3 -> level 3)
    const parts = matchingScope.split('_');
    const level = parseInt(parts[parts.length - 1], 10);

    // Return the extracted level or 0 if parsing fails
    return isNaN(level) ? 0 : level;
  }

}
