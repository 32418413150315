<ng-container *ngIf="displayCategory">
  <span>
    {{ displayCategoryDescription}}
  </span>
</ng-container>
<ng-container *ngIf="displaySubcategory">
  <ng-container *ngIf="displayCategory">
    /
  </ng-container>
  <span>
    {{ displaySubcategoryDescription }}
  </span>
</ng-container>