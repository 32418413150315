import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrentTrackCardComponent } from './current-track-card.component';
import { RouterModule } from '@angular/router';
import { GetDownloadURLPipeModule } from '@angular/fire/compat/storage';
import { ImagePipe } from '../../pipes/transformImage.pipe';

@NgModule({
  declarations: [CurrentTrackCardComponent],
  imports: [
    CommonModule,
    RouterModule,
    ImagePipe,
    GetDownloadURLPipeModule,
  ],
  exports: [CurrentTrackCardComponent],
})
export class CurrentTrackCardModule {}
