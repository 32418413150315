<section *ngIf="seminar" class="py-6 px-5 flex flex-col justify-start space-y-4 relative" [ngClass]="background === 'white' ? 'bg-white' : 'bg-culturedWhite'">
  <div class="flex flex-row justify-between items-center">
    <h3 class="text-xl font-semibold text-richBlack">{{ seminar.liveClasses && seminar.liveClasses.length > 1 ? 'Live Classes' : 'Live Class' }}</h3>
  </div>

  <p class="text-sm font-body text-richBlack">
    {{ seminar.liveClasses && seminar.liveClasses.length > 1 ? 'This course includes online live classes. Below is the list of live classes, which are included in this course.' : 'This event includes one live class where our instructors can give you feedback.' }}
  </p>

  <div class="flex flex-col justify-start space-y-4" *ngFor="let class of seminar.liveClasses">
    <sami-class-content [class]="class" [seminar]="seminar"></sami-class-content>
  </div>
</section>