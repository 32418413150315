<div class="flex flex-col justify-start space-y-2 items-start">
  <ngx-dropzone
    [multiple]="allowMultiple"
    (change)="onSelect($event)"
    class="w-full"
  >
    <ngx-dropzone-label>Drop it, baby!</ngx-dropzone-label>
    <ngx-dropzone-preview
      *ngFor="let f of files"
      [removable]="true"
      (removed)="onRemove(f)"
    >
      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-preview>
  </ngx-dropzone>

  <button type="button" (click)="uploadFiles()" mat-flat-button color="primary">
    <ng-container *ngIf="uploadPercent | async as pct; else notUploading">
      <span>Uploading ({{ pct }}%)</span>
    </ng-container>
    <ng-template #notUploading>
      <span>Upload</span>
    </ng-template>
  </button>
</div>
