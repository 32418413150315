import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { SamiPublicProfile } from 'interfaces';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CombatSystemSelectionComponent } from './combat-system-selection/combat-system-selection.component';

@Component({
  selector: 'app-combat-system',
  standalone: true,
  imports: [
    CommonModule,
    CombatSystemSelectionComponent
  ],
  templateUrl: './combat-system.component.html',
})
export class CombatSystemComponent implements OnInit {
  @Input() center: any | undefined = undefined;
  isBusy$ = new BehaviorSubject<boolean>(false);
  centerInstructors$: Observable<SamiPublicProfile[] | null> = new Observable();

  constructor(private afs: AngularFirestore) {}

  ngOnInit() {
    if (this.center && this.center.id) {
      this.centerInstructors$ = this.center.id
        ? this.afs
            .collectionGroup<SamiPublicProfile[]>('publicProfiles', (ref) =>
              ref
                .where('center.id', '==', this.center.id)
                .where('isPublic', '==', true)
                .where('instructorRank', '>', 0)
                .orderBy('instructorRank', 'desc')
            )
            .valueChanges({ idField: 'id' })
        : of(null);
    }
  }
}
