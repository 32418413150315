<button (click)="generate()" class="flex font-body text-xs text-[#7047F9] flex-row justify-center items-center space-x-2">
  <svg *ngIf="!loading" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7787_67646)">
    <path d="M8.07586 4.2582C8.15893 3.52592 8.44309 2.83092 8.89686 2.2502C8.97528 2.14908 9.07579 2.06724 9.19071 2.01093C9.30562 1.95463 9.43189 1.92536 9.55986 1.92536C9.68783 1.92536 9.8141 1.95463 9.92901 2.01093C10.0439 2.06724 10.1444 2.14908 10.2229 2.2502C10.6589 2.8042 10.9519 3.4832 11.0449 4.2582C11.7772 4.34156 12.4723 4.62607 13.0529 5.0802C13.154 5.15862 13.2358 5.25914 13.2921 5.37405C13.3484 5.48896 13.3777 5.61524 13.3777 5.7432C13.3777 5.87117 13.3484 5.99744 13.2921 6.11236C13.2358 6.22727 13.154 6.32778 13.0529 6.4062C12.4721 6.85998 11.7771 7.14414 11.0449 7.2272C10.9616 7.9596 10.677 8.65462 10.2229 9.2352C10.1444 9.33633 10.0439 9.41817 9.92901 9.47448C9.8141 9.53078 9.68783 9.56005 9.55986 9.56005C9.43189 9.56005 9.30562 9.53078 9.19071 9.47448C9.07579 9.41817 8.97528 9.33633 8.89686 9.2352C8.44309 8.65449 8.15893 7.95949 8.07586 7.2272C7.34358 7.14414 6.64858 6.85998 6.06786 6.4062C5.96674 6.32778 5.88489 6.22727 5.82859 6.11236C5.77228 5.99744 5.74301 5.87117 5.74301 5.7432C5.74301 5.61524 5.77228 5.48896 5.82859 5.37405C5.88489 5.25914 5.96674 5.15862 6.06786 5.0802C6.64844 4.62603 7.34346 4.34151 8.07586 4.2582Z" fill="#7047F9"/>
    <path d="M2.51886 8.9562C2.58669 8.36069 2.81787 7.79553 3.18686 7.3232C3.2506 7.24098 3.33232 7.17443 3.42574 7.12864C3.51916 7.08286 3.62182 7.05906 3.72586 7.05906C3.8299 7.05906 3.93256 7.08286 4.02598 7.12864C4.11941 7.17443 4.20112 7.24098 4.26486 7.3232C4.61886 7.7732 4.85686 8.3252 4.93286 8.9562C5.56286 9.0312 6.11486 9.2702 6.56486 9.6242C6.64709 9.68795 6.71364 9.76966 6.75942 9.86308C6.8052 9.9565 6.82901 10.0592 6.82901 10.1632C6.82901 10.2672 6.8052 10.3699 6.75942 10.4633C6.71364 10.5567 6.64709 10.6385 6.56486 10.7022C6.09295 11.0712 5.52809 11.3025 4.93286 11.3702C4.86511 11.9654 4.6339 12.5303 4.26486 13.0022C4.20112 13.0844 4.11941 13.151 4.02598 13.1968C3.93256 13.2425 3.8299 13.2663 3.72586 13.2663C3.62182 13.2663 3.51916 13.2425 3.42574 13.1968C3.33232 13.151 3.2506 13.0844 3.18686 13.0022C2.81782 12.5303 2.58661 11.9654 2.51886 11.3702C1.92363 11.3025 1.35877 11.0712 0.886861 10.7022C0.804451 10.6385 0.737737 10.5567 0.691836 10.4632C0.645936 10.3697 0.62207 10.2669 0.62207 10.1627C0.62207 10.0585 0.645936 9.95573 0.691836 9.86221C0.737737 9.76869 0.804451 9.68693 0.886861 9.6232C1.35888 9.25452 1.92373 9.02366 2.51886 8.9562Z" fill="#7047F9"/>
    <path d="M1.99486 2.10708C2.04369 1.67605 2.21086 1.26695 2.47786 0.925077C2.52403 0.865489 2.58323 0.817257 2.65091 0.784076C2.7186 0.750894 2.79298 0.733643 2.86836 0.733643C2.94375 0.733643 3.01813 0.750894 3.08581 0.784076C3.1535 0.817257 3.21269 0.865489 3.25886 0.925077C3.51486 1.25108 3.68786 1.65108 3.74186 2.10708C4.17289 2.1559 4.58199 2.32307 4.92386 2.59008C5.17886 2.79008 5.17886 3.17008 4.92386 3.37008C4.58213 3.63749 4.17301 3.80501 3.74186 3.85408C3.69304 4.2851 3.52587 4.69421 3.25886 5.03608C3.21268 5.09548 3.15354 5.14355 3.08595 5.17661C3.01836 5.20968 2.94411 5.22687 2.86886 5.22687C2.79362 5.22687 2.71937 5.20968 2.65178 5.17661C2.58419 5.14355 2.52505 5.09548 2.47886 5.03608C2.2115 4.69432 2.04398 4.28521 1.99486 3.85408C1.56419 3.80508 1.15545 3.63791 0.813864 3.37108C0.754461 3.32489 0.706393 3.26575 0.673327 3.19816C0.640262 3.13057 0.623074 3.05632 0.623074 2.98108C0.623074 2.90583 0.640262 2.83158 0.673327 2.76399C0.706393 2.6964 0.754461 2.63726 0.813864 2.59108C1.15532 2.32384 1.56407 2.15632 1.99486 2.10708Z" fill="#7047F9"/>
    </g>
    <defs>
    <clipPath id="clip0_7787_67646">
    <rect width="14" height="14" fill="white"/>
    </clipPath>
    </defs>
  </svg>

  <svg *ngIf="loading" aria-hidden="true" class="w-3.5 h-3.5 text-gray-200 animate-spin fill-[#7047F9]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
  </svg>
  <span>
    Write with AI
  </span>
</button>