import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-stat',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './stat.component.html'
})
export class StatComponent {
  @Input() stats = 0;
  @Input() title: string | undefined;
}
