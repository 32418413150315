<ng-container *ngIf="element">
  <ng-container *ngIf="(hasAccess); else noAccess">
    <div
      class="flex flex-col relative justify-start items-center space-y-6"
    >
      <span class="font-sans font-semibold text-4xl text-white text-center" *ngIf="(secondsUntilOpening && secondsUntilOpening <= 0) && status === 'attendee'">
        Now live!
      </span>
      <svg *ngIf="secondsUntilOpening && secondsUntilOpening > 0" width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 24.5C0 11.2452 10.7452 0.5 24 0.5C37.2548 0.5 48 11.2452 48 24.5C48 37.7548 37.2548 48.5 24 48.5C10.7452 48.5 0 37.7548 0 24.5ZM24 4.5C12.9543 4.5 4 13.4543 4 24.5C4 35.5456 12.9543 44.5 24 44.5C35.0456 44.5 44 35.5456 44 24.5C44 13.4543 35.0456 4.5 24 4.5ZM26 22.5V12.2781H22V24.5C22 25.6046 22.8954 26.5 24 26.5H33.778V22.5H26Z" fill="#ECEFEF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 24.5C0 11.2452 10.7452 0.5 24 0.5C37.2548 0.5 48 11.2452 48 24.5C48 37.7548 37.2548 48.5 24 48.5C10.7452 48.5 0 37.7548 0 24.5ZM24 4.5C12.9543 4.5 4 13.4543 4 24.5C4 35.5456 12.9543 44.5 24 44.5C35.0456 44.5 44 35.5456 44 24.5C44 13.4543 35.0456 4.5 24 4.5Z" fill="#ECEFEF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M26 22.5V12.2781H22V24.5C22 25.6046 22.8954 26.5 24 26.5H33.778V22.5H26Z" fill="#ECEFEF"/>
      </svg>
      <div class="flex flex-col justify-start items-center space-y-0" *ngIf="config && (secondsUntilOpening && secondsUntilOpening > 0)">
        <span class="font-body text-base text-white text-center">
          Opens in
        </span>

        <countdown (event)="onUpdateSecondsUntilOpening($event)" class="text-white font-sans text-5xl font-semibold" [config]="config"></countdown>
      </div>
      <button
        *ngIf="id && status === 'attendee' && (secondsUntilOpening && secondsUntilOpening <= 0)"
        [routerLink]="'/app/live/' + element.hmsRoomId"
        class="whitespace-nowrap font-sans font-semibold flex flex-row justify-center items-center space-x-3 bg-culturedWhite hover:bg-cadetGrey-200 px-7 py-3.5 text-lg text-richBlack"
      >
        <span> {{ mode === 'liveTraining' ? 'Join live training' : 'Start Exam Session' }} </span>
      </button>

      <ng-container *ngIf="id && status === 'attendee' && (secondsUntilOpening && secondsUntilOpening > 0)">
        <sami-notification
          [type]="'success'"
          title="You are registered"
          [text]="{ text: 'If you want to cancel your registration', callToAction: { text: 'click here' }}"
          size="medium"
          [shadow]="false"
          [showCloseButton]="false"
          (callToActionClick)="setAttendee('canceled', id)"
        ></sami-notification>
      </ng-container>
      <div class="max-w-sm text-center w-full" *ngIf="(secondsUntilOpening && secondsUntilOpening <= 0) && status === 'attendee'">
        <span class="text-center text-sm text-white font-body">
          Join the {{ mode === 'exam' ? 'exam' : 'live training' }} by clicking on the link above.
          If you experience any problems, please try reloading the page.
          If the problem persist, please <a href="mailto:online@sami-x.com" class="cursor-pointer underline">contact us</a>.
        </span>
      </div>

      <div class="max-w-sm text-center w-full" *ngIf="(secondsUntilOpening && secondsUntilOpening >= 0) && status !== 'attendee'">
        <span class="text-center text-sm text-white font-body">
          The {{ mode === 'exam' ? 'exam' : 'live training' }} session opens ten minutes before the actual start.
        </span>
      </div>
    </div>
    <div class="flex flex-col justify-start items-center pt-6" *ngIf="(status === null || status === 'canceled') && id">
      <button
        [disabled]="loading"
        (click)="setAttendee('attending', id)"
        class="whitespace-nowrap font-sans font-semibold flex flex-row justify-center items-center space-x-3 bg-culturedWhite hover:bg-cadetGrey-200 px-7 py-3.5 text-lg text-richBlack"
      >
        <svg
          *ngIf="loading"
          role="status"
          class="w-5 h-5 mr-4 text-gray-200 animate-spin fill-richBlack"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span> {{ mode === 'liveTraining' ? 'Register for Live Training' : 'Register for exam' }} </span>
      </button>
    </div>
  </ng-container>

  <ng-template #noAccess>
    <svg
      *ngIf="loading"
      role="status"
      class="w-16 h-16 text-cadetGrey-600 animate-spin fill-culturedWhite"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>

    <div *ngIf="!loading" class="flex flex-col justify-start items-center max-w-lg w-full space-y-6">
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24ZM24 4C12.9543 4 4 12.9543 4 24C4 35.0456 12.9543 44 24 44C35.0456 44 44 35.0456 44 24C44 12.9543 35.0456 4 24 4Z" fill="#ECEFEF"/>
        <path d="M22 14H26V10H22V14Z" fill="#ECEFEF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 18H26V34H22V22H18V18Z" fill="#ECEFEF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M30 36H18V32H30V36Z" fill="#ECEFEF"/>
      </svg>

      <span class="font-sans font-semibold text-4xl text-white text-center">
        You do not qualify to register for this exam.
      </span>

      <span class="font-body text-base text-white text-center" *ngIf="element['connectedTrackIds']">
        In order to register for this exam session you need to finish the corresponding track including all assignments.
      </span>

      <button
        *ngIf="element['connectedTrackIds']"
        [routerLink]="['/app/tracks/' + element['connectedTrackIds']?.[0]]"
        class="whitespace-nowrap flex flex-row justify-center items-center space-x-3 bg-culturedWhite hover:bg-cadetGrey-200 px-7 py-3.5 text-lg text-richBlack"
      >
        <span> Open Track </span>
      </button>
    </div>
  </ng-template>
</ng-container>
