<div class="h-full flex flex-row justify-end items-center space-x-6 pr-6">
  <ng-container *ngFor="let menuItem of secondaryMenu">
    <button [routerLink]="menuItem.link" *ngIf="menuItem.mode === 'internal'" class="px-4 py-2 space-x-2.5 flex items-center justify-center flex-row leading-7">
      <sami-icon class="w-4 h-4 text-cadetGrey-600" *ngIf="menuItem && menuItem.icon" [name]="menuItem.icon.name"></sami-icon>
      <span>
        {{ menuItem.label }}
      </span>
    </button>

    <a [href]="menuItem.link" target="_blank" *ngIf="menuItem.mode === 'external'" class="px-4 py-2 space-x-2.5 flex items-center justify-center flex-row leading-7">
      <sami-icon class="w-4 h-4 text-cadetGrey-600" *ngIf="menuItem && menuItem.icon" [name]="menuItem.icon.name"></sami-icon>
      <span>
        {{ menuItem.label }}
      </span>
    </a>
  </ng-container>
</div>