import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges, Output,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import '@mux/mux-player';

@Component({
  selector: 'app-display-mux',
  templateUrl: './display-mux.component.html',
})
export class DisplayMuxComponent implements OnChanges, AfterViewInit {
  @Input() muxPlaybackId: string | undefined;
  @Input() muxVideoElement: any | undefined;
  @Input() displayClearButton = true;
  @Output() clear = new EventEmitter();
  @ViewChild('muxPlayer') muxPlayer: ElementRef | undefined;

  isClient = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
  ) {}

  ngAfterViewInit() {
    if ((isPlatformBrowser(this.platformId))) {
      this.updateMuxPlayer();
    }
  }

  ngOnChanges() {
    if ((isPlatformBrowser(this.platformId))) {
      this.updateMuxPlayer();
    }
  }

  updateMuxPlayer() {
    this.isClient = true;

    this.muxPlayer?.nativeElement.setAttribute(
      'playback-id',
      this.muxPlaybackId
    );
  }
}
