<div class="bg-white py-5 px-4 flex flex-col justify-start space-y-3">
  <span class="text-richBlack font-sans font-semibold text-xl"
    >Get the equipment you need</span
  >
  <div class="flex flex-col justify-start space-y-3">
    <div
      class="flex flex-row justify-start space-x-4"
      *ngFor="let product of products"
    >
      <div class="flex flex-col justify-center items-center">
        <div class="w-16 h-16" *ngIf="product.thumbnailPath">
          <picture *ngIf="product.thumbnailPath">
            <source
              type="image/webp"
              [srcset]="
                product.thumbnailPath
                  | getDownloadURL
                  | transformImg
                    : 'firestore'
                    : 'fit-in/100x100/smart/filters:format(webp)'
              "
            />
            <source
              type="image/png"
              [srcset]="
                product.thumbnailPath
                  | getDownloadURL
                  | transformImg
                    : 'firestore'
                    : 'fit-in/100x100/smart/filters:format(png)'
              "
            />
            <img
              loading="lazy"
              class="w-16 h-16 object-cover object-center"
              [src]="
                product.thumbnailPath
                  | getDownloadURL
                  | transformImg: 'firestore':'fit-in/100x100/smart'
              "
              [alt]="product.title"
            />
          </picture>
        </div>
      </div>
      <div class="flex flex-col justify-start items-start space-y-0">
        <span class="text-lg font-sans text-richBlack font-semibold">
          {{ product.title }}
        </span>
        <span class="text-base text-richBlack font-body">
          {{ product.excerpt }}
        </span>
      </div>
    </div>
  </div>
  <a
    href="https://samics.shop"
    target="_blank"
    class="bg-richBlack py-2 px-3 font-sans text-base text-center text-culturedWhite font-semibold hover:bg-opacity-95"
  >
    Go to Shop
  </a>
</div>
