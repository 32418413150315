export interface SamiLesson {
  [key: string]: any;
  id?: string;
  title?: string;
  category?: string;
  additionalInformation?: string;
  videoId?: string;
  thumbnail?: string;
  isPreview?: boolean;
  availableAfterRelativeDaysToBeginning?: number;
}
