<ng-container *ngIf="!loading; else showLoading">
  <ng-container *ngIf="{ isMobile: ui.isMobileLayout$ | async} as vm">
    <button
      *ngIf="trackCard; else showLoading"
      (click)="clickEmitter.emit(trackCard._id)"
      [ngClass]="theme"
      class="grid grid-cols-1 sm:grid-cols-3 overflow-hidden w-full relative h-full hover:shadow-lg min-h-[220px]"
    >
      <div class="relative h-[180px] sm:h-full w-full" *ngIf="trackCard?.verticalThumbnail">
        <img
          *ngIf="trackCard?.verticalThumbnail"
          class="absolute w-full object-cover h-full inset-0 object-center"
          [src]="trackCard.verticalThumbnail" />
      </div>
      <div
        [ngClass]="trackCard.verticalThumbnail ? 'col-span-2' : 'col-span-3'"
        class="flex flex-col justify-start h-full"
      >
        <div
          class="flex-1 flex-col items-center justify-start px-5 py-5 space-y-3 h-full"
        >
          <sami-purchased-badge *ngIf="hasPurchased"></sami-purchased-badge>
          <h3 class="text-white text-left font-sans text-2xl tracking-tight font-semibold">
            {{ trackCard.title }}
          </h3>
          <p
            *ngIf="trackCard.excerpt"
            class="text-sm text-white text-left font-body line-clamp-3"
          >
            {{ trackCard.excerpt }}
          </p>
        </div>

        <div
          *ngIf="userProgress?.progress; else noProgress"
          class="w-full px-5 py-2 bg-richBlack/20 flex flex-row justify-start items-center"
        >
          <div
            *ngIf="userProgress?.progress"
            class="w-full flex flex-row justify-start items-center space-x-2"
          >
            <span class="text-base font-sans text-white">
              {{ ((userProgress?.progress || 0) * 100 | number: '1.0-0') + '%' }}
            </span>
            <div
              class="w-full bg-richBlack/90 rounded-none h-2 dark:bg-richBlack/90"
            >
              <div
                *ngIf="userProgress && userProgress?.progress"
                class="bg-culturedWhite h-2 rounded-none"
                [ngStyle]="{ width: ((userProgress.progress || 0) * 100) + '%' }"
              ></div>
            </div>
          </div>
        </div>

        <ng-template #noProgress>
          <div
            *ngIf="trackCard.duration || trackCard.level"
            class="w-full px-5 py-2 bg-richBlack/20 flex flex-row justify-start overflow-x-auto space-x-4 text-sm text-white font-semibold uppercase items-center"
          >
            <div
              class="flex flex-row justify-start items-center space-x-2"
              *ngIf="trackCard.level"
            >
              <svg
                class="w-4 h-4"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2805_9462)">
                  <path
                    d="M0.583333 10.5H2.91667V12.8333H0.583333V10.5Z"
                    fill="#EDF2EF"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 10.5C0 10.1778 0.261167 9.91665 0.583333 9.91665H2.91667C3.23883 9.91665 3.5 10.1778 3.5 10.5V12.8333C3.5 13.1555 3.23883 13.4166 2.91667 13.4166H0.583333C0.261167 13.4166 0 13.1555 0 12.8333V10.5ZM1.16667 11.0833V12.25H2.33333V11.0833H1.16667Z"
                    fill="#EDF2EF"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.5 1.16665C10.5 0.84448 10.7612 0.583313 11.0833 0.583313H13.4167C13.7388 0.583313 14 0.84448 14 1.16665V12.8333C14 13.1555 13.7388 13.4166 13.4167 13.4166H11.0833C10.7612 13.4166 10.5 13.1555 10.5 12.8333V1.16665ZM11.6667 1.74998V12.25H12.8333V1.74998H11.6667ZM5.25 5.83331C5.25 5.51115 5.51117 5.24998 5.83333 5.24998H8.16667C8.48883 5.24998 8.75 5.51115 8.75 5.83331V12.8333C8.75 13.1555 8.48883 13.4166 8.16667 13.4166H5.83333C5.51117 13.4166 5.25 13.1555 5.25 12.8333V5.83331ZM6.41667 6.41665V12.25H7.58333V6.41665H6.41667Z"
                    fill="#EDF2EF"
                  />
                </g>
              </svg>
              <span class="whitespace-nowrap font-sans"> LEVEL {{ trackCard.level }} </span>
            </div>

            <div
              class="flex flex-row justify-start items-center space-x-2"
              *ngIf="trackCard.duration"
            >
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7ZM7 1.16667C3.77834 1.16667 1.16667 3.77834 1.16667 7C1.16667 10.2216 3.77834 12.8333 7 12.8333C10.2216 12.8333 12.8333 10.2216 12.8333 7C12.8333 3.77834 10.2216 1.16667 7 1.16667ZM7.58333 6.41667V3.43527H6.41667V7C6.41667 7.32217 6.67783 7.58333 7 7.58333H9.85192V6.41667H7.58333Z" fill="#ECEFEF"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7ZM7 1.16667C3.77834 1.16667 1.16667 3.77834 1.16667 7C1.16667 10.2216 3.77834 12.8333 7 12.8333C10.2216 12.8333 12.8333 10.2216 12.8333 7C12.8333 3.77834 10.2216 1.16667 7 1.16667Z" fill="#ECEFEF"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.58333 6.41667V3.43527H6.41667V7C6.41667 7.32217 6.67783 7.58333 7 7.58333H9.85192V6.41667H7.58333Z" fill="#ECEFEF"/>
              </svg>
              <span class="whitespace-nowrap font-sans"> {{ trackCard.duration }} HOURS </span>
            </div>
          </div>
        </ng-template>
      </div>

    </button>
  </ng-container>
</ng-container>

<ng-template #showLoading>
  <div class="bg-white grid grid-cols-1 sm:grid-cols-3 overflow-hidden w-full relative h-full hover:shadow-lg min-h-[220px] justify-start items-center">
    <div class="relative h-44 sm:h-full w-full animate-pulse bg-cadetGrey-200"></div>
    <div class="col-span-2 grow shrink basis-0 self-stretch px-6 py-4 flex-col justify-center items-start gap-4 inline-flex">
      <div class="flex-col justify-start items-start gap-4 flex">
        <div class="flex-col justify-start items-start gap-2 flex">
          <div class="w-24 h-3.5 bg-cadetGrey-200 animate-pulse"></div>
          <div class="w-44 h-6 bg-cadetGrey-200 animate-pulse"></div>
        </div>
        <div class="w-64 h-12 bg-cadetGrey-200 animate-pulse"></div>
      </div>
      <div class="justify-start items-start gap-4 inline-flex">
        <div class="w-16 h-5 bg-cadetGrey-200 animate-pulse"></div>
        <div class="w-16 h-5 bg-cadetGrey-200 animate-pulse"></div>
        <div class="w-16 h-5 bg-cadetGrey-200 animate-pulse"></div>
      </div>
    </div>
  </div>
</ng-template>
