import { CdkOverlayOrigin, ConnectionPositionPair, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, ElementRef, InjectionToken, Injector, Input, ViewChild } from '@angular/core';
import { SamiIcon } from '@sami/ui';
import { SubmenuComponent } from '../submenu/submenu.component';

interface SamiMenuItem {
  icon?: SamiIcon,
  title: string,
  route: string,
  active?: boolean,
  submenu?: {
    cols?: number;
    items: SamiSubmenuItem[];
    featured?: SamiSubmenuItem;
  }
}

interface SamiSubmenuItem {
  title: string;
  route: string;
  description: string;
  image?: string;
  isFeatured?: boolean;
}

export const CONTAINER_DATA = new InjectionToken<{}>('CONTAINER_DATA');

@Component({
  selector: 'app-menu-button',
  templateUrl: './menu-button.component.html'
})
export class MenuButtonComponent {
  @Input() item: SamiMenuItem | undefined;
  @Input() lang: string | undefined;
  @Input() backgroundColor: 'white' | 'black' = 'white';

  @ViewChild('row') button: ElementRef<ElementRef> | undefined;
  isOpen = false;
  injector: Injector | undefined;

  constructor(private _overlay: Overlay) {}

  private getPosition(): ConnectionPositionPair {
    return {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'bottom'
    }
  }

  setOverlay(trigger: CdkOverlayOrigin) {
    const positionStrategy = this._overlay
      .position()
      .flexibleConnectedTo(trigger.elementRef)
      .withPositions([this.getPosition()])

    const overlayRef = this._overlay.create({
        positionStrategy,
        hasBackdrop: true,
        backdropClass: 'bg-transparent',
        scrollStrategy: this._overlay.scrollStrategies.reposition()
    });

    overlayRef.backdropClick().subscribe(() => overlayRef.detach());

    if (this.item && this.item.submenu) {
      const overlayPortal = new ComponentPortal(SubmenuComponent, null, this.createInjector(this.item.submenu || []));
      const ref = overlayRef.attach<SubmenuComponent>(overlayPortal);
      ref.instance.backdropClick.subscribe(() => overlayRef.detach());
    }
  }

  createInjector(submenu: {
    cols?: number;
    items: SamiSubmenuItem[];
    featured?: SamiSubmenuItem;
  }) {
    return Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: CONTAINER_DATA,
          useValue: {
            cols: submenu.cols || 2,
            items: submenu.items,
            featured: submenu.featured,
            lang: this.lang
          }
        }
      ]
    })
  }

}
