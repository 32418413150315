<div
  [ngClass]="background === 'white' ? 'bg-white' : 'bg-culturedWhite'"
  class="py-5 px-4 flex flex-col justify-start items-start space-y-3"
>
  <span *ngIf="cardTitle" class="text-xl font-sans font-semibold text-richBlack">
    {{ cardTitle }}
  </span>
  <div class="flex flex-row justify-start items-center space-x-4">
    <picture *ngIf="image && image.filename && type === 'firestore'">
      <source
        type="image/webp"
        [srcset]="
          image.filename
            | getDownloadURL
            | transformImg
              : 'firestore'
              : 'fit-in/0x600/smart/filters:format(webp)'
        "
      />
      <source
        type="image/png"
        [srcset]="
          image.filename
            | getDownloadURL
            | transformImg
              : 'firestore'
              : 'fit-in/0x600/smart/filters:format(png)'
        "
      />
      <img
        loading="lazy"
        class="w-16 h-16 rounded-full object-cover object-center"
        [src]="
          image.filename
            | getDownloadURL
            | transformImg: 'firestore':'fit-in/0x600/smart'
        "
        [alt]="title"
      />
    </picture>

    <picture *ngIf="image && image.filename && type === 'storyblok'">
      <source
        type="image/webp"
        [srcset]="
          image.filename
            | transformImg
              : 'storyblok'
              : 'fit-in/0x600/smart/filters:format(webp)'
        "
      />
      <source
        type="image/png"
        [srcset]="
          image.filename
            | transformImg
              : 'storyblok'
              : 'fit-in/0x600/smart/filters:format(png)'
        "
      />
      <img
        loading="lazy"
        class="w-16 h-16 rounded-full object-cover object-center"
        [src]="image.filename | transformImg: 'storyblok':'fit-in/0x600/smart'"
        [alt]="title"
      />
    </picture>

    <div class="flex flex-col justify-center items-start">
      <span class="text-lg font-sans font-semibold text-richBlack text-left">{{
        title
      }}</span>
      <span class="text-sm text-cadetGrey-600 text-left">{{ subtitle }}</span>
    </div>
  </div>
  <p class="text-sm text-richBlack text-left font-body">
    {{ description }}
  </p>
</div>
