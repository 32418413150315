import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClipCardComponent } from './clip-card.component';
import { ManageCollectionButtonModule } from '../../manage-collection-button/manage-collection-button.module';
import { SamiCombatSystemTagComponent } from '../../combat-system-tag/combat-system-tag.component';

@NgModule({
  declarations: [ClipCardComponent],
  imports: [CommonModule, ManageCollectionButtonModule, SamiCombatSystemTagComponent],
  exports: [ClipCardComponent],
})
export class ClipCardModule {}
