import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-display-file',
  templateUrl: './display-file.component.html',
})
export class DisplayFileComponent implements OnInit {
  @Input() filePath: string | any;
  @Output() clear = new EventEmitter();
  @Input() type: 'video' | 'image' | 'pdf' = 'image';

  meta$: Observable<any> | any;
  fileUrl$: Observable<any> | any;

  constructor(private storage: AngularFireStorage) {}

  ngOnInit(): void {
    this.meta$ = this.storage.ref(this.filePath).getMetadata();
    this.fileUrl$ = this.storage.ref(this.filePath).getDownloadURL();
  }
}
