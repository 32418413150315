import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@sami/features';
import { SamiUser } from 'interfaces';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html'
})
export class MyProfileComponent implements OnInit {
  @Input() user: SamiUser | undefined;
  @Input() currentSite: 'sami-international' | 'sami-x' | 'sami-x-app' | 'admin' | 'b2b' | 'instructor-area' | 'shop' = 'sami-international';
  @Input() backgroundColor: 'white' | 'black' = 'white';
  @Output() logout: EventEmitter<void> = new EventEmitter();

  menuItems: { label: string; link: string; mode: 'internal' | 'external' }[] = [];

  constructor(
    private auth: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getMenu();
  }

  getMenu() {
    switch (this.currentSite) {
      case 'sami-international':
        this.menuItems = [
          { label: 'My Profile', link: '/app/home/membership', mode: 'internal' },
          { label: 'Settings', link: '/app/home/personal-information', mode: 'internal' },
        ];

        if (this.user?.center?.role?.toLowerCase() === 'owner') {
          this.menuItems.push({ label: 'Center Management', link: '/app/center-area/general', mode: 'internal' });
        }

        if (this.user?.instructorSettings?.entiteledToInstructorSubscription) {
          this.menuItems.push({ label: 'Instructor Profile', link: '/app/instructor-area/account', mode: 'internal' });
        }

        if (this.user?.isGlobalAdmin) {
          this.menuItems.push({ label: 'Event Management', link: '/app/seminar-management/list', mode: 'internal' });
        }
        break;
      case 'sami-x':
        this.menuItems = [];
        this.menuItems = [
          { label: 'Go to App', link: '/app', mode: 'internal' },
          { label: 'My Profile', link: '/app/community/profile/my-account', mode: 'internal' },
          { label: 'Settings', link: '/app/settings/account', mode: 'internal' },
        ];

        if (this.user?.center?.role?.toLowerCase() === 'owner') {
          this.menuItems.push({ label: 'Center Management', link: 'https://sami-international.com/app/center-area/general', mode: 'external' });
        }

        if (this.user?.instructorSettings?.entiteledToInstructorSubscription) {
          this.menuItems.push({ label: 'Instructor Profile', link: 'https://sami-international.com/app/instructor-area/account', mode: 'external' });
        }

        if (this.user?.isGlobalAdmin) {
          this.menuItems.push({ label: 'Event Management', link: 'https://sami-international.com/app/seminar-management/list', mode: 'external' });
        }
        break;

      case 'sami-x-app':
        this.menuItems = [
          { label: 'My Profile', link: '/app/my-profile', mode: 'internal' },
          { label: 'Settings', link: '/app/my-profile/account-settings', mode: 'internal' },
          { label: 'My Purchases', link: '/app/my-profile/my-purchases', mode: 'internal' },
        ];

        if (this.user?.center?.role?.toLowerCase() === 'owner') {
          this.menuItems.push({ label: 'Center Management', link: 'https://sami-international.com/app/center-area/general', mode: 'external' });
        }

        if (this.user?.instructorSettings?.entiteledToInstructorSubscription) {
          this.menuItems.push({ label: 'Instructor Profile', link: 'https://sami-international.com/app/instructor-area/account', mode: 'external' });
        }

        if (this.user?.isGlobalAdmin) {
          this.menuItems.push({ label: 'Event Management', link: 'https://sami-international.com/app/seminar-management/list', mode: 'external' });
        }
        break;

      case 'instructor-area':
        this.menuItems = [
          { label: 'Settings', link: '/app/settings', mode: 'internal' },
        ];

        if (this.user?.center?.role?.toLowerCase() === 'owner') {
          this.menuItems.push({ label: 'Center Management', link: 'https://sami-international.com/app/center-area/general', mode: 'external' });
        }

        if (this.user?.instructorSettings?.entiteledToInstructorSubscription) {
          this.menuItems.push({ label: 'Instructor Profile', link: 'https://sami-international.com/app/instructor-area/account', mode: 'external' });
        }

        if (this.user?.isGlobalAdmin) {
          this.menuItems.push({ label: 'Event Management', link: 'https://sami-international.com/app/seminar-management/list', mode: 'external' });
        }
        break;

        case 'b2b':
        this.menuItems = [
          { label: 'Settings', link: '/app/settings', mode: 'internal' },
        ];
        break;
      }
  }


  getStudentRole(user: SamiUser) {
    if (user?.isGlobalAdmin) {
      return 'Admin';
    } else if (user?.isCenterAdmin) {
      return 'Center Admin';
    } else if (user?.isGlobalInstructor) {
      return 'Instructor';
    } else if (user.role && user.role?.status === 'unlocked' && user.role?.type === 'instructor') {
      return 'Instructor';
    } else if (user.subscriptionDigest?.hasSubscription?.instructor) {
      return 'SAMI Instructor';
    } else if (user.subscriptionDigest?.hasSubscription?.plus) {
      return 'Member';
    } else if (user.subscriptionDigest?.hasSubscription?.basic) {
      return 'Member';
    }
    return 'Free User';
  }

  onLogout() {
    this.auth.logout();
    if (this.currentSite === 'sami-x-app' || this.currentSite === 'sami-x') {
      this.router.navigate(['/auth/login']);
    } else {
      this.router.navigate(['/']);
    }
    this.logout.emit();
  }

}
