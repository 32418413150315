import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { SamiUser } from 'interfaces';

@Component({
  selector: 'sami-pricing-table',
  templateUrl: './pricing-table.component.html'
})
export class PricingTableComponent {
  price$: Observable<any>;
  price_sami_x_month$: Observable<any>;
  price_sami_x_year$: Observable<any>;

  @Input() currency = 'EUR';
  @Input() user: SamiUser | undefined;
  @Input() location: any;
  @Input() background: 'white' | 'culturedWhite' = 'culturedWhite';

  table = [
    {
      type: 'header',
      label: 'Training at SAMI Center',
      table_cells: [],
    },
    {
      type: 'body',
      label: 'Take SAMI exams to receive next level',
      table_cells: [
        {
          text: '',
          type: 'included',
        },
        {
          text: '',
          type: 'included',
        },
      ],
    },
    {
      type: 'body',
      label: 'Receive official SAMI certification/diploma',
      table_cells: [
        {
          text: '',
          type: 'included',
        },
        {
          text: '',
          type: 'included',
        },
      ],
    },
    {
      type: 'body',
      label: 'Digital Membership pass',
      table_cells: [
        {
          text: '',
          type: 'included',
        },
        {
          text: '',
          type: 'included',
        },
      ],
    },
    {
      type: 'header',
      label: 'Online Training',
      table_cells: [],
    },
    {
      type: 'body',
      label: 'Access to SAMI Combat Systems ',
      table_cells: [
        {
          text: 'Only one system for exam preparation',
          type: 'text',
        },
        {
          text: 'Krav Maga, Panantukan, Stick, Axe, Knife',
          type: 'text',
        },
      ],
    },
    {
      type: 'body',
      label: 'Access to all levels',
      table_cells: [
        {
          text: 'Only your current level and one above',
          type: 'text',
        },
        {
          text: 'Level 1 - 6 <br>(once you reach level 6, all levels are unlocked)',
          type: 'text',
        },
      ],
    },
    {
      type: 'body',
      label: 'Learning Tracks',
      table_cells: [
        {
          text: '',
          type: 'excluded',
        },
        {
          text: '',
          type: 'included',
        },
      ],
    },
    {
      type: 'body',
      label: 'Guided Trainings',
      table_cells: [
        {
          text: '',
          type: 'excluded',
        },
        {
          text: '',
          type: 'included',
        },
      ],
    },
    {
      type: 'body',
      label: 'Training Creator',
      table_cells: [
        {
          text: '',
          type: 'excluded',
        },
        {
          text: '',
          type: 'included',
        },
      ],
    },
    {
      type: 'body',
      label: 'Live Trainings',
      table_cells: [
        {
          text: '',
          type: 'excluded',
        },
        {
          text: '',
          type: 'included',
        },
      ],
    },
    {
      type: 'body',
      label: 'Video Library (incl. Playlists)',
      table_cells: [
        {
          text: 'Select videos from one system',
          type: 'text',
        },
        {
          text: '',
          type: 'included',
        },
      ],
    },
    {
      type: 'body',
      label: 'Online Assignments',
      table_cells: [
        {
          text: '',
          type: 'excluded',
        },
        {
          text: '',
          type: 'included',
        },
      ],
    },
    {
      type: 'body',
      label: 'Online Certification',
      table_cells: [
        {
          text: '',
          type: 'excluded',
        },
        {
          text: '',
          type: 'included',
        },
      ],
    },
    {
      type: 'body',
      label: 'Refer a friend',
      table_cells: [
        {
          text: '',
          type: 'excluded',
        },
        {
          text: '',
          type: 'included',
        },
      ],
    },
  ];

  constructor(
    private afs: AngularFirestore,
  ) {
    this.price$ = this.afs
      .doc(`stripeSubscriptions/sami_international_membership`)
      .valueChanges();
    this.price_sami_x_month$ = this.afs
      .doc(`stripeSubscriptions/samix_month_2023`)
      .valueChanges();
    this.price_sami_x_year$ = this.afs
      .doc(`stripeSubscriptions/samix_year_2023`)
      .valueChanges();
  }
}
