<button
  *ngIf="type === 'web'"
  (click)="setStatus()"
  matRipple
  class="bg-culturedWhite hover:bg-opacity-90 mt-8 text-lg uppercase flex flex-row justify-center items-center font-semibold font-sans text-richBlack px-6 py-3"
>
  <div [ngClass]="iconPosition === 'start' ? 'mr-2 order-first' : 'ml-2 order-last'" *ngIf="icon" [innerHTML]="icon | safe : 'html'"></div>
  <span>
    {{ text }}
  </span>
</button>

<div [ngClass]="isNative || isPWA ? 'pb-6' : 'pb-2.5'" class="bg-richBlack fixed bottom-0 inset-x-0 z-50 p-2.5" *ngIf="type === 'mobile'">
  <button matRipple (click)="setStatus()" [ngClass]="theme" class="w-full flex flex-row justify-center items-center space-x-3 px-7 py-3.5 font-sans text-white text-lg font-semibold">
    <span>
      {{ text }}
    </span>
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_7843_34206)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2559 4.4292L20.2559 9.4292C20.5814 9.75464 20.5814 10.2823 20.2559 10.6077L15.2559 15.6077L14.0774 14.4292L18.4882 10.0185L14.0774 5.60771L15.2559 4.4292Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.6667 10.8518H0.5V9.18512H19.6667V10.8518Z" fill="white"/>
      </g>
    </svg>
  </button>
</div>