import {
  Directive,
  ViewContainerRef,
  ComponentFactoryResolver,
  Input,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { componentMap } from '../component-map';

@Directive({
  selector: '[appCtrlFactory]',
})
export class ControlFactoryDirective implements OnChanges, OnDestroy {
  @Input() data: any;
  componentRef: any;
  init = false;

  constructor(
    private vcRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver
  ) {}

  ngOnChanges() {
    if (!this.data.component || this.init) {
      return;
    }

    this.lazyLoad();
  }

  public ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }

  async lazyLoad() {
    this.vcRef.clear();

    const component: any = await componentMap[this.data.component]();
    const factory = this.resolver.resolveComponentFactory(component);
    const compRef: any = this.vcRef.createComponent(factory);
    compRef.instance.data = { ...this.data };

    if (this.componentRef) {
      this.componentRef.destroy();
    }

    this.componentRef = compRef;
    this.init = true;
  }
}
