<ng-container *ngIf="type === 'button'">
  <button
    [matMenuTriggerFor]="appMenu"
    class="flex flex-row justify-center items-center space-x-3 w-full bg-cadetGrey-200 hover:bg-cadetGrey-100 px-4 py-3 text-lg font-semibold text-richBlack"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="2"
    >
      <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4" />
    </svg>
    <span>
      {{ buttonText }}
    </span>
  </button>
</ng-container>
<ng-container *ngIf="type === 'icon'">
  <button
    [matMenuTriggerFor]="appMenu"
    class="p-1 bg-richBlack hover:bg-cadetGrey-900 text-culturedWhite z-10 flex-col justify-center items-center"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-5 w-5"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="2"
    >
      <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4" />
    </svg>
  </button>
</ng-container>

<mat-menu #appMenu="matMenu">
  <ng-template matMenuContent>
    <app-collection-menu
      *ngIf="clipId"
      [clipId]="clipId"
      (updated)="updated.emit($event)"
    ></app-collection-menu>
  </ng-template>
</mat-menu>
