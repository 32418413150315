<div class="md:bg-cadetGrey-900 md:min-h-screen h-full">
  <section
    class="max-w-xl px-0 mx-auto flex flex-col justify-start h-full"
    *ngIf="workoutId$ | async as workoutId"
  >
    <div
      id="finish-workout-container"
      class="w-full flex flex-col min-h-[100svh] h-[100svh] safe-top bg-white relative"
      *ngIf="session$ | async as session"
    >
      <div class="bg-richBlack pt-8 pb-8 flex flex-col justify-center items-center">
        <div class="flex flex-col justify-start items-center pt-2">
          <span class="text-cadetGrey-600 font-sans text-lg font-semibold">
            TRAINING COMPLETE
          </span>
          <span class="text-white text-5xl font-semibold font-sans"> SAVE TRAINING </span>
        </div>
      </div>
      <div
        class="flex-1 pt-4 pb-20 px-4 flex flex-col justify-start space-y-4 overflow-y-auto bg-culturedWhite"
      >
        <div class="flex flex-col justify-start space-y-2">
          <h3 class="text-2xl font-semibold font-sans text-richBlack">
            Where did you train?
          </h3>
          <div
            class="flex font-sans flex-row justify-start items-start flex-wrap space-x-4"
          >
            <button
              (click)="
                trainingLocation === 'indoor'
                  ? (trainingLocation = null)
                  : (trainingLocation = 'indoor')
              "
              [ngClass]="
                trainingLocation === 'indoor'
                  ? 'bg-richBlack text-white'
                  : 'bg-white text-cadetGrey-300'
              "
              class="w-24 h-24 flex flex-col justify-center items-center relative"
            >
              <svg
                class="w-8 h-8"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="2"
                  d="M1 12L12 2L23 12H19V22H15V16H9V22H5V12H1Z"
                ></path>
              </svg>
              <span> HOME </span>
            </button>

            <button
              (click)="
                trainingLocation === 'center'
                  ? (trainingLocation = null)
                  : (trainingLocation = 'center')
              "
              [ngClass]="
                trainingLocation === 'center'
                  ? 'bg-richBlack text-white'
                  : 'bg-white text-cadetGrey-300'
              "
              class="w-24 h-24 flex flex-col justify-center items-center relative"
            >
              <svg
                class="w-8 h-8"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="2"
                  d="M18 20C18 21.7 16.7 23 15 23H9C7.3 23 6 21.7 6 20V10C6 8.3 7.3 7 9 7H15C16.7 7 18 8.3 18 10V20Z"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="2"
                  d="M6.8 7.92969L12 1L17.2 7.92969"
                ></path>
                <path fill="currentColor" d="M18 12.5H6V17.5H18V12.5Z"></path>
              </svg>
              <span> CENTER </span>
            </button>

            <button
              (click)="
                trainingLocation === 'outdoor'
                  ? (trainingLocation = null)
                  : (trainingLocation = 'outdoor')
              "
              [ngClass]="
                trainingLocation === 'outdoor'
                  ? 'bg-richBlack text-white'
                  : 'bg-white text-cadetGrey-300'
              "
              class="w-24 h-24 flex flex-col justify-center items-center relative"
            >
              <svg
                class="w-8 h-8"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 17H11L6 1L1 17Z"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M23 13C23 14.0609 22.5786 15.0783 21.8284 15.8284C21.0783 16.5786 20.0609 17 19 17C17.9391 17 16.9217 16.5786 16.1716 15.8284C15.4214 15.0783 15 14.0609 15 13V5C15 3.93913 15.4214 2.92172 16.1716 2.17157C16.9217 1.42143 17.9391 1 19 1C20.0609 1 21.0783 1.42143 21.8284 2.17157C22.5786 2.92172 23 3.93913 23 5V13Z"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 13V24"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 6V24"
                ></path>
              </svg>
              <span> OUTSIDE </span>
            </button>
          </div>
        </div>

        <div class="flex font-sans flex-col justify-start space-y-2 pt-4">
          <h3 class="text-2xl font-semibold text-richBlack">
            How do you feel?
          </h3>
          <div class="flex flex-row justify-start items-start flex-wrap">
            <button
              (click)="
                personalExperience === 5
                  ? (personalExperience = null)
                  : (personalExperience = 5)
              "
              [ngClass]="
                personalExperience === 5
                  ? 'bg-richBlack text-white'
                  : 'bg-white text-cadetGrey-300'
              "
              class="w-24 h-24 flex flex-col justify-center items-center relative mr-4 mb-4"
            >
              <svg
                class="w-12 h-12"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 13C19 15.866 15.866 19 12 19C8.134 19 5 15.866 5 13H19Z"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5V9"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 5V9"
                ></path>
              </svg>
            </button>

            <button
              (click)="
                personalExperience === 4
                  ? (personalExperience = null)
                  : (personalExperience = 4)
              "
              [ngClass]="
                personalExperience === 4
                  ? 'bg-richBlack text-white'
                  : 'bg-white text-cadetGrey-300'
              "
              class="w-24 h-24 flex flex-col justify-center items-center relative mr-4 mb-4"
            >
              <svg
                class="w-12 h-12"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 13C6 13 8 17 12 17C16 17 18 13 18 13"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 9H10"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M14 9H18"
                ></path>
              </svg>
            </button>

            <button
              (click)="
                personalExperience === 3
                  ? (personalExperience = null)
                  : (personalExperience = 3)
              "
              [ngClass]="
                personalExperience === 3
                  ? 'bg-richBlack text-white'
                  : 'bg-white text-cadetGrey-300'
              "
              class="w-24 h-24 flex flex-col justify-center items-center relative mr-4 mb-4"
            >
              <svg
                class="w-12 h-12"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 7V11"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7V11"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 17H16"
                ></path>
              </svg>
            </button>

            <button
              (click)="
                personalExperience === 2
                  ? (personalExperience = null)
                  : (personalExperience = 2)
              "
              [ngClass]="
                personalExperience === 2
                  ? 'bg-richBlack text-white'
                  : 'bg-white text-cadetGrey-300'
              "
              class="w-24 h-24 flex flex-col justify-center items-center relative mr-4 mb-4"
            >
              <svg
                class="w-12 h-12"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 17C7.68116 15.6094 9.82073 14.8962 12 15C14.1793 14.8962 16.3188 15.6094 18 17"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 7V11"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7V11"
                ></path>
              </svg>
            </button>

            <button
              (click)="
                personalExperience === 1
                  ? (personalExperience = null)
                  : (personalExperience = 1)
              "
              [ngClass]="
                personalExperience === 1
                  ? 'bg-richBlack text-white'
                  : 'bg-white text-cadetGrey-300'
              "
              class="w-24 h-24 flex flex-col justify-center items-center relative mr-4 mb-4"
            >
              <svg
                class="w-12 h-12"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 19C13.6569 19 15 17.6569 15 16C15 14.3431 13.6569 13 12 13C10.3431 13 9 14.3431 9 16C9 17.6569 10.3431 19 12 19Z"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M7 7L10 9L7 11"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 7L14 9L17 11"
                ></path>
              </svg>
            </button>
          </div>
        </div>

        <div class="flex flex-col font-sans justify-start space-y-2 pt-4">
          <h3 class="text-2xl font-semibold text-richBlack">Add a note</h3>
          <div>
            <div class="mt-1">
              <textarea
                rows="4"
                [(ngModel)]="notes"
                name="note"
                id="note"
                class="shadow-none focus:ring-richBlack focus:border-richBlack block w-full sm:text-sm border-gray-300 rounded-none"
                placeholder="Write something..."
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="auth.user$ | async as user">
        <div
          [ngClass]="isPWA || isNative ? 'pb-6' : 'pb-2.5'"
          class="absolute bottom-0 w-full px-2.5 pt-2.5 bg-white"
          *ngIf="sessionId$ | async as sessionId"
        >
          <button
            (click)="saveNotes(sessionId, user.uid)"
            class="uppercase font-sans bg-richBlack hover:bg-cadetGrey-800 w-full py-3 text-lg font-semibold text-white text-center"
          >
            Finish
          </button>
        </div>
      </ng-container>
    </div>
  </section>
</div>
