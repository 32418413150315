import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AuthService, LocationService, TracksService, UiService } from '@sami/features';
import { Track } from 'interfaces';
import { PaywallBottomsheetComponent, PaywallDialogComponent } from '@sami/ui';
import { Observable, lastValueFrom, map, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TrackGuard implements CanActivate {

  defaultImage: any = {
    knife: 'https://a.storyblok.com/f/110844/2100x700/43a4e301dd/p1433924-neu-2.png',
    stick: 'https://a.storyblok.com/f/110844/2100x700/f0ebf07adf/p1433746-neu-2.png',
    axe: 'https://a.storyblok.com/f/110844/2100x700/b959279965/p1433766-neu-2.png',
    panantukan: 'https://a.storyblok.com/f/110844/2100x700/1c3d7f9224/p1433940-neu-2.png',
    kravMaga: 'https://a.storyblok.com/f/110844/2100x700/735b599b2a/p1444036-neu-2.png',
    kapap: 'https://a.storyblok.com/f/110844/2000x1000/456c35052f/samixpro.png',
    ebc: 'https://a.storyblok.com/f/110844/2000x1000/5a23802f19/samixebc.png',
    sds: 'https://a.storyblok.com/f/110844/2000x1000/b21a7329ea/sds.png',
    defender: 'https://a.storyblok.com/f/110844/650x495/2a347e804f/sami-defender-course-previewimage.jpg',
    karambit: 'https://a.storyblok.com/f/110844/6000x4000/a3696dc428/karambit.jpeg'
  };

  constructor(
    private track: TracksService,
    private location: LocationService,
    private ui: UiService,
    private auth: AuthService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.track.getTrack$(route.params['trackId']).pipe(
      take(1),
      map((track: Track | undefined) => {

        const trackInfo: any = {
          docId: route.params['trackId'],
          title: track?.title || '',
          contentType: 'tracks',
          image: this.defaultImage[track?.system || 'kravMaga'],
        };

        console.log('trackInfo', trackInfo);
        console.log('track', track);

        const currentSite = this.ui.currentSite$.getValue();

        if (!track?.hasAccess && currentSite === 'sami-x-app' && track?.trackAccessRules?.subscriptionAccess?.plus) {
          this.openPaywallDialog(track, trackInfo);

          return false;
        }

        if (route.params['chapterId'] && !route.params['lessonId']) {
          const chapter = track?.chapters?.find((chapter) => chapter.id === route.params['chapterId']);
          if (!chapter?.hasAccess && currentSite === 'sami-x-app' && track?.trackAccessRules?.subscriptionAccess?.plus) {
            this.openPaywallDialog(track, trackInfo);

            return false;
          }
        }

        if (route.params['chapterId'] && route.params['lessonId']) {
          const chapter = track?.chapters?.find((chapter) => chapter.id === route.params['chapterId']);
          const lesson = chapter?.lessons?.find((lesson) => lesson.id === route.params['lessonId']);
          if (!lesson?.hasAccess && currentSite === 'sami-x-app' && track?.trackAccessRules?.subscriptionAccess?.plus) {
            this.openPaywallDialog(track, trackInfo);

            return false;
          }
        }

        return true;
      })
    );
  }

  async openPaywallDialog(track: Track, trackInfo: any) {
    const isMobile = await lastValueFrom(this.ui.isMobileLayout$.pipe(
      take(1)
    ));

    const loc = await lastValueFrom(this.location.location$.pipe(take(1)));
    const user = await lastValueFrom(this.auth.user$.pipe(take(1)));

    const oneTimePurchaseData = {
      docId: trackInfo.docId,
      title: track?.title,
      contentType: 'tracks',
      currency: loc.currency.code,
      stripeCustomerId: user?.stripeCustomerId,
      image: trackInfo.image,
    };

    if (isMobile) {
      this.bottomSheet.open(PaywallBottomsheetComponent, {
        panelClass: 'sami-bottomsheet', // Todo: Dünkleres Overlay
        data: {
          oneTimePurchaseData
        }
      });
    } else {
      this.dialog.open(PaywallDialogComponent, {
        panelClass: 'sami-dialog', // Todo: Dünkleres Overlay
        backdropClass: 'bg-richBlack/80',
        data: {
          oneTimePurchaseData
        },
      });
    }
  }
}
