import { Pipe, PipeTransform } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'doc',
  standalone: true,
})
export class DocPipe implements PipeTransform {
  constructor(private afs: AngularFirestore) {}

  transform(value: any): Observable<any> {
    return value ? this.afs.doc(value).valueChanges({ idField: 'id' }) : of(null);
  }
}
