<ng-container *ngIf="item">
  <ng-container *ngIf="item.submenu; else noSubmenu">
    <button
      (click)="setOverlay(trigger)"
      type="button"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      [ngClass]="backgroundColor === 'white' ? 'text-cadetGrey-600 hover:bg-culturedWhite' : 'text-gray-300 hover:bg-culturedWhite/10'"
      [routerLinkActive]="backgroundColor === 'white' ? 'font-semibold bg-culturedWhite' : 'font-semibold bg-culturedWhite/10'"
      [routerLinkActiveOptions]="{ exact: false }"
      type="button"
      class="px-3 h-full py-1.5 border-transparent whitespace-nowrap relative z-10 flex items-center"
      aria-expanded="false"
    >
      {{ item.title }}
    </button>
  </ng-container>

  <ng-template #noSubmenu>
    <button
      [routerLink]="'/' + item.route"
      [ngClass]="backgroundColor === 'white' ? 'text-cadetGrey-600 hover:bg-culturedWhite' : 'text-gray-300 hover:bg-culturedWhite/10'"
      [routerLinkActive]="backgroundColor === 'white' ? 'font-semibold bg-culturedWhite' : 'font-semibold bg-culturedWhite/10'"
      [routerLinkActiveOptions]="{ exact: true }"
      type="button"
      class="px-3 h-full py-1.5 border-transparent whitespace-nowrap relative z-10 flex items-center"
      aria-expanded="false"
    >
      {{ item.title }}
    </button>
  </ng-template>
</ng-container>