import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-field-errors',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './field-errors.component.html'
})
export class FieldErrorsComponent {
  @Input() public formField: FormControl | undefined;
}
