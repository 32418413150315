import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { SamiSeminar } from 'interfaces';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SeminarService } from '../services/seminar.service';


@Injectable({
  providedIn: 'root',
})
export class SeminarGuard implements CanActivate {
  constructor(private router: Router, private seminar: SeminarService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.seminar.getSeminarBySlug$(next.params['slug']).pipe(
      take(1),
      map((seminar: SamiSeminar | null | undefined) => {
        if (!seminar || !seminar?.isPublished) {
          this.router.navigateByUrl('/');
          return false;
        }

        return true;
      })
    );
  }
}
