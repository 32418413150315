import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PricingTableComponent } from './pricing-table.component';
import { MembershipCardComponent } from './membership-card/membership-card.component';
import { DowngradeDialogComponent } from './membership-card/downgrade-dialog/downgrade-dialog.component';
import { UpgradeDialogComponent } from './membership-card/upgrade-dialog/upgrade-dialog.component';
import { OffboardingProcessComponent } from './membership-card/offboarding-process/offboarding-process.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { ImagePipe } from '@sami/ui';
import { StoragePipe } from '@sami/ui';
import { OrderSummaryComponent } from './membership-card/upgrade-dialog/order-summary/order-summary.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MembershipSelectionComponent } from './membership-selection/membership-selection.component';

@NgModule({
  declarations: [
    PricingTableComponent,
    MembershipCardComponent,
    DowngradeDialogComponent,
    UpgradeDialogComponent,
    OffboardingProcessComponent,
    OrderSummaryComponent,
    MembershipSelectionComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatStepperModule,
    ImagePipe,
    StoragePipe,
    MatDialogModule,
  ],
  exports: [PricingTableComponent, MembershipCardComponent, MembershipSelectionComponent],
})
export class SamiPricingTableModule {}
