import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-text-display',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './text-display.component.html'
})
export class TextDisplayComponent {
  @Input() label: string | undefined | null;
  @Input() value: string | undefined | null;
}
