import { SamiStyleModel } from './sami-style.model';
import { SamiCenter } from './sami-center.model';

export interface SamiPublicProfile {
  [key: string]: any;
  id?: string;
  displayName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  photoURL?: string;
  profileImagePath?: string;
  phoneNumber?: string;
  website?: string;
  thumbnail?: string;
  description?: string;
  videoId?: string;
  center?: {
    id?: string;
    name?: string;
    role?: string;
    slug?: string;
  };
  address?: string;
  addressComponents?: any;
  place_id?: any;
  geolocation?:
    | {
        lat?: any;
        lng?: any;
        point?: any;
      }
    | any;
  social?: {
    facebook?: string;
    instagram?: string;
    linkedin?: string;
    whatsapp?: string;
  };
  role?: 'instructor' | 'multiplier' | 'user';
  gender?: 'male' | 'female';
  dateOfBirth?: Date | any;
  locale?: string;
  country?: {
    name?: string;
    code?: string;
  };

  styleLevels?: {
    style?: SamiStyleModel;
    level?: number;
    history?: {
      center?: SamiCenter;
      instructor?: { id: string; name: string; center: SamiCenter };
      level?: number;
      date?: Date | any;
    }[];
  }[];
  styleExams?: {
    style?: SamiStyleModel;
    exam?: number;
    history?: {
      center?: SamiCenter;
      instructor?: { id: string; name: string; center: SamiCenter };
      exam?: number;
      date?: Date | any;
    }[];
  }[];

  isPublic?: boolean;
  isHiddenOnCenterPage?: boolean;
  instructorRank?: number;

  slug?: string;
  seo?: {
    title?: string;
    description?: string;
  }
}
