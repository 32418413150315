import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, finalize, lastValueFrom, Observable } from 'rxjs';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NgxDropzoneComponent, NgxDropzoneModule } from 'ngx-dropzone';
import { ImagePipe, SamiButtonComponent } from '@sami/ui';
import { arrayUnion } from '@firebase/firestore';

@Component({
  selector: 'app-uploader',
  standalone: true,
  imports: [CommonModule, NgxDropzoneModule, ImagePipe, SamiButtonComponent ],
  templateUrl: './uploader.component.html'
})
export class UploaderComponent {
  @Input() storedFiles: { url: string, path: string, type: string, name: string }[] = [];

  @Input() storageCollection!: string | undefined; // path to the last collection where the document should be stored in. should end without a slash
  @Input() upsertPath!: string | undefined; // path to the document where the url of the uploaded file should be stored in. should end without a slash

  @Input() hint = 'Recommended image size: 2160 x 1080px | Maximum file size: 10MB | Supported image files: JPEG or PNG';
  @Input() placeholder = 'Drop image or click to select';

  @Input() allowRemove = true;
  @Input() type: 'image' | 'file' | 'video' = 'image';
  @Input() allowMultiple = false;
  @Input() key: string | undefined;

  @Input() allowRemoveAfterUpload = true;

  @Output() finishedUpload = new EventEmitter();

  files: File[] = [];
  uploadPercent$: Observable<number | undefined> = new Observable();
  isBusy$ = new BehaviorSubject<boolean>(false);

  constructor(
    private storage: AngularFireStorage,
    private afs: AngularFirestore
  ) {}

  uploadFiles() {
    this.isBusy$.next(true);
    if (this.files && this.files.length > 0) {
      let i = this.files.length;
      while (i--) {
        const file = this.files[i];
        const filePath = `${this.storageCollection}/${file.name}`;
        const fileRef: AngularFireStorageReference = this.storage.ref(filePath);
        const task = this.storage.upload(filePath, file);
        this.uploadPercent$ = task.percentageChanges();
        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              const url$ = fileRef.getDownloadURL();
              this.addImage(url$, filePath, file.type, file.name);
              this.files.splice(i, 1);
              if (this.files.length === 0) {
                this.isBusy$.next(false);
                this.uploadPercent$ = new Observable();
                this.finishedUpload.emit();
              }
            })
          )
          .subscribe();
      }
    }
  }
  onSelect(event: { addedFiles: File[]; rejectedFiles: File[], source: NgxDropzoneComponent }) {
    this.files.push(...event.addedFiles);
  }

  acceptFileTypes() {
    switch (this.type) {
      case 'image':
        return 'image/*';
      case 'file':
        return 'application/pdf';
      case 'video':
        return 'video/*';
    }
  }

  onRemove(event: File) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  async addImage(url$: Observable<string>, filePath: string, fileType: string, fileName: string) {
    const url = await lastValueFrom(url$);
    if (this.files && this.key) {

      const file = {
        url: url,
        type: fileType,
        path: filePath,
        name: fileName
      };

      this.afs.doc(`${this.upsertPath}`).set({
        [this.key]: arrayUnion(file),
      }, { merge: true });
    }
  }

  async removeFile(index: number) {
    if (this.upsertPath && this.key) {
      const file = this.storedFiles[index];
      await this.storage.ref(file.path).delete();
      this.storedFiles.splice(index, 1);
      this.afs.doc(`${this.upsertPath}`).set({
        [this.key]: this.storedFiles,
      }, { merge: true });
    }
  }
}
