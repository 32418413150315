
export interface Track {
  [key: string]: any;
  _id?: string;
  title?: string;
  subtitle?: string;
  releaseDate?: any;
  excerpt?: string;
  description?: string;
  system?: string;
  progress?: number;
  level?: number;
  numberOfLiveTrainingsToPass?: number;
  showProgress?: boolean; // Im hintergrund wird der progress trotzdem berechnet, er wird nur nicht angezeigt
  certificateBadgePath?: string;
  diplomaTrackBadgePath?: string;
  statementOfAccomplishmentBadgePath?: string;
  category?: 'SAMI-X Defense' | 'SAMI-X Fighting Arts' | 'SAMI-X Pro';
  drippingEnabled?: boolean;
  chapters?: {
    finished?: boolean;
    id?: string;
    lessonIds?: string[];
    isFree?: boolean;
    hasAccess?: boolean;
    isAvailable?: boolean;
    lessons?: {
      id?: string;
      title?: string;
      type?: string;
      isFreePreviewLesson?: boolean;
      hasAccess?: boolean;
    }[];
    muxPlaybackId?: string;
    thumbnail?: string;
    elements?: {
      thumbnails?: any;
      ref?: any;
      title?: string;
      type?: 'playlist' | 'video';
      finished?: boolean;
    }[];
    title?: string;
    trackId?: string;
    previousChapterId?: string;
    nextChapterId?: string;
    description?: string;
    availableAfterWeeks?: number;
  }[];
  status?: string;
  playlistClipIds?: string[];
  videoElementIds?: string[];
  lessonIds?: string[];
  chapterIds?: string[];
  clipIds?: string[];
  goal?: 'exam' | 'badge' | 'training_collection' | 'instructor_rank';
  goalFulfilled?: boolean;
  showPrivateSession?: boolean;
  thumbnail?: string;
  thumbnailPath?: string;
  heroPath?: string;
  success_message?: string;
  supervisors?: {
    id?: string;
    displayName?: string;
  }[];
  instructorId?: string;
  faqs?: {
    question?: string;
    answer?: string;
  }[];
  requirementsToPass?: string;
  longDescription?: string;
  assignments?: any[];
  products?: any[];
  downloads?: any[];
  startedExamPath?: boolean;
  instructor?: {
    displayName?: string;
    photoURL?: string;
    description?: string;
    subtitle?: string;
    id?: string;
  };
  duration?: number;
  sites?: string[]; // Array of sites where the track is available
  numberOfVideos?: number;
  trackAccessRules?: {
    purchasable?: boolean;
    companyAccess?: string[];
    subscriptionAccess?: {
      plus?: boolean;
      basic?: boolean;
      instructor?: boolean;
    };
    styleLevels?: any;
    styleExams?: any;
  };
  userProgress?: TrackUserProgress;
  userCurrentWeek?: number;
  userStatus?: 'active' | 'paused' | 'finished' | 'inactive' | 'exam_passed';
  hasAccess?: boolean;
  hasPurchased?: boolean;
}

export interface TrackContent {
  lessons?: number;
  title?: string;
  mainImagePath?: string;
  system?: string;
  description?: string;
  assignments?: string;
  chapters?: {
    id?: string;
    title?: string;
    isFree?: boolean;
    description?: string;
    lessons?: number;
    thumbnail?: string;
  }[];
  instructors?: {
    thumbnailPath?: string;
    name?: string;
    description?: string;
    subtitle?: string;
  }[];
  faqs?: {
    question: string;
    answer: string;
  }[];
  diplomaTrackBadgePath?: string;
  certificateBadgePath?: string;
  assignmentSections?: {

  }[];
  trackStructureRef?: string;
  duration?: number;
  level?: number;
}

export interface TrackCard {
  _id: string;
  title: string;
  category: 'SAMI-X Defense' | 'SAMI-X Fighting Arts' | 'SAMI-X Pro' | 'misc';
  system: string; // combat systems or 'misc'
  excerpt: string;
  horizontalThumbnail: string; // URL or path to the horizontal thumbnail
  verticalThumbnail: string; // URL or path to the vertical thumbnail
  level: number; // Level of the track (e.g., 1-12)
  duration: number; // Duration in appropriate units (e.g., seconds or minutes)
  numberOfVideos: number;
  releaseDate: Date; // Release date of the track
  certificationEnabled: boolean; // Whether certification is enabled for the track
  sites: string[]; // Array of sites where the track is available
  status: 'public' | 'private' | 'draft'; // Status of the track
}

export interface TrackChapter {
  availableAfterWeeks?: number;
  isFree?: boolean;
  userCurrentWeek?: number;
  description?: string;
  finished?: boolean;
  id?: string;
  lessonIds?: string[];
  lessons?: TrackLesson[];
  muxPlaybackId?: string;
  nextChapterId?: string;
  previousChapterId?: string;
  thumbnail?: string;
  title?: string;
  trackId?: string;
  hasAccess?: boolean;
}

export interface TrackLesson {
  section?: string;
  chapterId?: string;
  description?: string;
  id?: string;
  title?: string;
  trackId?: string;
  type?: string;
  vimeoId?: string;
  removeMarginsInPlayer?: boolean;
  ref?: any;
  thumbnails?: {
    big?: string;
    small?: string;
    bigWithPlayButton?: string;
    smallWithPlayButton?: string;
  };
  muxVideoElement?: any;
  makePDFContentDownloadable: boolean;
  isPrerequisite: boolean;
  isFreePreviewLesson: boolean;
  enableDiscussionForThisLesson: boolean;
  downloadPath: string;
  nextLessonId?: string;
  nextChapterId?: string;
  previousLessonId: string;
  finished?: boolean;
  assignmentId?: string;
  hasAccess?: boolean;
}

export interface TrackUserProgress {
  [key: string]: any;
  assignmentsPassed?: number;
  assignmentsToComplete?: number;
  assignmentsStatus?: 'not_applicable' | 'not_completed' | 'completed';

  liveTrainingsPassed?: number;
  liveTrainingsToComplete?: number;
  liveTrainingsStatus?: 'not_applicable' | 'not_completed' | 'completed';

  completed?: boolean;
  completedAt?: Date | any;
  createdAt?: Date | any;
  expired?: boolean;
  expiredAt?: Date | any;

  finishedLessons?: string[];
  finsishedChapters?: string[];
  lastWatchedChapter?: string;
  lastWatchedLesson?: string;

  progress?: number;

  startedExamPath?: boolean;
  examPassed?: boolean;

  status?: 'active' | 'paused' | 'finished' | 'inactive' | 'exam_passed';

  trackId?: string;
  trackTitle?: string;

  update?: boolean;
  updatedAt?: Date | any;

  badgesEarned?: any;
}

export interface Homework {
  [key: string]: any;
  video?: string;
  videoLibraryRef?: string;
  title?: string;
  description?: string;
  courseId?: string;
  courseRef?: string;
}

// Sollte ein Document in einer Subcollection erstellt werden --> Homeworks / UID / Assignemnts. In Academy / Track sollte es im Baum gespeichert werden aber mit Ref!
export interface Assignment {
  title: string;
  status: 'review' | 'passed' | 'rejected';
  file: string;
  submissionDate: Date;
  studentName: string;
  homeworkName: string;
  homeworkRef: string;
  course: string;
  description?: string;
  referenceSystemsLibraryVideoRef?: any
  studentId: string;
  studentEmail: string;
  lesson?: {
    id: string;
    title: string;
    chapterId: string;
  }
  chapter?: {
    id: string;
    title: string;
  }
  evaluation?: {
    audio?: string;
    video?: string;
    text?: string;
  };
}
