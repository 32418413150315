import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from 'interfaces';

interface MetaTags {
  title?: string;
  description?: string;
  image?: string;
  contentType?: string;
  url?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    private router: Router,
    private title: Title,
    private meta: Meta
  ) {}

  generateTags(tags: MetaTags) {

    console.log(tags);

    const defaultTitle = "SAMI-X | Learn how to fight";
    const defaultDescription = "SAMI-X Online is the leading learning and training platform for self-defense and martial arts.";
    const defaultImage = "https://a.storyblok.com/f/110844/1200x628/523bf409b4/meta_image.png";

    const title = tags.title ?? defaultTitle;
    const description = tags.description ?? defaultDescription;
    const image = tags.image ?? defaultImage;
    const type = tags.contentType ?? 'website';
    const url = tags.url ?? environment.url + '' + this.router.url;

    // Standard Meta Tags
    this.title.setTitle(title);
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({ name: 'title', content: title });

    // Open Graph Tags
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ property: 'og:url',content: url });
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ property: 'og:image', content: image });
    this.meta.updateTag({ property: 'og:type', content: type });

    // Twitter Card Tags
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:title', content: title });
    this.meta.updateTag({ name: 'twitter:description', content: description });
    this.meta.updateTag({ name: 'twitter:image', content: image });
  }

}
