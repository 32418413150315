import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UiService } from '../services/ui.service';

@Pipe({
  name: 'dateLocale',
  standalone: true,
})
export class DateLocalePipe implements PipeTransform {
  constructor(
    private ui: UiService,
  ) { }
  transform(date: Date, pattern: string): unknown {
    let userLocale = navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;

    if (!this.ui.locales.includes(userLocale)) {
      const next = this.ui.locales.find((l) => l.startsWith(userLocale));
      if (next) {
        userLocale = next;
      } else {
        userLocale = 'en-US';
      }
    }

    return new DatePipe(userLocale).transform(date, pattern);
  }

}
