import { Component } from '@angular/core';

@Component({
  selector: 'app-tabbar',
  templateUrl: './tabbar.component.html'
})
export class TabbarComponent {
  constructor(
  ) {
  }

}
