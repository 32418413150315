import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StartsWithPipe } from './startsWith.pipe';

@NgModule({
  declarations: [StartsWithPipe],
  imports: [CommonModule],
  exports: [StartsWithPipe],
})
export class StartsWithModule {}
