export interface SamiUserBadge {
  userId: string;
  trackId: string;
  identifier: string;
  imgPath: string;
  title: string; // kann dem titel vom track entsprechen
  system: string;
  level: number;
  createdAt: Date | number;
}
