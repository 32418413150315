import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import { SamiUser } from 'interfaces';
import { SamiSubscription } from 'interfaces';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Router } from '@angular/router';
import { DowngradeDialogComponent } from './downgrade-dialog/downgrade-dialog.component';
import { OffboardingProcessComponent } from './offboarding-process/offboarding-process.component';
import { UpgradeDialogComponent } from './upgrade-dialog/upgrade-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PaymentService } from '@sami/features';
import { isPlatformBrowser } from '@angular/common';


@Component({
  selector: 'app-membership-card',
  templateUrl: './membership-card.component.html'
})
export class MembershipCardComponent implements OnInit {
  @Input() hasSubscription = false;
  @Input() hadSubscription = false;
  @Input() hasInstructorMembership = false;
  @Input() hasBasicMembership = false;
  @Input() hasPlusMembership = false;
  @Input() user!: SamiUser;
  @Input() location: any;
  @Input() samiInternationalPricing: any;
  @Input() samiXPricingYear: any;
  @Input() samiXPricingMonth: any;
  @Input() background: 'white' | 'culturedWhite' = 'culturedWhite';

  activeSubscriptions$: Observable<any> | undefined;
  activePlusSubscriptions$: Observable<any> | undefined;
  activeBasicSubscriptions$: Observable<any> | undefined;

  loading = false;

  constructor(
    private afs: AngularFirestore,
    private dialog: MatDialog,
    private analytics: AngularFireAnalytics,
    private aff: AngularFireFunctions,
    private payment: PaymentService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {}

  ngOnInit(): void {
    if (this.user && this.user.uid) {
      this.activeSubscriptions$ = this.samiInternationalPricing
        ? this.afs
            .collection('users/' + this.user.uid + '/subscriptions', (ref) =>
              ref
                .where('subscriptionPlan', '==', 'basic')
                .where('status', '==', 'active')
            )
            .valueChanges({ idField: 'id' })
        : this.afs
            .collection('users/' + this.user.uid + '/subscriptions', (ref) =>
              ref
                .where('subscriptionPlan', '==', 'plus')
                .where('status', 'in', ['active', 'trialing'])
            )
            .valueChanges({ idField: 'id' });
      this.activeBasicSubscriptions$ = this.afs
        .collection('users/' + this.user.uid + '/subscriptions', (ref) =>
          ref
            .where('subscriptionPlan', '==', 'basic')
            .where('status', '==', 'active')
        )
        .valueChanges({ idField: 'id' });
      this.activePlusSubscriptions$ = this.afs
        .collection('users/' + this.user.uid + '/subscriptions', (ref) =>
          ref
            .where('subscriptionPlan', '==', 'plus')
            .where('status', 'in', ['active', 'trialing'])
        )
        .valueChanges({ idField: 'id' });
    }
  }

  async onCancelSubscription(subscription: SamiSubscription) {
    this.dialog.open(OffboardingProcessComponent, {
      data: {
        subscription,
      },
      panelClass: 'sami-dialog',
      maxWidth: '800px',
      width: '80vw',
    });
  }

  async onDowngradeSubscription(
    membership: any,
    subscriptions: SamiSubscription[]
  ) {
    this.dialog.open(DowngradeDialogComponent, {
      data: {
        subscription: subscriptions[0],
        priceId: membership.price_id ? membership.price_id : membership.id,
      },
      panelClass: 'sami-dialog',
      maxWidth: '800px',
      width: '80vw',
    });
  }

  async onUpgradeSubscription(
    monthlyPrice: any,
    yearlyPrice: any,
    subscriptions: SamiSubscription,
    location: any
  ) {
    this.dialog.open(UpgradeDialogComponent, {
      data: {
        subscription: subscriptions[0],
        location,
        monthlyPrice,
        yearlyPrice,
        priceId: monthlyPrice.price_id
          ? monthlyPrice.price_id
          : monthlyPrice.id,
      },
      panelClass: 'sami-dialog',
      maxWidth: '800px',
      width: '80vw',
    });
  }

  async onReactivateSubscription(subscription: SamiSubscription) {
    this.loading = true;
    try {
      await lastValueFrom(
        this.aff.httpsCallable('toggleEndOfSubscription')({
          subscriptionId: subscription.subscriptionId,
          endSubscription: false,
        })
      );

      await this.analytics.logEvent('reactivate-membership');

      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  }

  async getMembership(
    user: SamiUser,
    membership: any,
    type: 'plus' | 'basic',
    currency: string,
    hadSubscription = true
  ) {
    this.loading = true;
    if (isPlatformBrowser(this.platformId)) {
      if (user.stripeCustomerId) {
        try {
          const checkout = await this.payment.generateCheckoutSession({
            customerId: user.stripeCustomerId,
            priceId: membership.price_id ? membership.price_id : membership.id,
            currency: currency,
            trialPeriodDays: hadSubscription ? 0 : 7,
            productId:
              membership.product && membership.product.id
                ? membership.product?.id
                : membership.product,
            couponCode: null,
            metadata: {
              type: 'subscription',
            },
            ...type === 'plus' && {
              systems: 'stick,axe,kravMaga,panantukan,knife'
            },
            accountId: null,
            taxRate: null,
            successUrl: window.location.origin + '/app/home/membership',
            cancelUrl: window.location.origin + '/app/home/membership',
            returnUrl: window.location.origin + '/app/home/membership',
          });

          this.loading = false;
          if (checkout && checkout.url) {
            window.location.href = checkout.url;
          }
        } catch (error) {
          this.loading = false;
          console.error(error);
        }
      }
    }
  }

  async onRedirectToPortal() {
    if (isPlatformBrowser(this.platformId)) {

      try {
        this.loading = true;
        const stripeCheckout: any = await firstValueFrom(
          this.aff.httpsCallable('getStripeSession')({
            return_url: `${window.location.origin}/${this.router.url}`,
            mode: 'portal',
          })
        );
        if (stripeCheckout && stripeCheckout.id) {
          (window as any).open(stripeCheckout.url, '_blank');
        }
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    }
  }

  getBackgroundColor() {
    if (this.background === 'white' && !this.hasSubscription && (!this.samiInternationalPricing && !this.hasInstructorMembership)) {
      return 'bg-white';
    } else if (this.background === 'culturedWhite' && !this.hasSubscription && (!this.samiInternationalPricing && !this.hasInstructorMembership)) {
      return 'bg-culturedWhite';
    } else if (this.hasSubscription || (!this.hasSubscription && this.samiInternationalPricing && this.hasInstructorMembership)) {
      return 'bg-richBlack text-white';
    } else {
      return 'bg-culturedWhite';
    }
  }
}
