import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UiService } from '@sami/features';
import { ImagePipe } from '../../../pipes/transformImage.pipe';

@Component({
  selector: 'app-certification-modal',
  templateUrl: './certification-modal.component.html',
  standalone: true,
  imports: [ CommonModule, ImagePipe ]
})
export class CertificationModalComponent implements OnInit {
  system = 'kravMaga';

  constructor(
    private ui: UiService,
    public dialogRef: MatDialogRef<CertificationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  returnSystemComponent(
    identifier:
      | 'kravMaga'
      | 'panantukan'
      | 'axe'
      | 'stick'
      | 'knife'
      | 'ebc'
      | 'kapap'
      | 'core'
      | string,
    type: 'title' | 'color' | 'category'
  ) {
    return this.ui.samiSystems[identifier][type] || 'fightingRed-700';
  }

  goToAssignments() {
    this.dialogRef.close('assignments');
  }
}
