import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, LocationService } from '@sami/features';
import { SamiIconsRegistry, close } from '@sami/ui';
import { MenuService } from '../../menu.service';
import { SamiUser } from 'interfaces';


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html'
})
export class SidenavComponent {
  @Input() currentSite: 'sami-international' | 'sami-x' | 'sami-x-app' | 'admin' | 'b2b' | 'instructor-area' | 'shop' = 'sami-international';
  @Input() isMobile: boolean | null = false;
  @Input() user: SamiUser | undefined;
  @Input() isSmartphone: boolean | null = false;
  @Input() backgroundColor: 'white' | 'black' = 'white';

  @Output() toggleSidenav: EventEmitter<void> = new EventEmitter<void>();

  isPromoActive: boolean;

  constructor(
    public menuService: MenuService,
    private samiIconRegistry: SamiIconsRegistry,
    public location: LocationService,
    public router: Router,
    public auth: AuthService
  ) {

    const endDate = new Date('2023-11-26T23:50:00');

    // Get the current date
    const currentDate = new Date();

    // Check if current date is before the end date
    this.isPromoActive = currentDate < endDate;

    this.samiIconRegistry.registerIcons([
      close
    ]);
  }
}
