import { Pipe, PipeTransform } from '@angular/core';

@Pipe(
  {
    name: 'transformImg',
    standalone: true
  }

  )
export class ImagePipe implements PipeTransform {
  transform(
    image: string,
    type: 'storyblok' | 'firestore' | 'imagekit',
    option?: string
  ): any {
    if (!image) return '';

    if (type === 'storyblok') {
      const imageService = 'https://img2.storyblok.com/';
      const path = image.replace('https://a.storyblok.com', '');
      return imageService + option + path;
    } else if (type === 'firestore' ) {
      if (image.includes('firebasestorage')) {
        if (option) {
          const url = image.replace(
            'https://firebasestorage.googleapis.com',
            'https://ik.imagekit.io/samix'
          );
          return url + '&tr=' + option;
        } else {
          return image.replace(
            'https://firebasestorage.googleapis.com',
            'https://ik.imagekit.io/samix'
          );
        }
      } else {
        return image;
      }
    } else {
      if (image.includes('firebasestorage')) {
        if (option) {
          return image.replace(
            'https://firebasestorage.googleapis.com',
            ''
          );
        } else {
          return image.replace(
            'https://firebasestorage.googleapis.com',
            ''
          );
        }
      } else {
        return image;
      }
    }
  }
}
