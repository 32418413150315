import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  combineLatest,
  lastValueFrom,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { AuthService } from './auth.service';
import { arrayUnion, documentId } from '@angular/fire/firestore';
import { Track, Assignment, SamiUser, TrackChapter, TrackLesson, TrackUserProgress, SamiSeminar, SamiSeminarTicket, SamiUserPurchase, TrackCard, SamiTechniqueClip } from 'interfaces';
import { Router } from '@angular/router';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MenuService } from 'header';

@Injectable({
  providedIn: 'root',
})
export class TracksService {
  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private router: Router,
    private analytics: AngularFireAnalytics,
    private menuService: MenuService
  ) { }

  private _mapTrack(track: Track, user: SamiUser, userProgress: any, seminars: SamiSeminar[], tickets: SamiSeminarTicket[], purchases: SamiUserPurchase[]): Track {
    let mappedTrack = { ...track };
    mappedTrack = this._mapTrackUserProgress(mappedTrack, user, userProgress);
    mappedTrack = this._mapTrackAccess(mappedTrack, user, seminars, tickets, purchases);
    return mappedTrack;
  }

  private _mapTrackAccess(track: Track, user: SamiUser, seminars: SamiSeminar[], tickets: SamiSeminarTicket[], purchases: SamiUserPurchase[]): Track {
    let fulfilled_1 = false, fulfilled_2 = false;

    if (!track.trackAccessRules) {
      fulfilled_1 = true;
    }

    if (track._id && user.trackAccess?.includes(track._id)) {
      fulfilled_1 = true;
    }

    if (
      user.company?.id &&
      track.trackAccessRules?.companyAccess?.includes(user.company?.id)
    ) {
      fulfilled_1 = true;
    }

    if (
      track.trackAccessRules?.subscriptionAccess?.plus &&
      user.subscriptionDigest?.hasSubscription?.plus
    ) {
      fulfilled_1 = true;
    }

    if (
      track.trackAccessRules?.subscriptionAccess?.basic &&
      user.subscriptionDigest?.hasSubscription?.basic
    ) {
      fulfilled_1 = true;
    }

    if (
      track.trackAccessRules?.subscriptionAccess?.instructor &&
      user.subscriptionDigest?.hasSubscription?.instructor
    ) {
      fulfilled_1 = true;
    }

    if (seminars.length && tickets.length) {
      for (const ticket of tickets) {
        const ticketSeminar = seminars.find((seminar: SamiSeminar) => seminar['_id'] === ticket.seminarId);
        const seminarTrack = ticketSeminar?.tracks?.find((t) => t.id === track._id);
        if (seminarTrack?.ticketTypeIds?.includes(ticket.ticketStripePriceId ?? '')) {
          fulfilled_1 = true;
          break;
        }
      }
    }

    if (
      (track.trackAccessRules?.styleLevels && Object.entries(track.trackAccessRules?.styleLevels).length <= 0) &&
      (track.trackAccessRules?.styleExams && Object.entries(track.trackAccessRules?.styleExams).length <= 0)
    ) {
      fulfilled_2 = true;
    }

    if (track.trackAccessRules?.styleLevels) {
      for (const [style, level] of Object.entries(track.trackAccessRules.styleLevels)) {
        const userStyleLevel = user.styleLevels?.find((sl) => sl?.style?.identifier === style);
        if (userStyleLevel && (userStyleLevel.level ?? 0) >= (level as number)) {
          fulfilled_2 = true;
          break;
        }
      }
    }

    if (track.trackAccessRules?.styleExams) {
      for (const [style, exam] of Object.entries(track.trackAccessRules.styleExams)) {
        const userStyleExam = user.styleExams?.find((se) => se?.style?.identifier === style);
        if (userStyleExam && (userStyleExam.exam ?? 0) >= (exam as number)) {
          fulfilled_2 = true;
          break;
        }
      }
    }

    const hasPurchased = !!purchases.find((purchase: SamiUserPurchase) => purchase.docId === track._id);
    const hasTrackAccess = hasPurchased || (fulfilled_1 && fulfilled_2) || false;

    const chapters = track.chapters?.map((chapter) => {
      const lessons = chapter.lessons?.map((lesson) => ({
        ...lesson,
        hasAccess: hasTrackAccess || chapter.isFree || lesson.isFreePreviewLesson || false,
      }));

      return {
        ...chapter,
        lessons,
        hasAccess: lessons?.some((lesson) => lesson.hasAccess) || false,
        isAvailable: (chapter.availableAfterWeeks)
          ? (track.userCurrentWeek ? track.userCurrentWeek : 0) >= chapter.availableAfterWeeks
          : true,
      }
    });

    return {
      ...track,
      chapters,
      hasPurchased,
      hasAccess: chapters?.some((chapter) => chapter.hasAccess) || false,
      isAvailable: chapters?.some((chapter) => chapter.isAvailable) || false,
    };
  }

  private _mapTrackUserProgress(track: Track, user: SamiUser, userProgress: any): Track {
    const chapters = track.chapters?.map((chapter) => {
      const lessons = chapter.lessons?.map((lesson) => ({
        ...lesson,
        finished: userProgress?.finishedLessons?.includes(lesson.id) || false,
      }));
      return {
        ...chapter,
        lessons,
        finished: lessons?.every((lesson) => lesson.finished) || false,
      }
    });

    let userStatus: string | undefined = undefined;
    let goalFulfilled = false;

    if (track?.level && (track?.goal === 'exam')) {
      const userHasStyleLevel = user?.styleLevels?.find((e) => e?.style?.identifier === track?.system);
      if (userHasStyleLevel?.level) {
        goalFulfilled = (userHasStyleLevel?.level ?? 0) >= track?.level;
        if (goalFulfilled) {
          userStatus = 'exam_passed';
        }
      }
    } else if (track?.level && (track?.goal === 'instructor_rank')) {
      const userHasStyleExam = user?.styleExams?.find((e) => e?.style?.identifier === track?.system);
      if (userHasStyleExam?.exam) {
        goalFulfilled = (userHasStyleExam?.exam ?? 0) >= track?.level;
        if (goalFulfilled) {
          userStatus = 'exam_passed';
        }
      }
    }

    return {
      ...track,
      finishedLessons: userProgress?.finishedLessons || 0,
      finishedChapters: userProgress?.finishedChapters || 0,
      completed: userProgress?.completed || false,
      startedExamPath: userProgress?.startedExamPath || false,
      completedAt: userProgress?.completedAt || null,
      progress: userProgress?.progress || 0,
      assignmentsPassed: userProgress?.assignmentsPassed || 0,
      liveTrainingsPassed: userProgress?.liveTrainingsPassed || 0,
      userStatus: userStatus || userProgress?.status || 'inactive',
      userCurrentWeek: this.getWeeksDiff(userProgress?.createdAt?.toDate() || new Date(), new Date()),
      goalFulfilled,
      userProgress,
      chapters,
    }
  }

  public getTrack$(trackId: string): Observable<Track | undefined> {
    if (!trackId) {
      return of(undefined);
    }
    return combineLatest([
      this.afs.doc<Track>(`tracks/${trackId}`).valueChanges({ idField: '_id' }),
      this.auth.user$,
      this.auth.authUser$.pipe(
        switchMap((authUser: any) => authUser?.uid
          ? this.afs.doc<any>(`tracks/${trackId}/trackProgress/${authUser?.uid}`).valueChanges({ idField: '_id' })
          : of(null)
        )
      ),
      this.afs.collection<SamiSeminar[]>(`seminars`, ref => ref.where('trackIds', 'array-contains', trackId)).valueChanges({ idField: '_id' }),
      this.auth.getUserPurchases$('tracks'),
    ]).pipe(
      switchMap(([track, user, userProgress, seminars, purchases]) => {
        if (user?.uid && seminars?.length) {
          return this.afs.collectionGroup<SamiSeminarTicket[]>('seminarTickets', ref =>
            ref.where('seminarId', 'in', seminars.map((seminar: SamiSeminar) => seminar['_id'])).where('userId', '==', user.uid)
          ).valueChanges({ idField: '_id' }).pipe(
            map((tickets) => [track, user, userProgress, seminars, tickets, purchases])
          )
        } else {
          return of([track, user, userProgress, seminars, [], purchases]);
        }
      }),
      map(([track, user, userProgress, seminars, tickets, purchases]) => track && this._mapTrack(track, user, userProgress, seminars, tickets, purchases))
    );
  }

  public getCertificationTracks$(limit?: number): Observable<any> {
    return combineLatest([
      this.auth.authUser$.pipe(
        switchMap((authUser: any) => authUser?.uid
          ? this.getUserTrackProgress$(authUser.uid)
          : of(null)
        )
      ),
      this.afs.collection<Track[]>(`tracks`, (ref) => ref
        .where('goal', '==', 'exam')
        .where('status', '==', 'public')
        .orderBy('releaseDate', 'asc')
      )
        .valueChanges({ idField: '_id' }),
    ]).pipe(
      map(([userProgress, tracks]: any) => {
        if (userProgress) {
          userProgress.forEach((progressElement: any) => {
            const index = tracks.findIndex((el: any) => el._id === progressElement.trackId);
            if (index > -1) {
              tracks[index] = {
                ...tracks[index],
                progress: progressElement.progress,
                completed: progressElement.completed,
                expired: progressElement.expired,
                userCurrentWeek: this.getWeeksDiff(
                  userProgress.createdAt,
                  new Date()
                ),
              };
            }
          });
        }
        return tracks;
      }),
      shareReplay(1)
    );
  }

  public getCertificationTrackCards$(limit?: number): Observable<any> {
    return combineLatest([
      this.auth.authUser$.pipe(
        switchMap((authUser: any) => authUser?.uid
          ? this.getUserTrackProgress$(authUser.uid)
          : of(null)
        )
      ),
      this.afs.collection<TrackCard[]>(`trackCards`, (ref) => ref
        .where('certificationEnabled', '==', true)
        .where('status', '==', 'public')
        .where('sites', 'array-contains', 'sami-x-app')
        .orderBy('releaseDate', 'asc')
      )
        .valueChanges({ idField: '_id' }),
    ]).pipe(
      map(([userProgress, trackCards]: any) => {
        if (userProgress) {
          userProgress.forEach((progressElement: any) => {
            const index = trackCards.findIndex((el: any) => el._id === progressElement.trackId);
            if (index > -1) {
              trackCards[index] = {
                ...trackCards[index],
                progress: progressElement.progress,
                completed: progressElement.completed,
                expired: progressElement.expired,
                userCurrentWeek: this.getWeeksDiff(
                  userProgress.createdAt,
                  new Date()
                ),
              };
            }
          });
        }
        return trackCards;
      }),
      shareReplay(1)
    );
  }

  public getActiveUserTracks$(): Observable<any[]> {
    return this.auth.authUser$.pipe(
      switchMap((authUser: any) => authUser?.uid
        ? this.getUserTrackProgress$(authUser.uid).pipe(
          switchMap((userProgress: any) => userProgress
            ? combineLatest(
              userProgress.map((progress: any) =>
                this.getTrack$(progress.trackId).pipe(
                  map((track: any) => ({
                    ...track,
                    progress: progress.progress,
                    completed: progress.completed,
                    expired: progress.expired,
                    userCurrentWeek: this.getWeeksDiff(
                      progress.createdAt,
                      new Date()
                    ),
                  }))
                )
              )
            )
            : of([])
          )
        )
        : of([])
      )
    )
      .pipe(
        map((tracks: any) => tracks.filter((track: Track) => track.progress && track.progress >= 0 && track.progress < 1))
      );
  }

  public getTrainingCollectionTracks$(system?: string): Observable<any[]> {
    return combineLatest([
      this.auth.authUser$.pipe(
        switchMap((authUser: any) => authUser?.uid
          ? this.getUserTrackProgress$(authUser.uid)
          : of(null)
        )
      ),
      this.afs.collection<Track[]>(`tracks`, (ref) => ref
        .where('goal', '==', 'training_collection')
        .where('status', '==', 'public')
        .where('system', '==', system)
      )
        .valueChanges({ idField: '_id' }),
    ]).pipe(
      map(([userProgress, tracks]: any) => {
        if (userProgress) {
          userProgress.forEach((progressElement: any) => {
            const index = tracks.findIndex((el: any) => el._id === progressElement.trackId);
            if (index > -1) {
              tracks[index] = {
                ...tracks[index],
                progress: progressElement.progress,
                completed: progressElement.completed,
                expired: progressElement.expired,
                userCurrentWeek: this.getWeeksDiff(
                  userProgress.createdAt,
                  new Date()
                ),
              };
            }
          });
        }
        return tracks;
      })
    );
  }

  public getPurchasedTracks(trackIds: string[]): Observable<any> {
    return combineLatest([
      this.auth.authUser$.pipe(
        switchMap((authUser: any) => authUser?.uid
          ? this.getUserTrackProgress$(authUser.uid)
          : of(null)
        )
      ),
      this.afs.collection<Track[]>(`tracks`, (ref) => ref.where(documentId(), 'in', trackIds)).valueChanges({ idField: '_id' }),
    ]).pipe(
      map(([userProgress, tracks]: any) => {
        if (userProgress) {
          userProgress.forEach((progressElement: any) => {
            const index = tracks.findIndex((el: any) => el._id === progressElement.trackId);
            if (index > -1) {
              tracks[index] = {
                ...tracks[index],
                progress: progressElement.progress,
                completed: progressElement.completed,
                expired: progressElement.expired,
                userCurrentWeek: this.getWeeksDiff(
                  userProgress.createdAt,
                  new Date()
                ),
              };
            }
          });
        }
        return tracks;
      })
    );
  }

  public getSpecialTracks$(): Observable<any> {
    return combineLatest([
      this.auth.authUser$.pipe(
        switchMap((authUser: any) => authUser?.uid
          ? this.getUserTrackProgress$(authUser.uid)
          : of(null)
        )
      ),
      this.afs.collection<Track[]>(`tracks`, (ref) => ref
        .where('goal', '==', 'badge')
        .where('status', '==', 'public')
        .orderBy('releaseDate', 'asc')
      )
        .valueChanges({ idField: '_id' }),
    ]).pipe(
      map(([userProgress, tracks]: any) => {
        if (userProgress) {
          userProgress.forEach((progressElement: any) => {
            const index = tracks.findIndex((el: any) => el._id === progressElement.trackId);
            if (index > -1) {
              tracks[index] = {
                ...tracks[index],
                progress: progressElement.progress,
                completed: progressElement.completed,
                expired: progressElement.expired,
                userCurrentWeek: this.getWeeksDiff(
                  userProgress.createdAt,
                  new Date()
                ),
              };
            }
          });
        }
        return tracks;
      })
    );
  }

  public getSpecialTrackCards$(): Observable<any> {
    return combineLatest([
      this.auth.authUser$.pipe(
        switchMap((authUser: any) => authUser?.uid
          ? this.getUserTrackProgress$(authUser.uid)
          : of(null)
        )
      ),
      this.afs.collection<TrackCard[]>(`trackCards`, (ref) => ref
        .where('certificationEnabled', '==', false)
        .where('status', '==', 'public')
        .where('sites', 'array-contains', 'sami-x-app')
        .orderBy('releaseDate', 'asc')
      )
        .valueChanges({ idField: '_id' }),
    ]).pipe(
      map(([userProgress, trackCards]: any) => {
        if (userProgress) {
          userProgress.forEach((progressElement: any) => {
            const index = trackCards.findIndex((el: any) => el._id === progressElement.trackId);
            if (index > -1) {
              trackCards[index] = {
                ...trackCards[index],
                progress: progressElement.progress,
                completed: progressElement.completed,
                expired: progressElement.expired,
                userCurrentWeek: this.getWeeksDiff(
                  userProgress.createdAt,
                  new Date()
                ),
              };
            }
          });
        }
        return trackCards;
      }),
      shareReplay(1)
    );
  }

  public getCompanyTracks$(companyId: string): Observable<any> {
    return combineLatest([
      this.auth.authUser$.pipe(
        switchMap((authUser: any) => authUser?.uid
          ? this.getUserTrackProgress$(authUser.uid)
          : of(null)
        )
      ),
      this.afs.collection<Track[]>(`tracks`, (ref) => ref.where(
        'trackAccessRules.companyAccess',
        'array-contains',
        companyId
      )
      )
        .valueChanges({ idField: '_id' }),
    ]).pipe(
      map(([userProgress, tracks]: any) => {
        if (userProgress) {
          userProgress.forEach((progressElement: any) => {
            const index = tracks.findIndex((el: any) => el._id === progressElement.trackId);
            if (index > -1) {
              tracks[index] = {
                ...tracks[index],
                progress: progressElement.progress,
                completed: progressElement.completed,
                expired: progressElement.expired,
                userCurrentWeek: this.getWeeksDiff(
                  userProgress.createdAt,
                  new Date()
                ),
              };
            }
          });
        }
        return tracks;
      })
    );
  }

  public getAssignments$(trackId: string): Observable<any> {
    return combineLatest([
      this.auth.authUser$.pipe(
        switchMap((authUser: any) => authUser?.uid
          ? this.afs.collectionGroup<any[]>(`submissions`, (ref) => ref
            .where('userId', '==', authUser.uid)
            .where('trackId', '==', trackId)
          )
            .valueChanges({ idField: '_id' })
          : of(null)
        )
      ),
      this.afs.collection<Track[]>(`tracks/${trackId}/assignments`).valueChanges({ idField: '_id' }),
    ]).pipe(
      map(([userAssignments, assignments]: any) => {
        if (userAssignments) {
          userAssignments.forEach((userAssignment: any) => {
            const index = assignments.findIndex((el: any) => el._id === userAssignment.assignmentId);
            if (index > -1) {
              assignments[index] = {
                ...assignments[index],
                status: userAssignment.status,
              };
            }
          });
        }
        return assignments;
      })
    );
  }

  public getAssignment$(
    trackId: string,
    assignmentId: string
  ): Observable<any> {
    return combineLatest([
      this.auth.authUser$.pipe(
        switchMap((authUser: any) => authUser?.uid
          ? this.afs.collectionGroup<any[]>(`submissions`, (ref) => ref
            .where('userId', '==', authUser.uid)
            .where('trackId', '==', trackId)
          )
            .valueChanges({ idField: '_id' })
          : of(null)
        )
      ),
      this.afs.doc<Assignment>(`tracks/${trackId}/assignments/${assignmentId}`).valueChanges({ idField: '_id' }),
    ]).pipe(
      map(([userAssignments, assignment]: any) => {
        if (userAssignments) {
          userAssignments.forEach((userAssignment: any) => {
            if (userAssignment.assignmentId === assignmentId) {
              assignment = {
                ...assignment,
                status: userAssignment.status,
              };
            }
          });
        }
        return assignment;
      })
    );
  }

  public getUpcomingLiveSessions$(trackId: string): Observable<any> {
    return this.afs.collection<any>(`liveTrainings`, (ref) =>
      ref
        .where('connectedTrackIds', 'array-contains', trackId)
        .where('endDate', '>=', new Date())
    )
      .valueChanges({ idField: '_id' });
  }

  public getUpcomingExams$(trackId: string): Observable<any> {
    return this.afs.collection<any>(`exams`, (ref) =>
      ref
        .where('connectedTrackIds', 'array-contains', trackId)
        .where('startDate', '>=', new Date())
        .where('type', 'in', ['mixed', 'online'])
        .where('status', '==', 'published')
    )
      .valueChanges({ idField: '_id' });
  }

  public getAttempts$(userId: string, trackId: string, assignmentId: string) {
    return this.afs.collection(
      `tracks/${trackId}/assignments/${assignmentId}/submissions/${userId}/attempts`,
      (ref) => ref.orderBy('createdAt', 'asc')
    )
      .valueChanges({ idField: '_id' });
  }

  public getFAQs$(trackId: string) {
    return this.afs.collection(`tracks/${trackId}/faqs`).valueChanges({ idField: '_id' });
  }

  public getChapter$(trackId: string, chapterId: string) {
    return combineLatest([
      this.getTrack$(trackId),
      this.afs.doc<TrackChapter>(`tracks/${trackId}/chapters/${chapterId}`).valueChanges({ idField: 'id' }),
      this.auth.authUser$.pipe(
        switchMap((authUser: any) => authUser?.uid
          ? this.afs.doc<any>(`tracks/${trackId}/trackProgress/${authUser?.uid}`).valueChanges({ idField: '_id' })
          : of(null)
        )
      ),
    ]).pipe(
      map(([track, chapter, userProgress]) => {
        const lessons: TrackLesson[] = [];
        const trackChapter = track?.chapters?.find((c) => c.id === chapter?.id);

        for (const lesson of chapter?.lessons || []) {
          const trackLesson = trackChapter?.lessons?.find((l) => l.id === lesson.id);
          lessons.push({
            ...trackLesson,
            ...lesson,
            finished: userProgress?.finishedLessons?.includes(lesson.id),
          });
        }

        return {
          ...trackChapter,
          ...chapter,
          lessons,
          finished: lessons.every((lesson: any) => lesson.finished),
          userCurrentWeek: this.getWeeksDiff(userProgress?.createdAt, new Date()),
        };
      }),
      tap((chapter: any) => {
        console.log('chapter', chapter);
      })
    );
  }

  public getLesson$(trackId: string, chapterId: string, lessonId: string) {
    return combineLatest([
      this.getTrack$(trackId),
      this.afs.doc<TrackLesson>(`tracks/${trackId}/chapters/${chapterId}/lessons/${lessonId}`).valueChanges({ idField: 'id' }),
      this.auth.authUser$.pipe(
        switchMap((authUser: any) => authUser?.uid
          ? this.afs.doc<any>(`tracks/${trackId}/trackProgress/${authUser?.uid}`).valueChanges({ idField: '_id' })
          : of(null)
        )
      ),
    ]).pipe(
      map(([track, lesson, userProgress]: any) => {
        const trackChapter = track?.chapters?.find((c: TrackChapter) => c.id === chapterId);
        const trackLesson = trackChapter?.lessons?.find((l: TrackLesson) => l.id === lessonId);
        return {
          ...trackLesson,
          ...lesson,
          finished: userProgress?.finishedLessons?.includes(lesson.id),
        };
      })
    );
  }

  public getDownloads$(trackId: string) {
    return this.afs.collection(`tracks/${trackId}/downloads`).valueChanges({ idField: '_id' });
  }

  public getProducts$(trackId: string) {
    return this.afs.collection(`tracks/${trackId}/products`).valueChanges({ idField: '_id' });
  }

  public async markElementInTrackAsFinished(
    elementId: string,
    trackId: string
  ): Promise<any> {
    const { uid } = await lastValueFrom(this.auth.authUser$.pipe(take(1)));
    const userProgress = await lastValueFrom(
      this.afs
        .doc<TrackUserProgress>(`/tracks/${trackId}/trackProgress/${uid}`)
        .valueChanges({ idField: '_id' })
        .pipe(take(1))
    );
    await this.afs.doc(`/tracks/${trackId}/trackProgress/${uid}`).set(
      {
        finishedElements: Array.from(
          new Set([...(userProgress?.['finishedElements'] || []), elementId])
        ),
        lastWatchedElement: elementId,
        update: true,
      },
      { merge: true }
    );
  }

  public async markLessonInTrackAsFinished(
    lessonId: string,
    chapterId: string,
    trackId: string
  ): Promise<any> {
    const { uid } = await lastValueFrom(this.auth.authUser$.pipe(take(1)));
    const userProgress = await lastValueFrom(
      this.afs
        .doc<TrackUserProgress>(`/tracks/${trackId}/trackProgress/${uid}`)
        .valueChanges({ idField: '_id' })
        .pipe(take(1))
    );
    await this.afs.doc(`/tracks/${trackId}/trackProgress/${uid}`).set(
      {
        finishedLessons: Array.from(
          new Set([...(userProgress?.['finishedLessons'] || []), lessonId])
        ),
        lastWatchedLesson: lessonId,
        lastWatchedChapter: chapterId,
        update: true,
      },
      { merge: true }
    );
  }

  public getUserTrackProgress$(userId: string) {
    return this.afs.collectionGroup('trackProgress', (ref) => ref.where('userId', '==', userId)).valueChanges({ idField: '_id' });
  }

  public async startTrack(trackId: string, userId: string): Promise<any> {
    await this.afs.doc(`/tracks/${trackId}/trackProgress/${userId}`).set(
      {
        trackId: trackId,
        completed: false,
        progress: 0,
        completedAt: null,
        status: 'active',
        update: true,
        finishedElements: [],
        lastWatchedElement: null,
      },
      { merge: true }
    );

    await this.afs.doc('/users/' + userId).set(
      {
        lastActiveTrackId: trackId,
      },
      { merge: true }
    );
  }

  public async pauseTrack(trackId: string, userId: string): Promise<any> {
    await this.afs.doc(`/tracks/${trackId}/trackProgress/${userId}`).set(
      {
        status: 'paused',
      },
      { merge: true }
    );
  }

  public async resumeTrack(trackId: string, userId: string): Promise<any> {
    await Promise.all([
      this.afs.doc(`/tracks/${trackId}/trackProgress/${userId}`).set(
        {
          status: 'active',
          update: true,
        },
        { merge: true }
      ),
      this.afs.doc('/users/' + userId).set(
        {
          lastActiveTrackId: trackId,
        },
        { merge: true }
      ),
    ]);
  }

  public async unlockExamPath(trackId: string, userId: string): Promise<any> {
    await this.afs.doc(`/tracks/${trackId}/trackProgress/${userId}`).set(
      {
        startedExamPath: true,
        update: true,
      },
      { merge: true }
    );
  }

  getWeeksDiff(startDate: any, endDate: any) {
    const msInWeek = 1000 * 60 * 60 * 24 * 7;
    return Math.round(Math.abs(endDate - startDate) / msInWeek);
  }

  addWeeks(numOfWeeks: number, date = new Date()) {
    date.setDate(date.getDate() + numOfWeeks * 7);
    return date;
  }

  async setTrackStatus(
    status: 'startTrack' | 'reactivateTrack' | 'pauseTrack' | 'continueTrack',
    trackId: string,
    user: SamiUser,
    track: Track
  ) {
    const analyticsData = {
      trackId: trackId,
      userId: user.uid,
      trackTitle: track.title,
    };

    if (user && user.uid) {
      if (status === 'startTrack') {
        await this.startTrack(trackId, user.uid);
        if (track.goal === 'exam' || track.goal === 'instructor_rank') {
          await this.analytics.logEvent('started-diploma-track', analyticsData);
        } else {
          await this.analytics.logEvent('started-special-track', analyticsData);
        }
        this.navigateToNextLesson(track);
      } else if (status === 'reactivateTrack') {
        await this.resumeTrack(trackId, user.uid);
        if (track.goal === 'exam') {
          await this.analytics.logEvent(
            'reactivated-diploma-track',
            analyticsData
          );
        } else {
          await this.analytics.logEvent(
            'reactivated-special-track',
            analyticsData
          );
        }
        this.navigateToNextLesson(track);
      } else if (status === 'pauseTrack') {
        await this.pauseTrack(trackId, user.uid);
        if (track.goal === 'exam') {
          await this.analytics.logEvent('paused-diploma-track', analyticsData);
        } else {
          await this.analytics.logEvent('paused-special-track', analyticsData);
        }
      } else if (status === 'continueTrack') {
        this.navigateToNextLesson(track);
      }
    }
  }

  async saveTrackCard(id: string, form: any) {
    try {
      const trackCard: Partial<TrackCard> = {};

      if (form.title) trackCard.title = form.title;
      if (form.category) trackCard.category = form.category;
      if (form.system) trackCard.system = form.system;
      if (form.excerpt) trackCard.excerpt = form.excerpt;
      if (form.thumbnailPath) trackCard.horizontalThumbnail = form.thumbnailPath;
      if (form.heroPath) trackCard.verticalThumbnail = form.heroPath;
      if (form.level) trackCard.level = form.level;
      if (form.duration) trackCard.duration = form.duration;
      if (form.numberOfVideos) trackCard.numberOfVideos = form.numberOfVideos;
      if (form.releaseDate) trackCard.releaseDate = form.releaseDate;
      if (form.goal) trackCard.certificationEnabled = form.goal === 'exam';
      if (form.sites) trackCard.sites = form.sites;
      if (form.status) trackCard.status = form.status;

      await this.afs.collection('/trackCards/').doc(id).set(trackCard, { merge: true });
    } catch (error) {
      console.error(error);
    }
  }

  async navigateToNextLesson(track: Track) {
    const finishedLessons = track?.userProgress?.finishedLessons || [];

    let nextChapterId,
      nextLessonId = null;

    for (const chapter of track?.chapters || []) {
      nextChapterId = chapter.id;

      for (const lesson of chapter.lessons || []) {
        if (!lesson.id) {
          continue;
        }

        if (!finishedLessons.includes(lesson.id)) {
          nextLessonId = lesson.id;
          break;
        }
      }

      if (nextLessonId) {
        break;
      }
    }

    this.menuService.navigate(
      `/app/tracks/${track?._id}/chapters/${nextChapterId}/lessons/${nextLessonId}`,
      '/app/tracks/' + track?._id,
      track?.title || ''
    )
  }

  // ---------
  // LESSONS
  // ---------
  public async onCreateLessonFromSystemLibrary(track: any, technique: SamiTechniqueClip, chapterId: string): Promise<Boolean> {
    try {
      const lessonId = this.afs.createId();
      const lessonDoc = this.afs.doc(
        `tracks/${track['_id']}/chapters/${chapterId}/lessons/${lessonId}`
      );
      const chapterDoc = this.afs.doc(
        `tracks/${track['_id']}/chapters/${chapterId}`
      );
      const trackDoc = this.afs.doc(`tracks/${track['_id']}`);
      const techniqueRef = this.afs.doc(`systemsClips/${technique.id}`);

      const lessonData = {
        id: lessonId,
        ref: lessonDoc.ref,
        title: technique.title,
        type: 'library',
        description: technique.additionalInformation || '',
        trackId: track['_id'],
        chapterId,
        vimeoId: technique.videoId || null,
        videoId: technique.muxVideoElementId || null,
        libraryRef: techniqueRef.ref,
        assignmentId: "", // Assign your desired values for these fields
        difficulty: "",
        downloadPath: "",
        duration: 60, // Example value, replace with actual duration
        enableDiscussionForThisLesson: false,
        isFreePreviewLesson: false,
        isPrerequisite: false,
        isRequiredForExam: false,
        participants: "",
        removeMarginsInPlayer: false,
        section: "",
        thumbnails: technique.thumbnails || null,
        videoRef: technique.muxVideoElement ? this.afs.collection('muxVideoLibrary').doc(technique.muxVideoElementId || '').ref : null,
        views: 0, // Example value, replace with actual views
      };

      // Check if muxVideoElement exists before assigning
      if (technique.muxVideoElement) {
        (lessonData as any).muxVideoElement = {
          asset: {
              duration: technique.muxVideoElement.asset.duration,
              id: technique.muxVideoElement.asset.id,
              passthrough: technique.muxVideoElement.asset.passthrough,
              playback_ids: technique.muxVideoElement.asset.playback_ids,
              tracks: technique.muxVideoElement.asset.tracks,
          },
          title: technique.muxVideoElement.title || null,
        };
      }

      const currentChapterData = track.chapters.find(
        (chapter: any) => chapter.id === chapterId
      );
      const { lessons, lessonIds } = this._getLessonDenormForChapter(
        currentChapterData,
        lessonData
      );
      const { thumbnail, muxPlaybackId } = this._getMetaForChapter({
        ...currentChapterData,
        lessons,
        lessonIds,
      });
      const { chapters, chapterIds } = this._getChapterDenormForTrack(track, {
        ...currentChapterData,
        thumbnail,
        muxPlaybackId,
        lessons,
        lessonIds,
      });
      const trackLessonIds = this._getLessonIdsDenormForTrack({
        ...track,
        chapters,
        chapterIds,
      });

      if (technique.muxVideoElementId) {
        await this.afs
          .collection('muxVideoLibrary')
          .doc(technique.muxVideoElementId)
          .update({
            lessonIds: arrayUnion(lessonId),
        });
      }

      await this.afs
        .collection('systemsClips')
        .doc(technique.id)
        .update({
          lessonIds: arrayUnion(lessonId),
      });

      await Promise.all([
        lessonDoc.set(lessonData),
        chapterDoc.update({ lessons, lessonIds, thumbnail, muxPlaybackId }),
        trackDoc.update({ chapters, chapterIds, lessonIds: trackLessonIds }),
      ]);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  // ---------
  // HELPERS
  // ---------

  public trackById(i: number, data: any): number {
    return data.id;
  }

  private _getMetaForChapter(chapter: any): {
    thumbnail: string | undefined;
    muxPlaybackId: string | undefined;
  } {
    let thumbnail = null,
      muxPlaybackId = null;

    for (const lesson of chapter?.lessons || []) {
      thumbnail = lesson.thumbnails?.big || null;
      muxPlaybackId = lesson.muxVideoElement?.asset?.playback_ids?.[0]?.id || null;

      if (muxPlaybackId) {
        break;
      }
    }

    return { thumbnail, muxPlaybackId };
  }

  private _denormLessonForChapter(lesson: any): any {
    return {
      id: lesson.id,
      title: lesson.title,
      type: lesson.type,
      section: lesson.section || null,
      muxVideoElement: lesson.muxVideoElement || null,
      thumbnails: lesson.thumbnails || null,
      duration: lesson.duration || lesson.muxVideoElement?.asset?.duration || null,
    };
  }

  private _getLessonDenormForChapter(
    chapter: any,
    changedLesson: any
  ): { lessons: any; lessonIds: string[] } {
    const existingLessonIndex = chapter.lessons?.findIndex(
      (lesson: any) => lesson.id === changedLesson.id
    );

    if (existingLessonIndex > -1) {
      // chapter.lessons[existingLessonIndex] = this._denormLessonForChapter(changedLesson);
      chapter.lessons[existingLessonIndex] = changedLesson;
    } else {
      // chapter.lessons = [...(chapter.lessons || []), this._denormLessonForChapter(changedLesson)];
      chapter.lessons = [...(chapter.lessons || []), changedLesson];
      chapter.lessonIds = [...(chapter.lessonIds || []), changedLesson.id];
    }

    return { lessons: chapter.lessons, lessonIds: chapter.lessonIds };
  }

  private _getChapterDenormForTrack(
    track: any,
    changedChapter: any
  ): { chapters: any; chapterIds: string[] } {
    const existingChapterIndex = track.chapters?.findIndex(
      (chapter: any) => chapter.id === changedChapter.id
    );
    if (existingChapterIndex > -1) {
      track.chapters[existingChapterIndex] = {
        ...changedChapter,
        lessons: changedChapter.lessons.map((lesson: any) =>
          this._denormLessonForChapter(lesson)
        ),
      };
    } else {
      track.chapters = [...(track.chapters || []), changedChapter].map(
        (chapter: any) => ({
          ...chapter,
          lessons: chapter.lessons.map((lesson: any) =>
            this._denormLessonForChapter(lesson)
          ),
        })
      );
      track.chapterIds = [...(track.chapterIds || []), changedChapter.id];
    }
    return { chapters: track.chapters, chapterIds: track.chapterIds };
  }

  private _getLessonIdsDenormForTrack(track: any): string[] {
    return track.chapters.flatMap((chapter: any) => chapter.lessonIds).filter(Boolean);
  }
}
