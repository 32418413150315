<ng-container *ngIf="!storedFiles || storedFiles?.length === 0">
  <ngx-dropzone
    [accept]="acceptFileTypes()"
    [multiple]="allowMultiple"
    (change)="onSelect($event)"
    class="w-full">
    <ngx-dropzone-label>{{ placeholder }}</ngx-dropzone-label>
    <ngx-dropzone-preview
      *ngFor="let f of files"
      [removable]="true"
      (removed)="onRemove(f)"
    >
      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-preview>
  </ngx-dropzone>
  <div class="flex flex-row justify-between space-x-4 pt-1.5">
    <span class="text-xs font-body text-richBlack">
      {{ hint }}
    </span>
    <button
      (click)="uploadFiles()"
      [disabled]="files.length !== 1"
      class="text-sm disabled:bg-cadetGrey-600 bg-richBlack px-4 py-1 font-sans font-semibold text-white"
    >
      Upload
    </button>
  </div>
</ng-container>

<ng-container *ngIf="storedFiles && storedFiles.length > 0">
  <div class="grid grid-cols-2 gap-4">
    <div *ngFor="let file of storedFiles">
      <div class="relative aspect-square">
        <ng-container *ngIf="file.type.includes('image/')">
          <picture>
            <source
              type="image/webp"
              [srcset]="
                file.url
                  | transformImg
                    : 'firestore'
                    : 'fit-in/0x800/smart/filters:format(webp)'
              "
            />
            <source
              type="image/png"
              [srcset]="
              file.url
                  | transformImg
                    : 'firestore'
                    : 'fit-in/0x800/smart/filters:format(png)'
              "
            />
            <img
              loading="lazy"
              class="w-full h-full object-cover relative"
              [src]="
              file.url | transformImg : 'firestore' : 'fit-in/0x800/smart'
              "
            />
          </picture>
          </ng-container>

        <ng-container *ngIf="file.type.includes('application/pdf')">
          <div class="bg-richBlack object-contain w-full h-full p-4">
            <span class="text-base font-body text-center text-white">
              {{ file.name }}
            </span>
          </div>
          </ng-container>
          <sami-button
            *ngIf="allowRemoveAfterUpload"
            class="absolute top-2 right-2 z-10"
            [size]="'small'"
            [mode]="'icon'"
            icon="close"
          ></sami-button>
        </div>
    </div>
  </div>
</ng-container>
