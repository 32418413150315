import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-exercises-count',
  standalone: true,
  imports: [ CommonModule ],
  templateUrl: './exercises-count.component.html',
})
export class ExercisesCountComponent implements OnInit {
  @Input() rounds: any;
  exercises = 0;

  ngOnInit(): void {
    if (this.exercises && this.exercises > 0) {
      this.exercises = this.rounds.reduce(
        (acc: any, round: { exercises: string | any[] }) =>
          acc + round.exercises.length,
        0
      );
    }
  }
}
