<div class="md:bg-cadetGrey-900 md:min-h-screen h-full">
  <section
    class="max-w-xl px-0 mx-auto flex flex-col justify-start h-full"
    *ngIf="workoutId$ | async as workoutId"
  >
    <div
      id="finish-workout-container"
      class="w-full flex flex-col min-h-[100svh] h-[100svh] safe-top bg-white relative"
      *ngIf="session$ | async as session"
    >
      <div class="bg-richBlack pb-8 flex flex-col pt-8 justify-center items-center">
        <svg
          class="w-14 h-14"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="64" height="64" rx="32" fill="#EDF2EF" />
          <path
            d="M47.7041 19.1665L26.0689 40.4332L15.9361 30.4048L13.3584 33.0118L24.7746 44.3125C25.1177 44.6511 25.5804 44.8411 26.0625 44.8411C26.5446 44.8411 27.0073 44.6511 27.3504 44.3125L50.2671 21.7772L47.7041 19.1665Z"
            fill="#001427"
          />
        </svg>
        <div class="flex flex-col justify-start items-center pt-2">
          <span class="text-cadetGrey-600 text-lg font-semibold font-sans">
            TRAINING COMPLETE
          </span>
          <span class="text-white text-5xl font-semibold font-sans"> WELL DONE! </span>
        </div>
      </div>
      <div class="bg-cadetGrey-600 p-4">
        <span class="text-2xl font-semibold text-white font-sans"> SUMMARY </span>
        <div
          class="flex flex-col justify-start items-start space-y-0.5 text-white pt-2"
        >
          <div class="flex flex-row justify-start items-center space-x-2">
            <svg
              class="w-4 h-4"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM8 1.33333C4.3181 1.33333 1.33333 4.3181 1.33333 8C1.33333 11.6819 4.3181 14.6667 8 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8C14.6667 4.3181 11.6819 1.33333 8 1.33333ZM8.66667 7.33333V3.92603H7.33333V8C7.33333 8.36819 7.63181 8.66667 8 8.66667H11.2593V7.33333H8.66667Z"
                fill="#EDF2EF"
              />
            </svg>
            <span>
              Total Time:
              {{ (session.duration || 0) * 1000 | date: 'mm:ss':'UTC' }}
            </span>
          </div>
          <div
            class="flex flex-row justify-start items-center space-x-2"
            *ngIf="session.rounds && session.rounds > 0"
          >
            <svg
              class="w-4 h-4"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.756 4.2035L13.702 6.02816C13.3371 4.84996 12.6526 3.79608 11.7245 2.98372C10.7965 2.17137 9.66119 1.63241 8.44506 1.42679C7.22893 1.22118 5.97959 1.35696 4.83603 1.81904C3.69247 2.28113 2.69951 3.0514 1.96761 4.04416C1.23571 5.03693 0.793574 6.21328 0.690374 7.44236C0.587171 8.67143 0.826954 9.90503 1.38305 11.006C1.93914 12.1068 2.78975 13.0319 3.84024 13.6782C4.89073 14.3246 6.09993 14.6668 7.33333 14.6668V13.3335C6.33162 13.3345 5.34989 13.0534 4.50055 12.5222C3.65121 11.9912 2.96861 11.2316 2.5309 10.3306C2.09319 9.42963 1.91807 8.42356 2.0256 7.42763C2.13313 6.43174 2.51896 5.48624 3.13891 4.69942C3.75885 3.9126 4.58785 3.31628 5.53097 2.97874C6.47409 2.6412 7.49319 2.57609 8.47159 2.79086C9.44999 3.00564 10.3482 3.49161 11.0632 4.19313C11.7783 4.89465 12.2813 5.78336 12.5147 6.7575L10.2027 5.42416L9.53599 6.57883L13.5773 8.91216L15.9107 4.8715L14.756 4.2035Z"
                fill="#EDF2EF"
              />
            </svg>
            <span> Rounds {{ session.rounds.length || 0 }} </span>
          </div>
          <div
            class="flex flex-row justify-start items-center space-x-2"
            *ngIf="session.rounds && session.rounds > 0"
          >
            <svg
              class="w-4 h-4"
              viewBox="0 0 11 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3 6C2.17157 6 1.5 6.67157 1.5 7.5V15C1.5 15.8284 2.17157 16.5 3 16.5H7.5C8.32843 16.5 9 15.8284 9 15V7.5C9 6.67157 8.32843 6 7.5 6H3ZM0 7.5C0 5.84315 1.34315 4.5 3 4.5H7.5C9.15685 4.5 10.5 5.84315 10.5 7.5V15C10.5 16.6569 9.15685 18 7.5 18H3C1.34315 18 0 16.6569 0 15V7.5Z"
                fill="#EDF2EF"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.25 0C5.48678 0 5.70966 0.111817 5.85122 0.301627L9.72347 5.49388L8.52103 6.39062L5.25 2.00454L1.97897 6.39062L0.776533 5.49388L4.64878 0.301627C4.79034 0.111817 5.01322 0 5.25 0Z"
                fill="#EDF2EF"
              />
              <path d="M0.75 9.375H9.75V13.125H0.75V9.375Z" fill="#EDF2EF" />
            </svg>
            <app-exercises-count
              [rounds]="session.rounds"
            ></app-exercises-count>
          </div>
        </div>
      </div>
      <div
        class="flex-1 pt-4 pb-20 px-4 flex flex-col justify-start space-y-4 overflow-y-auto"
      >
        <ng-container *ngIf="session.warmup && session.warmup.length > 0">
          <div class="flex flex-col justify-start">
            <h4 class="text-lg font-semibold text-richBlack">Warmup</h4>
            <div class="pt-2 flex flex-col justify-start space-y-1">
              <div
                class="flex flex-row justify-between items-center"
                *ngFor="let exercise of session.warmup.exercises"
              >
                <span class="text-sm">
                  {{ exercise.exercise.title }}
                </span>
                <span class="text-cadetGrey-300 text-sm font-semibold">
                  {{ (exercise.duration || 0) * 1000 | date: 'mm:ss':'UTC' }}
                </span>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="session.rounds && session.rounds.length > 0">
          <div
            class="flex flex-col justify-start"
            *ngFor="let round of session.rounds; let i = index"
          >
            <h4 class="text-lg font-semibold text-richBlack">
              Round {{ i + 1 }}
            </h4>
            <div class="pt-2 flex flex-col justify-start space-y-1">
              <div
                class="flex flex-row justify-between items-center"
                *ngFor="let exercise of round.exercises"
              >
                <span class="text-sm">
                  {{ exercise.exercise.title }}
                </span>
                <span class="text-cadetGrey-300 text-sm font-semibold">
                  {{ (exercise.duration || 0) * 1000 | date: 'mm:ss':'UTC' }}
                </span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div
        [ngClass]="isPWA || isNative ? 'pb-6' : 'pb-2.5'"
        class="absolute bottom-0 w-full px-2.5 pt-2.5 bg-white"
        *ngIf="sessionId$ | async as sessionId"
      >
        <button
          [routerLink]="
            '../notes'
          "
          class="uppercase font-sans bg-richBlack hover:bg-cadetGrey-800 w-full py-3 text-lg font-semibold text-white text-center"
        >
          Next
        </button>
      </div>
    </div>
  </section>
</div>
