import { Component } from '@angular/core';
import { MenuService } from '../../menu.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html'
})
export class PageTitleComponent {

  constructor(
    public menuService: MenuService,
    private router: Router,
    private location: Location
  ) {}

  back(backButton: any) {
    console.log(backButton);
    if (backButton.url) {
      this.router.navigate([backButton.url], { queryParams: backButton.queryParams || '', queryParamsHandling: backButton.queryParamsHandling || 'merge' })
    } else {
      this.location.back();
    }
  }

}
