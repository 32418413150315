import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-products-card',
  templateUrl: './products-card.component.html',
})
export class ProductsCardComponent implements OnInit {
  @Input() products:
    | {
        title: string;
        excerpt: string;
        thumbnailPath: string;
        stripePriceId: string;
        link: string;
        description: string;
      }[]
    | null = null;

  constructor() {}

  ngOnInit(): void {}
}
