import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ImagePipe } from '../../pipes/transformImage.pipe';

@Component({
  selector: 'sami-news-card',
  standalone: true,
  imports: [CommonModule, ImagePipe],
  templateUrl: './news-card.component.html'
})
export class NewsCardComponent implements OnInit {
  @Input() newsElement: any | null = null;
  @Input() backgroundColor: 'white' | 'culturedWhite' = 'white';

  ngOnInit(): void {
      console.log('newsElement', this.newsElement);
  }
}
