<ng-container *ngIf="!loading; else showLoading">
  <button
    (click)="isAvailable ? clickEmitter.emit(id) : null"
    [ngClass]="'bg-' + returnSystemComponent(systemIdentifier, 'color')"
    class="grid grid-cols-1 sm:grid-cols-3 overflow-hidden w-full relative h-full hover:shadow-lg min-h-[220px]"
  >
    <div class="relative h-44 sm:h-full w-full" *ngIf="imageUrl">
      <img
        loading="lazy"
        class="absolute z-10 object-cover w-full h-full inset-0 object-center"
        [src]="
          imageUrl | getDownloadURL | transformImg: 'firestore':'pr=true,w-400'
        "
        [alt]=""
      />

      <div *ngIf="!imageLoad" class="absolute object-cover w-full h-full inset-0 object-center bg-cadetGrey-200 animate-pulse"></div>
    </div>
    <div
      [ngClass]="imageUrl ? 'col-span-2' : 'col-span-3'"
      class="flex flex-col justify-start h-full"
    >
      <div
        class="flex-1 flex-col items-center justify-start px-5 py-5 space-y-3 h-full"
      >
        <ng-container *ngIf="systemIdentifier !== 'general'">
          <p
            class="text-culturedWhite font-sans text-left opacity-40 text-base uppercase font-semibold"
          >
            SAMI-X
            {{
              color ? '' : returnSystemComponent(systemIdentifier, 'categoryText')
            }}
          </p>
        </ng-container>
        <h3 class="text-white text-left font-sans text-2xl tracking-tight font-semibold">
          {{ title }}
        </h3>
        <p
          *ngIf="subtitle"
          class="text-sm text-white text-left font-body line-clamp-3"
        >
          {{ subtitle }}
        </p>
      </div>
      <div
        *ngIf="progress && showProgress; else noProgress"
        class="w-full px-5 py-2 bg-richBlack/20 flex flex-row justify-start items-center"
      >
        <div
          *ngIf="progress"
          class="w-full flex flex-row justify-start items-center space-x-2"
        >
          <span class="text-base font-sans text-white">
            {{ (progress | number: '1.0-0') + '%' }}
          </span>
          <div
            class="w-full bg-richBlack/90 rounded-none h-2 dark:bg-richBlack/90"
          >
            <div
              class="bg-culturedWhite h-2 rounded-none"
              [ngStyle]="{ width: progress + '%' }"
            ></div>
          </div>
        </div>
      </div>

      <ng-template #noProgress>
        <div
          *ngIf="duration || lessons || level"
          class="w-full px-5 py-2 bg-richBlack/20 flex flex-row justify-start overflow-x-auto space-x-4 text-white font-semibold uppercase items-center"
        >
          <div
            class="flex flex-row justify-start items-center space-x-2"
            *ngIf="level"
          >
            <svg
              class="w-4 h-4"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2805_9462)">
                <path
                  d="M0.583333 10.5H2.91667V12.8333H0.583333V10.5Z"
                  fill="#EDF2EF"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 10.5C0 10.1778 0.261167 9.91665 0.583333 9.91665H2.91667C3.23883 9.91665 3.5 10.1778 3.5 10.5V12.8333C3.5 13.1555 3.23883 13.4166 2.91667 13.4166H0.583333C0.261167 13.4166 0 13.1555 0 12.8333V10.5ZM1.16667 11.0833V12.25H2.33333V11.0833H1.16667Z"
                  fill="#EDF2EF"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.5 1.16665C10.5 0.84448 10.7612 0.583313 11.0833 0.583313H13.4167C13.7388 0.583313 14 0.84448 14 1.16665V12.8333C14 13.1555 13.7388 13.4166 13.4167 13.4166H11.0833C10.7612 13.4166 10.5 13.1555 10.5 12.8333V1.16665ZM11.6667 1.74998V12.25H12.8333V1.74998H11.6667ZM5.25 5.83331C5.25 5.51115 5.51117 5.24998 5.83333 5.24998H8.16667C8.48883 5.24998 8.75 5.51115 8.75 5.83331V12.8333C8.75 13.1555 8.48883 13.4166 8.16667 13.4166H5.83333C5.51117 13.4166 5.25 13.1555 5.25 12.8333V5.83331ZM6.41667 6.41665V12.25H7.58333V6.41665H6.41667Z"
                  fill="#EDF2EF"
                />
              </g>
            </svg>
            <span class="whitespace-nowrap font-sans"> LEVEL {{ level }} </span>
          </div>

          <div
            class="flex flex-row justify-start items-center space-x-2"
            *ngIf="lessons"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.25 4.66669V9.33335L9.33333 7.00002L5.25 4.66669Z"
                fill="#EDF2EF"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 2.33333C0 2.01117 0.261167 1.75 0.583333 1.75H13.4167C13.7388 1.75 14 2.01117 14 2.33333V11.6667C14 11.9888 13.7388 12.25 13.4167 12.25H0.583333C0.261167 12.25 0 11.9888 0 11.6667V2.33333ZM1.16667 2.91667V11.0833H12.8333V2.91667H1.16667Z"
                fill="#EDF2EF"
              />
            </svg>
            <span class="whitespace-nowrap font-sans"> {{ lessons }} LESSONS </span>
          </div>

          <div
            class="flex flex-row justify-start items-center space-x-2"
            *ngIf="duration"
          >
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7ZM7 1.16667C3.77834 1.16667 1.16667 3.77834 1.16667 7C1.16667 10.2216 3.77834 12.8333 7 12.8333C10.2216 12.8333 12.8333 10.2216 12.8333 7C12.8333 3.77834 10.2216 1.16667 7 1.16667ZM7.58333 6.41667V3.43527H6.41667V7C6.41667 7.32217 6.67783 7.58333 7 7.58333H9.85192V6.41667H7.58333Z" fill="#ECEFEF"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7ZM7 1.16667C3.77834 1.16667 1.16667 3.77834 1.16667 7C1.16667 10.2216 3.77834 12.8333 7 12.8333C10.2216 12.8333 12.8333 10.2216 12.8333 7C12.8333 3.77834 10.2216 1.16667 7 1.16667Z" fill="#ECEFEF"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.58333 6.41667V3.43527H6.41667V7C6.41667 7.32217 6.67783 7.58333 7 7.58333H9.85192V6.41667H7.58333Z" fill="#ECEFEF"/>
            </svg>
            <span class="whitespace-nowrap font-sans"> {{ duration }} HOURS </span>
          </div>
        </div>
      </ng-template>
    </div>

    <div
      class="absolute right-0 top-0 bottom-0 h-full overflow-hidden pointer-events-none"
    >
      <picture>
        <source
          type="image/webp"
          [srcset]="
            'https://a.storyblok.com/f/110844/138x190/e1072f0643/x_track_card.png'
              | transformImg
                : 'storyblok'
                : 'fit-in/0x800/smart/filters:format(webp)'
          "
        />
        <source
          type="image/png"
          [srcset]="
            'https://a.storyblok.com/f/110844/138x190/e1072f0643/x_track_card.png'
              | transformImg
                : 'storyblok'
                : 'fit-in/0x800/smart/filters:format(png)'
          "
        />
        <img
          loading="lazy"
          class="h-full scale-125"
          [src]="
            'https://a.storyblok.com/f/110844/138x190/e1072f0643/x_track_card.png'
              | transformImg: 'storyblok':'fit-in/0x800/smart'
          "
          [alt]="'Onboarding Step'"
        />
      </picture>
    </div>

    <div
      class="absolute z-20 cursor-not-allowed inset-0 w-full h-full bg-richBlack/90 flex flex-col justify-center items-center space-y-2"
      *ngIf="releaseDate && !isAvailable"
    >
      <div class="flex flex-col justify-center items-center space-y-2">
        <svg
          class="w-8 h-8 flex-shrink-0"
          viewBox="0 0 37 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.5 18C0.5 8.05888 8.55888 0 18.5 0C28.4411 0 36.5 8.05888 36.5 18C36.5 27.9411 28.4411 36 18.5 36C8.55888 36 0.5 27.9411 0.5 18ZM18.5 3C10.2157 3 3.5 9.71573 3.5 18C3.5 26.2842 10.2157 33 18.5 33C26.7842 33 33.5 26.2842 33.5 18C33.5 9.71573 26.7842 3 18.5 3ZM20 16.5V8.83356H17V18C17 18.8284 17.6716 19.5 18.5 19.5H25.8335V16.5H20Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.5 18C0.5 8.05888 8.55888 0 18.5 0C28.4411 0 36.5 8.05888 36.5 18C36.5 27.9411 28.4411 36 18.5 36C8.55888 36 0.5 27.9411 0.5 18ZM18.5 3C10.2157 3 3.5 9.71573 3.5 18C3.5 26.2842 10.2157 33 18.5 33C26.7842 33 33.5 26.2842 33.5 18C33.5 9.71573 26.7842 3 18.5 3Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20 16.5V8.83356H17V18C17 18.8284 17.6716 19.5 18.5 19.5H25.8335V16.5H20Z"
            fill="white"
          />
        </svg>
        <div class="flex flex-col justify-start items-center font-sans space-y-1">
          <span class="uppercase font-semibold text-cadetGrey-700 text-xl">
            coming {{ releaseDate.toDate() | date: 'MMMM d' }}
          </span>
          <span class="text-2xl text-white font-semibold">
            {{ title }}
          </span>
        </div>
      </div>
    </div>
  </button>
</ng-container>

<ng-template #showLoading>
  <div class="bg-white grid grid-cols-1 sm:grid-cols-3 overflow-hidden w-full relative h-full hover:shadow-lg min-h-[220px] justify-start items-center">
    <div class="relative h-44 sm:h-full w-full animate-pulse bg-cadetGrey-200"></div>
    <div class="col-span-2 grow shrink basis-0 self-stretch px-6 py-4 flex-col justify-center items-start gap-4 inline-flex">
      <div class="flex-col justify-start items-start gap-4 flex">
        <div class="flex-col justify-start items-start gap-2 flex">
          <div class="w-24 h-3.5 bg-cadetGrey-200 animate-pulse"></div>
          <div class="w-44 h-6 bg-cadetGrey-200 animate-pulse"></div>
        </div>
        <div class="w-64 h-12 bg-cadetGrey-200 animate-pulse"></div>
      </div>
      <div class="justify-start items-start gap-4 inline-flex">
        <div class="w-16 h-5 bg-cadetGrey-200 animate-pulse"></div>
        <div class="w-16 h-5 bg-cadetGrey-200 animate-pulse"></div>
        <div class="w-16 h-5 bg-cadetGrey-200 animate-pulse"></div>
      </div>
    </div>
  </div>
</ng-template>
