import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'startsWith',
  standalone: true
})
export class StartsWithPipe implements PipeTransform {
  transform(fullText: string, textMatch: string): boolean {
    return fullText.startsWith(textMatch);
  }
}
