import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrackHeroComponent } from './track-hero.component';
import { GetDownloadURLPipeModule } from '@angular/fire/compat/storage';
import { ImagePipe } from '../../pipes/transformImage.pipe';
import { StartTrackButtonComponent } from '../start-button/start-button.component';

@NgModule({
  declarations: [
    TrackHeroComponent
  ],
  imports: [
    CommonModule,
    ImagePipe,
    GetDownloadURLPipeModule,
    StartTrackButtonComponent
  ],
  exports: [
    TrackHeroComponent
  ],
})
export class TrackHeroModule {}
