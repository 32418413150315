<div *ngIf="user" class="h-full py-2 pr-2">
  <button [matMenuTriggerFor]="menu" [ngClass]="backgroundColor === 'white' ? 'bg-culturedWhite' : 'bg-cadetGrey-900'" class="h-full rounded-lg flex flex-row justify-end items-center pr-2 pl-4">
    <div class="hidden sm:flex flex-col justify-center space-y-0.5 mr-4 items-end">
      <span class="font-sans font-semibold leading-4 text-base" [ngClass]="backgroundColor === 'white' ? 'text-richBlack' : 'text-culturedWhite'">
        {{ user.displayName }}
      </span>
      <span class="font-body text-xs leading-4" [ngClass]="backgroundColor === 'white' ? 'text-cadetGrey-600' : 'text-cadetGrey-200'">
        {{ getStudentRole(user) }}
      </span>
    </div>

    <ng-container
      *ngIf="
        user.photoURL &&
          (user.photoURL | stor | async) as userURL;
        else noPhoto
      "
    >
      <img
        class="inline-block h-8 w-8 rounded-full object-cover"
        [src]="userURL"
        alt=""
      />
    </ng-container>
    <ng-template #noPhoto>
      <span
        class="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100"
      >
        <svg
          class="h-full w-full text-gray-300"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
          />
        </svg>
      </span>
    </ng-template>

    <div [ngClass]="backgroundColor === 'white' ? 'text-richBlack' : 'text-culturedWhite'" class="ml-2">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 15.3751L6 9.3751L7.4 7.9751L12 12.5751L16.6 7.9751L18 9.3751L12 15.3751Z" fill="#597482"/>
      </svg>
    </div>

    <mat-menu xPosition="before" #menu="matMenu" panelClass="rounded-none font-body text-sm">
      <div class="flex flex-col justify-start text-sm">
        <ng-container *ngFor="let menuItem of menuItems">
          <ng-container *ngIf="menuItem.mode === 'internal'">
            <button [routerLink]="menuItem.link" class="text-left hover:text-cadetGrey-800 px-4 pt-1 pb-2">
              <span class="font-body">
                {{ menuItem.label }}
              </span>
            </button>
          </ng-container>

          <ng-container *ngIf="menuItem.mode === 'external'">
            <a [href]="menuItem.link" target="_blank" class="text-left hover:text-cadetGrey-800 flex flex-row space-x-2 items-center justify-between px-4 pt-1 pb-2">
              <span class="font-body">
                {{ menuItem.label }}
              </span>
              <svg class="w-3 h-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M23.7071 1.70711L7.70711 17.7071L6.29289 16.2929L22.2929 0.292893L23.7071 1.70711Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 0H23C23.5523 0 24 0.447715 24 1V10H22V2H14V0Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 6C0 5.44772 0.447715 5 1 5H10V7H2V22H17V14H19V23C19 23.5523 18.5523 24 18 24H1C0.447715 24 0 23.5523 0 23V6Z" fill="currentColor"/>
              </svg>
            </a>
          </ng-container>
          <hr>
        </ng-container>
        <button (click)="onLogout()" class="text-left px-4 pt-2 pb-1">
          <span class="font-body hover:text-cadetGrey-800">
            Logout
          </span>
        </button>
      </div>
    </mat-menu>
  </button>
</div>