import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayFileComponent } from './display-file.component';
import { GetDownloadURLPipeModule } from '@angular/fire/compat/storage';
import { SamiButtonComponent } from '../button/button.component';

@NgModule({
  declarations: [DisplayFileComponent],
  imports: [CommonModule, SamiButtonComponent, GetDownloadURLPipeModule],
  exports: [DisplayFileComponent],
})
export class DisplayFileModule {}
