import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ImagePipe } from '../../pipes/transformImage.pipe';
import { SamiCombatSystemTagComponent } from '../../combat-system-tag/combat-system-tag.component';
import { MembershipBadgeComponent } from '../../badges/membership-badge/membership-badge.component';
import { PurchasedBadgeComponent } from '../../badges/purchased-badge/purchased-badge.component';
import { MenuService } from 'header';
import { LiveTraining } from 'interfaces';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-live-training-card',
  standalone: true,
  imports: [CommonModule, RouterModule, ImagePipe, SamiCombatSystemTagComponent, MembershipBadgeComponent, PurchasedBadgeComponent],
  templateUrl: './live-training-card.component.html'
})
export class LiveTrainingCardComponent implements OnInit {
  @Input() liveTraining: any | null = null;
  @Input() isAttendee = false;
  @Input() isPast = false;
  @Input() backgroundColor: 'white' | 'culturedWhite' = 'white';

  defaultImage: any = {
    knife:
      'https://a.storyblok.com/f/110844/2100x700/43a4e301dd/p1433924-neu-2.png',
    stick:
      'https://a.storyblok.com/f/110844/2100x700/f0ebf07adf/p1433746-neu-2.png',
    axe: 'https://a.storyblok.com/f/110844/2100x700/b959279965/p1433766-neu-2.png',
    panantukan:
      'https://a.storyblok.com/f/110844/2100x700/1c3d7f9224/p1433940-neu-2.png',
    kravMaga:
      'https://a.storyblok.com/f/110844/2100x700/735b599b2a/p1444036-neu-2.png',
    kapap: 'https://a.storyblok.com/f/110844/2000x1000/456c35052f/samixpro.png',
    ebc: 'https://a.storyblok.com/f/110844/2000x1000/5a23802f19/samixebc.png',
    sds: 'https://a.storyblok.com/f/110844/2000x1000/b21a7329ea/sds.png',
    defender: 'https://a.storyblok.com/f/110844/650x495/2a347e804f/sami-defender-course-previewimage.jpg',
    karambit: 'https://a.storyblok.com/f/110844/6000x4000/a3696dc428/karambit.jpeg'
  };

  daysAvailable = 0;

  constructor(
    private menuService: MenuService,
    private router: Router,
    private analytics: AngularFireAnalytics
  ) {}

  ngOnInit(): void {
    if (this.liveTraining && this.isPast) {
      const now = Date.now();
      const end = this.liveTraining.endDate.toDate().getTime() + 86400 * 30;

      const diffTime = Math.abs(end - now);
      const diffDays = Math.ceil(diffTime / (1000 * 86400));
      this.daysAvailable = diffDays;
    }
  }

  navigateToTraining(liveTraining: LiveTraining) {
    this.analytics.logEvent('visit_live_training', { liveTrainingId: liveTraining._id, liveTrainingTitle: liveTraining.title });
    this.menuService.navigate('/app/live-sessions/' + liveTraining._id, this.router.url, liveTraining.title);
  }
}