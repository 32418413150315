import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'sami-steps',
  templateUrl: './steps.component.html',
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class SamiStepsComponent {
  @Input() currentStep = 0;
  @Input() steps: {
    title: string;
  }[] | undefined;

  constructor() {}
}
