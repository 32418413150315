import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { SamiUser } from 'interfaces';
import { CurrencyService } from './currency.service';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  ipBaseUrl =
    'https://api.ipstack.com/check?access_key=354e4b08e2e7488212874bee3108382b';
  userLocation: any;
  selectedLanguage$ = new BehaviorSubject('en');
  location$: Observable<any> = new Observable<any>();

  constructor(
    private http: HttpClient,
    private currencyService: CurrencyService
  ) {
    this.location$ = this.http.get<any>(this.ipBaseUrl);
  }

  getUserInformationBasedOnIP() {
    this.http.get<any>(this.ipBaseUrl).subscribe((location) => {
      this.userLocation = location;
      return location;
    });
  }

  getInitUserInformationBasedOnIP(user: SamiUser) {
    if (user && user.stripeCurrency) {
      this.currencyService.selectedCurrency = user.stripeCurrency.toUpperCase();
      this.currencyService.getCurrency(user.stripeCurrency.toUpperCase());
    } else {
      this.http.get<any>(this.ipBaseUrl).subscribe((location) => {
        this.userLocation = location;
        if (location && location.currency && location.currency.code !== 'EUR') {
          this.currencyService.getCurrency(location.currency.code);
        }
        return location;
      });
    }
  }

  setLanguage(language: string) {
    this.selectedLanguage$.next(language);
  }
}
