import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './layout/footer/footer.component';

import { DatePipe, registerLocaleData } from '@angular/common';
import localeAt from '@angular/common/locales/de-AT';
registerLocaleData(localeAt);

import { AngularFireModule } from '@angular/fire/compat';
import {
  AngularFireAnalyticsModule,
  CONFIG,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/compat/analytics';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import {
  AngularFireFunctionsModule,
  REGION,
} from '@angular/fire/compat/functions';
import { AngularFirePerformanceModule } from '@angular/fire/compat/performance';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { MatExpansionModule } from '@angular/material/expansion';

import { GoogleMapsModule } from '@angular/google-maps';

import { CdkAccordionModule } from '@angular/cdk/accordion';
import { HttpClientModule } from '@angular/common/http';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { BrowserModule, provideClientHydration } from "@angular/platform-browser";
import { StoryblokService } from '@sami/features';
import { environment } from 'interfaces';
import { ImagePipe } from '@sami/ui';
import { QRCodeModule } from 'angularx-qrcode';
import { HeaderModule } from 'header';
import { BottomsheetContainerComponent } from './components/_containers/bottomsheet-container/bottomsheet-container.component';
import { DialogContainerComponent } from './components/_containers/dialog-container/dialog-container.component';
import { DefaultComponent } from './pages/default/default.component';
import { ControlFactoryDirective } from './shared/control-factory.directive';
import { SafeModule } from './shared/pipes/safe/safe.module';
import { StartsWithModule } from './shared/pipes/startsWith/startswith.module';
import { StorageModule } from './shared/pipes/storage/storage.module';
import { getFunctions, provideFunctions } from '@angular/fire/functions';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    DefaultComponent,
    DialogContainerComponent,
    BottomsheetContainerComponent,
    ControlFactoryDirective,
  ],
  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    provideAuth(() => getAuth()),
    provideFunctions(() => getFunctions(getApp(), 'europe-west1')),
    BrowserModule,
    MatSidenavModule,
    HeaderModule,
    RouterModule,
    StorageModule,
    GoogleMapsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    SafeModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatBottomSheetModule,
    MatDialogModule,
    MatTabsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AngularFireAnalyticsModule,
    AngularFirePerformanceModule,
    MatExpansionModule,
    QRCodeModule,
    StartsWithModule,
    CdkAccordionModule,
    ImagePipe,
    HeaderModule,
  ],
  providers: [
    DatePipe,
    provideClientHydration(),
    { provide: LOCALE_ID, useValue: 'de-AT' },
    { provide: REGION, useValue: 'europe-west1' },
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: CONFIG,
      useValue: {
        allow_ad_personalization_signals: false,
        anonymize_ip: true,
        setAnalyticsCollectionEnabled: false,
      },
    },
    StoryblokService,
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}}

  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
