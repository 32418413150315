import { SamiStyleModel } from './sami-style.model';

export interface SamiSystem {
  [key: string]: any;
  id?: string;
  allClipsPerLevel: object;
  categories?: {
    allClipsPerLevel?: object;
    clips?: number;
    clipsPerLevel?: object;
    examClipsPerLevel?: object;
    instructorClipsPerLevel?: object;
    instructorExamClipsPerLevel?: object;
    title?: string;
    displayTitle?: string; // Title only for displaying purposes
    description?: string; // Display Description on System Overview
    isPublic?: boolean; // Display Category on SAMI-X for Students
    mainImage?: string; // Display Image on System Card
    subCategories?: {
      allClipsPerLevel?: object;
      clips?: number;
      clipsPerLevel?: object;
      examClipsPerLevel?: object;
      instructorClipsPerLevel?: object;
      instructorExamClipsPerLevel?: object;
      title?: string;
      displayTitle?: string; // Title only for displaying purposes
      description?: string; // Display Description on System Overview
      isPublic?: boolean; // Display Category on SAMI-X for Students
      mainImage?: string; // Display Image on System Card
    }[];
  }[];
  clips?: number;
  clipsPerLevel?: object;
  color1?: string;
  color2?: string;
  description?: string;
  examClipsPerLevel?: object;
  fontFamily?: string;
  fontWeight?: string;
  identifier?: string;
  title?: string;
  instructorClipsPerLevel?: object;
  instructorExamClipsPerLevel?: object;
  mainImage?: string; // Display Image on System Card
}

export interface SamiTechniqueClip {
  [key: string]: any;
  id?: string;
  _id?: any;
  isPreparationContent?: boolean;
  title?: string;
  videoId?: string;
  duration?: number;
  views?: number;
  thumbnails?: any;
  status?: 'public' | 'hidden' | 'trainee' | 'instructor' | 'draft';
  style?: SamiStyleModel;
  category?: string;
  subCategory?: string;
  level?: number;
  hasAccess?: boolean;
  hasPurchased?: boolean;
  // ADDITIONAL FIELDS
  additionalInformation?: string;
  isFeatured?: boolean;
  participants?: 'partner' | 'single' | 'group' | 'theory';
  typeOfClip?: 'technique' | 'theory' | 'exercise' | 'generalInformation';
  isFree?: boolean;
  isExamRequired?: boolean;
  isVimeoSynced?: boolean;
  connectedExerciseRefs?: {
    // only visible when typeOfClip === 'technique';
    level?: number;
    ref?: any;
    _id?: string;
    id?: string;
    hasAccess?: boolean;
    style?: SamiStyleModel;
    thumbnails?: {
      big?: string;
      bigWithPlayButton?: string;
      small?: string;
      smallWithPlayButton?: string;
    };
    title?: string;
  }[];
  assetType?: 'video' | 'vimeo';
  muxVideoElement?: any;
  muxVideoElementId?: string | null;
  connectedExercises?: string[]; // only visible when typeOfClip === 'technique';
  excerpt?: string;
  thumbnailPath?: string | null;
}

export interface SamiTechniqueClipWatchHistory {
  [key: string]: any;
  id?: string;
  title?: string;
  lastWatched?: Date | any;
  views?: number;
}
