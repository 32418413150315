<div class="flex flex-col justify-start" *ngIf="formField?.dirty">
  <span class="text-xs text-statusColors-red font-body"
    *ngIf="formField?.hasError('required')">
    This is a required field.
  </span>
  <span class="text-xs text-statusColors-red font-body"
    *ngIf="formField?.hasError('noEmptyString')">
    Please enter a valid name.
  </span>

  <span class="text-xs text-statusColors-red font-body"
    *ngIf="formField?.hasError('normalEmailRule')">
    Email format is invalid.
  </span>

  <span class="text-xs text-statusColors-red font-body"
    *ngIf="formField?.hasError('maxlength')">
    The maximum length is {{ formField?.getError('maxlength')?.requiredLength }} characters.
  </span>

  <span class="text-xs text-statusColors-red font-body"
    *ngIf="formField?.hasError('minlength')">
    A minimum length of {{ formField?.getError('minlength')?.requiredLength }} characters is required.
  </span>

  <span class="text-xs text-statusColors-red font-body"
    *ngIf="formField?.hasError('noWhitespaceRequired')">
    No spaces allowed.
  </span>

  <span class="text-xs text-statusColors-red font-body"
    *ngIf="formField?.hasError('notAvailable')">
    Not available.
  </span>
</div>