import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SamiSeminar } from 'interfaces';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'sami-seminar-description',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule
  ],
  templateUrl: './seminar-description.component.html'
})
export class SeminarDescriptionComponent {
  activeLink: { label: string; link: string } | null = null;
  navLinks = [
    {
      label: 'English',
      link: 'EN',
    },
    {
      label: 'Deutsch',
      link: 'DE',
    },
    {
      label: 'Czech',
      link: 'CS',
    },
    {
      label: 'Italian',
      link: 'IT',
    },
    {
      label: 'Spanish',
      link: 'ES',
    },
    {
      label: 'French',
      link: 'FR',
    },
  ];

  @Input() seminar: SamiSeminar | undefined;
  @Input() background: 'white' | 'culturedWhite' = 'white';

  constructor() {}

  getLanguage(language: any): any {
    const languages: any = {
      EN: 'English',
      DE: 'German',
      CS: 'Czech',
      IT: 'Italian',
      ES: 'Spanish',
      FR: 'French',
    };
    return languages[language];
  }
}
