<div class="w-full" [ngClass]="background === 'white' ? 'bg-white' : 'bg-culturedWhite'" *ngIf="user">
  <ng-container *ngIf="price$ | async as si_price">
    <ng-container *ngIf="price_sami_x_month$ | async as sxm_price">
      <ng-container *ngIf="price_sami_x_year$ | async as sxy_price">
        <!-- xs to lg -->
        <div class="mx-auto max-w-2xl space-y-16 lg:hidden">
          <section>
            <app-membership-card
              [location]="location"
              [background]="background === 'white' ? 'culturedWhite' : 'white'"
              [user]="user"
              [samiInternationalPricing]="si_price"
              [hasPlusMembership]="user.subscriptionDigest?.hasSubscription?.plus || false"
              [hasInstructorMembership]="user.subscriptionDigest?.hasSubscription?.instructor || false"
              [hasSubscription]="user.subscriptionDigest?.hasSubscription?.basic || false"
              [hadSubscription]="user.subscriptionDigest?.hadSubscription?.basic || false"
            >
            </app-membership-card>

            <ng-container *ngIf="table">
              <ng-container *ngFor="let row of table">
                <table class="w-full">
                  <caption
                    *ngIf="row.type === 'header'"
                    class="border-t border-gray-200 bg-gray-50 py-3 px-4 text-left text-base font-semibold text-cadetGrey-900"
                  >
                    {{
                      row.label
                    }}
                  </caption>
                  <thead *ngIf="row.type === 'header'">
                    <tr>
                      <th class="sr-only" scope="col">{{ row.label }}</th>
                      <th class="sr-only" scope="col">Included</th>
                    </tr>
                  </thead>
                  <tbody
                    class="divide-y divide-gray-200"
                    *ngIf="row.type === 'body'"
                  >
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-left text-sm font-normal font-body text-richBlack"
                        scope="row"
                      >
                        {{ row.label }}
                      </th>
                      <td
                        class="py-5 pr-4 text-right flex flex-row justify-end"
                      >
                        <ng-container
                          *ngIf="row.table_cells[0].type === 'included'"
                        >
                          <svg
                            width="21"
                            height="15"
                            viewBox="0 0 21 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19.566 0L7.76503 11.6L2.23803 6.13L0.832031 7.552L7.05903 13.716C7.24618 13.9007 7.49856 14.0043 7.76153 14.0043C8.0245 14.0043 8.27688 13.9007 8.46403 13.716L20.964 1.424L19.566 0Z"
                              fill="#001427"
                            />
                          </svg>
                          <span class="sr-only">Included</span>
                        </ng-container>

                        <ng-container
                          *ngIf="row.table_cells[0].type === 'excluded'"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.125 1.125L14.875 14.875"
                              stroke="#C0CBD1"
                              stroke-width="2"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M14.875 1.125L1.125 14.875"
                              stroke="#C0CBD1"
                              stroke-width="2"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <span class="sr-only">Included</span>
                        </ng-container>

                        <ng-container
                          *ngIf="row.table_cells[0].type === 'text'"
                        >
                          <div
                            class="block text-right font-body text-sm text-cadetGrey-600"
                            [innerHTML]="row.table_cells[0].text"
                          ></div>
                        </ng-container>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
            </ng-container>
          </section>

          <section>
            <app-membership-card
              [location]="location"
              [background]="background === 'white' ? 'culturedWhite' : 'white'"
              [user]="user"
              [samiXPricingYear]="sxy_price"
              [hasBasicMembership]="user.subscriptionDigest?.hasSubscription?.basic || false"
              [samiXPricingMonth]="sxm_price"
              [hasSubscription]="user.subscriptionDigest?.hasSubscription?.plus || false"
              [hadSubscription]="user.subscriptionDigest?.hadSubscription?.plus || false"
            >
            </app-membership-card>

            <ng-container *ngIf="table">
              <ng-container *ngFor="let row of table">
                <table class="w-full">
                  <caption
                    *ngIf="row.type === 'header'"
                    class="border-t border-gray-200 bg-gray-50 py-3 px-4 text-left text-base font-semibold text-cadetGrey-900"
                  >
                    {{
                      row.label
                    }}
                  </caption>
                  <thead *ngIf="row.type === 'header'">
                    <tr>
                      <th class="sr-only" scope="col">{{ row.label }}</th>
                      <th class="sr-only" scope="col">Included</th>
                    </tr>
                  </thead>
                  <tbody
                    class="divide-y divide-gray-200"
                    *ngIf="row.type === 'body'"
                  >
                    <tr class="border-t border-gray-200">
                      <th
                        class="py-5 px-4 text-left text-sm font-normal font-body text-richBlack"
                        scope="row"
                      >
                        {{ row.label }}
                      </th>
                      <td
                        class="py-5 pr-4 text-right flex flex-row justify-end"
                      >
                        <ng-container
                          *ngIf="row.table_cells[1].type === 'included'"
                        >
                          <svg
                            width="21"
                            height="15"
                            viewBox="0 0 21 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19.566 0L7.76503 11.6L2.23803 6.13L0.832031 7.552L7.05903 13.716C7.24618 13.9007 7.49856 14.0043 7.76153 14.0043C8.0245 14.0043 8.27688 13.9007 8.46403 13.716L20.964 1.424L19.566 0Z"
                              fill="#001427"
                            />
                          </svg>
                          <span class="sr-only">Included</span>
                        </ng-container>

                        <ng-container
                          *ngIf="row.table_cells[1].type === 'excluded'"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.125 1.125L14.875 14.875"
                              stroke="#C0CBD1"
                              stroke-width="2"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M14.875 1.125L1.125 14.875"
                              stroke="#C0CBD1"
                              stroke-width="2"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <span class="sr-only">Included</span>
                        </ng-container>

                        <ng-container
                          *ngIf="row.table_cells[1].type === 'text'"
                        >
                          <div
                            class="block text-right font-body text-sm text-cadetGrey-600"
                            [innerHTML]="row.table_cells[1].text"
                          ></div>
                        </ng-container>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
            </ng-container>
          </section>
        </div>

        <!-- lg+ -->
        <div class="hidden lg:block">
          <div class="grid grid-cols-3 gap-6">
            <div></div>
            <app-membership-card
              [location]="location"
              [background]="background === 'white' ? 'culturedWhite' : 'white'"
              [user]="user"
              [samiInternationalPricing]="si_price"
              [hasPlusMembership]="
                user.subscriptionDigest?.hasSubscription?.plus || false
              "
              [hasInstructorMembership]="
                user.subscriptionDigest?.hasSubscription?.instructor || false
              "
              [hasSubscription]="
                user.subscriptionDigest?.hasSubscription?.basic || false
              "
              [hadSubscription]="user.subscriptionDigest?.hadSubscription?.basic || false"
            >
            </app-membership-card>

            <app-membership-card
              [location]="location"
              [user]="user"
              [samiXPricingYear]="sxy_price"
              [hasBasicMembership]="
                user.subscriptionDigest?.hasSubscription?.basic || false
              "
              [samiXPricingMonth]="sxm_price"
              [hasSubscription]="
                user.subscriptionDigest?.hasSubscription?.plus || false
              "
              [hadSubscription]="user.subscriptionDigest?.hadSubscription?.plus || false"
            >
            </app-membership-card>
          </div>

          <table class="h-px w-full table-fixed mt-6">
            <caption class="sr-only">
              Pricing plan comparison
            </caption>
            <thead>
              <tr>
                <th
                  class="px-6 pb-4 w-1/3 text-left text-lg font-semibold text-cadetGrey-900"
                  scope="col"
                >
                  <span class="sr-only">Feature by</span>
                  <span>Features</span>
                </th>

                <th
                  class="w-1/3 px-6 pb-4 text-center text-lg font-semibold leading-6 text-richBlack"
                  scope="col"
                >
                  SAMI International
                </th>

                <th
                  class="w-1/3 px-6 pb-4 text-center text-lg font-semibold leading-6 text-richBlack"
                  scope="col"
                >
                  SAMI-X
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 border-t border-gray-200">
              <ng-container *ngIf="table">
                <ng-container *ngFor="let row of table">
                  <tr *ngIf="row.type === 'header'">
                    <th
                      class="bg-gray-50 py-3 pl-6 text-left text-lg font-semibold text-cadetGrey-900"
                      colspan="3"
                      scope="colgroup"
                    >
                      {{ row.label }}
                    </th>
                  </tr>

                  <tr *ngIf="row.type === 'body'">
                    <th
                      class="py-5 px-6 text-left text-base font-body font-normal text-richBlack"
                      scope="row"
                    >
                      {{ row.label }}
                    </th>

                    <td
                      class="py-5 px-6 text-center"
                      *ngFor="let cell of row.table_cells"
                    >
                      <div class="flex flex-row justify-center items-center">
                        <ng-container *ngIf="cell.type === 'included'">
                          <svg
                            width="21"
                            height="15"
                            viewBox="0 0 21 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19.566 0L7.76503 11.6L2.23803 6.13L0.832031 7.552L7.05903 13.716C7.24618 13.9007 7.49856 14.0043 7.76153 14.0043C8.0245 14.0043 8.27688 13.9007 8.46403 13.716L20.964 1.424L19.566 0Z"
                              fill="#001427"
                            />
                          </svg>
                          <span class="sr-only">Included</span>
                        </ng-container>

                        <ng-container *ngIf="cell.type === 'excluded'">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.125 1.125L14.875 14.875"
                              stroke="#C0CBD1"
                              stroke-width="2"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M14.875 1.125L1.125 14.875"
                              stroke="#C0CBD1"
                              stroke-width="2"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <span class="sr-only">Included</span>
                        </ng-container>

                        <ng-container *ngIf="cell.type === 'text'">
                          <div
                            class="block text-center font-body text-base text-cadetGrey-600"
                            [innerHTML]="cell.text"
                          ></div>
                        </ng-container>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>