import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsCardComponent } from './products-card.component';
import { GetDownloadURLPipeModule } from '@angular/fire/compat/storage';
import { ImagePipe } from '../../pipes/transformImage.pipe';

@NgModule({
  declarations: [ProductsCardComponent],
  imports: [CommonModule, GetDownloadURLPipeModule, ImagePipe],
  exports: [ProductsCardComponent],
})
export class ProductsCardModule {}
