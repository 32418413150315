import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import Client from 'storyblok-js-client';
@Injectable({
  providedIn: 'root',
})
export class StoryblokService {
  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {}

  async getStories(
    params?: any,
    token: string = 'qEvv5t70UPiLTMDB0a80OAtt'
  ): Promise<any> {
    try {
      const cv = Date.now();
      const res = await this.http
        .get(
          `https://api.storyblok.com/v2/cdn/stories?starts_with=${params}&is_startpage=0&token=${token}&cv=${cv}`
        )
        .toPromise();
      return res;
    } catch (e) {
      const error: any = e;
      if (error && error.status == 404) {
        this.router.navigate(['/page-not-found']);
      }
      return Promise.reject(error);
    }
  }

  async getStory(
    story: string,
    language: string = 'en',
    site: string = 'sami-international',
    token: string = 'qEvv5t70UPiLTMDB0a80OAtt'
  ): Promise<any> {
    try {
      // const space: any = await this.http.get(`https://api.storyblok.com/v1/cdn/spaces/me?token=${token}`).toPromise();
      const cv = Date.now();
      let res;
      if (language !== 'en') {
        res = await lastValueFrom(this.http
          .get(
            `https://api.storyblok.com/v2/cdn/stories/${site}/${story}?token=${token}&language=${language}&cv=${cv}`
          ));
      } else {
        res = await lastValueFrom(this.http
          .get(
            `https://api.storyblok.com/v2/cdn/stories/${site}/${story}?token=${token}&cv=${cv}`
          ));
      }
      return res;
    } catch (e) {
      console.error(e);
      const error: any = e;
      // if (error && error.status == 404) {
      //   this.router.navigate(['/' + language + '/page-not-found']);
      // }
      return Promise.reject(error);
    }
  }

  async getGeneralStory(
    story: string,
    language: string = 'en',
    token: string = 'qEvv5t70UPiLTMDB0a80OAtt'
  ): Promise<any> {
    try {
      // const space: any = await this.http.get(`https://api.storyblok.com/v1/cdn/spaces/me?token=${token}`).toPromise();
      const cv = Date.now();
      let res;
      if (language !== 'en') {
        res = await this.http
          .get(
            `https://api.storyblok.com/v2/cdn/stories/${story}?token=${token}&language=${language}&cv=${cv}`
          )
          .toPromise();
      } else {
        res = await this.http
          .get(
            `https://api.storyblok.com/v2/cdn/stories/${story}?token=${token}&cv=${cv}`
          )
          .toPromise();
      }
      return res;
    } catch (e) {
      console.error(e);
      const error: any = e;
      if (error && error.status == 404) {
        this.router.navigate(['/' + language + '/page-not-found']);
      }
      return Promise.reject(error);
    }
  }

  resolveRichText(richtext_field: any) {
    if (isPlatformBrowser(this.platformId)) {
      if (!richtext_field) return '';

      const sbClient = new Client({
        accessToken: 'qEvv5t70UPiLTMDB0a80OAtt',
        cache: {
          clear: 'auto',
          type: 'memory',
        },
      })

      return sbClient.richTextResolver.render(richtext_field);
    }
  }

  transformImage(image: any, option: any) {
    if (!image) return '';
    if (!option) return '';

    const imageService = 'https://img2.storyblok.com/';
    const path = image.replace('https://a.storyblok.com', '');
    return imageService + option + path;
  }
}
