export interface SamiCenter {
  [key: string]: any;
  id?: string;
  name?: string;
  phone?: string;
  address?: string;
  addressComponents?: any;
  place_id?: any;
  trainingSpace?: number;
  geolocation?:
    | {
        lat?: any;
        lng?: any;
        point?: any;
      }
    | any;
  country?: {
    name?: string;
    code?: string;
  };
  type?: 'center' | 'academy';
  blacklistedSystemIdentifiers?: string[];
  blacklistedInstructorIds?: string[];
  galleryImagePaths?: string[];
  rewardfulToken?: string;
  rewardfulId?: string;
  email?: string;
  website?: string;
  videoId?: string;
  images?: any[];
  visibility?: boolean;
  slug?: string;
  description?: string;
  subtitle?: string;
  isPartner?: boolean;
  commission?: number;
  visitations?: number;
  stripeAccountId?: string;
  stripeAccountEnabled?: boolean;
  whiteLogoPath?: string;
  blackLogoPath?: string;
}

export interface Activities {
  [key: string]: any;
  activityGroup: ActivitieGroup;
  bookable: Bookable | null;
  end: Date;
  hasOnlineStream: boolean;
  id: string;
  isArchived: boolean;
  isCanceled: boolean;
  limited: Limited;
  location: Location;
  name: string;
  room: Room;
  start: Date;
  teacher: Teacher;
}

export interface CenterCommission {
  centerId?: string;
  commissionAmount?: number;
  convertedAmount?: number;
  convertedCurrency?: string;
  currency?: string;
  date?: any;
  exchangeRate?: number;
  invoiceId?: string;
  isCommission?: boolean;
  mmcAmount?: number;
  mmcCalculateDate?: any;
  name?: string;
  originalAmount?: number;
  originalCurrency?: string;
  percent?: number;
  products: {
    name: string;
    period: {
      end: any;
      start: any;
    }
  }[];
  stripeCustomerId?: string;
  userId: string;
  userRef: any;
}

export interface CenterCommissionTotal {
  centerId?: string;
  centerName?: string;
  centerRef?: any;
  total_commission?: number;
  unpaid_commission?: number;
  activeMember: number;
  mmc: number;
  mmcRank?: number;
  totalCenters?: number;
};

export interface ActivitieGroup {
  [key: string]: any;
  bookable: Bookable | null;
  color?: string;
  description?: string;
  id: string;
  image?: string;
  limited?: Limited;
  name: string;
  publication_state?: 'active' | 'archived' | 'view_only' | 'hidden';
  sport?: Sport;
  type?: 'camp' | 'course' | 'event' | 'training' | 'class' | 'workshop';
  venue?: Venue;
}

interface Venue {
  company: Company;
  id: string;
  images: string[];
  location: Location;
  name: string;
  teachers: Teacher[];
}

interface Company {
  id: string;
  name: string;
  venues: Venue[];
}

interface Sport {
  id: string;
  name: string;
}

interface Bookable {
  bookableWindowEnd: Date;
  bookableWindowStart: Date;
  cancellationDeadline: Date;
  checkoutURL: string;
}

interface Limited {
  freeSpots?: number;
  totalSpots?: number;
}

interface Location {
  city?: string;
  coordinates?: Coordinates;
  country?: string;
  name?: string;
  street?: string;
  type: 'location_type_outdoor' | 'location_type_indoor';
  zip?: string;
}

interface Coordinates {
  latitude: number;
  longitude: number;
}

interface Room {
  id: string;
  name: string;
}

interface Teacher {
  description?: string;
  education?: string;
  gender?: 'diverse' | 'male' | 'female';
  id: string;
  image?: string;
  name: string;
}
