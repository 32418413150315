import { DocumentReference } from "@angular/fire/firestore";

export interface GuidedTraining {
  _id?: string;
  objectID?: string;
  id: string;
  description?: string;
  excerpt?: string;
  imageDetailPath?: string;
  instructor?: {
    displayName: string;
    id: string;
    photoURL: string;
    ref: DocumentReference;
  };
  isFree?: boolean;
  hasPurchased?: boolean;
  hasAccess?: boolean;
  muxVideoElement: MuxVideoElement;
  muxVideoElementId: string;
  muxVideoElementRef: DocumentReference;
  numberOfParticipants: 'single' | 'partner';
  rounds: number;
  system: System;
  status: 'draft' | 'published';
  thumbnailPath?: string;
  title: string;
  weeks: number[];
  exercises: number;
  trackConnections: {
    chapterId: string;
    chapterTitle: string;
    trackId: string;
    trackTitle: string;
  }[];
  trackChapterIds: string[];
}

export interface MuxVideoElement {
  _id?: string;
  asset: {
    aspect_ratio: string;
    created_at: number;
    duration: number;
    id: string;
    master_access: string;
    max_stored_resolution: string;
    max_stored_frame_rate: number;
    playback_ids: {
      id: string;
      policy: string;
    }[];
    status: string;
    upload_id: string;
    tracks: {
      duration: number;
      id: string;
      max_stored_frame_rate?: number;
      max_height?: number;
      max_width?: number;
      type: string;
      max_channels?: number;
      max_channel_layout?: string;
    }[];
  }
  assetType: string;
  description: string;
  difficulty: number;
  editorStatus: string;
  level: number;
  searchIndex: string[];
  status: string;
  system: string;
  tags: string[];
  title: string;
  uploadId: string;
}

export interface System {
  category: string;
  categoryText: string;
  color: string;
  identifier: string;
  title: string;
}