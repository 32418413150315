export interface SamiStyleModel {
  [key: string]: any;
  title: string;
  identifier:
    | 'kravMaga'
    | 'panantukan'
    | 'axe'
    | 'stick'
    | 'knife'
    | 'ebc'
    | 'kapap'
    | 'core';
  description: string;
  color1: string;
  color2: string;
  fontFamily: string;
  fontWeight: string;
  levels?: number;
  hasAccess?: boolean;
}
