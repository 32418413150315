import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileCardComponent } from './profile-card.component';
import { GetDownloadURLPipeModule } from '@angular/fire/compat/storage';
import { ImagePipe } from '../../pipes/transformImage.pipe';

@NgModule({
  declarations: [ProfileCardComponent],
  imports: [CommonModule, ImagePipe, GetDownloadURLPipeModule],
  exports: [ProfileCardComponent],
})
export class ProfileCardModule {}
