import { Injectable, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root',
})
export class StripeService implements OnInit {
  constructor(private afs: AngularFirestore) {}

  async ngOnInit() {}

  getStripeProduct$(stripeProductId: any) {
    return this.afs.doc(`stripeProducts/${stripeProductId}`).valueChanges();
  }

  getStripePrice$(stripeProductId: any, stripePriceId: any) {
    return this.afs
      .doc(`stripeProducts/${stripeProductId}/prices/${stripePriceId}`)
      .valueChanges();
  }

  getStripeUser$(stripeCustomerId: any) {
    return this.afs.doc(`stripeUsers/${stripeCustomerId}`).valueChanges();
  }

  getStripePrices$(
    stripeProductId: any,
    currency: string,
    interval?: undefined
  ) {
    if (interval) {
      return this.afs
        .collection(`stripeProducts/${stripeProductId}/prices`, (ref) =>
          ref
            .where('currency', '==', currency.toLowerCase())
            .where('active', '==', true)
            .where('recurring.interval', '==', interval)
        )
        .valueChanges();
    } else {
      return this.afs
        .collection(`stripeProducts/${stripeProductId}/prices`, (ref) =>
          ref
            .where('currency', '==', currency.toLowerCase())
            .where('active', '==', true)
        )
        .valueChanges();
    }
  }

  getProductPrices(productId: any, currency: string) {
    this.getStripePrices$(productId, currency).subscribe((s) => {
      if (s.length > 0) {
        return {
          price: s,
          convertedPrice: false,
        };
      } else {
        this.getStripePrices$(productId, 'EUR').subscribe((s) => {
          return {
            price: s,
            convertedPrice: true,
          };
        });
      }

      return false;
    });
  }
}
