import { Timestamp } from "@angular/fire/firestore";

export interface SamiSeminar {
  [key: string]: any;
  id?: string;

  stripeProductId?: string;

  // auto values
  stats?: {
    participantCount?: number;
    pageViews?: number;
    ticketsSold?: number; // TODO: should be private
    revenue?: number;
  };

  trailer?: {
    url: string;
    provider: 'YouTube' | 'Vimeo' | 'MUX';
    id: string;
  }

  isPublished?: boolean;
  slug?: string;

  tickets?: any;

  targetGroup?: any; // TODO

  // basic info
  title?: string;
  seminarType?: 'online' | 'location' | 'both';
  organizer?: any; // Center | Instructor
  organizerType?: 'center' | 'instructor';
  location?: {
    address?: string;
    geolocation?: {
      lat?: any;
      lng?: any;
      point?: any;
    };
    country?: {
      name?: string;
      code?: string;
      vat?: number;
    };
  } | null;
  eventStartsAt?: Date | number | any;
  eventEndsAt?: Date | number | any;
  eventDateInfo?: string;
  language?: string;

  // details
  imageUrl?: string;
  system?: string;
  instructors?: {
    id?: string;
    ref?: any;
    name?: string;
    imageUrl?: string;
    thumbnail?: string; // VALENTIN
    instructorRank?: number; // VALENTIN
    displayName?: string; // VALENTIN
  }[];
  instructorIds?: string[];
  targetGroups?: SamiSeminarTargetGroup[];
  targetGroupStrings?: string[];
  includesCertification?: boolean;
  certificationComment?: string;
  sellingPoints?: string[];
  requirements?: string[];
  summary?: {
    content?: string;
    translations?: {
      [key: string]: string; // key = languageCode // DE: 'Hallo Welt'
    };
  };
  description?: {
    content?: string;
    translations?: {
      [key: string]: string; // key = languageCode // DE: 'Hallo Welt'
    };
  };
  hmsRoomId?: string;

  // tickets
  ticketsForSale?: number;
  ticketLimit?: number;
  ticketTypes?: SeminarTicketType[];

  // promotion
  poster?: {
    imageUrl?: string;
    pdfUrl?: string;
  };
  flyer?: {
    imageUrl?: string;
    pdfUrl?: string;
  };
  facebookBanner?: {
    imageUrl?: string;
    pdfUrl?: string;
  }
  ogImage?: {
    imageUrl?: string;
    pdfUrl?: string;
  };

  notificationsSent?: {
    before?: boolean;
    after?: boolean;
  };

  timeZone?: string;
  locale?: string;

  tracks?: SeminarTrack[];
  trackIds?: string[];

  liveClasses?: SeminarLiveClass[];
  hasLiveClasses?: boolean;
  hasFixedStartDate?: boolean;
  space?: any;
}

export interface SeminarTicketType {
  paymentOptions?: 'online' | 'counter' | 'both';
  stripePriceId?: string;
  name?: string;
  targetGroups?: string;
  price?: number;
  currency?: string;
  allowGuestCheckout?: boolean;
  saleStartsAt?: Date | number | any;
  saleEndsAt?: Date | number | any;
  shortDescription?: string;
  description?: {
    content?: string;
    translations?: {
      [key: string]: string; // key = languageCode // DE: 'Hallo Welt'
    };
  };
  sold?: number;
  limit?: number;
  isActive?: boolean;
}

export interface SeminarTrack {
  id?: string;
  trackTitle?: string;
  ticketTypeIds?: string[];
  accessStart?: {
    baseDate?: 'purchase' | 'eventStartsAt' | 'eventEndsAt';
    manipulatorDays?: number;
  };
  accessEnd?: {
    baseDate?: 'purchase' | 'eventStartsAt' | 'eventEndsAt';
    manipulatorDays?: number;
  };
}

export interface SeminarLiveClass {
  title?: string;
  description?: string;
  ticketTypeIds?: string[];
  start?: Date | number | any;
  end?: Date | number | any;
  recordingId?: string;
}

export interface SamiSeminarTargetGroup {
  group?: string; // 'instructors' | 'students' | 'professionals'
  hasCertification?: boolean;
  certificationInformation?: string;
  levels?: number[];
}

export interface SamiSeminarParticipant {
  [key: string]: any;
  seminarId?: string;

  userId?: string;
  userRef?: any;
  customerData?: any;

  ticketId?: string;
  ticketRef?: any;

  bookingDate?: Date | number | any;
}

export interface SamiSeminarTicket {
  [key: string]: any;
  seminarId?: string;
  id?: string;
  _id?: string;
  userId?: string;
  userRef?: any;
  customerData?: any;

  ticketStripePriceId?: string; // ref f�r ticketType

  bookingDate?: Date | number | any;
  paymentOption?: string;
  paymentStatus?:
  | 'processing'
  | 'succeeded'
  | 'canceled'
  | 'requires_payment_method'
  | 'requires_confirmation'
  | 'requires_action'
  | 'requires_capture'
  | 'refunded';
  ticketStatus?: 'pending' | 'active' | 'canceled';
  checkinStatus?: null | 'open' | 'checkedIn' | 'noShow';

  seminarType?: 'online' | 'location' | 'both';
  system?: string;

  eventStartsAt?: Timestamp;

  price?: number;
  currency?: string;

  invoiceUrl?: string;
  invoicePdf?: string;
  ticketPdf?: string;
}

export interface SeminarMessage {
  parentId: string | null;
  seminarId: string;
  senderName: string;
  subject: string;
  replyTo: string;
  templateId: number;
  content: string;
  ticketTypeIds: string[];
  status: 'draft' | 'scheduled' | 'sent' | 'archived';
  scheduledAt: Date | any;
  scheduledAtEvent: string | 'custom' | 'now' | 'purchase' | 'eventStartsAt' | 'eventEndsAt' | null;
  scheduledAtEventManipulator: number | null;
  createdAt: Date | any;
  recipients?: string[];
  sentAt?: Date | any;
}

export interface SeminarMessageTemplate {
  id: string;
  content: string;
  subject: string;
}