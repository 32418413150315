import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { SystemsPlaylist, SystemsPlaylistUserProgress } from 'interfaces';
import {
  combineLatest,
  lastValueFrom,
  map,
  Observable,
  of,
  switchMap,
  take,
} from 'rxjs';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class PlaylistService {
  constructor(private auth: AuthService, private afs: AngularFirestore) {}

  public getOwnPlaylist$(
    playlistId: string
  ): Observable<SystemsPlaylist | undefined> {
    return this.auth.authUser$.pipe(
      switchMap((authUser: any) =>
        authUser?.uid
          ? this.afs
              .doc<SystemsPlaylist>(
                `users/${authUser.uid}/userSystemsPlaylists/${playlistId}`
              )
              .valueChanges({ idField: '_id' })
          : of(undefined)
      )
    );
  }

  // Function to get all playlists shared with a specific user
  getSharedPlaylists$(userId: string): Observable<SystemsPlaylist[]> {
    return this.afs.collectionGroup<SystemsPlaylist>('userSystemsPlaylists', ref => ref.where('invitedUsers', 'array-contains', userId))
      .valueChanges();
  }

  getUserGeneratedPlaylist$(playlistId: string, userId?: string): Observable<SystemsPlaylist | null> {

    return this.afs.collectionGroup<SystemsPlaylist>('userSystemsPlaylists', ref => ref.where('_id', '==', playlistId))
      .valueChanges()
      .pipe(
        map(playlists => {
          if (playlists.length) {
            const playlist = playlists[0];
            // Check if the user is invited
            playlist._isInvited = playlist.invitedUsers && userId ? playlist.invitedUsers.includes(userId) : false;
            // Check if the user is the creator
            // Assuming createdBy is a Firestore Document Reference
            playlist._isCreator = playlist.createdBy && userId ? playlist.createdBy.id === userId : false;
            return playlist;
          }
          return null;
        })
      );
  }

  public getSystemPlaylists$(): Observable<SystemsPlaylist[]> {
    return this.afs
      .collection<SystemsPlaylist[]>(`systemsPlaylists`, (ref) =>
        ref.where('status', '==', 'public')
      )
      .valueChanges({ idField: '_id' });
  }

  public getSystemPlaylist$(playlistId: string): Observable<SystemsPlaylist> {
    return combineLatest([
      this.afs
        .doc<any>(`systemsPlaylists/${playlistId}`)
        .valueChanges({ idField: '_id' }),
      this.auth.authUser$.pipe(
        switchMap((authUser: any) =>
          authUser?.uid
            ? this.afs
                .doc<any>(
                  `systemsPlaylists/${playlistId}/systemsPlaylistsUserProgress/${authUser?.uid}`
                )
                .valueChanges({ idField: '_id' })
            : of(null)
        )
      ),
    ]).pipe(
      map(([playlist, userProgress]) => {
        if (userProgress?.finishedClips?.length) {
          const chapters = [];
          for (const chapter of playlist.chapters) {
            const clips = [];
            for (const clip of chapter.clips) {
              clips.push({
                ...clip,
                finished: userProgress.finishedClips.includes(clip.ref.id),
              });
            }
            chapters.push({
              ...chapter,
              clips,
              finished: clips.every((clip: any) => clip.finished),
            });
          }
          playlist.chapters = chapters;

          playlist = {
            ...playlist,
            completed: userProgress.completed || false,
            completedAt: userProgress.completedAt || null,
            progress: userProgress.progress || 0,
          };
        }
        return playlist;
      })
    );
  }

  public getOwnPlaylists$(): Observable<SystemsPlaylist[]> {
    return this.auth.authUser$.pipe(
      switchMap((authUser: any) =>
        authUser?.uid
          ? this.afs
              .collection<SystemsPlaylist[]>(
                `users/${authUser.uid}/userSystemsPlaylists`
              )
              .valueChanges({ idField: '_id' })
          : of([])
      )
    );
  }

  public getActiveUserPlaylists$(): Observable<SystemsPlaylistUserProgress[]> {
    return this.auth.authUser$.pipe(
      switchMap((authUser: any) =>
        authUser?.uid
          ? this.afs
              .collectionGroup<SystemsPlaylistUserProgress[]>(
                `systemsPlaylistsUserProgress`,
                (ref) =>
                  ref
                    .where('userId', '==', authUser.uid)
                    .where('completed', '==', false)
              )
              .valueChanges({ idField: '_id' })
          : of([])
      )
    );
  }

  public async addOwnPlaylist(playlist: SystemsPlaylist): Promise<any> {
    const { uid } = await lastValueFrom(this.auth.authUser$.pipe(take(1)));
    return uid
      ? this.afs.collection(`users/${uid}/userSystemsPlaylists`).add({
          ...playlist,
      })
      : Promise.reject('Not Logged In');
  }

  public async updateOwnPlaylist(
    playlistId: string,
    playlist: SystemsPlaylist
  ): Promise<any> {
    const { uid } = await lastValueFrom(this.auth.authUser$.pipe(take(1)));
    return uid
      ? this.afs
          .doc(`users/${uid}/userSystemsPlaylists/${playlistId}`)
          .set({
            ...playlist,
            createdBy: this.afs.doc('users/' + uid).ref,
          }, { merge: true })
      : Promise.reject('Not Logged In');
  }

  public async deleteOwnPlaylist(playlistId: string): Promise<any> {
    const { uid } = await lastValueFrom(this.auth.authUser$.pipe(take(1)));
    return uid
      ? this.afs.doc(`users/${uid}/userSystemsPlaylists/${playlistId}`).delete()
      : Promise.reject('Not Logged In');
  }

  public async markVideoInPlaylistAsFinished(
    clipId: string,
    playlistId: string
  ): Promise<any> {
    const { uid } = await lastValueFrom(this.auth.authUser$.pipe(take(1)));
    const userProgress = await lastValueFrom(
      this.afs
        .doc<SystemsPlaylistUserProgress>(
          `/systemsPlaylists/${playlistId}/systemsPlaylistsUserProgress/${uid}`
        )
        .valueChanges({ idField: '_id' })
        .pipe(take(1))
    );
    await this.afs
      .doc(
        `/systemsPlaylists/${playlistId}/systemsPlaylistsUserProgress/${uid}`
      )
      .set(
        {
          finishedClips: Array.from(
            new Set([...(userProgress?.finishedClips || []), clipId])
          ),
          lastWatchedClip: clipId,
          update: true,
        },
        { merge: true }
      );
  }
}
