import { DocumentReference } from "@angular/fire/compat/firestore";
import { MuxVideoElement } from "./guided-training.model";

export interface SystemsPlaylist {
  [key: string]: any;
  _id?: string;
  _isInvited?: boolean;
  _isCreator?: boolean;
  title?: string;
  isDefaultPlaylist?: boolean;
  subtitle?: string;
  description?: string;
  pdfUrl?: string;

  styles?: string[]; // only for displaying information, no logic -> optional
  clips?: string[];
  status?: 'hidden' | 'public';
  invitedUsers?: string[]; // Array of user IDs who have been invited to the playlist
  chapters?: {
    clips?: any[];
    title?: string;
    description?: string;
    interactiveBreaks?: {
      type: string;
      duration: number;
      timestamp: number;
      title: string;
      description: string;
      repetitions?: number;
    }[];
  }[];
  permissionStyle?: 'clip' | 'open' | 'rules' | 'invite';
  permissionRules?: {
    adminOnly?: boolean;
    subscription?: string;
    style?: string;
    level?: number;
  }[]; // all permissionRules will be evaluated with OR so only ONE rule must resolve to true
  createdBy?: DocumentReference | any; // user ref or text or whatever
  thumbnailPath?: string;
  introVideoElement?: MuxVideoElement;
  introVideoId?: string;
  excerpt?: string;
  progress?: number;
  completed?: boolean;
}

export interface SystemsPlaylistUserProgress {
  [key: string]: any;
  finishedClips?: string[];
  lastWatchedClip?: string;
  playlistId?: string;
  createdAt?: Date | any;
  updatedAt?: Date | any;
  completed?: boolean;
  completedAt?: Date | any;
  progress?: number;
  expired?: boolean;
  expiredAt?: Date | any;
  playlistTitle?: string;
  userId?: string;
}
