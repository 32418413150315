<div
  class="bg-white relative px-4 sm:px-10 py-3 sm:py-6 max-w-[90vw] sm:max-w-[80vw] w-full"
>
  <button
    (click)="dialogRef.close()"
    class="z-20 absolute w-4 h-4 right-4 top-4"
  >
    <svg
      class="w-4 h-4"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2655_19739)">
        <path
          d="M1 1L23 23"
          stroke="black"
          stroke-width="2"
          stroke-linejoin="round"
        />
        <path
          d="M23 1L1 23"
          stroke="black"
          stroke-width="2"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2655_19739">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </button>

  <div
    class="absolute right-0 top-0 bottom-0 overflow-hidden hidden sm:block pointer-events-none"
    *ngIf="data.system"
  >
    <ng-container
      *ngIf="returnSystemComponent(data.system, 'category') === 'defense'"
    >
      <picture>
        <source
          type="image/webp"
          [srcset]="
            'https://a.storyblok.com/f/110844/413x820/c646fb1006/x_popup.png'
              | transformImg
                : 'storyblok'
                : 'fit-in/0x800/smart/filters:format(webp)'
          "
        />
        <source
          type="image/png"
          [srcset]="
            'https://a.storyblok.com/f/110844/413x820/c646fb1006/x_popup.png'
              | transformImg
                : 'storyblok'
                : 'fit-in/0x800/smart/filters:format(png)'
          "
        />
        <img
          loading="lazy"
          class="h-full"
          [src]="
            'https://a.storyblok.com/f/110844/413x820/c646fb1006/x_popup.png'
              | transformImg: 'storyblok':'fit-in/0x800/smart'
          "
          [alt]="'Onboarding Step'"
        />
      </picture>
    </ng-container>

    <ng-container
      *ngIf="returnSystemComponent(data.system, 'category') === 'pro'"
    >
      <picture>
        <source
          type="image/webp"
          [srcset]="
            'https://a.storyblok.com/f/110844/413x820/022b17d1d8/x_popup_pro.png'
              | transformImg
                : 'storyblok'
                : 'fit-in/0x800/smart/filters:format(webp)'
          "
        />
        <source
          type="image/png"
          [srcset]="
            'https://a.storyblok.com/f/110844/413x820/022b17d1d8/x_popup_pro.png'
              | transformImg
                : 'storyblok'
                : 'fit-in/0x800/smart/filters:format(png)'
          "
        />
        <img
          loading="lazy"
          class="h-full"
          [src]="
            'https://a.storyblok.com/f/110844/413x820/022b17d1d8/x_popup_pro.png'
              | transformImg: 'storyblok':'fit-in/0x800/smart'
          "
          [alt]="'Onboarding Step'"
        />
      </picture>
    </ng-container>

    <ng-container
      *ngIf="returnSystemComponent(data.system, 'category') === 'fightingArt'"
    >
      <picture>
        <source
          type="image/webp"
          [srcset]="
            'https://a.storyblok.com/f/110844/413x820/ed5fe54e15/x_popup_fa.png'
              | transformImg
                : 'storyblok'
                : 'fit-in/0x800/smart/filters:format(webp)'
          "
        />
        <source
          type="image/png"
          [srcset]="
            'https://a.storyblok.com/f/110844/413x820/ed5fe54e15/x_popup_fa.png'
              | transformImg
                : 'storyblok'
                : 'fit-in/0x800/smart/filters:format(png)'
          "
        />
        <img
          loading="lazy"
          class="h-full"
          [src]="
            'https://a.storyblok.com/f/110844/413x820/ed5fe54e15/x_popup_fa.png'
              | transformImg: 'storyblok':'fit-in/0x800/smart'
          "
          [alt]="'Onboarding Step'"
        />
      </picture>
    </ng-container>
  </div>

  <div
    class="max-w-xl flex flex-col justify-start items-start space-y-2 sm:space-y-4 z-10 relative"
  >
    <span class="text-lg md:text-3xl font-semibold text-richBlack">
      Get certified online!
    </span>
    <span class="text-sm md:text-base text-richBlack">
      Get your official SAMI diploma at home. With our new online certification
      process it has never been easier to become SAMI certified!
    </span>
    <div class="flex flex-col justify-start space-y-1 sm:space-y-2">
      <span class="text-base md:text-lg font-semibold text-richBlack">
        Here’s how it works:
      </span>
      <div class="flex flex-col justify-start space-y-2 sm:space-y-8">
        <div class="flex flex-row justify-start items-start space-x-4">
          <div
            class="w-5 h-5 md:w-10 md:h-10 shrink-0 bg-richBlack flex flex-col justify-center items-center"
          >
            <span class="text-sm md:text-lg font-semibold text-white">
              1.
            </span>
          </div>
          <div class="flex flex-col justify-start items-start space-y-1">
            <span class="text-base sm:text-2xl font-semibold text-richBlack">
              Finish all assignments
            </span>
            <p class="font-body text-xs md:text-base">
              Before you can take the exam, we want to make sure that you’re
              prepared. That’s why you have to pass a couple assignments first.
            </p>
          </div>
        </div>

        <div class="flex flex-row justify-start items-start space-x-4">
          <div
            class="w-5 h-5 md:w-10 md:h-10 shrink-0 bg-richBlack flex flex-col justify-center items-center"
          >
            <span class="text-sm md:text-lg font-semibold text-white">
              2.
            </span>
          </div>
          <div class="flex flex-col justify-start items-start space-y-1">
            <span class="text-base sm:text-2xl font-semibold text-richBlack">
              Take the online exam
            </span>
            <p class="font-body text-xs md:text-base">
              Once you have passed all your assignments, you will be able to
              sign up for your online exam. The online exam takes place over a
              video call. You can either join one of our dedicated online exam
              sessions (for free) or schedule a private one-on-one session (paid
              extra).
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row space-x-4 justify-start items-center">
      <button
        (click)="goToAssignments()"
        class="bg-richBlack text-sm sm:text-base px-4 py-2 uppercase font-semibold text-white"
      >
        Go to assignments
      </button>
      <a
        href="https://help.sami-x.com/en/articles/6537793-how-can-i-get-certified"
        target="_blank"
        class="underline text-sm sm:text-base bg-transparent text-richBlack font-sans"
      >
        More information
      </a>
    </div>
  </div>
</div>
