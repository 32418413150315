import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MembershipInfoComponent } from './membership-info.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [MembershipInfoComponent],
  imports: [CommonModule, RouterModule],
  exports: [MembershipInfoComponent],
})
export class MembershipInfoModule {}
