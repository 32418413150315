import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SamiUser } from 'interfaces';
import { ErrorSnackbarComponent, ImagePipe, StoragePipe, SuccessSnackbarComponent } from '@sami/ui';


@Component({
  selector: 'app-update-profile-image',
  templateUrl: './update-profile-image.component.html',
  standalone: true,
  imports: [
    CommonModule,
    StoragePipe,
    ImagePipe
  ]
})
export class UpdateProfileImageComponent {
  @Input() user: SamiUser | undefined;
  @Input() displayHint = true;
  profileImageRef: any;

  constructor(
    private storage: AngularFireStorage,
    private snackbar: MatSnackBar
  ) {}

  async onUploadImage(event: any) {
    if (!this.user) {
      return;
    }
    try {
      const file = event.target.files[0];

      if (file.type.split('/')[0] !== 'image') {
        return Promise.reject('File is not an image!');
      }
      if (file.size >= 5 * 1024 * 1024) {
        return Promise.reject('File is too big!');
      }

      const path = `user/${this.user.uid}/${new Date().getTime()}_${file.name}`;
      const customMetadata: any = {
        createThumb: 'true',
        userId: this.user.uid,
        folder: 'user',
      };
      const fileRef = this.storage.ref(path);
      const task: any = await fileRef.put(file, { customMetadata });
      this.snackbar.openFromComponent(SuccessSnackbarComponent, {
        duration: 3000,
        data: {
          title: 'Uploaded successfully',
          subtitle: 'Your image was uploaded successfully.',
        },
        panelClass: 'samix-toast',
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });

      return task;
    } catch (error) {
      console.error(error);
      this.snackbar.openFromComponent(ErrorSnackbarComponent, {
        duration: 3000,
        data: {
          title: 'Sorry, there was an error',
          subtitle: 'Please try again later',
        },
        panelClass: 'samix-toast',
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
      return;
    }
  }
}
