import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-mux-video',
  templateUrl: './mux-video.component.html',
})
export class MuxVideoComponent implements AfterViewInit, OnChanges {
  @Input() playbackId: string | undefined;
  @Input() title: string | undefined;
  @Input() userId: string | undefined;
  @ViewChild('muxPlayer') muxPlayer: ElementRef | undefined;
  @Input() play = false;

  muxPlayerInstance: any;
  isClient = false;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  ngOnChanges(): void {
    if (isPlatformBrowser(this.platformId) && this.muxPlayer) {
      if (this.play) {
        this.muxPlayer?.nativeElement.play();
      } else {
        this.muxPlayer?.nativeElement.pause();
      }
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      // Dynamically import the mux-video module
      import('@mux/mux-video')
        .then(() => {
          this.initializeMuxPlayer();
        })
        .catch(err => {
          console.error('Error loading @mux/mux-video:', err);
        });
    }
  }

  private initializeMuxPlayer(): void {
    this.muxPlayerInstance = document.getElementById('mux-player');
    this.muxPlayer?.nativeElement.setAttribute('playback-id', this.playbackId);

    if (this.title) {
      this.muxPlayer?.nativeElement.setAttribute('metadata-video-title', this.title);
    }

    if (this.userId) {
      this.muxPlayer?.nativeElement.setAttribute('metadata-viewer-user-id', this.userId);
    }

    this.muxPlayer?.nativeElement.pause();
  }
}
