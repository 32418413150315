import { Component, Input } from '@angular/core';
import { MenuService } from '../menu.service';
import { Router } from '@angular/router';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-app-header',
  templateUrl: './app-header.component.html'
})
export class AppHeaderComponent {
  @Input() showBottomNav = true;

  constructor(
    public menuService: MenuService,
    public router: Router
  ) { }

  navigateBack() {
    this.menuService.goBack();
  }
}
