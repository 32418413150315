import { Pipe, PipeTransform } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'stor',
  standalone: true
})
export class StoragePipe implements PipeTransform {
  constructor(private storage: AngularFireStorage) {}

  transform(value: any): Observable<any> {
    if (value.includes('http')) {
      return of(value);
    } else {
      return this.storage.ref(value).getDownloadURL();
    }
  }
}
