<div class="absolute w-screen max-w-max left-1/2 z-10 -translate-x-1/2 px-4 hidden lg:flex">
  <div [ngClass]="submenu?.cols === 2 ? 'w-screen max-w-max lg:max-w-3xl' : 'w-screen max-w-max lg:max-w-md'" class="w-screen max-w-md flex-auto overflow-hidden bg-white text-sm leading-6 shadow-lg lg:max-w-3xl">
    <div [ngClass]="submenu?.cols === 2 ? 'lg:grid-cols-2' : 'lg:grid-cols-1' " class="grid grid-cols-1 gap-x-6 gap-y-1 p-2">
      <a (click)="backdropClick.emit()" [routerLink]="'/' + item.route" *ngFor="let item of submenu?.items" class="group relative flex gap-x-4 p-2 hover:bg-cadetGrey-50">
        <img *ngIf="item.image" [src]="item.image" class="w-14 h-14 object-cover object-center">
        <div class="flex flex-col justify-center items-start">
          <span class="text-lg font-sans text-richBlack font-semibold">
            {{ item.title }}
            <span class="absolute inset-0"></span>
          </span>
          <p class="text-cadetGrey-600 font-body text-sm">{{ item.description }}</p>
        </div>
      </a>
    </div>
    <ng-container *ngIf="submenu?.featured">
      <ng-container *ngIf="submenu?.featured?.route?.startsWith('http'); else internalLink">
        <div class="bg-cadetGrey-50 cursor-pointer p-5">
          <a class="w-full h-full" target="_blank" [href]="submenu?.featured?.route">
            <h3 class="text-lg font-semibold font-sans">{{ submenu?.featured?.title }}</h3>
            <p class="text-sm leading-6 text-cadetGrey-600 font-body">
              {{ submenu?.featured?.description }}
            </p>
          </a>
        </div>
      </ng-container>

      <ng-template #internalLink>
        <div [routerLink]="'/' + submenu?.featured?.route" class="bg-cadetGrey-50 cursor-pointer p-5" (click)="backdropClick.emit()" *ngIf="submenu?.featured">
          <h3 class="text-lg font-semibold font-sans">{{ submenu?.featured?.title }}</h3>
          <p class="text-sm leading-6 text-cadetGrey-600 font-body">
            {{ submenu?.featured?.description }}
          </p>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>