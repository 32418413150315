import { SamiStyleModel } from './sami-style.model';

export interface EventCollectionModel {
  [key: string]: any;
  id?: string;
  title?: string;
  subtitle?: string;
  style?: SamiStyleModel;
  aboutText?: string;
  expectText?: string;
  outcomes?: string[];
  thumbnail?: string;
  previewVideoId?: string;
  sessions?: number;
  sessionList?: {
    [key: string]: any;
    id?: string;
    ref?: any;
  }[];
}
