import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoragePipe } from './storage.pipe';

@NgModule({
  declarations: [StoragePipe],
  imports: [CommonModule],
  exports: [StoragePipe],
})
export class StorageModule {}
