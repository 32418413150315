<div class="flex flex-col max-h-[80vh] overflow-y-auto justify-start min-h-[500px] bg-white p-6">
  <div class="flex flex-row justify-between">
    <span class="text-2xl font-semibold text-richBlack"> Edit my page </span>
    <button (click)="dialogRef.close()" class="w-4 h-4">
      <svg
        class="w-4 h-4"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3849_30117)">
          <path
            d="M1 1L23 23"
            stroke="black"
            stroke-width="2"
            stroke-linejoin="round"
          />
          <path
            d="M23 1L1 23"
            stroke="black"
            stroke-width="2"
            stroke-linejoin="round"
          />
        </g>
      </svg>
    </button>
  </div>

  <div class="" *ngIf="center$ | async as center">
    <nav mat-tab-nav-bar class="flex text-richBlack" aria-label="Tabs">
      <a
        mat-tab-link
        *ngFor="let link of navLinks"
        (click)="activeLink = link.link"
        [ngClass]="
          activeLink === link.link ? 'border-richBlack text-richBlack' : ''
        "
        class="font-sans text-richBlack border-transparent hover:text-richBlack hover:border-richBlack whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-base"
      >
        {{ link.label }}
      </a>
    </nav>
    <section class="pt-6">
      <app-about *ngIf="activeLink === 'about'" [center]="center"></app-about>
      <app-contact
        *ngIf="activeLink === 'contact-details'"
        [center]="center"
      ></app-contact>
      <app-combat-system
        *ngIf="activeLink === 'combat-systems'"
        [center]="center"
      ></app-combat-system>
      <app-edit-instructors
        *ngIf="activeLink === 'instructors'"
        [center]="center"
      ></app-edit-instructors>
      <app-upload-gallery
        *ngIf="activeLink === 'gallery'"
        [center]="center"
      ></app-upload-gallery>
    </section>
  </div>
</div>
