<div class="relative">
  <div class="flex w-full flex-row justify-between items-end absolute -top-6">
    <label [ngClass]="displayLabel ? 'block text-base font-semibold text-richBlack' : 'sr-only'" [for]="fieldName">
      {{ label }}
    </label>
    <span *ngIf="!validator" class="text-xs uppercase text-cadetGrey-400 font-body">
      optional
    </span>
  </div>
  <div class="flex flex-col justify-start space-y-0.5">
    <select
      [id]="fieldName"
      (input)="onChange( $event )"
      (blur)="touched()"
      [value]="value"
      [disabled]="isDisabled"
      [name]="fieldName"
      class="block w-full font-body rounded-none border-cadetGrey-300 shadow-none focus:border-cadetGrey-600 focus:ring-cadetGrey-600 sm:text-sm"
      [ngClass]="{
      'border-statusColors-red focus:border-statusColors-red focus:ring-statusColors-red':
          formField.invalid
          && formField.dirty
      }"
    >
      <option *ngFor="let option of options" [value]="option.key" [selected]="option.key === value">{{ option.value }}</option>
    </select>
    <div>
      <sami-field-errors *ngIf="formField.invalid" [formField]="formField"></sami-field-errors>
      <span *ngIf="hint && !formField.invalid" class="text-xs font-body text-cadetGrey-600" [innerText]="hint"></span>
    </div>
  </div>
</div>