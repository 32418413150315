import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImagePipe } from '../../pipes/transformImage.pipe';
import { ExamCardComponent } from './exam-card.component';
import { SamiCombatSystemTagComponent } from '../../combat-system-tag/combat-system-tag.component';
import { MembershipBadgeComponent } from '../../badges/membership-badge/membership-badge.component';

@NgModule({
  declarations: [ExamCardComponent],
  imports: [CommonModule, ImagePipe, SamiCombatSystemTagComponent, MembershipBadgeComponent],
  exports: [ExamCardComponent],
})
export class ExamCardModule {}
