import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from './auth.service';
import { BehaviorSubject, Observable, combineLatest, map, switchMap } from 'rxjs';
import { GuidedTraining, SamiUser, SamiUserPurchase } from 'interfaces';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class GuidedTrainingsService {
  selectedWeek$ = new BehaviorSubject<number>(this.utils.getWeekNumber(new Date()));

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private utils: UtilsService,
  ) {
  }

  public getGuidedTrainingsByWeek$(week: number): Observable<GuidedTraining[]> {
    return combineLatest([
      this.auth.user$,
      this.auth.getUserPurchases$('guidedTrainings'),
    ]).pipe(
      switchMap(([user, purchases]: [SamiUser, SamiUserPurchase[]]) =>
        this.afs.collection<GuidedTraining>('guidedTrainings', (ref) =>
          ref.where('weeks', 'array-contains', week)
        ).valueChanges({ idField: '_id' }).pipe(
          map((guidedTrainings: GuidedTraining[]) => guidedTrainings.map((training: GuidedTraining) => {
            const hasPurchased = !!purchases.find((purchase: SamiUserPurchase) => purchase.docId === training._id);
            return {
              ...training,
              hasPurchased,
              hasAccess: hasPurchased || user.subscriptionDigest?.hasSubscription?.plus || false,
            };
          }))
        )
      )
    );
  }

  getGuidedTraining$(id: string) {
    return combineLatest([
      this.afs.doc<GuidedTraining>(`guidedTrainings/${id}`).valueChanges({ idField: '_id' }),
      this.auth.user$,
      this.auth.getUserPurchases$('guidedTrainings'),
    ]).pipe(
      map(([guidedTraining, user, purchases]) => {
        const hasPurchased = !!purchases.find((purchase: SamiUserPurchase) => purchase.docId === guidedTraining?._id);
        return {
          ...guidedTraining,
          hasPurchased,
          hasAccess: hasPurchased || user?.subscriptionDigest?.hasSubscription?.plus || false,
        }
      })
    );
  }
}
