import { SamiStyleModel } from './sami-style.model';
import { SamiCenter } from './sami-center.model';
import { SamiSubscriptionDigest } from './sami-subscription.model';
import { SamiCompany } from './sami-company.model';
import { DocumentReference } from '@angular/fire/firestore';

export interface SamiUserExtended extends SamiUser {
  subscriptionDigest: SamiSubscriptionDigest | undefined;
  isCenterInstructor: boolean | undefined;
  isCenterAdmin: boolean | undefined;
}

export interface SamiUser {
  [key: string]: any;
  id?: string;
  objectID?: string; // Algolia
  uid?: string;
  email?: string;
  username?: string;
  displayName?: string;
  firstName?: string;
  hideWelcomeMessage?: boolean;
  lastName?: string;
  searchIndex?: string[];
  photoURL?: string;
  phoneNumber?: string;
  accessScope?: string[];
  mainCombatSystems?: string[];
  // lastActiveTrackId?: string;
  lastActiveTrackCardRef?: DocumentReference;
  signedUpForNewsletter?: boolean;
  onboardingCompleted?: boolean;
  onInstructorPath?: boolean;
  preferredLanguage?: string;
  newsletter?: boolean;
  signupSite?: string;
  center?: {
    id?: string;
    memberId?: number;
    name?: string;
    role?: string;
    slug?: string;
    membershipNumber?: string;
    membershipStartDate?: Date | any;
    membershipEndDate?: Date | any;
    hasValidMembership?: boolean;
  };
  addresses?: {
    name?: string;
    address?: {
      line1: string;
      line2: string;
      city: string;
      state: string;
      postal_code: string;
      country: string;
    };
    phone?: string;
  }[];
  isCenterInstructor?: boolean;
  isCenterAdmin?: boolean;
  isGlobalInstructor?: boolean;
  isGlobalAdmin?: boolean;
  gender?: 'male' | 'female' | 'non-binary';
  dateOfBirth?: Date | any;
  locale?: string;
  rewardfulUserId?: string;
  instructorSettings?: {
    entiteledToInstructorSubscription?: boolean;
    agreementAccepted?: boolean;
    welcomeEmailSent?: boolean;
  };
  unlockAssignmentsGrading?: boolean;
  stripeCustomerId?: string;
  stripeCurrency?: string;
  favoriteInstructors?: string[];
  userPreferences?: {
    goals?: string[] | null;
    systems?: string[] | null;
    experienceLevel?: number | null;
  };
  lastLibrarySearch?: {
    system?: string;
    level?: number;
    category?: string;
    subCategory?: string;
  };
  country?: {
    name?: string;
    code?: string;
  };
  styleLevels?: {
    style?: SamiStyleModel;
    level?: number;
    history?: {
      center?: SamiCenter;
      instructor?: { id: string; name: string; center: SamiCenter };
      level?: number;
      date?: Date | any;
    }[];
  }[];
  styleExams?: {
    style?: SamiStyleModel;
    exam?: number;
    history?: {
      center?: SamiCenter;
      instructor?: { id: string; name: string; center: SamiCenter };
      exam?: number;
      date?: Date | any;
    }[];
  }[];
  trackAccess?: string[];
  importedFromSamiInternational?: boolean;
  updatedFromSamiInternational?: boolean;
  tos?: {
    [key: string]: Date | number;
  };
  instructorAgreement?: {
    [key: string]: Date | number;
  };
  role?: {
    status: 'unlocked' | 'pending' | 'active' | 'canceled';
    type: 'member' | 'instructor' | 'multiplier';
    unlockedAt: Date | any;
  };
  company?: SamiCompany;
  subscriptionDigest?: SamiSubscriptionDigest;
}

export interface UserNote {
  description: string;
  createdAt: Date;
  updatedAt: Date;
  access: 'admin' | 'instructor' | 'center';
}

export interface InstructorAgreement {
  id: string,
  version: string,
  date: Date,
  ip: string,
  userAgent: string,
  instructorAgreement: { url: string, path: string, type: string, name: string }[],
  status: 'pending' | 'accepted' | 'rejected';
}

export interface FirebaseAuthActionHandles {
  mode: 'resetPassword' | 'recoverEmail' | 'verifyEmail',
  continueUrl: string,
  lang: string,
  apiKey: string,
  oobCode: string,
}