import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface OneTimePaymentData {
  docId: string;
  title: string;
  contentType: 'systemsClips' | 'guidedTrainings' | 'liveTrainings' | 'tracks';
  successUrl?: string;
  cancelUrl?: string;
  returnUrl?: string;
  image: string;
}

@Injectable({
  providedIn: 'root',
})
export class PaywallService {
  setOpen$ = new BehaviorSubject(false);
  setPaywallInfo$ = new BehaviorSubject<OneTimePaymentData | null>(null);

  public openPaywallDialog(data: OneTimePaymentData | null): void {
    this.setPaywallInfo$.next(data ? data : null);
    this.setOpen$.next(true);
  }
}
