import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { GetDownloadURLPipeModule } from '@angular/fire/compat/storage';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService, UiService } from '@sami/features';
import { ImagePipe } from '../../pipes/transformImage.pipe';
import { CertificationModalComponent } from './certification-modal/certification-modal.component';

@Component({
  selector: 'app-badge-card',
  templateUrl: './badge-card.component.html',
  standalone: true,
  imports: [ CertificationModalComponent, CommonModule, ImagePipe, GetDownloadURLPipeModule ]
})
export class BadgeCardComponent {
  @Input() cardType: 'exam' | 'badge' = 'badge';
  @Input() system = 'knife';
  @Input() trackTitle?: string = '';
  @Input() title = '';
  @Input() trackId: string | undefined;
  @Input() subtitle = '';
  @Input() badgePath: string | null = '';
  @Input() status:
    | 'active'
    | 'paused'
    | 'finished'
    | 'inactive'
    | 'exam_passed' = 'inactive';

  constructor(
    private ui: UiService,
    private _dialog: MatDialog,
    public auth: AuthService,
    private router: Router,
  ) {}

  returnSystemComponent(
    identifier:
      | 'kravMaga'
      | 'panantukan'
      | 'axe'
      | 'stick'
      | 'knife'
      | 'ebc'
      | 'kapap'
      | 'core'
      | string,
    type: 'title' | 'color' | 'category'
  ) {
    if (identifier !== 'misc') {
      return this.ui.samiSystems[identifier][type] || 'fightingRed-700';
    } else {
      return 'richBlack';
    }
  }

  askForOnlineExam() {
  }

  openCertificationModal() {
    this._dialog
      .open(CertificationModalComponent, {
        data: {
          system: this.system,
        },
        panelClass: 'sami-dialog',
        maxWidth: '800px',
        width: '80vw',
      })
      .afterClosed()
      .subscribe((value: string) => {
        if (value === 'assignments' && this.trackId) {
          this.router.navigate([
            '/app/tracks/' + this.trackId + '/assignments',
          ]);
        }
      });
  }
}
