import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  currentSite$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  isMenuExpanded$: BehaviorSubject<boolean>;
  isPublicMenuExpanded$: BehaviorSubject<boolean>;
  isMobileLayout$: Observable<boolean>;
  isMediumLayout$: Observable<boolean>;

  languages = [
    { en: 'English' },
    { de: 'German' },
  ];

  roles = ['Member', 'Instructor', 'Owner'];

  samixDifficulty = ['Easy', 'Intermediate', 'Hard'];


  countries = [
    {
      code: 'AT',
      name: 'Austria',
    },
    {
      code: 'DE',
      name: 'Germany',
    },
  ];

  locales = [
    'af-ZA',
    'am-ET',
    'ar-AE',
    'ar-BH',
    'ar-DZ',
    'ar-EG',
    'ar-IQ',
    'ar-JO',
    'ar-KW',
    'ar-LB',
    'ar-LY',
    'ar-MA',
    'arn-CL',
    'ar-OM',
    'ar-QA',
    'ar-SA',
    'ar-SD',
    'ar-SY',
    'ar-TN',
    'ar-YE',
    'as-IN',
    'az-az',
    'az-Cyrl-AZ',
    'az-Latn-AZ',
    'ba-RU',
    'be-BY',
    'bg-BG',
    'bn-BD',
    'bn-IN',
    'bo-CN',
    'br-FR',
    'bs-Cyrl-BA',
    'bs-Latn-BA',
    'ca-ES',
    'co-FR',
    'cs-CZ',
    'cy-GB',
    'da-DK',
    'de-AT',
    'de-CH',
    'de-DE',
    'de-LI',
    'de-LU',
    'dsb-DE',
    'dv-MV',
    'el-CY',
    'el-GR',
    'en-029',
    'en-AU',
    'en-BZ',
    'en-CA',
    'en-cb',
    'en-GB',
    'en-IE',
    'en-IN',
    'en-JM',
    'en-MT',
    'en-MY',
    'en-NZ',
    'en-PH',
    'en-SG',
    'en-TT',
    'en-US',
    'en-ZA',
    'en-ZW',
    'es-AR',
    'es-BO',
    'es-CL',
    'es-CO',
    'es-CR',
    'es-DO',
    'es-EC',
    'es-ES',
    'es-GT',
    'es-HN',
    'es-MX',
    'es-NI',
    'es-PA',
    'es-PE',
    'es-PR',
    'es-PY',
    'es-SV',
    'es-US',
    'es-UY',
    'es-VE',
    'et-EE',
    'eu-ES',
    'fa-IR',
    'fi-FI',
    'fil-PH',
    'fo-FO',
    'fr-BE',
    'fr-CA',
    'fr-CH',
    'fr-FR',
    'fr-LU',
    'fr-MC',
    'fy-NL',
    'ga-IE',
    'gd-GB',
    'gd-ie',
    'gl-ES',
    'gsw-FR',
    'gu-IN',
    'ha-Latn-NG',
    'he-IL',
    'hi-IN',
    'hr-BA',
    'hr-HR',
    'hsb-DE',
    'hu-HU',
    'hy-AM',
    'id-ID',
    'ig-NG',
    'ii-CN',
    'in-ID',
    'is-IS',
    'it-CH',
    'it-IT',
    'iu-Cans-CA',
    'iu-Latn-CA',
    'iw-IL',
    'ja-JP',
    'ka-GE',
    'kk-KZ',
    'kl-GL',
    'km-KH',
    'kn-IN',
    'kok-IN',
    'ko-KR',
    'ky-KG',
    'lb-LU',
    'lo-LA',
    'lt-LT',
    'lv-LV',
    'mi-NZ',
    'mk-MK',
    'ml-IN',
    'mn-MN',
    'mn-Mong-CN',
    'moh-CA',
    'mr-IN',
    'ms-BN',
    'ms-MY',
    'mt-MT',
    'nb-NO',
    'ne-NP',
    'nl-BE',
    'nl-NL',
    'nn-NO',
    'no-no',
    'nso-ZA',
    'oc-FR',
    'or-IN',
    'pa-IN',
    'pl-PL',
    'prs-AF',
    'ps-AF',
    'pt-BR',
    'pt-PT',
    'qut-GT',
    'quz-BO',
    'quz-EC',
    'quz-PE',
    'rm-CH',
    'ro-mo',
    'ro-RO',
    'ru-mo',
    'ru-RU',
    'rw-RW',
    'sah-RU',
    'sa-IN',
    'se-FI',
    'se-NO',
    'se-SE',
    'si-LK',
    'sk-SK',
    'sl-SI',
    'sma-NO',
    'sma-SE',
    'smj-NO',
    'smj-SE',
    'smn-FI',
    'sms-FI',
    'sq-AL',
    'sr-BA',
    'sr-CS',
    'sr-Cyrl-BA',
    'sr-Cyrl-CS',
    'sr-Cyrl-ME',
    'sr-Cyrl-RS',
    'sr-Latn-BA',
    'sr-Latn-CS',
    'sr-Latn-ME',
    'sr-Latn-RS',
    'sr-ME',
    'sr-RS',
    'sr-sp',
    'sv-FI',
    'sv-SE',
    'sw-KE',
    'syr-SY',
    'ta-IN',
    'te-IN',
    'tg-Cyrl-TJ',
    'th-TH',
    'tk-TM',
    'tlh-QS',
    'tn-ZA',
    'tr-TR',
    'tt-RU',
    'tzm-Latn-DZ',
    'ug-CN',
    'uk-UA',
    'ur-PK',
    'uz-Cyrl-UZ',
    'uz-Latn-UZ',
    'uz-uz',
    'vi-VN',
    'wo-SN',
    'xh-ZA',
    'yo-NG',
    'zh-CN',
    'zh-HK',
    'zh-MO',
    'zh-SG',
    'zh-TW',
    'zu-ZA'
  ];

  timeZones = [
    'Europe/Andorra',
    'Asia/Dubai',
    'Asia/Kabul',
    'Europe/Tirane',
    'Asia/Yerevan',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville',
    'Antarctica/Mawson',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Cordoba',
    'America/Argentina/Salta',
    'America/Argentina/Jujuy',
    'America/Argentina/Tucuman',
    'America/Argentina/Catamarca',
    'America/Argentina/La_Rioja',
    'America/Argentina/San_Juan',
    'America/Argentina/Mendoza',
    'America/Argentina/San_Luis',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Ushuaia',
    'Pacific/Pago_Pago',
    'Europe/Vienna',
    'Australia/Lord_Howe',
    'Antarctica/Macquarie',
    'Australia/Hobart',
    'Australia/Currie',
    'Australia/Melbourne',
    'Australia/Sydney',
    'Australia/Broken_Hill',
    'Australia/Brisbane',
    'Australia/Lindeman',
    'Australia/Adelaide',
    'Australia/Darwin',
    'Australia/Perth',
    'Australia/Eucla',
    'Asia/Baku',
    'America/Barbados',
    'Asia/Dhaka',
    'Europe/Brussels',
    'Europe/Sofia',
    'Atlantic/Bermuda',
    'Asia/Brunei',
    'America/La_Paz',
    'America/Noronha',
    'America/Belem',
    'America/Fortaleza',
    'America/Recife',
    'America/Araguaina',
    'America/Maceio',
    'America/Bahia',
    'America/Sao_Paulo',
    'America/Campo_Grande',
    'America/Cuiaba',
    'America/Santarem',
    'America/Porto_Velho',
    'America/Boa_Vista',
    'America/Manaus',
    'America/Eirunepe',
    'America/Rio_Branco',
    'America/Nassau',
    'Asia/Thimphu',
    'Europe/Minsk',
    'America/Belize',
    'America/St_Johns',
    'America/Halifax',
    'America/Glace_Bay',
    'America/Moncton',
    'America/Goose_Bay',
    'America/Blanc-Sablon',
    'America/Toronto',
    'America/Nipigon',
    'America/Thunder_Bay',
    'America/Iqaluit',
    'America/Pangnirtung',
    'America/Atikokan',
    'America/Winnipeg',
    'America/Rainy_River',
    'America/Resolute',
    'America/Rankin_Inlet',
    'America/Regina',
    'America/Swift_Current',
    'America/Edmonton',
    'America/Cambridge_Bay',
    'America/Yellowknife',
    'America/Inuvik',
    'America/Creston',
    'America/Dawson_Creek',
    'America/Fort_Nelson',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Dawson',
    'Indian/Cocos',
    'Europe/Zurich',
    'Africa/Abidjan',
    'Pacific/Rarotonga',
    'America/Santiago',
    'America/Punta_Arenas',
    'Pacific/Easter',
    'Asia/Shanghai',
    'Asia/Urumqi',
    'America/Bogota',
    'America/Costa_Rica',
    'America/Havana',
    'Atlantic/Cape_Verde',
    'America/Curacao',
    'Indian/Christmas',
    'Asia/Nicosia',
    'Asia/Famagusta',
    'Europe/Prague',
    'Europe/Berlin',
    'Europe/Copenhagen',
    'America/Santo_Domingo',
    'Africa/Algiers',
    'America/Guayaquil',
    'Pacific/Galapagos',
    'Europe/Tallinn',
    'Africa/Cairo',
    'Africa/El_Aaiun',
    'Europe/Madrid',
    'Africa/Ceuta',
    'Atlantic/Canary',
    'Europe/Helsinki',
    'Pacific/Fiji',
    'Atlantic/Stanley',
    'Pacific/Chuuk',
    'Pacific/Pohnpei',
    'Pacific/Kosrae',
    'Atlantic/Faroe',
    'Europe/Paris',
    'Europe/London',
    'Asia/Tbilisi',
    'America/Cayenne',
    'Africa/Accra',
    'Europe/Gibraltar',
    'America/Godthab',
    'America/Danmarkshavn',
    'America/Scoresbysund',
    'America/Thule',
    'Europe/Athens',
    'Atlantic/South_Georgia',
    'America/Guatemala',
    'Pacific/Guam',
    'Africa/Bissau',
    'America/Guyana',
    'Asia/Hong_Kong',
    'America/Tegucigalpa',
    'America/Port-au-Prince',
    'Europe/Budapest',
    'Asia/Jakarta',
    'Asia/Pontianak',
    'Asia/Makassar',
    'Asia/Jayapura',
    'Europe/Dublin',
    'Asia/Jerusalem',
    'Asia/Kolkata',
    'Indian/Chagos',
    'Asia/Baghdad',
    'Asia/Tehran',
    'Atlantic/Reykjavik',
    'Europe/Rome',
    'America/Jamaica',
    'Asia/Amman',
    'Asia/Tokyo',
    'Africa/Nairobi',
    'Asia/Bishkek',
    'Pacific/Tarawa',
    'Pacific/Enderbury',
    'Pacific/Kiritimati',
    'Asia/Pyongyang',
    'Asia/Seoul',
    'Asia/Almaty',
    'Asia/Qyzylorda',
    'Asia/Qostanay',
    'Asia/Aqtobe',
    'Asia/Aqtau',
    'Asia/Atyrau',
    'Asia/Oral',
    'Asia/Beirut',
    'Asia/Colombo',
    'Africa/Monrovia',
    'Europe/Vilnius',
    'Europe/Luxembourg',
    'Europe/Riga',
    'Africa/Tripoli',
    'Africa/Casablanca',
    'Europe/Monaco',
    'Europe/Chisinau',
    'Pacific/Majuro',
    'Pacific/Kwajalein',
    'Asia/Yangon',
    'Asia/Ulaanbaatar',
    'Asia/Hovd',
    'Asia/Choibalsan',
    'Asia/Macau',
    'America/Martinique',
    'Europe/Malta',
    'Indian/Mauritius',
    'Indian/Maldives',
    'America/Mexico_City',
    'America/Cancun',
    'America/Merida',
    'America/Monterrey',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Chihuahua',
    'America/Ojinaga',
    'America/Hermosillo',
    'America/Tijuana',
    'America/Bahia_Banderas',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Africa/Maputo',
    'Africa/Windhoek',
    'Pacific/Noumea',
    'Pacific/Norfolk',
    'Africa/Lagos',
    'America/Managua',
    'Europe/Amsterdam',
    'Europe/Oslo',
    'Asia/Kathmandu',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Auckland',
    'Pacific/Chatham',
    'America/Panama',
    'America/Lima',
    'Pacific/Tahiti',
    'Pacific/Marquesas',
    'Pacific/Gambier',
    'Pacific/Port_Moresby',
    'Pacific/Bougainville',
    'Asia/Manila',
    'Asia/Karachi',
    'Europe/Warsaw',
    'America/Miquelon',
    'Pacific/Pitcairn',
    'America/Puerto_Rico',
    'Asia/Gaza',
    'Asia/Hebron',
    'Europe/Lisbon',
    'Atlantic/Madeira',
    'Atlantic/Azores',
    'Pacific/Palau',
    'America/Asuncion',
    'Asia/Qatar',
    'Indian/Reunion',
    'Europe/Bucharest',
    'Europe/Belgrade',
    'Europe/Kaliningrad',
    'Europe/Moscow',
    'Europe/Simferopol',
    'Europe/Kirov',
    'Europe/Astrakhan',
    'Europe/Volgograd',
    'Europe/Saratov',
    'Europe/Ulyanovsk',
    'Europe/Samara',
    'Asia/Yekaterinburg',
    'Asia/Omsk',
    'Asia/Novosibirsk',
    'Asia/Barnaul',
    'Asia/Tomsk',
    'Asia/Novokuznetsk',
    'Asia/Krasnoyarsk',
    'Asia/Irkutsk',
    'Asia/Chita',
    'Asia/Yakutsk',
    'Asia/Khandyga',
    'Asia/Vladivostok',
    'Asia/Ust-Nera',
    'Asia/Magadan',
    'Asia/Sakhalin',
    'Asia/Srednekolymsk',
    'Asia/Kamchatka',
    'Asia/Anadyr',
    'Asia/Riyadh',
    'Pacific/Guadalcanal',
    'Indian/Mahe',
    'Africa/Khartoum',
    'Europe/Stockholm',
    'Asia/Singapore',
    'America/Paramaribo',
    'Africa/Juba',
    'Africa/Sao_Tome',
    'America/El_Salvador',
    'Asia/Damascus',
    'America/Grand_Turk',
    'Africa/Ndjamena',
    'Indian/Kerguelen',
    'Asia/Bangkok',
    'Asia/Dushanbe',
    'Pacific/Fakaofo',
    'Asia/Dili',
    'Asia/Ashgabat',
    'Africa/Tunis',
    'Pacific/Tongatapu',
    'Europe/Istanbul',
    'America/Port_of_Spain',
    'Pacific/Funafuti',
    'Asia/Taipei',
    'Europe/Kiev',
    'Europe/Uzhgorod',
    'Europe/Zaporozhye',
    'Pacific/Wake',
    'America/New_York',
    'America/Detroit',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Indiana/Indianapolis',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Chicago',
    'America/Indiana/Tell_City',
    'America/Indiana/Knox',
    'America/Menominee',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah',
    'America/Denver',
    'America/Boise',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Juneau',
    'America/Sitka',
    'America/Metlakatla',
    'America/Yakutat',
    'America/Nome',
    'America/Adak',
    'Pacific/Honolulu',
    'America/Montevideo',
    'Asia/Samarkand',
    'Asia/Tashkent',
    'America/Caracas',
    'Asia/Ho_Chi_Minh',
    'Pacific/Efate',
    'Pacific/Wallis',
    'Pacific/Apia',
    'Africa/Johannesburg'
  ];

  loadingState = false;

  stripeCurrencyCodes = [
    { currency: 'Afghan Afghani', code: 'AFN' },
    { currency: 'Albanian Lek', code: 'ALL' },
    { currency: 'Algerian Dinar', code: 'DZD' },
    { currency: 'Angolan Kwanza', code: 'AOA' },
    { currency: 'Argentine Peso', code: 'ARS' },
    { currency: 'Armenian Dram', code: 'AMD' },
    { currency: 'Aruban Florin', code: 'AWG' },
    { currency: 'Australian Dollar', code: 'AUD' },
    { currency: 'Azerbaijani Manat', code: 'AZN' },
    { currency: 'Bahamian Dollar', code: 'BSD' },
    { currency: 'Bangladeshi Taka', code: 'BDT' },
    { currency: 'Barbadian Dollar', code: 'BBD' },
    { currency: 'Belize Dollar', code: 'BZD' },
    { currency: 'Bermudian Dollar', code: 'BMD' },
    { currency: 'Bolivian Boliviano', code: 'BOB' },
    { currency: 'Bosnia & Herzegovina Convertible Mark', code: 'BAM' },
    { currency: 'Botswana Pula', code: 'BWP' },
    { currency: 'Brazilian Real', code: 'BRL' },
    { currency: 'British Pound', code: 'GBP' },
    { currency: 'Brunei Dollar', code: 'BND' },
    { currency: 'Bulgarian Lev', code: 'BGN' },
    { currency: 'Burundian Franc', code: 'BIF' },
    { currency: 'Cambodian Riel', code: 'KHR' },
    { currency: 'Canadian Dollar', code: 'CAD' },
    { currency: 'Cape Verdean Escudo', code: 'CVE' },
    { currency: 'Cayman Islands Dollar', code: 'KYD' },
    { currency: 'Central African Cfa Franc', code: 'XAF' },
    { currency: 'Cfp Franc', code: 'XPF' },
    { currency: 'Chilean Peso', code: 'CLP' },
    { currency: 'Chinese Renminbi Yuan', code: 'CNY' },
    { currency: 'Colombian Peso', code: 'COP' },
    { currency: 'Comorian Franc', code: 'KMF' },
    { currency: 'Congolese Franc', code: 'CDF' },
    { currency: 'Costa Rican Colón', code: 'CRC' },
    { currency: 'Croatian Kuna', code: 'HRK' },
    { currency: 'Czech Koruna', code: 'CZK' },
    { currency: 'Danish Krone', code: 'DKK' },
    { currency: 'Djiboutian Franc', code: 'DJF' },
    { currency: 'Dominican Peso', code: 'DOP' },
    { currency: 'East Caribbean Dollar', code: 'XCD' },
    { currency: 'Egyptian Pound', code: 'EGP' },
    { currency: 'Ethiopian Birr', code: 'ETB' },
    { currency: 'Euro', code: 'EUR' },
    { currency: 'Falkland Islands Pound', code: 'FKP' },
    { currency: 'Fijian Dollar', code: 'FJD' },
    { currency: 'Gambian Dalasi', code: 'GMD' },
    { currency: 'Georgian Lari', code: 'GEL' },
    { currency: 'Gibraltar Pound', code: 'GIP' },
    { currency: 'Guatemalan Quetzal', code: 'GTQ' },
    { currency: 'Guinean Franc', code: 'GNF' },
    { currency: 'Guyanese Dollar', code: 'GYD' },
    { currency: 'Haitian Gourde', code: 'HTG' },
    { currency: 'Honduran Lempira', code: 'HNL' },
    { currency: 'Hong Kong Dollar', code: 'HKD' },
    { currency: 'Hungarian Forint', code: 'HUF' },
    { currency: 'Icelandic Króna', code: 'ISK' },
    { currency: 'Indian Rupee', code: 'INR' },
    { currency: 'Indonesian Rupiah', code: 'IDR' },
    { currency: 'Israeli New Sheqel', code: 'ILS' },
    { currency: 'Jamaican Dollar', code: 'JMD' },
    { currency: 'Japanese Yen', code: 'JPY' },
    { currency: 'Kazakhstani Tenge', code: 'KZT' },
    { currency: 'Kenyan Shilling', code: 'KES' },
    { currency: 'Kyrgyzstani Som', code: 'KGS' },
    { currency: 'Lao Kip', code: 'LAK' },
    { currency: 'Lebanese Pound', code: 'LBP' },
    { currency: 'Lesotho Loti', code: 'LSL' },
    { currency: 'Liberian Dollar', code: 'LRD' },
    { currency: 'Macanese Pataca', code: 'MOP' },
    { currency: 'Macedonian Denar', code: 'MKD' },
    { currency: 'Malagasy Ariary', code: 'MGA' },
    { currency: 'Malawian Kwacha', code: 'MWK' },
    { currency: 'Malaysian Ringgit', code: 'MYR' },
    { currency: 'Maldivian Rufiyaa', code: 'MVR' },
    { currency: 'Mauritanian Ouguiya', code: 'MRO' },
    { currency: 'Mauritian Rupee', code: 'MUR' },
    { currency: 'Mexican Peso', code: 'MXN' },
    { currency: 'Moldovan Leu', code: 'MDL' },
    { currency: 'Mongolian Tögrög', code: 'MNT' },
    { currency: 'Moroccan Dirham', code: 'MAD' },
    { currency: 'Mozambican Metical', code: 'MZN' },
    { currency: 'Myanmar Kyat', code: 'MMK' },
    { currency: 'Namibian Dollar', code: 'NAD' },
    { currency: 'Nepalese Rupee', code: 'NPR' },
    { currency: 'Netherlands Antillean Gulden', code: 'ANG' },
    { currency: 'New Taiwan Dollar', code: 'TWD' },
    { currency: 'New Zealand Dollar', code: 'NZD' },
    { currency: 'Nicaraguan Córdoba', code: 'NIO' },
    { currency: 'Nigerian Naira', code: 'NGN' },
    { currency: 'Norwegian Krone', code: 'NOK' },
    { currency: 'Pakistani Rupee', code: 'PKR' },
    { currency: 'Panamanian Balboa', code: 'PAB' },
    { currency: 'Papua New Guinean Kina', code: 'PGK' },
    { currency: 'Paraguayan Guaraní', code: 'PYG' },
    { currency: 'Peruvian Nuevo Sol', code: 'PEN' },
    { currency: 'Philippine Peso', code: 'PHP' },
    { currency: 'Polish Złoty', code: 'PLN' },
    { currency: 'Qatari Riyal', code: 'QAR' },
    { currency: 'Romanian Leu', code: 'RON' },
    { currency: 'Russian Ruble', code: 'RUB' },
    { currency: 'Rwandan Franc', code: 'RWF' },
    { currency: 'São Tomé and Príncipe Dobra', code: 'STD' },
    { currency: 'Saint Helenian Pound', code: 'SHP' },
    { currency: 'Samoan Tala', code: 'WST' },
    { currency: 'Saudi Riyal', code: 'SAR' },
    { currency: 'Serbian Dinar', code: 'RSD' },
    { currency: 'Seychellois Rupee', code: 'SCR' },
    { currency: 'Sierra Leonean Leone', code: 'SLL' },
    { currency: 'Singapore Dollar', code: 'SGD' },
    { currency: 'Solomon Islands Dollar', code: 'SBD' },
    { currency: 'Somali Shilling', code: 'SOS' },
    { currency: 'South African Rand', code: 'ZAR' },
    { currency: 'South Korean Won', code: 'KRW' },
    { currency: 'Sri Lankan Rupee', code: 'LKR' },
    { currency: 'Surinamese Dollar', code: 'SRD' },
    { currency: 'Swazi Lilangeni', code: 'SZL' },
    { currency: 'Swedish Krona', code: 'SEK' },
    { currency: 'Swiss Franc', code: 'CHF' },
    { currency: 'Tajikistani Somoni', code: 'TJS' },
    { currency: 'Tanzanian Shilling', code: 'TZS' },
    { currency: 'Thai Baht', code: 'THB' },
    { currency: 'Tongan Paʻanga', code: 'TOP' },
    { currency: 'Trinidad and Tobago Dollar', code: 'TTD' },
    { currency: 'Turkish Lira', code: 'TRY' },
    { currency: 'Ugandan Shilling', code: 'UGX' },
    { currency: 'Ukrainian Hryvnia', code: 'UAH' },
    { currency: 'United Arab Emirates Dirham', code: 'AED' },
    { currency: 'United States Dollar', code: 'USD' },
    { currency: 'Uruguayan Peso', code: 'UYU' },
    { currency: 'Uzbekistani Som', code: 'UZS' },
    { currency: 'Vanuatu Vatu', code: 'VUV' },
    { currency: 'Vietnamese Đồng', code: 'VND' },
    { currency: 'West African Cfa Franc', code: 'XOF' },
    { currency: 'Yemeni Rial', code: 'YER' },
    { currency: 'Zambian Kwacha', code: 'ZMW' },
  ];

  weeks = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
  ];

  styleExamRanks = [
    {
      styleIdentiers: ['kravMaga', 'stick', 'knife', 'kapap', 'panantukan', 'axe'],
      ranks: [
        'Trainee',
        'Candidate Instructor',
        'Assist Instructor',
        'Basic Instructor',
        'Associate Instructor',
        'Advanced Instructor 1',
        'Advanced Instructor 2',
        'Advanced Instructor 3',
        'Advanced Instructor 4',
        'Expert Instructor',
        'Full Instructor',
        'Master Instructor',
        'Head Instructor',
      ],
    },
    {
      styleIdentiers: ['kidskravmaga', 'womenkravmaga', 'securitykravmaga'],
      ranks: [
        'Trainee',
        'Level 1 Instructor',
        'Level 2 Instructor',
        'Advanced Instructor',
      ],
    },
    {
      styleIdentiers: ['ebc', 'sds', 'defender', 'weaponProtection', 'karambit', 'core'],
      ranks: [
        'Trainee',
        'Modul 1 Instructor',
        'Modul 2 Instructor',
        'System Instructor',
      ],
    },
  ];

  samiSystems: any = {
    knife: {
      title: 'SAMI-X Knife',
      identifier: 'knife',
      color: 'fightingRed-700',
      category: 'fightingArt',
      categoryText: 'Fighting Art',
      excerpt:
        'Is a martial art, in which the defense against all types of cutting and stabbing weapons as well as the handling of the short blade is trained.',
      systemsLibraryExists: true,
      mainImage:
        'https://firebasestorage.googleapis.com/v0/b/sami-272007.appspot.com/o/samiLibrary%2F1663142839346.jpeg?alt=media&token=dc85e756-c635-4a31-8337-fd4496592039',

    },
    karambit: {
      title: 'SAMI-X Karambit',
      identifier: 'karambit',
      color: 'fightingRed-700',
      category: 'fightingArt',
      categoryText: 'Fighting Art',
      excerpt:
        'The karambit, originally intended as a tool, is a very special weapon for close combat. Historically, ring and cleaver knives come from the Asian and European area.',
      systemsLibraryExists: false,
    },
    stick: {
      title: 'SAMI-X Stick',
      identifier: 'stick',
      color: 'fightingRed-700',
      category: 'fightingArt',
      categoryText: 'Fighting Art',
      excerpt:
        'Is a system, in which fighting and defense with the stick is taught in one-handed and two-handed use.',
      systemsLibraryExists: true,
      mainImage:
        'https://firebasestorage.googleapis.com/v0/b/sami-272007.appspot.com/o/samiLibrary%2F1663142825307.jpeg?alt=media&token=4de6d7f5-6208-448b-8513-3eb69be13752',
    },
    kravMaga: {
      title: 'SAMI-X Krav Maga',
      identifier: 'kravMaga',
      color: 'defensiveGreen-600',
      category: 'defense',
      categoryText: 'Defense',
      excerpt:
        'Is an effective self-defense system, that builds on natural instincts and can be quickly learned as an emergency program for everyone.',
      systemsLibraryExists: true,
      mainImage:
        'https://firebasestorage.googleapis.com/v0/b/sami-272007.appspot.com/o/samiLibrary%2F1663141577669.jpeg?alt=media&token=59f68789-4ca4-4d85-ab5b-f7cb1936f6a5',
    },
    ebc: {
      title: 'SAMI-X EBC',
      identifier: 'ebc',
      color: 'professionalYellow-300',
      category: 'pro',
      categoryText: 'Pro',
      excerpt:
        'EBC (Expandable Baton Concept) provides new answers to many topics as well as adapted training concepts for practical use. We start where there are problems. From optimizing daily work processes to training extreme situations, we adapt our techniques and concepts to requirements, proportionality and the legal situation especially for professionals in law enforcement by using the telescopic stick as well as the tonfa or the EMS and for civilians even a normal stick. EBC is part of SAMI-X PRO and can be experienced separately or as an integral part of the overall system.',
      systemsLibraryExists: true,
      mainImage:
        'https://firebasestorage.googleapis.com/v0/b/sami-272007.appspot.com/o/samiLibrary%2F1663138712634.jpeg?alt=media&token=05eafdf1-fd2c-4384-805f-df4f1319cf91',
    },
    kapap: {
      title: 'SAMI-X Pro',
      identifier: 'kapap',
      color: 'professionalYellow-300',
      category: 'pro',
      categoryText: 'Pro',
      excerpt:
        'SAMI-X PRO (Professional)  is focused on improving defensive and offensive skills of (armed) operational forces. Training is oriented to armed defense and defense against armed or unarmed attacks by single or multiple aggressors. This professional system program was specially developed for emergency services such as the police, judiciary, military and security forces, authorities and weapon carriers.',
      systemsLibraryExists: true,
      mainImage:
        'https://firebasestorage.googleapis.com/v0/b/sami-272007.appspot.com/o/samiLibrary%2F1663139271147.jpeg?alt=media&token=0059add2-d0ca-4997-8b1b-4f1315eab512',
    },
    panantukan: {
      title: 'SAMI-X Panantukan',
      identifier: 'panantukan',
      color: 'fightingRed-700',
      category: 'fightingArt',
      categoryText: 'Fighting Art',
      excerpt:
        'Is one of the most comprehensive free-fight systems, which is not subject to any rules and can optimally develop physical and mental skills.',
      systemsLibraryExists: true,
      mainImage:
        'https://firebasestorage.googleapis.com/v0/b/sami-272007.appspot.com/o/samiLibrary%2F1663138599438.jpeg?alt=media&token=4299a0f7-5a38-4678-9eb3-0b2de69e0174',
    },
    axe: {
      title: 'SAMI-X Axe/Tomahawk',
      identifier: 'axe',
      color: 'fightingRed-700',
      category: 'fightingArt',
      categoryText: 'Fighting Art',
      excerpt:
        'Is a martial art in which the aesthetic side as well as the realistic use of this martial and historical weapon is the focus.',
      systemsLibraryExists: true,
      mainImage:
        'https://firebasestorage.googleapis.com/v0/b/sami-272007.appspot.com/o/samiLibrary%2F1663138684908.jpeg?alt=media&token=3e252d4b-c691-43f2-aa9c-8813f768bd7d',
    },
    boxing: {
      title: 'Unlimited Boxing',
      identifier: 'boxing',
      color: 'fightingRed-700',
      category: 'fightingArt',
      categoryText: 'Fighting Art',
      systemsLibraryExists: false,
      mainImage:
        'https://firebasestorage.googleapis.com/v0/b/sami-272007.appspot.com/o/samiLibrary%2F1663138599438.jpeg?alt=media&token=4299a0f7-5a38-4678-9eb3-0b2de69e0174',
    },
    sds: {
      title: 'SAMI-X SDS',
      identifier: 'sds',
      color: 'defensiveGreen-600',
      category: 'defense',
      categoryText: 'Defense',
      experpt:
        'SDS  Concept (Self Defense Stick Concept) is a highly efficient self-protection add on, which focuses on the utilization of improvised weapons and daily items such as bottles, keys, bags, books, pens, and every other available object. SDS is aplyable to any Defense System and thanks to its simple techniques and approaches, it is suitable for everyone.',
      systemsLibraryExists: true,
    },
    core: {
      title: 'SAMI-X Core',
      identifier: 'core',
      color: 'defensiveGreen-600',
      category: 'defense',
      categoryText: 'Defense',
      systemsLibraryExists: true,
    },
    weaponProtection: {
      title: 'SAMI-X Weapon Protection',
      identifier: 'weaponProtection',
      color: 'professionalYellow-300',
      category: 'pro',
      categoryText: 'pro',
      excerpt:
        'Again and again weapons are snatched away from those who carry weapons. "The protection of the weapon" is an important topic for weapon bearers. In most cases, only handling the weapon is trained, but hardly preventing it from being snatched away by force. We have developed this course that makes the participants sensitive and capable of action. Our system is taught and trained by various authorities in different European countries. Weapon Protection is part of SAMI-X PRO and can be experienced separately or as an integral part of the overall system.',
    },
    womenkravmaga: {
      title: 'SAMI-X Women',
      identifier: 'womenkravmaga',
      color: 'defensiveGreen-600',
      category: 'defense',
      categoryText: 'Defense',
      excerpt:
        'Women´s self-defence is not purely Krav Maga for female participants, but it´s about taking the possibilities, needs and situations of women and girls really seriously and solving them in a suitable way. In our understanding, our access "by women for women" is the means of choice. “Fight back!” Is the self-assertion and -defense program, that covers both the physical and the psychological approach to this special topic.',
    },
    securitykravmaga: {
      title: 'SAMI-X SEC',
      identifier: 'securitykravmaga',
      color: 'defensiveGreen-600',
      category: 'defense',
      categoryText: 'Defense',
      excerpt:
        'SAMI-X SEC (Security) is a purely purpose-driven program tailored to the needs of security personnel, taking into account local legality and proportionality. In addition to self-protection, the focus is also on teamwork, the order and legal situation, the professional environment, protection of other people, approach techniques, fixation techniques, transport techniques and much more.',
    },
    kidskravmaga: {
      title: 'SAMI-X Kids',
      identifier: 'kidskravmaga',
      color: 'defensiveGreen-600',
      category: 'defense',
      categoryText: 'Defense',
      excerpt:
        'The main focus in Kids Krav Maga is to integrate the joy of movement, fun and games as well as the topic of self-assertion and self-defense as a matter of course in the lives of children and young people. We always keep an eye on the respective age group and the appropriate topics.',
    },
    sirt: {
      title: 'SIRT',
      identifier: 'sirt',
      category: 'pro',
      categoryText: 'Pro',
      color: 'professionalYellow-300',
    },
    kickboxen: {
      title: 'Kickboxen',
      identifier: 'kickboxen',
      category: 'fightingArt',
      categoryText: 'Fighting Art',
      color: 'fightingRed-700',
    },
    lutaLivre: {
      title: 'Luta Livre',
      identifier: 'lutaLivre',
      category: 'fightingArt',
      categoryText: 'Fighting Art',
      color: 'fightingRed-700',
    },
    defender: {
      title: 'SAMI-X Defender',
      identifier: 'defender',
      color: 'defensiveGreen-600',
      category: 'defense',
      categoryText: 'Defense',
      excerpt:
        'The SAMI-X Defender is an extraordinary fist knife that the user can use both as a punch and pressure intensifier and as a cutting and stabbing object. Due to the unique and practical design, special handling is also possible, which we pass on to interested parties in courses and seminars.',
    },
    fitness: {
      title: 'SAMI-X Cross Training',
      identifier: 'fitness',
      color: 'defensiveGreen-600',
      category: 'defense',
      categoryText: 'Defense',
      excerpt:
        'SAMI-X Cross Training focuses on your body and your health. The training is designed to improve your physical condition, strength, endurance, flexibility and coordination. The training is suitable for everyone.',
    }
  };

  samiCategories: any = {
    pro: {
      title: 'SAMI-X PRO',
      color: 'professionalYellow-300',
    },
    fightingArt: {
      title: 'SAMI-X Fighting Art',
      color: 'fightingRed-700',
    },
    defense: {
      title: 'SAMI-X Defense',
      color: 'defensiveGreen-600'
    },
  }

  systemExamRanks = [
    {
      systemIdentiers: ['kravMaga', 'stick', 'knife', 'kapap', 'panantukan'],
      ranks: [
        'Trainee',
        'Candidate Instructor',
        'Assist Instructor',
        'Basic Instructor',
        'Associate Instructor',
        'Advanced Instructor 1',
        'Advanced Instructor 2',
        'Advanced Instructor 3',
        'Advanced Instructor 4',
        'Expert Instructor',
        'Full Instructor',
        'Master Instructor',
        'Head Instructor',
      ],
    },
    {
      systemIdentiers: ['axe'],
      ranks: [
        'Trainee',
        'Level 1 Instructor - Study Group Leader',
        'Basic Instructor',
        'Associate Instructor',
        'Advanced Instructor',
        'Expert Instructor',
        'Full Instructor',
        'Master Instructor',
        'Head Instructor',
      ],
    },
    {
      systemIdentiers: ['kidskravmaga', 'womenkravmaga', 'securitykravmaga'],
      ranks: [
        'Trainee',
        'Study Group Leader',
        'Basic Instructor',
        'Advanced Instructor',
      ],
    },
    {
      systemIdentiers: ['ebc', 'sds', 'defender', 'weaponProtection', 'karambit'],
      ranks: [
        'Trainee',
        'Modul 1 Instructor',
        'Modul 2 Instructor',
        'System Instructor',
      ],
    },
  ];

  setPrimaryColor(primary: string) {
    throw new Error('Method not implemented.');
  }

  capitalizeFirstLetter(s?: string) {
    return s && s.charAt(0).toUpperCase() + s.slice(1);
  }

  samiSystemsAsArray() {
    return Object.values(this.samiSystems);
  }

  utilsMkSearchIndex(searchableData: any) {
    const output: string[] = [];

    for (const dataPoint of searchableData) {
      if (!dataPoint) {
        continue;
      }
      const arr = ('' + dataPoint).toLowerCase().split('');
      let prevKey = '';

      for (const char of arr) {
        const key = prevKey + char;
        output.push(key);
        prevKey = key;
      }
    }

    return output;
  }

  constructor(public breakpointObserver: BreakpointObserver) {
    this.isMenuExpanded$ = new BehaviorSubject<boolean>(true);
    this.isPublicMenuExpanded$ = new BehaviorSubject<boolean>(false);
    this.isMobileLayout$ = this.breakpointObserver
      .observe(Breakpoints.XSmall)
      .pipe(
        map((match: any) => match.matches),
        tap((isMobile: boolean) => {
          if (isMobile) {
            this.isMenuExpanded$.next(false);
            this.isPublicMenuExpanded$.next(false);
          }
        })
      );

    this.isMediumLayout$ = this.breakpointObserver
      .observe([
        Breakpoints.Medium,
        Breakpoints.Small,
        Breakpoints.XSmall,
      ])
      .pipe(
        map((match: any) => match.matches),

      );
  }
}
