import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable, finalize } from 'rxjs';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
})
export class UploadFileComponent {
  uploadPercent: Observable<number> | any;
  @Input() path = '';
  @Input() allowMultiple = false;
  filePaths: string[] = [];
  @Output() filePath = new EventEmitter();

  files: File[] = [];

  constructor(private storage: AngularFireStorage) {}

  uploadFiles() {
    if (this.files && this.files.length > 0) {
      for (const file of this.files) {
        const filePath = this.path + '/' + Date.now() + '-' + file.name;
        const task = this.storage.upload(filePath, file);

        // observe percentage changes
        this.uploadPercent = task.percentageChanges();
        // get notified when the download URL is available
        task
          .snapshotChanges()
          .pipe(finalize(() => this.filePath.emit(filePath)))
          .subscribe();
      }
    }
  }
  onSelect(event: any) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }
}
