<div
  class="bg-white py-5 px-4 flex flex-col justify-start space-y-3"
  *ngIf="downloads; else loading"
>
  <span class="text-richBlack font-semibold text-xl font-sans">Downloads</span>
  <div class="flex flex-col justify-start space-y-3">
    <a
      *ngFor="let download of downloads"
      target="_blank"
      [href]="download.downloadPath | getDownloadURL"
      download
      class="p-4 border-2 hover:text-white hover:bg-richBlack border-richBlack flex flex-row justify-start items-center space-x-2"
    >
      <svg
        class="w-8"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12 9H4V11H12V9Z" fill="currentColor" />
        <path d="M10 13H4V15H10V13Z" fill="currentColor" />
        <path
          d="M16 6V12H18V4.586L13.414 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V20C0 20.5304 0.210714 21.0391 0.585786 21.4142C0.960859 21.7893 1.46957 22 2 22H12V20H2V2H12V6H16Z"
          fill="currentColor"
        />
        <path
          d="M22.293 17.293L20 19.586V14H18V19.586L15.707 17.293L14.293 18.707L19 23.414L23.707 18.707L22.293 17.293Z"
          fill="currentColor"
        />
      </svg>
      <div class="flex-1 flex flex-col justify-center items-start">
        <span class="text-base font-sans font-semibold text-left">
          {{ download.title }}
        </span>
        <span class="text-xs font-body text-left">
          {{ download.excerpt }}
        </span>
      </div>
    </a>
  </div>
</div>

<ng-template #loading>
  <div
    class="bg-white py-5 px-4 flex flex-col justify-start space-y-3"
  >
    <span class="text-richBlack font-semibold text-xl font-sans">Downloads</span>
    <div class="flex flex-col justify-start space-y-3">
      <a
        *ngFor="let download of downloads"
        target="_blank"
        [href]="download.downloadPath | getDownloadURL"
        download
        class="p-4 border-2 hover:text-white hover:bg-richBlack border-richBlack flex flex-row justify-start items-center space-x-2"
      >
        <svg
          class="w-8"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 9H4V11H12V9Z" fill="currentColor" />
          <path d="M10 13H4V15H10V13Z" fill="currentColor" />
          <path
            d="M16 6V12H18V4.586L13.414 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V20C0 20.5304 0.210714 21.0391 0.585786 21.4142C0.960859 21.7893 1.46957 22 2 22H12V20H2V2H12V6H16Z"
            fill="currentColor"
          />
          <path
            d="M22.293 17.293L20 19.586V14H18V19.586L15.707 17.293L14.293 18.707L19 23.414L23.707 18.707L22.293 17.293Z"
            fill="currentColor"
          />
        </svg>
        <div class="flex-1 flex flex-col justify-center items-start">
          <span class="text-base font-sans font-semibold text-left">
            {{ download.title }}
          </span>
          <span class="text-xs font-body text-left">
            {{ download.excerpt }}
          </span>
        </div>
      </a>
    </div>
  </div>
</ng-template>