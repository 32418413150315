import {
  AfterViewInit,
  Component, ElementRef, Input,
  OnChanges,
  ViewChild
} from '@angular/core';
import { VideoService } from '@sami/features';
import Player from '@vimeo/player';


@Component({
  selector: 'app-vimeo',
  templateUrl: './vimeo.component.html',
})
export class VimeoComponent implements AfterViewInit, OnChanges {
  @Input() videoId: number | string | undefined;
  @ViewChild('playerContainer') playerContainer: ElementRef<HTMLElement> | undefined;

  player: Player | undefined;

  constructor(private video: VideoService) {
    // Convert videoId to number if it's a string
    if (typeof this.videoId === 'string') {
      this.videoId = parseInt(this.videoId, 10);
    }
  }

  ngAfterViewInit() {
    if (!this.playerContainer) {
      return;
    }

    if (!this.videoId) {
      return;
    }

    this.player = new Player(this.playerContainer.nativeElement, {
      id: this.videoId as number,
      color: '001427',
      title: false,
      portrait: false,
      byline: false,
    });
    this.player.setVolume(this.video.volume$.getValue());

    this.loadVimeoVideo(this.videoId as number);

    this.player.on('volumechange', (data: any) => {
      this.video.volume$.next(data.volume);
    });
  }

  async ngOnChanges(): Promise<void> {
    if (this.player) {
      this.loadVimeoVideo(this.videoId as number);
    }
  }

  async loadVimeoVideo(id: number) {
    if (this.player) {
      await this.player.loadVideo(id);
    }
  }
}
