import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { combineLatest, lastValueFrom, map, Observable, take } from 'rxjs';
import { serverTimestamp } from 'firebase/firestore';
import { SamiUser } from 'interfaces';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class TosService {
  currentTOS$: Observable<any>;
  currentInstructorAgreement$: Observable<any>;

  constructor(private afs: AngularFirestore, private auth: AuthService) {
    this.currentTOS$ = combineLatest([
      this.afs.doc(`app/tos`).valueChanges({ idField: '_id' }),
      this.auth.user$,
    ]).pipe(
      map(([tos, user]: [any, SamiUser]) => ({
        ...tos,
        currentAccepted: user?.tos && user?.tos[tos.currentVersion],
        upcomingAccepted: user?.tos && user?.tos[tos.upcomingVersion],
      }))
    );

    this.currentInstructorAgreement$ = combineLatest([
      this.afs.doc(`app/instructorAgreement`).valueChanges({ idField: '_id' }),
      this.auth.user$,
    ]).pipe(
      map(([instructorAgreement, user]: [any, SamiUser]) => ({
        ...instructorAgreement,
        currentAccepted:
          user?.instructorAgreement &&
          user?.instructorAgreement[instructorAgreement.currentVersion],
        upcomingAccepted:
          user?.instructorAgreement &&
          user?.instructorAgreement[instructorAgreement.upcomingVersion],
      }))
    );
  }

  async onAcceptTOS(version: string): Promise<void> {
    if (!version) {
      return;
    }

    try {
      const { uid } = await lastValueFrom(this.auth.authUser$.pipe(take(1)));
      await this.afs.doc(`users/${uid}`).update({
        [`tos.${version}`]: serverTimestamp(),
      });
    } catch (error) {
      console.error(error);
    }
  }

  async onAcceptInstructorAgreement(version: string): Promise<void> {
    if (!version) {
      return;
    }

    try {
      const { uid } = await lastValueFrom(this.auth.authUser$.pipe(take(1)));
      await this.afs.doc(`users/${uid}`).update({
        [`instructorAgreement.${version}`]: serverTimestamp(),
      });
    } catch (error) {
      console.error(error);
    }
  }
}
