import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TracksService } from '@sami/features';
import { Track } from 'interfaces';
import { switchMap, filter, map, tap, Observable } from 'rxjs';


@Component({
  selector: 'app-track-next-element-button',
  templateUrl: './track-next-element-button.component.html',
})
export class TrackNextElementButtonComponent implements OnInit {
  clipId!: string;
  playlistId!: string;
  trackId!: string;

  upcoming$: Observable<any | null | undefined>;

  constructor(private route: ActivatedRoute, private tracks: TracksService) {
    const { clipId } = this.route.snapshot.params;
    this.clipId = clipId;

    this.upcoming$ = this.route.queryParams.pipe(
      filter((params: any) => params.trackId),
      tap((params: any) => {
        this.trackId = params.trackId;
        this.playlistId = params.playlist;
      }),
      switchMap((params: any) => this.tracks.getTrack$(params.trackId)),
      map((track: Track | undefined) => {
        if (!track || !track.chapters) {
          return;
        }

        const useId = this.playlistId || this.clipId;
        const currentChapterIdx = track.chapters.findIndex((chapter: any) =>
          chapter.elements.find((el: any) => el.ref.path.includes(useId))
        );
        const currentChapter = track.chapters[currentChapterIdx];

        if (
          !(currentChapterIdx >= 0) ||
          !currentChapter ||
          !currentChapter.elements
        ) {
          return;
        }

        const currentElementIdx = currentChapter.elements.findIndex((el: any) =>
          el.ref.path.includes(useId)
        );
        const nextElement = currentChapter.elements[currentElementIdx + 1];

        const nextChapter = track.chapters[currentChapterIdx + 1];

        const useElement = nextElement || nextChapter?.elements?.[0];

        if (!useElement) {
          return;
        }

        const url =
          useElement.type === 'playlist'
            ? `/app/playlist/${useElement.ref.id}`
            : `/app/video/${useElement.ref.id}`;

        const queryParams =
          useElement.type === 'playlist'
            ? { plScope: 'system', trackId: this.trackId, via: 'track' }
            : {
                plScope: 'system',
                trackId: this.trackId,
                via: 'track',
                type: 'video',
              };

        return { ...useElement, url, queryParams };
      })
    );
  }

  ngOnInit(): void {}
}
