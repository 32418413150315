<div *ngIf="entry; else noData" class="bg-white">
  <div class="sticky top-0 bg-white bg-opacity-70 backdrop-filter backdrop-blur text-lg font-semibold mb-4 p-4 z-10 shadow-md">
    {{ entry.style.title }}
    <button (click)="closeElement.emit()" class="w-4 h-4 absolute top-4 right-4">
      <svg
        class="w-4 h-4"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3849_30117)">
          <path
            d="M1 1L23 23"
            stroke="black"
            stroke-width="2"
            stroke-linejoin="round"
          />
          <path
            d="M23 1L1 23"
            stroke="black"
            stroke-width="2"
            stroke-linejoin="round"
          />
        </g>
      </svg>
    </button>
  </div>

  <ng-container *ngIf="!loading">
    <div class="px-4" *ngIf="!pdf">
      <div *ngFor="let historyItem of sortedHistory" class="mb-6">
        <table class="min-w-full table-auto font-body text-sm border-collapse">
          <tbody>
            <tr>
              <td class="border w-20 p-2 font-medium">Status</td>
              <td class="border p-2 capitalize">{{ historyItem.status }}</td>
            </tr>
            <tr>
              <td class="border w-20 p-2 font-medium">Date</td>
              <td class="border p-2">{{ historyItem.date.toDate() | date: 'mediumDate' }}</td>
            </tr>
            <tr *ngIf="historyItem.level">
              <td class="border w-20 p-2 font-medium">Level</td>
              <td class="border p-2">{{ historyItem.level }}</td>
            </tr>
            <tr *ngIf="historyItem.examText">
              <td class="border w-20 p-2 font-medium">Exam</td>
              <td class="border p-2">{{ historyItem.examText }}</td>
            </tr>
            <tr *ngIf="historyItem.note">
              <td class="border w-20 p-2 font-medium">Note</td>
              <td class="border p-2">{{ historyItem.note }}</td>
            </tr>
            <tr *ngIf="historyItem.center">
              <td class="border w-20 p-2 font-medium">Center</td>
              <td class="border p-2">{{ historyItem.center.name }}</td>
            </tr>
            <tr *ngIf="historyItem.instructor">
              <td class="border w-20 p-2 font-medium">Instructor</td>
              <td class="border p-2">{{ historyItem.instructor.name }}</td>
            </tr>
            <ng-container *ngIf="historyItem.status === 'passed'">
              <tr>
                <td class="border w-20 p-2 font-medium">Download</td>
                <td class="border p-2">
                  <button (click)="download(historyItem)" class="underline text-richBlack">
                    Generate diploma
                  </button>
                </td>
              </tr>
            </ng-container>

            <ng-container *ngIf="auth.user$ | async as user">
              <ng-container *ngIf="user.isGlobalAdmin">
                <tr>
                  <td class="border w-20 p-2 font-medium">Delete</td>
                  <td class="border p-2">
                    <button (click)="onDelete(historyItem)" class="underline text-richBlack">
                      Delete diploma
                    </button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>

    <div class="px-4 flex flex-col justify-start space-y-2 pb-4 sm:pb-0" *ngIf="pdf">
      <img [src]="pdf.image_url" alt="SAMI-X Diploma" class="w-full border-2 border-richBlack">
      <p class="text-center text-xs font-body">The PDF was generated. Please click on the button below to download it.</p>

      <div class="flex flex-row justify-center items-center space-x-4">
        <a [href]="pdf.pdf_url" download="Diploma.pdf" target="_blank" class="text-richBlack cursor-pointer font-body underline text-sm">
          Download Diploma
        </a>
        <button (click)="pdf = undefined" class="text-sm font-body cursor-pointer underline text-cadetGrey-500">
          Return to overview
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="loading">
    <div class="w-full h-auto flex flex-col justify-center items-center space-y-4 p-8">
      <mat-spinner diameter="30"></mat-spinner>
      <span class="font-body text-sm text-richBlack">Loading...</span>
    </div>
  </ng-container>
</div>
<ng-template #noData>
  <p class="text-center text-gray-500">No history data available.</p>
</ng-template>
