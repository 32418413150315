import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SamiSeminar } from 'interfaces';
import { TrackContentComponent } from './track-content/track-content.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-seminar-online-content',
  standalone: true,
  imports: [CommonModule, TrackContentComponent],
  templateUrl: './seminar-online-content.component.html'
})
export class SeminarOnlineContentComponent {
  @Input() seminar: SamiSeminar | undefined;
  @Input() background: 'white' | 'culturedWhite' = 'white';

}
