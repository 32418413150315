<div
  [ngClass]="getBackgroundColor()
  "
  class="h-full relative py-8 px-6 align-top"
>
  <div class="relative table h-full w-full">
    <div class="flex flex-row justify-between items-start">
      <span class="text-xl font-semibold" *ngIf="samiInternationalPricing">
        SAMI International<br />
        Membership
      </span>

      <span
        class="text-xl font-semibold"
        *ngIf="samiXPricingMonth && samiXPricingYear"
      >
        SAMI-X<br />
        Membership
      </span>

      <div
        *ngIf="hasSubscription"
        class="px-4 py-2 bg-activeGreen text-white rounded-full flex flex-row justify-center items-center space-x-2"
      >
        <svg
          class="w-4 h-4"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7071 6.70711C12.0976 6.31658 12.0976 5.68342 11.7071 5.29289C11.3166 4.90237 10.6834 4.90237 10.2929 5.29289L7 8.58579L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711Z"
            fill="#EDF2EF"
          />
        </svg>
        <span> Active </span>
      </div>
    </div>

    <ng-container
      *ngIf="activeSubscriptions$ | async as s; else noSubscription"
    >
      <ng-container *ngIf="s.length > 0; else noSubscription">
        <ng-container *ngIf="s[0] as subscription">
          <div class="flex flex-row justify-between items-end pt-4">
            <p *ngIf="subscription.stripePlan && subscription.stripePlan.amount">
              <span class="text-4xl font-semibold tracking-tight" *ngIf="subscription?.stripePlan?.amount">
                {{
                  subscription?.stripePlan?.amount / 100
                    | currency : subscription.stripePlan?.currency?.toUpperCase()
                }}
              </span>
              <span class="text-sm font-medium text-cadetGrey-500">
                /
                {{
                  subscription.stripePlan.interval === 'year' ? 'year' : 'month'
                }}
              </span>
            </p>
            <div
              *ngIf="
                subscription &&
                  subscription.nextBillingDate &&
                  !subscription.cancel_at_period_end;
                else showEndSubscriptionText
              "
            >
              <span class="text-xs" *ngIf="!subscription.schedule">
                Renews on
                {{
                  subscription.nextBillingDate.toDate() | date : 'MMM d. yyyy'
                }}
              </span>

              <span
                class="text-xs text-fightingRed-600"
                *ngIf="subscription.schedule"
              >
                Downgrades on
                {{
                  subscription.nextBillingDate.toDate() | date : 'MMM d. yyyy'
                }}
              </span>
            </div>
            <ng-template #showEndSubscriptionText>
              <div *ngIf="subscription && subscription.nextBillingDate">
                <span class="text-xs">
                  Ends on
                  {{
                    subscription.nextBillingDate.toDate() | date : 'MMM d. yyyy'
                  }}
                </span>
              </div>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #noSubscription>
      <p class="pt-4" *ngIf="samiInternationalPricing">
        <span class="text-4xl font-semibold tracking-tight">
          {{
            samiInternationalPricing?.currency_options[
              location?.currency?.code | lowercase
            ]
              ? (samiInternationalPricing.currency_options[
                  location.currency.code | lowercase
                ]?.unit_amount / 100 | currency : location.currency.code)
              : (samiInternationalPricing.currency_options['usd'].unit_amount /
                  100 | currency : 'USD')
          }}
        </span>
        <span class="text-sm font-medium text-cadetGrey-500">/year</span>
      </p>

      <div
        class="flex flex-row justify-between items-end pt-4"
        *ngIf="samiXPricingMonth && samiXPricingYear"
      >
        <div class="flex flex-row justify-start items-end">
          <span class="text-4xl font-semibold">
            {{
              samiXPricingMonth.currency_options[
                location.currency.code | lowercase
              ]
                ? (samiXPricingMonth.currency_options[
                    location.currency.code | lowercase
                  ]?.unit_amount / 100 | currency : location.currency.code)
                : (samiXPricingMonth.currency_options['usd'].unit_amount / 100
                  | currency : 'USD')
            }}
          </span>
          <span class="text-sm font-medium text-cadetGrey-500">/month</span>
        </div>

        <span class="text-sm text-cadetGrey-600 font-body"> or </span>

        <div class="relative">
          <div class="flex flex-row justify-start items-end">
            <span class="text-4xl font-semibold">
              {{
                samiXPricingYear.currency_options[
                  location.currency.code | lowercase
                ]
                  ? (samiXPricingYear.currency_options[
                      location.currency.code | lowercase
                    ]?.unit_amount / 100 | currency : location.currency.code)
                  : (samiXPricingYear.currency_options['usd'].unit_amount / 100
                    | currency : 'USD')
              }}
            </span>
            <span class="text-sm font-medium text-cadetGrey-500">/year</span>
          </div>
          <div
            class="absolute flex flex-row justify-center items-center space-x-2 inset-x-0 text-center -bottom-3 font-body text-xs"
          >
            <span class="line-through">
              {{
                samiXPricingMonth.currency_options[
                  location.currency.code | lowercase
                ]
                  ? ((samiXPricingMonth.currency_options[
                      location.currency.code | lowercase
                    ]?.unit_amount /
                      100) *
                      12 | currency : location.currency.code)
                  : ((samiXPricingMonth.currency_options['usd'].unit_amount /
                      100) *
                      12 | currency : 'USD')
              }}
            </span>
            <span class="text-fightingRed-600"> save 17% </span>
          </div>
        </div>
      </div>
    </ng-template>

    <p class="py-4 text-sm font-body" *ngIf="samiInternationalPricing">
      Join SAMI International to take official exams and get your SAMI
      certification including exam preparation.
    </p>
    <p class="py-4 text-sm font-body" *ngIf="samiXPricingMonth">
      Get SAMI certifications + full access to our online training plattform
      SAMI-X Online.
    </p>

    <div *ngIf="hasSubscription && !hasInstructorMembership" class="grid grid-cols-2 gap-4">
      <ng-container *ngIf="activeSubscriptions$ | async as s; else noSubscription">
        <ng-container *ngIf="s.length > 0; else noSubscription">
          <ng-container *ngIf="s[0] as subscription">
            <button
              (click)="onRedirectToPortal()"
              class="text-sm font-semibold px-2 py-2 bg-white border-2 border-white hover:border-culturedWhite text-richBlack hover:bg-culturedWhite"
            >
              Payment & Invoice
            </button>
            <ng-container *ngIf="!subscription.schedule">
              <ng-container
                *ngIf="
                  !subscription.cancel_at_period_end;
                  else showReactiveButton
                "
              >
                <button
                  (click)="onCancelSubscription(subscription)"
                  class="text-xs font-body px-2 py-2 bg-transparent text-cadetGrey-400 hover:underline"
                >
                  Cancel Membership
                </button>
              </ng-container>
              <ng-template #showReactiveButton>
                <button
                  (click)="onReactivateSubscription(subscription)"
                  class="text-xs font-body px-2 py-2 bg-transparent text-cadetGrey-400 hover:underline"
                >
                  Reactivate membership
                </button>
              </ng-template>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <ng-container *ngIf="!hasSubscription">
      <div
        *ngIf="hasInstructorMembership && samiInternationalPricing"
        class="grid gap-4"
      >
        <button
          class="text-sm font-semibold px-2 py-2 bg-white/20 border-2 border-white/20 cursor-default text-white/30"
        >
          Active due to SAMI Instructor License
        </button>
      </div>

      <ng-container *ngIf="samiXPricingMonth && hasBasicMembership">
        <ng-container
          *ngIf="activeBasicSubscriptions$ | async as basicSubscriptions"
        >
          <div class="grid gap-4">
            <button
              (click)="
                onUpgradeSubscription(
                  samiXPricingMonth,
                  samiXPricingYear,
                  basicSubscriptions,
                  location
                )
              "
              class="block w-full flex-grow rounded-none font-body border border-gray-800 bg-gray-800 py-3 px-6 text-center text-sm font-semibold text-white hover:bg-gray-900"
            >
              <span> Upgrade </span>
            </button>
          </div>
        </ng-container>
      </ng-container>

      <ng-container
        *ngIf="
          !hasInstructorMembership &&
          samiInternationalPricing &&
          hasPlusMembership
        "
      >
        <ng-container
          *ngIf="activePlusSubscriptions$ | async as activePlusSubscriptions"
        >
          <div class="grid gap-4" *ngIf="!activePlusSubscriptions[0].schedule">
            <button
              (click)="
                onDowngradeSubscription(
                  samiInternationalPricing,
                  activePlusSubscriptions
                )
              "
              class="block w-full flex-grow rounded-none font-body border border-gray-800 bg-gray-800 py-3 px-6 text-center text-sm font-semibold text-white hover:bg-gray-900"
            >
              <span> Downgrade </span>
            </button>
          </div>
        </ng-container>
      </ng-container>

      <div
        *ngIf="
          !hasInstructorMembership &&
          samiInternationalPricing &&
          !hasPlusMembership
        "
        class="grid gap-4"
      >
        <button
          (click)="
            getMembership(
              user,
              samiInternationalPricing,
              'basic',
              location.currency.code,
              true
            )
          "
          class="block w-full flex-grow rounded-none font-body border border-gray-800 bg-gray-800 py-3 px-6 text-center text-sm font-semibold text-white hover:bg-gray-900"
        >
          <span> Get SAMI International Membership </span>
        </button>
      </div>

      <div *ngIf="samiXPricingMonth && !hasBasicMembership" class="grid gap-4">
        <button
          (click)="
            getMembership(
              user,
              samiXPricingMonth,
              'plus',
              location.currency.code,
              hadSubscription
            )
          "
          class="block w-full flex-grow rounded-none font-body border border-gray-800 bg-gray-800 py-3 px-6 text-center text-sm font-semibold text-white hover:bg-gray-900"
        >
          <span> {{ hadSubscription ? 'Get SAMI-X Membership' : 'Start 7 day free trial' }} </span>
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="samiInternationalPricing">
      <ng-container *ngIf="activePlusSubscriptions$ | async as plus">
        <ng-container *ngIf="plus[0] && plus[0].schedule">
          <button
            *ngIf="!plus[0].cancel_at_period_end && !hasInstructorMembership"
            class="text-sm w-full font-semibold px-2 py-2 bg-richBlack/20 border-2 border-richBlack/20 cursor-default text-richBlack/30"
          >
            Starts on
            {{ plus[0].nextBillingDate.toDate() | date : 'MMM d. yyyy' }}
          </button>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <div
    *ngIf="loading"
    class="absolute inset-0 w-full h-full bg-richBlack/90 flex flex-col justify-center items-center"
  >
    <div role="status">
      <svg
        aria-hidden="true"
        class="mr-2 w-8 h-8 text-gray-50 animate-spin fill-gray-600"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
