import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SamiUser } from 'interfaces';
import { SamiIcon } from '@sami/ui';
import { MenuService } from '../../../menu.service';

interface SamiMenu {
  title: string;
  items: SamiMenuItem[];
}

interface SamiMenuItem {
  icon?: SamiIcon,
  title: string,
  route: string,
  active?: boolean,
  submenu?: {
    items: SamiSubmenuItem[];
    featured?: SamiSubmenuItem;
  }
}

interface SamiSubmenuItem {
  title: string;
  route: string;
  description: string;
  image?: string;
  isFeatured?: boolean;
}

@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html'
})
export class SidenavMenuComponent {
  @Input() lang = 'en';
  @Input() backgroundColor: 'white' | 'black' = 'white';
  @Input() user: SamiUser | undefined;
  @Input() isMobile: boolean | null = false;
  @Input() isSmartphone: boolean | null = false;
  @Input() currentSite: 'sami-international' | 'sami-x' | 'sami-x-app' | 'admin' | 'b2b' | 'instructor-area' | 'shop' = 'sami-international';

  menuItems: SamiMenu[] = [];

  @Output() toggleSidenav: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public menuService: MenuService,
  ) {
  }
}
