<div
  [ngClass]="'bg-' + returnSystemComponent(system, 'color')"
  class="grid grid-cols-1 sm:grid-cols-3 h-full relative overflow-hidden"
>
  <div class="absolute right-0 top-0 bottom-0 h-full pointer-events-none">
    <picture>
      <source
        type="image/webp"
        [srcset]="
          'https://a.storyblok.com/f/110844/411x600/c6eccd9685/track_x.png'
            | transformImg
              : 'storyblok'
              : 'fit-in/0x800/smart/filters:format(webp)'
        "
      />
      <source
        type="image/png"
        [srcset]="
          'https://a.storyblok.com/f/110844/411x600/c6eccd9685/track_x.png'
            | transformImg
              : 'storyblok'
              : 'fit-in/0x800/smart/filters:format(png)'
        "
      />
      <img
        loading="lazy"
        class="h-full scale-150"
        [src]="
          'https://a.storyblok.com/f/110844/411x600/c6eccd9685/track_x.png'
            | transformImg: 'storyblok':'fit-in/0x800/smart'
        "
        [alt]="'Onboarding Step'"
      />
    </picture>
  </div>

  <div class="relative h-44 sm:h-auto w-full" *ngIf="image">
    <div
      class="absolute inset-0 w-full h-full flex flex-col justify-center items-center bg-richBlack/70 z-10"
      *ngIf="status === 'finished' || status === 'exam_passed'"
    >
      <svg
        class="w-20 h-20"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M41.1321 10L17.5301 33.2L6.47606 22.26L3.66406 25.104L16.1181 37.432C16.4924 37.8014 16.9971 38.0086 17.5231 38.0086C18.049 38.0086 18.5538 37.8014 18.9281 37.432L43.9281 12.848L41.1321 10Z"
          fill="#EDF2EF"
        />
      </svg>
    </div>
    <img
      loading="lazy"
      class="absolute object-cover w-full h-full inset-0 object-center"
      [src]="
        image | getDownloadURL | transformImg: 'firestore':'pr=true,w-400'
      "
      [alt]="title"
    />
  </div>
  <div
    [ngClass]="image ? 'col-span-2' : 'col-span-3'"
    class="flex flex-col justify-start"
  >
    <div
      class="flex-1 flex-col justify-center px-2 sm:px-5 pt-4 pb-4 sm:pt-10 space-y-2 sm:space-y-6"
    >
      <h1 class="text-xl sm:text-5xl font-sans text-white tracking-tight font-bold">
        {{ title }}
      </h1>
      <p [ngClass]="showMoreText ? 'line-clamp-none' : 'line-clamp-2 md:line-clamp-none'" class="text-sm sm:text-base md:line-clamp-none max-w-xs md:max-w-none text-white font-body">
        {{ description }}
      </p>

      <button (click)="showMoreText = !showMoreText" class="text-gray-100 sm:hidden block underline text-sm font-body">
        Show {{ showMoreText ? 'less' : 'more' }}
      </button>

      <div
        *ngIf="status === 'finished'"
        class="bg-culturedWhite p-1 sm:p-2 w-full flex flex-row justify-start items-center space-x-4"
      >
        <div
          class="h-16 sm:h-20 flex-shrink-0"
          *ngIf="statementOfAccomplishmentBadgePath"
        >
          <img
            class="h-full"
            [src]="statementOfAccomplishmentBadgePath | getDownloadURL"
            [alt]="title"
          />
        </div>
        <div
          class="flex flex-col sm:flex-row justify-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-2"
        >
          <div
            class="flex flex-col justify-start items-start space-y-0 text-richBlack py-2 sm:py-4"
          >
            <span class="text-lg sm:text-xl font-sans font-semibold">
              {{
                trackType === 'diploma'
                  ? 'Congratulations, you have finished this track!'
                  : 'TRACK COMPLETED'
              }}
            </span>
            <span class="text-sm sm:text-base">
              {{
                trackType === 'diploma'
                  ? 'Now it’s time to get SAMI certified and receive your official SAMI diploma by taking the official exam.'
                  : 'Congratulations, you have successfully finished this special track!'
              }}
            </span>
          </div>
          <a
            *ngIf="trackType === 'diploma'"
            href="https://help.sami-x.com/en/articles/6537793-how-can-i-get-certified"
            target="_blank"
            class="bg-richBlack sm:px-6 px-3 py-1 text-base sm:py-2 uppercase text-white sm:text-lg flex flex-row justify-center items-center space-x-2 hover:bg-cadetGrey-900"
          >
            <span class="whitespace-nowrap"> Get Certified </span>
            <svg
              class="w-6 h-6"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2772_1675)">
                <path
                  d="M14.167 15L19.167 10L14.167 5"
                  stroke="#EDF2EF"
                  stroke-width="2"
                  stroke-linejoin="round"
                />
                <path
                  d="M0 10H19.1667"
                  stroke="#EDF2EF"
                  stroke-width="2"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2772_1675">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
      </div>

      <div
        *ngIf="status === 'exam_passed'"
        class="bg-culturedWhite p-1 sm:p-2 w-full flex flex-row justify-start items-center space-x-4"
      >
        <div class="h-16 sm:h-20 flex-shrink-0" *ngIf="certificateBadgePath">
          <img
            class="h-full"
            [src]="certificateBadgePath | getDownloadURL"
            [alt]="title"
          />
        </div>
        <div
          class="flex flex-col sm:flex-row justify-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-2"
        >
          <div
            class="flex flex-col justify-start items-start space-y-0 text-richBlack py-2 sm:py-4"
          >
            <span class="text-lg sm:text-xl font-sans font-semibold">
              EXAM PASSED
            </span>
            <span class="text-sm sm:text-base">
              Congratulations, you have successfully finished this exam track!
            </span>
          </div>
        </div>
      </div>

      <ng-container *ngIf="type === 'track'">
        <ng-container
          *ngIf="
            (status === 'inactive' || (status === 'exam_passed' && !progress)) || (status === 'active' ||
            (status === 'exam_passed' &&
              progress &&
              progress > 0 &&
              progress < 1)) || (status === 'paused')
          "
        >
          <div class="md:flex flex-row justify-between items-end hidden">
            <div class="h-20 sm:h-28 flex-shrink-0">
              <img
                *ngIf="diplomaTrackBadge"
                class="h-full"
                [src]="diplomaTrackBadge | getDownloadURL"
                [alt]="title"
              />
            </div>
            <sami-start-track-button *ngIf="trackId" [user]="user" [trackId]="trackId"></sami-start-track-button>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="w-full flex flex-row justify-start space-x-2">
      <div
        [ngClass]="status !== 'finished' && showProgress && progress ? 'space-y-2 sm:space-y-4' : ''"
        class="flex-1 bg-richBlack/20 px-2 py-2 sm:px-3 sm:py-4 flex flex-col justify-center items-start"
      >
        <div
          class="flex w-full flex-row justify-start items-center"
          *ngIf="status !== 'finished' && showProgress && progress"
        >
          <div
            *ngIf="progress"
            class="w-full flex flex-row justify-start items-center space-x-2"
          >
            <span class="text-sm font-sans sm:text-base text-white">
              {{ (progress * 100 | number: '1.0-0') + '%' }}
            </span>
            <div
              class="w-full bg-richBlack/50 rounded-none h-1 sm:h-2 dark:bg-richBlack/50"
            >
              <div
                class="bg-culturedWhite h-1 sm:h-2 rounded-none"
                [ngStyle]="{ width: progress * 100 + '%' }"
              ></div>
            </div>
          </div>
        </div>
        <div class="flex flex-row justify-start items-center space-x-6">
          <div
            *ngIf="duration"
            class="flex flex-row justify-start space-y-0 items-center space-x-2"
          >
            <svg
              class="w-4 h-4"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7ZM7 1.16667C3.77834 1.16667 1.16667 3.77834 1.16667 7C1.16667 10.2216 3.77834 12.8333 7 12.8333C10.2216 12.8333 12.8333 10.2216 12.8333 7C12.8333 3.77834 10.2216 1.16667 7 1.16667ZM7.58333 6.41667V3.43527H6.41667V7C6.41667 7.32217 6.67783 7.58333 7 7.58333H9.85192V6.41667H7.58333Z"
                fill="#EDF2EF"
              />
            </svg>
            <span class="text-sm font-sans text-culturedWhite uppercase font-semibold"
              >{{ duration }} Hours</span
            >
          </div>

          <div
            *ngIf="lessons"
            class="flex flex-row justify-start space-y-0 items-center space-x-2"
          >
            <svg
              class="w-4 h-4"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.25 4.66675V9.33341L9.33333 7.00008L5.25 4.66675Z"
                fill="#EDF2EF"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 2.33333C0 2.01117 0.261167 1.75 0.583333 1.75H13.4167C13.7388 1.75 14 2.01117 14 2.33333V11.6667C14 11.9888 13.7388 12.25 13.4167 12.25H0.583333C0.261167 12.25 0 11.9888 0 11.6667V2.33333ZM1.16667 2.91667V11.0833H12.8333V2.91667H1.16667Z"
                fill="#EDF2EF"
              />
            </svg>
            <span class="text-sm font-sans text-culturedWhite uppercase font-semibold"
              >{{ lessons }} Lessons</span
            >
          </div>
        </div>
      </div>
      <ng-container *ngIf="status !== 'exam_passed'">
        <div
          class="p-3 sm:p-4 bg-richBlack/50 flex flex-row justify-center items-center space-x-8"
          *ngIf="
            hasSamiInternationalMembership &&
            startedExamPath &&
            (numberOfAssignments || numberOfLiveTrainings)
          "
        >
          <div
            class="flex flex-col justify-items-start space-y-1"
            *ngIf="numberOfLiveTrainings"
          >
            <div class="flex flex-row justify-center items-center space-x-2">
              <svg
                class="h-4 w-4 sm:w-6 sm:h-6 text-culturedWhite"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.33333 8.63598L10 10.0833L13.5833 8.66881V11.3333C11.1667 12.9166 8.75 12.9166 6.33333 11.3333V8.63598Z"
                  fill="#EDF2EF"
                />
                <path
                  d="M3.75 6.3333L10 8.83331L16.25 6.3333L10 3.91665L3.75 6.3333Z"
                  fill="#EDF2EF"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.74806 9.99998V6.33331H5.41472V9.99998H3.74806Z"
                  fill="#EDF2EF"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 1.66665C0 1.20641 0.373096 0.833313 0.833333 0.833313H19.1667C19.6269 0.833313 20 1.20641 20 1.66665V15C20 15.4602 19.6269 15.8333 19.1667 15.8333H0.833333C0.373096 15.8333 0 15.4602 0 15V1.66665ZM1.66667 2.49998V14.1666H18.3333V2.49998H1.66667Z"
                  fill="#EDF2EF"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.16667 18.3333V15H10.8333V18.3333H9.16667Z"
                  fill="#EDF2EF"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.1667 19.1666H5.83333V17.5H14.1667V19.1666Z"
                  fill="#EDF2EF"
                />
              </svg>
              <span
                class="text-lg font-sans sm:text-2xl font-semibold text-culturedWhite"
              >
                {{ liveTrainingsPassed }}/{{ numberOfLiveTrainings }}
              </span>
            </div>
            <span class="text-sm font-sans sm:text-base uppercase text-culturedWhite/60">
              Live Sessions
            </span>
          </div>

          <div
            class="flex flex-col justify-items-start space-y-1"
            *ngIf="numberOfAssignments"
          >
            <div class="flex flex-row justify-center items-center space-x-2">
              <svg
                class="h-4 w-4 sm:w-6 sm:h-6 text-culturedWhite"
                viewBox="0 0 18 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.666016 0.833333C0.666016 0.373096 1.03911 0 1.49935 0H12.3327C12.5537 0 12.7657 0.0877973 12.9219 0.244078L17.0886 4.41074C17.2449 4.56702 17.3327 4.77899 17.3327 5V19.1667C17.3327 19.6269 16.9596 20 16.4993 20H1.49935C1.03911 20 0.666016 19.6269 0.666016 19.1667V0.833333ZM2.33268 1.66667V18.3333H15.666V5.34518L11.9875 1.66667H2.33268Z"
                  fill="#EDF2EF"
                />
                <path
                  d="M11.4993 0.833333V5.83333H16.4993L11.4993 0.833333Z"
                  fill="#EDF2EF"
                />
                <path d="M10.666 10H4.83268V15H10.666V10Z" fill="#EDF2EF" />
                <path
                  d="M10.666 13.75L13.166 15V10L10.666 11.25V13.75Z"
                  fill="#EDF2EF"
                />
              </svg>
              <span
                class="text-lg font-sans sm:text-2xl font-semibold text-culturedWhite"
              >
                {{ assignmentsPassed }}/{{ numberOfAssignments }}
              </span>
            </div>
            <span class="text-sm font-sans sm:text-base uppercase text-culturedWhite/60">
              Assignments
            </span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
