import { SamiStyleModel } from './sami-style.model';

export interface SamiCourse {
  [key: string]: any;
  id?: string;
  slug?: string;
  title?: string;
  subtitle?: string;
  price?: number;
  overHeadCosts?: number;
  status?: 'public' | 'private' | 'unpublished';
  accessMode?: 'open' | 'free' | 'buyNow' | 'closed';
  releaseDate?: Date | any;
  hasIndividualStartDate?: boolean;
  salesPrice?: number;
  duration?: string;
  style?: SamiStyleModel;
  language?: string;
  additionalInformation?: string;
  targetGroups?: string[];
  outcomes?: string[];
  requirements?: string[];
  thumbnail?: string;
  previewVideoId?: string;
  lessons?: number;
  lessonList?: {
    [key: string]: any;
    id?: string;
    ref?: any;
  }[];
  ressources?: number;
  ressourceList?: {
    [key: string]: any;
    id?: string;
    ref?: any;
  }[];
  center?: {
    id?: string;
    name?: string;
    slug?: string;
  };
  instructorIds?: string[];
  instructor?: {
    id?: string;
    name?: string;
    center?: {
      id?: string;
      name?: string;
      slug?: string;
    };
  }[];
  attendees?: number;
  attendeeList?: {
    id?: string;
    ref?: any;
  }[];
  mailingList?: {
    lastSynced?: Date | any;
    lists: {
      id: number;
      name: string;
      totalBlacklisted: number;
      totalSubscribers: number;
    }[];
  };
  stripeProductId?: string;
}
