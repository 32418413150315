<ng-container *ngIf="{ currentSite: ui.currentSite$ | async } as vm">
  <div
    *ngIf="chapter && chapter.lessons && chapter.lessons.length > 0"
    (click)="navigateToChapter()"
    [ngClass]="
      'cursor-pointer hover:shadow-lg group'
    "
    class="grid grid-cols-1 sm:grid-cols-3 bg-white"
  >
    <div class="aspect-w-16 aspect-h-9 relative">
      <ng-container
        *ngIf="chapter?.muxPlaybackId || chapter?.thumbnail; else noThumbnail"
      >
        <ng-container *ngIf="chapter?.muxPlaybackId; else thumbnail">
          <img
            [src]="
              'https://image.mux.com/' +
              chapter?.muxPlaybackId +
              '/animated.gif?width=200&fps=5'
            "
            [alt]="chapter.title"
            class="hidden group-hover:block object-cover w-full h-full absolute inset-0"
          />
          <img
            [src]="
              'https://image.mux.com/' + chapter?.muxPlaybackId + '/thumbnail.png?width=400&height=226&fit_mode=smartcrop'
            "
            [alt]="chapter.title"
            class="block group-hover:hidden object-cover w-full h-full absolute inset-0"
          />
        </ng-container>
        <ng-template #thumbnail>
          <img
            class="absolute inset-0 object-center w-full h-full object-cover"
            [src]="chapter.thumbnail"
            [alt]="chapter.title"
          />
        </ng-template>
      </ng-container>
      <ng-template #noThumbnail>
        <ng-container *ngIf="chapter.thumbnailPath">
          <img
            class="absolute inset-0 object-center w-full h-full object-cover"
            [src]="chapter.thumbnailPath | getDownloadURL"
            [alt]="chapter.title"
          />
        </ng-container>

        <ng-container *ngIf="!chapter.thumbnailPath">
          <div
            class="absolute inset-0 object-center w-full h-full object-cover bg-richBlack flex flex-col justify-center items-center"
          >
            <svg
              class="w-10 h-10 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M2 0C1.44772 0 1 0.447715 1 1V23C1 23.5523 1.44772 24 2 24H21C21.5523 24 22 23.5523 22 23V7C22 6.73478 21.8946 6.48043 21.7071 6.29289L15.7071 0.292893C15.5196 0.105357 15.2652 0 15 0H2ZM14 2.00006V8.00006H20L14 2.00006ZM7 10.95C6.30964 10.95 5.75 11.5097 5.75 12.2V15.2H8.25V13.45H10.25V18.55H9V21.05H14V18.55H12.75V13.45H14.75V15.2H17.25V12.2C17.25 11.5097 16.6904 10.95 16 10.95H7Z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </ng-container>
      </ng-template>

      <div
        class="h-full w-full absolute inset-0 flex flex-col justify-center items-center bg-richBlack/70"
        *ngIf="chapter.finished && chapter.isAvailable"
      >
        <svg
          class="w-8 h-8"
          viewBox="0 0 31 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28.849 0L11.1475 17.4L2.85705 9.195L0.748047 11.328L10.0885 20.574C10.3693 20.851 10.7478 21.0064 11.1423 21.0064C11.5368 21.0064 11.9153 20.851 12.196 20.574L30.946 2.136L28.849 0Z"
            fill="#EDF2EF"
          />
        </svg>
      </div>

      <div
        class="h-full w-full absolute inset-0 flex flex-col justify-center items-center bg-richBlack/70"
        *ngIf="!chapter.isAvailable"
      >
        <svg
          class="w-8 h-8"
          viewBox="0 0 36 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_3368_13725)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.5 17C4.5 16.1716 5.17157 15.5 6 15.5H30C30.8284 15.5 31.5 16.1716 31.5 17V35C31.5 35.8284 30.8284 36.5 30 36.5H6C5.17157 36.5 4.5 35.8284 4.5 35V17ZM7.5 18.5V33.5H28.5V18.5H7.5Z"
              fill="#EDF2EF"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18 3.5C16.0109 3.5 14.1032 4.29017 12.6967 5.6967C11.2902 7.10323 10.5 9.01088 10.5 11V17H7.5V11C7.5 8.21522 8.60625 5.54451 10.5754 3.57538C12.5445 1.60625 15.2152 0.5 18 0.5C20.7848 0.5 23.4554 1.60623 25.4245 3.57534C25.4245 3.57536 25.4245 3.57533 25.4245 3.57534M25.4245 3.57534C27.3938 5.5445 28.5 8.21527 28.5 11V17H25.5V11C25.5 9.01085 24.7099 7.10323 23.3033 5.69674C21.8968 4.29022 19.9891 3.5 18 3.5"
              fill="#EDF2EF"
            />
            <path
              d="M18 29C19.6569 29 21 27.6569 21 26C21 24.3431 19.6569 23 18 23C16.3431 23 15 24.3431 15 26C15 27.6569 16.3431 29 18 29Z"
              fill="#EDF2EF"
            />
          </g>
          <defs>
            <clipPath id="clip0_3368_13725">
              <rect
                width="36"
                height="36"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
    <div
      class="p-4 flex flex-row sm:col-span-2 space-x-4 justify-between items-start relative"
    >
      <div class="flex flex-col justify-start items-start space-y-2.5">
        <sami-membership-badge [isFree]="chapter.isFree || false" *ngIf="!track?.hasPurchased && vm.currentSite === 'sami-x-app' && track?.trackAccessRules?.subscriptionAccess?.plus"></sami-membership-badge>
        <span
          class="text-xl font-semibold font-sans text-richBlack"
          *ngIf="chapter.title"
        >
          {{ chapter.title }}
        </span>
        <div
          class="font-body text-richBlack line-clamp-2"
          *ngIf="chapter.description"
          [innerHtml]="chapter.description"
        ></div>
      </div>
      <div class="h-full flex flex-col justify-center items-center">
        <svg class="w-4 sm:w-5 text-cadetGrey-200 group-hover:text-richBlack" viewBox="0 0 21 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.749999 2.41675L2.74261 0.166748L19.682 17.1061L20.7426 18.1667L19.682 19.2274L2.74261 36.1667L0.75 33.9167L16.5 18.1667L0.749999 2.41675Z" fill="currentColor"/>
        </svg>
      </div>
    </div>
  </div>
</ng-container>