import { SamiStyleModel } from './sami-style.model';

export interface SamiEvent {
  [key: string]: any;
  id?: string;
  title?: string;
  price?: number;
  priceForMembers?: number; // If 0 --> Free, if Price --> Discounted Price!
  releaseDate?: Date | any;
  endDate?: Date | any;
  spaceId?: string;
  accessMode?: 'open' | 'free' | 'buyNow' | 'closed' | 'onlyOpenForMembers';
  capacity?: number;
  style?: SamiStyleModel;
  hmsRoomId?: string;
  types?: string[];
  lessonType?: string;
  setting?: string;
  education?: string;
  visibility?: string;
  duration?: number;
  difficulty?: string;
  level?: string;
  systemLevels?: number[];
  equipment?: string[];
  targetGroups?: string[];
  outcomes?: string[];
  requirements?: string[];
  isFeatured?: boolean;
  availableInWeeks?: number[];
  center?: {
    name?: string;
    id?: string;
    slug?: string;
  };
  instructor?: {
    displayName?: string; // only for patching reasons!
    name?: string;
    id?: string;
    center?: {
      id?: string;
      name?: string;
    };
  };
  language?: string;
  additionalInformation?: string;
  videoId?: string;
  trailerId?: string;
  thumbnail?: string;
  thumbnails?: any;
  previewImage?: string;
  attendees?: number;
  attendeeList?: {
    id?: string;
    ref?: any;
  }[];
  isVimeoSynced?: boolean;
  stripeProductId?: string;
  associatedOnlineCourseList?: {
    id?: string;
    ref?: any;
  }[];
  associatedOnlineCourseIds?: string[];
  exercises?: any;
  videoClipElement?: {
    contentType?: string;
    id: string;
    path?: string;
    title?: string;
  };
}
