import { Component, Inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SamiCenter, SamiUser, environment } from 'interfaces';
import { Observable, tap } from 'rxjs';

import algoliasearch from 'algoliasearch';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-student-center-modal',
  templateUrl: './student-center-modal.component.html'
})
export class StudentCenterModalComponent {
  centers$: Observable<SamiCenter>;
  saving = false;
  customer$: Observable<(SamiUser & { uid: string; }) | undefined>;
  customerId: string | undefined;

  client = algoliasearch('PVL356RKY9', '1dbbc66873df6bfc51ec0b3536e5ad94');
  centerIndex = this.client.initIndex(
    environment.production ? 'prod_CENTERS' : 'dev_CENTERS'
  );
  filteredCenters: any;
  roles = [
    {
      key: 'Member',
      value: 'Member',
    },
    {
      key: 'Instructor',
      value: 'Instructor',
    },
    {
      key: 'Owner',
      value: 'Owner',
    },
  ];

  form = new FormGroup({
    role: new FormControl('', [Validators.required]),
  });

  centerForm = new FormGroup({
    center: new FormControl(null),
  });

  constructor(
    public dialogRef: MatDialogRef<StudentCenterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private afs: AngularFirestore
  ) {
    this.centers$ = this.afs
      .collection<SamiCenter[]>('centers')
      .valueChanges({ idField: 'id' });

    this.customerId = data.customerId;
    this.customer$ =
      this.afs.doc<(SamiUser & { uid: string; }) | undefined>(`users/${data.customerId}`).valueChanges({ idField: 'uid' })
        .pipe(
          tap((customer) => {
            if (customer && customer.center) {
              this.form.patchValue({
                role: customer.center.role,
              });
            }
          }),
        );
  }

  onSave(customer: SamiUser) {
    if (this.form.valid && customer.center) {
      const f = this.form.getRawValue();
      if (f.role) {
        this.onChangeUserRole(customer.center, f.role);
      }
    }
  }

  async updateCustomer(data: any) {
    try {
      await this.afs
        .collection('users')
        .doc(this.customerId)
        .update({
          ...data,
        });

      this.dialogRef.close();
    } catch (error) {
      console.error(error);
    }
  }

  async onChangeUserCenter(center: SamiCenter) {
    await this.updateCustomer({
      center: {
        id: center.id,
        name: center.name,
        slug: center.slug,
        role: 'Member',
      },
    });
  }

  async onChangeUserRole(center: SamiCenter, role: string) {
    await this.updateCustomer({
      center: {
        id: center.id,
        name: center.name,
        slug: center.slug,
        role: role,
      },
    });
  }

  async onRemoveUserFromCenter() {
    await this.updateCustomer({ center: null });
  }

  assignRole(center: any) {
    return (
      (center && center.role && this.roles.find((r) => r === center.role)) ||
      null
    );
  }

  assignCenter(centers: SamiCenter[], center: SamiCenter) {
    return (
      (centers &&
        center &&
        center.id &&
        centers.find((c: SamiCenter) => c.id === center.id)) ||
      null
    );
  }

  async searchCenter(query: string) {
    const results = await this.centerIndex.search(query, {
      hitsPerPage: 8,
    });
    if (results.hits.length) {
      this.filteredCenters = results.hits;
    } else {
      this.filteredCenters = [];
    }
  }

  async onSelectCenter(sCenter: SamiCenter, type: 'autocomplete' | 'marker') {
    await this.updateCustomer({
      center: {
        id: sCenter['objectID'],
        name: sCenter.name,
        slug: sCenter.slug,
        role: 'Member',
      },
    });
  }
}
