import { Component, OnInit, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-dialog-container',
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.scss'],
})
export class DialogContainerComponent implements OnInit {
  data: any;

  constructor(
    dialogRef: MatDialogRef<DialogContainerComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this.data = {
      ...dialogData,
      containerRef: dialogRef,
      close: () => dialogRef.close(),
      refType: 'dialog',
    };
  }

  ngOnInit() {}
}
