import { DocumentReference } from "firebase/firestore";

export interface Workout {
  [key: string]: any;
  title?: string;
  focus?: string;
  status?: 'active' | 'hidden';
  subtitle?: string;
  description?: string;
  system?: string;
  level?: number;
  tags?: string[];
  exercise_titles?: string[];
  difficulty?: number;
  category?: 'SAMI-X Defense' | 'SAMI-X Fighting Arts' | 'SAMI-X Pro';
  warmUp?: {
    exercises: WorkoutExercise[];
  };
  rounds?: WorkoutRound[];
  thumbnail?: string;
  duration?: number;
  totalDuration?: {
    work: number;
    rest: number;
    total: number;
  }
  instructorId?: string;
  requiredPlans?: 'free' | 'plus' | 'basic' | 'premium' | 'instructor'[];
  breakDuration?: number;
  instructor?: {
    id: string;
    displayName: string;
    photoURL: string;
    ref: DocumentReference;
  }
  scope: 'global' | 'instructor' | 'center' | 'user';
  isFeatured?: boolean;
  trackConnections: {
    chapterId: string;
    chapterTitle: string;
    trackId: string;
    trackTitle: string;
  }[];
  trackChapterIds: string[];
}

export interface WorkoutExercise {
  [key: string]: any;
  id?: string;
  title?: string;
  objectID?: string;
  tags?: string[];
  countdownDuration?: number;
  difficulty?: number;
  description?: string;
  level?: number;
  system?: string;
  numberOfRepetitions?: number;
  restDuration?: number;
  type?: 'countdown' | 'repetition' | 'rest';
  mux_playback_id?: string;
  mux_id?: string;
  thumbnailPath?: string;
  systemsLibraryRef?: any;
  systemsLibraryClip?: {
    title: string;
    id: string;
  };
  calories?: number;
}

export interface WorkoutRound {
  [key: string]: any;
  exercises: WorkoutExercise[];
}

export interface UserWorkout {
  createdAt?: Date | any;
  [key: string]: any;
  workoutTitle?: string;
  workoutRef?: string;
  type?: 'trainingCreator' | 'guidedTraining' | 'liveTraining';
  status?: 'finished' | 'canceled' | 'inProgress';
  duration?: number;
  music?: string;
  caloriesBurned?: number;
  warmup: {
    duration?: number;
    exercises?: {
      duration?: number;
      exercise?: any;
    }[];
  };
  rounds: {
    duration?: number;
    exercises?: any[];
  }[];
  images?: {
    beforeProgressImage?: string;
    afterProgressImage?: string;
    shareImage?: string;
  };
  notes?: string;
  shareImage?: string;
  trainingLocation?: 'indoor' | 'outdoor' | 'gym';
  personalExperience?: number;
}
