<div class="flex w-full max-h-[80vh] overflow-y-auto flex-col justify-start bg-white p-6">
  <div class="flex flex-row justify-between">
    <span class="text-2xl font-semibold text-richBlack"> Update Center Connection </span>
    <button (click)="dialogRef.close()" class="w-4 h-4">
      <svg
        class="w-4 h-4"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3849_30117)">
          <path
            d="M1 1L23 23"
            stroke="black"
            stroke-width="2"
            stroke-linejoin="round"
          />
          <path
            d="M23 1L1 23"
            stroke="black"
            stroke-width="2"
            stroke-linejoin="round"
          />
        </g>
      </svg>
    </button>
  </div>

  <div class="py-4" *ngIf="customer$ | async as customer">

    <ng-container *ngIf="customer.center; else selectCenter">
      <div
        class="grid grid-cols-2 gap-10 py-4"
        [formGroup]="form"
      >
        <div class="col-span-2">
          <div class="grid sm:grid-cols-2 gap-2 sm:gap-10">
            <sami-select
              [parentForm]="form"
              [displayLabel]="true"
              label="Center Role"
              fieldName="role"
              formControlName="role"
              [options]="roles"
            ></sami-select>
            <span class="text-xs font-body text-cadetGrey-600">
              Define which role this customer will have at the center. This will determine what they can see and do.
            </span>
          </div>
        </div>
      </div>

      <div class="flex flex-row justify-between items-center space-x-4 col-span-2">
        <sami-button
          (onClick)="onRemoveUserFromCenter()"
          text="Remove Center Connection"
          mode="secondary"
          size="medium"
        ></sami-button>

        <sami-button
          (onClick)="onSave(customer)"
          [loading]="saving"
          [disabled]="saving || !form.valid"
          text="Save"
          mode="primary"
          size="medium"
        ></sami-button>
      </div>
    </ng-container>
    <ng-template #selectCenter>
      <div [formGroup]="centerForm">
        <p class="font-body mb-4">
          This customer is currently not connected to a SAMI Center. Please select a SAMI Center to connect this customer to.
        </p>

        <label for="selectedCenter" class="block text-base font-semibold text-richBlack">Select a SAMI Center</label>
        <input
          #centerSearch
          formControlName="center"
          (keyup)="searchCenter(centerSearch.value)"
          [matAutocomplete]="centerAutocomplete"
          id="selectedCenter"
          placeholder="Search for a SAMI Center..."
          name="selectedCenter"
          class="block w-full font-body border p-3 mt-1 rounded-none border-cadetGrey-300 shadow-none focus:border-cadetGrey-600 focus:ring-cadetGrey-600 sm:text-sm"
        />
        <mat-autocomplete #centerAutocomplete="matAutocomplete">
          <mat-option
            (click)="onSelectCenter(center, 'autocomplete')"
            *ngFor="let center of filteredCenters"
            [value]="center"
          >
            <span class="font-body">
              {{ center.name }}
            </span>
          </mat-option>
        </mat-autocomplete>
      </div>
    </ng-template>
  </div>
</div>
