<div
  class="p-4 flex font-sans flex-row overflow-x-auto justify-start space-x-6"
  [ngClass]="color === 'white' ? 'bg-culturedWhite/10' : 'bg-cadetGrey-200'"
>
  <div
    *ngIf="instructor"
    class="sm:flex-1 pr-6 flex flex-row justify-start items-center space-x-2"
  >
    <img
      class="inline-block h-6 w-6 rounded-full"
      [src]="instructor.photoURL | getDownloadURL"
      [alt]="instructor.displayName"
    />
    <span
      [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
      class="uppercase font-semibold whitespace-nowrap"
    >
      {{ instructor.displayName }}
    </span>
  </div>

  <div
    *ngIf="trackTitle"
    class="flex flex-row justify-center items-center space-x-2"
  >
    <svg
      class="w-5 h-5"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 2.83887C2 2.47068 2.29848 2.1722 2.66667 2.1722H12.6667C12.8435 2.1722 13.013 2.24244 13.1381 2.36746L15.1381 4.36746C15.3984 4.62781 15.3984 5.04992 15.1381 5.31027L13.1381 7.31027C13.013 7.4353 12.8435 7.50553 12.6667 7.50553H2.66667C2.29848 7.50553 2 7.20706 2 6.83887V2.83887ZM3.33333 3.50553V6.1722H7.33333H8.66667H12.3905L13.7239 4.83887L12.3905 3.50553H8.66667H7.33333H3.33333ZM2.86193 9.03413C2.98695 8.90911 3.15652 8.83887 3.33333 8.83887H13.3333C13.7015 8.83887 14 9.13734 14 9.50553V13.5055C14 13.8737 13.7015 14.1722 13.3333 14.1722H3.33333C3.15652 14.1722 2.98695 14.102 2.86193 13.9769L0.861928 11.9769C0.601579 11.7166 0.601579 11.2945 0.861928 11.0341L2.86193 9.03413ZM3.60947 10.1722L2.27614 11.5055L3.60947 12.8389H7.33333H8.66667H12.6667V10.1722H8.66667H7.33333H3.60947Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.66667 0.838867V2.83887H12.6667L14.6667 4.83887L12.6667 6.83887H8.66667V9.50553H13.3333V13.5055H8.66667V16.8389H7.33333V13.5055H3.33333L1.33333 11.5055L3.33333 9.50553H7.33333V6.83887H2.66667V2.83887H7.33333V0.838867H8.66667ZM7.33333 6.1722H8.66667H12.3905L13.7239 4.83887L12.3905 3.50553H8.66667H7.33333H3.33333V6.1722H7.33333ZM8.66667 12.8389H7.33333H3.60947L2.27614 11.5055L3.60947 10.1722H7.33333H8.66667H12.6667V12.8389H8.66667Z"
        fill="currentColor"
      />
    </svg>
    <span
      [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
      class="uppercase font-semibold whitespace-nowrap"
    >
      {{ trackTitle }}
    </span>
  </div>
  <sami-combat-system-tag [combatSystem]="systemIdentifier" *ngIf="systemIdentifier"></sami-combat-system-tag>
  <div
    *ngIf="date && !daysAvailable"
    [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
    class="flex flex-row justify-center items-center space-x-2"
  >
    <svg class="w-5 h-5 shrink-0" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 3.75C0.5 3.33579 0.835786 3 1.25 3H17.75C18.1642 3 18.5 3.33579 18.5 3.75V17.25C18.5 17.6642 18.1642 18 17.75 18H1.25C0.835786 18 0.5 17.6642 0.5 17.25V3.75ZM2 4.5V16.5H17V4.5H2Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5 0V3.75H3.5V0H5Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 0V3.75H14V0H15.5Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.75 8.25H1.25V6.75H17.75V8.25Z" fill="currentColor"/>
    </svg>

    <span
      [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
      class="uppercase font-semibold whitespace-nowrap"
    >
      {{ date | date: 'MMM d, y'}}
    </span>
  </div>

  <div
    *ngIf="date && !daysAvailable"
    [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
    class="flex flex-row justify-center items-center space-x-2"
  >
    <svg class="w-5 h-5 shrink-0" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 9C0.5 4.02944 4.52944 0 9.5 0C14.4705 0 18.5 4.02944 18.5 9C18.5 13.9705 14.4705 18 9.5 18C4.52944 18 0.5 13.9705 0.5 9ZM9.5 1.5C5.35787 1.5 2 4.85787 2 9C2 13.1421 5.35787 16.5 9.5 16.5C13.6421 16.5 17 13.1421 17 9C17 4.85787 13.6421 1.5 9.5 1.5ZM10.25 8.25V4.41678H8.75V9C8.75 9.41421 9.08579 9.75 9.5 9.75H13.1667V8.25H10.25Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 9C0.5 4.02944 4.52944 0 9.5 0C14.4705 0 18.5 4.02944 18.5 9C18.5 13.9705 14.4705 18 9.5 18C4.52944 18 0.5 13.9705 0.5 9ZM9.5 1.5C5.35787 1.5 2 4.85787 2 9C2 13.1421 5.35787 16.5 9.5 16.5C13.6421 16.5 17 13.1421 17 9C17 4.85787 13.6421 1.5 9.5 1.5Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.25 8.25V4.41678H8.75V9C8.75 9.41421 9.08579 9.75 9.5 9.75H13.1667V8.25H10.25Z" fill="currentColor"/>
    </svg>

    <span
      [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
      class="uppercase font-semibold whitespace-nowrap"
    >
      {{ date | date: 'HH:mm  z'}}
    </span>
  </div>

  <div
    *ngIf="daysAvailable"
    [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
    class="flex flex-row justify-center items-center space-x-2"
  >
    <svg
      class="w-5 h-5 shrink-0"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.75 0.75C2.75 0.335786 3.08579 0 3.5 0H15.5C15.9142 0 16.25 0.335786 16.25 0.75V6C16.25 6.28408 16.0895 6.54378 15.8354 6.67082L11.1771 9L15.8354 11.3292C16.0895 11.4562 16.25 11.7159 16.25 12V17.25C16.25 17.6642 15.9142 18 15.5 18H3.5C3.08579 18 2.75 17.6642 2.75 17.25V12C2.75 11.7159 2.9105 11.4562 3.16459 11.3292L7.82295 9L3.16459 6.67082C2.9105 6.54378 2.75 6.28408 2.75 6V0.75ZM9.5 9.83853L4.25 12.4635V16.5H14.75V12.4635L9.5 9.83853ZM9.5 8.16147L4.25 5.53647V1.5H14.75V5.53647L9.5 8.16147Z"
        fill="currentColor"
      />
      <path d="M3.5 6L9.5 9L15.5 6L9.5 3.75L3.5 6Z" fill="currentColor" />
    </svg>

    <span
      [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
      class="uppercase font-semibold whitespace-nowrap"
    >
      {{ daysAvailable }} more days available
    </span>
  </div>


  <span
    *ngIf="examRequired"
    class="uppercase text-xs py-1 px-2 bg-richBlack text-culturedWhite cursor-default"
  >
    exam
  </span>

  <span
    *ngIf="forInstructors"
    class="uppercase whitespace-nowrap text-xs py-1 px-2 bg-richBlack text-culturedWhite cursor-default"
  >
    instructor content
  </span>

  <div
    *ngIf="level"
    [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
    class="flex flex-row justify-center items-center space-x-2"
  >
    <svg
      class="w-5 h-5"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2717_17954)">
        <path d="M0.75 13.5H3.75V16.5H0.75V13.5Z" fill="currentColor" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 13.5C0 13.0858 0.335786 12.75 0.75 12.75H3.75C4.16421 12.75 4.5 13.0858 4.5 13.5V16.5C4.5 16.9142 4.16421 17.25 3.75 17.25H0.75C0.335786 17.25 0 16.9142 0 16.5V13.5ZM1.5 14.25V15.75H3V14.25H1.5Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.5 1.5C13.5 1.08579 13.8358 0.75 14.25 0.75H17.25C17.6642 0.75 18 1.08579 18 1.5V16.5C18 16.9142 17.6642 17.25 17.25 17.25H14.25C13.8358 17.25 13.5 16.9142 13.5 16.5V1.5ZM15 2.25V15.75H16.5V2.25H15ZM6.75 7.5C6.75 7.08579 7.08579 6.75 7.5 6.75H10.5C10.9142 6.75 11.25 7.08579 11.25 7.5V16.5C11.25 16.9142 10.9142 17.25 10.5 17.25H7.5C7.08579 17.25 6.75 16.9142 6.75 16.5V7.5ZM8.25 8.25V15.75H9.75V8.25H8.25Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2717_17954">
          <rect width="18" height="18" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
    <span
      [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
      class="uppercase font-semibold whitespace-nowrap"
    >
      Level {{ level }}
    </span>
  </div>

  <div
    *ngIf="difficulty"
    [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
    class="flex flex-row justify-center items-center space-x-2"
  >
    <svg
      class="w-5 h-5"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2717_17954)">
        <path d="M0.75 13.5H3.75V16.5H0.75V13.5Z" fill="currentColor" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 13.5C0 13.0858 0.335786 12.75 0.75 12.75H3.75C4.16421 12.75 4.5 13.0858 4.5 13.5V16.5C4.5 16.9142 4.16421 17.25 3.75 17.25H0.75C0.335786 17.25 0 16.9142 0 16.5V13.5ZM1.5 14.25V15.75H3V14.25H1.5Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.5 1.5C13.5 1.08579 13.8358 0.75 14.25 0.75H17.25C17.6642 0.75 18 1.08579 18 1.5V16.5C18 16.9142 17.6642 17.25 17.25 17.25H14.25C13.8358 17.25 13.5 16.9142 13.5 16.5V1.5ZM15 2.25V15.75H16.5V2.25H15ZM6.75 7.5C6.75 7.08579 7.08579 6.75 7.5 6.75H10.5C10.9142 6.75 11.25 7.08579 11.25 7.5V16.5C11.25 16.9142 10.9142 17.25 10.5 17.25H7.5C7.08579 17.25 6.75 16.9142 6.75 16.5V7.5ZM8.25 8.25V15.75H9.75V8.25H8.25Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2717_17954">
          <rect width="18" height="18" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
    <span
      [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
      class="uppercase font-semibold whitespace-nowrap"
    >
      {{ difficulty }}
    </span>
  </div>

  <div
    *ngIf="typeOfExercise && typeOfExercise !== 'generalInformation'"
    class="flex flex-row justify-center items-center space-x-2"
  >
    <svg
      class="w-5 h-5"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6678 3.02784C13.5736 2.74229 14.543 2.72713 15.4572 2.98423C16.3715 3.24133 17.1908 3.75951 17.815 4.4753L16.6845 5.46113C16.2521 4.96528 15.6845 4.60632 15.0511 4.42822C14.4178 4.25012 13.7463 4.26062 13.1188 4.45843C12.4914 4.65624 11.9353 5.03278 11.5186 5.5419C11.1019 6.05103 10.8428 6.67063 10.773 7.32481L9.28148 7.16562C9.38227 6.22128 9.75632 5.32684 10.3578 4.59189C10.9593 3.85694 11.7621 3.3134 12.6678 3.02784Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.64167 0.758822C3.25284 0.394371 3.98002 0.276638 4.67503 0.429696C5.37111 0.582989 5.98236 0.99633 6.38387 1.58524C6.78538 2.17415 6.94682 2.89416 6.8352 3.59813C6.72371 4.30123 6.34825 4.93529 5.78541 5.37107L5.60053 5.51588L8.06184 9.47822L6.78765 10.2697L3.9714 5.73596C3.76944 5.41083 3.8447 4.98578 4.14603 4.74977L4.86614 4.18574C5.12756 3.98373 5.30197 3.68952 5.3537 3.36322C5.40544 3.03692 5.33061 2.70318 5.14451 2.43021C4.9584 2.15724 4.67507 1.96565 4.35243 1.89459C4.02978 1.82354 3.69218 1.87839 3.40862 2.04793L3.40322 2.05116C2.57477 2.53709 1.95796 3.31523 1.674 4.23274C1.39096 5.14726 1.45898 6.13433 1.86456 7.00124L5.49061 14.2067C6.35098 14.165 8.05721 14.0159 9.96638 13.4943C12.3436 12.8448 14.9432 11.6433 16.6605 9.44787L17.842 10.3721C15.8416 12.9294 12.8862 14.2516 10.3617 14.9413C7.82703 15.6337 5.63346 15.7141 5.04566 15.7216C4.75882 15.7253 4.495 15.5651 4.36604 15.3089L0.520044 7.66636C0.517099 7.66051 0.514231 7.65462 0.511441 7.64869C-0.057149 6.44103 -0.153593 5.0644 0.241055 3.78926C0.635406 2.51508 1.49162 1.43427 2.64167 0.758822Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.26505 4.97767C5.46527 5.34027 5.33364 5.79654 4.97104 5.99677L3.70654 6.69502C3.34394 6.89524 2.88767 6.76361 2.68744 6.40101C2.48721 6.0384 2.61885 5.58214 2.98145 5.38191L4.24595 4.68366C4.60855 4.48343 5.06482 4.61506 5.26505 4.97767ZM11.3173 6.80126C11.8226 7.02352 12.2928 7.38184 12.5601 7.89287C12.6748 8.11215 12.6739 8.37389 12.5578 8.59242C12.4417 8.81094 12.2252 8.95812 11.9793 8.98577L6.85005 9.56252C6.56103 9.59501 6.27928 9.45749 6.12711 9.20964C5.97493 8.9618 5.97976 8.64831 6.1395 8.40527C7.01112 7.07916 8.372 6.52095 9.59718 6.48458C10.2077 6.46645 10.8058 6.57625 11.3173 6.80126ZM16.5354 17.1015L14.8637 11.8185L16.2938 11.3659L17.9655 16.6489L16.5354 17.1015Z"
        fill="currentColor"
      />
    </svg>
    <span
      [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
      class="uppercase font-semibold whitespace-nowrap"
    >
      {{ typeOfExercise }}
    </span>
  </div>

  <div
    *ngIf="category"
    class="flex flex-row justify-center items-center space-x-2"
  >
    <svg
      class="w-5 h-5"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 4.5H1.5V14.25C1.5 14.6478 1.65803 15.0293 1.93934 15.3106C2.22064 15.592 2.60218 15.75 3 15.75H14.25V14.25H3V4.5Z"
        fill="currentColor"
      />
      <path
        d="M15 4.5H11.25L10.0125 2.85C9.87248 2.66401 9.69128 2.51302 9.48308 2.40891C9.2748 2.3048 9.0453 2.2504 8.8125 2.25H6C5.60218 2.25 5.22065 2.40803 4.93934 2.68934C4.65803 2.97064 4.5 3.35218 4.5 3.75V11.25C4.5 11.6478 4.65803 12.0293 4.93934 12.3106C5.22065 12.592 5.60218 12.75 6 12.75H15C15.3978 12.75 15.7793 12.592 16.0606 12.3106C16.342 12.0293 16.5 11.6478 16.5 11.25V6C16.5 5.60218 16.342 5.22065 16.0606 4.93934C15.7793 4.65803 15.3978 4.5 15 4.5ZM6 11.25V3.75H8.8125L10.05 5.4C10.19 5.58599 10.3712 5.73698 10.5794 5.84109C10.7877 5.9452 11.0172 5.99959 11.25 6H15V11.25H6Z"
        fill="currentColor"
      />
    </svg>
    <span
      [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
      class="uppercase font-semibold whitespace-nowrap"
    >
      {{ category }}
    </span>
  </div>

  <div
    *ngIf="slCategory"
    class="flex flex-row justify-center items-center space-x-2"
  >
    <svg
      class="w-5 h-5"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 4.5H1.5V14.25C1.5 14.6478 1.65803 15.0293 1.93934 15.3106C2.22064 15.592 2.60218 15.75 3 15.75H14.25V14.25H3V4.5Z"
        fill="currentColor"
      />
      <path
        d="M15 4.5H11.25L10.0125 2.85C9.87248 2.66401 9.69128 2.51302 9.48308 2.40891C9.2748 2.3048 9.0453 2.2504 8.8125 2.25H6C5.60218 2.25 5.22065 2.40803 4.93934 2.68934C4.65803 2.97064 4.5 3.35218 4.5 3.75V11.25C4.5 11.6478 4.65803 12.0293 4.93934 12.3106C5.22065 12.592 5.60218 12.75 6 12.75H15C15.3978 12.75 15.7793 12.592 16.0606 12.3106C16.342 12.0293 16.5 11.6478 16.5 11.25V6C16.5 5.60218 16.342 5.22065 16.0606 4.93934C15.7793 4.65803 15.3978 4.5 15 4.5ZM6 11.25V3.75H8.8125L10.05 5.4C10.19 5.58599 10.3712 5.73698 10.5794 5.84109C10.7877 5.9452 11.0172 5.99959 11.25 6H15V11.25H6Z"
        fill="currentColor"
      />
    </svg>
    <span
      [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
      class="uppercase font-semibold whitespace-nowrap"
    >
      <sami-category-description [system]="slCategory.system" [category]="slCategory.category" [subcategory]="slCategory.subcategory"></sami-category-description>
    </span>
  </div>

  <div
    *ngIf="numberOfParticipants"
    [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
    class="flex flex-row justify-center items-center space-x-2"
  >
    <ng-container *ngIf="numberOfParticipants !== 'theory'">
      <svg
        class="w-5 h-5"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.2 2.7C7.2 2.16599 7.04164 1.64397 6.74497 1.19996C6.44829 0.755945 6.02661 0.409886 5.53325 0.205523C5.03988 0.00116869 4.497 -0.0523002 3.97326 0.0518838C3.44951 0.156059 2.96842 0.413207 2.59081 0.790811C2.21321 1.16841 1.95606 1.64951 1.85189 2.17326C1.7477 2.697 1.80117 3.23988 2.00552 3.73324C2.20989 4.22661 2.55595 4.64829 2.99996 4.94497C3.44398 5.24164 3.96599 5.4 4.5 5.4C5.21608 5.4 5.90284 5.11554 6.40919 4.60919C6.91554 4.10284 7.2 3.41608 7.2 2.7ZM4.5 3.6C4.322 3.6 4.14799 3.54721 3.99999 3.44832C3.85198 3.34943 3.73663 3.20887 3.66851 3.04441C3.60039 2.87996 3.58257 2.699 3.6173 2.52442C3.65202 2.34984 3.73774 2.18947 3.8636 2.0636C3.98947 1.93773 4.14984 1.85202 4.32442 1.8173C4.499 1.78257 4.67996 1.80039 4.84441 1.86851C5.00887 1.93663 5.14943 2.05198 5.24832 2.19999C5.34721 2.34799 5.4 2.522 5.4 2.7C5.4 2.9387 5.30518 3.16761 5.1364 3.3364C4.96761 3.50518 4.7387 3.6 4.5 3.6Z"
          fill="currentColor"
        />
        <path
          d="M4.5 5.4C3.30696 5.40143 2.1632 5.87599 1.3196 6.71958C0.475992 7.56324 0.001431 8.70696 0 9.9V12.6C0 12.8387 0.0948241 13.0676 0.263601 13.2364C0.432387 13.4051 0.661302 13.5 0.9 13.5H1.8V18H3.6V12.6C3.6 12.3613 3.50518 12.1324 3.3364 11.9636C3.16761 11.7949 2.9387 11.7 2.7 11.7H1.8V9.9C1.8 9.18396 2.08446 8.49717 2.59081 7.99083C3.09716 7.48449 3.78391 7.2 4.5 7.2C5.21608 7.2 5.90284 7.48449 6.40919 7.99083C6.91554 8.49717 7.2 9.18396 7.2 9.9V11.7H6.3C6.0613 11.7 5.83239 11.7949 5.6636 11.9636C5.49482 12.1324 5.4 12.3613 5.4 12.6V18H7.2V13.5H8.1C8.33868 13.5 8.56764 13.4051 8.73639 13.2364C8.90514 13.0676 9 12.8387 9 12.6V9.9C8.99856 8.70696 8.52399 7.56324 7.68042 6.71958C6.8368 5.87599 5.69304 5.40143 4.5 5.4Z"
          fill="currentColor"
        />
        <path
          d="M16.2 2.7C16.2 2.16599 16.0417 1.64397 15.745 1.19996C15.4483 0.755945 15.0266 0.409886 14.5332 0.205523C14.0399 0.00116869 13.497 -0.0523002 12.9732 0.0518838C12.4495 0.156059 11.9684 0.413207 11.5908 0.790811C11.2132 1.16841 10.9561 1.64951 10.8518 2.17326C10.7477 2.697 10.8012 3.23988 11.0056 3.73324C11.2099 4.22661 11.5559 4.64829 12 4.94497C12.4439 5.24164 12.966 5.4 13.5 5.4C14.216 5.4 14.9028 5.11554 15.4092 4.60919C15.9155 4.10284 16.2 3.41608 16.2 2.7ZM13.5 3.6C13.322 3.6 13.148 3.54721 13 3.44832C12.852 3.34943 12.7366 3.20887 12.6685 3.04441C12.6004 2.87996 12.5825 2.699 12.6173 2.52442C12.652 2.34984 12.7377 2.18947 12.8636 2.0636C12.9894 1.93773 13.1498 1.85202 13.3244 1.8173C13.499 1.78257 13.68 1.80039 13.8444 1.86851C14.0089 1.93663 14.1494 2.05198 14.2483 2.19999C14.3473 2.34799 14.4 2.522 14.4 2.7C14.4 2.9387 14.3051 3.16761 14.1364 3.3364C13.9676 3.50518 13.7387 3.6 13.5 3.6Z"
          fill="currentColor"
        />
        <path
          d="M13.5 5.4C12.7593 5.39989 12.0301 5.5826 11.377 5.93193C10.7239 6.28124 10.167 6.78636 9.756 7.4025L11.25 8.4024C11.5708 7.91865 12.039 7.55127 12.5851 7.35462C13.1314 7.15788 13.7263 7.14249 14.2819 7.31052C14.8375 7.47864 15.3241 7.82136 15.6696 8.28774C16.0151 8.75421 16.2011 9.3195 16.2 9.9V11.7H15.3C15.0613 11.7 14.8324 11.7949 14.6636 11.9636C14.4949 12.1324 14.4 12.3613 14.4 12.6V18H16.2V13.5H17.1C17.3387 13.5 17.5676 13.4051 17.7364 13.2364C17.9051 13.0676 18 12.8387 18 12.6V9.9C17.9986 8.70696 17.524 7.56324 16.6804 6.71958C15.8368 5.87599 14.693 5.40143 13.5 5.4Z"
          fill="currentColor"
        />
        <path d="M12.6 12.6H10.8V18H12.6V12.6Z" fill="currentColor" />
      </svg>
      <span
        [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
        class="uppercase font-semibold whitespace-nowrap"
      >
        {{ numberOfParticipants === 'single' ? 'Individual' : 'Partner' }}
      </span>
    </ng-container>
    <ng-container *ngIf="numberOfParticipants === 'theory'">
      <svg
        class="w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          fill-rule="evenodd"
          d="M3 1C3 0.447715 3.44772 0 4 0H8H17H21V2H18V5V7.5C18 10.5523 15.5523 13 12.5 13C9.44772 13 7 10.5523 7 7.5V5V2H5V5H3V1ZM9 6H16V7.5C16 9.44772 14.4477 11 12.5 11C10.5523 11 9 9.44772 9 7.5V6ZM2.01943 22.804C3.01118 17.8452 7.31457 14.2001 12.5 14.2001C17.695 14.2001 21.9886 17.9541 22.9797 22.7997C23.0399 23.0939 22.9646 23.3997 22.7747 23.6324C22.5848 23.8651 22.3004 24.0001 22 24.0001H3.00001C2.70042 24.0001 2.41661 23.8658 2.22667 23.6341C2.03673 23.4024 1.96067 23.0977 2.01943 22.804ZM3 11V7H5V11H3ZM12.5 23L8.5 17.1C9.7 16.4 11 16 12.5 16C14 16 15.3 16.4 16.5 17.1C15.6 18.4 12.5 23 12.5 23Z"
          clip-rule="evenodd"
        ></path>
      </svg>
      <span
        [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
        class="uppercase font-semibold whitespace-nowrap"
      >
        Theory
      </span>
    </ng-container>
  </div>

  <div
    *ngIf="duration"
    [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
    class="flex flex-row justify-center items-center space-x-2"
  >
    <svg
      class="w-5 h-5"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 9C0 4.02944 4.02944 0 9 0C13.9705 0 18 4.02944 18 9C18 13.9705 13.9705 18 9 18C4.02944 18 0 13.9705 0 9ZM9 1.5C4.85787 1.5 1.5 4.85787 1.5 9C1.5 13.1421 4.85787 16.5 9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85787 13.1421 1.5 9 1.5ZM9.75 8.25V4.41678H8.25V9C8.25 9.41421 8.58579 9.75 9 9.75H12.6667V8.25H9.75Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 9C0 4.02944 4.02944 0 9 0C13.9705 0 18 4.02944 18 9C18 13.9705 13.9705 18 9 18C4.02944 18 0 13.9705 0 9ZM9 1.5C4.85787 1.5 1.5 4.85787 1.5 9C1.5 13.1421 4.85787 16.5 9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85787 13.1421 1.5 9 1.5Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.75 8.25V4.41678H8.25V9C8.25 9.41421 8.58579 9.75 9 9.75H12.6667V8.25H9.75Z"
        fill="currentColor"
      />
    </svg>
    <span
      [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
      class="uppercase font-semibold whitespace-nowrap"
    >
      <ng-container *ngIf="isNumber(duration)">
        {{ getDurationInMinutes(duration) }} mins
      </ng-container>

      <ng-container *ngIf="!isNumber(duration)">
        {{ duration }}
      </ng-container>
    </span>
  </div>

  <div
    *ngIf="rounds"
    [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
    class="flex flex-row justify-center items-center space-x-2"
  >
    <svg
      class="w-5 h-5"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6006 4.72875L15.4149 6.7815C15.0044 5.45602 14.2343 4.2704 13.1902 3.3565C12.1461 2.44261 10.869 1.83628 9.50081 1.60495C8.13266 1.37364 6.72717 1.52639 5.44065 2.04624C4.15415 2.56609 3.03707 3.43264 2.21369 4.5495C1.3903 5.66636 0.892893 6.98975 0.776793 8.37247C0.660689 9.75517 0.930445 11.143 1.55605 12.3815C2.18166 13.62 3.13859 14.6607 4.32039 15.3878C5.5022 16.1149 6.86255 16.4999 8.25011 16.5V15C7.12319 15.0011 6.01875 14.6848 5.06325 14.0873C4.10774 13.4899 3.33981 12.6354 2.84739 11.6218C2.35496 10.6081 2.15796 9.47633 2.27892 8.3559C2.3999 7.23552 2.83395 6.17183 3.53139 5.28667C4.22883 4.40149 5.16145 3.73064 6.22246 3.3509C7.28347 2.97117 8.42997 2.89792 9.53067 3.13954C10.6314 3.38116 11.6418 3.92788 12.4462 4.71709C13.2507 5.5063 13.8165 6.5061 14.0791 7.602L11.4781 6.102L10.7281 7.401L15.2746 10.026L17.8996 5.48025L16.6006 4.72875Z"
        fill="currentColor"
      />
    </svg>
    <span
      [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
      class="uppercase font-semibold whitespace-nowrap"
    >
      {{ rounds }}
      <ng-container *ngIf="isNumber(rounds)"> Rounds </ng-container>
    </span>
  </div>

  <div
    *ngIf="exercises"
    [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
    class="flex flex-row justify-center items-center space-x-2"
  >
    <svg
      class="w-5 h-5"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.75 4.05C6.75 2.66421 7.91421 1.5 9.3 1.5H12.45C13.8358 1.5 15 2.66421 15 4.05V10.2C15 11.5858 13.8358 12.75 12.45 12.75H6.29999C5.70967 12.75 5.22054 12.4934 4.78808 12.0364C4.34314 11.5663 3.98552 10.9083 3.7078 10.1986C3.43289 9.49602 3.25339 8.78747 3.14255 8.25345C3.08746 7.98798 3.05 7.76896 3.02645 7.61952C3.01469 7.54492 3.00635 7.48737 3.00087 7.44896L3.00077 7.44831C3.02582 6.61375 3.65654 6 4.5 6H5.25V9H6.75V4.05ZM4.5 4.5C2.81079 4.5 1.5 5.81079 1.5 7.5C1.5 7.54689 1.5043 7.59277 1.51254 7.63728L1.51594 7.66109C1.52199 7.70343 1.53145 7.76871 1.54474 7.85304C1.57128 8.02147 1.61293 8.26475 1.67385 8.55827C1.79505 9.14222 1.99523 9.93835 2.31094 10.7452C2.62384 11.5448 3.06778 12.4009 3.69862 13.0675C4.12045 13.5132 4.63581 13.8828 5.25 14.0845V17.25C5.25 17.6642 5.58579 18 6 18H14.25C14.6642 18 15 17.6642 15 17.25V13.3317C15.9119 12.5852 16.5 11.4532 16.5 10.2V4.05C16.5 1.83579 14.6642 0 12.45 0H9.3C7.08578 0 5.25 1.83579 5.25 4.05V4.5H4.5ZM13.5 14.109C13.1644 14.2009 12.8123 14.25 12.45 14.25H6.75V16.5H13.5V14.109ZM1.50984 7.61861C1.50984 7.61861 1.50996 7.61941 1.51018 7.62083L1.51017 7.62077C1.50995 7.61927 1.50984 7.6186 1.50984 7.61861Z"
        fill="currentColor"
      />
    </svg>
    <span
      [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
      class="uppercase font-semibold whitespace-nowrap"
    >
      {{ exercises }}
      <ng-container *ngIf="isNumber(exercises)"> Exercises </ng-container>
    </span>
  </div>

  <div
    *ngIf="equipmentNeeded"
    [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
    class="flex flex-row justify-center items-center space-x-2"
  >
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.25 4.5C1.83579 4.5 1.5 4.83579 1.5 5.25V12.75C1.5 13.1642 1.83579 13.5 2.25 13.5H3.75C4.16421 13.5 4.5 13.1642 4.5 12.75V5.25C4.5 4.83579 4.16421 4.5 3.75 4.5H2.25ZM0 5.25C0 4.00736 1.00736 3 2.25 3H3.75C4.99264 3 6 4.00736 6 5.25V6.75H12V5.25C12 4.00736 13.0074 3 14.25 3H15.75C16.9926 3 18 4.00736 18 5.25V12.75C18 13.9926 16.9926 15 15.75 15H14.25C13.0074 15 12 13.9926 12 12.75V11.25H6V12.75C6 13.9926 4.99264 15 3.75 15H2.25C1.00736 15 0 13.9926 0 12.75V5.25ZM6 9.75H12V8.25H6V9.75ZM14.25 4.5C13.8358 4.5 13.5 4.83579 13.5 5.25V12.75C13.5 13.1642 13.8358 13.5 14.25 13.5H15.75C16.1642 13.5 16.5 13.1642 16.5 12.75V5.25C16.5 4.83579 16.1642 4.5 15.75 4.5H14.25Z"
        fill="currentColor"
      />
    </svg>
    <span
      [ngClass]="color === 'white' ? 'text-white' : 'text-richBlack'"
      class="uppercase font-semibold whitespace-nowrap"
    >
      Equipment Needed
    </span>
  </div>
</div>