import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Firestore } from '@angular/fire/firestore';
import * as UpChunk from '@mux/upchunk';
import { UiService } from '@sami/features';
import { doc } from 'firebase/firestore';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-upload-mux',
  templateUrl: './upload-mux.component.html',
})
export class UploadMuxComponent {
  firestore: Firestore = inject(Firestore);
  @Input() allowMultiple = false;
  filePaths: string[] = [];
  @Output() file = new EventEmitter();

  uploadPercent = 0;
  uploadAssignment: any;

  files: File[] = [];

  constructor(
    private aff: AngularFireFunctions,
    private afs: AngularFirestore,
    private ui: UiService
  ) {}

  async uploadFiles() {
    if (this.files && this.files.length > 0) {
      try {
        const ref = doc(this.firestore, 'muxVideoLibrary');
        this.uploadAssignment = await lastValueFrom(
          this.aff.httpsCallable('createMuxUrl')({
            id: ref.id,
            title: this.files[0].name,
            searchIndex: this.ui.utilsMkSearchIndex([this.files[0].name]),
            editorStatus: 'under_review',
          })
        );

        const upload = UpChunk.createUpload({
          endpoint: this.uploadAssignment.url,
          file: this.files[0],
          dynamicChunkSize: true,
        });

        // subscribe to events
        upload.on('error', (err) => {
          console.error('💥 🙀', err.detail);
        });

        upload.on('progress', (progress) => {
          console.log('Uploaded', progress.detail, 'percent of this file.');
          this.uploadPercent = progress.detail;
        });

        // subscribe to events
        upload.on('success', (err) => {
          this.uploadPercent = 100;
          console.log("Wrap it up, we're done here. 👋");
          this.file.emit(ref.id);
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
  onSelect(event: any) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }
}
