import { Component, OnInit, Inject } from '@angular/core';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-bottomsheet-container',
  templateUrl: './bottomsheet-container.component.html',
  styleUrls: ['./bottomsheet-container.component.scss'],
})
export class BottomsheetContainerComponent implements OnInit {
  data: any;

  constructor(
    bottomSheetRef: MatBottomSheetRef<BottomsheetContainerComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any
  ) {
    this.data = {
      ...bottomSheetData,
      containerRef: bottomSheetRef,
      close: () => bottomSheetRef.dismiss(),
      refType: 'bottomSheet',
    };
  }

  ngOnInit() {}
}
