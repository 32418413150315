import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnChanges, PLATFORM_ID } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { TracksService } from '@sami/features';
import { SamiUser, Track } from 'interfaces';
import { lastValueFrom, take } from 'rxjs';
import { SafePipe } from '../../pipes/safe.pipe';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-start-track-button',
  templateUrl: './start-button.component.html',
  standalone: true,
  imports: [ CommonModule, SafePipe, MatRippleModule ]
})
export class StartTrackButtonComponent implements OnChanges {
  @Input() trackId: string | undefined;
  @Input() user: SamiUser | undefined;
  @Input() type: 'web' | 'mobile' = 'web';

  theme = '';

  track: Track | undefined;
  icon: string | undefined;
  iconPosition: 'start' | 'end' = 'start';

  text = 'Start this track';
  status: 'startTrack' | 'reactivateTrack' | 'pauseTrack' | 'continueTrack' = 'startTrack';


  isPWA = false;
  isNative = false;

  constructor(
    private trackService: TracksService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.isPWA = (window.matchMedia('(display-mode: standalone)').matches) || ((window.navigator as any).standalone) || document.referrer.includes('android-app://');
    }
  }

  ngOnChanges(): void {
    this.getTrack();
  }

  async getTrack() {
    if (this.trackId) {
      const track$ = await this.trackService.getTrack$(this.trackId);
      this.track = await lastValueFrom(track$.pipe(take(1)));
      this.getStatus();
      this.theme = this.getBackgroundClass(this.track);
    }
  }


  getBackgroundClass(track: Track | undefined) {
    switch (track?.category) {
      case 'SAMI-X Defense':
        return 'bg-defensiveGreen-500';

      case 'SAMI-X Fighting Arts':
        return 'bg-fightingRed-700';

      case 'SAMI-X Pro':
        return 'bg-professionalYellow-300';

      default:
        return 'bg-richBlack';
    }
  }

  getStatus() {
    if (this.track?.userProgress?.status === 'active' || (this.track?.userProgress?.status === 'exam_passed' && this.track?.progress && this.track?.progress > 0 && this.track?.progress < 1)) {
      this.text = 'Continue Track';
      this.status = 'continueTrack';
      this.icon = `
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2772_1251)">
          <path
            d="M14.167 15L19.167 10L14.167 5"
            stroke="black"
            stroke-width="2"
            stroke-linejoin="round"
          />
          <path
            d="M0 10H19.1667"
            stroke="black"
            stroke-width="2"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2772_1251">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
      `;
      this.iconPosition = 'end';
    }

    if (this.track?.userProgress?.status === 'paused') {
      this.status = 'reactivateTrack';
      this.text = 'Continue Track';
      this.icon = `
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2772_1251)">
          <path
            d="M14.167 15L19.167 10L14.167 5"
            stroke="black"
            stroke-width="2"
            stroke-linejoin="round"
          />
          <path
            d="M0 10H19.1667"
            stroke="black"
            stroke-width="2"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_2772_1251">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
      `;
      this.iconPosition = 'end';
    }

    if (this.track?.userProgress?.status === 'inactive' || (this.track?.userProgress?.status === 'exam_passed' && !this.track.progress)) {
      this.status = 'startTrack';
      this.text = 'Start this track';
      this.icon = `
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.49967 2.5C2.49967 2.03976 2.87277 1.66667 3.33301 1.66667H15.833C16.054 1.66667 16.266 1.75446 16.4223 1.91074L18.9223 4.41074C19.2477 4.73618 19.2477 5.26382 18.9223 5.58926L16.4223 8.08926C16.266 8.24554 16.054 8.33333 15.833 8.33333H3.33301C2.87277 8.33333 2.49967 7.96024 2.49967 7.5V2.5ZM4.16634 3.33333V6.66667H9.16634H10.833H15.4878L17.1545 5L15.4878 3.33333H10.833H9.16634H4.16634ZM3.57709 10.2441C3.73337 10.0878 3.94533 10 4.16634 10H16.6663C17.1266 10 17.4997 10.3731 17.4997 10.8333V15.8333C17.4997 16.2936 17.1266 16.6667 16.6663 16.6667H4.16634C3.94533 16.6667 3.73337 16.5789 3.57709 16.4226L1.07709 13.9226C0.751649 13.5972 0.751649 13.0695 1.07709 12.7441L3.57709 10.2441ZM4.51152 11.6667L2.84485 13.3333L4.51152 15H9.16634H10.833H15.833V11.6667H10.833H9.16634H4.51152Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.833 0V2.5H15.833L18.333 5L15.833 7.5H10.833V10.8333H16.6663V15.8333H10.833V20H9.16634V15.8333H4.16634L1.66634 13.3333L4.16634 10.8333H9.16634V7.5H3.33301V2.5H9.16634V0H10.833ZM9.16634 6.66667H10.833H15.4878L17.1545 5L15.4878 3.33333H10.833H9.16634H4.16634V6.66667H9.16634ZM10.833 15H9.16634H4.51152L2.84485 13.3333L4.51152 11.6667H9.16634H10.833H15.833V15H10.833Z"
          fill="black"
        />
      </svg>
      `;
      this.iconPosition = 'start';
    }
  }

  setStatus() {
    if (this.trackId && this.user && this.track) {
      this.trackService.setTrackStatus(
        this.status,
        this.trackId,
        this.user,
        this.track
      )
    }
  }
}
