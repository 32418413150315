import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-dialog-certification-wrapper',
  templateUrl: './dialog-certification-wrapper.component.html',
  styleUrls: ['./dialog-certification-wrapper.component.css'],
})
export class DialogCertificationWrapperComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogCertificationWrapperComponent>,
  ) {}

  deleteHistoryEntry(entry: any) {
    this.dialogRef.close({
      deleteHistoryEntry: true,
      entry: entry
    });
  }
}
