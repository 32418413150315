import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService, LocationService, PaymentService } from '@sami/features';
import { Price, SamiUser } from 'interfaces';
import { Observable } from 'rxjs';
import { SamiButtonComponent } from '../../button/button.component';
import { SamiPricingTableModule } from 'pricing-table';

@Component({
  selector: 'app-paywall-dialog',
  templateUrl: './paywall-dialog.component.html',
  standalone: true,
  imports: [CommonModule, SamiButtonComponent, SamiPricingTableModule],
  styles: [],
})
export class PaywallDialogComponent {

  reasons: string[] = [
    'Complete learning tracks including feedback on assignments and official certification',
    'All guided trainings and live trainings',
    'Trainings Creator to build your own trainings',
    'Full access to our 4000+ video library',
  ];

  yearlyPrice2023$: Observable<any>;
  monthlyPrice2023$: Observable<any>;
  loading = false;

  mode: 'subscription' | 'oneTimePurchase' = 'subscription';

  oneTimePurchaseData: {
    docId: string;
    title: string;
    contentType: 'systemsClips' | 'guidedTrainings' | 'liveTrainings' | 'tracks';
    currency: string;
    successUrl?: string;
    cancelUrl?: string;
    returnUrl?: string;
    stripeCustomerId: string;
    image: string;
  } | undefined = undefined;

  selected: 'monthly' | 'yearly' = 'yearly';
  trailPeriod = 7;

  contentTitle = '';

  price$: Observable<any> = new Observable<any>();

  isPromoActive: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(PLATFORM_ID) private platformId: any,
    private dialogRef: MatDialogRef<PaywallDialogComponent>,
    private afs: AngularFirestore,
    public location: LocationService,
    private paymentService: PaymentService,
    public auth: AuthService,
  ) {

    const endDate = new Date('2023-11-26T23:50:00');

    // Get the current date
    const currentDate = new Date();

    // Check if current date is before the end date
    this.isPromoActive = currentDate < endDate;


    this.yearlyPrice2023$ = this.afs
      .doc('stripeSubscriptions/samix_year_2023')
      .valueChanges({ idField: '_id' });
    this.monthlyPrice2023$ = this.afs
      .doc('stripeSubscriptions/samix_month_2023')
      .valueChanges({ idField: '_id' });



    this.oneTimePurchaseData = this.data.oneTimePurchaseData;

    if (this.oneTimePurchaseData?.contentType) {

      this.price$ = this.afs
        .doc(`contentTypePrices/${this.oneTimePurchaseData.contentType}`).valueChanges({ idField: '_id' });

      switch (this.oneTimePurchaseData?.contentType) {
        case 'systemsClips':
          this.contentTitle = 'Video';
          break;

        case 'guidedTrainings':
          this.contentTitle = 'Guided Training';
          break;

        case 'liveTrainings':
          this.contentTitle = 'Live Training';
          break;

        case 'tracks':
          this.contentTitle = 'Track';
          break;

        default:
          break;
      }
    }
  }

  onLeave(): void {
    this.dialogRef.close();
  }

  async startCheckout(price: Price, location: any, user: SamiUser) {
    if (isPlatformBrowser(this.platformId)) {
      if (!user.stripeCustomerId) {
        return;
      }

      this.loading = true;
      const currentURL = window.location.href;

      try {
        const checkout = await this.paymentService
          .generateCheckoutSession({
            priceId: price.id,
            currency: location?.currency.code || 'EUR',
            accountId: null,
            couponCode: null,
            metadata: {
              type: 'subscription',
            },
            taxRate: null,
            systems: 'stick,axe,kravMaga,panantukan,knife',
            trialPeriodDays: this.selected === 'yearly' ? this.trailPeriod : 0,
            customerId: user.stripeCustomerId,
            successUrl: currentURL,
            cancelUrl: currentURL,
            returnUrl: currentURL
          });

        if (checkout) {
          // redirect to checkout.url which is an url
          window.location.href = checkout.url;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    }
  }

  public async openOneTimePurchaseSession(user: SamiUser, currency: string): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      this.loading = true;

      if (!this.oneTimePurchaseData || !user.stripeCustomerId) {
        return;
      }

      const currentURL = window.location.href;

      this.oneTimePurchaseData = {
        ...this.oneTimePurchaseData,
        stripeCustomerId: user.stripeCustomerId,
        currency: currency || 'EUR',
        successUrl: currentURL,
        cancelUrl: currentURL,
        returnUrl: currentURL
      }

      if (this.oneTimePurchaseData) {
        const checkout: any = await this.paymentService.generateCheckoutSessionForPayment(this.oneTimePurchaseData);
        this.loading = false;

        console.log('checkout', checkout);

        if (checkout) {
          location.href = checkout.url;
        }
      }
    }
  }


}
