import { z } from "zod";

/**
 * If you're using Firebase Admin's "DocumentReference" or AngularFire's "DocumentReference",
 * we need a stand-in schema. Typically there's no easy runtime check for it, so you can
 * represent it as `z.any()` or a more specialized brand if you like.
 */
export const DocumentReferenceSchema = z.any();

/* --------------- Zod Enum Schemas --------------- */
// Instead of TypeScript enums, we can define zod "enums" (union of literals).

export const LessonTypeSchema = z.enum([
  "vimeo",
  "mux",
  "pdf",
  "text",
  "library",
]);

export const AudienceScopeSchema = z.enum([
  "students",
  "instructors",
  "both",
]);

export const ContentStatusSchema = z.enum([
  "draft",
  "published",
  "archived",
]);

export const LessonDifficultySchema = z.enum([
  "easy",
  "medium",
  "hard",
]);

export const TrackStatusSchema = z.enum([
  "public",
  "draft",
  "archived",
]);

export const LessonParticipantsSchema = z.enum([
  "single",
  "partner",
  "theory",
]);

export const SyncBehaviorSchema = z.enum(["none", "sync"]);

export const FormContextSchema = z.enum(["track", "global"]);

/* --------------- Lesson Models --------------- */

export const LessonSummarySchema = z.object({
  id: z.string(),
  title: z.string(),
  type: LessonTypeSchema,
  thumbnail: z.string().nullable(),
  section: z.string().nullable(),
  isFreePreviewLesson: z.boolean(),
  order: z.number(),
  chapterId: z.string().nullable(),
  audienceScope: AudienceScopeSchema,
  globalLessonId: z.string().nullable(),
  syncBehavior: SyncBehaviorSchema,
  duration: z.number(),
});

/**
 * A "full" representation of the lesson, 
 * including additional metadata beyond the summary.
 */
export const LessonFullSchema = LessonSummarySchema.extend({
  description: z.string().nullable(),
  participants: LessonParticipantsSchema.nullable(),
  difficulty: LessonDifficultySchema.nullable(),
  availableFrom: z.date().nullable(),
  downloadPath: z.string().nullable(),
  assignmentId: z.string().nullable(),
  tags: z.array(z.string()).nullable(),
  status: ContentStatusSchema,
  prerequisiteLessonIds: z.array(z.string()).nullable(),
  mediaId: z.string().nullable(),
  videoRef: DocumentReferenceSchema.nullable(),
  libraryRef: DocumentReferenceSchema.nullable(),
  thumbnails: z
    .object({
      big: z.string().nullable(),
      small: z.string().nullable(),
    })
    .nullable(),
  isPrerequisite: z.boolean(),
  isRequiredForExam: z.boolean(),
  enableDiscussionForThisLesson: z.boolean(),
  removeMarginsInPlayer: z.boolean(),
  trackId: z.string().nullable(),

  // optional field for function-based triggers
  _lastSyncedByFunction: z.boolean().optional(),
});

/* --------------- Global Chapter Model --------------- */
/**
 * A globally available chapter, stored at /globalChapters/{globalChapterId}.
 * No optional fields: any "not used" fields are set to `null` or an empty array.
 */
export const GlobalChapterSchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string().nullable(),
  lessons: z.array(LessonSummarySchema), // store lesson summaries inline
  createdAt: z.date().nullable(),
  updatedAt: z.date().nullable(),
  _lastSyncedByFunction: z.boolean().optional(),

  // Catch-all for any extra fields
  // By default, Zod disallows unknown keys. 
  // If you want to allow them, we can do:
});

/* --------------- Chapter Models --------------- */
/**
 * A "summary" representation of a chapter with no optional fields, but
 * many can be null if not used.
 */
export const ChapterSummarySchema = z.object({
  id: z.string(),
  title: z.string(),
  thumbnail: z.string().nullable(),
  isFree: z.boolean(),
  lessonIds: z.array(z.string()).nullable(),
  order: z.number(),
  lessons: z.array(LessonSummarySchema).nullable(),
  audienceScope: AudienceScopeSchema,
  globalChapterId: z.string().nullable(),
  syncBehavior: SyncBehaviorSchema,
});

/**
 * A "full" representation of the chapter, 
 * including fields beyond the summary.
 */
export const ChapterFullSchema = ChapterSummarySchema.extend({
  description: z.string().nullable(),
  availableAfterWeeks: z.number().nullable(),
  trackId: z.string().nullable(),
});

/* --------------- Track Model --------------- */
export const TrackV2Schema = z.object({
  id: z.string(),
  title: z.string(),
  subtitle: z.string().nullable(),
  excerpt: z.string().nullable(),
  description: z.string().nullable(),
  system: z.string(),
  level: z.number(),
  category: z.string(),
  goal: z.string(),
  sites: z.array(z.string()).nullable(),
  requirementsToPass: z.string().nullable(),
  status: TrackStatusSchema,
  numberOfLiveTrainingsToPass: z.number(),
  releaseDate: z.date().nullable(),
  drippingEnabled: z.boolean(),
  supervisors: z.array(z.any()), // or a more specific shape if you know it
  instructors: z.array(z.any()), // likewise
  showProgress: z.boolean(),
  programDuration: z.string().nullable(),
  isBeginnerTrack: z.boolean(),

  chapters: z.array(ChapterSummarySchema),
  chapterIds: z.array(z.string()),

  // optional for function triggers
  _lastSyncedByFunction: z.boolean().optional(),
  // optionally allow unknown extra fields
  thumbnail: z.string().nullable(),
}); 


export type LessonSummary = z.infer<typeof LessonSummarySchema>;
export type LessonFull = z.infer<typeof LessonFullSchema>;
export type GlobalChapter = z.infer<typeof GlobalChapterSchema>;
export type ChapterSummary = z.infer<typeof ChapterSummarySchema>;
export type ChapterFull = z.infer<typeof ChapterFullSchema>;
export type TrackV2 = z.infer<typeof TrackV2Schema>;

// and so forth for the "enum" styles if you want them as TS types:
export type LessonType = z.infer<typeof LessonTypeSchema>;
export type AudienceScope = z.infer<typeof AudienceScopeSchema>;
export type ContentStatus = z.infer<typeof ContentStatusSchema>;
export type LessonDifficulty = z.infer<typeof LessonDifficultySchema>;
export type TrackStatus = z.infer<typeof TrackStatusSchema>;
export type LessonParticipants = z.infer<typeof LessonParticipantsSchema>;
export type SyncBehavior = z.infer<typeof SyncBehaviorSchema>;
export type FormContext = z.infer<typeof FormContextSchema>;