<ng-container *ngIf="{ backButton : menuService.showBackButton | async } as vm">
  <ng-container *ngIf="vm.backButton?.show; else showTitle">
    <button (click)="back(vm.backButton)" class="w-24 h-10 px-5 py-2.5 bg-gray-100 justify-center items-center gap-2 inline-flex">
      <div class="w-4 h-4 py-1 justify-center items-center flex">
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.195262 8.02881L4.19526 4.02881L5.13807 4.97162L1.60948 8.50021L5.13807 12.0288L4.19526 12.9716L0.195262 8.97162C-0.0650874 8.71127 -0.0650874 8.28916 0.195262 8.02881Z" fill="#091F2C"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.666667 7.83355H16V9.16688H0.666667V7.83355Z" fill="#091F2C"/>
        </svg>
      </div>
      <div class="text-slate-900 text-sm font-semibold leading-tight">Back</div>
    </button>
  </ng-container>
  <ng-template #showTitle>
    <div class="text-black text-2xl font-semibold leading-7">{{ menuService.navbarTextChanged.getValue() }}</div>
  </ng-template>
</ng-container>