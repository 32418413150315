import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AuthService, UiService } from '@sami/features';
import { lastValueFrom } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-diploma-history',
  templateUrl: './diploma-history.component.html'
})
export class DiplomaHistoryComponent implements OnInit {
  @Output() closeElement: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteHistroyEntry: EventEmitter<any> = new EventEmitter<any>();
  @Input() data: any;

  entry: {
    generalNote: any;
    level?: number;
    exam?: number;
    style: {
      identifier: string;
      title: string;
    };
    history: {
      status: string;
      date: any;
      level?: number;
      exam?: number;
      examText?: string;
      note: string;
      center?: {
        name: string;
      }
      instructor?: {
        name: string;
      }
    }[]
  } | undefined;

  sortedHistory: any[] = [];

  userName = '';
  examType: 'student' | 'instructor' | undefined = undefined;
  system: any;

  loading = false;

  pdf: any | undefined = undefined;

  constructor(
    private aff: AngularFireFunctions,
    private ui: UiService,
    public auth: AuthService
  ) {
  }

  ngOnInit() {
    this.entry = {
      generalNote: this.data.generalNote,
      level: this.data.level,
      exam: this.data.exam,
      style: this.data.system,
      history: this.data.history
    }

    this.userName = this.data.userName;
    this.examType = this.data.examType;
    this.system = this.data.system;

    this.sortAndAddExamText();
  }

  sortAndAddExamText(): void {
    if (this.entry && this.entry.history) {
      this.sortedHistory = this.entry.history
        .map(historyItem => ({
          ...historyItem,
          examText: historyItem.exam !== undefined ? this.getExamText(historyItem.exam) : null
        }))
        .sort((a, b) => b.date.toDate().getTime() - a.date.toDate().getTime());
    }
  }

  getExamText(exam: number): string | null {
    const examText = this.ui.systemExamRanks.find((rank: any) =>
      rank.systemIdentiers.includes(this.entry?.style.identifier)
    )?.ranks[exam];

    return examText || null;
  }


  async download(historyEntry: any) {
    try {

      const examText = this.ui.systemExamRanks.find((rank: any) =>
        rank.systemIdentiers.includes(this.entry?.style.identifier)
      )?.ranks[historyEntry.exam];

      this.loading = true;
      const pdf = await lastValueFrom(
        this.aff.httpsCallable('generateDiploma')({
          name: this.userName,
          systemTitle: this.entry?.style.title || this.entry?.style.title,
          rankText:
            this.examType === 'student' ? 'Level ' + historyEntry.level : examText,
          date:
            historyEntry && historyEntry.date
              ? historyEntry.date
                  .toDate()
                  .toLocaleDateString('de-DE', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })
              : new Date().toLocaleDateString('de-DE', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }),
          level: historyEntry.level,
          examType: this.examType,
          systemIdentifier: this.entry?.style.identifier,
          examiner:
            (historyEntry &&
              historyEntry.instructor &&
              historyEntry.instructor.name) ||
            null,
          center: null,
          category: this.system.category,
        })
      );
      this.loading = false;

      console.log(pdf);

      this.pdf = pdf;  // Store the URL for the download link
    } catch (error) {
      this.loading = false;
      console.error(error);
    }
  }

  async onDelete(historyEntry: any) {
    console.log(historyEntry);
    this.deleteHistroyEntry.emit(historyEntry);
  }
}
