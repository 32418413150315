import { SamiPrice } from './sami-price.model';
import { SamiStyleModel } from './sami-style.model';

export interface SamiExam {
  [key: string]: any;
  id?: string;
  _id?: string;
  title?: string;
  price?: number;
  prices?: SamiPrice[];
  hmsRoomId?: string;
  startDate?: Date | any;
  type?: 'online' | 'inPerson' | 'mixed';
  targetGroup?: 'students' | 'instructors';
  style?: SamiStyleModel;
  associatedOnlineCourseList?: {
    id?: string;
    ref?: any;
  }[];
  associatedOnlineCourseIds?: string[];
  status?: 'private' | 'published' | 'draft';
  duration?: number; // In Minutes
  systemLevels?: number[];
  isFeatured?: boolean;
  center?: {
    name?: string;
    id?: string;
    slug?: string;
  };
  instructors?: {
    name?: string;
    id?: string;
    center?: {
      id?: string;
      name?: string;
    };
  }[];
  instructorIds?: string[];
  language?: string;
  description?: string;
  thumbnail?: string;
  attendees?: number;
  attendeeList?: {
    id?: string;
    ref?: any;
  }[];
  stripeProductId?: string;
  systems?: string[];
  system?: string;
  spaceId?: string;
}

export interface ExamAttendee {
  createdAt: Date;
  displayName: string;
  isGuest?: boolean;
  email?: string;
  examId: string;
  photoURL: string;
  startDate: Date;
  status: string;
  trackId: string;
  trackTitle: string;
  userId: string;
  examResult?: 'open' | 'passed' | 'partly_passed' | 'failed';
  examComment?: string;
}

export interface SamiExamPreparation {
  [key: string]: any;
  examId?: string;
  date?: Date | any;
  title?: string;
  notes?: string;
  style?: SamiStyleModel;
  levelToReach?: number;
}

export interface SamiExamClip {
  [key: string]: any;
  clipId?: string;
  clipRef?: string;
  title?: string;
  style?: SamiStyleModel;
  level?: number;
  category?: string;
  subcategory?: string;
  videoId?: string;
  note?: string;
  completed?: boolean;
  status?: 'active' | 'redo' | 'completed' | 'later';
  played?: number;
}
