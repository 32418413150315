<div class="flex flex-col justify-start space-y-4" *ngIf="track$ | async as track">
  <div class="flex flex-row justify-between items-center w-full">
    <div class="flex flex-col" *ngIf="showTitle">
      <span class="text-lg font-semibold font-sans text-richBlack">
        {{ track.title }}
      </span>
      <span class="font-body text-sm text-cadetGrey-400">
        available for {{ availableForTicketTypes }}
      </span>
    </div>
    <div class="flex flex-row justify-center items-end space-x-4 font-sans font-semibold uppercase text-base text-richBlack">
      <div class="flex flex-row justify-center items-center space-x-2" *ngIf="$any(track)['_totalDuration']">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 4.02944 4.02944 0 9 0C13.9705 0 18 4.02944 18 9C18 13.9705 13.9705 18 9 18C4.02944 18 0 13.9705 0 9ZM9 1.5C4.85787 1.5 1.5 4.85787 1.5 9C1.5 13.1421 4.85787 16.5 9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85787 13.1421 1.5 9 1.5ZM9.75 8.25V4.41678H8.25V9C8.25 9.41421 8.58579 9.75 9 9.75H12.6667V8.25H9.75Z" fill="#091F2C"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 4.02944 4.02944 0 9 0C13.9705 0 18 4.02944 18 9C18 13.9705 13.9705 18 9 18C4.02944 18 0 13.9705 0 9ZM9 1.5C4.85787 1.5 1.5 4.85787 1.5 9C1.5 13.1421 4.85787 16.5 9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85787 13.1421 1.5 9 1.5Z" fill="#091F2C"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 8.25V4.41678H8.25V9C8.25 9.41421 8.58579 9.75 9 9.75H12.6667V8.25H9.75Z" fill="#091F2C"/>
        </svg>
        <span>
          {{ ($any(track)['_totalDuration'] || 0) / 60 / 60 | number: '1.0-1' }} Hours
        </span>
      </div>

      <div class="flex flex-row justify-center items-center space-x-2">
        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.75 4V10L12 7L6.75 4Z" fill="#091F2C"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1C0 0.585786 0.335786 0.25 0.75 0.25H17.25C17.6642 0.25 18 0.585786 18 1V13C18 13.4142 17.6642 13.75 17.25 13.75H0.75C0.335786 13.75 0 13.4142 0 13V1ZM1.5 1.75V12.25H16.5V1.75H1.5Z" fill="#091F2C"/>
        </svg>
        <span>
          {{ track.lessonIds?.length }} Videos
        </span>
      </div>
    </div>
  </div>
  <cdk-accordion class="flex flex-col justify-start space-y-4">
    <cdk-accordion-item
      *ngFor="let chapter of track.chapters; let index = index;"
      #accordionItem="cdkAccordionItem"
    >
      <div [ngClass]="background === 'white' ? 'bg-culturedWhite' : 'bg-white'" class="px-4 py-2.5 w-full flex flex-row justify-between items-center cursor-pointer" (click)="accordionItem.toggle()">
        <div class="flex flex-row justify-start items-center space-x-2">
          <svg *ngIf="accordionItem.expanded" width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 1H12" stroke="#091F2C" stroke-width="2" stroke-linejoin="round"/>
          </svg>
          <svg *ngIf="!accordionItem.expanded" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 6H12" stroke="#091F2C" stroke-width="2" stroke-linejoin="round"/>
            <path d="M6 0V12" stroke="#091F2C" stroke-width="2" stroke-linejoin="round"/>
          </svg>
          <span class="text-lg font-sans font-semibold text-richBlack">
            {{ chapter.title }}
          </span>
        </div>
        <div class="flex flex-row justify-end items-center space-x-2 text-sm font-body">
          <span>
            {{ chapter.lessonIds?.length || 0 }} lessons
          </span>
          <ng-container *ngIf="$any(chapter)['_totalDuration'] > 0">
            <span>
              •
            </span>
            <span>
              {{ ($any(chapter)['_totalDuration'] || 0) / 60 | number: '1.0-0' }} mins
            </span>
          </ng-container>
        </div>
      </div>
      <div
        [style.display]="accordionItem.expanded ? '' : 'none'"
      >
        <div class="flex flex-col justify-start space-y-1 pt-6 pb-2 px-2">
          <div class="flex flex-row justify-start items-center space-x-2" *ngFor="let lesson of chapter.lessons">
            <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 3.33301V8.66634L10.6667 5.99967L6 3.33301Z" fill="black"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.666667C0 0.298477 0.298477 0 0.666667 0H15.3333C15.7015 0 16 0.298477 16 0.666667V11.3333C16 11.7015 15.7015 12 15.3333 12H0.666667C0.298477 12 0 11.7015 0 11.3333V0.666667ZM1.33333 1.33333V10.6667H14.6667V1.33333H1.33333Z" fill="black"/>
            </svg>
            <span class="text-sm font-body text-richBlack">
              {{ lesson.title }}
            </span>
          </div>
        </div>
      </div>
    </cdk-accordion-item>
  </cdk-accordion>
</div>