<mux-player #muxPlayer *ngIf="isClient"></mux-player>

<button
  *ngIf="displayClearButton"
  (click)="clear.emit()"
  mat-button
  color="primary"
>
  Clear
</button>
