import { isPlatformBrowser } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output, PLATFORM_ID } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService, LocationService, PaymentService } from '@sami/features';
import { Price, SamiUser } from 'interfaces';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-membership-selection',
  templateUrl: './membership-selection.component.html'
})
export class MembershipSelectionComponent {
  @Input() price: any;
  @Input() oneTimePurchaseData: any;
  @Input() contentTitle = '';
  @Input() mode: any;
  @Input() wrapperType: 'bottomSheet' | 'dialog' = 'dialog';

  @Output() clicked: EventEmitter<boolean> = new EventEmitter();

  reasons: string[] = [
    'Complete learning tracks including feedback on assignments and official certification',
    'All guided trainings and live trainings',
    'Trainings Creator to build your own trainings',
    'Full access to our 4000+ video library',
  ];

  yearlyPrice2023$: Observable<any>;
  monthlyPrice2023$: Observable<any>;
  loading = false;

  selected: 'monthly' | 'yearly' = 'yearly';
  trailPeriod = 7;

  constructor(
    private afs: AngularFirestore,
    public location: LocationService,
    private paymentService: PaymentService,
    public auth: AuthService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.yearlyPrice2023$ = this.afs
      .doc('stripeSubscriptions/samix_year_2023')
      .valueChanges({ idField: '_id' });
    this.monthlyPrice2023$ = this.afs
      .doc('stripeSubscriptions/samix_month_2023')
      .valueChanges({ idField: '_id' });
  }


  async startCheckout(price: Price, location: any, user: SamiUser) {

    if (!user.stripeCustomerId) {
      return;
    }

    if (isPlatformBrowser(this.platformId)) {
      this.loading = true;
      const currentURL = window.location.href;

      try {
        const checkout = await this.paymentService
          .generateCheckoutSession({
            priceId: price.id,
            currency: location?.currency.code || 'EUR',
            accountId: null,
            couponCode: null,
            metadata: {
              type: 'subscription',
            },
            taxRate: null,
            systems: 'stick,axe,kravMaga,panantukan,knife',
            // trialPeriodDays: this.selected === 'yearly' ? this.trailPeriod : 0,
            trialPeriodDays: 0,
            customerId: user.stripeCustomerId,
            successUrl: currentURL,
            cancelUrl: currentURL,
            returnUrl: currentURL
          });

        if (checkout) {
          // redirect to checkout.url which is an url
          window.location.href = checkout.url;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    }
  }
}
