<ng-container *ngIf="menuItem">
  <cdk-accordion class="w-full" *ngIf="menuItem.submenu; else noSubmenu">
    <cdk-accordion-item class="w-full" #accordionItem="cdkAccordionItem">
      <div [ngClass]="accordionItem.expanded ? 'bg-richBlack' : ''" (click)="accordionItem.toggle()" class="flex flex-row pl-6 py-2.5 pr-4 justify-between items-center w-full">
        <button
          [ngClass]="accordionItem.expanded ? 'font-semibold' : ''"
          (click)="navigateToElement(menuItem.route)"
          [routerLinkActive]="backgroundColor === 'white' ? 'text-cadetGrey-800' : 'text-white'"
          [routerLinkActiveOptions]="{ exact: false }"
          class="flex cursor-pointer flex-row justify-start w-full text-left items-center space-x-3"
        >
          <sami-icon
            class="w-4 h-4 flex flex-row justify-center items-center"
            *ngIf="menuItem.icon && menuItem.icon.data" [name]="menuItem.icon.name">
          </sami-icon>
          <span class="flex-1">
            {{ menuItem.title }}
          </span>

        </button>
        <button class="w-4 h-4">
          <svg [ngClass]="accordionItem.expanded ? '' : '-rotate-90'" class="w-full object-contain h-full" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.64645 10.0202L10 10.3737L10.3536 10.0202L18.7708 1.60293L19.2708 2.04573L10.2357 11.0808L10 11.3165L9.7643 11.0808L0.729206 2.04573L1.2292 1.60293L9.64645 10.0202Z" fill="currentColor" stroke="currentColor"/>
          </svg>
        </button>
      </div>
      <div
        class="w-full flex flex-col space-y-1 justify-start"
        role="region"
        [ngClass]="accordionItem.expanded ? 'bg-cadetGrey-900' : ''"
        [style.display]="accordionItem.expanded ? '' : 'none'">
        <a
          class="pl-6 py-2.5 pr-4 text-cadetGrey-300"
          [routerLinkActive]="backgroundColor === 'white' ? 'text-richBlack' : 'text-white'"
          *ngFor="let item of menuItem.submenu.items"
          [routerLink]="item.route"
          (click)="isMobile || isSmartphone ? toggleSidenav.emit() : null"
        >
          {{ item.title }}
        </a>
        <a
          class="pl-6 py-2.5 pr-4 text-cadetGrey-600"
          [routerLinkActive]="backgroundColor === 'white' ? 'text-richBlack' : 'text-white'"
          *ngIf="menuItem.submenu.featured"
          [routerLink]="menuItem.submenu.featured.route"
          (click)="isMobile || isSmartphone ? toggleSidenav.emit() : null"
        >
          {{ menuItem.submenu.featured.title }}
        </a>
      </div>
    </cdk-accordion-item>
  </cdk-accordion>

  <ng-template #noSubmenu>
    <ng-container *ngIf="menuItem.route.includes('https://'); else internalRouting">
      <a
        [href]="menuItem.route"
        target="_blank"
        class="flex cursor-pointer pl-6 py-2.5 pr-4 flex-row justify-start w-full text-left items-center space-x-3"
      >
        <sami-icon
          class="w-4 h-4 flex flex-row justify-center items-center"
          *ngIf="menuItem.icon && menuItem.icon.data" [name]="menuItem.icon.name">
        </sami-icon>
        <span class="flex-1">
          {{ menuItem.title }}
        </span>

        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8536 1.00492L3.85355 9.00492L3.14645 8.29781L11.1464 0.297814L11.8536 1.00492Z" fill="currentColor"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7 0.151367H11.5C11.7761 0.151367 12 0.375225 12 0.651367V5.15137H11V1.15137H7V0.151367Z" fill="currentColor"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.15137C0 2.87522 0.223858 2.65137 0.5 2.65137H5V3.65137H1V11.1514H8.5V7.15137H9.5V11.6514C9.5 11.9275 9.27614 12.1514 9 12.1514H0.5C0.223858 12.1514 0 11.9275 0 11.6514V3.15137Z" fill="currentColor"/>
        </svg>

      </a>
    </ng-container>
    <ng-template #internalRouting>
      <button
        [routerLink]="menuItem.route"
        (click)="isMobile || isSmartphone ? toggleSidenav.emit() : null"
        [routerLinkActive]="backgroundColor === 'white' ? 'text-richBlack font-semibold' : 'text-white'"
        [routerLinkActiveOptions]="{ exact: false }"
        class="flex cursor-pointer pl-6 py-2.5 pr-4 flex-row justify-start w-full text-left items-center space-x-3"
      >
        <sami-icon
          class="w-4 h-4 flex flex-row justify-center items-center"
          *ngIf="menuItem.icon && menuItem.icon.data" [name]="menuItem.icon.name">
        </sami-icon>
        <span class="flex-1">
          {{ menuItem.title }}
        </span>

      </button>
    </ng-template>
  </ng-template>
</ng-container>