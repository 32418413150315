import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { lastValueFrom, Observable, take } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService, ShopService } from '@sami/features';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upgrade-dialog',
  templateUrl: './upgrade-dialog.component.html',
})
export class UpgradeDialogComponent implements OnInit {
  form: FormGroup;
  selected: 'monthly' | 'yearly' = 'monthly';
  location: any;
  monthlyPrice: any;
  yearlyPrice: any;
  invoice: any;

  product$: Observable<any> = new Observable();

  loading = false;
  success = false;

  constructor(
    public dialogRef: MatDialogRef<UpgradeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public auth: AuthService,
    private fb: FormBuilder,
    private aff: AngularFireFunctions,
    private afs: AngularFirestore,
    private shop: ShopService,
    private router: Router
  ) {
    this.form = this.fb.group({
      system: this.fb.control('kravMaga', Validators.required),
    });
  }

  ngOnInit(): void {
    this.location = this.data.location;
    this.monthlyPrice = this.data.monthlyPrice;
    this.yearlyPrice = this.data.yearlyPrice;
    this.getUpcomingInvoice(this.monthlyPrice);

    if (this.monthlyPrice && this.monthlyPrice.product) {
      this.product$ = this.afs
        .doc(`stripeProducts/${this.monthlyPrice.product}`)
        .valueChanges({ idField: 'id' });
    }
  }

  async getUpcomingInvoice(price: any) {
    try {
      if (this.data?.subscription?.id) {
        this.invoice = await lastValueFrom(
          this.aff
            .httpsCallable('getUpcomingInvoice')({
              subscriptionId: this.data.subscription.id,
              priceId: price.id,
            })
            .pipe(take(1))
        );
      } else {
        this.invoice = null;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async upgrade(interval: 'monthly' | 'yearly') {
    if (this.form.invalid) {
      return;
    }
    try {
      this.loading = true;

      if (this.data?.subscription?.id) {
        await lastValueFrom(
          this.aff
            .httpsCallable('updateSubscription')({
              subscriptionId: this.data.subscription.subscriptionId,
              priceId:
                interval === 'monthly'
                  ? this.monthlyPrice.id
                  : this.yearlyPrice.id,
              mode: 'upgrade',
              systems: 'kravMaga,stick,axe,sds,knife,panantukan',
            })
            .pipe(take(1))
        );
        this.success = true;
      } else {
        const price = interval === 'monthly' ? this.monthlyPrice : this.yearlyPrice;
        this.addToCart(
          price.product?.id || price.product,
          price.id
        );
        this.dialogRef.close();
      }
      this.loading = false;
    } catch (error) {
      console.error(error);
      this.loading = false;
    }
  }

  async addToCart(productId: string, priceId?: string) {
    try {
      const cartSessionId: string = await this.shop.createCartSession(null, productId, priceId || null, 'membership');
      this.router.navigateByUrl('/en/checkout/' + cartSessionId);
    } catch(error) {
      console.error(error);
    }
  }
}
