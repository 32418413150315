import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { GetDownloadURLPipeModule } from '@angular/fire/compat/storage';
import { UiService } from '@sami/features';
import { TrackCard, TrackUserProgress } from 'interfaces';
import { PurchasedBadgeComponent } from '../../badges/purchased-badge/purchased-badge.component';
import { ImagePipe } from '../../pipes/transformImage.pipe';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { Auth } from '@angular/fire/auth';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-track-card',
  standalone: true,
  imports: [CommonModule, GetDownloadURLPipeModule, ImagePipe, PurchasedBadgeComponent],
  templateUrl: './track-card.component.html'
})
export class TrackCardComponent implements OnInit {
  firestore: Firestore = inject(Firestore);
  auth: Auth = inject(Auth);

  @Input() loading: boolean | undefined;
  @Input() purchases: any[] | undefined;
  @Input() id: string | undefined;
  @Input() trackCard: TrackCard | undefined;
  @Output() clickEmitter: EventEmitter<any> = new EventEmitter();

  hasPurchased = false;

  userProgress: TrackUserProgress | undefined | null;

  theme = 'bg-richBlack';

  desktopImageClass = [{
    "height": "290",
    "width": "190"
  }];

  mobileImageClass = [{
    "height": "170",
    "width": "300"
  }];

  constructor(
    public ui: UiService
  ) {}

  ngOnInit(): void {
    if (!this.id) return;
    if (this.trackCard) {
      // Create an observable from the trackCard data and include hasPurchased
      this.theme = this.getTheme(this.trackCard.category);
      this.hasPurchased = this.purchases?.find((p: any) => p.docId === this.trackCard?._id);

      this.userProgress = getDoc(
        doc(this.firestore, 'tracks/' + this.trackCard?._id + '/trackProgress/' + this.auth.currentUser?.uid )
      ).then((doc) => {
        this.userProgress = doc.data();
      });
    }
  }

  getTheme(category: string | undefined) {
    if (category) {
      if (category === 'SAMI-X Fighting Arts') {
        return 'bg-fightingRed-700';
      } else if (category === 'SAMI-X Defense') {
        return 'bg-defensiveGreen-500';
      } else if (category === 'SAMI-X Pro') {
        return 'bg-professionalYellow-300';
      } else {
        return 'bg-richBlack';
      }
    }

    return 'bg-richBlack';
  }
}
