import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadMuxComponent } from './upload-mux.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [UploadMuxComponent],
  imports: [CommonModule, NgxDropzoneModule, MatButtonModule],
  exports: [UploadMuxComponent],
})
export class UploadMuxModule {}
