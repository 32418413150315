import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { SamiCenter } from 'interfaces';

@Component({
  selector: 'app-center-card',
  templateUrl: './center-card.component.html',
})
export class CenterCardComponent implements OnInit {
  @Input() center: any | undefined = undefined;
  @Input() centerId: any | undefined = undefined;
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Input() mode: 'remove' | 'visit' = 'visit';

  center$: Observable<SamiCenter> = new Observable();

  constructor(private afs: AngularFirestore) {}

  ngOnInit(): void {
    if (this.centerId) {
      this.center$ = this.afs
        .doc(`centers/${this.centerId}`)
        .valueChanges() as Observable<SamiCenter>;
    }
  }

  clickedOnCard() {
    this.onClick.emit(this.centerId);
  }
}
