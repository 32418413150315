import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FieldErrorsComponent } from '../field-errors/field-errors.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import algoliasearch from 'algoliasearch';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-autocomplete',
  standalone: true,
  imports: [CommonModule, FieldErrorsComponent, MatAutocompleteModule],
  templateUrl: './autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SamiAutocompleteComponent), multi: true,
    }
  ],
})
export class SamiAutocompleteComponent implements ControlValueAccessor {

  @Input() parentForm!: FormGroup;
  @Input() fieldName!: string;
  @Input() label!: string;
  @Input() placeholder!: string;
  @Input() hint: string | undefined;
  @Input() disabled = false;
  @Input() displayLabel = false;
  @Input() maxLengthLabel: number | undefined;
  @Input() type: 'center' | 'instructor' = 'instructor';
  @Input() showForgotPassword = false;
  @Input() changeEmail = false;

  @Output() clickedOnLink = new EventEmitter();

  filteredElements: any;

  client = algoliasearch('PVL356RKY9', '1dbbc66873df6bfc51ec0b3536e5ad94');
  centerIndex = this.client.initIndex(
    'prod_CENTERS'
  );
  instructorIndex = this.client.initIndex(
    'prod_INSTRUCTORS'
  );

  public isDisabled = false;
  public value: any;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public changed: (value: string) => void = () => { };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public touched: () => void = () => { };

  get formField(): FormControl {
    return this.parentForm.get(this.fieldName) as FormControl;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  public onChange(event: Event): void {
    const value: string = (event.target as HTMLInputElement).value;
    this.changed(value);
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  get validator() {
    if (this.formField.validator) {
      const validator = this.formField.validator({} as AbstractControl);
      if (validator && validator['required']) {
        return true;
      }
    }
    return false;
  }

  onSelect(event: any) {
    if (this.type === 'center') {
      this.value = {
        id: event['objectID'],
        name: event.name,
        image: '',
      };
      this.changed(this.value);
    } else if (this.type === 'instructor') {
      this.value = {
        id: event.objectID,
        name: event.displayName,
        image: event.photoURL,
      };
      this.changed(this.value);
    }
  }

  async search(query: string) {
    let results;
    if (this.type === 'center') {
      results = await this.centerIndex.search(query, {
        hitsPerPage: 8,
      });
    } else if (this.type === 'instructor') {
      results = await this.instructorIndex.search(query, {
        hitsPerPage: 8,
      });
    }
    if (results && results.hits.length) {
      this.filteredElements = results.hits;
    } else {
      this.filteredElements = [];
    }
  }
}
