<button
  class="flex flex-col justify-start w-full h-full hover:shadow-lg"
  [ngClass]="backgroundColor === 'white' ? 'bg-white' : 'bg-culturedWhite'"
  *ngIf="newsElement"
>
  <div class="bg-richBlack w-full relative">
    <div class="aspect-w-16 aspect-h-9"></div>
    <picture *ngIf="newsElement?.content?.image?.filename">
      <source
        type="image/webp"
        [srcset]="
          newsElement?.content?.image?.filename
            | transformImg
              : 'storyblok'
              : 'fit-in/0x300/smart/filters:format(webp)'
        "
      />
      <source
        type="image/png"
        [srcset]="
          newsElement?.content?.image?.filename
            | transformImg
              : 'storyblok'
              : 'fit-in/0x300/smart/filters:format(png)'
        "
      />
      <img
        loading="lazy"
        class="absolute inset-0 w-full h-full object-cover"
        [src]="
          newsElement?.content?.image?.filename
            | transformImg: 'storyblok':'fit-in/0x300/smart'
        "
        [alt]="newsElement.title"
      />
    </picture>
  </div>
  <div
    class="px-3 py-4 w-full flex flex-col justify-start space-y-3"
  >
    <div class="flex flex-col justify-start items-start space-y-1">
      <span class="text-cadetGrey-300 text-sm uppercase font-semibold">
        {{ newsElement?.content?.category }}
      </span>
      <h4 class="text-richBlack text-xl font-semibold text-left tracking-tight">
        {{ newsElement?.name }}
      </h4>
    </div>
    <p class="text-richBlack font-body text-sm line-clamp-3 text-left">
      {{ newsElement?.content?.excerpt }}
    </p>
    <div class="justify-start items-center gap-2.5 inline-flex" *ngIf="newsElement?.content?.author && newsElement?.content?.author.length > 0">
      <img class="w-8 h-8 rounded-full" *ngIf="newsElement?.content?.author[0]?.image?.filename" [src]="newsElement?.content?.author[0]?.image?.filename" />
      <div class="flex-col justify-start items-start inline-flex">
        <div class="text-richBlack text-sm font-semibold font-sans leading-tight">{{ newsElement?.content?.author[0]?.name }}</div>
        <div class="text-cadetGrey-500 text-xs font-normal font-body leading-none">{{ newsElement.created_at | date : 'MMM d, y'}}</div>
      </div>
    </div>
  </div>
</button>