import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  currencyBaseUrl =
    'https://data.fixer.io/api/latest?access_key=b228396649a186cd830f855445c0370d';
  selectedCurrency = 'EUR';
  convertedCurrency = 1;

  constructor(private http: HttpClient) {}

  getCurrencies() {
    this.http
      .get<any>(this.currencyBaseUrl + '&format=1')
      .subscribe((currencies) => {
        return currencies;
      });
  }

  getCurrency(currency: string) {
    this.selectedCurrency = currency;
    this.http
      .get<any>(this.currencyBaseUrl + '&symbols=' + currency)
      .subscribe((c) => {
        this.convertedCurrency = c.rates[currency];
        return c;
      });
  }
}
