<div class="flex flex-row space-x-10 p-2">
  <div class="flex flex-row justify-center items-center">
    <div
      class="w-16 h-16 flex flex-row justify-center items-center"
      [innerHtml]="returnSVG(categories[type].icon)"
    ></div>
  </div>
  <div class="flex flex-col justify-start items-start space-y-0">
    <span class="text-richBlack text-5xl font-semibold">{{ value }}</span>
    <span class="text-cadetGrey-richBlack text-sm">{{
      categories[type].title
    }}</span>
  </div>
</div>
