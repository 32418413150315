<ng-container *ngIf="!loading; else showLoading">
  <div
    class="group hover:shadow-lg rounded-md overflow-hidden h-full"
    *ngIf="{user: auth.user$ | async} as vm"
    [ngClass]="{
      'flex flex-col justify-start': layout === 'column',
      'grid grid-cols-1 sm:grid-cols-2': layout === 'row',
      'bg-white': backgroundColor === 'white',
      'bg-culturedWhite': backgroundColor === 'culturedWhite'
    }"
  >
    <div class="relative z-10">
      <app-manage-collection-button
        *ngIf="displayManageCollectionButton && vm.user"
        [clipId]="id"
        [type]="'icon'"
        class="absolute top-2 right-2 group-hover:flex hidden flex-col justify-center items-center"
      ></app-manage-collection-button>

      <div
        *ngIf="isExamRequired"
        class="absolute text-sm top-2 left-2 py-1 px-2 bg-richBlack text-culturedWhite z-10 flex flex-col font-semibold justify-center items-center"
      >
        <span class="cursor-default uppercase font-sans">exam</span>
      </div>

      <div
        *ngIf="clip && clip.status && clip.status === 'instructor'"
        class="absolute text-sm top-2 right-2 py-1 px-2 bg-richBlack text-culturedWhite z-10 flex flex-col font-semibold justify-center items-center"
      >
        <span class="cursor-default uppercase font-sans">instructor content</span>
      </div>

      <div
        *ngIf="numberOfVideos"
        class="absolute text-sm bottom-2 left-2 py-1 px-2 bg-richBlack text-culturedWhite z-10 flex flex-col font-semibold justify-center items-center"
      >
        <span class="cursor-default uppercase font-sans">{{
          numberOfVideos + ' Videos'
        }}</span>
      </div>
      <div
        *ngIf="durationInSeconds && hasAccess"
        class="absolute text-sm bottom-2 right-2 py-1 px-2 bg-richBlack text-culturedWhite z-10 flex flex-col font-semibold justify-center items-center"
      >
        <span class="cursor-default font-sans">{{
          durationInSeconds * 1000 | date: 'mm:ss'
        }}</span>
      </div>
      <div
        class="aspect-w-16 aspect-h-9 cursor-pointer"
        (click)="clickEmitter.emit(id)"
      >
        <ng-container *ngIf="clip && clip.muxVideoElement; else vimeoThumbnail">
          <div
            *ngIf="clip?.muxVideoElement?.asset?.playback_ids[0]?.id"
            class="group"
          >
            <img
              [src]="
                'https://image.mux.com/' +
                clip?.muxVideoElement?.asset?.playback_ids[0]?.id +
                '/animated.gif?width=400&fps=5&start=0'
              "
              [alt]="clip.title"
              class="hidden group-hover:block object-cover w-full h-full absolute inset-0"
            />
            <img
              [src]="
                'https://image.mux.com/' +
                clip?.muxVideoElement?.asset?.playback_ids[0]?.id +
                '/thumbnail.png?width=400&height=226&fit_mode=smartcrop'
              "
              [alt]="clip.title"
              class="block group-hover:hidden object-cover w-full h-full absolute inset-0"
            />
          </div>
        </ng-container>
        <ng-template #vimeoThumbnail>
          <ng-container *ngIf="image; else noImage">
            <img [src]="image" [alt]="title" class="object-cover w-full" />
          </ng-container>
          <ng-template #noImage>
            <div
              class="w-full h-full bg-richBlack text-white flex flex-col items-center justify-center"
            >
              <svg
                class="w-16 h-16"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 48 48"
              >
                <g transform="matrix(2,0,0,2,0,0)">
                  <path
                    fill="currentColor"
                    d="M4 6H2V19C2 19.5304 2.21071 20.0391 2.58579 20.4142C2.96086 20.7893 3.46957 21 4 21H19V19H4V6Z"
                  ></path>
                  <path
                    fill="currentColor"
                    d="M20 6H15L13.35 3.8C13.1633 3.55201 12.9217 3.35069 12.6441 3.21188C12.3664 3.07307 12.0604 3.00054 11.75 3H8C7.46957 3 6.96086 3.21071 6.58579 3.58579C6.21071 3.96086 6 4.46957 6 5V15C6 15.5304 6.21071 16.0391 6.58579 16.4142C6.96086 16.7893 7.46957 17 8 17H20C20.5304 17 21.0391 16.7893 21.4142 16.4142C21.7893 16.0391 22 15.5304 22 15V8C22 7.46957 21.7893 6.96086 21.4142 6.58579C21.0391 6.21071 20.5304 6 20 6V6ZM8 15V5H11.75L13.4 7.2C13.5867 7.44799 13.8283 7.64931 14.1059 7.78812C14.3836 7.92693 14.6896 7.99946 15 8H20V15H8Z"
                  ></path>
                </g>
              </svg>
            </div>
          </ng-template>
        </ng-template>
        <div
          *ngIf="history$ | async as history"
          class="h-full w-full absolute inset-0 overflow-x-hidden flex flex-col justify-end"
        >
          <div
            class="w-full h-1 bg-red-600 absolute bottom-0 left-0 opacity-25 z-0"
          ></div>
          <div
            [style.width.%]="history.percent * 100"
            class="h-1 bg-red-600 absolute bottom-0 left-0 z-10"
          ></div>
        </div>

        <ng-container *ngIf="showProgressBar">
          <ng-container *ngIf="playlist$ | async as playlist">
            <div
              class="h-full w-full absolute inset-0 flex flex-col justify-end"
              *ngIf="playlist['progress']"
            >
              <div
                class="w-full h-1 bg-red-600 absolute bottom-0 left-0 opacity-25 z-0"
              ></div>
              <div
                [style.width.%]="playlist['progress'] * 100"
                class="h-1 bg-red-600 absolute bottom-0 left-0 z-10"
              ></div>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <ng-container *ngIf="completed">
        <div
          class="absolute inset-0 bg-richBlack/70 flex flex-col justify-center items-center"
        >
          <svg
            class="w-14 h-14"
            viewBox="0 0 41 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M38.1321 0L14.5301 23.2L3.47606 12.26L0.664062 15.104L13.1181 27.432C13.4924 27.8014 13.9971 28.0086 14.5231 28.0086C15.049 28.0086 15.5538 27.8014 15.9281 27.432L40.9281 2.848L38.1321 0Z"
              fill="#EDF2EF"
            />
          </svg>
        </div>
      </ng-container>

      <ng-container *ngIf="hasAccess === false">
        <button
          (click)="clickEmitter.emit(id)"
          class="absolute inset-0 bg-richBlack/90 text-culturedWhite flex flex-col justify-center items-center space-y-6"
        >
          <svg
            class="w-10 h-10"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linejoin="round"
              stroke-width="2"
              d="M20 11H4V23H20V11Z"
            ></path>
            <path
              stroke="currentColor"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 11V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V11"
            ></path>
            <path
              fill="currentColor"
              d="M12 19C13.1046 19 14 18.1046 14 17C14 15.8954 13.1046 15 12 15C10.8954 15 10 15.8954 10 17C10 18.1046 10.8954 19 12 19Z"
            ></path>
          </svg>
          <div class="flex flex-col justify-center items-center font-body space-y-2">
            <span class="text-cadetGrey-500 text-sm">
              You do not have access to this content
            </span>
            <a
              href="https://help.sami-x.com/en/articles/6549768-why-can-t-i-access-level-7-content"
              target="_blank"
              class="text-cadetGrey-500 hover:text-cadetGrey-200 text-sm underline"
              >More information</a
            >
          </div>
        </button>
      </ng-container>
    </div>
    <div
      class="h-full py-5 px-4 cursor-pointer flex flex-col justify-between space-y-3"
      (click)="clickEmitter.emit(id)"
      [ngClass]="{
        'bg-white': backgroundColor === 'white',
        'bg-culturedWhite': backgroundColor === 'culturedWhite'
      }"
    >
      <div class="flex flex-row justify-start" *ngIf="showSystemOnTop">
        <sami-combat-system-tag [combatSystem]="system" *ngIf="system" class="bottom-3 left-3 absolute"></sami-combat-system-tag>
      </div>
      <div class="flex flex-col justify-start space-y-1 flex-1">
        <ng-container *ngIf="active">
          <span
            class="cursor-pointer capitalize font-sans line-clamp-2 text-lg leading-tight font-semibold text-richBlack"
            *ngIf="title"
            >{{ title.toLowerCase() }}</span
          >
        </ng-container>
        <ng-container *ngIf="!active">
          <span class="text-xl font-semibold font-sans text-richBlack">{{ title }}</span>
        </ng-container>

        <div
          class="flex flex-row flex-1 justify-start items-center space-x-5 pt-2"
          *ngIf="contentType === 'live-coaching'"
        >
          <div
            class="flex flex-row justify-start items-center space-x-1"
            *ngIf="date"
          >
            <svg
              class="w-5"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 3.75C0 3.33579 0.335786 3 0.75 3H17.25C17.6642 3 18 3.33579 18 3.75V17.25C18 17.6642 17.6642 18 17.25 18H0.75C0.335786 18 0 17.6642 0 17.25V3.75ZM1.5 4.5V16.5H16.5V4.5H1.5Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.5 0V3.75H3V0H4.5Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15 0V3.75H13.5V0H15Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.25 8.25H0.75V6.75H17.25V8.25Z"
                fill="black"
              />
            </svg>
            <span class="text-sm font-sans font-semibold text-culturedBlack">{{
              date
            }}</span>
          </div>

          <div
            class="flex flex-row font-sans justify-start items-center space-x-1"
            *ngIf="time"
          >
            <svg
              class="w-5"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 17.25C13.5563 17.25 17.25 13.5563 17.25 9C17.25 4.44365 13.5563 0.75 9 0.75C4.44365 0.75 0.75 4.44365 0.75 9C0.75 13.5563 4.44365 17.25 9 17.25Z"
                stroke="black"
                stroke-width="1.5"
                stroke-linejoin="round"
              />
              <path
                d="M12.6667 9.00003H9V4.41681"
                stroke="black"
                stroke-width="1.5"
                stroke-linejoin="round"
              />
            </svg>
            <span class="text-sm font-semibold text-culturedBlack">{{
              time
            }}</span>
          </div>
        </div>

        <div
          *ngIf="description"
          class="font-body text-base text-cadetGrey-600 cursor-default line-clamp-3"
          [innerHtml]="description"
        ></div>

        <div
          *ngIf="progress"
          class="pt-2 flex flex-row justify-start items-center space-x-4"
        >
          <span class="text-sm text-cadetGrey-700">
            {{ (progress | number) + '%' }}
          </span>
          <div class="w-full bg-cadetGrey-50 rounded-none h-2">
            <div
              class="bg-cadetGrey-700 h-2 rounded-none"
              [ngStyle]="{ width: progress + '%' }"
            ></div>
          </div>
        </div>
        <ng-container *ngIf="showProgressBar">
          <ng-container *ngIf="playlist$ | async as playlist">
            <div
              *ngIf="
                active &&
                playlist['progress'] === 1 &&
                contentType === 'playlist' &&
                !completed &&
                embeddedIn === 'track'
              "
              class="flex flex-row justify-end items-center pt-2"
            >
              <button
                (click)="trackClickMarkAsComplete.emit(id)"
                class="bg-richBlack hover:bg-richBlack/80 py-2 text-white font-sans text-base tracking-tight font-semibold px-4 flex flex-row justify-center items-center space-x-3"
              >
                <svg
                  class="w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 48 48"
                >
                  <g transform="matrix(2,0,0,2,0,0)">
                    <path
                      fill="currentColor"
                      d="M20.566 5L8.765 16.6L3.238 11.13L1.832 12.552L8.059 18.716C8.24615 18.9007 8.49853 19.0043 8.7615 19.0043C9.02447 19.0043 9.27685 18.9007 9.464 18.716L21.964 6.424L20.566 5Z"
                    ></path>
                  </g>
                </svg>
                <span>Mark as Complete</span>
              </button>
            </div>
          </ng-container>
        </ng-container>

        <div
          class="flex flex-row justify-end items-center pt-2"
          *ngIf="
            active &&
            contentType === 'video' &&
            !completed &&
            embeddedIn === 'track'
          "
        >
          <button
            (click)="trackClickMarkAsComplete.emit(id)"
            class="bg-richBlack hover:bg-richBlack/80 py-2 text-white font-sans text-base tracking-tight font-semibold px-4 flex flex-row justify-center items-center space-x-3"
          >
            <svg
              class="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 48 48"
            >
              <g transform="matrix(2,0,0,2,0,0)">
                <path
                  fill="currentColor"
                  d="M20.566 5L8.765 16.6L3.238 11.13L1.832 12.552L8.059 18.716C8.24615 18.9007 8.49853 19.0043 8.7615 19.0043C9.02447 19.0043 9.27685 18.9007 9.464 18.716L21.964 6.424L20.566 5Z"
                ></path>
              </g>
            </svg>
            <span>Mark as Complete</span>
          </button>
        </div>
      </div>
      <div class="flex flex-row font-sans space-x-2" *ngIf="showMeta">
        <sami-combat-system-tag [displaySAMIText]="false" [combatSystem]="system" *ngIf="system"></sami-combat-system-tag>
        <span
          class="text-xs bg-cadetGrey-100 py-1 px-2 text-cadetGrey-700 cursor-default"
        >
          {{ 'Level ' + level }}
        </span>
        <span
          *ngIf="type"
          class="text-xs capitalize bg-cadetGrey-100 py-1 px-2 text-cadetGrey-700 cursor-default"
        >
          {{ type }}
        </span>
      </div>
      <div
        class="flex flex-row justify-between items-center"
        *ngIf="instructor && signUps"
      >
        <div
          class="flex flex-row justify-start space-x-2 items-center"
          *ngIf="instructor"
        >
          <img
            class="inline-block h-7 w-7 rounded-full"
            [src]="instructor.image"
            [alt]="instructor.name"
          />
          <a
            class="text-richBlack font-normal text-sm tracking-tight hover:underline cursor-pointer"
            >{{ instructor.name }}</a
          >
        </div>
        <div
          class="flex flex-row justify-end items-center space-x-2"
          *ngIf="signUps"
        >
          <svg
            class="h-7 w-7"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 48 48"
          >
            <g transform="matrix(2,0,0,2,0,0)">
              <path
                fill="currentColor"
                d="M9 12C9.79113 12 10.5645 11.7654 11.2223 11.3259C11.8801 10.8864 12.3928 10.2616 12.6955 9.53074C12.9983 8.79983 13.0775 7.99556 12.9231 7.21964C12.7688 6.44372 12.3878 5.73098 11.8284 5.17157C11.269 4.61216 10.5563 4.2312 9.78036 4.07686C9.00444 3.92252 8.20017 4.00173 7.46927 4.30448C6.73836 4.60723 6.11365 5.11992 5.67412 5.77772C5.2346 6.43552 5 7.20888 5 8C5 9.06087 5.42143 10.0783 6.17157 10.8284C6.92172 11.5786 7.93913 12 9 12ZM9 6C9.39556 6 9.78224 6.1173 10.1111 6.33706C10.44 6.55683 10.6964 6.86918 10.8478 7.23463C10.9991 7.60009 11.0387 8.00222 10.9616 8.39018C10.8844 8.77814 10.6939 9.13451 10.4142 9.41422C10.1345 9.69392 9.77814 9.8844 9.39018 9.96157C9.00222 10.0387 8.60009 9.99914 8.23463 9.84776C7.86918 9.69639 7.55682 9.44004 7.33706 9.11114C7.1173 8.78224 7 8.39556 7 8C7 7.46957 7.21071 6.96086 7.58579 6.58579C7.96086 6.21072 8.46957 6 9 6Z"
              ></path>
              <path
                fill="currentColor"
                d="M17 14C17.5933 14 18.1734 13.8241 18.6667 13.4944C19.1601 13.1648 19.5446 12.6962 19.7716 12.1481C19.9987 11.5999 20.0581 10.9967 19.9424 10.4147C19.8266 9.83279 19.5409 9.29824 19.1213 8.87868C18.7018 8.45912 18.1672 8.1734 17.5853 8.05765C17.0033 7.94189 16.4001 8.0013 15.8519 8.22836C15.3038 8.45543 14.8352 8.83994 14.5056 9.33329C14.1759 9.82664 14 10.4067 14 11C14 11.7957 14.3161 12.5587 14.8787 13.1213C15.4413 13.6839 16.2044 14 17 14V14ZM17 10C17.1978 10 17.3911 10.0587 17.5556 10.1685C17.72 10.2784 17.8482 10.4346 17.9239 10.6173C17.9996 10.8 18.0194 11.0011 17.9808 11.1951C17.9422 11.3891 17.847 11.5673 17.7071 11.7071C17.5673 11.847 17.3891 11.9422 17.1951 11.9808C17.0011 12.0194 16.8 11.9996 16.6173 11.9239C16.4346 11.8482 16.2784 11.72 16.1685 11.5556C16.0586 11.3911 16 11.1978 16 11C16 10.7348 16.1054 10.4804 16.2929 10.2929C16.4804 10.1054 16.7348 10 17 10Z"
              ></path>
              <path
                fill="currentColor"
                d="M17 15V17C17.7956 17 18.5587 17.3161 19.1213 17.8787C19.6839 18.4413 20 19.2044 20 20H16C16 18.1435 15.2625 16.363 13.9497 15.0503C12.637 13.7375 10.8565 13 9 13C7.14348 13 5.36301 13.7375 4.05025 15.0503C2.7375 16.363 2 18.1435 2 20V22H22V20C21.9984 18.6744 21.4711 17.4036 20.5338 16.4662C19.5964 15.5289 18.3256 15.0016 17 15V15ZM4 20C4 18.6739 4.52678 17.4021 5.46447 16.4645C6.40215 15.5268 7.67392 15 9 15C10.3261 15 11.5979 15.5268 12.5355 16.4645C13.4732 17.4021 14 18.6739 14 20H4Z"
              ></path>
            </g>
          </svg>
          <span
            class="text-richBlack font-normal text-sm tracking-tight cursor-default"
            >{{ signUps }} signed up</span
          >
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #showLoading>
  <div class="w-full bg-white flex-col justify-start items-start inline-flex">
    <div class="self-stretch aspect-w-16 aspect-h-9 animate-pulse bg-cadetGrey-200"></div>
      <div class="self-stretch h-28 p-4 flex-col justify-between items-start gap-4 flex">
          <div class="self-stretch h-12 flex-col justify-start items-start gap-2 flex">
              <div class="self-stretch h-5 flex-col justify-start items-start gap-4 flex">
                  <div class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-2 flex">
                      <div class="self-stretch grow shrink basis-0 animate-pulse bg-cadetGrey-200"></div>
                  </div>
              </div>
              <div class="self-stretch h-5 flex-col justify-start items-start gap-2 flex">
                  <div class="w-40 h-5 bg-cadetGrey-200 animate-pulse"></div>
              </div>
          </div>
          <div class="self-stretch justify-start items-start gap-3 inline-flex">
              <div class="w-14 h-5 bg-cadetGrey-200 animate-pulse"></div>
              <div class="w-14 h-5 bg-cadetGrey-200 animate-pulse"></div>
              <div class="w-14 h-5 bg-cadetGrey-200 animate-pulse"></div>
          </div>
      </div>
  </div>
</ng-template>