<div class="flex flex-col justify-start space-y-2 items-start">
  <ngx-dropzone [multiple]="false" (change)="onSelect($event)" class="w-full">
    <ngx-dropzone-label>Video Asset</ngx-dropzone-label>
    <ngx-dropzone-preview
      *ngFor="let f of files"
      [removable]="true"
      (removed)="onRemove(f)"
    >
      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-preview>
  </ngx-dropzone>

  <button type="button" (click)="uploadFiles()" mat-flat-button color="primary">
    <ng-container
      *ngIf="uploadPercent > 0 && uploadPercent < 100; else notUploaded"
    >
      Uploading {{ uploadPercent }}%
    </ng-container>
    <ng-template #notUploaded> Upload </ng-template>
  </button>
</div>
