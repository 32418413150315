import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { AuthService, LocationService, ShopService } from '@sami/features';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-membership-selection',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './membership-selection.component.html'
})
export class MembershipSelectionComponent {
  @Input() pricing_subtitle: string | undefined;
  @Input() mode: 'checkout' | 'select' = 'checkout';

  @Output() membershipSelected: EventEmitter<any> = new EventEmitter<any>();
  selectedMembership: any | null;

  price$: Observable<any>;
  price_sami_x_month$: Observable<any>;
  price_sami_x_year$: Observable<any>;

  constructor(
    public location: LocationService,
    private afs: AngularFirestore,
    public auth: AuthService,
    private shop: ShopService,
    private router: Router
  ) {
    this.price$ = this.afs
      .doc(`stripeSubscriptions/sami_international_membership`)
      .valueChanges();
    this.price_sami_x_month$ = this.afs
      .doc(`stripeSubscriptions/samix_month_2023`)
      .valueChanges();
    this.price_sami_x_year$ = this.afs
      .doc(`stripeSubscriptions/samix_year_2023`)
      .valueChanges();
  }

  async addToCart(productId: string, priceId?: string) {
    try {
      const cartSessionId: string = await this.shop.createCartSession(null, productId, priceId || null, 'membership');
      this.router.navigateByUrl('/en/checkout/' + cartSessionId);
    } catch(error) {
      console.error(error);
    }
  }

  selectPlan(plan: any) {
    this.selectedMembership = plan;
    this.membershipSelected.emit(plan);
  }

}
