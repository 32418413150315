import { Component, Input } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Router } from '@angular/router';
import { GuidedTraining } from 'interfaces';
import { MenuService } from 'header';

@Component({
  selector: 'app-guided-training-card',
  templateUrl: './guided-training-card.component.html',
})
export class GuidedTrainingCardComponent {
  @Input() guidedTraining: GuidedTraining | null = null;
  @Input() routingPath = '/app/gym/guided-trainings/0/event/';
  @Input() isAvailable = false;

  constructor(
    public storage: AngularFireStorage,
    private menuService: MenuService,
    private router: Router,
    private analytics: AngularFireAnalytics
  ) {}

  visitGuidedTraining(guidedTraining: GuidedTraining) {
    this.analytics.logEvent('visit_guided_training', { guidedTrainingId: guidedTraining._id, guidedTrainingTitle: guidedTraining.title });
    this.menuService.navigate(
      this.routingPath + guidedTraining._id,
      this.router.url,
      guidedTraining.title || ''
    );
  }
}