import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { GeoPoint } from '@angular/fire/firestore';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MapGeocoder } from '@angular/google-maps';
import { MapService, UiService } from '@sami/features';
import { SamiCenter } from 'interfaces';
import { BehaviorSubject } from 'rxjs';

import GeocoderResult = google.maps.GeocoderResult;
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './contact.component.html',
})
export class ContactComponent implements OnChanges {
  @ViewChild('googleAutocomplete', { static: false }) googleAutocomplete:
    | ElementRef
    | undefined;
  @Input() center: SamiCenter | undefined;
  isBusy$ = new BehaviorSubject<boolean>(false);

  form = this.fb.group({
    address: ['', Validators.required],
    phone: ['', Validators.required],
    website: [''],
    email: ['', Validators.required],
    geolocation: [null],
    addressComponents: [null],
    place_id: [null],
  });

  compareWith = (o1: { code: any }, o2: { code: any }) =>
    o1 && o2 ? o1.code === o2.code : o1 === o2;

  constructor(
    private fb: FormBuilder,
    private afs: AngularFirestore,
    public ui: UiService,
    private geocoder: MapGeocoder,
    private mapService: MapService
  ) {
    const url =
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyBM0thRrffEBb2fO-EIB7o_KShPhYDf0lg&libraries=places&callback=Function.prototype';
    this.mapService.loadScript(url, 'sami-x-google-maps');
  }

  ngOnChanges(): void {
    if (this.center) {
      this.form.patchValue({
        address: this.center.address,
        phone: this.center.phone,
        website: this.center.website,
        email: this.center.email,
        addressComponents: this.center.addressComponents || null,
        place_id: this.center.place_id || null,
        geolocation: this.center.geolocation,
      });
    }
  }

  async submit() {
    if (this.form.valid) {
      this.isBusy$.next(true);
      try {
        await this.afs.doc(`centers/${this.center?.id}`).update({
          address: this.form.get('address')?.value,
          phone: this.form.get('phone')?.value,
          website: this.form.get('website')?.value,
          email: this.form.get('email')?.value,
          geolocation: this.form.get('geolocation')?.value,
          addressComponents: this.form.get('addressComponents')?.value,
          place_id: this.form.get('place_id')?.value,
        });

        this.isBusy$.next(false);
      } catch (error) {
        console.error(error);
        this.isBusy$.next(false);
      }
    }
  }

  async updateGooglePlaces() {
    if (this.form) {
      const addr = this.form.get('address')?.value;
      if (addr) {
        this.geocoder.geocode({ address: addr }).subscribe((res: any) => {
          const response = res.results;
          if (response.length >= 1) {
            this.patchAddress(response[0]);
          }
        });
      }
    }
  }

  patchAddress(place: any) {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    const geolocation: any = { lat, lng, point: new GeoPoint(lat, lng) };

    if (this.form) {
      this.form.get('address')?.patchValue(place.formatted_address, 'route');
      this.form.get('geolocation')?.patchValue(geolocation, 'route');
      this.form
        .get('addressComponents')
        ?.patchValue(place.address_components, 'route');
      this.form.get('place_id')?.patchValue(place.place_id, 'route');
    }
  }
}
