import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AuthService, WorkoutsService } from '@sami/features';
import { Observable, combineLatest, map, of, switchMap } from 'rxjs';
import { ExercisesCountComponent } from './exercises-count/exercises-count.component';
import { MatRippleModule } from '@angular/material/core';


@Component({
  selector: 'app-success',
  standalone: true,
  imports: [ CommonModule, RouterModule, ExercisesCountComponent, MatRippleModule ],
  templateUrl: './success.component.html',
})
export class SuccessComponent {
  @Input() extraPaddingOnTop = false;

  workoutId$: Observable<any>;
  sessionId$: Observable<any>;
  session$: Observable<any>;

  isNative = false;
  isPWA = false;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public auth: AuthService,
    private workoutService: WorkoutsService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.workoutId$ = this.route.params.pipe(
      map((params: any) => params.workoutId)
    );

    if (isPlatformBrowser(this.platformId)) {
      this.isPWA = (window.matchMedia('(display-mode: standalone)').matches) || ((window.navigator as any).standalone) || document.referrer.includes('android-app://');
    }

    this.sessionId$ = this.route.params.pipe(
      map((params: any) => params.sessionId)
    );

    this.session$ = combineLatest([this.auth.authUser$, this.sessionId$]).pipe(
      switchMap(([user, sessionId]) =>
        user.uid && sessionId
          ? this.workoutService.getUserSession$(user.uid, sessionId)
          : of(null)
      )
    );
  }
}
