import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ShopService } from '@sami/features';
import { SamiSeminar, SeminarTicketType } from 'interfaces';
import { ShareModule } from 'ngx-sharebuttons';
import { TicketTypeDialogComponent } from '../ticket-type-dialog/ticket-type-dialog.component';

declare var Calendly: any;
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-seminar-sidenav',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ShareModule,
    MatDialogModule,
    TicketTypeDialogComponent,
  ],
  templateUrl: './seminar-sidenav.component.html'
})
export class SeminarSidenavComponent implements OnChanges, OnInit {
  @Input() seminar: SamiSeminar | undefined;
  @Input() background: 'white' | 'culturedWhite' = 'white';
  @Input() sticky = true;
  @Input() showPayment = true;
  @Input() showCalendly = false;

  selectedPrice: string | undefined;
  totalTicketsAvailable!: number;
  ticketTypesForSale!: any[];
  isPromoActive: boolean;

  constructor(
    private shop: ShopService,
    private router: Router,
    private dialog: MatDialog
  ) {
    // Set the end date of the promotion
    const endDate = new Date('2023-11-26T23:50:00');

    // Get the current date
    const currentDate = new Date();

    // Check if current date is before the end date
    this.isPromoActive = currentDate < endDate;
  }

  ngOnInit(): void {
    if (this.showCalendly) {
      this.loadCalendly();
    }
  }

  ngOnChanges(): void {
    const now = Date.now();

    this.ticketTypesForSale =
      this.seminar?.ticketTypes?.filter(
        (tt: any) =>
          tt.isActive &&
          tt.saleStartsAt.toDate().getTime() <= now &&
          tt.saleEndsAt.toDate().getTime() >= now
      ) ?? [];

    this.selectedPrice = this.ticketTypesForSale[0]?.stripePriceId;

    const totalTicketLimit = [-1, undefined].includes(this.seminar?.ticketLimit) ? 99999 : this.seminar?.ticketLimit ?? 99999;
    const totalTicketsSold = this.seminar?.ticketTypes?.reduce((acc, cur) => acc += (cur.sold || 0), 0) ?? 0;
    const totalTicketsAvailable = totalTicketLimit - totalTicketsSold;

    const ticketTypesTicketLimit = this.ticketTypesForSale.reduce(
      (acc: any, cur: any) => (cur.limit === -1 ? acc += 99999 : (acc += cur.limit - (cur.sold || 0))),
      0
    );

    this.totalTicketsAvailable = Math.min(totalTicketsAvailable, ticketTypesTicketLimit);
  }

  getLanguage(language: any): any {
    const languages: any = {
      EN: 'English',
      DE: 'German',
      CS: 'Czech',
      IT: 'Italian',
      ES: 'Spanish',
      FR: 'French',
    };
    return languages[language];
  }

  async addToCart(productId: string, priceId?: string) {
    try {
      const cartSessionId: string = await this.shop.createCartSession(null, productId, priceId || null, 'ticket');
      this.router.navigateByUrl('/en/checkout/' + cartSessionId);
    } catch (error) {
      console.error(error);
    }
  }

  loadCalendly() {
    if (!document.getElementById('sami-x-calendly')) {
      // One way of doing this: dynamically load a script tag.
      const calendlyScript = document.createElement('script');
      calendlyScript.setAttribute('async', '');
      calendlyScript.id = 'sami-x-calendly';
      calendlyScript.src = 'https://assets.calendly.com/assets/external/widget.js';
      document.head.appendChild(calendlyScript);

      const calendlyLink = document.createElement('link');
      calendlyLink.rel = 'stylesheet';
      calendlyLink.href = 'https://assets.calendly.com/assets/external/widget.css';
      document.head.appendChild(calendlyLink);
    }
  }

  openTicketTypeDialog(ticket: SeminarTicketType) {
    this.dialog.open(TicketTypeDialogComponent, {
      data: {
        ticket
      },
      width: '538px',
      height: 'auto',
      panelClass: 'ticket-type-dialog'
    });
  }

  isWithinNext30Days(dateToCheck: Date): boolean {
    const today = new Date();
    const thirtyDaysLater = new Date(today);
    thirtyDaysLater.setDate(thirtyDaysLater.getDate() + 30);

    return dateToCheck < thirtyDaysLater;
  }

  openCalendly() {
    Calendly.initPopupWidget({url: 'https://calendly.com/sami-x/personal-course-selection-session?background_color=ecefef&primary_color=091f2c'})
  }
}
