<div class="flex flex-col justify-start">
  <button mat-menu-item>
    <div
      (click)="
        assignedToWatchLater.length > 0
          ? onRemoveClipFromPlaylist('watchLater')
          : onAssignClipToPlaylist('watchLater')
      "
      class="font-sans flex flex-row justify-start space-x-4 items-center text-base text-gray-800"
    >
      <svg
        class="w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 48 48"
      >
        <g transform="matrix(2,0,0,2,0,0)">
          <path
            stroke="currentColor"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
          ></path>
          <path
            stroke="currentColor"
            stroke-linejoin="round"
            stroke-width="2"
            d="M16.889 12H12V5.88904"
          ></path>
        </g>
      </svg>
      <span>
        {{
          assignedToWatchLater.length > 0
            ? 'Remove from Watch Later'
            : 'Add to Watch Later'
        }}
      </span>
    </div>
  </button>

  <button mat-menu-item>
    <div
      (click)="
        assignedToFavorites.length > 0
          ? onRemoveClipFromPlaylist('favorites')
          : onAssignClipToPlaylist('favorites')
      "
      class="font-sans flex flex-row justify-start space-x-4 items-center text-base text-gray-800"
    >
      <svg
        class="w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 48 48"
      >
        <g transform="matrix(2,0,0,2,0,0)">
          <path
            stroke="currentColor"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 7.725C12.025 -0.721998 23.022 0.577001 23 9C22.983 15.875 12 22 12 22C12 22 1.08001 15.875 1.00001 9C0.900007 0.579002 11.889 -0.721998 12 7.725Z"
          ></path>
        </g>
      </svg>
      <span>
        {{
          assignedToFavorites.length > 0
            ? 'Remove from Favorites'
            : 'Add to Favorites'
        }}
      </span>
    </div>
  </button>

  <button
    mat-menu-item
    *ngIf="playlistsWithoutDefaults.length > 0"
    [matMenuTriggerFor]="unassignedPlaylists"
  >
    <div
      class="font-sans flex flex-row justify-start space-x-4 items-center text-base text-gray-800"
    >
      <svg
        class="w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 48 48"
      >
        <g transform="matrix(2,0,0,2,0,0)">
          <path
            fill="currentColor"
            d="M20 7H11.75L10.1 4.8C9.91333 4.55201 9.67168 4.35069 9.39406 4.21188C9.11643 4.07307 8.81039 4.00054 8.5 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V19C2 19.5304 2.21071 20.0391 2.58579 20.4142C2.96086 20.7893 3.46957 21 4 21H20C20.5304 21 21.0391 20.7893 21.4142 20.4142C21.7893 20.0391 22 19.5304 22 19V9C22 8.46957 21.7893 7.96086 21.4142 7.58579C21.0391 7.21071 20.5304 7 20 7V7ZM4 19V6H8.5L10.15 8.2C10.3367 8.44799 10.5783 8.64931 10.8559 8.78812C11.1336 8.92693 11.4396 8.99946 11.75 9H20V19H4Z"
          ></path>
        </g>
      </svg>
      <span> Add to Collection </span>
    </div>
  </button>

  <button
    mat-menu-item
    *ngIf="assignedPlaylistsWithoutDefaults.length > 0"
    [matMenuTriggerFor]="assignedPlaylists"
  >
    <div
      class="font-sans flex flex-row justify-start space-x-4 items-center text-base text-gray-800"
    >
      <svg
        class="w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 48 48"
      >
        <g transform="matrix(2,0,0,2,0,0)">
          <path
            fill="currentColor"
            d="M20 7H11.75L10.1 4.8C9.91333 4.55201 9.67168 4.35069 9.39406 4.21188C9.11643 4.07307 8.81039 4.00054 8.5 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V19C2 19.5304 2.21071 20.0391 2.58579 20.4142C2.96086 20.7893 3.46957 21 4 21H20C20.5304 21 21.0391 20.7893 21.4142 20.4142C21.7893 20.0391 22 19.5304 22 19V9C22 8.46957 21.7893 7.96086 21.4142 7.58579C21.0391 7.21071 20.5304 7 20 7V7ZM4 19V6H8.5L10.15 8.2C10.3367 8.44799 10.5783 8.64931 10.8559 8.78812C11.1336 8.92693 11.4396 8.99946 11.75 9H20V19H4Z"
          ></path>
        </g>
      </svg>

      <span> Remove from Collection </span>
    </div>
  </button>

  <button mat-menu-item [matMenuTriggerFor]="newPlaylist">
    <div
      class="font-sans flex flex-row justify-start space-x-4 items-center text-base text-gray-800"
    >
      <svg
        class="w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 48 48"
      >
        <g transform="matrix(2,0,0,2,0,0)">
          <path
            fill="currentColor"
            d="M13 10H11V13H8V15H11V18H13V15H16V13H13V10Z"
          ></path>
          <path
            fill="currentColor"
            d="M20 7H11.75L10.1 4.8C9.91322 4.55212 9.67156 4.35089 9.39395 4.21209C9.11635 4.07329 8.81037 4.00069 8.5 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V19C2 19.5304 2.21071 20.0391 2.58579 20.4142C2.96086 20.7893 3.46957 21 4 21H20C20.5304 21 21.0391 20.7893 21.4142 20.4142C21.7893 20.0391 22 19.5304 22 19V9C22 8.46957 21.7893 7.96086 21.4142 7.58579C21.0391 7.21071 20.5304 7 20 7V7ZM4 19V6H8.5L10.15 8.2C10.3367 8.44799 10.5783 8.64931 10.8559 8.78812C11.1336 8.92693 11.4396 8.99946 11.75 9H20V19H4Z"
          ></path>
        </g>
      </svg>
      <span> Create new Collection </span>
    </div>
  </button>
</div>

<mat-menu #unassignedPlaylists="matMenu">
  <div class="flex flex-col justify-start">
    <button
      mat-menu-item
      *ngFor="let playlist of playlistsWithoutDefaults"
      (click)="onAssignClipToPlaylist(playlist._id)"
    >
      <div
        class="font-sans flex flex-row space-x-4 justify-start items-center text-base text-gray-800"
      >
        <svg
          class="w-4 h-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 48 48"
        >
          <g transform="matrix(2,0,0,2,0,0)">
            <path
              fill="currentColor"
              d="M20 7H11.75L10.1 4.8C9.91333 4.55201 9.67168 4.35069 9.39406 4.21188C9.11643 4.07307 8.81039 4.00054 8.5 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V19C2 19.5304 2.21071 20.0391 2.58579 20.4142C2.96086 20.7893 3.46957 21 4 21H20C20.5304 21 21.0391 20.7893 21.4142 20.4142C21.7893 20.0391 22 19.5304 22 19V9C22 8.46957 21.7893 7.96086 21.4142 7.58579C21.0391 7.21071 20.5304 7 20 7V7ZM4 19V6H8.5L10.15 8.2C10.3367 8.44799 10.5783 8.64931 10.8559 8.78812C11.1336 8.92693 11.4396 8.99946 11.75 9H20V19H4Z"
            ></path>
          </g>
        </svg>
        <span>
          {{ playlist.title }}
        </span>
      </div>
    </button>
  </div>
</mat-menu>

<mat-menu #assignedPlaylists="matMenu">
  <div class="flex flex-col justify-start">
    <button
      mat-menu-item
      *ngFor="let playlist of assignedPlaylistsWithoutDefaults"
      (click)="onRemoveClipFromPlaylist(playlist._id)"
    >
      <div
        class="font-sans flex flex-row justify-start space-x-4 items-center text-base text-gray-800"
      >
        <svg
          class="w-4 h-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 48 48"
        >
          <g transform="matrix(2,0,0,2,0,0)">
            <path
              fill="currentColor"
              d="M20 7H11.75L10.1 4.8C9.91333 4.55201 9.67168 4.35069 9.39406 4.21188C9.11643 4.07307 8.81039 4.00054 8.5 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V19C2 19.5304 2.21071 20.0391 2.58579 20.4142C2.96086 20.7893 3.46957 21 4 21H20C20.5304 21 21.0391 20.7893 21.4142 20.4142C21.7893 20.0391 22 19.5304 22 19V9C22 8.46957 21.7893 7.96086 21.4142 7.58579C21.0391 7.21071 20.5304 7 20 7V7ZM4 19V6H8.5L10.15 8.2C10.3367 8.44799 10.5783 8.64931 10.8559 8.78812C11.1336 8.92693 11.4396 8.99946 11.75 9H20V19H4Z"
            ></path>
          </g>
        </svg>
        <span>
          {{ playlist.title }}
        </span>
      </div>
    </button>
  </div>
</mat-menu>

<mat-menu #newPlaylist="matMenu">
  <div class="flex flex-col justify-start px-3 py-2">
    <div class="flex flex-col justify-start" (click)="$event.stopPropagation()">
      <div class="w-full sm:max-w-xs">
        <label for="title" class="text-xs">Video Collection Name</label>
        <input
          type="text"
          name="title"
          id="title"
          #title
          class="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-none"
          placeholder="Video Collection Name"
        />
      </div>
      <button
        (click)="onCreateNewPlaylist(title.value)"
        [disabled]="title.value.length < 1"
        class="mt-2 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-none text-white bg-richBlack hover:bg-cadetGrey-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cadetGrey-800 text-sm"
      >
        Create
      </button>
    </div>
  </div>
</mat-menu>
