import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageCollectionButtonComponent } from './manage-collection-button.component';
import { MatMenuModule } from '@angular/material/menu';
import { CollectionMenuComponent } from './collection-menu/collection-menu.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarsModule } from '../snackbars/snackbars.module';

@NgModule({
  declarations: [ManageCollectionButtonComponent, CollectionMenuComponent],
  imports: [CommonModule, MatMenuModule, SnackbarsModule, MatSnackBarModule],
  exports: [ManageCollectionButtonComponent]
})
export class ManageCollectionButtonModule {}
