import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-youtube-player',
  template: `
    <div class="aspect-w-16 aspect-h-9">
      <ng-container *ngIf="consentGranted">
        <ng-container *ngIf="loaded">
          <youtube-player
            class="w-full h-full"
            *ngIf="videoId"
            [videoId]="videoId"
          ></youtube-player>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!consentGranted">
        <div
          class="w-full h-full bg-richBlack flex flex-col justify-center items-center space-y-2 px-4 py-2"
        >
          <span class="text-xl font-body text-white text-center">
            Please allow Cookies to display this video.
          </span>
          <button
            (click)="openCookiebot()"
            class="bg-culturedWhite px-4 py-1 text-base hover:bg-white"
          >
            Change Preferences
          </button>
        </div>
      </ng-container>
    </div>
  `,
})
export class YoutubePlayerComponent implements OnInit {
  @Input() videoId: string | null = null;
  consentGranted = false;
  loaded = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window && window.Cookiebot) {
        if (window.Cookiebot.hasResponse && window.Cookiebot.consent.marketing) {
          this.consentGranted = true;
          this.loadYoutube();
        }
      }
    }
  }

  loadYoutube() {
    if (this.loaded) {
      return;
    }

    const tag = document.createElement('script');
    tag.setAttribute('async', '');
    tag.id = 'sami-x-youtube';
    tag.src = 'https://www.youtube.com/iframe_api';
    tag.addEventListener('load', () => {
      this.loaded = true;
    });
    document.head.appendChild(tag);
  }

  openCookiebot() {
    if (isPlatformBrowser(this.platformId)) {
      if (window && window.Cookiebot) {
        window.Cookiebot.show();
      }
    }
  }
}
