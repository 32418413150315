<nav aria-label="Progress">
  <ol
    role="list"
    class="border-2 md:border-4 border-white bg-white rounded-none divide-y divide-white flex md:divide-y-0"
  >
    <ng-container *ngFor="let step of steps; let index = index">
      <li
        class="relative flex-1 flex"
        [ngClass]="currentStep === index ? 'bg-richBlack' : 'bg-white'"
      >
        <!-- Upcoming Step -->
        <div
          class="group flex items-center"
          [ngClass]="
            currentStep === index ? 'text-white' : 'text-cadetGrey-500'
          "
        >
          <span
            class="px-2 py-2 md:px-6 md:py-4 flex items-center text-sm font-medium"
          >
            <ng-container *ngIf="currentStep > index">
              <span
                class="flex-shrink-0 h-6 w-6 md:w-10 md:h-10 flex items-center justify-center bg-richBlack rounded-full"
              >
                <!-- Heroicon name: solid/check -->
                <svg
                  class="h-4 w-4 md:w-6 md:h-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
            </ng-container>
            <ng-container *ngIf="currentStep <= index">
              <span
                class="flex-shrink-0 hidden sm:flex h-6 w-6 md:w-10 md:h-10 items-center justify-center border-2 border-cadetGrey-5000 rounded-full"
              >
                <span class="font-body text-xxs md:text-sm"
                  >0{{ index + 1 }}</span
                >
              </span>
            </ng-container>
            <span
              class="ml-2 md:ml-4 text-xs md:text-sm font-medium font-body"
              >{{ step.title }}</span
            >
          </span>
        </div>
      </li>
    </ng-container>
  </ol>
</nav>
