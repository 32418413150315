import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
})
export class StatsComponent {
  @Input() type:
    | 'system'
    | 'category'
    | 'level'
    | 'duration'
    | 'views'
    | 'tracks'
    | 'trainings'
    | 'videosWatched'
    | 'year'
    | 'leads'
    | 'conversions'
    | 'revenue' = 'system';
  @Input() title = 'Krav Maga';
  @Input() value: string | number | any = '0';

  categories = {
    tracks: {
      title: 'Tracks Completed',
      icon: `
      <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30 60.5V0.5" stroke="#5D737E" stroke-width="4" stroke-linejoin="round"/>
        <path d="M10 8H47.5L55 15.5L47.5 23H10V8ZM50 48H12.5L5 40.5L12.5 33H50V48Z" fill="#EDF2EF" stroke="#5D737E" stroke-width="4" stroke-linejoin="round"/>
      </svg>
      `,
    },
    trainings: {
      title: 'Trainings Aced',
      icon: `
      <svg width="50" height="59" viewBox="0 0 50 59" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.9996 29.5V12.945C14.9996 11.5075 15.2828 10.084 15.833 8.75595C16.3832 7.42788 17.1896 6.2212 18.2062 5.20483C19.2228 4.18846 20.4297 3.38231 21.7579 2.83242C23.0861 2.28253 24.5096 1.99967 25.9471 2H36.5546C39.4572 2.00066 42.2407 3.15401 44.2932 5.20645C46.3456 7.25889 47.499 10.0424 47.4996 12.945V33.555C47.499 36.4576 46.3456 39.2411 44.2932 41.2936C42.2407 43.346 39.4572 44.4993 36.5546 44.5H15.9471C4.65962 44.5 2.40962 24.25 2.49962 24.5C2.49962 22.5109 3.2898 20.6032 4.69632 19.1967C6.10284 17.7902 8.0105 17 9.99962 17H14.9996M42.5021 42V57H15.0021V44.5" stroke="#5D737E" stroke-width="4" stroke-linejoin="round"/>
      </svg>
      `,
    },
    videosWatched: {
      title: 'Videos Watched',
      icon: `
      <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.5 23.9464L35.9689 30.5L24.5 37.0536V23.9464Z" stroke="#5D737E" stroke-width="4"/>
      <path d="M57.5 10.5H2.5V50.5H57.5V10.5Z" stroke="#5D737E" stroke-width="4" stroke-linejoin="round"/>
      </svg>

      `,
    },
    system: {
      title: 'System',
      icon: `
      <svg viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M32.875 27.6875L28.8125 10.6875C28.4375 9.125 27.0625 8 25.4375 8H21.8125C22.25 7.25 22.4375 6.4375 22.4375 5.5C22.5 2.5 20 0 17 0C13.9375 0 11.4375 2.5 11.4375 5.5C11.4375 6.4375 11.625 7.25 12.0625 8H8.5C6.875 8 5.5 9.125 5.125 10.6875L1.0625 27.6875C0.8125 28.75 1.0625 29.875 1.75 30.6875C2.375 31.5625 3.375 32 4.5 32H29.4375C30.5625 32 31.5625 31.5625 32.1875 30.6875C32.875 29.875 33.125 28.75 32.875 27.6875ZM14.4375 5.5C14.4375 4.125 15.5625 3 16.9375 3C18.3125 3 19.4375 4.125 19.4375 5.5C19.4375 6.875 18.375 8 17 8C15.625 8 14.4375 6.875 14.4375 5.5ZM29.875 28.8125C29.8125 28.9375 29.6875 29 29.4375 29H4.5C4.25 29 4.125 28.9375 4.0625 28.8125C4 28.75 3.9375 28.625 4 28.4375L8.0625 11.5C8.0625 11.1875 8.3125 11 8.5 11H25.375C25.5625 11 25.8125 11.1875 25.8125 11.4375L29.875 28.4375C30 28.625 29.9375 28.75 29.875 28.8125Z" fill="#001427"/>
      </svg>`,
    },
    year: {
      title: 'Year Joined',
      icon: `
      <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.00977 9.57098L15.3238 16.8851H8.00977V9.57098Z" fill="#5D737E" stroke="#5D737E" stroke-width="4"/>
      <path d="M28.9902 16.2574V31.2575H38.9902" fill="#EDF2EF"/>
      <path d="M28.9902 16.2574V31.2575H38.9902" stroke="#5D737E" stroke-width="4" stroke-linejoin="round"/>
      <path d="M11.1375 48.7498C14.7109 52.3975 19.3156 54.8635 24.3324 55.8163C29.3492 56.7688 34.5372 56.1623 39.199 54.0783C43.8607 51.9943 47.7728 48.5328 50.4085 44.159C53.044 39.7855 54.2773 34.7098 53.9423 29.6143C53.607 24.519 51.7197 19.6484 48.5337 15.6577C45.348 11.667 41.0165 8.74758 36.122 7.29208C31.2275 5.83658 26.0045 5.9148 21.1558 7.51625C16.307 9.11768 12.0649 12.1655 9 16.2499" stroke="#5D737E" stroke-width="4" stroke-linejoin="round"/>
      </svg>
    `,
    },
    category: {
      title: 'Category',
      icon: `
      <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 3C0 1.375 1.3125 0 3 0H9C10.625 0 12 1.375 12 3V9C12 10.6875 10.625 12 9 12H3C1.3125 12 0 10.6875 0 9V3ZM3 9H9V3H3V9ZM0 19C0 17.375 1.3125 16 3 16H9C10.625 16 12 17.375 12 19V25C12 26.6875 10.625 28 9 28H3C1.3125 28 0 26.6875 0 25V19ZM3 25H9V19H3V25ZM25 0C26.625 0 28 1.375 28 3V9C28 10.6875 26.625 12 25 12H19C17.3125 12 16 10.6875 16 9V3C16 1.375 17.3125 0 19 0H25ZM25 3H19V9H25V3ZM16 19C16 17.375 17.3125 16 19 16H25C26.625 16 28 17.375 28 19V25C28 26.6875 26.625 28 25 28H19C17.3125 28 16 26.6875 16 25V19ZM19 25H25V19H19V25Z" fill="#001427"/>
      </svg>
      `,
    },
    level: {
      title: 'Level',
      icon: `
      <svg viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25 0H21C19.875 0 19 0.9375 19 2V22C19 23.125 19.875 24 21 24H25C26.0625 24 27 23.125 27 22V2C27 0.9375 26.0625 0 25 0ZM24 21H22V3H24V21ZM6 16H2C0.875 16 0 16.9375 0 18V22C0 23.125 0.875 24 2 24H6C7.0625 24 8 23.125 8 22V18C8 16.9375 7.0625 16 6 16ZM5 21H3V19H5V21ZM15.5 8H11.5C10.375 8 9.5 8.9375 9.5 10V22C9.5 23.125 10.375 24 11.5 24H15.5C16.5625 24 17.5 23.125 17.5 22V10C17.5 8.9375 16.5625 8 15.5 8ZM14.5 21H12.5V11H14.5V21Z" fill="#001427"/>
      </svg>
      `,
    },
    duration: {
      title: 'Duration',
      icon: `
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.5 7.5C14.5 6.6875 15.125 6 16 6C16.8125 6 17.5 6.6875 17.5 7.5V15.25L22.8125 18.75C23.5 19.25 23.6875 20.1875 23.1875 20.875C22.75 21.5625 21.8125 21.75 21.125 21.25L15.125 17.25C14.75 17 14.5 16.5 14.5 16V7.5ZM16 0C24.8125 0 32 7.1875 32 16C32 24.875 24.8125 32 16 32C7.125 32 0 24.875 0 16C0 7.1875 7.125 0 16 0ZM3 16C3 23.1875 8.8125 29 16 29C23.125 29 29 23.1875 29 16C29 8.875 23.125 3 16 3C8.8125 3 3 8.875 3 16Z" fill="#001427"/>
      </svg>
      `,
    },
    views: {
      title: 'Views',
      icon: `
      <svg class="w-full h-full" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M35.75 12.9375C32.375 5.25 25.625 0 18 0C10.3125 0 3.5625 5.25 0.1875 12.9375C0.0625 13.25 0 13.6875 0 14C0 14.3125 0.0625 14.8125 0.1875 15.125C3.5625 22.8125 10.3125 28 18 28C25.625 28 32.375 22.8125 35.75 15.125C35.875 14.8125 36 14.3125 36 14C36 13.6875 35.875 13.25 35.75 12.9375ZM18 25C11.75 25 6 20.8125 3 14.0625C6.0625 7.25 11.75 3 18 3C24.1875 3 29.9375 7.25 32.9375 14C29.875 20.8125 24.1875 25 18 25ZM18 6C13.5625 6 10 9.625 10 14C10 18.4375 13.5625 22 18 22C22.375 22 26 18.4375 26 14.0625C26 9.625 22.375 6 18 6ZM18 19C15.1875 19 13 16.8125 13 14C13 14 13 13.9375 13 13.875C13.3125 14 13.625 14 14 14C16.1875 14 18 12.25 18 10C18 9.6875 17.9375 9.375 17.8125 9.0625C17.875 9.0625 17.9375 9 18 9C20.75 9 23 11.25 23 14.0625C23 16.8125 20.75 19 18 19Z" fill="#001427"/>
      </svg>
      `,
    },
    leads: {
      title: 'Leads',
      icon: `
      <svg class="w-full h-full" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="48" width="48"><path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M21 21L8 15V7L21 1V21Z"></path><path fill="currentColor" d="M22 6.53699V15.463C22.629 14.902 23.1324 14.2144 23.477 13.4453C23.8216 12.6761 23.9998 11.8428 23.9998 11C23.9998 10.1572 23.8216 9.32385 23.477 8.55471C23.1324 7.78557 22.629 7.09796 22 6.53699V6.53699Z"></path><path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M8 15V23"></path><path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M1 11C1 12.0609 1.42143 13.0783 2.17157 13.8284C2.92172 14.5786 3.93913 15 5 15H8V7H5C3.93913 7 2.92172 7.42143 2.17157 8.17157C1.42143 8.92172 1 9.93913 1 11V11Z"></path></svg>
      `,
    },
    conversions: {
      title: 'Conversions',
      icon: `
      <svg class="w-full h-full" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="48" width="48"><path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M14 3.458C11.8673 2.78899 9.57177 2.85456 7.48069 3.64422C5.38961 4.43389 3.62376 5.90201 2.4656 7.81377C1.30743 9.72554 0.823866 11.9705 1.09225 14.1895C1.36062 16.4086 2.36544 18.4735 3.94598 20.054C5.52651 21.6346 7.59144 22.6394 9.81049 22.9078C12.0295 23.1761 14.2745 22.6926 16.1862 21.5344C18.098 20.3763 19.5661 18.6104 20.3558 16.5193C21.1454 14.4282 21.211 12.1327 20.542 10"></path><path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M12 8.1C10.9484 7.8864 9.85589 8.01722 8.88442 8.47309C7.91295 8.92897 7.11416 9.68564 6.60639 10.631C6.09862 11.5764 5.90887 12.6602 6.06525 13.7218C6.22164 14.7835 6.71584 15.7666 7.47465 16.5254C8.23345 17.2842 9.2165 17.7784 10.2782 17.9347C11.3398 18.0911 12.4236 17.9014 13.369 17.3936C14.3144 16.8858 15.071 16.0871 15.5269 15.1156C15.9828 14.1441 16.1136 13.0516 15.9 12"></path><path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M18.343 5.657L11 13"></path><path fill="currentColor" d="M20.464 7.778L16.929 7.071L16.222 3.536L19.757 0L20.464 3.536L24 4.243L20.464 7.778Z"></path></svg>
      `,
    },
    revenue: {
      title: 'Unpaid commission',
      icon: `
      <svg class="w-full h-full" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="48" width="48"><path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M19 4H1V16H19V4Z"></path><path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"></path><path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M23 4V20H3"></path></svg>
      `,
    },
  };

  constructor(private sanitizer: DomSanitizer) {}

  returnSVG(svg: string) {
    return this.sanitizer.bypassSecurityTrustHtml(svg);
  }
}
