import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadCardComponent } from './download-card.component';
import { GetDownloadURLPipeModule } from '@angular/fire/compat/storage';

@NgModule({
  declarations: [DownloadCardComponent],
  imports: [CommonModule, GetDownloadURLPipeModule],
  exports: [DownloadCardComponent],
})
export class DownloadCardModule {}
