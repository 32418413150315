import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { GeoPoint } from '@angular/fire/firestore';
import { FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { MapGeocoder } from '@angular/google-maps';
import { SamiPublicProfile } from 'interfaces';
import { MapService, UiService } from '@sami/features';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
})
export class ContactComponent implements OnChanges {
  @ViewChild('googleAutocomplete', { static: false }) googleAutocomplete:
    | ElementRef
    | undefined;
  @Input() instructor: SamiPublicProfile | undefined;
  isBusy$ = new BehaviorSubject<boolean>(false);

  form = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    address: [''],
    phoneNumber: [''],
    website: [''],
    email: ['', Validators.required],
    geolocation: [null],
    addressComponents: [null],
    place_id: [null],
    dateOfBirth: [''],
  });

  compareWith = (o1: { code: any }, o2: { code: any }) =>
    o1 && o2 ? o1.code === o2.code : o1 === o2;

  constructor(
    private fb: FormBuilder,
    private afs: AngularFirestore,
    public ui: UiService,
    private geocoder: MapGeocoder,
    private mapService: MapService
  ) {
    const url =
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyBM0thRrffEBb2fO-EIB7o_KShPhYDf0lg&libraries=places&callback=Function.prototype';
    this.mapService.loadScript(url, 'sami-x-google-maps');
  }

  ngOnChanges(): void {
    if (this.instructor) {
      this.form.patchValue({
        firstName: this.instructor.firstName,
        lastName: this.instructor.lastName,
        address: this.instructor.address,
        dateOfBirth: this.instructor.dateOfBirth,
        phoneNumber: this.instructor.phoneNumber,
        website: this.instructor.website,
        email: this.instructor.email,
        addressComponents: this.instructor.addressComponents || null,
        place_id: this.instructor.place_id || null,
        geolocation: this.instructor.geolocation,
      });

      if (this.instructor.dateOfBirth) {
        this.form.controls.dateOfBirth.patchValue(
          this.formatDate(this.instructor.dateOfBirth.toDate())
        );
      }
    }
  }

  async submit() {
    if (this.form.valid) {
      this.isBusy$.next(true);
      const dob = this.form.get('dateOfBirth')?.value;
      try {
        const x = await this.afs
          .doc(
            `users/${this.instructor?.id}/publicProfiles/${this.instructor?.id}`
          )
          .set(
            {
              firstName: this.form.get('firstName')?.value,
              lastName: this.form.get('lastName')?.value,
              dateOfBirth: dob ? new Date(dob) : null,
              address: this.form.get('address')?.value || null,
              phoneNumber: this.form.get('phoneNumber')?.value || null,
              website: this.form.get('website')?.value || null,
              email: this.form.get('email')?.value,
              geolocation: this.form.get('geolocation')?.value || null,
              addressComponents:
                this.form.get('addressComponents')?.value || null,
              place_id: this.form.get('place_id')?.value || null,
            },
            { merge: true }
          );
        this.isBusy$.next(false);
      } catch (error) {
        console.error(error);
        this.isBusy$.next(false);
      }
    }
  }

  private formatDate(date: any) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  async updateGooglePlaces() {
    if (this.form) {
      const addr = this.form.get('address')?.value;
      if (addr) {
        this.geocoder.geocode({ address: addr }).subscribe((res: any) => {
          const response = res.results;
          if (response.length >= 1) {
            this.patchAddress(response[0]);
          }
        });
      }
    }
  }

  patchAddress(place: any) {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    const geolocation: any = { lat, lng, point: new GeoPoint(lat, lng) };

    if (this.form) {
      this.form.get('address')?.patchValue(place.formatted_address, 'route');
      this.form.get('geolocation')?.patchValue(geolocation, 'route');
      this.form
        .get('addressComponents')
        ?.patchValue(place.address_components, 'route');
      this.form.get('place_id')?.patchValue(place.place_id, 'route');
    }
  }
}
