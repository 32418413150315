import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService, LocationService } from '@sami/features';
import { SamiSubscription, SamiSubscriptionDigest, SamiUser } from 'interfaces';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-subscription-status',
  templateUrl: './subscription-status.component.html',
  standalone: true,
  imports: [ CommonModule ]
})
export class SubscriptionStatusComponent implements OnInit {
  @Input() id: string | null = null;
  subscriptions$: Observable<SamiSubscriptionDigest | undefined> | undefined = new Observable(undefined);
  user$: Observable<SamiUser | undefined> | undefined = new Observable(undefined);

  constructor(
    private afs: AngularFirestore,
    public location: LocationService,
    public auth: AuthService
  ) {
  }

  ngOnInit(): void {
    if (this.id) {
      this.subscriptions$ = this.afs
        .doc<SamiSubscription>(`users/${this.id}/subscriptions/digest`)
        .valueChanges({ idField: '_id' });
    }
  }

  checkIfActiveMembership(subscriptions: any) {
    if (
      subscriptions.some((sub: { status: string }) => sub.status == 'active')
    ) {
      return true;
    } else {
      return false;
    }
  }
}
