import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  Output,
  PLATFORM_ID,
} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[onVisible]',
  standalone: true,
})
export class OnVisibleDirective implements AfterViewInit, OnDestroy {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onVisible: EventEmitter<any> = new EventEmitter();

  private _intersectionObserver?: IntersectionObserver;

  constructor(
    private _element: ElementRef,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  public ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this._intersectionObserver = new IntersectionObserver((entries) => {
        this.checkForIntersection(entries);
      }, {});
      this._intersectionObserver.observe(<Element>this._element.nativeElement);
    }
  }

  public ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      if (this._intersectionObserver) {
        this._intersectionObserver.disconnect();
      }
    }
  }

  private checkForIntersection = (
    entries: Array<IntersectionObserverEntry>
  ) => {
    if (isPlatformBrowser(this.platformId)) {
      entries.forEach((entry: IntersectionObserverEntry) => {
        const isIntersecting =
          (<any>entry).isIntersecting &&
          entry.target === this._element.nativeElement;

        if (isIntersecting) {
          this.onVisible.emit();
        }
      });
    }
  };
}
