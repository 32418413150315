<button
  (click)="visitGuidedTraining(guidedTraining)"
  [ngClass]="isAvailable ? 'cursor-pointer' : 'cursor-not-allowed'"
  class="bg-richBlack group h-full flex flex-col hover:shadow-lg justify-start w-full"
  *ngIf="guidedTraining; else loading"
>
  <div class="bg-richBlack w-full relative">
    <ng-container *ngIf="!isAvailable">
      <div
        class="absolute inset-0 w-full h-full bg-richBlack z-20 bg-opacity-75"
      >
        <div
          class="w-full h-full flex flex-col justify-center space-y-1 items-center"
        >
          <span class="uppercase text-white text-2xl font-semibold"
            >Not Available</span
          >
          <span class="text-sm font-semibold font-body text-white max-w-xs">
            Only guided trainings from this week are currently available.
          </span>
        </div>
      </div>
    </ng-container>
    <div class="aspect-w-16 aspect-h-9"></div>
    <div
      *ngIf="guidedTraining.muxVideoElement.asset?.playback_ids?.[0]?.id"
    >
      <img
        [src]="
          'https://image.mux.com/' +
          guidedTraining.muxVideoElement.asset.playback_ids[0].id +
          '/animated.gif?width=360&fps=5&start=40'
        "
        [alt]="guidedTraining.title"
        class="hidden group-hover:block object-cover w-full h-full absolute inset-0"
      />
      <img
        [src]="
          'https://image.mux.com/' +
          guidedTraining.muxVideoElement.asset.playback_ids[0].id +
          '/thumbnail.png?width=400&height=226&fit_mode=smartcrop'
        "
        [alt]="guidedTraining.title"
        class="block group-hover:hidden object-cover w-full h-full absolute inset-0"
      />
    </div>
    <div
      class="bottom-3 left-3 absolute"
      *ngIf="
        guidedTraining &&
        guidedTraining.system &&
        guidedTraining.system.identifier
      "
    >
      <sami-combat-system-tag [combatSystem]="guidedTraining.system.identifier" *ngIf="guidedTraining.system.identifier"></sami-combat-system-tag>
    </div>

    <div
      class="bottom-3 right-3 absolute"
      *ngIf="guidedTraining.muxVideoElement"
    >
      <span
        class="bg-richBlack text-xs font-sans uppercase py-1 px-2 text-culturedWhite cursor-default"
      >
        {{ guidedTraining.muxVideoElement.asset.duration * 1000 | date : 'mm:ss'}}
      </span>
    </div>
  </div>
  <div class="flex flex-col flex-1 justify-start w-full">
    <div class="p-3 w-full flex flex-col justify-start space-y-3 flex-1">
      <div class="flex flex-row justify-start">
        <sami-membership-badge [isFree]="guidedTraining.isFree || false" *ngIf="!guidedTraining.hasPurchased"></sami-membership-badge>
        <sami-purchased-badge *ngIf="guidedTraining.hasPurchased"></sami-purchased-badge>
      </div>
      <h4 class="text-white text-xl font-sans font-semibold text-left tracking-tight">
        {{ guidedTraining.title }}
      </h4>
      <p
        class="text-cadetGrey-400 text-sm font-body line-clamp-3 text-left"
        [innerHtml]="guidedTraining.description"
      ></p>
    </div>
    <div
      class="p-3 bg-culturedWhite/10 w-full flex flex-row justify-between items-center"
    >
      <div
        *ngIf="guidedTraining.instructor"
        class="flex flex-row justify-start items-center space-x-2"
      >
        <img
          class="inline-block h-6 w-6 rounded-full"
          [src]="guidedTraining.instructor.photoURL | getDownloadURL"
          [alt]="guidedTraining.instructor.displayName"
        />
        <span class="text-sm font-sans font-semibold whitespace-nowrap text-white">
          {{ guidedTraining.instructor.displayName }}
        </span>
      </div>

      <div
        *ngIf="guidedTraining.numberOfParticipants"
        class="flex flex-row text-white justify-center items-center space-x-2"
      >
        <ng-container
          *ngIf="
            guidedTraining.numberOfParticipants === 'single';
            else partnerIcon
          "
        >
          <svg
            class="w-5 h-5"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.7 2.7C11.7 2.16599 11.5416 1.64397 11.245 1.19996C10.9483 0.755945 10.5266 0.409886 10.0332 0.205523C9.53988 0.00116869 8.997 -0.0523002 8.47326 0.0518838C7.94951 0.156059 7.46842 0.413207 7.09081 0.790811C6.71321 1.16841 6.45606 1.64951 6.35189 2.17326C6.2477 2.697 6.30117 3.23988 6.50552 3.73324C6.70989 4.22661 7.05595 4.64829 7.49996 4.94497C7.94398 5.24164 8.46599 5.4 9 5.4C9.71608 5.4 10.4028 5.11554 10.9092 4.60919C11.4155 4.10284 11.7 3.41608 11.7 2.7ZM9 3.6C8.822 3.6 8.64799 3.54721 8.49999 3.44832C8.35198 3.34943 8.23663 3.20887 8.16851 3.04441C8.10039 2.87996 8.08257 2.699 8.1173 2.52442C8.15202 2.34984 8.23774 2.18947 8.3636 2.0636C8.48947 1.93773 8.64984 1.85202 8.82442 1.8173C8.999 1.78257 9.17996 1.80039 9.34441 1.86851C9.50887 1.93663 9.64943 2.05198 9.74832 2.19999C9.84721 2.34799 9.9 2.522 9.9 2.7C9.9 2.9387 9.80518 3.16761 9.6364 3.3364C9.46761 3.50517 9.2387 3.6 9 3.6Z"
              fill="#EDF2EF"
            />
            <path
              d="M9 5.4C7.80696 5.40143 6.6632 5.87599 5.8196 6.71958C4.97599 7.56324 4.50143 8.70696 4.5 9.9V12.6C4.5 12.8387 4.59482 13.0676 4.7636 13.2364C4.93239 13.4051 5.1613 13.5 5.4 13.5H6.3V18H8.1V12.6C8.1 12.3613 8.00518 12.1324 7.8364 11.9636C7.66761 11.7949 7.4387 11.7 7.2 11.7H6.3V9.9C6.3 9.18396 6.58446 8.49717 7.09081 7.99083C7.59716 7.48449 8.28391 7.2 9 7.2C9.71608 7.2 10.4028 7.48449 10.9092 7.99083C11.4155 8.49717 11.7 9.18396 11.7 9.9V11.7H10.8C10.5613 11.7 10.3324 11.7949 10.1636 11.9636C9.99482 12.1324 9.9 12.3613 9.9 12.6V18H11.7V13.5H12.6C12.8387 13.5 13.0676 13.4051 13.2364 13.2364C13.4051 13.0676 13.5 12.8387 13.5 12.6V9.9C13.4986 8.70696 13.024 7.56324 12.1804 6.71958C11.3368 5.87599 10.193 5.40143 9 5.4Z"
              fill="#EDF2EF"
            />
          </svg>
        </ng-container>
        <ng-template #partnerIcon>
          <svg
            class="w-5 h-5"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.2 2.7C7.2 2.16599 7.04164 1.64397 6.74497 1.19996C6.44829 0.755945 6.02661 0.409886 5.53325 0.205523C5.03988 0.00116869 4.497 -0.0523002 3.97326 0.0518838C3.44951 0.156059 2.96842 0.413207 2.59081 0.790811C2.21321 1.16841 1.95606 1.64951 1.85189 2.17326C1.7477 2.697 1.80117 3.23988 2.00552 3.73324C2.20989 4.22661 2.55595 4.64829 2.99996 4.94497C3.44398 5.24164 3.96599 5.4 4.5 5.4C5.21608 5.4 5.90284 5.11554 6.40919 4.60919C6.91554 4.10284 7.2 3.41608 7.2 2.7ZM4.5 3.6C4.322 3.6 4.14799 3.54721 3.99999 3.44832C3.85198 3.34943 3.73663 3.20887 3.66851 3.04441C3.60039 2.87996 3.58257 2.699 3.6173 2.52442C3.65202 2.34984 3.73774 2.18947 3.8636 2.0636C3.98947 1.93773 4.14984 1.85202 4.32442 1.8173C4.499 1.78257 4.67996 1.80039 4.84441 1.86851C5.00887 1.93663 5.14943 2.05198 5.24832 2.19999C5.34721 2.34799 5.4 2.522 5.4 2.7C5.4 2.9387 5.30518 3.16761 5.1364 3.3364C4.96761 3.50518 4.7387 3.6 4.5 3.6Z"
              fill="currentColor"
            />
            <path
              d="M4.5 5.4C3.30696 5.40143 2.1632 5.87599 1.3196 6.71958C0.475992 7.56324 0.001431 8.70696 0 9.9V12.6C0 12.8387 0.0948241 13.0676 0.263601 13.2364C0.432387 13.4051 0.661302 13.5 0.9 13.5H1.8V18H3.6V12.6C3.6 12.3613 3.50518 12.1324 3.3364 11.9636C3.16761 11.7949 2.9387 11.7 2.7 11.7H1.8V9.9C1.8 9.18396 2.08446 8.49717 2.59081 7.99083C3.09716 7.48449 3.78391 7.2 4.5 7.2C5.21608 7.2 5.90284 7.48449 6.40919 7.99083C6.91554 8.49717 7.2 9.18396 7.2 9.9V11.7H6.3C6.0613 11.7 5.83239 11.7949 5.6636 11.9636C5.49482 12.1324 5.4 12.3613 5.4 12.6V18H7.2V13.5H8.1C8.33868 13.5 8.56764 13.4051 8.73639 13.2364C8.90514 13.0676 9 12.8387 9 12.6V9.9C8.99856 8.70696 8.52399 7.56324 7.68042 6.71958C6.8368 5.87599 5.69304 5.40143 4.5 5.4Z"
              fill="currentColor"
            />
            <path
              d="M16.2 2.7C16.2 2.16599 16.0417 1.64397 15.745 1.19996C15.4483 0.755945 15.0266 0.409886 14.5332 0.205523C14.0399 0.00116869 13.497 -0.0523002 12.9732 0.0518838C12.4495 0.156059 11.9684 0.413207 11.5908 0.790811C11.2132 1.16841 10.9561 1.64951 10.8518 2.17326C10.7477 2.697 10.8012 3.23988 11.0056 3.73324C11.2099 4.22661 11.5559 4.64829 12 4.94497C12.4439 5.24164 12.966 5.4 13.5 5.4C14.216 5.4 14.9028 5.11554 15.4092 4.60919C15.9155 4.10284 16.2 3.41608 16.2 2.7ZM13.5 3.6C13.322 3.6 13.148 3.54721 13 3.44832C12.852 3.34943 12.7366 3.20887 12.6685 3.04441C12.6004 2.87996 12.5825 2.699 12.6173 2.52442C12.652 2.34984 12.7377 2.18947 12.8636 2.0636C12.9894 1.93773 13.1498 1.85202 13.3244 1.8173C13.499 1.78257 13.68 1.80039 13.8444 1.86851C14.0089 1.93663 14.1494 2.05198 14.2483 2.19999C14.3473 2.34799 14.4 2.522 14.4 2.7C14.4 2.9387 14.3051 3.16761 14.1364 3.3364C13.9676 3.50518 13.7387 3.6 13.5 3.6Z"
              fill="currentColor"
            />
            <path
              d="M13.5 5.4C12.7593 5.39989 12.0301 5.5826 11.377 5.93193C10.7239 6.28124 10.167 6.78636 9.756 7.4025L11.25 8.4024C11.5708 7.91865 12.039 7.55127 12.5851 7.35462C13.1314 7.15788 13.7263 7.14249 14.2819 7.31052C14.8375 7.47864 15.3241 7.82136 15.6696 8.28774C16.0151 8.75421 16.2011 9.3195 16.2 9.9V11.7H15.3C15.0613 11.7 14.8324 11.7949 14.6636 11.9636C14.4949 12.1324 14.4 12.3613 14.4 12.6V18H16.2V13.5H17.1C17.3387 13.5 17.5676 13.4051 17.7364 13.2364C17.9051 13.0676 18 12.8387 18 12.6V9.9C17.9986 8.70696 17.524 7.56324 16.6804 6.71958C15.8368 5.87599 14.693 5.40143 13.5 5.4Z"
              fill="currentColor"
            />
            <path d="M12.6 12.6H10.8V18H12.6V12.6Z" fill="currentColor" />
          </svg>
        </ng-template>
        <span class="text-white font-sans text-sm font-semibold whitespace-nowrap">
          {{
            guidedTraining.numberOfParticipants === 'single'
              ? 'Individual'
              : 'Partner'
          }}
        </span>
      </div>
    </div>
  </div>
</button>


<ng-template #loading>
  <div class="bg-white w-full flex-col justify-start items-start inline-flex">
    <div class="self-stretch aspect-w-16 aspect-h-9 animate-pulse bg-cadetGrey-200"></div>
    <div class="self-stretch grow shrink basis-0 px-6 py-4 flex-col justify-start items-start gap-4 flex">
      <div class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-4 flex">
        <div class="flex-col justify-start items-start gap-2 flex">
          <div class="w-24 h-3.5 bg-cadetGrey-200 animate-pulse"></div>
          <div class="w-44 h-6 bg-cadetGrey-200 animate-pulse"></div>
        </div>
        <div class="self-stretch h-24 bg-cadetGrey-200 animate-pulse"></div>
      </div>
      <div class="self-stretch justify-between items-start gap-4 inline-flex">
        <div class="w-24 h-5 bg-cadetGrey-200 animate-pulse"></div>
        <div class="w-24 h-5 bg-cadetGrey-200 animate-pulse"></div>
      </div>
    </div>
  </div>
</ng-template>