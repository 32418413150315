<div class="bg-white p-6 grid grid-cols-1 gap-y-4">
  <div class="flex flex-row justify-start space-x-4">
    <div
      class="h-8 w-8 sm:h-12 sm:w-12 flex-shrink-0"
      *ngIf="product && product.images && product.images[0]"
    >
      <img
        class="w-full h-full object-cover rounded-none"
        [src]="product.images[0]"
        [alt]="product?.name"
      />
    </div>
    <div class="flex-1 flex flex-row justify-between items-start">
      <div class="flex flex-col justify-start space-y-0">
        <span class="text-base sm:text-lg font-semibold text-richBlack">
          {{ product?.name }}
        </span>
        <span class="text-xs hidden sm:block font-body text-richBlack">
          {{ product?.description }}
        </span>
      </div>
      <div class="flex flex-col justify-start items-end">
        {{ price?.unit_amount / 100 | currency : (price.currency | uppercase) }}
        <span class="text-xs font-body text-richBlack">
          /{{ price?.recurring?.interval }}
        </span>
      </div>
    </div>
  </div>
  <div class="border-t-2 border-t-culturedWhite"></div>

  <div class="flex flex-col justify-start" *ngIf="invoice">
    <div class="flex flex-row justify-between items-start">
      <div class="flex flex-col justify-start items-start space-y-1">
        <span class="text-xs font-semibold font-body text-richBlack"
          >Discount from existing membership</span
        >
      </div>
      <span class="text-xs font-body text-richBlack">
        {{
          invoice?.lines?.data && invoice.lines.data[0].amount / 100
            | currency : (invoice.currency | uppercase)
        }}
      </span>
    </div>
  </div>

  <div class="border-t-2 border-t-culturedWhite" *ngIf="invoice"></div>
  <div class="flex flex-col justify-start" *ngIf="invoice">
    <div class="flex flex-row justify-between items-start">
      <div class="flex flex-col justify-start items-start space-y-1">
        <span class="text-xs font-semibold font-body text-richBlack"
          >Subtotal</span
        >
      </div>
      <span class="text-xs font-body text-richBlack">
        {{
          invoice.total_excluding_tax / 100
            | currency : (invoice.currency | uppercase)
        }}
      </span>
    </div>

    <div class="flex flex-row justify-between items-start">
      <div class="flex flex-col justify-start items-start space-y-1">
        <span class="text-xs font-semibold font-body text-richBlack"
          >Taxes</span
        >
      </div>
      <span class="text-xs font-body text-richBlack">
        {{ invoice.tax / 100 | currency : (invoice.currency | uppercase) }}
      </span>
    </div>
  </div>

  <div class="border-t-2 border-t-culturedWhite"></div>
  <div class="flex flex-col justify-start">
    <div
      class="flex flex-row justify-between items-start"
      *ngIf="invoice && invoice.total"
    >
      <div class="flex flex-col justify-start items-start space-y-1">
        <span class="text-xl font-semibold text-richBlack">{{
          invoice.total > 0 ? 'Pay now' : 'Your Balance'
        }}</span>
      </div>
      <div
        class="text-2xl font-semibold text-richBlack flex flex-col justify-start items-end"
      >
        <span class="whitespace-nowrap font-semibold text-richBlack">
          {{ invoice.total / 100 | currency : (invoice.currency | uppercase) }}
        </span>
      </div>
    </div>

    <div class="flex flex-row justify-between items-start">
      <div class="flex flex-col justify-start items-start space-y-1">
        <span class="text-xs font-semibold font-body text-richBlack"
          >From next {{ price?.recurring?.interval }}</span
        >
      </div>
      <span class="text-xs font-body text-richBlack">
        {{ price?.unit_amount / 100 | currency : (price.currency | uppercase) }}
      </span>
    </div>
  </div>
</div>
