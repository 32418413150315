import { CommonModule, DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@sami/features';
import { SamiUser } from 'interfaces';
import { ErrorSnackbarComponent, SamiButtonComponent, SnackbarsModule, SuccessSnackbarComponent } from '@sami/ui';


@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SnackbarsModule,
    SamiButtonComponent
  ],
})
export class UpdateProfileComponent implements OnInit {
  @Input() user: SamiUser | undefined;
  @Input() displayHint = true;
  userForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe,
    private auth: AuthService,
    private snackbar: MatSnackBar
  ) {
    this.userForm = this.fb.group({
      email: this.fb.control(
        { value: '', disabled: true },
        Validators.required
      ),
      firstName: this.fb.control(''),
      lastName: this.fb.control(''),
      phoneNumber: this.fb.control(''),
      membershipNumber: this.fb.control(''),
      gender: this.fb.control(''),
      preferredLanguage: this.fb.control('en'),
      dateOfBirth: this.fb.control(''),
    });
  }

  ngOnInit(): void {
    this.user = {
      ...this.user,
      ...(this.user?.dateOfBirth && {
        dateOfBirth: this.datePipe.transform(
          this.user.dateOfBirth.toDate(),
          'yyyy-MM-dd'
        ),
      }),
    };
    this.userForm.patchValue(this.user || {});
  }

  async onUpdateUser() {
    if (this.userForm.valid && this.user) {
      const userData: SamiUser = this.userForm.getRawValue();
      try {
        await this.auth.updateUser({
          ...userData,
          ...(userData['firstName'] || userData['lastName']) && { displayName: `${userData['firstName']} ${userData['lastName']}` },
          dateOfBirth: userData.dateOfBirth
            ? new Date(userData.dateOfBirth)
            : null,
        }, this.user.uid);

        this.snackbar.openFromComponent(SuccessSnackbarComponent, {
          duration: 3000,
          data: {
            title: 'Saved successfully',
            subtitle: 'Your data was saved successfully.',
          },
          panelClass: 'samix-toast',
          horizontalPosition: 'right',
          verticalPosition: 'top',
        });
      } catch (error) {
        console.error(error);
        this.snackbar.openFromComponent(ErrorSnackbarComponent, {
          duration: 3000,
          data: {
            title: 'Sorry, something went wrong',
            subtitle: 'Please try again later or contact our support',
          },
          panelClass: 'samix-toast',
          horizontalPosition: 'right',
          verticalPosition: 'top',
        });
      }
    }
  }
}
