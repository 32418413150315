import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-certificate-modal',
  templateUrl: './certificate-modal.component.html'
})
export class CertificateModalComponent {
  pdf: {
    created_at: string;
    height: number;
    image_url: string;
    image_url_jpg: string;
    image_url_png: string;
    pdf_url: string;
    pdf_url_compressed: string;
    render_pdf: boolean;
    template_name: string;
    width: number;
  } | undefined;

  constructor(
    public dialogRef: MatDialogRef<CertificateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.pdf = data.pdf;
  }

  downloadPDF() {
    if (isPlatformBrowser(this.platformId)) {
      window.open(this.pdf?.pdf_url, '_blank');
    }
  }
}
