import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
})
export class OrderSummaryComponent implements OnInit {
  @Input() location: any;
  @Input() invoice: any;
  @Input() product: any;
  @Input() price: any;

  constructor() {}

  ngOnInit(): void {}
}
