<div
  (click)="clickedOnCard()"
  class="h-full bg-white rounded-none group cursor-pointer hover:shadow-lg"
  *ngIf="center$ | async as center"
>
  <div class="relative">
    <div class="flex flex-col sm:flex-row">
      <div
        class="w-full sm:w-40 relative flex-shrink-0"
        *ngIf="center.whiteLogoPath"
      >
        <div
          class="absolute inset-0 w-full h-full object-cover bg-richBlack/80 z-10"
        ></div>
        <picture>
          <source
            type="image/webp"
            [srcset]="
              'https://a.storyblok.com/f/110844/3000x2250/710a782cf3/p1455345.jpg'
                | transformImg
                  : 'storyblok'
                  : 'fit-in/1200x0/smart/filters:format(webp)'
            "
          />
          <source
            type="image/png"
            [srcset]="
              'https://a.storyblok.com/f/110844/3000x2250/710a782cf3/p1455345.jpg'
                | transformImg
                  : 'storyblok'
                  : 'fit-in/1200x0/smart/filters:format(png)'
            "
          />
          <img
            loading="lazy"
            class="absolute inset-0 w-full h-full object-cover"
            [src]="
              'https://a.storyblok.com/f/110844/3000x2250/710a782cf3/p1455345.jpg'
                | transformImg : 'storyblok' : 'fit-in/1200x0/smart'
            "
            [alt]="'SAMI CENTER'"
          />
        </picture>

        <div
          class="aspect-w-2 aspect-h-1 sm:aspect-h-2 z-20"
          *ngIf="center.whiteLogoPath"
        >
          <div
            class="w-full h-full flex flex-col flex-shrink-0 justify-center items-center"
          >
            <img
              class="w-28 h-auto"
              *ngIf="center.whiteLogoPath"
              [src]="center.whiteLogoPath | getDownloadURL"
              [alt]="center?.name"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full justify-start items-start space-y-2 py-4 px-4">
        <div class="w-full flex flex-row justify-between items-center">
          <span class="text-lg font-sans sm:text-xl font-semibold text-black">{{
            center?.name
          }}</span>
          <ng-container *ngIf="mode === 'visit'">
            <svg class="w-5 shrink-0 text-cadetGrey-300 group-hover:text-richBlack" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7071 0.959595L23.7071 6.95959C24.0976 7.35012 24.0976 7.98328 23.7071 8.37381L17.7071 14.3738L16.2929 12.9596L21.5858 7.6667L16.2929 2.37381L17.7071 0.959595Z" fill="currentColor"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M23 8.6667H0V6.6667H23V8.6667Z" fill="currentColor"/>
            </svg>
          </ng-container>

          <ng-container *ngIf="mode === 'remove'">
            <svg class="w-4 shrink-0 text-cadetGrey-300 group-hover:text-richBlack" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L17 17" stroke="#091F2C" stroke-width="2" stroke-linejoin="round"/>
              <path d="M17 1L1 17" stroke="#091F2C" stroke-width="2" stroke-linejoin="round"/>
            </svg>
          </ng-container>
        </div>
        <div
          class="flex flex-col justify-start items-start space-y-1 text-sm text-black font-body"
        >
          <div
            class="flex flex-row justify-start items-start space-x-2"
            *ngIf="center.address"
          >
            <svg
              class="flex-shrink-0"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.27424 19.6668H14.6076M15.8576 6.691C15.8576 9.64933 10.5001 16.3335 10.5001 16.3335C10.5001 16.3335 5.14258 9.64933 5.14258 6.691C5.14258 5.98744 5.28115 5.29077 5.55039 4.64077C5.81963 3.99077 6.21426 3.40016 6.71175 2.90267C7.20924 2.40518 7.79985 2.01055 8.44985 1.74131C9.09985 1.47207 9.79652 1.3335 10.5001 1.3335C11.2036 1.3335 11.9003 1.47207 12.5503 1.74131C13.2003 2.01055 13.7909 2.40518 14.2884 2.90267C14.7859 3.40016 15.1805 3.99077 15.4498 4.64077C15.719 5.29077 15.8576 5.98744 15.8576 6.691V6.691Z"
                stroke="black"
                stroke-width="2"
                stroke-linejoin="round"
              />
              <path
                d="M10.4999 8.83334C11.6832 8.83334 12.6424 7.87411 12.6424 6.69084C12.6424 5.50757 11.6832 4.54834 10.4999 4.54834C9.31665 4.54834 8.35742 5.50757 8.35742 6.69084C8.35742 7.87411 9.31665 8.83334 10.4999 8.83334Z"
                fill="black"
              />
            </svg>
            <div>
              <span>{{ center.address }}</span>
            </div>
          </div>

          <div
            class="flex flex-row justify-start items-start space-x-2"
            *ngIf="center.email"
          >
            <svg
              class="flex-shrink-0"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 7.1665L9.5755 11.5502C10.1353 11.9234 10.8647 11.9234 11.4245 11.5502L18 7.1665M4.66667 16.3332H16.3333C17.2538 16.3332 18 15.587 18 14.6665V6.33317C18 5.4127 17.2538 4.6665 16.3333 4.6665H4.66667C3.74619 4.6665 3 5.4127 3 6.33317V14.6665C3 15.587 3.74619 16.3332 4.66667 16.3332Z"
                stroke="#111827"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div>
              <span>{{ center.email }}</span>
            </div>
          </div>

          <div
            class="flex flex-row justify-start items-start space-x-2"
            *ngIf="center.website"
          >
            <svg
              class="flex-shrink-0"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_3852_6168)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.93947 1.55021C10.2573 1.26126 10.7427 1.26126 11.0606 1.55021L20.2273 9.88355C20.482 10.1151 20.5681 10.4795 20.444 10.8007C20.3198 11.1218 20.011 11.3335 19.6667 11.3335H17.1667V18.8335C17.1667 19.2937 16.7936 19.6668 16.3334 19.6668H13C12.5398 19.6668 12.1667 19.2937 12.1667 18.8335V14.6668H8.83337V18.8335C8.83337 19.2937 8.46027 19.6668 8.00003 19.6668H4.6667C4.20646 19.6668 3.83337 19.2937 3.83337 18.8335V11.3335H1.33337C0.989085 11.3335 0.680246 11.1218 0.556099 10.8007C0.431952 10.4795 0.518058 10.1151 0.772806 9.88355L9.93947 1.55021ZM3.48887 9.66683H4.6667C5.12694 9.66683 5.50003 10.0399 5.50003 10.5002V18.0002H7.1667V13.8335C7.1667 13.3733 7.5398 13.0002 8.00003 13.0002H13C13.4603 13.0002 13.8334 13.3733 13.8334 13.8335V18.0002H15.5V10.5002C15.5 10.0399 15.8731 9.66683 16.3334 9.66683H17.5112L10.5 3.29305L3.48887 9.66683Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_3852_6168">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            <div>
              <span>{{ center.website }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="h-full bg-white rounded-none cursor-pointer hover:shadow-lg"
  *ngIf="center"
>
  <div class="relative">
    <div class="hidden sm:block absolute top-8 right-4">
      <svg
        class="w-6"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 18L23 12L17 6"
          stroke="black"
          stroke-width="2"
          stroke-linejoin="round"
        />
        <path
          d="M0 12H23"
          stroke="black"
          stroke-width="2"
          stroke-linejoin="round"
        />
      </svg>
    </div>

    <div class="flex flex-col sm:flex-row">
      <div
        class="w-full sm:w-40 relative flex-shrink-0"
        *ngIf="center.whiteLogoPath"
      >
        <div
          class="absolute inset-0 w-full h-full object-cover bg-richBlack/80 z-10"
        ></div>
        <picture>
          <source
            type="image/webp"
            [srcset]="
              'https://a.storyblok.com/f/110844/3000x2250/710a782cf3/p1455345.jpg'
                | transformImg
                  : 'storyblok'
                  : 'fit-in/1200x0/smart/filters:format(webp)'
            "
          />
          <source
            type="image/png"
            [srcset]="
              'https://a.storyblok.com/f/110844/3000x2250/710a782cf3/p1455345.jpg'
                | transformImg
                  : 'storyblok'
                  : 'fit-in/1200x0/smart/filters:format(png)'
            "
          />
          <img
            loading="lazy"
            class="absolute inset-0 w-full h-full object-cover"
            [src]="
              'https://a.storyblok.com/f/110844/3000x2250/710a782cf3/p1455345.jpg'
                | transformImg : 'storyblok' : 'fit-in/1200x0/smart'
            "
            [alt]="'SAMI CENTER'"
          />
        </picture>

        <div
          class="aspect-w-2 aspect-h-1 sm:aspect-h-2 z-20"
          *ngIf="center.whiteLogoPath"
        >
          <div
            class="w-full h-full flex flex-col flex-shrink-0 justify-center items-center"
          >
            <img
              class="w-28 h-auto"
              *ngIf="center.whiteLogoPath"
              [src]="center.whiteLogoPath | getDownloadURL"
              [alt]="center?.name"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col justify-start items-start space-y-2 py-4 w-full px-4">
        <span class="text-lg sm:text-2xl font-semibold text-black">{{
          center?.name
        }}</span>
        <div
          class="flex flex-col justify-start items-start space-y-1 text-sm text-black font-body"
        >
          <div
            class="flex flex-row justify-start items-start space-x-2"
            *ngIf="center.address"
          >
            <svg
              class="flex-shrink-0"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.27424 19.6668H14.6076M15.8576 6.691C15.8576 9.64933 10.5001 16.3335 10.5001 16.3335C10.5001 16.3335 5.14258 9.64933 5.14258 6.691C5.14258 5.98744 5.28115 5.29077 5.55039 4.64077C5.81963 3.99077 6.21426 3.40016 6.71175 2.90267C7.20924 2.40518 7.79985 2.01055 8.44985 1.74131C9.09985 1.47207 9.79652 1.3335 10.5001 1.3335C11.2036 1.3335 11.9003 1.47207 12.5503 1.74131C13.2003 2.01055 13.7909 2.40518 14.2884 2.90267C14.7859 3.40016 15.1805 3.99077 15.4498 4.64077C15.719 5.29077 15.8576 5.98744 15.8576 6.691V6.691Z"
                stroke="black"
                stroke-width="2"
                stroke-linejoin="round"
              />
              <path
                d="M10.4999 8.83334C11.6832 8.83334 12.6424 7.87411 12.6424 6.69084C12.6424 5.50757 11.6832 4.54834 10.4999 4.54834C9.31665 4.54834 8.35742 5.50757 8.35742 6.69084C8.35742 7.87411 9.31665 8.83334 10.4999 8.83334Z"
                fill="black"
              />
            </svg>
            <div>
              <span>{{ center.address }}</span>
            </div>
          </div>

          <div
            class="flex flex-row justify-start items-start space-x-2"
            *ngIf="center.email"
          >
            <svg
              class="flex-shrink-0"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 7.1665L9.5755 11.5502C10.1353 11.9234 10.8647 11.9234 11.4245 11.5502L18 7.1665M4.66667 16.3332H16.3333C17.2538 16.3332 18 15.587 18 14.6665V6.33317C18 5.4127 17.2538 4.6665 16.3333 4.6665H4.66667C3.74619 4.6665 3 5.4127 3 6.33317V14.6665C3 15.587 3.74619 16.3332 4.66667 16.3332Z"
                stroke="#111827"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div>
              <span>{{ center.email }}</span>
            </div>
          </div>

          <div
            class="flex flex-row justify-start items-start space-x-2"
            *ngIf="center.website"
          >
            <svg
              class="flex-shrink-0"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_3852_6168)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.93947 1.55021C10.2573 1.26126 10.7427 1.26126 11.0606 1.55021L20.2273 9.88355C20.482 10.1151 20.5681 10.4795 20.444 10.8007C20.3198 11.1218 20.011 11.3335 19.6667 11.3335H17.1667V18.8335C17.1667 19.2937 16.7936 19.6668 16.3334 19.6668H13C12.5398 19.6668 12.1667 19.2937 12.1667 18.8335V14.6668H8.83337V18.8335C8.83337 19.2937 8.46027 19.6668 8.00003 19.6668H4.6667C4.20646 19.6668 3.83337 19.2937 3.83337 18.8335V11.3335H1.33337C0.989085 11.3335 0.680246 11.1218 0.556099 10.8007C0.431952 10.4795 0.518058 10.1151 0.772806 9.88355L9.93947 1.55021ZM3.48887 9.66683H4.6667C5.12694 9.66683 5.50003 10.0399 5.50003 10.5002V18.0002H7.1667V13.8335C7.1667 13.3733 7.5398 13.0002 8.00003 13.0002H13C13.4603 13.0002 13.8334 13.3733 13.8334 13.8335V18.0002H15.5V10.5002C15.5 10.0399 15.8731 9.66683 16.3334 9.66683H17.5112L10.5 3.29305L3.48887 9.66683Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_3852_6168">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            <div>
              <span>{{ center.website }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
