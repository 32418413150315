import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, LocationService } from '@sami/features';
import { SamiUser } from 'interfaces';
import { SamiIconsRegistry, menu, samiIconsArrowLeft } from '@sami/ui';
import { MenuService } from '../menu.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  @Input() currentSite: 'sami-international' | 'sami-x' | 'sami-x-app' | 'admin' | 'b2b' | 'instructor-area' | 'shop' = 'sami-international';
  @Input() user: SamiUser | undefined;
  @Input() backgroundColor: 'white' | 'black' = 'white';
  @Input() isMobile: boolean | null = false;
  @Input() isSmartphone: boolean | null = false;
  @Input() mode: 'minimal' | 'full' = 'full';
  @Input() showTopNav = false;
  mobileMenuStatus = false;

  isOpen = false;

  @Output() toggleSidenav: EventEmitter<void> = new EventEmitter<void>();
  @Output() logout: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public auth: AuthService,
    public location: LocationService,
    public router: Router,
    private _location: Location,
    private samiIconRegistry: SamiIconsRegistry,
    public menuService: MenuService
  ) {
    this.samiIconRegistry.registerIcons([
      menu,
      samiIconsArrowLeft
    ]);
  }

  toggleMobileMenu() {
    this.mobileMenuStatus = !this.mobileMenuStatus;
  }

  goBack() {
    if (this.menuService.samiMenuSettings?.route) {
      this.router.navigate([this.menuService.samiMenuSettings.route]);
    } else {
      if (this.router.navigated && !this.router.url.includes('tracks') && !this.router.url.includes('live-sessions') ) {
        this._location.back();
      } else {
        this.router.navigate(['/app']);
      }
    }
  }

  getBackgroundColor() {
    if (this.isSmartphone) {
      if (this.menuService.samiMenuSettings?.backgroundColor) {
        if (this.menuService.samiMenuSettings.backgroundColor === 'white') {
          return 'bg-white text-richBlack';
        } else if (this.menuService.samiMenuSettings.backgroundColor === 'black') {
          return 'bg-richBlack text-culturedWhite';
        } else if (this.menuService.samiMenuSettings.backgroundColor === 'red') {
          return 'bg-fightingRed-700 text-white';
        } else if (this.menuService.samiMenuSettings.backgroundColor === 'yellow') {
          return 'bg-professionalYellow-700 text-white';
        } else if (this.menuService.samiMenuSettings.backgroundColor === 'green') {
          return 'bg-defensiveGreen-700 text-white';
        } else {
          return 'bg-white text-richBlack';
        }
      } else {
        if (this.backgroundColor === 'white') {
          return 'bg-white';
        } else {
          return 'bg-richBlack';
        }
      }
    } else {
      if (this.backgroundColor === 'white') {
        return 'bg-white text-richBlack';
      } else {
        return 'bg-richBlack text-white';
      }
    }
  }
}
