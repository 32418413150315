import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagePipe } from '../pipes/transformImage.pipe';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-picture',
  standalone: true,
  imports: [CommonModule, ImagePipe],
  templateUrl: './picture.component.html'
})
export class PictureComponent {
  @Input() mode: 'firestore' | 'storyblok' = 'firestore';
  @Input() src: string | undefined;
  @Input() alt: string | undefined;
  @Input() width: number | undefined;
  @Input() height: number | undefined;
  @Input() class: string | undefined;
  @Input() loading: 'lazy' | 'eager' = 'lazy';
  @Input() options: {
    webp_options: string;
    png_options: string;
    default_options: string;
  } | undefined;
}
