<div class="w-[300px] sm:w-[550px] md:w-[760px] flex flex-col justify-start pt-12 pb-8 items-center bg-culturedWhite">
  <button (click)="dialogRef.close()" class="w-6 h-6 absolute top-2 right-2">
    <svg class="text-richBlack" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.5 4L20.5 20" stroke="currentColor" stroke-width="2" stroke-linejoin="round"/>
      <path d="M20.5 4L4.5 20" stroke="currentColor" stroke-width="2" stroke-linejoin="round"/>
    </svg>
  </button>

  <ng-container *ngIf="status === 'success'">
    <div class="justify-start items-center space-y-8 pb-8 flex flex-col w-80">
      <div class="w-20 h-20 relative rounded-xl">
        <svg class="w-full h-full" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="39" cy="39.0669" r="38.5" fill="#239C45"/>
          <path d="M58.2498 23.0254L32.5832 48.6921L21.354 37.4629L16.5415 41.9309L30.8073 56.0524C31.2361 56.4755 31.8143 56.7129 32.4167 56.7129C33.0192 56.7129 33.5974 56.4755 34.0261 56.0524L62.6632 27.8919L58.2498 23.0254Z" fill="#ECEFEF"/>
        </svg>
      </div>
      <div class="flex-col justify-start items-center space-y-4 flex">
        <div class="px-5 flex-col justify-start items-start space-y-2.5 flex">
          <div class="text-center text-richBlack text-3xl font-sans font-semibold">Thank you!</div>
        </div>
        <div class="text-center text-richBlack text-sm font-body leading-tight">
          You now have access to {{ productTitle || 'this content'}}. You can find this and all of your other purchases in your profile under "My Purchases".
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="status !== 'success'">
    <div class="justify-start items-center space-y-8 pb-8 flex flex-col w-80">
      <div class="w-20 h-20 relative rounded-xl">
        <svg class="w-full h-full" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_5046_43245)">
          <path d="M21.382 22H2.61804L12 3.23607L21.382 22Z" fill="#D71427"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8944 0.552786C12.725 0.214002 12.3788 0 12 0C11.6212 0 11.275 0.214002 11.1056 0.552786L0.105574 22.5528C-0.0494204 22.8628 -0.0328564 23.2309 0.14935 23.5257C0.331557 23.8205 0.653423 24 1 24H23C23.3466 24 23.6684 23.8205 23.8507 23.5257C24.0329 23.2309 24.0494 22.8628 23.8944 22.5528L12.8944 0.552786ZM2.61804 22H21.382L12 3.23607L2.61804 22Z" fill="#D71427"/>
          <path d="M13 9H11V16H13V9Z" fill="#FDEAEC"/>
          <path d="M13 18H11V20H13V18Z" fill="#FDEAEC"/>
          </g>
        </svg>
      </div>
      <div class="flex-col justify-start items-center space-y-4 flex">
        <div class="px-5 flex-col justify-start items-start space-y-12 flex">
          <div class="text-center text-richBlack text-3xl font-sans font-semibold">You canceled your purchase!</div>
        </div>
        <div class="text-center text-richBlack text-sm font-body leading-tight">
          If you change your mind, you can purchase this content again at any time.
        </div>
      </div>
    </div>
  </ng-container>

  <div class="w-96 h-16 p-2.5 bg-gray-100 flex-col justify-start items-start gap-2.5 inline-flex">
    <button (click)="dialogRef.close()" class="self-stretch h-12 flex-col justify-start items-start gap-7 flex">
      <div class="self-stretch px-7 py-3.5 bg-red-700 justify-center items-center inline-flex">
        <div class="text-white text-base font-semibold leading-normal">Continue</div>
      </div>
    </button>
  </div>
</div>