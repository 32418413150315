import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SamiUser } from 'interfaces';
import { SamiIcon } from '@sami/ui';
import { Router } from '@angular/router';

interface SamiMenuItem {
  icon?: SamiIcon,
  title: string,
  route: string,
  active?: boolean,
  submenu?: {
    items: SamiSubmenuItem[];
    featured?: SamiSubmenuItem;
  }
}

interface SamiSubmenuItem {
  title: string;
  route: string;
  description: string;
  image?: string;
  isFeatured?: boolean;
}

@Component({
  selector: 'app-sidenav-button',
  templateUrl: './sidenav-button.component.html'
})
export class SidenavButtonComponent {
  @Input() lang = 'en';
  @Input() backgroundColor: 'white' | 'black' = 'white';
  @Input() isMobile: boolean | null = false;
  @Input() isSmartphone: boolean | null = false;
  @Input() currentSite: 'sami-international' | 'sami-x' | 'sami-x-app' | 'admin' | 'b2b' | 'instructor-area' | 'shop' = 'sami-international';
  @Input() menuItem: SamiMenuItem | undefined;
  @Input() user: SamiUser | undefined;

  @Output() toggleSidenav: EventEmitter<void> = new EventEmitter<void>();

  constructor(private router: Router) {}

  navigateToElement(route: string) {
    if (route) {
      this.router.navigate([route]);
    }
  }
}
