import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { tap, scan, take } from 'rxjs/operators';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';

// Options to reproduce firestore queries consistently
interface QueryConfig {
  path: string; // path to collection
  field: string; // field to orderBy
  limit: number; // limit per query
  orderBy?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PaginationService {
  // Source data
  private _done: any = new BehaviorSubject(false);
  private _loading: any = new BehaviorSubject(false);
  private _data: any = new BehaviorSubject([]);

  private query: QueryConfig | any;

  // Observable data
  data: Observable<any> | undefined;
  done: Observable<boolean> = this._done.asObservable();
  loading: Observable<boolean> = this._loading.asObservable();

  constructor(private afs: AngularFirestore) {}

  // Initial query sets options and defines the Observable

  init(
    path: any,
    field: any,
    orderBy: string,
    limit: number,
    conditionKey: string,
    conditionValue: string
  ) {
    this._data = new BehaviorSubject([]);
    this._done = new BehaviorSubject(false);
    this._loading = new BehaviorSubject(false);

    this.query = {
      path,
      field,
      limit,
      orderBy,
      conditionKey,
      conditionValue,
    };

    if (this.query && this.query.limit) {
      const first = this.afs.collection(this.query.path, (ref) => {
        return this.query.conditionKey !== ''
          ? ref
              .where(this.query.conditionKey, '==', this.query.conditionValue)
              .orderBy(this.query.orderBy)
              .limit(this.query.limit)
          : ref.orderBy(this.query.orderBy).limit(this.query.limit);
      });

      this.mapAndUpdate(first);

      // Create the observable array for consumption in components
      this.data = this._data.asObservable().pipe(
        scan((acc, val: any) => {
          return acc.concat(val);
        })
      );
    }
  }

  // more() Retrieves additional data from firestore
  // This works the first time but not the second time

  more() {
    const cursor = this.getCursor();
    const more = this.afs.collection(this.query.path, (ref) => {
      return this.query.conditionKey !== ''
        ? ref
            .orderBy(this.query.field)
            .limit(this.query.limit)
            .where(this.query.conditionKey, '==', this.query.conditionValue)
            .startAfter(cursor)
        : ref
            .orderBy(this.query.field)
            .limit(this.query.limit)
            .startAfter(cursor);
    });
    this.mapAndUpdate(more);
  }

  // Determines the doc snapshot to paginate query
  private getCursor() {
    const current = this._data.value;
    if (current.length) {
      return current[current.length - 1].doc;
    }
    return null;
  }

  // Maps the snapshot to usable format the updates source
  private mapAndUpdate(col: AngularFirestoreCollection<any>) {
    if (this._done.value || this._loading.value) {
      return;
    }

    // loading
    this._loading.next(true);

    // Map snapshot with doc ref (needed for cursor)
    return col
      .snapshotChanges()
      .pipe(
        tap((arr) => {
          let values = arr.map((snap) => {
            const data = snap.payload.doc.data();
            const doc = snap.payload.doc;
            return { ...data, doc };
          });

          // update source with new values, done loading
          this._data.next(values);
          this._loading.next(false);

          // no more values, mark done
          if (!values.length) {
            this._done.next(true);
          }
        })
      )
      .pipe(take(1))
      .subscribe();
  }

  // Reset the page
  reset() {
    this._data.next([]);
    this._done.next(false);
  }
}
