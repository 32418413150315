import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { StatComponent } from './stat/stat.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-training-stats',
  standalone: true,
  imports: [CommonModule, StatComponent],
  templateUrl: './training-stats.component.html'
})
export class TrainingStatsComponent implements OnInit {
  @Input() userId: string | undefined;
  @Input() background: 'white' | 'culturedWhite' = 'white';
  stats$: Observable<any> = new Observable();

  constructor(private afs: AngularFirestore) {}

  ngOnInit(): void {
    if (this.userId) {
      this.stats$ = this.afs.doc(`users/${this.userId}/stats/${this.userId}`).valueChanges()
    }
  }
}
