<div class="relative">
  <div class="flex w-full flex-row justify-between items-end absolute -top-6">
    <label [ngClass]="displayLabel ? 'block text-base font-semibold text-richBlack' : 'sr-only'" [for]="fieldName">
      {{ label }}
    </label>
    <span *ngIf="!validator && displayOptional" class="text-xs uppercase text-cadetGrey-400 font-body">
      optional
    </span>
  </div>
  <div class="flex flex-col justify-start space-y-0.5">
    <textarea
      (input)="onChange( $event )"
      (blur)="touched()"
      [value]="value"
      [disabled]="isDisabled"
      [id]="fieldName"
      [name]="fieldName"
      [rows]="rows"
      type="text"
      class="block w-full font-body rounded-none border-cadetGrey-300 shadow-none focus:border-cadetGrey-600 focus:ring-cadetGrey-600 sm:text-sm"
      [ngClass]="{
      'border-statusColors-red placeholder:text-statusColors-darkRed focus:border-statusColors-red focus:ring-statusColors-red':
          formField.invalid
          && formField.dirty
      }"
      [placeholder]="placeholder"
    ></textarea>
    <div class="flex flex-row justify-between items-start space-x-2">
      <sami-field-errors *ngIf="formField.invalid" [formField]="formField"></sami-field-errors>
      <span *ngIf="hint && !formField.invalid" class="text-xs font-body text-cadetGrey-600" [innerText]="hint"></span>
      <div *ngIf="maxLengthLabel"
        class="text-right flex-1 font-body text-xs text-cadetGrey-600"
        [ngClass]="{
        'text-statusColors-red':
            formField.invalid
            && formField.dirty
            && formField.value.length > maxLengthLabel
        }"
      >
        {{ formField.value.length }} / {{ maxLengthLabel }}
      </div>
    </div>
  </div>
</div>