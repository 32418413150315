import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-payment-status-dialog',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './payment-status-dialog.component.html',
  styles: [],
})
export class PaymentStatusDialogComponent {
  status: 'success' | 'canceled' | 'returned';
  productTitle = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { status: 'success' | 'canceled' | 'returned', productTitle: string },
    public dialogRef: MatDialogRef<PaymentStatusDialogComponent>,
  ) {
    this.status = data.status;
    this.productTitle = data.productTitle;
  }
}
