export interface VideoAsset {
  asset: {
    aspect_ratio: string;
    created_at: number;
    duration: number;
    id: string;
    master_access: string;
    max_stored_resolution: string;
    max_stored_frame_rate: number;
    playback_ids: {
      id: string;
      policy: string;
    }[];
    status: string;
    upload_id: string;
    tracks: {
      duration: number;
      id: string;
      max_stored_frame_rate?: number;
      max_height?: number;
      max_width?: number;
      type: string;
      max_channels?: number;
      max_channel_layout?: string;
    }[];
  }
  linkedExercises: any[];
  linkedTechniques: any[];
  assetType: string;
  description: string;
  difficulty: number;
  editorStatus: string;
  level: number;
  searchIndex: string[];
  status: string;
  system: string;
  tags: string[];
  title: string;
  uploadId: string;
}