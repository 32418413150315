<div class="flex w-full flex-col max-h-[80vh] overflow-y-auto justify-start md:w-[600px] bg-white p-3 md:p-6">
  <div class="flex flex-row justify-between">
    <span class="text-2xl font-semibold text-richBlack"> Your diploma is ready! </span>
    <button (click)="dialogRef.close()" class="w-4 h-4">
      <svg
        class="w-4 h-4"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3849_30117)">
          <path
            d="M1 1L23 23"
            stroke="black"
            stroke-width="2"
            stroke-linejoin="round"
          />
          <path
            d="M23 1L1 23"
            stroke="black"
            stroke-width="2"
            stroke-linejoin="round"
          />
        </g>
      </svg>
    </button>
  </div>

  <div class="py-4 flex flex-col justify-start space-y-3">
    <img [src]="pdf?.image_url_jpg" class="w-full border-2 border-richBlack" [alt]="pdf?.template_name">
  </div>
  <div class="flex flex-row justify-end items-center">
    <sami-button text="Download Diploma" (click)="downloadPDF()"></sami-button>
  </div>
</div>
