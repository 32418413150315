import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { SamiIconComponent, StoragePipe } from '@sami/ui';
import { HeaderComponent } from './header/header.component';
import { SecondaryMenuComponent } from './header/secondary-menu/secondary-menu.component';
import { SidenavMenuComponent } from './header/sidenav/sidenav-menu/sidenav-menu.component';
import { SidenavComponent } from './header/sidenav/sidenav.component';
import { SubmenuComponent } from './header/submenu/submenu.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { MenuButtonComponent } from './header/menu-button/menu-button.component';
import { SidenavButtonComponent } from './header/sidenav/sidenav-menu/sidenav-button/sidenav-button.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { TabbarComponent } from './tabbar/tabbar.component';
import { MatRippleModule } from '@angular/material/core';
import { AppHeaderComponent } from './app-header/app-header.component';
import { PageTitleComponent } from './header/page-title/page-title.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    StoragePipe,
    SamiIconComponent,
    MatSidenavModule,
    MatSidenavModule,
    SamiIconComponent,
    OverlayModule,
    CdkAccordionModule,
    MatRippleModule,
  ],
  declarations: [
    HeaderComponent,
    MyProfileComponent,
    SidenavComponent,
    SecondaryMenuComponent,
    SidenavMenuComponent,
    SubmenuComponent,
    MenuButtonComponent,
    SidenavButtonComponent,
    TabbarComponent,
    AppHeaderComponent,
    PageTitleComponent,
  ],
  exports: [
    HeaderComponent,
    SidenavComponent,
    TabbarComponent,
    AppHeaderComponent,
  ],
})
export class HeaderModule {}
