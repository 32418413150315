import { Component, Input, OnChanges } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder } from '@angular/forms';
import { SamiPublicProfile } from 'interfaces';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-seo',
  templateUrl: './seo.component.html',
})
export class SeoComponent implements OnChanges {
  @Input() instructor: SamiPublicProfile | undefined;
  isBusy$ = new BehaviorSubject<boolean>(false);

  form = this.fb.group({
    seoTitle: [''],
    seoDescription: [''],
    slug: [''],
  });

  constructor(private fb: FormBuilder, private afs: AngularFirestore) {}

  ngOnChanges(): void {
    if (this.instructor) {
      this.form.patchValue({
        seoTitle: this.instructor.seo?.title || '',
        seoDescription: this.instructor.seo?.description || '',
        slug: this.instructor.slug || '',
      });
    }
  }

  async submit() {
    if (this.form.valid && this.instructor) {
      this.isBusy$.next(true);
      try {
        await this.afs
          .doc(
            `users/${this.instructor.id}/publicProfiles/${this.instructor.id}`
          )
          .update({
            seo: {
              title: this.form.value.seoTitle,
              description: this.form.value.seoDescription,
            },
            slug: this.form.value.slug,
          });

        this.isBusy$.next(false);
      } catch (error) {
        console.error(error);
        this.isBusy$.next(false);
      }
    }
  }
}
