import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '@sami/features';
import { SafePipe } from '../../pipes/safe.pipe';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-onboarding',
  standalone: true,
  imports: [CommonModule, SafePipe],
  templateUrl: './onboarding.component.html'
})
export class OnboardingComponent {
  @Input() topic!: 'guidedTraining' | 'liveTraining' | 'track' | 'workout' | 'workoutCreator' | 'trainingCreator' | 'training' | 'learning' | 'trainingCreator' | 'systemGuide' | 'library';
  @Input() userId: string | undefined;

  mode: any = {
    'guidedTraining': {
      icon: `
      <svg class="w-full h-full" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18 16.3477C15.7909 16.3477 14 18.1385 14 20.3477V40.3477C14 42.5568 15.7909 44.3477 18 44.3477H30C32.2091 44.3477 34 42.5568 34 40.3477V20.3477C34 18.1385 32.2091 16.3477 30 16.3477H18ZM10 20.3477C10 15.9294 13.5817 12.3477 18 12.3477H30C34.4183 12.3477 38 15.9294 38 20.3477V40.3477C38 44.7659 34.4183 48.3477 30 48.3477H18C13.5817 48.3477 10 44.7659 10 40.3477V20.3477Z" fill="#ECEFEF"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M24 0.347656C24.6314 0.347656 25.2258 0.645834 25.6032 1.152L35.9292 14.998L32.7228 17.3893L24 5.69309L15.2772 17.3893L12.0708 14.998L22.3968 1.152C22.7742 0.645834 23.3686 0.347656 24 0.347656Z" fill="#ECEFEF"/>
      <path d="M12 25.3477H36V35.3477H12V25.3477Z" fill="#ECEFEF"/>
      </svg>
      `,
      title: 'Practice your skills',
      description: 'Our guided trainings are easy-to-follow sessions where everything is explained so you can just follow along. New trainings are released every two weeks.',
      link: 'https://help.sami-x.com/en/articles/8278586-what-are-guided-trainings',
      image: 'https://a.storyblok.com/f/110844/476x401/8b0e9d0a90/guidedtrainings.png'
    },
    'training': {
      icon: `
      <svg class="w-full h-full" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 5.4C9 3.55228 10.5523 2 12.4 2H16.6C18.4477 2 20 3.55228 20 5.4V13.6C20 15.4477 18.4477 17 16.6 17H8.39999C7.6129 17 6.96072 16.6578 6.38411 16.0486C5.79086 15.4217 5.31403 14.5444 4.94374 13.5981C4.57719 12.6614 4.33785 11.7166 4.19007 11.0046C4.11661 10.6506 4.06667 10.3586 4.03527 10.1594C4.01959 10.0599 4.00847 9.98315 4.00116 9.93194L4.00103 9.93108C4.03442 8.81833 4.87538 8 6 8H7V12H9V5.4ZM6 6C3.74772 6 2 7.74771 2 10C2 10.0625 2.00574 10.1237 2.01671 10.183L2.02126 10.2148C2.02932 10.2712 2.04193 10.3583 2.05965 10.4707C2.09505 10.6953 2.15058 11.0197 2.2318 11.411C2.3934 12.1896 2.6603 13.2511 3.08125 14.3269C3.49846 15.3931 4.09038 16.5345 4.9315 17.4233C5.49393 18.0176 6.18108 18.5104 7 18.7793V23C7 23.5523 7.44772 24 8 24H19C19.5523 24 20 23.5523 20 23V17.7757C21.2159 16.7803 22 15.271 22 13.6V5.4C22 2.44772 19.5523 0 16.6 0H12.4C9.4477 0 7 2.44772 7 5.4V6H6ZM18 18.812C17.5525 18.9345 17.083 19 16.6 19H9V22H18V18.812ZM2.01356 10.161L2.01358 10.1611C2.013 10.1575 2.01295 10.1569 2.01356 10.161Z" fill="#ECEFEF"/>
      </svg>
      `,
      title: 'Unlimited training opportunities',
      description: 'Train what and where you want. Whether you want to train live over video or all by yourself - we’ve got you covered.',
      link: '',
      image: ''
    },
    'liveTraining': {
      icon: `
      <svg class="w-full h-full" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_9101_67809)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 24.6104C0.5 11.3555 11.2452 0.610352 24.5 0.610352C37.7548 0.610352 48.5 11.3555 48.5 24.6104C48.5 37.8651 37.7548 48.6104 24.5 48.6104C11.2452 48.6104 0.5 37.8651 0.5 24.6104ZM24.5 4.61035C13.4543 4.61035 4.5 13.5647 4.5 24.6104C4.5 35.656 13.4543 44.6104 24.5 44.6104C35.5456 44.6104 44.5 35.656 44.5 24.6104C44.5 13.5647 35.5456 4.61035 24.5 4.61035Z" fill="#ECEFEF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 24.6104C10.5 16.8784 16.768 10.6104 24.5 10.6104C32.232 10.6104 38.5 16.8784 38.5 24.6104C38.5 32.3423 32.232 38.6104 24.5 38.6104C16.768 38.6104 10.5 32.3423 10.5 24.6104ZM24.5 14.6104C18.9771 14.6104 14.5 19.0875 14.5 24.6104C14.5 30.1332 18.9772 34.6104 24.5 34.6104C30.0228 34.6104 34.5 30.1332 34.5 24.6104C34.5 19.0875 30.0228 14.6104 24.5 14.6104Z" fill="#ECEFEF"/>
        <path d="M24.5 30.6104C27.8138 30.6104 30.5 27.9242 30.5 24.6104C30.5 21.2966 27.8138 18.6104 24.5 18.6104C21.1862 18.6104 18.5 21.2966 18.5 24.6104C18.5 27.9242 21.1862 30.6104 24.5 30.6104Z" fill="#ECEFEF"/>
        </g>
      </svg>
      `,
      title: 'Let’s train together',
      description: 'Train live with one of our SAMI instructors. By sharing your video, you can get valuable feedback to improve your skills.',
      link: 'https://help.sami-x.com/en/articles/8278589-what-are-live-trainings',
      image: 'https://a.storyblok.com/f/110844/441x401/16554f1f54/livetrainings.png'
    },
    learning: {
      icon: `
      <svg class="w-full h-full" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3 3C3 2.44772 3.44772 2 4 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289L22.7071 5.29289C23.0976 5.68342 23.0976 6.31658 22.7071 6.70711L19.7071 9.70711C19.5196 9.89464 19.2652 10 19 10H4C3.44772 10 3 9.55228 3 9V3ZM5 4V8H11H13H18.5858L20.5858 6L18.5858 4H13H11H5ZM4.29289 12.2929C4.48043 12.1054 4.73478 12 5 12H20C20.5523 12 21 12.4477 21 13V19C21 19.5523 20.5523 20 20 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071L1.29289 16.7071C0.902369 16.3166 0.902369 15.6834 1.29289 15.2929L4.29289 12.2929ZM5.41421 14L3.41421 16L5.41421 18H11H13H19V14H13H11H5.41421Z" fill="#ECEFEF"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13 0V3H19L22 6L19 9H13V13H20V19H13V24H11V19H5L2 16L5 13H11V9H4V3H11V0H13ZM11 8H13H18.5858L20.5858 6L18.5858 4H13H11H5V8H11ZM13 18H11H5.41421L3.41421 16L5.41421 14H11H13H19V18H13Z" fill="#ECEFEF"/>
      </svg>
      `,
      title: 'Learn everything you need to know',
      description: 'Enter the arena of self-defense and martial arts with our extensive line of learning tracks, guides and videos designed for beginners and enthusiasts alike.',
      link: '',
      image: ''
    },
    track: {
      icon: `
      <svg class="w-full h-full" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6 6C6 4.89543 6.89543 4 8 4H38C38.5304 4 39.0391 4.21071 39.4142 4.58579L45.4142 10.5858C46.1953 11.3668 46.1953 12.6332 45.4142 13.4142L39.4142 19.4142C39.0391 19.7893 38.5304 20 38 20H8C6.89543 20 6 19.1046 6 18V6ZM10 8V16H22H26H37.1716L41.1716 12L37.1716 8H26H22H10ZM8.58579 24.5858C8.96086 24.2107 9.46957 24 10 24H40C41.1046 24 42 24.8954 42 26V38C42 39.1046 41.1046 40 40 40H10C9.46957 40 8.96086 39.7893 8.58579 39.4142L2.58579 33.4142C1.80474 32.6332 1.80474 31.3668 2.58579 30.5858L8.58579 24.5858ZM10.8284 28L6.82843 32L10.8284 36H22H26H38V28H26H22H10.8284Z" fill="#ECEFEF"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M26 0V6H38L44 12L38 18H26V26H40V38H26V48H22V38H10L4 32L10 26H22V18H8V6H22V0H26ZM22 16H26H37.1716L41.1716 12L37.1716 8H26H22H10V16H22ZM26 36H22H10.8284L6.82843 32L10.8284 28H22H26H38V36H26Z" fill="#ECEFEF"/>
      </svg>
      `,
      title: 'Learn and get certified',
      description: 'The best way to start your journey is with our learning tracks. These will teach you everything you need to know. Optional assignments help you get feedback on your progress, and passing the final exam can earn you an official SAMI diploma.',
      link: 'https://help.sami-x.com/en/articles/6538034-what-are-tracks',
      image: 'https://a.storyblok.com/f/110844/580x410/008c64a1e1/learningtracks.png'
    },
    systemGuide: {
      icon: `
      <svg class="w-full h-full" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5 4C9.31332 4 8.15328 4.3519 7.16658 5.01118C6.17988 5.67048 5.41086 6.60754 4.95672 7.7039C4.5026 8.80026 4.38378 10.0067 4.6153 11.1705C4.8468 12.3344 5.41824 13.4035 6.25736 14.2426C7.09648 15.0818 8.16558 15.6532 9.32946 15.8847C10.4933 16.1162 11.6997 15.9974 12.7961 15.5433C13.8925 15.0892 14.8295 14.3201 15.4888 13.3334C16.1481 12.3467 16.5 11.1867 16.5 10C16.5 8.4087 15.8679 6.88258 14.7426 5.75736C13.6174 4.63214 12.0913 4 10.5 4ZM10.5 12C10.1044 12 9.71776 11.8827 9.38886 11.6629C9.05996 11.4432 8.80362 11.1308 8.65224 10.7654C8.50086 10.3999 8.46126 9.99778 8.53844 9.60982C8.6156 9.22186 8.80608 8.8655 9.08578 8.58578C9.3655 8.30608 9.72186 8.1156 10.1098 8.03842C10.4978 7.96126 10.8999 8.00086 11.2654 8.15224C11.6308 8.30362 11.9432 8.55996 12.1629 8.88886C12.3827 9.21776 12.5 9.60444 12.5 10C12.5 10.5304 12.2893 11.0391 11.9142 11.4142C11.5391 11.7893 11.0304 12 10.5 12Z" fill="#ECEFEF"/>
        <path d="M10.5 18C9.31332 18 8.15328 18.3519 7.16658 19.0112C6.17988 19.6705 5.41086 20.6076 4.95672 21.7038C4.5026 22.8002 4.38378 24.0066 4.6153 25.1706C4.8468 26.3344 5.41824 27.4036 6.25736 28.2426C7.09648 29.0818 8.16558 29.6532 9.32946 29.8848C10.4933 30.1162 11.6997 29.9974 12.7961 29.5432C13.8925 29.0892 14.8295 28.3202 15.4888 27.3334C16.1481 26.3468 16.5 25.1866 16.5 24C16.5 22.4088 15.8679 20.8826 14.7426 19.7574C13.6174 18.6321 12.0913 18 10.5 18ZM10.5 26C10.1044 26 9.71776 25.8828 9.38886 25.663C9.05996 25.4432 8.80362 25.1308 8.65224 24.7654C8.50086 24.4 8.46126 23.9978 8.53844 23.6098C8.6156 23.2218 8.80608 22.8654 9.08578 22.5858C9.3655 22.306 9.72186 22.1156 10.1098 22.0384C10.4978 21.9612 10.8999 22.0008 11.2654 22.1522C11.6308 22.3036 11.9432 22.56 12.1629 22.8888C12.3827 23.2178 12.5 23.6044 12.5 24C12.5 24.5304 12.2893 25.0392 11.9142 25.4142C11.5391 25.7892 11.0304 26 10.5 26Z" fill="#ECEFEF"/>
        <path d="M10.5 32C9.31332 32 8.15328 32.3518 7.16658 33.0112C6.17988 33.6704 5.41086 34.6076 4.95672 35.7038C4.5026 36.8002 4.38378 38.0066 4.6153 39.1706C4.8468 40.3344 5.41824 41.4036 6.25736 42.2426C7.09648 43.0818 8.16558 43.6532 9.32946 43.8848C10.4933 44.1162 11.6997 43.9974 12.7961 43.5432C13.8925 43.0892 14.8295 42.3202 15.4888 41.3334C16.1481 40.3468 16.5 39.1866 16.5 38C16.5 36.4088 15.8679 34.8826 14.7426 33.7574C13.6174 32.6322 12.0913 32 10.5 32ZM10.5 40C10.1044 40 9.71776 39.8828 9.38886 39.663C9.05996 39.4432 8.80362 39.1308 8.65224 38.7654C8.50086 38.4 8.46126 37.9978 8.53844 37.6098C8.6156 37.2218 8.80608 36.8654 9.08578 36.5858C9.3655 36.306 9.72186 36.1156 10.1098 36.0384C10.4978 35.9612 10.8999 36.0008 11.2654 36.1522C11.6308 36.3036 11.9432 36.56 12.1629 36.8888C12.3827 37.2178 12.5 37.6044 12.5 38C12.5 38.5304 12.2893 39.0392 11.9142 39.4142C11.5391 39.7892 11.0304 40 10.5 40Z" fill="#ECEFEF"/>
        <path d="M44.5 7.99804H20.5V11.998H44.5V7.99804Z" fill="#ECEFEF"/>
        <path d="M44.5 21.998H20.5V25.998H44.5V21.998Z" fill="#ECEFEF"/>
        <path d="M44.5 35.998H20.5V39.998H44.5V35.998Z" fill="#ECEFEF"/>
      </svg>
      `,
      title: 'Study and prepare for your exam',
      description: 'Our System Guide provides a comprehensive overview of all techniques and exercises at each level. Perfect for studying or preparing for your exam.',
      link: 'https://help.sami-x.com/en/articles/8278595-what-is-the-system-guide',
      image: 'https://a.storyblok.com/f/110844/571x400/a36077605f/studyguide.png'
    },
    library: {
      icon: `
      <svg class="w-full h-full" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 12.5H2V38.5C2 39.5608 2.42142 40.5782 3.17158 41.3284C3.92172 42.0786 4.93914 42.5 6 42.5H36V38.5H6V12.5Z" fill="#ECEFEF"/>
        <path d="M22 26.5L34 20.5L22 14.5V26.5Z" fill="#ECEFEF"/>
        <path d="M38.828 6.5H14C12.9391 6.5 11.9217 6.92142 11.1716 7.67158C10.4214 8.42172 10 9.43914 10 10.5V30.5C10 31.5608 10.4214 32.5782 11.1716 33.3284C11.9217 34.0786 12.9391 34.5 14 34.5H42C43.0608 34.5 44.0782 34.0786 44.8284 33.3284C45.5786 32.5782 46 31.5608 46 30.5V13.672L38.828 6.5ZM14 30.5V10.5H36V16.5H42V30.5H14Z" fill="#ECEFEF"/>
      </svg>`,
      title: 'Find all techniques and exercises',
      description: 'Dive into our extensive library of over 6000 videos with new videos added every week. Perfect as a reference when you are looking for a specific technique or new inspiration. Use the search and filters to easily navigate and find what you need.',
      link: 'https://help.sami-x.com/en/articles/8278596-what-is-the-video-library',
      image: 'https://a.storyblok.com/f/110844/600x400/98911cc53a/library.png'
    }
  }

  constructor(
    public auth: AuthService
  ) {}

}
