import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnChanges, OnInit, PLATFORM_ID } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { SamiUser } from 'interfaces';
import { FastCommentsCommentWidgetConfig, FastCommentsModule, FastCommentsSSO } from 'ngx-fastcomments';
import { lastValueFrom } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-comments-section',
  standalone: true,
  imports: [
    CommonModule,
    FastCommentsModule
  ],
  templateUrl: './comments-section.component.html'
})
export class CommentsSectionComponent implements OnInit, OnChanges {

  @Input() urlId = '';
  @Input() user!: SamiUser;
  @Input() mode: 'lesson' | 'technique' = 'lesson';

  fastCommentsConfig: FastCommentsCommentWidgetConfig | undefined;
  sso: FastCommentsSSO | undefined;

  constructor(
    private aff: AngularFireFunctions,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {}

  ngOnChanges() {
    this.setFastCommentsConfig();
  }

  ngOnInit() {
    this.getSSOToken();
  }

  async getSSOToken() {
    if (this.user && this.user.uid && this.user.email && this.user.displayName) {
      const sso = await lastValueFrom(this.aff.httpsCallable('getSSOConfig')({}));
      if (sso) {
        this.sso = sso;
        this.setFastCommentsConfig();
      }
    }
  }

  setFastCommentsConfig() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.sso) {
        return;
      }
      this.fastCommentsConfig = undefined;
      setTimeout(() => {
        this.fastCommentsConfig = {
          tenantId: 'eu-qamsL1f5cO-I',
          region: 'eu',
          hideAvatars: true,
          urlId: this.urlId,
          url: window.location.href,
          sso: this.sso,
        };
      }, 100);
    }
  }
}
