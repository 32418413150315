import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Timestamp } from '@angular/fire/firestore';
import { CartSession, environment, LineItem, Price, Product } from 'interfaces';
import { lastValueFrom, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  constructor(
    private afs: AngularFirestore
  ) { }

  public async increaseQuantityOfPrice(cart: CartSession, price: Price, currency?: string) {
    try {
      const product: Product | undefined = await lastValueFrom(this.afs.doc<Product>('stripeProducts/' + price.product).valueChanges().pipe(take(1)));

      if (!product) {
        return; // TODO: Add Message, that the underlying product does not exist!
      }

      const line_item: LineItem = {
        id: price.id,
        amount_total: price.unit_amount,
        amount_subtotal: price.unit_amount,
        amount_tax: 0,
        currency: currency ? currency : price.currency,
        name: product.name,
        description: product.description || '',
        product: price.product,
        price: price,
        tax_rates: product.metadata?.['taxRate'] ? [product.metadata?.['taxRate']] : [],
        quantity: 1,
        url: product.url,
        image: product.images.length > 0 ? product.images[0] : null
      }

      // check if line item already exists
      if (cart.item_count > 0) {
        // check if line item already exists in cart
        const line_item_index = cart.items.findIndex((lineItem: LineItem) => lineItem.id === price.id);

        if (line_item_index > -1) {
          // The line item already exists in the cart!
          cart.total_price = cart.total_price + cart.items[line_item_index].price.unit_amount;
          cart.item_count++
          cart.items[line_item_index] = {
            ...cart.items[line_item_index],
            quantity: cart.items[line_item_index].quantity++,
            amount_total: cart.items[line_item_index].amount_total + cart.items[line_item_index].price.unit_amount,
            amount_subtotal: cart.items[line_item_index].amount_subtotal + cart.items[line_item_index].price.unit_amount,
          }

        } else {
          // the line item does not exist in the cart!
          cart = this.addNewPriceToCart(cart, line_item);
        }

      }

      return cart;
    } catch (error) {
      console.error(error); // TODO: Return Error Message

      return error;
    }
  }

  public async decreaseQuantityOfPrice(cart: CartSession, priceId: string) {
    try {
      // Find Line Item in Cart
      const line_item_index = cart.items.findIndex((lineItem: LineItem) => lineItem.id === priceId);

      if (!line_item_index) {
        return;
      }

      // Check if removal of line item makes cart empty
      if (cart.item_count === 1) {
        cart = {
          ...cart,
          empty: true,
          item_count: 0,
          items: [],
          requires_shipping: false,
          taxes_included: false,
          total_discount: 0,
          total_price: 0
        };
      }

      // Reduce line item
      if (cart.items[line_item_index].quantity > 1) {
        // The line item will still exist
        cart.items[line_item_index] = {
          ...cart.items[line_item_index],
          quantity: cart.items[line_item_index].quantity--,
          amount_total: cart.items[line_item_index].amount_total - cart.items[line_item_index].price.unit_amount,
          amount_subtotal: cart.items[line_item_index].amount_subtotal - cart.items[line_item_index].price.unit_amount,
        }

        cart = {
          ...cart,
          item_count: cart.item_count--,
          total_price: cart.total_price - cart.items[line_item_index].price.unit_amount,
        }

      } else {
        // The line item will be removed from the cart
        cart = {
          ...cart,
          item_count: cart.item_count--,
          items: cart.items.filter((line_item: LineItem) => line_item.id === line_item.id),
          total_price: cart.total_price - cart.items[line_item_index].amount_total
        }
      }

      return cart;
    } catch (error) {
      console.error(error);

      return error;
    }
  }

  addNewPriceToCart(cart: CartSession, line_item: LineItem) {
    return {
      ...cart,
      items: [...cart.items, line_item],
      item_count: cart.item_count + line_item.quantity,
      empty: false,
      total_discount: 0,
      total_price: cart.total_price + line_item.amount_total
    }
  }

  public async createCartSession(currency: string | null, stripeProductId: string, stripePriceId: string | null, purpose: 'shop' | 'ticket' | 'membership', target: 'sami-x' | 'sami-international' | 'join-sami' = 'sami-international', trial_period_days = 0) {
    const product: Product | undefined = await lastValueFrom(this.afs.doc<Product>('stripeProducts/' + stripeProductId).valueChanges().pipe(take(1)));
    let price: Price | undefined;

    if (!product) {
      return 'error';
    }

    if (stripePriceId) {
      price = await lastValueFrom(this.afs.doc<Price>('stripeProducts/' + stripeProductId + '/prices/' + stripePriceId).valueChanges().pipe(take(1)));
    }

    if (!stripePriceId && product.price) {
      price = await lastValueFrom(this.afs.doc<Price>('stripeProducts/' + stripeProductId + '/prices/' + product.price).valueChanges().pipe(take(1)));
    }

    if (!price) {
      return 'error';
    }

    const now = new Date();
    const expiresInDays = 3;
    const expiryDate = now.setDate(now.getDate() + expiresInDays);

    try {
      const cartSessionId: string = this.afs.createId();

      let cartSession: CartSession = {
        id: cartSessionId,
        object: 'cart.session',
        target: target,
        base_url: location.origin,
        payment_options: price.metadata?.['paymentOptions'] ? price.metadata?.['paymentOptions'] : 'online',
        payment_selection: 'online',
        allow_guest_checkout: price.metadata?.['allowGuestCheckout'] === 'true' ? true : false,
        client_secret: null,
        purpose: purpose,
        expires_at: Timestamp.fromDate(new Date(expiryDate)),
        livemode: environment.production ? true : false,
        settings: {
          allow_promotion_codes: true
        },
        ...trial_period_days > 0 && {
          subscription_data: {
            trial_period_days: trial_period_days,
            trial_settings: {
              end_behavior: {
                missing_payment_method: 'cancel',
              },
            }
          }
        },
        currency: currency ? currency : price.currency,
        empty: true,
        item_count: 0,
        items: [],
        note: null,
        requires_shipping: false,
        taxes_included: false,
        total_discount: 0,
        total_price: 0,
        connect: product.stripeAccountId ? {
          stripeAccountId: product.stripeAccountId,
          type: price.metadata?.['organizerType'],
          id: price.metadata?.['organizerId']
        } : null,
        checkout_settings: null,
        checkout_url: null,
      }

      const line_item: LineItem = {
        id: price.id,
        amount_total: price.currency_options ? price.currency_options[currency ? currency : price.currency].unit_amount : price.unit_amount,
        amount_subtotal: price.currency_options ? price.currency_options[currency ? currency : price.currency].unit_amount : price.unit_amount,
        amount_tax: 0,
        currency: currency ? currency : price.currency,
        name: product.name,
        description: product.description || '',
        product: product,
        price: price,
        tax_rates: product.metadata?.['taxRate'] ? [product.metadata?.['taxRate']] : [],
        quantity: 1,
        url: product.url,
        image: product.images.length > 0 ? product.images[0] : null
      }

      cartSession = this.addNewPriceToCart(cartSession, line_item);

      await this.afs.doc('cartSessions/' + cartSessionId).set(cartSession);
      return cartSessionId;
    } catch (error) {
      console.error(error);
      return 'error';
    }
  }
}
