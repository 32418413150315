import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { lastValueFrom, Observable } from 'rxjs';
import { arrayUnion, arrayRemove } from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class MuxService {
  constructor(
    private afs: AngularFirestore,
    private aff: AngularFireFunctions
  ) {}

  public getVideo$(ref: any): Observable<any[]> {
    return this.afs
      .collection<any>(`muxVideoLibrary`, ref)
      .valueChanges({ idField: '_id' });
  }

  async deleteVideo(id: string, asset: any) {
    try {
      await lastValueFrom(
        this.aff.httpsCallable('deleteMuxAsset')({
          assetId: asset.id,
        })
      );
      await this.afs.collection('muxVideoLibrary').doc(id).delete();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async deleteAsset(id: string, asset: any) {
    try {
      await lastValueFrom(
        this.aff.httpsCallable('deleteMuxAsset')({
          assetId: asset.id,
        })
      );
      await this.afs.collection('muxVideoLibrary').doc(id).set(
        {
          asset: null,
        },
        { merge: true }
      );
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async setVideo(path: string, contentId: string, videos: any[]) {
    const muxAsset = await lastValueFrom(
      this.afs.doc(`muxVideoLibrary/${videos[0]._id}`).get()
    );
    await this.afs
      .collection(path)
      .doc(contentId)
      .update({
        muxVideoElement: muxAsset.data(),
        muxVideoElementId: videos[0]._id,
        muxVideoElementRef: this.afs
          .collection('muxVideoLibrary')
          .doc(videos[0]._id).ref,
      });

    if (path === 'guidedTrainings') {
      await this.afs
        .collection('muxVideoLibrary')
        .doc(videos[0]._id)
        .update({
          guidedTrainingIds: arrayUnion(contentId),
        });
    }

    if (path === 'workoutExercises') {
      await this.afs.collection(path).doc(contentId).update({
        mux_id: videos[0]._id,
        mux_playback_id: videos[0].asset?.playback_ids[0].id,
      });

      await this.afs
        .collection('muxVideoLibrary')
        .doc(videos[0]._id)
        .update({
          exerciseIds: arrayUnion(contentId),
        });
    }

    if (path === 'systemsClips') {
      await this.afs
        .collection('muxVideoLibrary')
        .doc(videos[0]._id)
        .update({
          systemsClipIds: arrayUnion(contentId),
        });
    }
  }

  async onClearMuxVideoElement(
    path: string,
    contentElement: any,
    contentId: string
  ) {
    await this.afs.collection(path).doc(contentId).update({
      muxVideoElement: null,
      muxVideoElementId: null,
      muxVideoElementRef: null,
    });

    if (path === 'guidedTrainings') {
      await this.afs
        .collection('muxVideoLibrary')
        .doc(contentElement.muxVideoElement?.asset?.passthrough)
        .update({
          guidedTrainingIds: arrayRemove(contentId),
        });
    }

    if (path === 'systemsClips') {
      await this.afs
        .collection('muxVideoLibrary')
        .doc(contentElement.muxVideoElement?.asset?.passthrough)
        .update({
          systemsClipIds: arrayRemove(contentId),
        });
    }

    if (path === 'workoutExercises') {
      await this.afs.collection(path).doc(contentId).update({
        mux_id: null,
        mux_playback_id: null,
      });

      await this.afs
        .collection('muxVideoLibrary')
        .doc(contentElement.muxVideoElement?.asset?.passthrough)
        .update({
          exerciseIds: arrayRemove(contentId),
        });
    }
  }
}
