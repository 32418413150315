import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GetDownloadURLPipeModule } from '@angular/fire/compat/storage';
import { ImagePipe } from '../../pipes/transformImage.pipe';
import { CenterCardComponent } from './center-card.component';

@NgModule({
  declarations: [CenterCardComponent],
  imports: [
    CommonModule,
    ImagePipe,
    GetDownloadURLPipeModule
  ],
  exports: [CenterCardComponent]
})
export class CenterCardModule {}
