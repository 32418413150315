import { Component, Input, OnInit } from '@angular/core';
import { SamiUser } from 'interfaces';
import { SamiIcon, SamiIconsRegistry, gift, instructor, questionCircle } from '@sami/ui';

@Component({
  selector: 'app-secondary-menu',
  templateUrl: './secondary-menu.component.html'
})
export class SecondaryMenuComponent implements OnInit {
  @Input() user: SamiUser | undefined;
  @Input() currentSite: 'instructor-area' | 'sami-x-app' | 'b2b' | 'sami-international' = 'sami-x-app';

  secondaryMenu: { icon?: SamiIcon; mode: 'internal' | 'external'; label: string; link: string }[] = [];

  constructor(private samiIconRegistry: SamiIconsRegistry) {
    this.samiIconRegistry.registerIcons([
      gift,
      questionCircle,
      instructor
    ]);
  }

  ngOnInit(): void {
    if (this.currentSite === 'instructor-area') {
      this.secondaryMenu.push(
        {
          label: 'Help',
          mode: 'external',
          link: 'https://help.sami-x.com/en/',
          icon: questionCircle
        }
      );
    } else if (this.currentSite === 'b2b') {
      this.secondaryMenu.push(
        {
          label: 'Help',
          mode: 'external',
          link: 'https://help.sami-x.com/en/',
          icon: questionCircle
        }
      );
    } else {
      if (this.user) {
        if (this.user.subscriptionDigest?.hasSubscription?.plus) {
          this.secondaryMenu.push({
            label: 'Refer a friend',
            mode: 'internal',
            link: '/app/refer-a-friend',
            icon: gift
          });
        }

        if (!this.user.subscriptionDigest?.hasSubscription?.instructor) {
          this.secondaryMenu.push({
            label: 'Become an instructor',
            mode: 'external',
            link: 'https://sami-international.com/en/for-instructors',
            icon: instructor
          });
        }

        this.secondaryMenu.push(
          {
            label: 'Help',
            mode: 'external',
            link: 'https://help.sami-x.com/en/',
            icon: questionCircle
          }
        );
      }
    }
  }

}
