import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  getWeekNumber(d: Date): number {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));

    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    const weekNo = Math.ceil(
      ((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
    );

    return weekNo;
  }

  getDatesOfWeek(w: number): Date[] {
    const fow = new Date(
      Date.UTC(new Date().getFullYear(), 0, 1 + (w - 1) * 7)
    );
    while (fow.getDay() !== 1) {
      fow.setDate(fow.getDate() + 1);
    }

    const low = new Date(fow.getTime());
    low.setDate(fow.getDate() + 6);

    return [fow, low];
  }
}
