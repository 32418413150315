import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnChanges,
  PLATFORM_ID,
} from '@angular/core';

@Component({
  selector: 'app-mux-player',
  templateUrl: './mux-player.component.html',
})
export class MuxPlayerComponent implements AfterViewInit, OnChanges {
  @Input() playbackId: string | undefined;
  @Input() userId: string | undefined;
  @Input() title: string | undefined;
  @Input() contentType: string | undefined;
  @Input() preload: 'metadata' | 'auto' | 'none' = 'auto';

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
  ) {}

  ngAfterViewInit(): void {
    this.initializeMuxPlayer();
  }

  ngOnChanges(): void {
    this.initializeMuxPlayer();
  }

  private initializeMuxPlayer(): void {
    if (isPlatformBrowser(this.platformId)) {
      // Dynamically import the mux-player module
      import('@mux/mux-player')
        .then(() => {
          this.setMuxPlayerInstance();
        })
        .catch(err => {
          console.error('Error loading @mux/mux-player:', err);
        });
    }
  }

  setMuxPlayerInstance() {
    // Create the mux-player element
    const muxPlayerElement = document.createElement('mux-player');

    // Set the attributes for the mux-player element
    muxPlayerElement.setAttribute('class', 'object-cover h-full w-full z-10 relative');

    if (this.title) {
      muxPlayerElement.setAttribute('metadata-video-title', this.title);
    }

    if (this.contentType) {
      muxPlayerElement.setAttribute('metadata-video-content-type', this.contentType);
    }

    if (this.playbackId) {
      muxPlayerElement.setAttribute('playback-id', this.playbackId);
    }
    muxPlayerElement.setAttribute('preload', 'auto');

    if (this.userId) {
      muxPlayerElement.setAttribute('metadata-viewer-user-id', this.userId);
    }

    // Get the div with id "muxPlayer"
    const containerDiv = document.getElementById('muxPlayer');

    const existingMuxPlayerElement = containerDiv && containerDiv.querySelector('mux-player');

    // Append the mux-player element to the div
    if (existingMuxPlayerElement) {
      containerDiv && containerDiv.replaceChild(muxPlayerElement, existingMuxPlayerElement);
    } else {
      containerDiv && containerDiv.appendChild(muxPlayerElement);
    }
  }
}
