import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-chip',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './chip.component.html'
})
export class ChipComponent {
  @Input() text = 'Badge';
  @Input() removable = true;
  @Output() removed = new EventEmitter();
}
