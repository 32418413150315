import { Pipe, PipeTransform } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';

@Pipe({
  name: 'stor',
})
export class StoragePipe implements PipeTransform {
  constructor(private storage: AngularFireStorage) {}

  transform(value: any): Observable<any> {
    return this.storage.ref(value).getDownloadURL();
  }
}
