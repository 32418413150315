import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
})
export class ProfileCardComponent {
  @Input() background: 'white' | 'culturedWhite' = 'white';
  @Input() cardTitle: string | undefined;
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() userId?: string;
  @Input() image?: { filename: string; alt: string };
  @Input() description?: string;
  @Input() type: 'storyblok' | 'firestore' = 'firestore';
}
