<button
  (click)="navigateToTraining(liveTraining)"
  class="flex flex-col h-full justify-start w-full hover:shadow-lg"
  *ngIf="liveTraining; else loading"
>
  <div class="bg-richBlack w-full relative">
    <div class="aspect-w-16 aspect-h-9"></div>
    <picture *ngIf="liveTraining.system.identifier">
      <source
        type="image/webp"
        [srcset]="
          defaultImage[liveTraining.system.identifier]
            | transformImg
              : 'storyblok'
              : 'fit-in/0x300/smart/filters:format(webp)'
        "
      />
      <source
        type="image/png"
        [srcset]="
          defaultImage[liveTraining.system.identifier]
            | transformImg
              : 'storyblok'
              : 'fit-in/0x300/smart/filters:format(png)'
        "
      />
      <img
        loading="lazy"
        class="absolute inset-0 w-full h-full object-cover"
        [src]="
          defaultImage[liveTraining.system.identifier]
            | transformImg: 'storyblok':'fit-in/0x300/smart'
        "
        [alt]="liveTraining.title"
      />
    </picture>
    <div
      class="bottom-3 left-3 absolute"
      *ngIf="
        liveTraining && liveTraining.system && liveTraining.system.identifier
      "
    >
      <sami-combat-system-tag [combatSystem]="liveTraining.system.identifier" *ngIf="liveTraining.system.identifier"></sami-combat-system-tag>
    </div>

    <div class="top-3 right-3 absolute" *ngIf="isAttendee">
      <div
        class="flex flex-row justify-center items-center space-x-2 py-1 px-2 bg-culturedWhite"
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.31311 8.41082L5.66666 8.76437L6.02022 8.41082L10.3333 4.09773L10.5689 4.33329L5.78445 9.11771L6.138 9.47126L5.78444 9.11771C5.75325 9.1489 5.71091 9.16646 5.66667 9.16646C5.62245 9.16646 5.58009 9.14891 5.54886 9.11768L3.43111 6.99993L3.66667 6.76437L5.31311 8.41082Z"
            fill="#001427"
            stroke="#001427"
          />
          <path
            d="M12.3333 0.333374H1.66665C1.31303 0.333374 0.973886 0.473847 0.72384 0.723901C0.473786 0.973947 0.333313 1.31309 0.333313 1.66671V12.3334C0.333313 12.687 0.473786 13.0261 0.72384 13.2762C0.973886 13.5262 1.31303 13.6667 1.66665 13.6667H12.3333C12.6869 13.6667 13.026 13.5262 13.2761 13.2762C13.5262 13.0261 13.6666 12.687 13.6666 12.3334V1.66671C13.6666 1.31309 13.5262 0.973947 13.2761 0.723901C13.026 0.473847 12.6869 0.333374 12.3333 0.333374ZM1.66665 12.3334V1.66671H12.3333V12.3334H1.66665Z"
            fill="#001427"
          />
        </svg>
        <span class="text-xs text-richBlack uppercase font-semibold">
          You're in
        </span>
      </div>
    </div>
  </div>
  <div
    [ngClass]="backgroundColor === 'white' ? 'bg-white' : 'bg-culturedWhite'"
    class="px-3 py-4 w-full flex-1 flex flex-col justify-start space-y-3"
  >
    <div class="flex flex-row justify-start">
      <sami-membership-badge [isFree]="liveTraining.isFree || false" *ngIf="!liveTraining.hasPurchased"></sami-membership-badge>
      <sami-purchased-badge *ngIf="liveTraining.hasPurchased"></sami-purchased-badge>
    </div>
    <h4 class="text-richBlack font-sans text-xl font-semibold text-left tracking-tight">
      {{ liveTraining.title }}
    </h4>
    <p class="text-richBlack font-body text-sm line-clamp-3 text-left">
      {{ liveTraining.excerpt }}
    </p>
  </div>
  <div
    *ngIf="liveTraining.startDate && !isPast"
    class="bg-cadetGrey-200 w-full font-sans px-2 py-3.5 flex flex-row justify-evenly items-center text-richBlack font-semibold text-sm"
  >
    <div class="flex flex-row justify-center items-center space-x-2">
      <svg
        class="w-5"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 3.75C0 3.33579 0.335786 3 0.75 3H17.25C17.6642 3 18 3.33579 18 3.75V17.25C18 17.6642 17.6642 18 17.25 18H0.75C0.335786 18 0 17.6642 0 17.25V3.75ZM1.5 4.5V16.5H16.5V4.5H1.5Z"
          fill="#001427"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.5 0V3.75H3V0H4.5Z"
          fill="#001427"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15 0V3.75H13.5V0H15Z"
          fill="#001427"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.25 8.25H0.75V6.75H17.25V8.25Z"
          fill="#001427"
        />
      </svg>
      <span>
        {{ liveTraining.startDate?.toDate() | date: 'dd.MM.yyyy' }}
      </span>
    </div>

    <div
      *ngIf="liveTraining.startDate"
      class="flex flex-row justify-center items-center space-x-2"
    >
      <svg
        class="w-5"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 9C0 4.02944 4.02944 0 9 0C13.9705 0 18 4.02944 18 9C18 13.9705 13.9705 18 9 18C4.02944 18 0 13.9705 0 9ZM9 1.5C4.85787 1.5 1.5 4.85787 1.5 9C1.5 13.1421 4.85787 16.5 9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85787 13.1421 1.5 9 1.5ZM9.75 8.25V4.41678H8.25V9C8.25 9.41421 8.58579 9.75 9 9.75H12.6667V8.25H9.75Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 9C0 4.02944 4.02944 0 9 0C13.9705 0 18 4.02944 18 9C18 13.9705 13.9705 18 9 18C4.02944 18 0 13.9705 0 9ZM9 1.5C4.85787 1.5 1.5 4.85787 1.5 9C1.5 13.1421 4.85787 16.5 9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85787 13.1421 1.5 9 1.5Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.75 8.25V4.41678H8.25V9C8.25 9.41421 8.58579 9.75 9 9.75H12.6667V8.25H9.75Z"
          fill="black"
        />
      </svg>
      <span>
        {{ liveTraining.startDate?.toDate() | date: 'HH:mm z' }}
      </span>
    </div>
  </div>
  <ng-container *ngIf="isPast">
    <div
      *ngIf="liveTraining.startDate"
      class="bg-cadetGrey-200 w-full px-2 py-3.5 flex flex-row justify-evenly items-center text-richBlack font-semibold text-sm"
    >
      <div
        class="flex flex-row justify-center items-center space-x-2 uppercase"
      >
        <svg
          class="w-5"
          viewBox="0 0 19 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.75 0.75C2.75 0.335786 3.08579 0 3.5 0H15.5C15.9142 0 16.25 0.335786 16.25 0.75V6C16.25 6.28408 16.0895 6.54378 15.8354 6.67082L11.1771 9L15.8354 11.3292C16.0895 11.4562 16.25 11.7159 16.25 12V17.25C16.25 17.6642 15.9142 18 15.5 18H3.5C3.08579 18 2.75 17.6642 2.75 17.25V12C2.75 11.7159 2.9105 11.4562 3.16459 11.3292L7.82295 9L3.16459 6.67082C2.9105 6.54378 2.75 6.28408 2.75 6V0.75ZM9.5 9.83853L4.25 12.4635V16.5H14.75V12.4635L9.5 9.83853ZM9.5 8.16147L4.25 5.53647V1.5H14.75V5.53647L9.5 8.16147Z"
            fill="#001427"
          />
          <path d="M3.5 6L9.5 9L15.5 6L9.5 3.75L3.5 6Z" fill="#001427" />
        </svg>
        <span> {{ daysAvailable }} more days available </span>
      </div>
    </div>
  </ng-container>
</button>

<ng-template #loading>
  <div class="bg-white w-full flex-col justify-start items-start inline-flex">
    <div class="self-stretch aspect-h-9 aspect-w-16 animate-pulse bg-cadetGrey-200"></div>
    <div class="self-stretch grow shrink basis-0 px-6 py-4 flex-col justify-start items-start gap-4 flex">
      <div class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-4 flex">
        <div class="flex-col justify-start items-start gap-2 flex">
          <div class="w-24 h-3.5 bg-cadetGrey-200 animate-pulse"></div>
          <div class="w-44 h-6 bg-cadetGrey-200 animate-pulse"></div>
        </div>
        <div class="self-stretch h-24 bg-cadetGrey-200 animate-pulse"></div>
      </div>
      <div class="self-stretch px-4 justify-between items-start gap-4 inline-flex">
        <div class="w-24 h-5 bg-cadetGrey-200 animate-pulse"></div>
        <div class="w-24 h-5 bg-cadetGrey-200 animate-pulse"></div>
      </div>
    </div>
  </div>
</ng-template>