import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LibraryService, PlaylistService } from '@sami/features';
import { SamiTechniqueClip, SystemsPlaylist } from 'interfaces';
import { combineLatest, lastValueFrom, Subscription, take } from 'rxjs';
import { SuccessSnackbarComponent } from '../../snackbars/success-snackbar/success-snackbar.component';

@Component({
  selector: 'app-collection-menu',
  templateUrl: './collection-menu.component.html',
})
export class CollectionMenuComponent implements OnInit, OnDestroy {
  @Input() userId!: string;
  @Input() clipId!: string;
  @Output() updated = new EventEmitter();

  clip: SamiTechniqueClip | undefined;
  playlists: SystemsPlaylist[] = [];
  playlistsWithoutDefaults: SystemsPlaylist[] = [];

  subscription!: Subscription;

  assignedPlaylists: SystemsPlaylist[] = [];
  assignedPlaylistsWithoutDefaults: SystemsPlaylist[] = [];
  assignedToFavorites: SystemsPlaylist[] = [];
  assignedToWatchLater: SystemsPlaylist[] = [];

  constructor(
    private playlistService: PlaylistService,
    private analytics: AngularFireAnalytics,
    private libraryService: LibraryService,
    private afs: AngularFirestore,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.subscription = combineLatest([
      this.libraryService.getClip$(this.clipId),
      this.playlistService.getOwnPlaylists$(),
    ]).subscribe(([clip, playlists]) => {
      if (clip && clip['_id']) {
        this.clip = clip;
        this.playlists = playlists
          .map((playlist: SystemsPlaylist) => ({
            ...playlist,
            isAssigned:
              playlist.clips &&
              clip['_id'] &&
              playlist.clips.includes(clip['_id']),
          }))
          .sort((a, b) => (a.isAssigned ? 1 : -1))
          .sort((a, b) => (a.isDefaultPlaylist ? -1 : 1));
        this.playlistsWithoutDefaults = this.playlists.filter(
          (playlist: SystemsPlaylist) =>
            !playlist.isDefaultPlaylist &&
            playlist.clips &&
            clip['_id'] &&
            !playlist.clips.includes(clip['_id'])
        );
        this.assignedPlaylists = this.playlists.filter(
          (playlist: SystemsPlaylist) => playlist['isAssigned']
        );
        this.assignedPlaylistsWithoutDefaults = this.assignedPlaylists.filter(
          (playlist: SystemsPlaylist) => !playlist.isDefaultPlaylist
        );
        this.assignedToFavorites = this.assignedPlaylists.filter(
          (playlist: SystemsPlaylist) => playlist['id'] === 'favorites'
        );
        this.assignedToWatchLater = this.assignedPlaylists.filter(
          (playlist: SystemsPlaylist) => playlist['id'] === 'watchLater'
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async onAssignClipToPlaylist(playlistId: string | undefined): Promise<void> {

    if (!playlistId) return;

    try {
      if (this.clip) {
        const clip = {
          clipId: this.clipId,
          ref: this.afs.doc('systemsClips/' + this.clipId).ref,
          title: this.clip.title,
          style: this.clip.style,
          thumbnails: this.clip.thumbnails,
          assetType: this.clip.assetType || 'vimeo',
          muxVideoElement: this.clip.muxVideoElement || null,
          level: this.clip.level,
          type: 'systemsClip',
        };

        const playlist: SystemsPlaylist | undefined = await lastValueFrom(
          this.playlistService.getOwnPlaylist$(playlistId).pipe(take(1))
        );
        if (
          playlist &&
          playlist.clips &&
          playlist.chapters &&
          playlist.chapters[0] &&
          playlist.chapters[0].clips &&
          this.clip
        ) {
          playlist.clips.push(this.clipId);
          playlist.chapters[0].clips.push(clip);

          await this.playlistService.updateOwnPlaylist(playlistId, playlist);
          await this.analytics.logEvent('add video to own collection', {
            playlistId: playlistId,
          });
          this.updated.emit('add');
          this.snackBar.openFromComponent(SuccessSnackbarComponent, {
            data: {
              title: 'Successfully added!',
              subtitle: 'Added video to collection',
            },
            panelClass: 'samix-toast',
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 5000,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async onRemoveClipFromPlaylist(playlistId: string | undefined): Promise<void> {

    if (!playlistId) return;

    try {
      const playlist: SystemsPlaylist | undefined = await lastValueFrom(
        this.playlistService.getOwnPlaylist$(playlistId).pipe(take(1))
      );
      if (
        playlist &&
        playlist.clips &&
        playlist.chapters &&
        playlist.chapters[0] &&
        playlist.chapters[0].clips
      ) {
        playlist.clips = playlist.clips.filter(
          (clipId: string) => clipId !== this.clipId
        );
        playlist.chapters[0].clips = playlist.chapters[0].clips.filter(
          (clip: any) => clip.ref.id !== this.clipId
        );
        await this.playlistService.updateOwnPlaylist(playlistId, playlist);
      }
      await this.analytics.logEvent('remove video from own collection', {
        playlistId: playlistId,
      });
      this.updated.emit('remove');

      this.snackBar.openFromComponent(SuccessSnackbarComponent, {
        data: {
          title: 'Successfully removed!',
          subtitle: 'Removed video from collection',
        },
        panelClass: 'samix-toast',
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: 5000,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async onCreateNewPlaylist(title: string): Promise<void> {
    try {
      const playlist = {
        title,
        description: '',
        subtitle: '',
        createdBy: this.afs.doc('users/' + this.userId).ref,
        styles: ['general'],
        clips: [],
        chapters: [
          {
            title: 'Clips',
            description: '',
            clips: [],
          },
        ],
      };

      await this.playlistService.addOwnPlaylist(playlist);
      await this.analytics.logEvent('create own collection', {
        title: title,
      });

      this.updated.emit('create');
    } catch (error) {
      console.error(error);
    }
  }
}
