<section
  [ngClass]="background === 'white' ? 'bg-white' : 'bg-culturedWhite'"
  class="grid grid-cols-2 sm:grid-cols-2 gap-4 lg:grid-cols-4"
>
  <ng-container *ngIf="stats$ | async as stats; else noStats">
    <sami-stat [stats]="stats.lessonsCompleted" [title]="'Lesson completed'"></sami-stat>
    <sami-stat [stats]="stats.videosWatched" [title]="'Techniques watched'"></sami-stat>
    <sami-stat [stats]="stats.liveTrainingsCompleted" [title]="'Live Trainings completed'"></sami-stat>
    <sami-stat [stats]="stats.trainingCreatorTrainingsCompleted" [title]="'Training Creator Trainings completed'"></sami-stat>
  </ng-container>
  <ng-template #noStats>
    <sami-stat [stats]="0" [title]="'Lesson completed'"></sami-stat>
    <sami-stat [stats]="0" [title]="'Techniques watched'"></sami-stat>
    <sami-stat [stats]="0" [title]="'Live Trainings completed'"></sami-stat>
    <sami-stat [stats]="0" [title]="'Training Creator Trainings completed'"></sami-stat>
  </ng-template>
</section>
