<div class="bg-richBlack grid sm:grid-cols-3 gap-3 sm:gap-6">
  <div
    class="sm:col-span-2 flex flex-col justify-center items-start space-y-3 sm:space-y-6 px-6 pt-10 sm:py-10 pb-4"
  >
    <span class="text-white text-xl sm:text-3xl font-semibold">
      Bring your fighting skills to the next level!
    </span>
    <p class="text-white text-sm sm:text-base">
      Learn whenever you want and wherever you are. With our extensive learning
      tracks, our intuitive trainings and a huge video library, you will get
      better faster than ever before!
    </p>
    <div class="flex flex-row justify-start space-x-3">
      <button
        routerLink="/en/pricing"
        class="bg-white text-richBlack hover:bg-cadetGrey-100 px-4 py-2 rounded-none uppercase text-base sm:text-lg"
      >
        Try for free
      </button>

      <button
        routerLink="/en"
        class="bg-transparent text-white px-4 py-2 rounded-none underline sm:text-base text-sm font-body"
      >
        Learn more
      </button>
    </div>
  </div>
  <div class="relative px-6 pb-10 pt-0 sm:py-10">
    <img
      class="absolute hidden sm:block inset-0 w-full h-full object-cover"
      src="https://a.storyblok.com/f/110844/1024x1230/f9e577041d/white-x.png"
      alt="Hero illustration"
      width="400"
      height="400"
    />
    <img
      class="z-2 relative"
      src="https://a.storyblok.com/f/110844/355x234/9dc848233b/sami-x.png"
      alt="SAMI-X"
    />
  </div>
</div>
