import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatBottomSheet, MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { PaymentService, UiService } from '@sami/features';
import { Observable } from 'rxjs';
import { PaywallDialogComponent } from '../dialogs/paywall-dialog/paywall-dialog.component';
import { PaywallBottomsheetComponent } from '../dialogs/paywall-bottomsheet/paywall-bottomsheet.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-access-button',
  standalone: true,
  imports: [
    CommonModule,
    MatBottomSheetModule
  ],
  templateUrl: './access-button.component.html'
})
export class AccessButtonComponent {
  @Input() oneTimePurchaseData: {
    docId: string;
    title: string;
    contentType: 'systemsClips' | 'guidedTrainings' | 'liveTrainings' | 'tracks';
    currency: string;
    successUrl?: string;
    cancelUrl?: string;
    returnUrl?: string;
    stripeCustomerId: string;
    image: string;
  } | undefined;

  price$: Observable<any> = new Observable<any>();

  @Output() accessButtonClicked: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private paymentService: PaymentService,
    private afs: AngularFirestore,
    private dialog: MatDialog,
    public ui: UiService,
    private bottomSheet: MatBottomSheet
  ) {
  }

  public openPaywallDialog(isMobile: boolean): void {
    console.log(isMobile);
    if (isMobile) {
      const paywallBottomSheetRef = this.bottomSheet.open(PaywallBottomsheetComponent, {
        panelClass: 'sami-bottomsheet', // Todo: Dünkleres Overlay
        data: {
          oneTimePurchaseData: this.oneTimePurchaseData ? this.oneTimePurchaseData : null
        }
      });
    } else {
      const paywallDialogRef = this.dialog.open(PaywallDialogComponent, {
        panelClass: 'sami-dialog', // Todo: Dünkleres Overlay
        backdropClass: 'bg-richBlack/80',
        maxHeight: '90vh',
        data: {
          oneTimePurchaseData: this.oneTimePurchaseData ? this.oneTimePurchaseData : null
        },
      });
    }
  }
}
