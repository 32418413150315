import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-payment-status-dialog',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './payment-status-bottomsheet.component.html',
  styles: [],
})
export class PaymentStatusBottomSheetComponent {
  status: 'success' | 'canceled' | 'returned';
  productTitle = '';

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { status: 'success' | 'canceled' | 'returned', productTitle: string },
    public bottomsheetRef: MatBottomSheetRef <PaymentStatusBottomSheetComponent>,
  ) {
    this.status = data.status;
    this.productTitle = data.productTitle;
  }
}
