<div class="flex flex-col p-4 justify-start items-center space-y-4 text-richBlack">
  <svg
    class="w-16 h-16"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM12.9648 7.03857H10.9648V5.03857H12.9648V7.03857ZM11 11H9V9H13V16H15V18H13H11H9V16H11V11Z"
      clip-rule="evenodd"
    ></path>
  </svg>
  <span class="text-xl text-center">
    Do you really want to cancel this {{ mode === 'workout' ? 'workout? All activities will be deleted.' : 'exam?' }}
  </span>
  <button
    (click)="continueTraining()"
    class="bg-richBlack w-56 border-2 border-richBlack px-6 py-3 flex-1 flex flex-row justify-center text-center items-center space-x-4"
  >
    <span class="text-cadetGrey-200 text-xl text-center">
      Continue
    </span>
  </button>
  <button
    (click)="cancelTraining()"
    class="bg-white w-56 border-2 border-richBlack px-6 py-3 flex-1 flex flex-row justify-center text-center items-center space-x-4"
  >
    <span class="text-richBlack text-xl text-center"> Cancel </span>
  </button>
</div>
