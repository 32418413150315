<div class="relative w-full">
  <div class="flex w-full flex-row justify-between items-end absolute -top-6">
    <label [ngClass]="displayLabel ? 'block text-base font-semibold text-richBlack' : 'sr-only'" [for]="fieldName">
      {{ label }}
    </label>
    <span *ngIf="!validator" class="text-xs flex-1 text-right uppercase text-cadetGrey-400 font-body">
      optional
    </span>
  </div>
  <div class="flex flex-col justify-start space-y-0.5">
    <input
      #input
      (input)="onChange( $event )"
      (blur)="touched()"
      [disabled]="isDisabled || disabled"
      [id]="fieldName"
      [name]="fieldName"
      (keyup)="search(input.value)"
      [matAutocomplete]="autocomplete"
      [type]="type"
      class="block w-full border px-3 py-2 font-body rounded-none border-cadetGrey-300 shadow-none focus:border-cadetGrey-600 focus:ring-cadetGrey-600 sm:text-sm"
      [ngClass]="{
      'border-statusColors-red placeholder:text-statusColors-darkRed focus:border-statusColors-red focus:ring-statusColors-red':
          formField.invalid
          && formField.dirty,
      'text-cadetGrey-400': disabled
      }"
      [placeholder]="placeholder"
    />

    <mat-autocomplete #autocomplete="matAutocomplete">
      <ng-container *ngIf="type === 'center'">
        <mat-option
          (click)="onSelect(element)"
          *ngFor="let element of filteredElements"
          [value]="element.name"
        >
          {{ element.name }}
        </mat-option>
      </ng-container>

      <ng-container *ngIf="type === 'instructor'">
        <mat-option
          (click)="onSelect(element)"
          *ngFor="let element of filteredElements"
          [value]="element.displayName"
        >
          {{ element.displayName }}
        </mat-option>
      </ng-container>
    </mat-autocomplete>

    <div class="flex flex-row justify-between items-start space-x-2">
      <sami-field-errors *ngIf="formField.invalid" [formField]="formField"></sami-field-errors>
      <span *ngIf="hint && !formField.invalid" class="text-xs font-body text-cadetGrey-600" [innerText]="hint"></span>
    </div>
  </div>
</div>