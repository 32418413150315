import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SeminarTicketType } from 'interfaces';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-ticket-type-dialog',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './ticket-type-dialog.component.html'
})
export class TicketTypeDialogComponent {
  ticket: SeminarTicketType | undefined;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TicketTypeDialogComponent>,
  ) {
    this.ticket = this.data.ticket;
  }
}
