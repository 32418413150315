import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { arrayRemove, arrayUnion } from '@angular/fire/firestore';
import { SamiPublicProfile } from 'interfaces';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Component({
  selector: 'app-edit-instructors',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './instructors.component.html',
})
export class EditInstructorsComponent implements OnInit {
  @Input() center: any | undefined = undefined;
  isBusy$ = new BehaviorSubject<boolean>(false);
  centerInstructors$: Observable<SamiPublicProfile[] | null> = new Observable();

  constructor(private afs: AngularFirestore) {}

  ngOnInit() {
    if (this.center && this.center.id) {
      this.centerInstructors$ = this.center.id
        ? this.afs
            .collectionGroup<SamiPublicProfile[]>('publicProfiles', (ref) =>
              ref
                .where('center.id', '==', this.center.id)
                .where('isPublic', '==', true)
                .where('instructorRank', '>', 0)
                .orderBy('instructorRank', 'desc')
            )
            .valueChanges({ idField: 'id' })
        : of(null);
    }
  }

  async updateBlacklistedInstructorIds(id: string) {
    if (this.center && this.center.id) {
      if (
        this.center?.blacklistedInstructorIds &&
        this.center.blacklistedInstructorIds.includes(id)
      ) {
        this.afs.doc(`centers/${this.center.id}`).update({
          blacklistedInstructorIds: arrayRemove(id),
        });
      } else {
        this.afs.doc(`centers/${this.center.id}`).update({
          blacklistedInstructorIds: arrayUnion(id),
        });
      }
    }
  }
}
