import { Component, Inject } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { AuthService } from '@sami/features';
import { SamiUser, Survey } from 'interfaces';
import { lastValueFrom } from 'rxjs';
import { Timestamp } from "firebase/firestore";
import { serverTimestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-offboarding-process',
  templateUrl: './offboarding-process.component.html',
})
export class OffboardingProcessComponent {
  offboardingForm: FormGroup;

  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private aff: AngularFireFunctions,
    private afs: AngularFirestore,
    public auth: AuthService,
    private analytics: AngularFireAnalytics,
    public dialogRef: MatDialogRef<OffboardingProcessComponent>
  ) {
    this.offboardingForm = this.fb.group({
      reason: this.fb.control(''),
      text: this.fb.control(''),
    });
  }

  async onCancelMembership(stepper: MatStepper, user: SamiUser) {
    this.loading = true;
    const form = this.offboardingForm.getRawValue();

    try {

      const surveyId = this.afs.createId();
      const survey: Survey = {
        id: surveyId,
        title: 'Cancel Membership',
        user: user || null,
        createdAt: new Date(),
        data: {
          reason: form.reason,
          text: form.text,
        }
      };

      await this.afs.doc('app/surveys/offboarding/' + surveyId).set(survey, { merge: true });

      await this.analytics.logEvent('cancel-membership', {
        reason: form.reason,
        text: form.text,
      });

      await lastValueFrom(
        this.aff.httpsCallable('toggleEndOfSubscription')({
          subscriptionId: this.data.subscription.subscriptionId,
          endSubscription: true,
          ...(form.text || form.reason) && {
            cancellation_details: {
              comment: form.text,
              feedback: form.reason,
            }
          }
        })
      );

      stepper.next();
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }
}
