import { Timestamp } from "@angular/fire/firestore";

export interface LiveTraining {
  [key: string]: any;
  _id?: string;
  id: string;
  startDate: Timestamp;
  status: 'published' | 'draft';
  stripeProductId?: string;
  stripePriceId?: string;
  stripePaymentLink?: string;
  promotionCodes: {
    code: string;
    percent_off: number;
  }[];
  system: {
    category: string;
    categoryText: string;
    color: string;
    identifier: string;
    title: string;
  };
  attendees: number;
  hmsRoomId: string;
  title: string;
  duration: number;
  hasAccess?: boolean;
  hasPurchased?: boolean;
  description?: string;
  excerpt?: string;
  imageDetailPath?: string;
  instructor?: {
    displayName: string;
    id: string;
    photoURL: string;
  };
  isFree?: boolean;
  numberOfParticipants: 'single' | 'partner';
  rounds: number;
  thumbnailPath?: string;
  weeks: number[];
  exercises: number;
}