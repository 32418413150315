import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VimeoPlayerComponent } from './vimeo-player.component';

@NgModule({
  declarations: [VimeoPlayerComponent],
  imports: [CommonModule],
  exports: [VimeoPlayerComponent],
})
export class VimeoPlayerModule {}
