import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { SamiSeminar, SeminarTrack, Track } from 'interfaces';
import { map, Observable, of } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-track-content',
  templateUrl: './track-content.component.html',
  standalone: true,
  imports: [CommonModule, CdkAccordionModule],
})
export class TrackContentComponent implements OnInit {
  @Input() seminar: SamiSeminar | undefined;
  @Input() seminarTrack: SeminarTrack | undefined;
  @Input() showTitle: boolean | undefined = true;
  @Input() background: 'white' | 'culturedWhite' = 'white';
  track$: Observable<Track | undefined> = of(undefined);

  availableForTicketTypes!: string;

  constructor(
    private afs: AngularFirestore
  ) {
  }

  ngOnInit(): void {
    if (this.seminarTrack && this.seminarTrack.id) {
      const availableForTicketTypes = this.seminar?.ticketTypes?.filter((tt: any) => this.seminarTrack?.ticketTypeIds?.includes(tt.stripePriceId)).map((tt: any) => tt.name) ?? [];
      this.availableForTicketTypes = availableForTicketTypes.length === this.seminar?.ticketTypes?.length ? 'all ticket types' : availableForTicketTypes.join(', ');

      this.track$ = this.afs.doc<Partial<Track>>(`tracks/${this.seminarTrack.id}`).valueChanges().pipe(
        map((track: any) => ({
          ...track,
          chapters: track.chapters.map((chapter: any) => ({
            ...chapter,
            _totalDuration: chapter.lessons?.reduce((acc: any, cur: any) => acc += (cur.duration ?? 0), 0),
          })),
          _totalDuration: track.chapters.reduce((a1: any, c1: any) => a1 += c1.lessons?.reduce((a2: any, c2: any) => a2 += (c2.duration ?? 0), 0) ?? 0, 0),
        })),
      );
    }
  }
}
