<div class="p-4 relative" *ngIf="ticket">
  <div class="flex flex-row justify-between items-center">
    <h1 class="font-sans text-lg sm:text-2xl font-semibold tracking-tight">
      {{ ticket.name }}
    </h1>
    <button (click)="dialogRef.close()" class="w-4 h-4">
      <svg
        class="w-4 h-4"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3849_30117)">
          <path
            d="M1 1L23 23"
            stroke="black"
            stroke-width="2"
            stroke-linejoin="round"
          />
          <path
            d="M23 1L1 23"
            stroke="black"
            stroke-width="2"
            stroke-linejoin="round"
          />
        </g>
      </svg>
    </button>
  </div>

  <div [innerHTML]="ticket.description?.content" class="prose prose-sm font-body text-black"></div>

</div>