import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FieldErrorsComponent } from '../field-errors/field-errors.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-input',
  standalone: true,
  imports: [CommonModule, FieldErrorsComponent],
  templateUrl: './input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SamiInputComponent), multi: true,
    }
  ],
})
export class SamiInputComponent implements ControlValueAccessor {

  @Input() parentForm!: FormGroup;
  @Input() fieldName!: string;
  @Input() label!: string;
  @Input() placeholder = '';
  @Input() hint: string | undefined;
  @Input() disabled = false;
  @Input() displayLabel = false;
  @Input() maxLengthLabel: number | undefined;
  @Input() type: 'text' | 'email' | 'password' | 'number' | 'tel' | 'url' = 'text';
  @Input() showForgotPassword = false;
  @Input() changeEmail = false;

  @Output() clickedOnLink = new EventEmitter();

  public isDisabled = false;
  public value = '';
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public changed: (value: string) => void = () => { };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public touched: () => void = () => { };

  get formField(): FormControl {
    return this.parentForm.get(this.fieldName) as FormControl;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  public onChange(event: Event): void {
    const value: string = (event.target as HTMLInputElement).value;
    this.changed(value);
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  get validator() {
    if (this.formField.validator) {
      const validator = this.formField.validator({} as AbstractControl);
      if (validator && validator['required']) {
        return true;
      }
    }
    return false;
  }
}
