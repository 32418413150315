import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { LibraryService } from '@sami/features';
import { lastValueFrom, take } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-category-description',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './category-description.component.html'
})
export class CategoryDescriptionComponent implements OnChanges {
  @Input() system: string | null = null;
  @Input() category: string | null = null;
  @Input() subcategory: string | null = null;
  @Input() displayCategory = true;
  @Input() displaySubcategory = true;

  displayCategoryDescription: string | null = null;
  displaySubcategoryDescription: string | null = null;

  constructor(
    private afs: AngularFirestore,
    private library: LibraryService
  ) { }

  ngOnChanges(): void {
    if (this.system && this.category && this.subcategory) {
      this.getCategoryDescription();
    }
  }

  async getCategoryDescription() {
    if (!this.system || !this.category || !this.subcategory) {
      return;
    }
    const system: any = await lastValueFrom(this.library.getSystem$(this.system).pipe(take(1)));
    const category = system.categories.find((c: any) => c.title === this.category);
    const subcategory = category.subCategories.find((s: any) => s.title === this.subcategory);

    this.displayCategoryDescription = category.displayTitle || category.title;
    this.displaySubcategoryDescription = subcategory.displayTitle || subcategory.title;
  }

}
