import { Component, Input, OnInit, inject } from '@angular/core';
import { Auth, user } from '@angular/fire/auth';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Firestore, doc, docData, getDoc } from '@angular/fire/firestore';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { AuthService, UiService } from '@sami/features';
import { TrackCard, TrackUserProgress } from 'interfaces';
import { MenuService } from 'header';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-current-track-card',
  templateUrl: './current-track-card.component.html',
})
export class CurrentTrackCardComponent implements OnInit {
  firestore: Firestore = inject(Firestore);
  auth: Auth = inject(Auth);

  @Input() type: 'last' | 'current' = 'current';
  @Input() trackCard: TrackCard | undefined | null;

  userProgress: TrackUserProgress | undefined | null;

  constructor(public ui: UiService, public menuService: MenuService, private analytics: AngularFireAnalytics) {}

  ngOnInit(): void {
    this.userProgress = getDoc(
      doc(this.firestore, 'tracks/' + this.trackCard?._id + '/trackProgress/' + this.auth.currentUser?.uid )
    ).then((doc) => {
      this.userProgress = doc.data();
    });
  }


  getTheme(trackCard: TrackCard | undefined | null) {
    if (trackCard) {
      if (trackCard.category) {
        if (trackCard.category === 'SAMI-X Fighting Arts') {
          return 'bg-fightingRed-700';
        } else if (trackCard.category === 'SAMI-X Defense') {
          return 'bg-defensiveGreen-500';
        } else if (trackCard.category === 'SAMI-X Pro') {
          return 'bg-professionalYellow-300';
        } else {
          return 'bg-fightingRed-700';
        }
      }

      return 'bg-richBlack';
    } else {
      return 'bg-richBlack';
    }
  }

  continueCurrentTrack() {
    this.analytics.logEvent('continue_current_track', { trackId: this.trackCard?._id, trackTitle: this.trackCard?.title });
    this.menuService.navigate('/app/tracks/' + this.trackCard?._id, '/app/progress', this.trackCard?.title || 'Track')
  }
}
