import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { SamiCenter } from 'interfaces';
import { Observable } from 'rxjs';
import { UploadGalleryComponent } from './upload-gallery/upload-gallery.component';
import { EditInstructorsComponent } from './instructors/instructors.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { CombatSystemComponent } from './combat-system/combat-system.component';

@Component({
  selector: 'app-edit-center-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    UploadGalleryComponent,
    EditInstructorsComponent,
    ContactComponent,
    AboutComponent,
    CombatSystemComponent
  ],
  templateUrl: './edit-center-modal.component.html',
})
export class EditCenterModalComponent implements OnInit {
  activeLink = 'about';
  navLinks = [
    {
      label: 'About',
      link: 'about',
    },
    {
      label: 'Contact Details',
      link: 'contact-details',
    },
    {
      label: 'Combat Systems',
      link: 'combat-systems',
    },
    {
      label: 'Instructors',
      link: 'instructors',
    },
    {
      label: 'Gallery',
      link: 'gallery',
    },
  ];

  center$: Observable<SamiCenter> = new Observable();
  tinyEditorLoading = false;
  tinyEditorLoaded = false;

  constructor(
    public dialogRef: MatDialogRef<EditCenterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(PLATFORM_ID) private platformId: any,
    private afs: AngularFirestore,
  ) {
    if (data.centerId) {
      this.center$ = this.afs
        .doc<any>(`centers/${data.centerId}`)
        .valueChanges({ idField: 'id' });
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.loadTinyEditor();
    }
  }

  loadTinyEditor() {
    if (this.tinyEditorLoaded || this.tinyEditorLoading) {
      return;
    }

    if (!document.getElementById('sami-tiny-editor')) {
      // One way of doing this: dynamically load a script tag.
      this.tinyEditorLoading = true;
      const tinyScript = document.createElement('script');
      tinyScript.setAttribute('async', '');
      tinyScript.id = 'sami-tiny-editor';
      tinyScript.referrerPolicy = 'origin';
      tinyScript.src =
        'https://cdn.tiny.cloud/1/fff2303go3z5jayxdtyadczrh4y61new4mycg9xyheawrbwq/tinymce/6/tinymce.min.js';
      tinyScript.addEventListener('load', () => {
        this.tinyEditorLoaded = true;
        this.tinyEditorLoading = false;
      });
      document.head.appendChild(tinyScript);
    } else {
      this.tinyEditorLoaded = true;
    }
  }
}
