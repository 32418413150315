import { Component, Input, OnChanges } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FormBuilder, Validators } from '@angular/forms';
import { SamiPublicProfile } from 'interfaces';
import { BehaviorSubject, finalize } from 'rxjs';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
})
export class AboutComponent implements OnChanges {
  @Input() instructor: SamiPublicProfile | undefined;
  isBusy$ = new BehaviorSubject<boolean>(false);

  form = this.fb.group({
    description: ['', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private afs: AngularFirestore,
    private storage: AngularFireStorage
  ) {}

  ngOnChanges(): void {
    if (this.instructor) {
      this.form.patchValue({
        description: this.instructor.description,
      });
    }
  }

  async submit() {
    if (this.form.valid && this.instructor) {
      this.isBusy$.next(true);
      try {
        await this.afs
          .doc(
            `users/${this.instructor.id}/publicProfiles/${this.instructor.id}`
          )
          .set(
            {
              description: this.form.value.description,
            },
            { merge: true }
          );

        this.isBusy$.next(false);
      } catch (error) {
        console.error(error);
        this.isBusy$.next(false);
      }
    }
  }

  uploadFile(event: any) {
    if (this.instructor && this.instructor.id) {
      const file = event.target.files[0];
      const filePath = `users/${this.instructor.id}/publicProfiles/${this.instructor.id}/gallery/${file.name}`;
      const task = this.storage.upload(filePath, file);

      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            this.updateProfileImage(filePath);
          })
        )
        .subscribe();
    }
  }

  async updateProfileImage(path: string) {
    if (this.instructor && this.instructor.id) {
      this.afs
        .doc(`users/${this.instructor.id}/publicProfiles/${this.instructor.id}`)
        .set(
          {
            profileImagePath: path,
          },
          { merge: true }
        );
    }
  }
}
