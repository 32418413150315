<ng-container *ngIf="fileUrl$ | async as url">
  <ng-container *ngIf="meta$ | async as meta">
    <ng-container *ngIf="type === 'video'">
      <video width="100%" controls>
        <source [src]="url" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </ng-container>
    <ng-container *ngIf="type === 'image'">
      <div class="flex flex-col justify-start items-start space-y-1">
        <img [src]="url" />
        <sami-button (click)="clear.emit()" size="small" mode="secondary" text="Clear"></sami-button>
      </div>
    </ng-container>
    <ng-container *ngIf="type === 'pdf'">
      <div class="flex flex-col justify-start items-start space-y-1">
        <a [href]="url" download target="_blank" mat-flat-button color="primary">Download File</a>
        <sami-button (click)="clear.emit()" size="small" mode="secondary" text="Clear"></sami-button>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
