// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'sami-272007',
    appId: '1:632126702347:web:69709c94e64050f9a3d80e',
    databaseURL: 'https://sami-272007.firebaseio.com',
    storageBucket: 'sami-272007.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyDu0tJcORMAAlfs1muqtW28RGwyLZjy4yk',
    authDomain: 'sami-272007.firebaseapp.com',
    messagingSenderId: '632126702347',
    measurementId: 'G-YEX0C3Q5P6',
  },
  production: false,
  url: 'http://localhost:4200/',
  urls: {
    samix: 'http://localhost:4200/',
    sami_international: 'http://localhost:4200/',
    embed: 'https://embed-sami.web.app/',
  },
  algolia: {
    appId: 'PVL356RKY9',
    apiKey: '74513c9c6ce04201e905e74a4e2f92a6',
  },
  imageKit: {
    publicKey: 'public_HGWLJ6nur60JkKvJufbkiiEBZLI=',
    urlEndpoint: 'https://ik.imagekit.io/samix/',
    authenticationEndpoint: 'https://sami-dev.web.app/imagekitio-auth',
  },
  stripe_key:
    'pk_live_51HAi4dFh6B0Q2BhaupynhH3HFWzQBehKIJRl2mZSjojdENuwZ13uDsOGYZfhzFPQ5ZTbeRtYj54WWwSqaY4tAdFp00a6uSyyws',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
