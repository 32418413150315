import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SeoService, StoryblokService } from '@sami/features';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultComponent implements OnInit {
  story: any = { content: null, name: '' };

  constructor(
    public storyblokService: StoryblokService,
    private seo: SeoService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.storyblokService
      .getStory(`${this.router.url}`)
      .then((data) => {
        if (this.story.content && this.story.content.SEO) {
          this.seo.generateTags(
            {
              title: this.story.content.SEO.title,
              description: this.story.content.SEO.description,
              image: this.story.content.SEO.og_image,
            }
          );
        }
        this.story = data.story;
      });
  }
}
