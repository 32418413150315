import { Location, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService, UiService } from '@sami/features';
import { GuidedTraining, SamiExam, SamiTechniqueClip, Track } from 'interfaces';
import { SamiIcon, SamiIconsRegistry, awardBadgeCertification, boxingBag, boxingGlove, checkBox, defensiveIcon, fightingArtIcon, folders, graphStatsAscend, musicPlayerButtonRecord, proIcon, samiListBulletPoints, samiPublish, samiSchoolOnline, synchornizeArrowTime, tracks, userMultiple, videoMediaFiles } from '@sami/ui';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

interface SamiMenu {
  title: string;
  items: SamiMenuItem[];
}

interface SamiMenuItem {
  icon?: SamiIcon,
  title: string,
  route: string,
  active?: boolean,
  submenu?: {
    cols?: number;
    items: SamiSubmenuItem[];
    featured?: SamiSubmenuItem;
  }
}

interface SamiSubmenuItem {
  title: string;
  route: string;
  description: string;
  image?: string;
  isFeatured?: boolean;
}

interface SamiMenuSettings {
  backgroundColor?: 'white' | 'black' | 'red' | 'green' | 'yellow';
  textColor?: 'white' | 'black';
  displayMobileMenu: boolean;
  title: string;
  route?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private defaultBackUrl: string | undefined = undefined;

  private navigationStack: {
    url: string,
    navbarText: string,
    queryParams?: { [key: string]: any }
    queryParamsHandling?: 'merge' | 'preserve',
  }[] = [];
  navbarTextChanged = new BehaviorSubject<string>('');
  showBackButton = new BehaviorSubject<{
    show: boolean,
    url?: string,
    queryParamsHandling?: 'merge' | 'preserve',
    queryParams?: { [key: string]: any }
  }>({
    show: false,
    url: undefined,
    queryParamsHandling: undefined,
    queryParams: undefined
  });

  samiInternationalMenuItems: any[] = [
    {
      items: [
        { title: 'Combat Systems', route: '/en/combat-systems', active: true },
        {
          title: 'For Students',
          route: '/en/for-students',
          active: true,
          submenu: {
            cols: 1,
            items: [
              {
                title: 'SAMI Centers & Academies',
                route: '/en/centers',
                description: 'Find a SAMI Center or an Academy to start your learning journey.',
              },
              {
                title: 'SAMI Instructors',
                route: '/en/instructors',
                description: 'Find a SAMI Instructor who will teach you our combat systems on a one-to-one basis.',
              },
            ],
          }
        },
        {
          title: 'For Instructors',
          route: '/en/for-instructors',
          active: true,
          submenu: {
            items: [
              {
                title: 'Become an Instructor',
                route: '/en/for-instructors',
                description: 'Join our team and become a certified SAMI instructor.',
              },
              {
                title: 'In Person Courses',
                route: '/en/for-instructors/in-person-courses',
                description: 'Start your instructor journey with an in-person event.',
              },
              {
                title: 'Online Courses',
                route: '/en/for-instructors/online-courses',
                description: 'Start your instructor journey with an online course.',
              },
              {
                title: 'Ongoing development',
                route: '/en/for-instructors/ongoing-development',
                description: 'See how you can take your instructor career even further.',
              }
            ],
            featured: {
              title: 'Get in touch',
              route: '/en/for-instructors/contact',
              description: 'Want to find out more or got any questions? Contact us or schedule a call.',
            }
          }
        },
        { title: 'Courses & Events', route: '/en/seminars', active: true },
        { title: 'About Us', route: '/en/about', active: true },
      ]
    }
  ];

  samiMenuSettings: SamiMenuSettings = {
    displayMobileMenu: true,
    title: ''
  };

  menuItems$: Observable<any[]>;

  subpage: 'lesson' | 'video' | 'liveTraining' | 'guidedTraining' | 'workout' | undefined = undefined;

  constructor(
    private samiIconRegistry: SamiIconsRegistry,
    public router: Router,
    private ui: UiService,
    private auth: AuthService,
    private location: Location,
    private breakpointObserver: BreakpointObserver,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.samiIconRegistry.registerIcons([
      awardBadgeCertification,
      tracks,
      graphStatsAscend,
      folders,
      synchornizeArrowTime,
      videoMediaFiles,
      boxingBag,
      boxingGlove,
      musicPlayerButtonRecord,
      userMultiple,
      checkBox,
      samiSchoolOnline,
      proIcon,
      fightingArtIcon,
      defensiveIcon,
      samiListBulletPoints,
      samiPublish
    ]);

    this.menuItems$ =
      combineLatest([
        this.auth.user$,
        this.ui.currentSite$,
        this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet])
      ]).pipe(
        map(([user, currentSite, breakpointState]) => {
          let menuItems: any[] = [];
          switch (currentSite) {
            case 'sami-international': {
              if (!user) {
                menuItems = [
                  {
                    items: [
                      { title: 'Combat Systems', route: '/en/combat-systems', active: true },
                      {
                        title: 'For Students',
                        route: '/en/for-students',
                        active: true,
                        submenu: {
                          cols: 1,
                          items: [
                            {
                              title: 'SAMI Centers & Academies',
                              route: '/en/centers',
                              description: 'Find a SAMI Center or an Academy to start your learning journey.',
                            },
                            {
                              title: 'SAMI Instructors',
                              route: '/en/instructors',
                              description: 'Find a SAMI Instructor who will teach you our combat systems on a one-to-one basis.',
                            },
                          ],
                        }
                      },
                      {
                        title: 'For Instructors',
                        route: '/en/for-instructors',
                        active: true,
                        submenu: {
                          items: [
                            {
                              title: 'Become an Instructor',
                              route: '/en/for-instructors',
                              description: 'Join our team and become a certified SAMI instructor.',
                            },
                            {
                              title: 'In Person Courses',
                              route: '/en/for-instructors/in-person-courses',
                              description: 'Start your instructor journey with an in-person event.',
                            },
                            {
                              title: 'Online Courses',
                              route: '/en/for-instructors/online-courses',
                              description: 'Start your instructor journey with an online course.',
                            },
                            {
                              title: 'Ongoing development',
                              route: '/en/for-instructors/ongoing-development',
                              description: 'See how you can take your instructor career even further.',
                            }
                          ],
                          featured: {
                            title: 'Get in touch',
                            route: '/en/for-instructors/contact',
                            description: 'Want to find out more or got any questions? Contact us or schedule a call.',
                          }
                        }
                      },
                      { title: 'Courses & Events', route: '/en/seminars', active: true },
                      { title: 'About Us', route: '/en/about', active: true },
                    ]
                  }
                ];
              }

              if (user?.subscriptionDigest?.hasSubscription?.basic || user?.subscriptionDigest?.hasSubscription?.plus) {
                menuItems.push({
                  title: 'Learning',
                  items: [
                    {
                      title: 'System Guide',
                      route: '/app/system-guide',
                      active: true
                    },

                    {
                      title: 'Courses & Events',
                      route: '/en/seminars',
                      active: true
                    },
                  ],
                });
              }

              if (user && !(user?.subscriptionDigest?.hasSubscription?.basic || user?.subscriptionDigest?.hasSubscription?.plus)) {
                menuItems.push({
                  title: 'Learning',
                  items: [
                    {
                      title: 'Courses & Events',
                      route: '/en/seminars',
                      active: true
                    },
                  ],
                });
              }

              let myAccountSection = menuItems.find(item => item.title === 'My Account');
              if (!myAccountSection) {
                myAccountSection = { title: 'My Account', items: [] };
                if (user) {
                  menuItems.push(myAccountSection); // Add 'My Account' only if user exists
                }
              }

              if (user) {
                myAccountSection.items.push({
                  title: 'My Personal Profile',
                  route: '/app/profile',
                  active: true,
                  submenu: {
                    items: [
                      {
                        title: 'Membership & Payment',
                        route: 'app/home/membership',
                        active: true
                      },
                      {
                        title: 'My Courses & Events',
                        route: 'app/home/courses-seminars',
                        active: true
                      },
                      {
                        title: 'Diplomas',
                        route: 'app/home/certifications',
                        active: true
                      },
                      {
                        title: 'Personal Information',
                        route: 'app/home/personal-information',
                        active: true
                      },
                      {
                        title: 'E-Mail Preferences',
                        route: 'app/home/email-preferences',
                        active: true
                      }
                    ]
                  }
                });
              }

              if (user && user.subscriptionDigest?.hasSubscription?.instructor) {
                myAccountSection.items.push({
                  title: 'My Instructor Profile',
                  route: '/app/instructor-area',
                  active: true,
                  submenu: {
                    items: [
                      {
                        title: 'Instructor License',
                        route: 'app/instructor-area/license',
                        active: true
                      },
                      {
                        title: 'Instructor Page',
                        route: 'app/instructor-area/account',
                        active: true
                      },
                      {
                        title: 'Affiliate Program',
                        route: 'app/instructor-area/affiliate-programm',
                        active: true
                      }
                    ]
                  }
                });
              }

              if (user?.center?.role?.toLowerCase() === 'owner') {

                myAccountSection.items.push({
                  title: 'My Center Manager',
                  route: '/app/center-area/general',
                  active: true,
                  submenu: {
                    items: [
                      {
                        title: 'Members',
                        route: '/app/center-area/general',
                        active: true
                      },
                      {
                        title: 'Member Requests',
                        route: 'app/center-area/customer-requests',
                        active: true
                      },
                      {
                        title: 'My Center Page',
                        route: 'app/center-area/edit-center',
                        active: true
                      },
                      {
                        title: 'Affiliate Program',
                        route: 'app/center-area/affiliate-program',
                        active: true,
                      },
                    ]
                  }
                });

                myAccountSection.items.push({
                  title: 'My Event Manager',
                  route: '/app/seminar-management/list',
                  active: true,
                  submenu: {
                    items: [
                      {
                        title: 'All Events',
                        route: '/app/seminar-management/list',
                        active: true
                      },
                      {
                        title: 'Orders',
                        route: 'app/seminar-management/orders',
                        active: true
                      },
                      {
                        title: 'Finances',
                        route: 'app/seminar-management/finances',
                        active: true
                      },
                      {
                        title: 'Payment & Taxes',
                        route: '/app/seminar-management/taxes',
                        active: true,
                      },
                      {
                        title: 'Email Templates',
                        route: 'app/seminar-management/email-templates',
                        active: true
                      },
                      {
                        title: 'Embed',
                        route: '/app/seminar-management/embed',
                        active: true,
                      }
                    ]
                  }
                });
              }

              if (user?.isGlobalAdmin) {
                myAccountSection.items.push({
                  title: 'Administration',
                  route: '/app/admin/centers',
                  active: true,
                  submenu: {
                    items: [
                      {
                        title: 'Centers',
                        route: '/app/admin/centers',
                        active: true
                      },
                      {
                        title: 'Instructors',
                        route: '/app/admin/instructors',
                        active: true
                      },
                      {
                        title: 'Exams',
                        route: '/app/admin/exams',
                        active: true
                      },
                      {
                        title: 'Live Trainings',
                        route: '/app/admin/live-trainings',
                        active: true,
                      },
                    ]
                  }
                });
              }

              if (user && user.subscriptionDigest?.hasSubscription?.instructor && !(user.company)) {
                menuItems.push({
                  title: 'My Quicklinks',
                  items: [
                    {
                      title: 'SAMI Instructor Area',
                      route: 'https://instructors.sami-x.com',
                      active: true
                    },
                  ],
                });
              }

              if (user && !(user.subscriptionDigest?.hasSubscription?.instructor) && user.company) {
                menuItems.push({
                  title: 'SAMI-X Corporate',
                  items: [
                    {
                      title: user?.company?.name || 'My Company',
                      route: 'https://corporate.sami-x.com',
                      active: true
                    },
                  ],
                });
              }

              if (user && breakpointState.matches) {
                menuItems.push(
                  {
                    title: 'About SAMI',
                    items: [
                      { title: 'Combat Systems', route: '/en/combat-systems', active: true },
                      {
                        title: 'For Students',
                        active: true,
                        submenu: {
                          cols: 1,
                          items: [
                            {
                              title: 'SAMI Centers & Academies',
                              route: '/en/centers',
                              description: 'Find a SAMI Center or an Academy to start your learning journey.',
                            },
                            {
                              title: 'SAMI Instructors',
                              route: '/en/instructors',
                              description: 'Find a SAMI Instructor who will teach you our combat systems on a one-to-one basis.',
                            },
                          ],
                        }
                      },
                      {
                        title: 'For Instructors',
                        route: '/en/for-instructors',
                        active: true,
                        submenu: {
                          items: [
                            {
                              title: 'Become an Instructor',
                              route: '/en/for-instructors',
                              description: 'Join our team and become a certified SAMI instructor.',
                            },
                            {
                              title: 'In Person Courses',
                              route: '/en/for-instructors/in-person-courses',
                              description: 'Start your instructor journey with an in-person event.',
                            },
                            {
                              title: 'Online Courses',
                              route: '/en/for-instructors/online-courses',
                              description: 'Start your instructor journey with an online course.',
                            },
                            {
                              title: 'Ongoing development',
                              route: '/en/for-instructors/ongoing-development',
                              description: 'See how you can take your instructor career even further.',
                            }
                          ],
                          featured: {
                            title: 'Get in touch',
                            route: '/en/for-instructors/contact',
                            description: 'Want to find out more or got any questions? Contact us or schedule a call.',
                          }
                        }
                      }
                    ]
                  }
                )
              }

              if (user && (user.subscriptionDigest?.hasSubscription?.instructor) && user.company) {
                menuItems.push({
                  title: 'My Quicklinks',
                  items: [
                    {
                      title: 'SAMI Instructor Area',
                      route: 'https://instructors.sami-x.com',
                      active: true
                    },
                    {
                      title: 'SAMI-X Corporate | ' + user?.company?.name,
                      route: 'https://corporate.sami-x.com',
                      active: true
                    }
                  ],
                });
              }

              break;

            }

            case 'sami-x':
              menuItems = [
                {
                  title: '',
                  items: [
                    { title: 'Home', route: '/', active: true },
                    { title: 'Features', route: '/features', active: true },
                    { title: 'Combat Systems', route: '/systems', active: true },
                    { title: 'About Us', route: '/about-us', active: true },
                  ]
                }
              ];
              break;

            case 'sami-x-app':
              menuItems = [
                {
                  title: '',
                  items: [
                    {
                      icon: graphStatsAscend,
                      title: 'My Progress',
                      route: 'progress',
                      active: true,
                    },
                    {
                      icon: folders,
                      title: 'My Playlists',
                      route: 'collections',
                      active: true,
                    },
                  ],
                },
                {
                  title: 'Learning',
                  items: [
                    {
                      icon: tracks,
                      title: 'Learning Tracks',
                      route: 'tracks',
                      active: true,
                    },
                    {
                      icon: samiListBulletPoints,
                      title: 'System Guide',
                      route: 'system-guide',
                      active: true,
                    },
                    {
                      icon: videoMediaFiles,
                      title: 'Library',
                      route: 'library',
                      active: true,
                    },
                  ],
                },
                {
                  title: 'Training',
                  items: [
                    {
                      icon: boxingBag,
                      title: 'Guided Trainings',
                      route: 'gym/guided-trainings',
                      active: true,
                    },
                    {
                      icon: boxingGlove,
                      title: 'Training Creator',
                      route: 'gym/training-creator',
                      active: true,
                    },
                    {
                      icon: musicPlayerButtonRecord,
                      title: 'Live Trainings',
                      route: 'gym/live-trainings',
                      active: true,
                    }
                  ],
                },
                {
                  title: 'Certification',
                  items: [
                    // {
                    //   icon: samiListBulletPoints,
                    //   title: 'Exam Preparation',
                    //   route: 'curriculum/exam-preparation',
                    //   active: true,
                    // },
                    {
                      icon: awardBadgeCertification,
                      title: 'Online Exams',
                      route: 'online-exams',
                      active: true
                    }
                  ],
                }
              ];

              if (user && user.subscriptionDigest?.hasSubscription?.instructor && !(user.company)) {
                menuItems.push({
                  title: 'Quicklinks',
                  items: [
                    {
                      title: 'SAMI Instructor Area',
                      route: 'https://instructors.sami-x.com',
                      active: true
                    },
                  ],
                });
              }

              if (user && !(user.subscriptionDigest?.hasSubscription?.instructor) && user.company) {
                menuItems.push({
                  title: 'SAMI-X Corporate',
                  items: [
                    {
                      title: user?.company?.name || 'My Company',
                      route: 'https://corporate.sami-x.com',
                      active: true
                    },
                  ],
                });
              }

              if (user && (user.subscriptionDigest?.hasSubscription?.instructor) && user.company) {
                menuItems.push({
                  title: 'Quicklinks',
                  items: [
                    {
                      title: 'SAMI Instructor Area',
                      route: 'https://instructors.sami-x.com',
                      active: true
                    },
                    {
                      title: 'SAMI-X Corporate | ' + user?.company?.name,
                      route: 'https://corporate.sami-x.com',
                      active: true
                    }
                  ],
                });
              }

              break;

            case 'admin':
              menuItems = [
                {
                  title: 'SAMI International',
                  items: [
                    {
                      title: 'Users',
                      route: 'users',
                      active: true,
                    },
                    {
                      title: 'Instructors',
                      route: 'instructors',
                      active: true,
                    },
                    {
                      title: 'Centers & Academies',
                      route: 'centers',
                      active: true,
                    },
                    {
                      title: 'Exams',
                      route: 'exams',
                      active: true,
                    },
                    {
                      title: 'Combat Systems',
                      route: 'combat-systems',
                      active: true,
                    },
                  ],
                },
                {
                  title: 'Online Content',
                  items: [
                    {
                      title: 'Video Library',
                      route: 'video-library',
                      active: true,
                    },
                    {
                      title: 'Review Videos',
                      route: 'video-library/review',
                      active: true
                    },
                    {
                      title: 'Systems Library',
                      route: 'systems-library',
                      active: true,
                    },
                    {
                      title: 'Guided Trainings',
                      route: 'guided-trainings',
                      active: true,
                    },
                    {
                      title: 'Live Trainings',
                      route: 'live-trainings',
                      active: true,
                    },
                  ],
                },
                {
                  title: 'Training Creator',
                  items: [
                    {
                      title: 'Exercises',
                      route: 'exercises',
                      active: true,
                    },
                    {
                      title: 'Trainings',
                      route: 'trainings',
                      active: true,
                    }
                  ],
                },
                {
                  title: 'Tracks',
                  items: [
                    {
                      title: 'Tracks',
                      route: 'tracks',
                      active: true,
                    },
                    {
                      title: 'Student Progress',
                      route: 'student-progress',
                      active: true,
                    },
                    {
                      title: 'Assignment Grading',
                      route: 'assignment-grading',
                      active: true,
                    }
                  ],
                }
              ];

              break;
            case 'instructor-area':
              if (user && user.subscriptionDigest?.hasSubscription?.instructor) {
                menuItems = [
                  {
                    title: '',
                    items: [
                      // {
                      //   icon: samiPublish,
                      //   title: 'Home',
                      //   route: 'home',
                      //   active: true,
                      // },
                      {
                        icon: samiPublish,
                        title: 'Instructor News',
                        route: 'news',
                        active: true,
                      },
                      {
                        icon: samiSchoolOnline,
                        title: 'My Courses',
                        route: 'courses',
                        active: true,
                      },
                      {
                        icon: samiSchoolOnline,
                        title: 'My Online Courses',
                        route: 'online-courses',
                        active: true,
                      },
                      {
                        icon: samiSchoolOnline,
                        title: 'My Progress',
                        route: 'my-progress',
                        active: true,
                      },
                      {
                        icon: folders,
                        title: 'My Playlists',
                        route: 'collections',
                        active: true,
                      },
                    ],
                  },
                  {
                    title: 'Learning',
                    items: [
                      {
                        icon: samiPublish,
                        title: 'All Courses',
                        route: 'all-courses',
                        active: true,
                      },
                      {
                        icon: samiPublish,
                        title: 'Library',
                        route: 'video-library',
                        active: true,
                      },
                      {
                        icon: samiSchoolOnline,
                        title: 'What\'s New',
                        route: 'whats-new',
                        active: true,
                      },
                    ],
                  },
                ];

                if (user && user.styleExams && user.styleExams.length > 0) {
                  const items = user.styleExams.map((styleExam: any) => {
                    return {
                      title: this.ui.samiSystems[styleExam.style?.identifier].title,
                      route: `system/${styleExam.style?.identifier}`,
                      active: true,
                      icon: this.getSAMIIcon(this.ui.samiSystems[styleExam.style?.identifier].category)
                    }
                  });

                  menuItems.push(
                    {
                      title: 'My Instructor Systems',
                      items: items
                    }
                  );
                }

                menuItems.push(
                  {
                    title: 'Teaching',
                    items: [
                      {
                        icon: musicPlayerButtonRecord,
                        title: 'Private Live Trainings',
                        route: 'live-classes',
                        active: true,
                      }
                    ],
                  }
                );

                if (user?.unlockAssignmentsGrading) {
                  menuItems.push({
                    title: 'Admin',
                    items: [
                      {
                        icon: checkBox,
                        title: 'Assignments',
                        route: 'assignments',
                        active: true
                      }
                    ],
                  });
                }

                menuItems.push({
                  title: 'Quicklinks',
                  items: [
                    {
                      title: 'Affiliate Program',
                      route: 'https://sami-international.com/app/instructor-area/affiliate-programm',
                      active: true
                    },
                    {
                      title: 'Public Profile',
                      route: 'https://sami-international.com/en/instructors/' + user?.uid,
                      active: true
                    },
                    {
                      title: 'Rank & Diplomas',
                      route: 'https://sami-international.com/app/home/certifications',
                      active: true
                    },
                    {
                      title: 'Events & Courses',
                      route: 'https://sami-international.com/en/seminars',
                      active: true
                    }
                  ],
                });
              }
              break;
            case 'b2b':
              menuItems = [];
              if (user) {
                if (['owner', 'admin'].includes(user['company']?.['role'])) {
                  menuItems.push({
                    title: 'Administration',
                    items: [
                      {
                        title: 'User Management',
                        route: 'admin',
                        active: true
                      }
                    ],
                  });
                }
              }
              break;

            default: {
              menuItems = [];
            }
          }
          return menuItems;
        })
      );

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('lessons')) {
          this.subpage = 'lesson';
        } else if (event.url.includes('live-sessions')) {
          this.subpage = 'liveTraining';
        } else if (event.url.includes('video/')) {
          this.subpage = 'video';
        } else if (event.url.includes('event') && event.url.includes('gym/guided-trainings')) {
          this.subpage = 'guidedTraining';
        } else {
          this.subpage = undefined;
          this.updateMenu();
        }
      }
    });
  }

  navigate(url: string, backUrl: string, navbarText: string, queryParams?: { [key: string]: any }, queryParamsHandling: 'merge' | 'preserve' = 'preserve') {

    const pathOnly = this.router.parseUrl(this.router.url).root.children['primary'].segments.map(segment => segment.path).join('/');

    this.navigationStack.push({ url: pathOnly, navbarText, queryParams, queryParamsHandling });
    this.navbarTextChanged.next(navbarText);

    this.router.navigate([url], { queryParams: { ...queryParams }, queryParamsHandling: queryParamsHandling });

    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }

  updateMenu(props?: { track?: Track, liveTraining?: any, video?: SamiTechniqueClip, guidedTraining?: GuidedTraining, exam?: SamiExam, type?: 'lesson' | 'live-sessions' | 'video' | 'guided-trainings' | 'exam' }) {
    if (this.subpage) {
      if (props?.type === 'lesson') {
        this.samiMenuSettings.displayMobileMenu = false;
        this.samiMenuSettings.route = '/app/tracks/' + props.track?._id;
        this.samiMenuSettings.title = props.track?.title || '';
        this.samiMenuSettings.backgroundColor = this.getBackgroundColorBasedOnSystem({ category: props.track?.category })
      } if (props?.type === 'exam') {
        this.samiMenuSettings.displayMobileMenu = false;
        this.samiMenuSettings.route = '/app/tracks/' + props.exam?._id;
        this.samiMenuSettings.title = props.exam?.title || '';
        this.samiMenuSettings.backgroundColor = this.getBackgroundColorBasedOnSystem({ category: props.exam?.systems?.[0] })
      } else if (props?.type === 'live-sessions') {
        this.samiMenuSettings.displayMobileMenu = false;
        this.samiMenuSettings.title = props?.liveTraining?.title || '';
        this.samiMenuSettings.backgroundColor = this.getBackgroundColorBasedOnSystem({ category: props?.liveTraining?.system?.categoryText })
      } else if (props?.type === 'video') {
        this.samiMenuSettings.displayMobileMenu = false;
        this.samiMenuSettings.title = props?.video?.title || '';
        this.samiMenuSettings.backgroundColor = this.getBackgroundColorBasedOnSystem({ system: props?.video?.style?.identifier })
      } else if (props?.type === 'guided-trainings') {
        this.samiMenuSettings.displayMobileMenu = false;
        this.samiMenuSettings.title = props?.guidedTraining?.title || '';
        this.samiMenuSettings.backgroundColor = this.getBackgroundColorBasedOnSystem({ system: props?.guidedTraining?.system?.identifier })
      }
    } else {
      this.samiMenuSettings = {
        backgroundColor: undefined,
        textColor: undefined,
        displayMobileMenu: true,
        title: '',
        route: undefined,
      };
    }
  }

  getBackgroundColorBasedOnSystem(props: { category?: string | undefined, system?: string }) {
    if (props.category) {
      const color = this.getColor(props.category);
      if (color) {
        return color;
      } else {
        return undefined;
      }
    } else if (props.system) {
      const system = this.ui.samiSystems[props.system].categoryText;
      const color = this.getColor(system);
      if (color) {
        return color;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  getColor(category: string) {
    if (category?.includes('Pro')) {
      return 'yellow';
    } else if (category?.includes('Fighting Art')) {
      return 'red';
    } else if (category?.includes('Defense')) {
      return 'green';
    } else {
      return undefined;
    }
  }

  getSAMIIcon(category: 'fightingArt' | 'defense' | 'pro') {
    switch (category) {
      case 'fightingArt':
        return fightingArtIcon;
      case 'defense':
        return defensiveIcon;
      case 'pro':
        return proIcon;
    }
  }

  setNavbarText(text: string) {
    this.navbarTextChanged.next(text);
  }

  setBackButtonOnDesktop(state: { show: boolean, url?: string, queryParams?: { [key: string]: any }, queryParamsHandling?: 'merge' | 'preserve' }) {
    this.showBackButton.next(state);
  }

  goBack() {
    const navigationItem = this.navigationStack.pop();

    if (navigationItem) {
      this.setNavbarText(navigationItem.navbarText);

      if (navigationItem.url) {
        this.router.navigate([navigationItem.url], { queryParams: navigationItem.queryParams || {}, queryParamsHandling: navigationItem.queryParamsHandling || 'preserve' });

      } else {
        this.location.back();
      }
    } else {
      if (this.defaultBackUrl) {
        this.router.navigate([this.defaultBackUrl]);
        this.setDefaultBackUrl(undefined);
      } else {
        // If there's no defaultBackUrl set, navigate to a hardcoded default route or handle accordingly
        this.setNavbarText('');
        this.router.navigate(['/app']);
      }
    }
  }

  updateNavbarAndBackRoute(newNavbarText: string, backUrl?: string, queryParams?: { [key: string]: any }, queryParamsHandling?: 'merge' | 'preserve') {
    this.setNavbarText(newNavbarText);

    const lastNavigationItem = this.navigationStack[this.navigationStack.length - 1];
    if (lastNavigationItem) {
      lastNavigationItem.navbarText = newNavbarText;
      if (backUrl) {
        lastNavigationItem.url = backUrl;
        if (queryParams) {
          lastNavigationItem.queryParams = queryParams;
        }
        if (queryParamsHandling) {
          lastNavigationItem.queryParamsHandling = queryParamsHandling;
        }
      }
    } else if (backUrl) {
      this.navigationStack.push({ url: backUrl, navbarText: newNavbarText });
    }
  }

  setDefaultBackUrl(url: string | undefined) {
    this.defaultBackUrl = url;
  }

}
