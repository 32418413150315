import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-manage-collection-button',
  templateUrl: './manage-collection-button.component.html',
})
export class ManageCollectionButtonComponent implements OnInit {
  @Input() clipId: string | undefined;
  @Input() type: 'icon' | 'button' = 'button';
  @Input() buttonText? = 'Add';
  @Output() updated = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
}
