<div class="w-full relative md:pl-8 p-4 md:pr-16 md:py-6 bg-slate-900 shadow justify-start items-center gap-2 md:gap-8 inline-flex">
  <div class="grow shrink basis-0 flex-col justify-start items-start gap-2 md:gap-4 inline-flex">
    <div class="md:w-12 md:h-12 h-6 w-6 relative" *ngIf="mode[topic].icon" [innerHTML]="mode[topic].icon | safe : 'html'">
    </div>
    <div class="self-stretch flex-col justify-start items-start gap-2 flex">
      <h3 class="self-stretch text-culturedWhite text-lg md:text-2xl font-semibold leading-7">{{ mode[topic].title }}</h3>
      <div class="self-stretch flex flex-col justify-start space-y-4">
        <span class="text-cadetGrey-600 text-sm md:text-base font-body leading-normal">{{ mode[topic].description }}</span>
        <a *ngIf="mode[topic].link" target="_blank" [href]="mode[topic].link" class="text-culturedWhite text-sm md:text-base font-body underline leading-tight">Learn more</a>
      </div>
    </div>
  </div>
  <div class="w-60 hidden md:block">
    <img *ngIf="mode[topic].image" [src]="mode[topic].image" [alt]="mode[topic].title">
  </div>
  <button (click)="auth.setUserOnboarding(topic)" class="w-6 h-6 absolute top-2 md:top-4 right-2 md:right-4 justify-center items-center flex">
    <div class="w-4 h-4 md:h-6 md:w-6 relative">
      <svg class="w-full h-full" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 4.76758L20 20.7676" stroke="#ECEFEF" stroke-width="2" stroke-linejoin="round"/>
        <path d="M20 4.76758L4 20.7676" stroke="#ECEFEF" stroke-width="2" stroke-linejoin="round"/>
      </svg>
    </div>
  </button>
</div>