import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { GetDownloadURLPipeModule } from '@angular/fire/compat/storage';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Track } from 'interfaces';
import { MembershipBadgeComponent } from '../../badges/membership-badge/membership-badge.component';
import { UiService } from '@sami/features';

@Component({
  selector: 'app-chapter-card',
  templateUrl: './chapter-card.component.html',
  standalone: true,
  imports: [CommonModule, RouterModule, GetDownloadURLPipeModule, MembershipBadgeComponent]
})
export class ChapterCardComponent implements OnInit {
  @Input() track?: Track | null | undefined;
  @Input() chapter: any | undefined = undefined;

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    public ui: UiService
  ) { }

  ngOnInit(): void {
    console.log('ChapterCardComponent', this.track, this.chapter);
  }

  navigateToChapter() {
    this.router.navigate(['../chapters', this.chapter.id, 'lessons', this.chapter.lessons[0].id], { relativeTo: this.route });
  }
}
