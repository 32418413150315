<div class="p-4 md:p-8 bg-culturedWhite overflow-y-auto h-full flex flex-col justify-start space-y-2" *ngIf="{ monthlyPrice: monthlyPrice2023$ | async, yearlyPrice: yearlyPrice2023$ | async, location: location.location$ | async, user: auth.user$ | async} as vm">
  <div class="flex flex-col justify-start space-y-6">
    <h1 class="text-3xl font-semibold font-sans text-richBlack text-left md:text-center">
      Become a member and get unlimited access
    </h1>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4">
      <div class="flex flex-row justify-start items-center space-x-4" *ngFor="let reason of reasons">
        <div class="flex-shrink-0 w-[22px] h-[22px] rounded-full bg-richBlack flex flex-col justify-center items-center">
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.21 3.96814L6.3427 11.7015L2.65804 8.05481L1.7207 9.00281L5.87204 13.1121C5.9968 13.2353 6.16506 13.3043 6.34037 13.3043C6.51568 13.3043 6.68394 13.2353 6.8087 13.1121L15.142 4.91747L14.21 3.96814Z" fill="#ECEFEF"/>
          </svg>
        </div>
        <span class="text-sm font-body text-richBlack">
          {{ reason }}
        </span>
      </div>
    </div>
  </div>

  <div class="py-6 flex flex-col justify-center items-center">
    <div class="max-w-sm w-full mx-auto px-8 pt-4 pb-8 bg-white flex-col justify-center items-center gap-6 inline-flex">
      <div class="p-1.5 bg-gray-100 rounded-full justify-start items-center gap-2 inline-flex">
          <button (click)="selected = 'monthly'"
            [ngClass]="selected === 'monthly' ? 'bg-richBlack rounded-full' : ''"
            class="px-6 py-2.5 justify-start items-start gap-2.5 flex">
              <div [ngClass]="selected === 'monthly' ? 'text-white' : 'text-cadetGrey-600'" class="text-center text-base font-bold leading-normal">Monthly</div>
          </button>
          <button (click)="selected = 'yearly'"
          [ngClass]="selected === 'yearly' ? 'bg-richBlack rounded-full' : ''"
          class="px-6 py-2.5 justify-start items-start gap-2.5 flex">
              <div class="justify-start items-center gap-2.5 flex">
                  <div [ngClass]="selected === 'yearly' ? 'text-white' : 'text-cadetGrey-600'" class="text-center text-base font-semibold leading-normal">Yearly </div>
                  <div class="text-center text-red-600 text-base font-semibold leading-normal">Save 17%</div>
              </div>
          </button>
      </div>
      <div class="self-stretch h-20 flex-col justify-end items-center flex">
          <div class="flex-col justify-end items-center flex">
              <div class="text-center text-slate-900 text-xl font-semibold line-through leading-7">
                <ng-container *ngIf="selected === 'yearly'">
                  {{
                    vm.monthlyPrice?.currency_options[
              vm.location?.currency.code | lowercase
              ]
                ? (vm.monthlyPrice?.currency_options[
                vm.location?.currency.code | lowercase
                  ]?.unit_amount / 100
                  | currency: vm.location?.currency.code)
                : (vm.monthlyPrice?.currency_options['eur']
                    .unit_amount / 100 | currency: 'EUR')
                  }}
                </ng-container>
              </div>
              <div class="justify-center items-end inline-flex">
                  <div [ngClass]="selected === 'yearly' ? 'text-red-700' : 'text-richBlack'" class="text-center text-5xl font-extrabold leading-10">

                    <ng-container *ngIf="selected === 'monthly'">
                      {{
                        vm.monthlyPrice?.currency_options[
                  vm.location?.currency.code | lowercase
                  ]
                    ? (vm.monthlyPrice?.currency_options[
                    vm.location?.currency.code | lowercase
                      ]?.unit_amount / 100
                      | currency: vm.location?.currency.code)
                    : (vm.monthlyPrice?.currency_options['eur']
                        .unit_amount / 100 | currency: 'EUR')
                      }}
                    </ng-container>

                    <ng-container *ngIf="selected === 'yearly'">
                      {{
                        vm.yearlyPrice?.currency_options[
                          vm.location?.currency.code | lowercase
                        ]
                          ? (vm.yearlyPrice?.currency_options[
                          vm.location?.currency.code | lowercase
                            ].unit_amount / 100 / 12
                            | currency: vm.location?.currency.code)
                          : (vm.yearlyPrice?.currency_options['eur'].unit_amount /
                              100 / 12 | currency: 'EUR')
                      }}
                    </ng-container>

                  </div>
                  <div class="text-center text-slate-900 text-xl font-semibold leading-7">/month</div>
              </div>
          </div>
      </div>
      <div class="self-stretch text-center text-richBlack font-normal text-sm font-body leading-tight">billed {{ selected === 'yearly' ? 'yearly' : 'monthly' }}, cancel anytime</div>
    </div>
  </div>

  <div class="md:hidden flex-1"></div>

  <div class="flex flex-col justify-start space-y-6">
    <div class="flex flex-row justify-center items-center" *ngIf="oneTimePurchaseData">
      <button (click)="clicked.emit()" class="text-base font-body text-richBlack underline">
        Buy access to this {{ contentTitle }} only for <ng-container *ngIf="oneTimePurchaseData && oneTimePurchaseData.contentType">
          {{ price[( (vm.location?.currency?.code) | uppercase )] | currency: vm.location?.currency?.code }}
        </ng-container>
      </button>
    </div>
    <div class="flex flex-col justify-start items-center">
      <button (click)="startCheckout(selected === 'monthly' ? vm.monthlyPrice : vm.yearlyPrice, vm.location, vm.user)" [ngClass]="wrapperType === 'bottomSheet' ? 'w-full' : 'max-w-xs'" class="text-white h-14 py-3.5 px-7 bg-fightingRed-700 hover:bg-fightingRed-800 font-semibold font-sans w-full flex flex-col justify-center items-center">
        <svg
          *ngIf="loading"
          role="status"
          class="animate-spin mr-2 w-4 h-4"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span *ngIf="!loading">
          Get started
        </span>
      </button>
    </div>
  </div>
</div>