import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, firstValueFrom, lastValueFrom, take } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'interfaces';

declare const window: Window &
  typeof globalThis & {
    Rewardful: any;
  };

interface StripeData {
  priceId: string;
  currency: string;
  trialPeriodDays: number;
  productId?: string;
  couponCode: string | null;
  customerId: string;
  metadata: any;
  systems?: any;
  accountId: string | null;
  taxRate: string | null;
  client_reference_id?: string;
  payment_intent_metadata?: any;
  successUrl?: string;
  cancelUrl?: string;
  returnUrl?: string;
}

interface OneTimePaymentData {
  docId: string;
  title: string;
  contentType: 'systemsClips' | 'guidedTrainings' | 'liveTrainings' | 'tracks';
  currency: string;
  successUrl?: string;
  cancelUrl?: string;
  returnUrl?: string;
  stripeCustomerId: string;
  image: string;
}

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  clientRef = 'checkout_' + new Date().getTime();
  paymentType: BehaviorSubject<string> = new BehaviorSubject<string>('');
  courseId: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private aff: AngularFireFunctions,
    private router: Router,
    private auth: AuthService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    if (isPlatformBrowser(this.platformId)) {
      if (window.Rewardful && window.Rewardful.referral) {
        this.clientRef = window.Rewardful.referral;
      }
    }
  }

  public async requestCutomerPortal(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      try {
        const baseUrl = environment.production
          ? 'https://sami-international.web.app'
          : 'http://localhost:4200';

        const stripeCheckout = await firstValueFrom(
          this.aff.httpsCallable('getStripeSession')({
            return_url: `${baseUrl}/${this.router.url}`,
            mode: 'portal',
          })
        );
        if (stripeCheckout && stripeCheckout.id) {
          window.location.href = stripeCheckout.url;
        } else {
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async generateCheckoutSession(stripeData: StripeData) {
    if (isPlatformBrowser(this.platformId)) {
      if (window.Rewardful && window.Rewardful.referral) {
        this.clientRef = window.Rewardful.referral;
      }
    }

    try {
      const baseUrl =
        (environment.production && environment?.url) || 'http://localhost:4200';
      const user = await lastValueFrom(this.auth.user$.pipe(take(1)));

      const success_url = `${baseUrl}/en/confirmation/{CHECKOUT_SESSION_ID}?priceId=${stripeData.priceId
        }&productId=${stripeData.productId}&stripeAccountId=${stripeData.accountId ? stripeData.accountId : ''
        }`;

      const cancel_url = `${baseUrl}/en/checkout/${stripeData.productId}/${stripeData.priceId
        }?priceId=${stripeData.priceId}&productId=${stripeData.productId
        }&stripeAccountId=${stripeData.accountId ? stripeData.accountId : ''}`;

      const return_url = `${baseUrl}/en/checkout/${stripeData.productId}/${stripeData.priceId
        }?priceId=${stripeData.priceId}&productId=${stripeData.productId
        }&stripeAccountId=${stripeData.accountId ? stripeData.accountId : ''}`;

      const params = {
        mode:
          stripeData.metadata.type === 'subscription'
            ? 'subscription'
            : 'payment',
        line_items: [
          {
            price: stripeData.priceId,
            quantity: 1,
            ...(stripeData.taxRate && { tax_rates: [stripeData.taxRate] }),
          },
        ],
        customer: stripeData.customerId ? stripeData.customerId : null,
        currency: stripeData.currency,
        customer_email: user.email,
        ...(stripeData.metadata && { metadata: stripeData.metadata }),
        ...((stripeData.trialPeriodDays || stripeData.systems) && {
          subscription_data: {
            ...stripeData.trialPeriodDays && {
              trial_period_days: stripeData.trialPeriodDays,
            },
            ...stripeData.systems && {
              metadata: {
                systems: stripeData.systems,
              },
            },
          },
        }),
        ...(stripeData.couponCode && {
          discounts: [
            {
              coupon: stripeData.couponCode,
            },
          ],
        }),
        allow_promotion_codes: true,
        ...(stripeData.couponCode && {
          allow_promotion_codes: false,
        }),
        ...(stripeData.accountId && { stripeAccount: stripeData.accountId }),
        client_reference_id: stripeData.client_reference_id ?? this.clientRef,
        // payment_method_collection: 'if_required',
        success_url: `${stripeData.successUrl ? stripeData.successUrl : success_url}?payment=success`,
        cancel_url: `${stripeData.cancelUrl ? stripeData.cancelUrl : cancel_url}?payment=canceled`,
        return_url: `${stripeData.returnUrl ? stripeData.returnUrl : return_url}?payment=returned`,
        ...(stripeData.payment_intent_metadata && {
          payment_intent_metadata: stripeData.payment_intent_metadata,
        }),
      };

      const stripeCheckout = await firstValueFrom(
        this.aff.httpsCallable('getStripeSession')(params)
      );
      return stripeCheckout;
    } catch (error) {
      console.error(error);
    }
  }

  async generateCheckoutSessionForPayment(data: OneTimePaymentData) {
    try {

      data = {
        ...data,
        successUrl: `${data.successUrl}`,
        cancelUrl: `${data.successUrl}`,
        returnUrl: `${data.successUrl}`,

      }

      const stripeCheckout = await firstValueFrom(
        this.aff.httpsCallable('getStripeSessionForOneTimePayment')(data)
      );

      return stripeCheckout;
    } catch (error) {
      console.error(error);
    }
  }
}
