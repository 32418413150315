import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayMuxComponent } from './display-mux.component';

@NgModule({
  declarations: [DisplayMuxComponent],
  imports: [CommonModule],
  exports: [DisplayMuxComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DisplayMuxModule {}
