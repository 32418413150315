<ng-container *ngIf="subscriptions$ | async as subscriptionDigest">
  <div class="flex flex-row justify-start items-start space-x-1">
    <div class="flex flex-row justify-start items-center space-x-2">
      <ng-container *ngIf="subscriptionDigest?.hasSubscription?.basic">
        <div
          class="px-2 py-0.5 bg-green-100 text-sm text-green-800 rounded-md whitespace-nowrap cursor-default"
        >
          SAMI International
        </div>
      </ng-container>

      <ng-container *ngIf="subscriptionDigest?.hasSubscription?.plus">
        <div
          class="px-2 py-0.5 bg-green-100 text-sm text-green-800 rounded-md whitespace-nowrap cursor-default"
        >
          SAMI-X
        </div>
      </ng-container>

      <ng-container *ngIf="subscriptionDigest?.hasSubscription?.instructor">
        <div
          class="px-2 py-0.5 bg-green-100 text-sm text-green-800 rounded-md whitespace-nowrap cursor-default"
        >
          SAMI Instructor
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
