<div class="flex flex-col justify-start space-y-4 max-h-[50vh] overflow-y-auto">
  <div class="flex flex-col justify-start space-y-1">
    <span class="block text-lg font-semibold text-richBlack">
      Combat Systems
    </span>
    <span class="text-sm font-body">
      Select the SAMI Combat Systems that your Center is offering. You can only
      select Combat Systems that your Instructors are certified in.
    </span>
  </div>

  <app-combat-system-selection
    [center]="center"
    [instructors]="centerInstructors$ | async"
  ></app-combat-system-selection>
</div>
