<div class="z-50 text-cadetGrey-500 safe-bottom w-full bg-richBlack grid grid-cols-4">
  <button matRipple matRippleColor="rgba(236, 239, 239, 0.1)" routerLinkActive="text-culturedWhite" routerLink="/app/progress" class="flex py-2.5 flex-col justify-center space-y-1.5 items-center">
    <svg class="w-5 h-5" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.50986 0.217736C9.80207 0.00522074 10.1979 0.00522074 10.4901 0.217736L19.6568 6.8844C19.8724 7.04122 20 7.29174 20 7.55835V19.225C20 19.6853 19.6269 20.0583 19.1667 20.0583H13.3333C12.8731 20.0583 12.5 19.6853 12.5 19.225V15.0583C12.5 14.3953 12.2366 13.7594 11.7678 13.2906C11.299 12.8218 10.6631 12.5583 10 12.5583C9.33692 12.5583 8.70108 12.8217 8.23225 13.2906C7.76343 13.7594 7.5 14.3953 7.5 15.0583V19.225C7.5 19.6853 7.1269 20.0583 6.66667 20.0583H0.833333C0.373096 20.0583 0 19.6853 0 19.225V7.55835C0 7.29174 0.12757 7.04122 0.34319 6.8844L9.50986 0.217736ZM1.66667 7.9827V18.3917H5.83333V15.0583C5.83333 13.9533 6.27231 12.8935 7.0537 12.1121C7.83512 11.3306 8.89491 10.8917 10 10.8917C11.105 10.8917 12.1648 11.3306 12.9462 12.1121C13.7277 12.8934 14.1667 13.9533 14.1667 15.0583V18.3917H18.3333V7.9827L10 1.9221L1.66667 7.9827Z" fill="currentColor"/>
    </svg>
    <div class="text-center text-xs font-body leading-3">Home</div>
  </button>

  <button routerLinkActive="text-culturedWhite" routerLink="/app/learning" matRipple matRippleColor="rgba(236, 239, 239, 0.1)" class="flex flex-col justify-center space-y-1.5 py-2.5 items-center">
    <svg class="w-5 h-5" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.49967 2.55835C2.49967 2.09811 2.87277 1.72502 3.33301 1.72502H15.833C16.054 1.72502 16.266 1.81281 16.4223 1.96909L18.9223 4.46909C19.2477 4.79453 19.2477 5.32217 18.9223 5.6476L16.4223 8.1476C16.266 8.30389 16.054 8.39168 15.833 8.39168H3.33301C2.87277 8.39168 2.49967 8.01859 2.49967 7.55835V2.55835ZM4.16634 3.39168V6.72502H9.16634H10.833H15.4878L17.1545 5.05835L15.4878 3.39168H10.833H9.16634H4.16634ZM3.57709 10.3024C3.73337 10.1461 3.94533 10.0583 4.16634 10.0583H16.6663C17.1266 10.0583 17.4997 10.4314 17.4997 10.8917V15.8917C17.4997 16.3519 17.1266 16.725 16.6663 16.725H4.16634C3.94533 16.725 3.73337 16.6372 3.57709 16.4809L1.07709 13.9809C0.751649 13.6555 0.751649 13.1279 1.07709 12.8024L3.57709 10.3024ZM4.51152 11.725L2.84485 13.3917L4.51152 15.0583H9.16634H10.833H15.833V11.725H10.833H9.16634H4.51152Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.833 0.0583496V2.55835H15.833L18.333 5.05835L15.833 7.55835H10.833V10.8917H16.6663V15.8917H10.833V20.0583H9.16634V15.8917H4.16634L1.66634 13.3917L4.16634 10.8917H9.16634V7.55835H3.33301V2.55835H9.16634V0.0583496H10.833ZM9.16634 6.72502H10.833H15.4878L17.1545 5.05835L15.4878 3.39168H10.833H9.16634H4.16634V6.72502H9.16634ZM10.833 15.0583H9.16634H4.51152L2.84485 13.3917L4.51152 11.725H9.16634H10.833H15.833V15.0583H10.833Z" fill="currentColor"/>
    </svg>
    <div class="text-center text-xs font-body leading-3">Learning</div>
  </button>

  <button routerLinkActive="text-culturedWhite" routerLink="/app/training" matRipple matRippleColor="rgba(236, 239, 239, 0.1)" class="flex flex-col justify-center space-y-1.5 py-2.5 items-center">
    <svg class="w-5 h-5" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.50033 4.55835C7.50033 3.01859 8.79389 1.72502 10.3337 1.72502H13.8337C15.3734 1.72502 16.667 3.01859 16.667 4.55835V11.3917C16.667 12.9314 15.3734 14.225 13.8337 14.225H7.00032C6.34441 14.225 5.80093 13.9399 5.32042 13.4322C4.82604 12.9098 4.42868 12.1787 4.12011 11.3901C3.81465 10.6095 3.6152 9.8222 3.49205 9.22885C3.43083 8.93389 3.38922 8.69053 3.36305 8.52448C3.34998 8.44159 3.34072 8.37764 3.33462 8.33497L3.33452 8.33425C3.36234 7.40696 4.06314 6.72502 5.00033 6.72502H5.83366V10.0583H7.50033V4.55835ZM5.00033 5.05835C3.12342 5.05835 1.66699 6.51478 1.66699 8.39168C1.66699 8.44378 1.67177 8.49477 1.68092 8.54422L1.68471 8.57067C1.69143 8.61772 1.70194 8.69025 1.7167 8.78395C1.7462 8.97109 1.79247 9.24141 1.86016 9.56754C1.99482 10.2164 2.21725 11.101 2.56804 11.9974C2.91571 12.8859 3.40897 13.8371 4.10991 14.5778C4.5786 15.073 5.15123 15.4837 5.83366 15.7078V19.225C5.83366 19.6853 6.20676 20.0583 6.66699 20.0583H15.8337C16.2939 20.0583 16.667 19.6853 16.667 19.225V14.8714C17.6802 14.0419 18.3337 12.7841 18.3337 11.3917V4.55835C18.3337 2.09811 16.2939 0.0583496 13.8337 0.0583496H10.3337C7.87341 0.0583496 5.83366 2.09811 5.83366 4.55835V5.05835H5.00033ZM15.0003 15.735C14.6274 15.8371 14.2362 15.8917 13.8337 15.8917H7.50033V18.3917H15.0003V15.735ZM1.6783 8.52587L1.67831 8.52594C1.67783 8.5229 1.67778 8.52242 1.6783 8.52587Z" fill="currentColor"/>
    </svg>
    <div class="text-center text-xs font-body leading-3">Training</div>
  </button>

  <button routerLinkActive="text-culturedWhite" routerLink="/app/profile" matRipple matRippleColor="rgba(236, 239, 239, 0.1)" class="flex flex-col justify-center space-y-1.5 py-2.5 items-center">
    <svg class="w-5 h-5" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 0.0585938C8.0222 0.0585938 6.0888 0.645084 4.4443 1.74389C2.79981 2.84271 1.51809 4.4045 0.761209 6.23176C0.00433276 8.05902 -0.1937 10.0697 0.192151 12.0095C0.578004 13.9493 1.53041 15.7312 2.92894 17.1297C4.32746 18.5282 6.1093 19.4806 8.0491 19.8664C9.98892 20.2523 11.9996 20.0543 13.8268 19.2974C15.6541 18.5405 17.2159 17.2588 18.3147 15.6143C19.4135 13.9698 20 12.0364 20 10.0586C19.9972 7.40731 18.9427 4.86544 17.0679 2.99069C15.1932 1.11595 12.6513 0.0614613 10 0.0585938ZM10 18.3919C8.35184 18.3919 6.74066 17.9032 5.37025 16.9875C3.99985 16.0718 2.93174 14.7703 2.30101 13.2476C1.67028 11.7249 1.50525 10.0493 1.82679 8.43284C2.14834 6.81634 2.94201 5.33148 4.10744 4.16604C5.27289 3.0006 6.75775 2.20693 8.37425 1.88539C9.99075 1.56384 11.6663 1.72887 13.189 2.35959C14.7118 2.99033 16.0133 4.05844 16.9289 5.42884C17.8446 6.79925 18.3333 8.41043 18.3333 10.0586C18.3309 12.268 17.4522 14.3862 15.8899 15.9485C14.3276 17.5108 12.2094 18.3895 10 18.3919Z" fill="currentColor"/>
      <path d="M4.545 13.3428L5.455 14.7394C6.82776 13.8669 8.42267 13.4081 10.0493 13.4177C11.6758 13.4273 13.2652 13.9049 14.6275 14.7936L15.5542 13.4086C13.9266 12.3223 12.0162 11.7371 10.0594 11.7254C8.1027 11.7138 6.18546 12.276 4.545 13.3428Z" fill="currentColor"/>
      <path d="M13.75 7.97526C13.75 7.23359 13.5301 6.50856 13.118 5.89188C12.7059 5.27519 12.1203 4.79454 11.4351 4.51071C10.7498 4.22689 9.99583 4.15262 9.26842 4.29732C8.541 4.44201 7.8728 4.79916 7.34835 5.32361C6.8239 5.84806 6.46675 6.51624 6.32206 7.24368C6.17736 7.9711 6.25163 8.72509 6.53545 9.41034C6.81928 10.0955 7.29993 10.6812 7.91661 11.0933C8.53333 11.5053 9.25833 11.7253 10 11.7253C10.9943 11.7242 11.9474 11.3287 12.6504 10.6257C13.3534 9.92268 13.7489 8.96951 13.75 7.97526ZM10 10.0586C9.58792 10.0586 9.18517 9.93643 8.84258 9.70751C8.5 9.47859 8.23293 9.15318 8.07525 8.77251C7.91757 8.39184 7.87631 7.97295 7.9567 7.56883C8.03708 7.16469 8.2355 6.79349 8.52683 6.50212C8.81825 6.21076 9.18942 6.01234 9.59358 5.93196C9.99767 5.85158 10.4166 5.89283 10.7973 6.05051C11.1779 6.20819 11.5033 6.47522 11.7323 6.81783C11.9612 7.16043 12.0833 7.56322 12.0833 7.97526C12.0833 8.24885 12.0294 8.51976 11.9248 8.77251C11.8201 9.02526 11.6666 9.25493 11.4732 9.44843C11.2797 9.64184 11.05 9.79534 10.7973 9.90001C10.5445 10.0047 10.2736 10.0586 10 10.0586Z" fill="currentColor"/>
    </svg>
    <div class="text-center text-xs font-body leading-3">Profile</div>
  </button>
</div>