<ng-container *ngIf="(displayBadge$ | async) || isAdminSite">
  <ng-container *ngIf="isFree">
    <div
      [ngClass]="isFree ? 'text-statusColors-darkGreen bg-statusColors-lightGreen' : 'bg-statusColors-lightRed text-statusColors-darkRed'"
      class="px-2.5 py-0.5 tracking-wide font-body rounded-full leading-tight font-semibold text-xs uppercase"
      >
      {{ isFree ? 'Free' : 'Members only' }}
    </div>
  </ng-container>

  <ng-container *ngIf="!isFree && !onlyShowIsFree">
    <div
      [ngClass]="isFree ? 'text-statusColors-darkGreen bg-statusColors-lightGreen' : 'bg-statusColors-lightRed text-statusColors-darkRed'"
      class="px-2.5 py-0.5 tracking-wide font-body rounded-full leading-tight font-semibold text-xs uppercase"
      >
      {{ isFree ? 'Free' : 'Members only' }}
    </div>
  </ng-container>
</ng-container>