import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { lastValueFrom } from 'rxjs';
import { SamiPublicProfile } from 'interfaces';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { UiService } from '@sami/features';

@Component({
  selector: 'app-instructor-card',
  templateUrl: './instructor-card.component.html',
})
export class InstructorCardComponent implements OnInit {
  @Input() instructor: SamiPublicProfile | undefined;
  @Input() background: 'white' | 'culturedWhite' = 'white';
  @Input() displayRanks = true;
  @Input() displayAddress = true;
  @Input() displayImage = true;
  @Input() id: string | undefined = '';

  constructor(public ui: UiService, private afs: AngularFirestore) {}

  ngOnInit(): void {
    if (!this.instructor && this.id) {
      this.getInstructor(this.id);
    }
  }

  async getInstructor(id: string) {
    const instructor = await lastValueFrom(
      this.afs
        .collection('users/' + id + '/publicProfiles')
        .doc(id)
        .get()
    );
    if (instructor.exists) {
      this.instructor = instructor.data() as SamiPublicProfile;
    }
  }
}
