import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable, combineLatest, map, take } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { GuidedTraining, SamiUser } from 'interfaces';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { PaywallService } from '../services/paywall.service';

@Injectable({
  providedIn: 'root'
})
export class LiveTrainingGuard implements CanActivate {
  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private paywall: PaywallService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return combineLatest([
      this.auth.user$,
      this.afs.doc<GuidedTraining>(`liveTrainings/${route.params['workoutId']}`).valueChanges(),
    ]).pipe(
      take(1),
      map(([user, workout]: [SamiUser | undefined, GuidedTraining | undefined]) => {
        if (workout?.isFree) {
          return true;
        }

        if (user?.subscriptionDigest?.hasSubscription?.plus) {
          return true;
        }

        // this.paywall.openPaywallDialog();

        return true;
      }),
    )
  }

}
