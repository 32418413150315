<div class="flex w-full flex-col max-h-[80vh] overflow-y-auto justify-start md:w-[600px] bg-white p-6" *ngIf="customer$ | async as customer">
  <div class="flex flex-row justify-between">
    <span class="text-2xl font-semibold text-richBlack"> Enter {{ mode === 'instructor' ? 'Instructor Diploma' : 'Student Diploma'}} for {{ customer.displayName }} </span>
    <button (click)="dialogRef.close()" class="w-4 h-4">
      <svg
        class="w-4 h-4"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3849_30117)">
          <path
            d="M1 1L23 23"
            stroke="black"
            stroke-width="2"
            stroke-linejoin="round"
          />
          <path
            d="M23 1L1 23"
            stroke="black"
            stroke-width="2"
            stroke-linejoin="round"
          />
        </g>
      </svg>
    </button>
  </div>

  <div class="pb-4 pt-8 flex flex-col justify-start space-y-3" *ngIf="customer$ | async as customer">
    <form [formGroup]="addExamForm" class="md:grid flex flex-col justify-start md:grid-cols-2 gap-10">
      <sami-select
        [parentForm]="addExamForm"
        [displayLabel]="true"
        label="Combat System"
        fieldName="style"
        formControlName="style"
        #styleInput
        [options]="systems"
      ></sami-select>

      <sami-select
        [parentForm]="addExamForm"
        [displayLabel]="true"
        label="Combat System"
        [fieldName]="mode === 'instructor' ? 'exam' : 'level'"
        [formControlName]="mode === 'instructor' ? 'exam' : 'level'"
        [options]="availableRanks"
      ></sami-select>

      <sami-autocomplete
        [parentForm]="addExamForm"
        [displayLabel]="true"
        label="SAMI Center"
        [placeholder]="'Enter the name of the center'"
        [fieldName]="'center'"
        [formControlName]="'center'"
        [type]="'center'"
      ></sami-autocomplete>

      <sami-autocomplete
        [parentForm]="addExamForm"
        [displayLabel]="true"
        label="SAMI Instructor"
        [placeholder]="'Enter the name of the instructor'"
        [fieldName]="'instructor'"
        [formControlName]="'instructor'"
        [type]="'instructor'"
      ></sami-autocomplete>

      <sami-datepicker
        [parentForm]="addExamForm"
        [displayLabel]="true"
        label="Date"
        fieldName="date"
        formControlName="date"
        placeholder="Date"
      ></sami-datepicker>

      <sami-select
        [parentForm]="addExamForm"
        [displayLabel]="true"
        label="Status"
        fieldName="status"
        formControlName="status"
        [options]="[
          {
            key: 'passed',
            value: 'Exam passed'
          },
          {
            key: 'partly',
            value: 'Exam partly passed'
          },
          {
            key: 'failed',
            value: 'Exam failed'
          }
        ]"
      ></sami-select>

      <sami-text-area
        [parentForm]="addExamForm"
        [displayLabel]="true"
        label="Exam Note"
        fieldName="note"
        formControlName="note"
        placeholder="Information about this exam"
      ></sami-text-area>

      <sami-text-area
        [parentForm]="addExamForm"
        [displayLabel]="true"
        label="General Note"
        fieldName="generalNote"
        formControlName="generalNote"
        placeholder="Existing Note will be overwritten"
      ></sami-text-area>
    </form>

    <div class="flex flex-row justify-end">
      <sami-button (click)="onSave(customer)" text="Add new Diploma"></sami-button>
    </div>
  </div>
</div>
