import { Injectable, inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { SamiCenter, SamiPublicProfile, SamiSeminar, SamiSeminarParticipant, SamiSeminarTicket, SamiUser, SeminarMessage } from 'interfaces';
import { Observable, switchMap, of, lastValueFrom, take, map, combineLatest, tap } from 'rxjs';
import { AuthService } from './auth.service';
import { Firestore, doc, docData, documentId } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class SeminarService {
  firestore: Firestore = inject(Firestore);

  colName = 'seminars'; // change rules accordingly
  organizer: any;
  organizerType: any;

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
  ) { }

  canCenterCreateSeminars$ = this.auth.user$.pipe(
    switchMap((user: SamiUser | undefined) =>
      user?.center?.id
        ? this.afs.doc<SamiCenter>(`centers/${user.center.id}`).valueChanges().pipe(
          map((center: SamiCenter | undefined) => center?.stripeAccountId)
        )
        : of(false)
    )
  );

  getSeminarsByOrganizer$(
    organizerId: string
  ): Observable<SamiSeminar | undefined> {
    return this.afs
      .collection(`${this.colName}`, (ref) =>
        ref.where('organizer.id', '==', organizerId)
      )
      .valueChanges({ idField: '_id' });
  }

  getSeminars$(): Observable<SamiSeminar[]> {
    return this.auth.user$.pipe(
      switchMap((user: any) =>
        user?.center?.id
          ? this.afs
            .collection(`${this.colName}`, (ref) =>
              ref.where('organizer.id', '==', user?.center?.id)
            )
            .valueChanges({ idField: '_id' })
          : of([])
      )
    );
  }

  getDefaultCenterInstructors$(): Observable<SamiPublicProfile[]> {
    const allowedIds = [
      "users/10049/publicProfiles/10049",
      "users/QjUb5QUNDPee8GDyrXp47HcNKML2/publicProfiles/QjUb5QUNDPee8GDyrXp47HcNKML2",
      "users/VICfKbE5s9WM45lYiuFlaiBNORU2/publicProfiles/VICfKbE5s9WM45lYiuFlaiBNORU2",
      "users/ws0Edf6HMvfLhD41L4trh01nnzf2/publicProfiles/ws0Edf6HMvfLhD41L4trh01nnzf2",
    ];

    // Firestore 'whereIn' query is used to match document IDs against the 'allowedIds' array
    return this.afs
      .collectionGroup<SamiPublicProfile>('publicProfiles', ref =>
        ref.where(documentId(), 'in', allowedIds)
      )
      .valueChanges({ idField: '_id' });
  }

  combineInstructors$(): Observable<SamiPublicProfile[]> {
    return combineLatest([
      this.getDefaultCenterInstructors$(),
      this.getCenterInstructors$(),
    ]).pipe(
      tap(([centerInstructors, defaultCenterInstructors]) => {
        console.log('centerInstructors', centerInstructors);
        console.log('defaultCenterInstructors', defaultCenterInstructors);
      }),
      map(([centerInstructors, defaultCenterInstructors]) => [
        ...centerInstructors,
        ...defaultCenterInstructors
      ]),
      // Remove duplicates if any, assuming 'id' is unique
      map(instructors => Array.from(new Map(instructors.map(i => [i['_id'], i])).values()))
    );
  }


  getCenterInstructors$(): Observable<SamiPublicProfile[]> {
    return this.auth.user$.pipe(
      switchMap((user: any) =>
        user?.center?.id
          ? this.afs
            .collectionGroup<SamiPublicProfile[]>('publicProfiles', (ref) =>
              ref
                .where('center.id', '==', user?.center?.id)
                .where('isPublic', '==', true)
                .where('instructorRank', '>', 0)
                .orderBy('instructorRank', 'desc')
            )
            .valueChanges({ idField: '_id' })
          : of([])
      )
    );
  }

  getSeminar$(id: string): Observable<SamiSeminar | undefined> {

    return docData(
      doc(
        this.firestore,
        `${this.colName}/${id}`
      ),
      { idField: '_id' }
    );
  }

  getRelatedSeminars$(seminar: SamiSeminar, limit = 3): Observable<SamiSeminar[]> {
    const locationSeminars = this.afs.collection(this.colName, ref =>
      ref.where('system', '==', seminar.system).where('eventEndsAt', '>', new Date()).where('isPublished', '==', true).limit(limit + 1)
    ).valueChanges({ idField: '_id' });

    const onlineSeminars = this.afs.collection(this.colName, ref =>
      ref.where('system', '==', seminar.system).where('seminarType', '==', 'online').where('isPublished', '==', true).limit(limit + 1)
    ).valueChanges({ idField: '_id' });

    return combineLatest([locationSeminars, onlineSeminars]).pipe(
      map(([locationSeminars, onlineSeminars]: [SamiSeminar[], SamiSeminar[]]) => [...locationSeminars, ...onlineSeminars]),
      map((seminars: SamiSeminar[]) =>
        seminars
          .filter((s: SamiSeminar) => s.slug !== seminar.slug)
          .map((value: SamiSeminar) => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value)
          .slice(0, limit)
      )
    );
  }

  getSeminarBySlug$(slug: string): Observable<SamiSeminar | undefined> {
    return this.afs
      .collection(`${this.colName}`, (ref) => ref.where('slug', '==', slug))
      .valueChanges({ idField: '_id' })
      .pipe(map((seminars: SamiSeminar) => seminars[0]));
  }

  getSeminarParticipants$(id: string): Observable<SamiSeminarParticipant[]> {
    return this.afs
      .collection(`${this.colName}/${id}/seminarParticipants`)
      .valueChanges({ idField: '_id' });
  }

  getSeminarTickets$(id: string): Observable<SamiSeminarTicket[]> {
    return this.afs
      .collection(`${this.colName}/${id}/seminarTickets`)
      .valueChanges({ idField: '_id' });
  }

  public getUserSeminarTickets$(userId: string): Observable<SamiSeminarTicket[] | undefined> {
    return this.afs
      .collectionGroup('seminarTickets', (ref) =>
        ref.where('userId', '==', userId)
      )
      .valueChanges({ idField: '_id' });
  }

  async createSeminar(): Promise<string> {
    try {
      const user = await lastValueFrom(this.auth.user$.pipe(take(1)));

      if (!user.center?.id) {
        throw new Error('User is not a center member!');
      }

      const center: SamiCenter | undefined = (
        await lastValueFrom(
          this.afs.doc<SamiCenter>(`centers/${user.center.id}`).get()
        )
      ).data();

      if (!center) {
        throw new Error('Center not found!');
      }

      const seminarId = this.afs.createId();

      const { timeZone, locale } = Intl.DateTimeFormat().resolvedOptions();

      const data: SamiSeminar = {
        organizerType: 'center',
        organizer: {
          id: user.center.id,
          ...center,
        },
        location: {
          ...(center.address && { address: center.address }),
          ...(center.geolocation && { geolocation: center.geolocation }),
          ...(center.country && { country: center.country }),
        },
        timeZone,
        locale,
        isPublished: false,
      };

      await this.upsertSeminar(seminarId, data);

      return seminarId;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  upsertSeminar(id: string, data: SamiSeminar | any): Promise<any> {
    return this.afs.doc(`${this.colName}/${id}`).set(data, { merge: true });
  }

  async duplicateSeminar(seminarId: string): Promise<string> {
    try {
      const seminar: SamiSeminar | undefined = await lastValueFrom(this.afs.doc<SamiSeminar>(`seminars/${seminarId}`).valueChanges().pipe(take(1)));
      if (!seminar) {
        throw 'Seminar not found!';
      }

      if (!seminar.title) {
        throw 'Title required for duplication!';
      }

      const newSeminarId = this.afs.createId();

      const newSeminar: Partial<SamiSeminar> = {
        title: `[DUPLICATE] ${seminar.title}`,
        description: seminar.description,
        summary: seminar.summary,
        imageUrl: seminar.imageUrl,
        instructors: seminar.instructors,
        language: seminar.language,
        locale: seminar.locale,
        timeZone: seminar.timeZone,
        location: seminar.location,
        organizer: seminar.organizer,
        organizerType: seminar.organizerType,
        requirements: seminar.requirements,
        sellingPoints: seminar.sellingPoints,
        seminarType: seminar.seminarType,
        system: seminar.system,
        targetGroups: seminar.targetGroups,
        includesCertification: seminar.includesCertification,
        certificationComment: seminar.certificationComment,
        isPublished: false,
      };

      await this.upsertSeminar(newSeminarId, newSeminar);


      // Fetch and duplicate seminar messages
      const seminarMessages = await this.fetchSeminarMessages(seminarId);
      const newSeminarMessages: SeminarMessage[] = seminarMessages
        .filter((message: SeminarMessage) => message.parentId === null)
        .map((message: SeminarMessage) => ({
          ...message,
          createdAt: new Date(), // set to current date
          scheduledAt: null,
          scheduledAtEvent: null,
          scheduledAtEventManipulator: null,
          recipients: [],
          seminarId: newSeminarId,
          status: 'draft',
          subject: `DRAFT ${message.subject}`,
          ticketTypeIds: [],
        }));

      // Save new seminar messages
      await this.saveSeminarMessages(newSeminarId, newSeminarMessages);


      return newSeminarId;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }

  async fetchSeminarMessages(seminarId: string): Promise<SeminarMessage[]> {
    try {
      const messagesRef = this.afs.collection<SeminarMessage>(`seminars/${seminarId}/seminarMessages`);
      const snapshot = await messagesRef.ref.where('parentId', '==', null).get();
      if (snapshot.empty) {
        console.log('No matching documents.');
        return [];
      }

      const messages: SeminarMessage[] = [];
      snapshot.forEach(doc => {
        messages.push(doc.data() as SeminarMessage);
      });

      return messages;
    } catch (error) {
      console.error('Error fetching seminar messages: ', error);
      throw error;  // or handle it as you see fit
    }
  }

  async saveSeminarMessages(seminarId: string, messages: SeminarMessage[]): Promise<void> {
    const batch = this.afs.firestore.batch();

    messages.forEach(message => {
      const messageId = this.afs.createId(); // Generates a new unique ID for each message
      const messageRef = this.afs.doc(`seminars/${seminarId}/seminarMessages/${messageId}`).ref;
      batch.set(messageRef, message);
    });

    try {
      await batch.commit();
      console.log('All messages saved successfully');
    } catch (error) {
      console.error('Error saving seminar messages: ', error);
      throw error;  // or handle it as you see fit
    }
  }


  async deleteSeminar(seminarId: string): Promise<void> {
    try {
      await this.afs.doc(`seminars/${seminarId}`).delete();
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }

  async generateSlug(string: string, i = 0): Promise<string> {
    const suffix = Array(i).fill('').map((_, j) => j).join('');

    const gen = () =>
      `${string}${suffix}`
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');

    const slug = gen();

    const col = await lastValueFrom(this.afs.collection('seminars', (ref) => ref.where('slug', '==', slug)).get());

    if (!col.empty) {
      return this.generateSlug(string, +i + 1);
    } else {
      return slug;
    }
  }
}
