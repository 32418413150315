import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-download-card',
  templateUrl: './download-card.component.html',
})
export class DownloadCardComponent {
  @Input() downloads:
    | {
        title: string;
        excerpt: string;
        downloadPath: string;
      }[]
    | null = null;
}
