<div class="flex flex-col justify-start space-y-1">
  <div
    class="bg-culturedWhite flex flex-row justify-between items-center px-4 py-2"
    *ngFor="let system of combatSystems"
  >
    <span class="block text-base font-semibold text-richBlack">
      {{ ui.samiSystems[system].title }}
    </span>

    <button
      (click)="updateBlacklistedSystems(system)"
      [ngClass]="
        center &&
        center.blacklistedSystemIdentifiers &&
        center.blacklistedSystemIdentifiers.includes(system)
          ? 'bg-cadetGrey-200'
          : 'bg-richBlack'
      "
      type="button"
      class="relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-richBlack focus:ring-offset-2"
      role="switch"
      aria-checked="false"
    >
      <span class="sr-only">Toggle Combat System</span>
      <span
        [ngClass]="
          center &&
          center.blacklistedSystemIdentifiers &&
          center.blacklistedSystemIdentifiers.includes(system)
            ? 'translate-x-0'
            : 'translate-x-5'
        "
        class="pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
      >
        <span
          [ngClass]="
            center &&
            center.blacklistedSystemIdentifiers &&
            center.blacklistedSystemIdentifiers.includes(system)
              ? 'opacity-100 ease-in duration-200'
              : 'opacity-0 ease-out duration-100'
          "
          class="ease-in duration-200 absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
          aria-hidden="true"
        >
          <svg
            class="h-3 w-3 text-cadetGrey-400"
            fill="none"
            viewBox="0 0 12 12"
          >
            <path
              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <span
          [ngClass]="
            center &&
            center.blacklistedSystemIdentifiers &&
            center.blacklistedSystemIdentifiers.includes(system)
              ? 'opacity-0 ease-out duration-100'
              : 'opacity-100 ease-in duration-200'
          "
          class="ease-out duration-100 absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
          aria-hidden="true"
        >
          <svg
            class="h-3 w-3 text-richBlack"
            fill="currentColor"
            viewBox="0 0 12 12"
          >
            <path
              d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
            />
          </svg>
        </span>
      </span>
    </button>
  </div>
</div>
