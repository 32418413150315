

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GetDownloadURLPipeModule } from '@angular/fire/compat/storage';
import { GuidedTrainingCardComponent } from './guided-training-card.component';
import { SamiCombatSystemTagComponent } from '../../combat-system-tag/combat-system-tag.component';
import { MembershipBadgeComponent } from '../../badges/membership-badge/membership-badge.component';
import { PurchasedBadgeComponent } from '../../badges/purchased-badge/purchased-badge.component';

@NgModule({
  declarations: [GuidedTrainingCardComponent],
  imports: [
    CommonModule,
    RouterModule,
    GetDownloadURLPipeModule,
    SamiCombatSystemTagComponent,
    MembershipBadgeComponent,
    PurchasedBadgeComponent,
  ],
  exports: [GuidedTrainingCardComponent],
})
export class GuidedTrainingCardModule { }