import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService, PlaylistService } from '@sami/features';
import { SystemsPlaylist } from 'interfaces';
import { Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-clip-card',
  templateUrl: './clip-card.component.html',
})
export class ClipCardComponent implements OnInit {
  @Input() displayManageCollectionButton?= true;
  @Input() clip: any;
  @Input() backgroundColor: 'white' | 'culturedWhite' = 'white';
  @Input() showSystemOnTop?= false;
  @Input() instructor?: { name: string; image: string; id: string } | null =
    null;
  @Input() date?: any;
  @Input() time?: any;
  @Input() signUps?: number;
  @Input() showMeta = true;
  @Input() id: string | undefined;
  @Input() hasAccess: boolean | undefined;
  @Input() layout: 'column' | 'row' = 'row';
  @Input() contentType:
    | 'gym'
    | 'live-coaching'
    | 'systemsLibraryClip'
    | 'video'
    | 'playlist'
    | 'course' = 'systemsLibraryClip';
  @Input() embeddedIn?: 'track' | undefined;
  @Input() system:
    | 'kravMaga'
    | 'panantukan'
    | 'axe'
    | 'stick'
    | 'knife'
    | 'ebc'
    | 'kapap'
    | 'core'
    | string = 'kravMaga';
  @Input() gymTypeButton?: 'current' | 'past' | 'next';
  @Input() courseButtonText = 'View your Course Page';
  @Input() isExamRequired = false;
  @Input() level?: number;
  @Input() image?: string = 'https://picsum.photos/600/900';
  @Input() completed = false;
  @Input() type: 'technique' | 'exercise' | 'theory' = 'technique';
  @Input() durationInSeconds?: number;
  @Input() numberOfVideos?: number;
  @Input() title?: string;
  @Input() loading = false;
  @Input() description?: string | undefined;
  @Input() progress?: number;
  @Input() showProgressBar?: boolean = false;
  @Input() active = true; // Soll das Element klickbar sein oder nicht? Bei einem Track muss der Status beim Track aktiv sein, sonst nicht!
  @Output() clickEmitter: EventEmitter<any> = new EventEmitter();
  @Output() gymClickEmitter: EventEmitter<any> = new EventEmitter();
  @Output() trackClickMarkAsComplete: EventEmitter<any> = new EventEmitter();

  playlist$: Observable<SystemsPlaylist> | undefined;
  history$: Observable<any>;

  constructor(
    private playlist: PlaylistService,
    public auth: AuthService,
    private afs: AngularFirestore,
  ) {
    this.history$ = this.auth.authUser$.pipe(
      switchMap((user: any) =>
        user?.uid
          ? this.afs
            .doc<any>(`users/${user.uid}/systemsClipsWatchHistory/${this.id}`)
            .valueChanges()
          : of(null)
      )
    );
  }

  ngOnInit(): void {
    if (this.showProgressBar && this.id && this.contentType === 'playlist') {
      this.playlist$ = this.playlist.getSystemPlaylist$(this.id);
    }
  }
}
