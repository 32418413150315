import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SamiPublicProfile } from 'interfaces';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-edit-instructor-modal',
  templateUrl: './edit-instructor-modal.component.html',
})
export class EditInstructorModalComponent implements OnInit {
  activeLink = 'about';
  navLinks = [
    {
      label: 'About',
      link: 'about',
    },
    {
      label: 'Contact Details',
      link: 'contact-details',
    },
    {
      label: 'Combat Systems',
      link: 'combat-systems',
    },
    {
      label: 'Gallery',
      link: 'gallery',
    },
  ];
  tinyEditorLoading = false;
  tinyEditorLoaded = false;

  instructor$: Observable<SamiPublicProfile> = new Observable();
  view: 'admin' | 'instructor' = 'instructor';

  constructor(
    public dialogRef: MatDialogRef<EditInstructorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(PLATFORM_ID) private platformId: any,
    private afs: AngularFirestore
  ) {
    if (data.instructorId) {
      this.instructor$ = this.afs
        .doc<any>(
          `users/${data.instructorId}/publicProfiles/${data.instructorId}`
        )
        .valueChanges({ idField: 'id' });
    }

    if (data.view) {
      this.view = data.view;
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.loadTinyEditor();
    }
  }

  loadTinyEditor() {
    if (this.tinyEditorLoaded || this.tinyEditorLoading) {
      return;
    }

    if (!document.getElementById('sami-tiny-editor')) {
      // One way of doing this: dynamically load a script tag.
      this.tinyEditorLoading = true;
      const tinyScript = document.createElement('script');
      tinyScript.setAttribute('async', '');
      tinyScript.id = 'sami-tiny-editor';
      tinyScript.referrerPolicy = 'origin';
      tinyScript.src =
        'https://cdn.tiny.cloud/1/fff2303go3z5jayxdtyadczrh4y61new4mycg9xyheawrbwq/tinymce/6/tinymce.min.js';
      tinyScript.addEventListener('load', () => {
        this.tinyEditorLoaded = true;
        this.tinyEditorLoading = false;
      });
      document.head.appendChild(tinyScript);
    } else {
      this.tinyEditorLoaded = true;
    }
  }
}
