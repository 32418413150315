import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { SamiSeminar, SamiSeminarTicket } from 'interfaces';
import { SamiCombatSystemTagComponent } from '../../combat-system-tag/combat-system-tag.component';
import { PictureComponent } from '../../picture/picture.component';
import { StoragePipe } from '../../pipes/storage.pipe';
import { StartsWithPipe } from '../../pipes/startsWith.pipe';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-seminar-card',
  standalone: true,
  imports: [CommonModule, StartsWithPipe, StoragePipe, SamiCombatSystemTagComponent, PictureComponent],
  templateUrl: './seminar-card.component.html',
  styleUrls: ['./seminar-card.component.css'],
})
export class SamiSeminarCardComponent implements OnInit {
  @Input() seminar!: SamiSeminar;
  @Input() background: 'white' | 'culturedWhite' = 'white';
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  ticketTypesForSale!: any[];

  @Input() ticket: SamiSeminarTicket | undefined;
  isPromoActive: boolean;

  constructor() {
    // Set the end date of the promotion
    const endDate = new Date('2023-11-26T23:50:00');

    // Get the current date
    const currentDate = new Date();

    // Check if current date is before the end date
    this.isPromoActive = currentDate < endDate;
  }

  ngOnInit(): void {
    const now = Date.now();

    console.log(this.seminar);
    console.log(this.ticket);

    this.ticketTypesForSale =
      this.seminar?.ticketTypes?.filter(
        (tt: any) =>
          tt.isActive &&
          tt.saleStartsAt.toDate().getTime() <= now &&
          tt.saleEndsAt.toDate().getTime() >= now
      ) ?? [];

    // Next, sort the array by the 'price' property in ascending order
    this.ticketTypesForSale.sort((a: any, b: any) => a.price - b.price);

  }
}
