import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgeLargeComponent } from './badge-large.component';
import { GetDownloadURLPipeModule } from '@angular/fire/compat/storage';
import { MatDialogModule } from '@angular/material/dialog';
import { CertificateModalComponent } from './certificate-modal/certificate-modal.component';
import { SamiButtonComponent } from '../../button/button.component';
import { DiplomaHistoryComponent } from './diploma-history/diploma-history.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { DialogCertificationWrapperComponent } from './dialog-certification-wrapper/dialog-certification-wrapper.component';
import { BottomSheetCertificationWrapperComponent } from './bottom-sheet-certification-wrapper/bottom-sheet-certification-wrapper.component';

@NgModule({
  declarations: [
    BadgeLargeComponent,
    CertificateModalComponent,
    DiplomaHistoryComponent,
    DialogCertificationWrapperComponent,
    BottomSheetCertificationWrapperComponent,
  ],
  imports: [
    CommonModule,
    GetDownloadURLPipeModule,
    MatDialogModule,
    SamiButtonComponent,
    MatProgressSpinnerModule,
    MatBottomSheetModule,
  ],
  exports: [BadgeLargeComponent],
})
export class BadgeLargeModule {}
