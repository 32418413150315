<div [ngClass]="getNotificationTheme()" class="relative flex border-2 space-x-2.5 flex-row justify-start items-start font-body">
  <ng-container *ngIf="type === 'error'">
    <svg [ngClass]="getIconSize()" class="text-statusColors-red shrink-0 translate-y-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.382 22H2.61804L12 3.23607L21.382 22Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8944 0.552786C12.725 0.214002 12.3788 0 12 0C11.6212 0 11.275 0.214002 11.1056 0.552786L0.105574 22.5528C-0.0494204 22.8628 -0.0328564 23.2309 0.14935 23.5257C0.331557 23.8205 0.653423 24 1 24H23C23.3466 24 23.6684 23.8205 23.8507 23.5257C24.0329 23.2309 24.0494 22.8628 23.8944 22.5528L12.8944 0.552786ZM2.61804 22H21.382L12 3.23607L2.61804 22Z" fill="currentColor"/>
      <path d="M13 9H11V16H13V9Z" fill="#FDEAEC"/>
      <path d="M13 18H11V20H13V18Z" fill="#FDEAEC"/>
    </svg>
  </ng-container>
  <ng-container *ngIf="type === 'warning'">
    <svg [ngClass]="getIconSize()" class="text-statusColors-yellow shrink-0 translate-y-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12C0 5.37259 5.37259 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47716 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47716 17.5228 2 12 2Z" fill="#F1C523"/>
      <path d="M11 7H13V5H11V7Z" fill="#5B4806"/>
      <path d="M11 16H13V9H9V11H11V16Z" fill="#5B4806"/>
      <path d="M13 16H11H9V18H15V16H13Z" fill="#5B4806"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47716 22 12C22 17.5228 17.5228 22 12 22C6.47716 22 2 17.5228 2 12ZM13 7H11V5H13V7ZM11 11H9V9H13V16H15V18H9V16H11V11Z" fill="#F1C523"/>
    </svg>
  </ng-container>
  <ng-container *ngIf="type === 'info'">
    <svg [ngClass]="getIconSize()" class="text-statusColors-yellow shrink-0 translate-y-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12C0 5.37259 5.37259 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47716 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47716 17.5228 2 12 2Z" fill="#F1C523"/>
      <path d="M11 7H13V5H11V7Z" fill="#5B4806"/>
      <path d="M11 16H13V9H9V11H11V16Z" fill="#5B4806"/>
      <path d="M13 16H11H9V18H15V16H13Z" fill="#5B4806"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47716 22 12C22 17.5228 17.5228 22 12 22C6.47716 22 2 17.5228 2 12ZM13 7H11V5H13V7ZM11 11H9V9H13V16H15V18H9V16H11V11Z" fill="#F1C523"/>
    </svg>
  </ng-container>
  <ng-container *ngIf="type === 'success'">
    <svg [ngClass]="getIconSize()" class="shrink-0 text-statusColors-green translate-y-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#239C45"/>
      <path d="M18 7L10 15L6.5 11.5L5 12.8926L9.44649 17.2941C9.58013 17.426 9.76035 17.5 9.94812 17.5C10.1359 17.5 10.3161 17.426 10.4498 17.2941L19.3756 8.51683L18 7Z" fill="#D7F6E0"/>
    </svg>
  </ng-container>

  <button *ngIf="showCloseButton" (click)="hideNotification.emit()" class="absolute top-2 right-2">
    <svg [ngClass]="getCloseButtonColor()" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.6665 1.66699L12.3332 12.3337" stroke="currentColor" stroke-width="2" stroke-linejoin="round"/>
      <path d="M12.3332 1.66699L1.6665 12.3337" stroke="currentColor" stroke-width="2" stroke-linejoin="round"/>
    </svg>
  </button>

  <div class="flex flex-col justify-start flex-1 space-y-1">
    <span [ngClass]="getTitleFontSize()" class="font-sans font-semibold" *ngIf="title">
      {{ title }}
    </span>
    <div class="flex flex-col justify-start items-start space-y-0">
      <span *ngIf="subtitle" [ngClass]="getSubtitleFontSize()" class="font-sans text-black font-semibold">
        {{ subtitle }}
      </span>
      <div [ngClass]="size === 'small' ? 'prose-sm' : 'prose'" class="font-body prose-ul:my-2 text-black marker:text-black" *ngIf="text && text.text">
        {{ text.text }}
        <ng-container *ngIf="text.callToAction">
          <a class="underline cursor-pointer" (click)="callToActionClick.emit()">{{ text.callToAction.text }}</a>.
        </ng-container>
      </div>

      <div [ngClass]="size === 'small' ? 'prose-sm' : 'prose'" class="font-body prose-ul:my-2 max-w-none text-black marker:text-black" [innerHtml]="text.html" *ngIf="text && text.html">
      </div>
    </div>
    <div class="pt-3" *ngIf="button">
      <sami-button (onClick)="buttonClick.emit()" [size]="size" [text]="button.text" [mode]="'primary'"></sami-button>
    </div>
  </div>
</div>