<ng-container *ngIf="instructorAgreement && user && location">
  <app-uploader
    [type]="'file'"
    *ngIf="instructorAgreement.status === 'rejected' || !instructorAgreement.status"
    [placeholder]="'Drop instructor agreement or click to select a file'"
    [hint]="hint"
    [storageCollection]="'users/' + user.uid + '/instructor-agreements'"
    [upsertPath]="'users/' + user.uid + '/instructorAgreement/' + user.uid"
    [key]="'instructorAgreement'"
    (finishedUpload)="onFinishedUpload(user, location)"
    [allowRemoveAfterUpload]="false"
  ></app-uploader>
  <sami-notification
    *ngIf="instructorAgreement && instructorAgreement.status"
    [size]="'small'"
    [type]="getNotificationType(instructorAgreement)"
    [title]="getNotificationTitle(instructorAgreement)"
    [text]="{ text: getNotificationMessage(instructorAgreement) }"
    [shadow]="false"
    [showCloseButton]="false"
  ></sami-notification>
</ng-container>