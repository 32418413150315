import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, WorkoutsService } from '@sami/features';
import {
  Observable,
  combineLatest,
  lastValueFrom,
  map,
  of,
  switchMap,
  take,
} from 'rxjs';


@Component({
  selector: 'app-notes',
  standalone: true,
  imports: [ CommonModule, FormsModule, MatRippleModule ],
  templateUrl: './notes.component.html',
})
export class NotesComponent {
  workoutId$: Observable<any>;
  sessionId$: Observable<any>;
  session$: Observable<any>;

  @Input() extraPaddingOnTop = false;
  @Input() routingPath = '/app';

  trainingLocation?: 'indoor' | 'outdoor' | 'center' | null = null;
  personalExperience?: number | null = null;
  notes = '';

  isNative = false;
  isPWA = false;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public auth: AuthService,
    private workoutService: WorkoutsService,
    private afs: AngularFirestore,
    private analytics: AngularFireAnalytics,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.workoutId$ = this.route.params.pipe(
      map((params: any) => params.workoutId)
    );

    if (isPlatformBrowser(this.platformId)) {
      this.isPWA = (window.matchMedia('(display-mode: standalone)').matches) || ((window.navigator as any).standalone) || document.referrer.includes('android-app://');
    }

    this.sessionId$ = this.route.params.pipe(
      map((params: any) => params.sessionId)
    );

    this.session$ = combineLatest([this.auth.authUser$, this.sessionId$]).pipe(
      switchMap(([user, sessionId]) =>
        user.uid && sessionId
          ? this.workoutService.getUserSession$(user.uid, sessionId)
          : of(null)
      )
    );
  }

  async saveNotes(workoutId: string, userId?: string) {
    if (!userId) {
      return;
    }
    try {
      await this.afs.doc(`users/${userId}/workouts/${workoutId}`).update({
        notes: this.notes || null,
        trainingLocation: this.trainingLocation || null,
        personalExperience: this.personalExperience || null,
      });

      const session = await lastValueFrom(
        this.workoutService.getUserSession$(userId, workoutId).pipe(take(1))
      );
      if (session) {
        if (session.type === 'trainingCreator') {
          await this.analytics.logEvent('completed-training-creator-training', {
            title: session.workoutTitle,
            id: session.workoutRef.id,
          });
        }

        if (session.type === 'guidedTraining') {
          await this.analytics.logEvent('completed-guided-training', {
            title: session.workoutTitle,
            id: session.workoutRef.id,
          });
        }
      }

      this.router.navigate([this.routingPath]);
    } catch (error) {
      console.error(error);
    }
  }
}
