import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UiService } from '@sami/features';
import { SamiUser } from 'interfaces';


@Component({
  selector: 'app-track-hero',
  templateUrl: './track-hero.component.html',
})
export class TrackHeroComponent implements OnInit {
  @Input() image?: string | null = null;
  @Input() trackId: string | null = null;
  @Input() type: 'track' | 'course' = 'track';
  @Input() trackType: 'special' | 'diploma' = 'diploma';
  @Input() showProgress = true;
  @Input() title: string | undefined = 'Panantukan Level 1';
  @Input() lessons = 0;
  @Input() hasSamiInternationalMembership: boolean | undefined = false;
  @Input() startedExamPath = false;
  @Input() description: string | undefined = '';
  @Input() statementOfAccomplishmentBadgePath: string | null = null;
  @Input() certificateBadgePath: string | null = null;
  @Input() diplomaTrackBadge: string | null = null;
  @Input() duration = 15;
  @Input() videos = 20;
  @Input() progress: number | undefined = undefined;
  @Input() success_message?: string =
    'Non vel tellus pellentesque ut. Lectus imperdiet morbi urna enim placerat non. Magna lectus commodo at viverra interdum. Non pellentesque ut amet diam.';
  @Input() system = 'general';
  @Input() status:
    | 'active'
    | 'paused'
    | 'finished'
    | 'inactive'
    | 'exam_passed' = 'inactive';

  @Input() numberOfAssignments = 0;
  @Input() numberOfLiveTrainings = 0;

  @Input() assignmentsPassed = 0;
  @Input() liveTrainingsPassed = 0;
  @Input() user: SamiUser | undefined = undefined;

  @Output() clickOnButton: EventEmitter<any> = new EventEmitter();

  showMoreText = false;

  constructor(private ui: UiService) {}

  ngOnInit(): void {
    if (this.status === undefined) {
      this.status = 'inactive';
    }
  }

  returnSystemComponent(
    identifier:
      | 'kravMaga'
      | 'panantukan'
      | 'axe'
      | 'stick'
      | 'knife'
      | 'ebc'
      | 'kapap'
      | 'core'
      | string,
    type: 'title' | 'color'
  ) {
    if (identifier === 'general') {
      return 'richBlack';
    } else {
      return this.ui.samiSystems[identifier][type] || 'fightingRed-700';
    }
  }
}
