<section *ngIf="seminar" class="py-6 px-5 flex flex-col justify-start space-y-4 relative" [ngClass]="background === 'white' ? 'bg-white' : 'bg-culturedWhite'">
  <div class="flex flex-row justify-between items-center">
    <h3 class="text-xl font-semibold text-richBlack">{{ seminar.seminarType === 'online' ? 'Course Content' : 'Additional online content' }}</h3>
  </div>

  <p class="text-sm font-body text-richBlack" *ngIf="seminar.seminarType !== 'online'">
    This course includes additional online content that you can view online. This can be either support material, preparation for your course/event, or follow-up information. This online content may be limited in availability and to certain ticket types.
  </p>

  <div class="flex flex-col justify-start space-y-4" *ngFor="let track of seminar.tracks">
    <sami-track-content [background]="background" [seminar]="seminar" [seminarTrack]="track" [showTitle]="seminar.tracks && seminar.tracks.length > 0"></sami-track-content>
  </div>
</section>