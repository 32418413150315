<div class="flex flex-col justify-start">
  <div
    class="px-4 py-2 bg-richBlack text-white flex flex-row justify-between items-center"
  >
    <h1 class="text-lg font-semibold">Payment Details</h1>
    <button class="w-4 h-4" (click)="close()">
      <svg
        class="w-4 h-4"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3580_24354)">
          <path
            d="M0.666992 0.666687L15.3337 15.3334"
            stroke="#EDF2EF"
            stroke-width="2"
            stroke-linejoin="round"
          />
          <path
            d="M15.3337 0.666687L0.666992 15.3334"
            stroke="#EDF2EF"
            stroke-width="2"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_3580_24354">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </button>
  </div>
  <div class="px-4 py-3 bg-white">
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
      <div class="grid grid-cols-2 gap-2 sm:grid-cols-1">
        <div class="flex flex-col justify-start space-y-0">
          <span class="uppercase text-xs sm:text-sm text-cadetGrey-400">
            Amount
          </span>
          <span class="text-base font-semibold text-richBlack">
            {{
              data.amount_total / 100 | currency : (data.currency | uppercase)
            }}
          </span>
        </div>

        <div class="flex flex-col justify-start space-y-0">
          <span class="uppercase text-xs sm:text-sm text-cadetGrey-400">
            Link expires at
          </span>
          <span class="text-base font-semibold text-richBlack">
            {{ data.expires_at * 1000 | date : 'dd.MM.yyyy HH:mm' }}
          </span>
        </div>

        <div class="flex flex-col justify-start space-y-0">
          <span class="uppercase text-xs sm:text-sm text-cadetGrey-400">
            Customer Email
          </span>
          <span class="text-base font-semibold text-richBlack">
            {{ data?.customer_details?.email }}
          </span>
        </div>
      </div>
      <div class="flex flex-col justify-start space-y-1 items-end">
        <qrcode
          class="hidden sm:block"
          [qrdata]="data.url"
          [width]="176"
          [errorCorrectionLevel]="'M'"
        ></qrcode>
        <qrcode
          class="sm:hidden block"
          [qrdata]="data.url"
          [width]="268"
          [errorCorrectionLevel]="'M'"
        ></qrcode>
        <span class="text-xs">
          Scan to pay. <strong>Important:</strong> Never share these information
          with someone else than the selected customer.
        </span>
      </div>
    </div>
    <div>
      <label for="url" class="block text-sm font-medium text-richBlack"
        >Payment Link</label
      >
      <div class="mt-1 flex rounded-none">
        <div class="relative flex flex-grow items-stretch focus-within:z-10">
          <input
            type="url"
            name="url"
            id="url"
            class="block w-full rounded-none border-cadetGrey-300 pl-2 focus:border-richBlack focus:ring-richBlack sm:text-sm"
            [value]="data.url"
          />
        </div>
        <button
          type="button"
          [cdkCopyToClipboard]="data.url"
          class="relative -ml-px inline-flex items-center space-x-2 border border-cadetGrey-300 bg-cadetGrey-50 px-4 py-2 text-sm font-medium text-cadetGrey-700 hover:bg-cadetGrey-100 focus:border-richBlack focus:outline-none focus:ring-1 focus:ring-richBlack"
        >
          <span>Copy Link</span>
        </button>
      </div>
    </div>

    <div class="grid grid-cols-1 gap-4 mt-2">
      <button
        (click)="sendNotification()"
        [disabled]="loading"
        class="bg-richBlack disabled:bg-cadetGrey-900 text-sm w-full text-white px-3 py-1.5 flex flex-row justify-center items-center space-x-1"
      >
        <svg
          *ngIf="loading"
          role="status"
          class="w-5 h-5 mr-2 text-gray-200 animate-spin fill-professionalYellow-400"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span> Send Payment Link to Customer </span>
      </button>
    </div>
  </div>
</div>
