import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private renderer: Renderer2;

  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public loadScript(url: string, id: string) {
    if (!document.getElementById(id)) {
      return new Promise((resolve, reject) => {
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.text = ``;
        script.async = true;
        script.defer = true;
        script.onload = resolve;
        script.onerror = reject;
        this.renderer.appendChild(this.document.body, script);
      });
    } else {
      return Promise.resolve(false);
    }
  }
}
