import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { arrayRemove, arrayUnion } from '@angular/fire/firestore';
import { UiService } from '@sami/features';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-combat-system',
  templateUrl: './combat-system.component.html',
})
export class CombatSystemComponent implements OnInit {
  @Input() instructor: any | undefined = undefined;
  isBusy$ = new BehaviorSubject<boolean>(false);

  constructor(public ui: UiService, private afs: AngularFirestore) {}

  ngOnInit() {}

  async updateBlacklistedSystems(id: string) {
    if (this.instructor && this.instructor.id) {
      if (
        this.instructor?.blacklistedSystemIdentifiers &&
        this.instructor.blacklistedSystemIdentifiers.includes(id)
      ) {
        this.afs
          .doc(
            `users/${this.instructor.id}/publicProfiles/${this.instructor.id}`
          )
          .set(
            {
              blacklistedSystemIdentifiers: arrayRemove(id),
            },
            { merge: true }
          );
      } else {
        this.afs
          .doc(
            `users/${this.instructor.id}/publicProfiles/${this.instructor.id}`
          )
          .set(
            {
              blacklistedSystemIdentifiers: arrayUnion(id),
            },
            { merge: true }
          );
      }
    }
  }
}
