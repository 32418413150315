import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SamiIconsRegistry } from '../icon/icon-registry.service';
import { SamiIconComponent } from '../icon/icon.component';
import { gear, menu, pencil, samiDocumentDownload, samiHorizontalMediaFiles, samiIconsAdd, samiIconsArrowLeft, samiIconsArrowRight, samiIconsClose, samiIconsSave, samiMaximiseNewTab, samiPaymentSchedule, samiPublish } from '../icon/sami-icons';
import { MatRippleModule } from '@angular/material/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-button',
  templateUrl: './button.component.html',
  standalone: true,
  imports: [
    CommonModule,
    SamiIconComponent,
    MatRippleModule
  ]
})
export class SamiButtonComponent {
  @Input() text = '';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() align: 'start' | 'full' = 'start';
  @Input() disabled = false;
  @Input() mode: 'primary' | 'secondary' | 'tertiary' | 'icon' = 'primary';
  @Input() loading = false;
  @Input() icon: 'add' | 'pencil' | 'menu' | 'gear' | 'arrow-right' | 'close' | 'save' | 'arrow-left' | 'payment-schedule' | 'horizontal-media-files' | 'publish' | 'maximise-new-tab' | 'document-download' | undefined;
  @Input() iconPosition: 'left' | 'right' | undefined = undefined;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick = new EventEmitter();

  constructor(private samiIconRegistry: SamiIconsRegistry) {
    this.samiIconRegistry.registerIcons([
      samiIconsAdd,
      samiIconsArrowRight,
      samiIconsArrowLeft,
      samiHorizontalMediaFiles,
      samiPublish,
      samiMaximiseNewTab,
      samiDocumentDownload,
      samiPaymentSchedule,
      samiIconsClose,
      samiIconsSave,
      menu,
      gear,
      pencil
    ]);
  }

  onButtonClick() {
    if (this.disabled || this.loading) {
      return;
    } else {
      this.onClick.emit();
    }
  }

  getButtonClass() {
    let classes = '';

    if (this.align === 'full') {
      classes += 'w-full ';
    }

    if (this.size === 'small' && this.mode !== 'icon') {
      classes += 'px-3.5 py-2 text-xs ';
    }
    if (this.size === 'medium' && this.mode !== 'icon') {
      classes += 'px-5 py-2.5 text-base ';
    }
    if (this.size === 'large' && this.mode !== 'icon') {
      classes += 'px-7 py-3.5 text-lg ';
    }
    if (this.mode === 'icon') {
      classes += 'min-h-[36px] min-w-[36px] bg-richBlack border-2 p-2 disabled:cursor-not-allowed border-richBlack hover:border-cadetGrey-800 hover:bg-cadetGrey-800 text-culturedWhite text-base font-semibold leading-normal'
    }
    if (this.mode === 'primary') {
      classes += ' bg-richBlack border-2 disabled:cursor-not-allowed border-richBlack hover:border-cadetGrey-800 hover:bg-cadetGrey-800 text-culturedWhite text-base font-semibold leading-normal'
    }
    if (this.mode === 'secondary') {
      classes += ' bg-white border-2 disabled:cursor-not-allowed border-richBlack hover:border-cadetGrey-800 hover:text-cadetGrey-800 text-richBlack text-base font-semibold leading-normal'
    }
    if (this.mode === 'tertiary') {
      classes += ' bg-transparent border-2 disabled:cursor-not-allowed border-transparent hover:underline hover:text-cadetGrey-800 text-richBlack text-base font-semibold leading-normal'
    }

    return classes;
  }

  getIconClass() {
    let classes = '';

    if (this.size === 'small') {
      classes += ' w-3 h-3';
    }
    if (this.size === 'medium') {
      classes += ' w-3.5 h-3.5';
    }
    if (this.size === 'large' && this.mode !== 'icon') {
      classes += ' w-5 h-4';
    }
    if (this.size === 'large' && this.mode === 'icon') {
      classes += ' w-5 h-5';
    }
    if (this.iconPosition === 'right') {
      classes += ' order-last';
    }
    if (this.iconPosition === 'left') {
      classes += ' order-first';
    }
    if (this.mode !== 'icon' && this.iconPosition === 'left') {
      classes += ' mr-2';
    }
    if (this.mode !== 'icon' && this.iconPosition === 'right') {
      classes += ' ml-2';
    }

    return classes;
  }

  getLoadingClass() {
    let classes = '';

    if (this.size === 'small') {
      classes += ' w-3 h-3';
    }
    if (this.size === 'medium') {
      classes += ' w-3.5 h-3.5';
    }
    if (this.size === 'large') {
      classes += ' w-5 h-5';
    }
    if (this.mode === 'primary') {
      classes += ' text-cadetGrey-800 fill-culturedWhite';
    }
    if (this.mode !== 'primary') {
      classes += ' text-culturedWhite fill-cadetGrey-800';
    }
    if (this.mode !== 'icon') {
      classes += ' mr-2';
    }

    return classes;
  }


}
