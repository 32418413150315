import { Component, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { arrayRemove, arrayUnion } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, finalize } from 'rxjs';

@Component({
  selector: 'app-upload-gallery',
  templateUrl: './upload-gallery.component.html',
})
export class UploadGalleryComponent {
  @Input() instructor: any | undefined = undefined;
  uploadPercent: Observable<number> | any;
  isBusy$ = new BehaviorSubject<boolean>(false);
  files: File[] = [];
  constructor(
    private storage: AngularFireStorage,
    private afs: AngularFirestore
  ) {}

  uploadFiles() {
    this.isBusy$.next(true);
    if (this.files && this.files.length > 0) {
      let i = this.files.length;
      while (i--) {
        const file = this.files[i];
        const filePath = `users/${this.instructor.id}/publicProfiles/${this.instructor.id}/gallery/${file.name}`;
        const task = this.storage.upload(filePath, file);
        this.uploadPercent = task.percentageChanges();
        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              this.addImageToGallery(filePath);
              this.files.splice(i, 1);
              if (this.files.length === 0) {
                this.isBusy$.next(false);
                this.uploadPercent = new Observable();
              }
            })
          )
          .subscribe();
      }
    }
  }
  onSelect(event: any) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  async addImageToGallery(path: string) {
    if (this.instructor && this.instructor.id) {
      this.afs
        .doc(`users/${this.instructor.id}/publicProfiles/${this.instructor.id}`)
        .set(
          {
            galleryImagePaths: arrayUnion(path),
          },
          { merge: true }
        );
    }
  }

  async removeImage(path: string) {
    if (this.instructor && this.instructor.id) {
      this.afs
        .doc(`users/${this.instructor.id}/publicProfiles/${this.instructor.id}`)
        .set(
          {
            galleryImagePaths: arrayRemove(path),
          },
          { merge: true }
        );
    }
  }
}
