<div
  class="bg-culturedWhite relative p-3 sm:p-6 font-sans"
  *ngIf="auth.user$ | async as user"
>
  <button (click)="dialogRef.close()" class="w-4 h-4 absolute top-4 right-4">
    <svg
      class="w-4 h-4"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3849_30117)">
        <path
          d="M1 1L23 23"
          stroke="black"
          stroke-width="2"
          stroke-linejoin="round"
        />
        <path
          d="M23 1L1 23"
          stroke="black"
          stroke-width="2"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  </button>

  <div
    *ngIf="!success"
    class="bg-culturedWhite flex flex-col justify-start items-start space-y-3 sm:space-y-8"
  >
    <h1 class="font-semibold text-xl sm:text-3xl">
      Are you sure you want to downgrade?
    </h1>

    <div
      class="bg-white p-4 flex flex-col sm:flex-row justify-start sm:items-center sm:space-y-0 space-y-2 sm:space-x-6"
    >
      <svg
        class="h-8 w-8 sm:w-12 sm:h-12 flex-shrink-0"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2790_20792)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24 0C24.7575 0 25.4501 0.428005 25.7889 1.10557L47.7889 45.1056C48.0988 45.7255 48.0657 46.4618 47.7013 47.0515C47.3369 47.6411 46.6932 48 46 48H2C1.30685 48 0.663114 47.6411 0.298701 47.0515C-0.0657127 46.4618 -0.0988407 45.7255 0.211148 45.1056L22.2111 1.10557C22.5499 0.428005 23.2425 0 24 0ZM5.23607 44H42.7639L24 6.47214L5.23607 44Z"
            fill="#990800"
          />
          <path d="M26 18H22V32H26V18Z" fill="#990800" />
          <path d="M26 36H22V40H26V36Z" fill="#990800" />
        </g>
        <defs>
          <clipPath id="clip0_2790_20792">
            <rect width="48" height="48" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <div class="flex flex-col justify-center items-start space-y-1">
        <h3 class="text-fightingRed-800 font-semibold text-lg sm:text-xl">
          Before you downgrade your membership, please know this:
        </h3>
        <p class="text-sm sm:text-base">
          You will lose access to weekly guided trainings, live trainings, the
          training creator, tracks and access to all combat systems in the video
          library except for one. The change will take effect at the beginning
          of the next billing cycle.
        </p>

        <div class="w-full" [formGroup]="form">
          <label
            for="system"
            class="block text-base font-semibold text-richBlack"
            >Combat System</label
          >
          <div class="mt-1 grid sm:grid-cols-2 gap-2 sm:gap-10">
            <div>
              <select
                formControlName="system"
                id="system"
                class="block w-full font-body rounded-none border-cadetGrey-300 shadow-none focus:border-richBlack focus:ring-richBlack text-sm"
              >
                <option ngValue="kravMaga">SAMI-X Krav Maga</option>
                <option ngValue="knife">SAMI-X Knife</option>
                <option ngValue="stick">SAMI-X Stick</option>
                <option ngValue="axe">SAMI-X Axe</option>
                <option ngValue="panantukan">SAMI-X Panantukan</option>
              </select>
            </div>
            <span class="text-xs font-body text-cadetGrey-600">
              Please select the SAMI Combat System you want to keep with the
              SAMI International Membership.
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="flex flex-col sm:flex-row w-full justify-start sm:justify-between sm:items-center sm:space-y-0 space-y-2 sm:space-x-6"
    >
      <button
        mat-dialog-close
        class="px-2 py-2 sm:px-4 sm:py-2 text-sm sm:text-base font-semibold border-2 border-richBlack bg-white rounded-none text-richBlack font-base"
      >
        No, I want to stay
      </button>

      <button
        (click)="downgrade()"
        [disabled]="loading"
        class="px-2 py-2 sm:px-4 sm:py-2 text-sm sm:text-base font-semibold border-2 border-richBlack hover:bg-cadetGrey-900 flex flex-row justify-center items-center space-x-2 bg-richBlack rounded-none text-white font-base"
      >
        <svg
          *ngIf="loading"
          role="status"
          class="w-5 h-5 mr-4 text-gray-200 animate-spin fill-professionalYellow-400"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span> Yes, downgrade on period end </span>
      </button>
    </div>
  </div>

  <div
    *ngIf="success"
    class="bg-culturedWhite flex flex-col justify-start items-start space-y-3 sm:space-y-8"
  >
    <h1 class="font-semibold text-xl sm:text-3xl">
      Downgrade successfully initiated
    </h1>
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M82.2641 20L35.0601 66.4L12.9521 44.52L7.32812 50.208L32.2361 74.864C32.9847 75.6028 33.9942 76.0172 35.0461 76.0172C36.098 76.0172 37.1075 75.6028 37.8561 74.864L87.8561 25.696L82.2641 20Z"
        fill="#048F3C"
      />
    </svg>
    <div class="flex flex-col justify-start items-start space-y-1 w-full">
      <span class="font-body text-sm sm:text-base">
        Your downgrade request was successful. It will become effective at the
        beginning of your next billing cycle.
      </span>
    </div>

    <div class="flex flex-row w-full justify-center items-center">
      <button
        mat-dialog-close
        class="px-2 py-2 sm:px-4 sm:py-2 text-sm sm:text-base font-semibold border-2 border-richBlack hover:bg-cadetGrey-900 bg-richBlack rounded-none text-white font-base"
      >
        Close
      </button>
    </div>
  </div>
</div>
