import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cancel',
  standalone: true,
  imports: [ CommonModule ],
  templateUrl: './cancel.component.html',
})
export class CancelComponent {
  mode: 'exam' | 'workout' = 'workout';
  constructor(public dialogRef: MatDialogRef<CancelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.mode = data.mode ? data.mode : 'workout';
    }

  cancelTraining() {
    this.dialogRef.close('cancel');
  }

  continueTraining() {
    this.dialogRef.close();
  }
}
