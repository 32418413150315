import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SamiSeminar } from 'interfaces';
import { ClassContentComponent } from './class-content/class-content.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-seminar-live-class',
  standalone: true,
  imports: [
    CommonModule,
    ClassContentComponent
  ],
  templateUrl: './seminar-live-class.component.html'
})
export class SeminarLiveClassComponent {
  @Input() seminar: SamiSeminar | undefined;
  @Input() background: 'white' | 'culturedWhite' = 'white';

}
