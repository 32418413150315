import { Component, forwardRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldErrorsComponent } from '../field-errors/field-errors.component';
import { AbstractControl, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-text-area',
  standalone: true,
  imports: [CommonModule, FieldErrorsComponent],
  templateUrl: './text-area.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SamiTextAreaComponent), multi: true,
    }
  ],
})
export class SamiTextAreaComponent {
  public isDisabled = false;

  @Input() parentForm!: FormGroup;
  @Input() fieldName!: string;
  @Input() label!: string;
  @Input() placeholder!: string;
  @Input() hint: string | undefined;
  @Input() displayLabel = false;
  @Input() displayOptional = true;
  @Input() maxLengthLabel: number | undefined;
  @Input() rows = 5;

  public value = '';
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public changed: (value: string) => void = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public touched: () => void = () => {};

  get formField (): FormControl {
    return this.parentForm.get(this.fieldName) as FormControl;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  public onChange( event: Event ): void {
    const value: string = (event.target as HTMLInputElement).value;
    this.changed( value );
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  get validator() {
    if (this.formField.validator) {
      const validator = this.formField.validator({} as AbstractControl);
      if (validator && validator['required']) {
        return true;
      }
    }
    return false;
  }
}
