import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CombatSystemComponent } from './combat-system/combat-system.component';
import { UploadGalleryComponent } from './upload-gallery/upload-gallery.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { EditInstructorModalComponent } from './edit-instructor-modal.component';
import { GetDownloadURLPipeModule } from '@angular/fire/compat/storage';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { SeoComponent } from './seo/seo.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { GoogleMapsModule } from '@angular/google-maps';
import { ImagePipe } from '../../pipes/transformImage.pipe';

@NgModule({
  declarations: [
    AboutComponent,
    ContactComponent,
    UploadGalleryComponent,
    CombatSystemComponent,
    EditInstructorModalComponent,
    SeoComponent,
  ],
  imports: [
    CommonModule,
    GetDownloadURLPipeModule,
    ImagePipe,
    NgxDropzoneModule,
    ReactiveFormsModule,
    MatTabsModule,
    EditorModule,
    GoogleMapsModule,
  ],
  exports: [EditInstructorModalComponent],
})
export class EditInstructorModalModule {}
