export interface SamiUserStats {
  userId: string;
  tracksCompleted: number;
  badgesEarned: number;
  certificationsEarned: number;
  videosWatched: number;
  trainingCreatorTrainingsCompleted: number;
  guidedTrainingsCompleted: number;
  liveTrainingsCompleted: number;
  playlistsCompleted: number;
  joinedAt: number | Date;
}
