import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, of, switchMap } from 'rxjs';
import { Workout, UserWorkout } from 'interfaces';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class WorkoutsService {
  constructor(private afs: AngularFirestore, private auth: AuthService) {}

  public getWorkouts$(): Observable<any> {
    return this.afs
      .collection<Workout[]>(`workouts`)
      .valueChanges({ idField: 'id' });
  }

  public getPredefinedWorkouts$(): Observable<any> {
    return this.afs
      .collection<Workout[]>(`workouts`, ref => ref.where('isFeatured', '==', true).where('scope', '==', 'global').where('status', '==', 'active').limit(6))
      .valueChanges({ idField: 'id' });
  }

  public getWorkoutsFiltered$({
    system,
    level,
    duration,
  }: any): Observable<any> {
    return this.afs
      .collection<Workout[]>(`workouts`, (ref) =>
        ref
          .where('system', '==', system)
          .where('level', '==', level)
          .where('duration', '==', duration * 60)
      )
      .valueChanges({ idField: 'id' });
  }

  public getWorkout$(workoutId: string): Observable<Workout | undefined> {
    return this.afs
      .doc<Workout>(`workouts/${workoutId}`)
      .valueChanges({ idField: 'id' });
  }

  public getUserSession$(
    userId: string,
    userWorkoutId: string
  ): Observable<any | undefined> {
    return this.afs
      .doc<any>(`users/${userId}/workouts/${userWorkoutId}`)
      .valueChanges({ idField: 'id' });
  }

  public getUserSessions$(): Observable<UserWorkout[] | any[] | null> {
    return this.auth.authUser$.pipe(
      switchMap((authUser: any) =>
        authUser?.uid
          ? this.afs
              .collection<UserWorkout[]>(
                `users/${authUser.uid}/workouts`,
                (ref) =>
                  ref
                    .where('status', '==', 'finished')
                    .orderBy('createdAt', 'desc')
                    .limit(50)
              )
              .valueChanges({ idField: '_id' })
          : of(null)
      )
    );
  }

  public updateUserWorkout(userId: any, userWorkoutId: string, data: any) {
    return this.afs
      .doc<any>(`users/${userId}/workouts/${userWorkoutId}`)
      .set(data, { merge: true });
  }
}
