import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UiService } from '@sami/features';
import { SamiUser } from 'interfaces';
import { Observable } from 'rxjs';
import { SamiButtonComponent } from '../../button/button.component';
import { SamiSelectComponent } from '../../input-fields/select/select.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SamiTextAreaComponent } from '../../input-fields/text-area/text-area.component';
import { SamiAutocompleteComponent } from '../../input-fields/autocomplete/autocomplete.component';
import { SamiDatepickerComponent } from '../../input-fields/datepicker/datepicker.component';

interface FixedExamData {
  style: any; // Replace 'any' with a more specific type if possible
  generalNote: any;
  history: {
    center?: any;
    date: any;
    exam?: number;
    level?: number;
    instructor?: any;
  }[];
  exam?: number; // Optional property
  level?: number; // Optional property
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-add-diploma-modal',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SamiSelectComponent,
    MatAutocompleteModule,
    SamiButtonComponent,
    SamiTextAreaComponent,
    SamiAutocompleteComponent,
    SamiDatepickerComponent
  ],
  templateUrl: './add-diploma-modal.component.html'
})
export class AddDiplomaModalComponent {
  customer$: Observable<(SamiUser & { uid: string; }) | undefined>;
  customerId: string | undefined;
  examId: string | undefined;

  systems: any[] = [];
  addExamForm = new FormGroup({
    style: new FormControl('knife', Validators.required),
    exam: new FormControl(0),
    level: new FormControl(0),
    date: new FormControl(new Date(), Validators.required),
    instructor: new FormControl(null, Validators.required),
    center: new FormControl(null, Validators.required),
    note: new FormControl(''),
    generalNote: new FormControl(''),
    status: new FormControl('passed', Validators.required),
  });

  mode: 'student' | 'instructor' = 'student';
  availableRanks: { key: number, value: string }[] = [];

  constructor(
    public dialogRef: MatDialogRef<AddDiplomaModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private afs: AngularFirestore,
    private ui: UiService
  ) {
    this.systems = this.ui.samiSystemsAsArray();

    this.mode = data.mode;
    this.examId = data.examId;

    // map identifier to key and title to value of systems
    this.systems = this.systems.map((s) => {
      return {
        key: s.identifier,
        value: s.title
      };
    });

    this.availableRanks = this.receiveRanks('knife');
    this.addExamForm?.get("style")?.valueChanges.subscribe(selectedValue => {
      if (!selectedValue) return;
      this.availableRanks = this.receiveRanks(selectedValue);
    })


    this.customerId = data.customerId;
    this.customer$ = this.afs.doc<(SamiUser & { uid: string; }) | undefined>(`users/${data.customerId}`).valueChanges({ idField: 'uid' });
  }

  receiveRanks(style: string) {
    const styleIdentier = style;

    const styleRanks = this.ui.styleExamRanks.find((rank) =>
      rank.styleIdentiers.includes(styleIdentier)
    );
    if (this.mode === 'instructor') {
      // map styleranks.ranks which is an array of strings into an array of objects with key and value properties
      return styleRanks?.ranks.map((rank, index) => {
        return {
          key: index,
          value: rank
        };
      }) || [];
    } else {
      return styleRanks?.ranks.map((rank, index) => {
        return {
          key: index,
          value: index === 0 ? 'Trainee' : 'Level ' + (index)
        };
      }) || [];
    }
  }

  async onSave(customer: SamiUser) {
    if (this.addExamForm.invalid) {
      return;
    }

    const formData = this.addExamForm.getRawValue();
    const finalData = this.prepareFinalData(formData);

    // Create a history entry based on the mode (instructor or student)
    const historyEntry = this.createHistoryEntry(finalData);


    const fixedExamData: FixedExamData = {
      style: finalData.style,
      generalNote: finalData.generalNote,
      history: [historyEntry],
      ...(this.mode === 'instructor' && { exam: this.getExamOrLevel(finalData, 'exam') }),
      ...(this.mode === 'student' && { level: this.getExamOrLevel(finalData, 'level') }),
    };

    if (this.mode === 'instructor') {
      await this.processInstructorMode(customer, fixedExamData);
    } else {
      await this.processStudentMode(customer, fixedExamData);
    }

    this.closeDialog();
  }

  createHistoryEntry(finalData: any): any {
    const historyEntry = { ...finalData };
    delete historyEntry.style;
    delete historyEntry.generalNote;
    delete historyEntry.exam;
    delete historyEntry.level;

    // Assign the 'exam' or 'level' based on the mode
    if (this.mode === 'instructor') {
      historyEntry.exam = this.getExamOrLevel(finalData, 'exam');
    } else if (this.mode === 'student') {
      historyEntry.level = this.getExamOrLevel(finalData, 'level');
    }

    return historyEntry;
  }

  prepareFinalData(formData: any): any {
    return {
      ...formData,
      date: formData.date ? new Date(formData.date) : new Date(),
      level: this.safeNumber(formData.level),
      exam: this.safeNumber(formData.exam),
      style: this.ui.samiSystemsAsArray().find((s: any) => s.identifier === formData.style),
    };
  }

  getExamOrLevel(data: any, key: 'exam' | 'level'): number {
    return data.status === 'passed' ? this.safeNumber(data[key]) : 0;
  }

  async processInstructorMode(customer: SamiUser, fixedExamData: FixedExamData) {
    const styleExams = customer.styleExams || [];
    const examIndex = styleExams.findIndex(l => l.style && l.style.identifier === fixedExamData.style.identifier);

    if (examIndex >= 0) {
      styleExams[examIndex] = this.updateExamData(styleExams[examIndex], fixedExamData, 'exam');
    } else {
      styleExams.push(fixedExamData);
    }
    if (customer.uid) {
      await this.updateFirestoreDocument(customer.uid, { styleExams });
      await this.updateExamStatus(customer.uid);
    }
  }

  async processStudentMode(customer: SamiUser, fixedExamData: FixedExamData) {
    const styleLevels = customer.styleLevels || [];
    const levelIndex = styleLevels.findIndex(l => l.style && l.style.identifier === fixedExamData.style.identifier);

    if (levelIndex >= 0) {
      styleLevels[levelIndex] = this.updateExamData(styleLevels[levelIndex], fixedExamData, 'level');
    } else {
      styleLevels.push(fixedExamData);
    }

    if (customer.uid) {
      await this.updateFirestoreDocument(customer.uid, { styleLevels });
      await this.updateExamStatus(customer.uid);
    }
  }

  updateExamData(existingData: any, newData: FixedExamData, key: 'exam' | 'level'): any {
    if (newData.history.length === 0) {
      return existingData; // No updates if newData.history is empty
    }

    const newHistoryItem = newData.history[0];
    let historyUpdated = false;

    const updatedHistory = existingData.history.map((historyItem: { [x: string]: number | undefined; }) => {
      if (historyItem[key] === newHistoryItem[key]) {
        historyUpdated = true;
        return {...newHistoryItem}; // Replace with the new history item
      }
      return historyItem;
    });

    if (!historyUpdated) {
      // Add new history item if no existing entry was updated
      updatedHistory.push(newHistoryItem);
    }

    return {
      ...existingData,
      generalNote: newData.generalNote,
      [key]: Math.max(this.safeNumber(newData[key]), existingData[key]),
      history: updatedHistory
    };
  }



  async updateFirestoreDocument(uid: string, updateData: any) {
    try {
      await this.afs.doc(`users/${uid}`).update(updateData);
    } catch (error) {
      console.error('Firestore update failed:', error);
    }
  }

  safeNumber(value: any, defaultValue = 0) {
    const number = Number(value);
    return isNaN(number) ? defaultValue : number;
  }

  closeDialog() {
    this.dialogRef.close();
    // Any additional cleanup or notifications
  }

  async updateExamStatus(customerId: string) {
    if (!this.examId) {
      return;
    }

    const form = this.addExamForm.getRawValue();

    try {
      await this.afs.doc(`exams/${this.examId}/attendees/${customerId}`).update({
        examResult: form.status,
        examComment: form.note
      });
    } catch (error) {
      console.error('EXAM ATTENDEE UPDATE FAILED:', error);
    }
  }
}
