import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { LiveTraining, SamiUser, SamiUserPurchase } from 'interfaces';
import { Observable, combineLatest, map, of, shareReplay, switchMap, tap } from 'rxjs';
import { UiService } from './ui.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class LiveTrainingService {
  liveTrainings$: Observable<LiveTraining[]>;
  pastLiveTrainings$: Observable<LiveTraining[]>;

  constructor(
    private afs: AngularFirestore,
    private ui: UiService,
    private auth: AuthService,
  ) {
    const source$ = combineLatest([
      this.ui.currentSite$,
      this.auth.user$,
      this.auth.getUserPurchases$('liveTrainings'),
    ]).pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
      tap(([currentSite, user, purchases]) => {
        console.log('currentSite', currentSite);
        console.log('user', user);
        console.log('purchases', purchases);
      }),
    );

    this.liveTrainings$ = source$.pipe(
      switchMap(([currentSite, user, purchases]) => currentSite
        ? this.afs.collection<LiveTraining>('liveTrainings', (ref) => ref
          .where('endDate', '>=', new Date())
          .where('sites', 'array-contains', currentSite)
          .limit(18)
        ).valueChanges({ idField: '_id' }).pipe(
          map((liveTrainings: LiveTraining[]) => this._mapLiveTrainings(liveTrainings, user, purchases))
        )
        : of([])
      )
    );

    this.pastLiveTrainings$ = source$.pipe(
      switchMap(([currentSite, user, purchases]) => currentSite
        ? this.afs.collection<LiveTraining>('liveTrainings', (ref) => ref
          .where('endDate', '<', new Date())
          .where('sites', 'array-contains', currentSite)
          .where('endDate', '>=', new Date(new Date().valueOf() - (1000 * 60 * 60 * 24 * 30))) // 30 days
          .limit(6)
        ).valueChanges({ idField: '_id' }).pipe(
          map((liveTrainings: LiveTraining[]) => this._mapLiveTrainings(liveTrainings, user, purchases))
        )
        : of([])
      )
    );
  }

  private _mapLiveTrainings(liveTrainings: LiveTraining[], user: SamiUser, purchases: SamiUserPurchase[]) {
    return liveTrainings.map((liveTraining: LiveTraining) => {
      const hasPurchased = !!purchases.find((purchase: SamiUserPurchase) => purchase.docId === liveTraining._id);
      return {
        ...liveTraining,
        hasPurchased,
        hasAccess: hasPurchased || user?.subscriptionDigest?.hasSubscription?.plus || false,
      }
    });
  }

  getLiveTraining$(id: string) {
    return combineLatest([
      this.afs.doc<LiveTraining>(`liveTrainings/${id}`).valueChanges({ idField: '_id' }),
      this.auth.user$,
      this.auth.getUserPurchases$('liveTrainings'),
    ]).pipe(
      map(([liveTraining, user, purchases]) => {
        const hasPurchased = !!purchases.find((purchase: SamiUserPurchase) => purchase.docId === liveTraining?._id);
        return {
          ...liveTraining,
          hasPurchased,
          hasAccess: hasPurchased || user?.subscriptionDigest?.hasSubscription?.plus || false,
        }
      })
    );
  }

}
