import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuccessSnackbarComponent } from './success-snackbar/success-snackbar.component';
import { ErrorSnackbarComponent } from './error-snackbar/error-snackbar.component';

@NgModule({
  declarations: [SuccessSnackbarComponent, ErrorSnackbarComponent],
  imports: [CommonModule],
  exports: [SuccessSnackbarComponent, ErrorSnackbarComponent],
})
export class SnackbarsModule {}
