import { Timestamp } from "@angular/fire/firestore";

export interface CartSession {
  id: string;
  object: string;
  client_secret: null;
  expires_at: Timestamp;
  livemode: boolean;
  allow_guest_checkout: boolean;
  purpose: 'shop' | 'ticket' | 'membership';
  base_url: string | null;
  target: 'sami-x' | 'sami-international' | 'join-sami';
  payment_options: 'online' | 'counter' | 'both';
  payment_selection: 'online' | 'counter';
  settings: {
    allow_promotion_codes: boolean;
  }
  subscription_data?: {
    trial_period_days: number | null;
    trial_settings: {
      end_behavior: {
        missing_payment_method: 'cancel' | 'pause' | 'create_invoice';
      }
    }
  };
  currency: string;
  empty?: boolean;
  item_count: number;
  items: LineItem[];
  note: string | null;
  requires_shipping: boolean | null;
  taxes_included: boolean | null;
  total_discount: number | null;
  total_price: number;
  connect: {
    stripeAccountId: string;
    type: 'center' | 'instructor';
    id: string;
  } | null;
  checkout_url: string | null;
  checkout_settings: {
    user_id: string | null; // if null, create a new customer
    customer: CustomerInformation | null; // if null, use the customer's default information
    is_guest_checkout: boolean; // if true, create a guest checkout with stripe
    client_reference_id: string; // A unique string to reference the checkout session like the cart_session_id or rewardful
    metadata: {
      [key: string]: any;
    } | null;
    payment_intent_data: {
      metadata: {
        [key: string]: any;
      } | null;
    }
  } | null;
}

export interface CustomerInformation {
  name: string;
  address: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    postal_code: string;
    country: string;
  } | null;
  email: string | null;
  user_id: string | null;
}

export interface CheckoutSession {
  id: string;
  object: string;
  cancel_url: string;
  client_reference_id: string;
  currency: string;
  customer: string | Customer;
  customer_email: string | null;
  line_items: LineItem[];
  metadata: {
    [key: string]: any;
  } | null;
  mode: 'payment' | 'subscription' | 'setup' | 'onsite';
  payment_intent: string;
  payment_status: 'paid' | 'unpaid' | 'no_payment_required';
  status: 'open' | 'complete' | 'expired';
  success_url: string;
  url: string;
}

export interface Customer {
  id: string;
  address: {
    city: string;
    country: string;
    line1: string;
    line2: string;
    postal_code: string;
    state: string;
  } | null;
  description: string | null;
  email: string;
  metadata: {
    [key: string]: any;
  } | null;
  name: string;
  phone: string | null;
  shipping: {
    address: {
      city: string;
      country: string;
      line1: string;
      line2: string;
      postal_code: string;
      state: string;
    };
    name: string;
    phone: string;
  } | null
  object: string;
  currency: string | null;
}

export interface LineItem {
  id: string; // price ID
  amount_total: number;
  amount_subtotal: number;
  amount_tax: number;
  currency: string;
  image: string | null;
  name: string; // Product Name
  description: string // Product Description
  product: Product | string;
  price: Price;
  quantity: number;
  url: string | null;
  tax_rates: string[] | null;
}

export interface CheckoutLineItems {
  price: string;
  quantity: number;
  adjustable_quantity?: {
    enabled: boolean;
    maximum: number | undefined;
    minimum: number | undefined;
  };
  tax_rates?: string[];
}

export interface Price {
  id: string;
  active: boolean;
  currency: string;
  metadata: {
    [key: string]: any;
  } | null;
  nickname: string | null;
  product: string;
  recurring: {
    aggregate_usage: string;
    interval: 'day' | 'month' | 'week' | 'year';
    interval_count: number;
    usage_type: 'licensed' | 'metered'
  } | null;
  type: 'one_time' | 'licensed';
  unit_amount: number;
  object: string;
  billing_scheme: 'per_unit' | 'tiered';
  created: number;
  currency_options: any;
  custom_unit_amount: {
    maximum: number;
    minimum: number;
    preset: number;
  };
  livemode: boolean;
  lookup_key: string | null;
  tax_behavior: 'inclusive' | 'exclusive' | 'unspecified';
  tiers: any;
  tiers_mode: 'graduated' | 'volume' | null;
  transform_quantity: any | null;
  unit_amount_decimal: number;
  stripeAccountId: string | null;
}

export interface Product {
  id: string;
  active: boolean;
  default_price: string | null;
  description: string | null;
  metadata: {
    [key: string]: any;
  } | null;
  currency_options: CurrencyOption[];
  name: string;
  sku: string | null;
  object: string;
  attribute_values: any[] | null;
  created: number;
  identifiers: any | null;
  images: string[] | [];
  livemode: boolean;
  package_dimensions: {
    height: number;
    length: number;
    weight: number;
    width: number;
  } | null;
  price: string | null;
  product_class: string | null;
  shippable: boolean | null;
  statement_descriptor: string | null;
  tax_code: string | null;
  unit_label: string | null;
  updated: number;
  url: string | null;
  stripeAccountId: string | null;
}

export interface ProductClass {
  id: string;
  active: boolean;
  description: string | null;
  metadata: {
    [key: string]: any;
  } | null;
  name: string;
  object: string;
  attributes: any[] | null;
  created: number;
  images: string[] | [];
  livemode: boolean;
  updated: number;
  url: string | null;
}

export interface CurrencyOption {
  [currency: string]: {
    tax_behavior: 'inclusive' | 'exclusive' | 'unspecified';
    unit_amount: number;
  }
}