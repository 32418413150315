<ng-container *ngIf="upcoming$ | async as upcoming">
  <div class="bg-white p-2 border-t-2 border-culturedWhite">
    <button
      class="border-2 w-full border-richBlack py-2 hover:bg-culturedWhite px-4 flex flex-row justify-end items-center space-x-2"
      [routerLink]="[upcoming.url]"
      [queryParams]="upcoming.queryParams"
    >
      <div class="flex flex-col justify-center items-end">
        <span
          class="uppercase font-semibold text-cadetGrey-400 text-sm text-right"
          >Up next in your Track:</span
        >
        <span
          class="text-base font-semibold text-richBlack uppercase text-right"
          >{{ upcoming.title }}</span
        >
      </div>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 18.5L23 12.5L17 6.5"
          stroke="#001427"
          stroke-width="2"
          stroke-linejoin="round"
        />
        <path
          d="M0 12.5H23"
          stroke="#001427"
          stroke-width="2"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </div>
</ng-container>
