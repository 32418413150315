import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { SamiSeminar, SeminarLiveClass } from 'interfaces';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-class-content',
  templateUrl: './class-content.component.html',
  standalone: true,
  imports: [
    CommonModule
  ],
})
export class ClassContentComponent implements OnInit {
  @Input() class!: SeminarLiveClass;
  @Input() seminar!: SamiSeminar;
  availableForTicketTypes!: string;

  ngOnInit(): void {
    if (this.class) {
      const availableForTicketTypes = this.seminar?.ticketTypes?.filter((tt: any) => this.class?.ticketTypeIds?.includes(tt.stripePriceId)).map((tt: any) => tt.name) ?? [];
      this.availableForTicketTypes = availableForTicketTypes.length === this.seminar?.ticketTypes?.length ? 'all ticket types' : availableForTicketTypes.join(', ');
    }
  }
}
