import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadFileComponent } from './upload-file.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [UploadFileComponent],
  imports: [CommonModule, NgxDropzoneModule, MatButtonModule],
  exports: [UploadFileComponent],
})
export class UploadFileModule {}
