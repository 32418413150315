import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class InstructorGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.user$.pipe(
      take(1),
      tap((user) => {
        if (user && user.subscriptionDigest && user.subscriptionDigest?.hasSubscription && user.subscriptionDigest?.hasSubscription?.instructor) {
          this.router.navigate(['/app/instructor-area/account']);
        }
      }),
      map((user) => !!user)
    );
  }
}
