import { Component, OnInit, Input } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MatDialog } from '@angular/material/dialog';
import { UiService } from '@sami/features';
import { lastValueFrom } from 'rxjs';
import { CertificateModalComponent } from './certificate-modal/certificate-modal.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomSheetCertificationWrapperComponent } from './bottom-sheet-certification-wrapper/bottom-sheet-certification-wrapper.component';
import { DialogCertificationWrapperComponent } from './dialog-certification-wrapper/dialog-certification-wrapper.component';
import { SamiUser } from 'interfaces';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-badge-large',
  templateUrl: './badge-large.component.html',
})
export class BadgeLargeComponent implements OnInit {
  @Input() backgroundColor: 'white' | 'culturedWhite' = 'white';
  @Input() title!: string;
  @Input() type: 'certification' | 'badge' = 'badge';
  @Input() examType: 'student' | 'instructor' | undefined = undefined;
  @Input() history: any[] | undefined = [];
  @Input() level!: number;
  @Input() date!: Date;
  @Input() systemIdentifier?: string | undefined;
  @Input() badgePath?: string | null | undefined;
  @Input() userName: string | undefined = undefined;
  @Input() customer: SamiUser | undefined = undefined;

  lastExam: any;
  loading = false;
  system: any;

  examText: string | undefined = '';

  constructor(
    private aff: AngularFireFunctions,
    private ui: UiService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private bottomSheet: MatBottomSheet,
    private afs: AngularFirestore
  ) {}

  ngOnInit(): void {

    if (
      this.history &&
      this.history.length > 0 &&
      this.examType === 'student'
    ) {
      this.lastExam = this.history.find(
        (element) => element.level === this.level
      );
    }

    if (
      this.history &&
      this.history.length > 0 &&
      this.examType === 'instructor'
    ) {
      this.lastExam = this.history.find(
        (element) => element.exam === this.level
      );
    }

    if (this.systemIdentifier) {
      this.system = this.ui.samiSystems[this.systemIdentifier];
      if (this.examType === 'instructor' && this.ui.systemExamRanks) {
        this.examText = this.ui.systemExamRanks.find((rank: any) =>
          rank.systemIdentiers.includes(this.systemIdentifier)
        )?.ranks[this.level];
      }
    }
  }

  async generateCertificate() {
    try {
      this.loading = true;
      const pdf = await lastValueFrom(
        this.aff.httpsCallable('generateDiploma')({
          name: this.userName,
          systemTitle: this.system.title || this.title,
          rankText:
            this.examType === 'student' ? 'Level ' + this.level : this.examText,
          date:
            this.lastExam && this.lastExam.date
              ? this.lastExam.date
                  .toDate()
                  .toLocaleDateString('de-DE', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })
              : new Date().toLocaleDateString('de-DE', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }),
          level: this.level,
          examType: this.examType,
          systemIdentifier: this.systemIdentifier,
          examiner:
            (this.lastExam &&
              this.lastExam.instructor &&
              this.lastExam.instructor.name) ||
            null,
          center: null,
          category: this.system.category,
        })
      );
      this.loading = false;

      this.dialog.open(
        CertificateModalComponent,
        {
          data: {
            pdf: pdf
          },
          width: '95vw',
          maxWidth: '600px'
        }
      )


    } catch (error) {
      this.loading = false;
      console.error(error);
    }
  }

  viewHistory() {
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe(result => {
        if (result.matches) {
          const bs = this.bottomSheet.open(BottomSheetCertificationWrapperComponent, {
            data: {
              history: this.history,
              title: this.title,
              system: this.system,
              userName: this.userName,
              level: this.examType === 'student' ? this.level : undefined,
              exam: this.examType === 'instructor' ? this.level : undefined,
              examType: this.examType,
            },
            panelClass: 'sami-bottomsheet'
          });

          bs.afterDismissed().subscribe((result: any) => {
            if (result && result.deleteHistoryEntry && this.customer && result.entry && this.examType) {
              this.deleteHistoryEntry(this.customer, result.entry, this.examType);
            }
          });
        } else {
          const d = this.dialog.open(DialogCertificationWrapperComponent, {
            width: '500px',
            maxHeight: '80vh',
            height: '500px',
            // pass any data if needed
            data: {
              history: this.history,
              title: this.title,
              system: this.system,
              userName: this.userName,
              level: this.examType === 'student' ? this.level : undefined,
              exam: this.examType === 'instructor' ? this.level : undefined,
              examType: this.examType,
            }
          });

          d.afterClosed().subscribe((result: any) => {
            if (result && result.deleteHistoryEntry && this.customer && result.entry && this.examType) {
              this.deleteHistoryEntry(this.customer, result.entry, this.examType);
            }
          });
        }
      });
  }

  async deleteHistoryEntry(customer: SamiUser, historyToDelete: any, mode: 'student' | 'instructor'): Promise<void> {
    if (!customer || !historyToDelete || !mode) return;

    try {
      this.loading = true;

      if (mode === 'student') {
        await this.processStudentModeForDeletion(customer, historyToDelete);
      } else if (mode === 'instructor') {
        await this.processInstructorModeForDeletion(customer, historyToDelete);
      }

      this.loading = false;
    } catch (error) {
      console.error('Error deleting history entry:', error);
      this.loading = false;
    }
  }

  async processStudentModeForDeletion(customer: SamiUser, historyToDelete: any) {
    const styleLevels = customer.styleLevels || [];
    const levelIndex = styleLevels.findIndex(l => l.style && l.style.identifier === this.system.identifier);

    if (levelIndex === -1) {
      console.error('Level with the specified identifier not found');
      return;
    }

    // Ensure that the found item is not undefined
    if (levelIndex !== -1 && styleLevels[levelIndex]) {
      const levelItem = styleLevels[levelIndex];

      if (levelItem && levelItem.history) {
        // Safe to proceed since we now know levelItem and levelItem.history are defined
        levelItem.history = levelItem.history.filter(historyItem =>
          historyItem.level !== historyToDelete.level);


        // Further logic...
        if (levelItem.history.length === 0) {
          styleLevels.splice(levelIndex, 1); // Remove entire entry if no history left
        } else {
          const sortedHistory = levelItem.history.sort((a, b) => {
            // Use 0 as the default value for undefined levels
            const levelA = a.level ?? 0;
            const levelB = b.level ?? 0;

            return levelB - levelA; // Sort in descending order
          });

          const highestEntry = sortedHistory[0];
          if (highestEntry) {
            levelItem.level = highestEntry.level;
          } else {
            // Handle the case where there are no history entries after sorting
            levelItem.level = undefined;
          }
        }

        if (customer.uid) {
          console.log(styleLevels);
          await this.updateFirestoreDocument(customer.uid, { styleLevels });
        }
      }
    } else {
      console.error('Level with the specified identifier not found');
    }
  }

  async processInstructorModeForDeletion(customer: SamiUser, historyToDelete: any) {
    const styleExams = customer.styleExams || [];
    const levelIndex = styleExams.findIndex(l => l.style && l.style.identifier === this.system.identifier);

    // Ensure that the found item is not undefined
    if (levelIndex !== -1 && styleExams[levelIndex]) {
      const levelItem = styleExams[levelIndex];

      if (levelItem && levelItem.history) {
        // Safe to proceed since we now know levelItem and levelItem.history are defined
        levelItem.history = levelItem.history.filter(historyItem =>
          historyItem.exam !== historyToDelete.exam);

        // Further logic...
        if (levelItem.history.length === 0) {
          styleExams.splice(levelIndex, 1); // Remove entire entry if no history left
        } else {
          const sortedHistory = levelItem.history.sort((a, b) => {
            // Use 0 as the default value for undefined levels
            const levelA = a.exam ?? 0;
            const levelB = b.exam ?? 0;

            return levelB - levelA; // Sort in descending order
          });

          const highestEntry = sortedHistory[0];
          if (highestEntry) {
            levelItem.exam = highestEntry.exam;
          } else {
            // Handle the case where there are no history entries after sorting
            levelItem.exam = undefined;
          }
        }

        if (customer.uid) {
          console.log(styleExams);
          await this.updateFirestoreDocument(customer.uid, { styleExams });
        }
      }
    } else {
      console.error('Level with the specified identifier not found');
    }
  }

  async updateFirestoreDocument(uid: string, updateData: any) {
    try {
      await this.afs.doc(`users/${uid}`).update(updateData);
    } catch (error) {
      console.error('Firestore update failed:', error);
    }
  }


}
