import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  autoplay$ = new BehaviorSubject<boolean>(true);
  cinemaMode$ = new BehaviorSubject<boolean>(true);
  volume$ = new BehaviorSubject<number>(0.75);

  constructor(
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    if (isPlatformBrowser(this.platformId)) {
      if (window.localStorage) {
        const ls = window.localStorage;
        const [autoplay, cinemaMode, volume] = [
          ls.getItem('autoplay'),
          ls.getItem('cinemaMode'),
          ls.getItem('volume'),
        ];

        if (autoplay) {
          this.autoplay$.next(autoplay === 'true');
        }

        if (cinemaMode) {
          this.cinemaMode$.next(cinemaMode === 'true');
        }

        if (volume) {
          this.volume$.next(Number.parseFloat(volume));
        }

        this.autoplay$
          .pipe(tap((state: boolean) => ls.setItem('autoplay', `${state}`)))
          .subscribe();
        this.cinemaMode$
          .pipe(tap((state: boolean) => ls.setItem('cinemaMode', `${state}`)))
          .subscribe();
        this.volume$
          .pipe(tap((value: number) => ls.setItem('volume', `${value}`)))
          .subscribe();
      }
    }
  }
}
