import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { SamiSeminar, SamiSeminarTicket, SamiStyleModel, SamiTechniqueClip, SamiUser, SamiUserPurchase } from 'interfaces';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class LibraryService {
  constructor(private afs: AngularFirestore, private auth: AuthService) { }

  private _mapClipAccess(clip: SamiTechniqueClip, user: SamiUser | undefined | null, purchases: SamiUserPurchase[]) {
    const hasPurchased = !!purchases.find((purchase: SamiUserPurchase) => purchase.docId === clip._id);
    return {
      ...clip,
      hasPurchased,
      hasAccess:
        clip.isFree
        || user?.['subscriptionDigest']?.styles?.find((s: SamiStyleModel) => s.identifier === clip.style?.identifier)?.access >= (clip.level || 0)
        || hasPurchased
        || false,
    }
  }

  public getClip$(clipId: string): Observable<SamiTechniqueClip> {
    return combineLatest([
      this.afs.doc<any>(`systemsClips/${clipId}`).valueChanges({ idField: '_id' }),
      this.auth.user$,
      this.auth.getUserPurchases$('systemsClips'),
    ]).pipe(
      map(([clip, user, purchases]: [SamiTechniqueClip, SamiUser, SamiUserPurchase[]]) =>
        this._mapClipAccess(clip, user, purchases)
      )
    );
  }

  public getClipForPreparation$(clipId: string, seminarId: string, ticketId: string): Observable<SamiTechniqueClip | null> {
    return combineLatest([
      this.afs.doc<SamiTechniqueClip>(`systemsClips/${clipId}`).valueChanges({ idField: '_id' }),
      this.auth.user$,
      this.afs.doc<SamiSeminar>(`seminars/${seminarId}`).valueChanges({ idField: '_id' }),
      this.afs.doc<SamiSeminarTicket>(`seminars/${seminarId}/seminarTickets/${ticketId}`).valueChanges({ idField: '_id' }),
    ]).pipe(
      map(([clip, user, seminar, ticket]: [SamiTechniqueClip | undefined, SamiUser | undefined, SamiSeminar | undefined, SamiSeminarTicket | undefined]) => {
        console.log(clip, user, seminar, ticket);
        if (!clip || !user || !seminar || !ticket) {
          return {
            ...clip,
            hasAccess: false,
          };
        }

        // Check if the clip is marked as preparation content
        if (!clip.isPreparationContent) {
          return {
            ...clip,
            hasAccess: false,
          };
        }

        // Check if the seminar is not finished
        // Assuming you have a method or property to check if the seminar is finished
        if (seminar.eventEndsAt.toDate() < new Date()) {
          return {
            ...clip,
            hasAccess: false,
          };
        }

        // Check if the user is preparing for the correct level
        // Assuming you have a method or property to get the user's level for the seminar
        const userLevel = this.getUserLevelForSeminar(user, seminar, ticket); // Replace with your actual logic
        console.log(userLevel);
        if (clip.level && clip.level > userLevel) {
          return {
            ...clip,
            hasAccess: false,
          };
        }

        return {
          ...clip,
          hasAccess: true,
        };
      }),
    );
  }

  getUserLevelForSeminar(user: SamiUser, seminar: SamiSeminar, ticket: SamiSeminarTicket): number {
    // Determine if the user is a student or instructor based on the ticket and seminar

    if (seminar.ticketTypes) {
      const ticketType = seminar.ticketTypes.find(t => t.stripePriceId === ticket.ticketStripePriceId);
      const ticketTargetGroup = ticketType ? ticketType.targetGroups : null;
      const userRole = ticketTargetGroup === 'instructors' ? 'instructor' : 'student';

      let examLevel: any;
      if (userRole === 'instructor') {
        // For instructors, use styleExams
        const userStyles = user.styleExams?.filter((se: any) => se.style.identifier === ticket.system);
        if (!userStyles?.length) {
          examLevel = 1;
        } else {
          examLevel = userStyles.map((se: any) => se.exam);
          examLevel = examLevel++;
        }
      } else {
        // For students, use styleLevels
        const userStyles = user.styleLevels?.filter((sl: any) => sl.style.identifier === ticket.system);
        if (!userStyles?.length) {
          examLevel = 1;
        } else {
          examLevel = userStyles.map((se: any) => se.exam);
          examLevel = examLevel++;
        }
      }

      return examLevel;
    } else {
      return 0;
    }
  }

  public getClipByVideoId$(videoId: string): Observable<SamiTechniqueClip> {
    return combineLatest([
      this.afs.collection<any>(`systemsClips`, (ref) => ref.where('videoId', '==', videoId)).valueChanges({ idField: '_id' }),
      this.auth.user$,
      this.auth.getUserPurchases$('systemsClips'),
    ]).pipe(
      map(([clips, user, purchases]: [SamiTechniqueClip[], SamiUser, SamiUserPurchase[]]) =>
        this._mapClipAccess(clips[0], user, purchases)
      )
    );
  }

  public getVideoElement$(clipId: string): Observable<any> {
    return this.afs.doc<any>(`videoLibraryClips/${clipId}`).valueChanges({ idField: '_id' });
  }

  public getSystemsClips$(ref: any): Observable<SamiTechniqueClip[]> {
    return combineLatest([
      this.afs.collection<SamiTechniqueClip>(`systemsClips`, ref).valueChanges({ idField: '_id' }),
      this.auth.user$,
      this.auth.getUserPurchases$('systemsClips'),
    ]).pipe(
      map(([clips, user, purchases]: [SamiTechniqueClip[], SamiUser, SamiUserPurchase[]]) =>
        clips.map((clip: SamiTechniqueClip) => this._mapClipAccess(clip, user, purchases))
      )
    );
  }

  public getConnectedTechniques$(clipId: string): Observable<SamiTechniqueClip[]> {
    return this.afs.collection('systemsClips', (ref) => ref.where('connectedExercises', 'array-contains', clipId)).valueChanges({ idField: '_id' }).pipe(
      map((clips: SamiTechniqueClip[]) =>
        clips.map((clip) => ({
          ...clip,
          connectedExerciseRefs: clip.connectedExerciseRefs
            ? clip.connectedExerciseRefs.filter((c: any) => c.ref.id !== clipId)
            : [],
        }))
      )
    );
  }

  public getSystems$(): Observable<SamiStyleModel[]> {
    return combineLatest([
      this.afs.collection<any>('systemsLibrary').valueChanges({ idField: '_id' }),
      this.auth.user$,
    ]).pipe(
      map(([styles, user]: [SamiStyleModel[], SamiUser]) =>
        styles.map((system: SamiStyleModel) => ({
          ...system,
          hasAccess: user?.['subscriptionDigest']?.styles?.find((s: SamiStyleModel) => s.identifier === system.identifier),
        }))
      )
    );
  }

  public getSystem$(systemId: string): Observable<SamiStyleModel> {
    return this.afs.doc<any>('systemsLibrary/' + systemId).valueChanges({ idField: '_id' });
  }

  public getUserVideoHistory$(): Observable<any[]> {
    return this.auth.authUser$.pipe(
      switchMap((authUser: any) => authUser?.uid
        ? this.afs.collection<any[]>(`users/${authUser.uid}/systemsClipsWatchHistory`, (ref) =>
          ref.orderBy('lastWatched', 'desc').limit(50)
        ).valueChanges({ idField: '_id' })
        : of([])
      )
    );
  }
}
