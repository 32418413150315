import { DocumentReference } from "firebase/firestore";

export interface CombatSystem {
  id: string;
  createdAt: Date;
  title: string;
  category: 'defense' | 'fightingArts' | 'pro';
  identifier: string;
  shortDescription: string;
  downloadIds: string[];
  numberOfLevels: number;
  downloads: {
    ref: DocumentReference;
    id: string;
    title: string;
  }[];
  levelIds: string[];
  levels: {
    ref: DocumentReference;
    id: string;
    level: number;
  }[];
  productIds: string[];
  products: {
    ref: DocumentReference;
    id: string;
    title: string;
  }[];

  faqIds: string[];
  faqs: {
    ref: DocumentReference;
    id: string;
    question: string;
  }[];

  updatedAt?: Date;
}
