import { SamiMediaItem } from './sami-media-item.model';

export interface SamiCenterPost {
  [key: string]: any;
  id?: string;
  name?: string;
  summary?: string;
  languageCode?: string;
  callToAction?: {
    actionType?:
      | 'ACTION_TYPE_UNSPECIFIED'
      | 'BOOK'
      | 'ORDER'
      | 'SHOP'
      | 'LEARN_MORE'
      | 'SIGN_UP'
      | 'CALL';
    url?: string;
  };
  createAt?: Date | any;
  updateAt?: Date | any;
  event?: {
    title?: string;
    schedule?: {
      startDate?: Date | any;
      startTime?: Date | any;
      endDate?: Date | any;
      endTime?: Date | any;
    };
  };
  state?: 'LOCAL_POST_STATE_UNSPECIFIED' | 'LIVE' | 'PROCESSING';
  media?: SamiMediaItem[];
  previewImage?: string;
  searchUrl?: string;
  topicType?:
    | 'LOCAL_POST_TOPIC_TYPE_UNSPECIFIED'
    | 'STANDARD'
    | 'EVENT'
    | 'OFFER'
    | 'ALERT';
  alertType?: 'ALERT_TYPE_UNSPECIFIED' | 'COVID_19';
  offer?: {
    couponCode?: string;
    redeemOnlineUrl?: string;
    termsConditions?: string;
  };
  centerSlug?: string;
  center?: {
    name?: string;
    id?: string;
    slug?: string;
  };
}
