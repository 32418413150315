<div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
  <h1 class="text-3xl sm:text-4xl font-extrabold tracking-tight text-gray-900">
    {{ story?.content?.title }}
  </h1>
  <p class="mt-4 max-w-xl text-base text-gray-700">
    {{ story?.content?.subtitle }}
  </p>
</div>

<section
  class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-12 grid sm:grid-cols-4 sm:gap-12"
>
  <div
    class="sm:col-span-3 prose prose-sm"
    *ngIf="story?.content?.text_area"
    [innerHtml]="storyblokService.resolveRichText(story?.content?.text_area)"
  ></div>
  <div></div>
</section>
