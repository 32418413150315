import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UiService } from '@sami/features';

@Component({
  selector: 'app-track-tile',
  templateUrl: './track-tile.component.html',
})
export class TrackTileComponent implements OnInit {
  @Input() title = 'Knife Fighting Level 1';
  @Input() id = '';
  @Input() progress = 0;
  @Input() showProgress = true;
  @Input() color: string | undefined = undefined;
  @Input() level: number | undefined = undefined;
  @Input() duration: number | undefined = undefined;
  @Input() lessons: number | undefined = undefined;
  @Input() subtitle =
    'Vitae, imperdiet venenatis augue dignissim egestas viverra at libero lacus.';
  @Input() imageUrl?: string;
  @Input() systemIdentifier = 'general';
  @Input() releaseDate: any = null;
  @Input() loading = false;
  @Output() clickEmitter: EventEmitter<any> = new EventEmitter();

  @Input() isAvailable = false;
  today = new Date();

  imageLoad = false;

  constructor(public uiService: UiService) {}

  ngOnInit(): void {
    if (this.releaseDate) {
      this.isAvailable = this.today >= this.releaseDate.toDate();
    }
  }

  returnSystemComponent(
    identifier:
      | 'kravMaga'
      | 'panantukan'
      | 'axe'
      | 'stick'
      | 'knife'
      | 'ebc'
      | 'kapap'
      | 'core'
      | string,
    type: 'title' | 'color' | 'categoryText'
  ) {
    if (this.color) {
      return '[' + this.color + ']';
    } else if (identifier === 'general') {
      return 'richBlack';
    } else {
      return this.uiService.samiSystems[identifier][type] || 'fightingRed-700';
    }
  }
}
