import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { GetDownloadURLPipeModule } from '@angular/fire/compat/storage';
import { UiService } from '@sami/features';
import { SamiCombatSystemTagComponent } from '../combat-system-tag/combat-system-tag.component';
import { CategoryDescriptionComponent } from '../category-description/category-description.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-info-bar',
  standalone: true,
  imports: [CommonModule, GetDownloadURLPipeModule, SamiCombatSystemTagComponent, CategoryDescriptionComponent],
  templateUrl: './info-bar.component.html'
})
export class InfoBarComponent {
  @Input() systemIdentifier: string | undefined = undefined;
  @Input() instructor: { displayName: string; photoURL: string } | undefined;
  @Input() color: 'white' | 'black' = 'white';
  @Input() date: Date | undefined = undefined;
  @Input() trackTitle: string | undefined = undefined;
  @Input() difficulty: string | undefined = undefined;
  @Input() level: number | undefined = 0;
  @Input() examRequired: boolean | undefined = undefined;
  @Input() forInstructors: boolean | undefined = undefined;
  @Input() daysAvailable: number | undefined = undefined;
  @Input() typeOfExercise:
    | 'technique'
    | 'exercise'
    | 'generalInformation'
    | 'theory'
    | undefined = undefined;
  @Input() category: string | undefined = undefined;
  @Input() slCategory: { category: any, subcategory: any, system: any } | undefined = undefined;
  @Input() numberOfParticipants: 'single' | 'partner' | 'theory' | 'group' | undefined =
  undefined;
  @Input() rounds: number | string | undefined = undefined;
  @Input() duration: string | number | undefined = undefined;
  @Input() exercises: string | number | undefined = undefined;
  @Input() equipmentNeeded: boolean | undefined = undefined;

  constructor(public ui: UiService) { }

  returnSystemComponent(
    identifier:
      | 'kravMaga'
      | 'panantukan'
      | 'axe'
      | 'stick'
      | 'knife'
      | 'ebc'
      | 'kapap'
      | 'core'
      | 'boxing'
      | string
      | any,
    type: 'title' | 'color' | 'category'
  ) {
    return this.ui.samiSystems[identifier][type] || 'fightingRed-700';
  }

  isNumber(val: any): boolean {
    return typeof val === 'number';
  }

  getDurationInMinutes(duration: number | string) {
    if (typeof duration === 'number') {
      return (duration / 60).toFixed(2);
    } else {
      return duration;
    }
  }
}
