import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttendanceButtonsComponent } from './attendance-buttons.component';
import { RouterModule } from '@angular/router';
import { NotificationComponent } from '../notification/notification.component';
import { CountdownModule } from 'ngx-countdown';
@NgModule({
  declarations: [AttendanceButtonsComponent],
  imports: [CommonModule, RouterModule, NotificationComponent, CountdownModule],
  exports: [AttendanceButtonsComponent],
})
export class AttendanceButtonsModule {}
