import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StudentCenterModalComponent } from './student-center-modal.component';
import { SamiSelectComponent } from '../../input-fields/select/select.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SamiButtonComponent } from '../../button/button.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [
    StudentCenterModalComponent
  ],
  imports: [
    CommonModule,
    SamiSelectComponent,
    ReactiveFormsModule,
    SamiButtonComponent,
    MatAutocompleteModule
  ],
  exports: [StudentCenterModalComponent]
})
export class StudentCenterModalModule {}
