<button
  *ngIf="instructor"
  [routerLink]="'/en/instructors/' + id"
  [ngClass]="background === 'white' ? 'bg-white' : 'bg-culturedWhite'"
  class="flex group hover:shadow-lg flex-col justify-start w-full h-full space-y-2"
>
  <div
    *ngIf="displayImage"
    class="flex flex-col relative justify-center py-2 h-28 overflow-hidden items-center bg-richBlack w-full"
  >
    <picture>
      <source
        type="image/webp"
        [srcset]="
          'https://a.storyblok.com/f/110844/3000x2250/710a782cf3/p1455345.jpg'
            | transformImg
              : 'storyblok'
              : 'fit-in/200x0/smart/filters:format(webp)'
        "
      />
      <source
        type="image/png"
        [srcset]="
          'https://a.storyblok.com/f/110844/3000x2250/710a782cf3/p1455345.jpg'
            | transformImg
              : 'storyblok'
              : 'fit-in/200x0/smart/filters:format(png)'
        "
      />
      <img
        loading="lazy"
        class="w-full z-0 absolute h-full inset-0 object-cover"
        [src]="
          'https://a.storyblok.com/f/110844/3000x2250/710a782cf3/p1455345.jpg'
            | transformImg : 'storyblok' : 'fit-in/200x0/smart'
        "
        [alt]="'Background Image'"
      />
    </picture>
    <div class="w-full h-full inset-0 absolute bg-richBlack/90 z-10"></div>

    <div class="mx-auto h-24 w-24 relative z-20">
      <ng-container *ngIf="instructor.profileImagePath; else noPublicProfile">
        <div class="aspect-w-1 aspect-h-1 overflow-hidden">
          <picture>
            <source
              type="image/webp"
              [srcset]="
                instructor.profileImagePath
                  | getDownloadURL
                  | transformImg
                    : 'firestore'
                    : 'fit-in/200x0/smart/filters:format(webp)'
              "
            />
            <source
              type="image/png"
              [srcset]="
                instructor.profileImagePath
                  | getDownloadURL
                  | transformImg
                    : 'firestore'
                    : 'fit-in/200x0/smart/filters:format(png)'
              "
            />
            <img
              loading="lazy"
              class="inline-block w-full h-full object-center rounded-full object-cover"
              [src]="
                instructor.profileImagePath
                  | getDownloadURL
                  | transformImg : 'firestore' : 'fit-in/200x0/smart'
              "
              [alt]="instructor.displayName"
            />
          </picture>
        </div>
      </ng-container>
      <ng-template #noPublicProfile>
        <ng-container *ngIf="instructor.photoURL; else noPhoto">
          <ng-container
            *ngIf="instructor.photoURL | startsWith : 'http'; else stor"
          >
            <img
              class="mx-auto h-24 w-24 rounded-full object-cover"
              [src]="instructor.photoURL"
              alt=""
            />
          </ng-container>
          <ng-template #stor>
            <ng-container
              *ngIf="
                instructor.photoURL &&
                  (instructor.photoURL | stor | async) as userURL;
                else noPhoto
              "
            >
              <img
                class="mx-auto h-24 w-24 rounded-full object-cover"
                [src]="userURL"
                alt=""
              />
            </ng-container>
          </ng-template>
        </ng-container>
        <ng-template #noPhoto>
          <span
            class="inline-flex items-center justify-center h-24 w-24 rounded-full bg-white"
          >
            <span class="font-medium leading-none text-richBlack">{{
              instructor.displayName | slice : 0 : 1
            }}</span>
          </span>
        </ng-template>
      </ng-template>
    </div>
  </div>
  <div class="relative pt-2 pb-5 px-5 w-full space-y-4">
    <div class="flex flex-row justify-between items-center w-full">
      <h3
        class="text-xl text-left font-semibold text-richBlack"
        *ngIf="instructor.displayName"
      >
        {{ instructor.displayName }}
      </h3>
      <h3
        class="text-xl text-left font-semibold text-richBlack"
        *ngIf="
          instructor.firstName && instructor.lastName && !instructor.displayName
        "
      >
        {{ instructor.firstName }} {{ instructor.lastName }}
      </h3>
      <svg class="w-5 shrink-0 text-cadetGrey-300 group-hover:text-richBlack" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7071 0.959595L23.7071 6.95959C24.0976 7.35012 24.0976 7.98328 23.7071 8.37381L17.7071 14.3738L16.2929 12.9596L21.5858 7.6667L16.2929 2.37381L17.7071 0.959595Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23 8.6667H0V6.6667H23V8.6667Z" fill="currentColor"/>
      </svg>
    </div>
    <div
      class="flex flex-row w-full justify-start items-start space-x-2"
      *ngIf="displayAddress && instructor.address"
    >
      <div class="w-5 flex-shrink-0 pt-1">
        <svg
          class="w-5"
          viewBox="0 0 18 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.19721 18.25H12.6972M13.8222 6.57175C13.8222 9.23425 9.00046 15.25 9.00046 15.25C9.00046 15.25 4.17871 9.23425 4.17871 6.57175C4.17871 5.93855 4.30343 5.31155 4.54574 4.72655C4.78806 4.14154 5.14323 3.61 5.59097 3.16226C6.03871 2.71452 6.57026 2.35935 7.15526 2.11703C7.74026 1.87472 8.36726 1.75 9.00046 1.75C9.63366 1.75 10.2607 1.87472 10.8457 2.11703C11.4307 2.35935 11.9622 2.71452 12.41 3.16226C12.8577 3.61 13.2129 4.14154 13.4552 4.72655C13.6975 5.31155 13.8222 5.93855 13.8222 6.57175V6.57175Z"
            stroke="black"
            stroke-width="2"
            stroke-linejoin="round"
          />
          <path
            d="M9.00052 8.50005C10.0655 8.50005 10.9288 7.63675 10.9288 6.5718C10.9288 5.50686 10.0655 4.64355 9.00052 4.64355C7.93557 4.64355 7.07227 5.50686 7.07227 6.5718C7.07227 7.63675 7.93557 8.50005 9.00052 8.50005Z"
            fill="black"
          />
        </svg>
      </div>
      <div
        class="flex-1 text-sm flex flex-col justify-start items-start text-richBlack text-left font-body"
      >
        <span class="font-semibold text-[16px] font-sans" *ngIf="instructor.center?.name">
          {{ instructor.center?.name }}
        </span>
        <span class="font-body font-normal">
          {{ instructor.address }}
        </span>
      </div>
    </div>
    <div *ngIf="displayRanks" class="flex flex-row w-full justify-start items-start space-x-2">
      <div class="w-5 flex-shrink-0 pt-1">
        <svg
          class="w-5"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_3979_30879)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 0.75C0 0.335786 0.335786 0 0.75 0H14.25C14.6642 0 15 0.335786 15 0.75V4.5H13.5V1.5H1.5V10.5H9V12H0.75C0.335786 12 0 11.6642 0 11.25V0.75Z"
              fill="black"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.5 9.75C10.5 7.67896 12.179 6 14.25 6C16.321 6 18 7.67896 18 9.75C18 11.821 16.321 13.5 14.25 13.5C12.179 13.5 10.5 11.821 10.5 9.75ZM14.25 7.5C13.0074 7.5 12 8.50739 12 9.75C12 10.9926 13.0074 12 14.25 12C15.4926 12 16.5 10.9926 16.5 9.75C16.5 8.50739 15.4926 7.5 14.25 7.5Z"
              fill="black"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.5 11.7343C15.9503 12.3571 15.146 12.75 14.25 12.75C13.354 12.75 12.5497 12.3571 12 11.7343V18L14.25 16.5L16.5 18V11.7343Z"
              fill="black"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 4.5H3V3H12V4.5Z"
              fill="black"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.5 7.5H4.5V6H7.5V7.5Z"
              fill="black"
            />
          </g>
        </svg>
      </div>
      <div
        class="flex-1 text-sm flex flex-col justify-start items-start text-richBlack text-left font-body"
      >
        <span class="font-semibold text-[16px] font-sans"> Instructor Diplomas </span>
        <span class="font-normal" *ngFor="let exam of instructor?.styleExams">
          <ng-container *ngIf="exam && exam.style && exam.style.identifier">
            {{ ui.samiSystems[exam.style.identifier].title }}
          </ng-container>
        </span>
      </div>
    </div>
  </div>
</button>
