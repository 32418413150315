<ng-container *ngIf="!loading; else showLoading">
  <ng-container *ngIf="{ clip: clip$ | async, history: history$ | async, isMobile: ui.isMobileLayout$ | async } as vm">
    <div
      [id]="id"
      *ngIf="vm.clip; else showLoading"
      [ngClass]="{
        'flex-col': layout === 'column',
        'md:flex-col flex flex-row': layout !== 'column',
        'bg-white': cardBackground === 'white',
        'bg-culturedWhite': cardBackground !== 'white'
      }"
      class="group hover:shadow-lg space-x-1 md:space-x-0 justify-start"
    >
      <div
        [ngClass]="layout === 'column' ? 'md:min-h-[170px] w-auto' : 'w-40 md:w-auto'"
        class="relative z-10">
        <app-manage-collection-button
          *ngIf="user && showManageCollectionButton"
          [clipId]="id"
          [type]="'icon'"
          class="absolute top-2 right-2 group-hover:flex hidden flex-col justify-center items-center"
        ></app-manage-collection-button>

        <div
          *ngIf="vm.clip && vm.clip?.isExamRequired && !(vm.clip && vm.clip?.isInstructorExamRequired)"
          class="absolute text-xs md:text-sm top-1 md:top-2 left-1 md:left-2 py-1 px-2 bg-richBlack text-culturedWhite z-10 flex flex-col font-semibold justify-center items-center"
        >
          <span class="cursor-default uppercase font-sans">exam</span>
        </div>

        <div
          *ngIf="vm.clip && vm.clip?.isInstructorExamRequired"
          class="absolute text-xs md:text-sm top-1 md:top-2 left-1 md:left-2 py-1 px-2 bg-richBlack text-culturedWhite z-10 flex flex-col font-semibold justify-center items-center"
        >
          <span class="cursor-default uppercase font-sans">exam</span>
        </div>

        <div *ngIf="status === 'new'" class="bottom-1 md:bottom-2 z-10 absolute left-1 md:left-2 px-2.5 py-px bg-statusColors-lightGreen rounded-xl justify-center items-center flex">
          <div class="text-center text-statusColors-darkGreen text-xs font-body uppercase leading-tight">NEW</div>
        </div>

        <div *ngIf="status === 'updated'" class="bottom-1 md:bottom-2 z-10 absolute left-1 md:left-2 px-2.5 py-px bg-statusColors-lightYellow rounded-xl justify-center items-center flex">
          <div class="text-center text-statusColors-darkYellow text-xs font-body uppercase leading-tight">UPDATED</div>
        </div>

        <div class="absolute md:hidden left-1 z-50 bottom-1" *ngIf="!vm.clip.hasPurchased || vm.clip.hasPurchased">
          <sami-membership-badge [isFree]="vm.clip.isFree || false" *ngIf="!vm.clip.hasPurchased"></sami-membership-badge>
          <sami-purchased-badge *ngIf="vm.clip.hasPurchased"></sami-purchased-badge>
        </div>

        <div
          *ngIf="(vm.clip && vm.clip?.isInstructorExamRequired) || (vm.clip && vm.clip.isInstructorContent)"
          class="absolute text-xs md:text-sm md:top-2 md:right-2 top-1 right-1 py-1 px-2 bg-richBlack text-culturedWhite z-10 flex flex-col font-semibold justify-center items-center"
        >
          <span class="cursor-default uppercase font-sans">instructor content</span>
        </div>
        <div
          *ngIf="vm.clip?.durationInSeconds && vm.clip?.hasAccess"
          class="absolute text-xs md:text-sm bottom-1 md:bottom-2 right-1 md:right-2 py-1 px-2 bg-richBlack text-culturedWhite z-10 flex flex-col font-semibold justify-center items-center"
        >
          <span class="cursor-default font-sans">{{
            vm.clip?.durationInSeconds * 1000 | date: 'mm:ss'
          }}</span>
        </div>
        <div
          class="h-full object-cover w-full md:min-h-[170px] aspect-w-16 aspect-h-9 cursor-pointer"
          (click)="clickEmitter.emit(id)"
        >
          <ng-container *ngIf="vm.clip && vm.clip?.muxVideoElement; else image">
            <div
              *ngIf="vm.clip?.muxVideoElement?.asset?.playback_ids[0]?.id"
              class="group"
            >
              <img
                *ngIf="!vm.isMobile"
                [src]="
                  'https://image.mux.com/' +
                  vm.clip?.muxVideoElement?.asset?.playback_ids[0]?.id +
                  '/animated.gif?width=400&fps=5&start=0'
                "
                [alt]="vm.clip.title"
                class="hidden group-hover:block object-cover w-full h-full absolute inset-0"
              />
              <img
                [src]="vm.isMobile ?
                  'https://image.mux.com/' +
                  vm.clip?.muxVideoElement?.asset?.playback_ids[0]?.id +
                  '/thumbnail.png?width=213&height=120&fit_mode=smartcrop'
                :
                  'https://image.mux.com/' +
                  vm.clip?.muxVideoElement?.asset?.playback_ids[0]?.id +
                  '/thumbnail.png?width=400&height=226&fit_mode=smartcrop'
                "
                [alt]="vm.clip.title"
                [ngClass]="vm.isMobile ? 'block group-hover:hidden object-cover w-full h-full absolute inset-0' : 'w-full h-full'"
                class=""
              />
            </div>
          </ng-container>

          <ng-template #image>
            <ng-container *ngIf="vm.clip?.thumbnails?.small; else noImage">
              <img [src]="vm.clip?.thumbnails?.small" [alt]="vm.clip?.title" class="h-full md:min-h-[170px] object-cover w-full" />
            </ng-container>
            <ng-template #noImage>
              <div
                class="w-full h-full bg-richBlack text-white flex flex-col items-center justify-center"
              >
                <svg
                  class="md:w-16 md:h-16 h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 48 48"
                >
                  <g transform="matrix(2,0,0,2,0,0)">
                    <path
                      fill="currentColor"
                      d="M4 6H2V19C2 19.5304 2.21071 20.0391 2.58579 20.4142C2.96086 20.7893 3.46957 21 4 21H19V19H4V6Z"
                    ></path>
                    <path
                      fill="currentColor"
                      d="M20 6H15L13.35 3.8C13.1633 3.55201 12.9217 3.35069 12.6441 3.21188C12.3664 3.07307 12.0604 3.00054 11.75 3H8C7.46957 3 6.96086 3.21071 6.58579 3.58579C6.21071 3.96086 6 4.46957 6 5V15C6 15.5304 6.21071 16.0391 6.58579 16.4142C6.96086 16.7893 7.46957 17 8 17H20C20.5304 17 21.0391 16.7893 21.4142 16.4142C21.7893 16.0391 22 15.5304 22 15V8C22 7.46957 21.7893 6.96086 21.4142 6.58579C21.0391 6.21071 20.5304 6 20 6V6ZM8 15V5H11.75L13.4 7.2C13.5867 7.44799 13.8283 7.64931 14.1059 7.78812C14.3836 7.92693 14.6896 7.99946 15 8H20V15H8Z"
                    ></path>
                  </g>
                </svg>
              </div>
            </ng-template>
          </ng-template>


          <div
            *ngIf="vm.history && vm.history?.percent"
            class="h-full w-full absolute inset-0 overflow-x-hidden flex flex-col justify-end"
          >
            <div
              class="w-full h-1 bg-red-600 absolute bottom-0 left-0 opacity-25 z-0"
            ></div>
            <div
              [style.width.%]="vm.history?.percent * 100"
              class="h-1 bg-red-600 absolute bottom-0 left-0 z-10"
            ></div>
          </div>
        </div>

        <ng-container *ngIf="!vm.clip.hasPurchased && vm.clip.showLock">
          <button
            (click)="clickEmitter.emit(id)"
            class="absolute inset-0 bg-richBlack/90 text-culturedWhite flex flex-col justify-center items-center space-y-6"
          >
            <svg
              class="md:w-10 md:h-10 h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linejoin="round"
                stroke-width="2"
                d="M20 11H4V23H20V11Z"
              ></path>
              <path
                stroke="currentColor"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 11V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V11"
              ></path>
              <path
                fill="currentColor"
                d="M12 19C13.1046 19 14 18.1046 14 17C14 15.8954 13.1046 15 12 15C10.8954 15 10 15.8954 10 17C10 18.1046 10.8954 19 12 19Z"
              ></path>
            </svg>
            <div class="md:flex hidden flex-col justify-center items-center font-body space-y-2">
              <span class="text-cadetGrey-500 w-52 text-xs">
                Level 7+ content becomes available once you reach level 6.
              </span>
              <a
                href="https://help.sami-x.com/en/articles/6549768-why-can-t-i-access-level-7-content"
                target="_blank"
                class="text-cadetGrey-500 hover:text-cadetGrey-200 text-xs underline"
                >More information</a
              >
            </div>
          </button>
        </ng-container>
      </div>
      <div
        [ngClass]="cardBackground === 'white' ? 'bg-white' : 'bg-culturedWhite'"
        class="h-full md:h-auto flex-1 md:py-5 md:px-4 p-1.5 cursor-pointer flex flex-col justify-start md:justify-between space-y-3"
        (click)="clickEmitter.emit(id)"
      >
        <div class="flex-row justify-start hidden md:flex" *ngIf="!vm.clip.hasPurchased || vm.clip.hasPurchased">
          <sami-membership-badge [isFree]="vm.clip.isFree || false" *ngIf="!vm.clip.hasPurchased"></sami-membership-badge>
          <sami-purchased-badge *ngIf="vm.clip.hasPurchased"></sami-purchased-badge>
        </div>
        <div class="flex flex-col justify-start space-y-1 flex-1">
          <span
            class="text-base capitalize leading-none  cursor-pointer md:capitalize font-sans line-clamp-2 md:text-lg md:leading-tight font-semibold text-richBlack"
            *ngIf="vm.clip?.title"
            >
            {{ vm.clip?.title.toLowerCase() }}
          </span>
        </div>
        <div class="flex flex-row font-sans space-x-2 relative">
          <sami-combat-system-tag [displaySAMIText]="false" [combatSystem]="vm.clip?.style?.identifier" *ngIf="vm.clip?.style?.identifier"></sami-combat-system-tag>
          <span
            [ngClass]="cardBackground === 'white' ? 'bg-cadetGrey-100' : 'bg-cadetGrey-200'"
            class="text-xs py-1 px-2 text-cadetGrey-700 cursor-default"
          >
            {{ 'Level ' + vm.clip?.level }}
          </span>
          <span
            *ngIf="vm.clip?.typeOfClip"
            [ngClass]="cardBackground === 'white' ? 'bg-cadetGrey-100' : 'bg-cadetGrey-200'"
            class="text-xs md:block hidden capitalize py-1 px-2 text-cadetGrey-700 cursor-default"
          >
            {{ vm.clip?.typeOfClip }}
          </span>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #showLoading>
  <div class="w-full bg-white flex-col justify-start items-start inline-flex">
    <div class="self-stretch aspect-w-16 aspect-h-9 animate-pulse bg-cadetGrey-200"></div>
      <div class="self-stretch h-28 p-4 flex-col justify-between items-start gap-4 flex">
          <div class="self-stretch h-12 flex-col justify-start items-start gap-2 flex">
              <div class="self-stretch h-5 flex-col justify-start items-start gap-4 flex">
                  <div class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-2 flex">
                      <div class="self-stretch grow shrink basis-0 animate-pulse bg-cadetGrey-200"></div>
                  </div>
              </div>
              <div class="self-stretch h-5 flex-col justify-start items-start gap-2 flex">
                  <div class="w-40 h-5 bg-cadetGrey-200 animate-pulse"></div>
              </div>
          </div>
          <div class="self-stretch justify-start items-start gap-3 inline-flex">
              <div class="w-14 h-5 bg-cadetGrey-200 animate-pulse"></div>
              <div class="w-14 h-5 bg-cadetGrey-200 animate-pulse"></div>
              <div class="w-14 h-5 bg-cadetGrey-200 animate-pulse"></div>
          </div>
      </div>
  </div>
</ng-template>