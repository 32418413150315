<div class="absolute inset-0 h-full sm:h-auto object-contain bg-richBlack">
  <img
    class="object-cover inset-0 object-center absolute w-full h-full"
    [src]="'https://image.mux.com/' + playbackId + '/thumbnail.png'"
  />
  <mux-video
    id="mux-player"
    #muxPlayer
    class="object-cover h-full w-full z-10 relative"
    autoplay="muted"
    loop
    muted
    playsinline
    preload="auto"
  ></mux-video>
</div>
