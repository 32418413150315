import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'sami-instructor-progress-card',
  templateUrl: './instructor-progress-card.component.html',
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class InstructorProgressCardComponent {

}
