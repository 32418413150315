import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AuthService, UiService } from '@sami/features';
import { SamiUser } from 'interfaces';
import { Observable, combineLatest, map } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-membership-badge',
  templateUrl: './membership-badge.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class MembershipBadgeComponent {
  @Input() isFree = false;
  @Input() onlyShowIsFree = false;
  @Input() isAdminSite = false;
  displayBadge$: Observable<boolean>;

  constructor(
    private auth: AuthService,
    private ui: UiService,
  ) {
    this.displayBadge$ = combineLatest([
      this.auth.user$,
      this.ui.currentSite$,
    ]).pipe(
      map(([user, currentSite]: [SamiUser | null | undefined, string | null]) =>
        (currentSite === 'sami-x-app') && !user?.subscriptionDigest?.hasSubscription?.plus
      ),
    )
  }
}
