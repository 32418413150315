import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UiService } from '@sami/features';
import { Track } from 'interfaces';


@Component({
  selector: 'app-exam-card',
  templateUrl: './exam-card.component.html',
})
export class ExamCardComponent implements OnInit {
  @Input() exam: any | null = null;
  @Input() track: Track | undefined = undefined;
  @Input() system: string | undefined;
  @Input() isAttendee = false;
  @Output() clicked = new EventEmitter();
  isAvailable = false;

  defaultImage: any = {
    knife:
      'https://a.storyblok.com/f/110844/2100x700/43a4e301dd/p1433924-neu-2.png',
    stick:
      'https://a.storyblok.com/f/110844/2100x700/f0ebf07adf/p1433746-neu-2.png',
    axe: 'https://a.storyblok.com/f/110844/2100x700/b959279965/p1433766-neu-2.png',
    panantukan:
      'https://a.storyblok.com/f/110844/2100x700/1c3d7f9224/p1433940-neu-2.png',
    kravMaga:
      'https://a.storyblok.com/f/110844/2100x700/735b599b2a/p1444036-neu-2.png',
    kapap: 'https://a.storyblok.com/f/110844/2000x1000/456c35052f/samixpro.png',
    ebc: 'https://a.storyblok.com/f/110844/2000x1000/5a23802f19/samixebc.png',
    sds: 'https://a.storyblok.com/f/110844/2000x1000/b21a7329ea/sds.png',
    defender: 'https://a.storyblok.com/f/110844/650x495/2a347e804f/sami-defender-course-previewimage.jpg',
    karambit: 'https://a.storyblok.com/f/110844/6000x4000/a3696dc428/karambit.jpeg'
  };

  constructor(private uiService: UiService) {}

  ngOnInit(): void {

  }

  goToExam() {
    this.clicked.emit(this.isAvailable);
  }
}
