<button
  (click)="continueCurrentTrack()"
  class="grid text-left sm:grid-cols-3 w-full hover:shadow-lg relative"
  [ngClass]="getTheme(trackCard)"
>
  <div class="relative w-full md:h-56 overflow-hidden h-40">
    <div class="absolute inset-0 w-full h-full aspect-w-16 aspect-h-9">
      <img
        *ngIf="trackCard && trackCard.horizontalThumbnail"
        [src]="trackCard.horizontalThumbnail"
        [alt]="trackCard.title"
        class="object-cover object-center w-full"
      />
    </div>
  </div>
  <div
    class="sm:col-span-2 sm:flex sm:flex-col h-full sm:justify-center"
  >
    <div class="flex flex-col px-4 py-8 justify-between flex-1 space-y-4 sm:space-y-6">
      <div
        class="flex flex-col justify-start xl:justify-center flex-1 space-y-4"
      >
        <div class="flex flex-col justify-start space-y-1">
          <span class="text-zinc-100 uppercase text-opacity-40 text-xs font-semibold leading-none">Your {{ type === 'current' ? 'current' : 'last' }} Track</span>
          <h2 class="text-culturedWhite text-2xl font-semibold uppercase leading-7">{{ trackCard?.title }}</h2>
        </div>

        <p class="text-culturedWhite
        text-sm
        font-body
        line-clamp-2
        leading-tight">
          {{ trackCard?.excerpt }}
        </p>
      </div>
    </div>

    <div
        *ngIf="userProgress && userProgress.progress"
        class="px-4 py-2 flex flex-row bg-richBlack/40 justify-start items-center space-x-1.5"
      >
        <span class="text-sm text-white">
          {{ (userProgress.progress * 100 | number : '1.0-0') + '%' }}
        </span>
        <div class="w-full bg-richBlack/70 rounded-none h-2">
          <div
            class="bg-culturedWhite h-2 rounded-none"
            [ngStyle]="{ width: userProgress.progress * 100 + '%' }"
          ></div>
        </div>
    </div>
  </div>

  <div
    class="hidden sm:block absolute right-0 top-0 bottom-0 h-full pointer-events-none"
  >
    <picture>
      <source
        type="image/webp"
        [srcset]="
          'https://a.storyblok.com/f/110844/335x159/45198e257f/x.png'
            | transformImg
              : 'storyblok'
              : 'fit-in/0x800/smart/filters:format(webp)'
        "
      />
      <source
        type="image/png"
        [srcset]="
          'https://a.storyblok.com/f/110844/335x159/45198e257f/x.png'
            | transformImg
              : 'storyblok'
              : 'fit-in/0x800/smart/filters:format(png)'
        "
      />
      <img
        loading="lazy"
        class="h-full"
        [src]="
          'https://a.storyblok.com/f/110844/335x159/45198e257f/x.png'
            | transformImg: 'storyblok':'fit-in/0x800/smart'
        "
        [alt]="'Onboarding Step'"
      />
    </picture>
  </div>
</button>
