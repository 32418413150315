<div class="flex flex-row justify-start bg-white relative">
  <div
    class="w-8 flex flex-row justify-center items-start pt-1 mr-3 text-richBlack"
  >
    <svg
      class="w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 48 48"
    >
      <g transform="matrix(2,0,0,2,0,0)">
        <path
          fill="currentColor"
          d="M20.566 5L8.765 16.6L3.238 11.13L1.832 12.552L8.059 18.716C8.24615 18.9007 8.49853 19.0043 8.7615 19.0043C9.02447 19.0043 9.27685 18.9007 9.464 18.716L21.964 6.424L20.566 5Z"
        ></path>
      </g>
    </svg>
  </div>
  <div class="flex flex-col justify-start items-start space-y-0">
    <span class="text-base font-semibold tracking-tight text-richBlack">
      {{ title }}
    </span>
    <span class="text-sm font-body text-cadetGrey-600">
      {{ subtitle }}
    </span>
  </div>

  <button (click)="closeSnackBar()" class="absolute -top-2 -right-2">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L10 8.58579L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L11.4142 10L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L10 11.4142L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L8.58579 10L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z"
        fill="#9CA3AF"
      />
    </svg>
  </button>
</div>
