<div class="flex flex-col justify-start space-y-2 md:space-y-0 md:flex-row md:justify-between md:items-center w-full">
  <div class="flex flex-col">
    <span class="text-lg font-semibold font-sans text-richBlack">
      {{ class.title }}
    </span>
    <span class="font-body text-sm text-cadetGrey-400">
      available for {{ availableForTicketTypes }}
    </span>
  </div>
  <div class="flex flex-row justify-start items-center md:justify-center md:items-end space-x-4 font-sans font-semibold uppercase text-base text-richBlack">
    <div class="flex flex-row justify-center items-center space-x-2">
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_7219_45490)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 4.25C0.5 3.83579 0.835786 3.5 1.25 3.5H17.75C18.1642 3.5 18.5 3.83579 18.5 4.25V17.75C18.5 18.1642 18.1642 18.5 17.75 18.5H1.25C0.835786 18.5 0.5 18.1642 0.5 17.75V4.25ZM2 5V17H17V5H2Z" fill="#091F2C"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 0.5V4.25H3.5V0.5H5Z" fill="#091F2C"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 0.5V4.25H14V0.5H15.5Z" fill="#091F2C"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.75 8.75H1.25V7.25H17.75V8.75Z" fill="#091F2C"/>
        </g>
        <defs>
        <clipPath id="clip0_7219_45490">
        <rect width="18" height="18" fill="white" transform="translate(0.5 0.5)"/>
        </clipPath>
        </defs>
      </svg>
      <span *ngIf="class && class.start">
        {{ class.start.toDate() | date : 'dd.MM.yyyy' }}
      </span>
    </div>

    <div class="flex flex-row justify-center items-center space-x-2">
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 9.5C0.5 4.52944 4.52944 0.5 9.5 0.5C14.4705 0.5 18.5 4.52944 18.5 9.5C18.5 14.4705 14.4705 18.5 9.5 18.5C4.52944 18.5 0.5 14.4705 0.5 9.5ZM9.5 2C5.35787 2 2 5.35787 2 9.5C2 13.6421 5.35787 17 9.5 17C13.6421 17 17 13.6421 17 9.5C17 5.35787 13.6421 2 9.5 2ZM10.25 8.75V4.91678H8.75V9.5C8.75 9.91421 9.08579 10.25 9.5 10.25H13.1667V8.75H10.25Z" fill="#091F2C"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 9.5C0.5 4.52944 4.52944 0.5 9.5 0.5C14.4705 0.5 18.5 4.52944 18.5 9.5C18.5 14.4705 14.4705 18.5 9.5 18.5C4.52944 18.5 0.5 14.4705 0.5 9.5ZM9.5 2C5.35787 2 2 5.35787 2 9.5C2 13.6421 5.35787 17 9.5 17C13.6421 17 17 13.6421 17 9.5C17 5.35787 13.6421 2 9.5 2Z" fill="#091F2C"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.25 8.75V4.91678H8.75V9.5C8.75 9.91421 9.08579 10.25 9.5 10.25H13.1667V8.75H10.25Z" fill="#091F2C"/>
      </svg>
      <span *ngIf="class && class.end && class.start">
        {{ class.start.toDate() | date : 'HH:mm' }} - {{ class.end.toDate() | date : 'HH:mm' }}
      </span>
    </div>
  </div>
</div>