import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IcsService {
  ics: any;

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  buildICS(uid: any, start: any, end: any, summary: any, location: any) {
    this.ics = [
      `BEGIN:VCALENDAR`,
      `VERSION:2.0`,
      `PRODID:-//hacksw/handcal//NONSGML v1.0//EN`,
      `BEGIN:VEVENT`,
      `UID:${uid}`,
      `DTSTART:${this._formatDate(start)}`,
      `DTEND:${this._formatDate(end)}`,
      `SUMMARY:${summary}`,
      `LOCATION:${location}`,
      `END:VEVENT`,
      `END:VCALENDAR`,
    ].join('\n');

    this.download(summary);
  }

  download(filename = 'event') {
    if (isPlatformBrowser(this.platformId)) {
      const blob = new Blob([this.ics], { type: 'octet/stream' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${filename}.ics`;
      document.body.appendChild(a);

      a.click();

      URL.revokeObjectURL(url);
      this._flush();
    }
  }

  private _flush() {
    this.ics = null;
  }

  private _formatDate(d: Date): string {
    const pre =
      d.getFullYear().toString() +
      ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1).toString() : (d.getMonth() + 1).toString()) +
      ((d.getDate() + 1) < 10 ? "0" + d.getDate().toString() : d.getDate().toString());

    const post = (d.getHours() % 12).toString() + d.getMinutes().toString() + "00";

    return `${pre}T${post}`;
  }
}
