import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  MatDialogRef as MatDialogRef,
  MAT_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { QRCodeModule } from 'angularx-qrcode';
import { ClipboardModule } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  standalone: true,
  imports: [
    CommonModule,
    QRCodeModule,
    ClipboardModule
  ]
})
export class PaymentModalComponent {
  loading = false;

  constructor(
    private dialogRef: MatDialogRef<PaymentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private aff: AngularFireFunctions
  ) {}

  close() {
    this.dialogRef.close();
  }

  async sendNotification() {
    this.loading = true;

    try {
      const d = {
        customerId: this.data.customerId,
        checkout: this.data,
      };

      const res = await lastValueFrom(
        this.aff.httpsCallable('sendNotificationWithPaymentInformation')(d)
      );

      this.loading = false;
    } catch (error) {
      console.error(error);
      this.loading = false;
    }
  }
}
