import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UiService } from '@sami/features';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-combat-system-tag',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './combat-system-tag.component.html'
})
export class SamiCombatSystemTagComponent {
  @Input() combatSystem: string | undefined;
  @Input() category: string | undefined;
  @Input() customLabel: string | undefined;
  @Input() displaySAMIText = true;

  constructor(private ui: UiService) {}

  getClasses() {
    if (this.combatSystem && !this.category) {
      return this.getColorComposition(this.ui.samiSystems[this.combatSystem]?.category);
    } else if (!this.combatSystem && this.category) {
      return this.getColorComposition(this.category);
    } else {
      return this.getColorComposition();
    }
  }

  getColorComposition(category?: string) {
    if (category === 'defense') {
      return 'bg-defensiveGreen-600 text-culturedWhite';
    } else if (category === 'fightingArt') {
      return 'bg-fightingRed-700 text-culturedWhite';
    } else if (category === 'pro') {
      return 'bg-professionalYellow-300 text-richBlack';
    } else {
      return 'text-richBlack bg-cadetGrey-100';
    }
  }

  getLabel() {
    if (this.combatSystem && !this.category) {
      if (this.displaySAMIText) {
        return this.ui.samiSystems[this.combatSystem].title;
      } else {
        const title = this.ui.samiSystems[this.combatSystem].title;
        return title.replace('SAMI-X ', '');
      }
    } else if (!this.combatSystem && this.category) {
      if (this.displaySAMIText) {
        return this.ui.samiCategories[this.category].title;
      } else {
        const title = this.ui.samiCategories[this.category].title;
        return title.replace('SAMI-X ', '');
      }
    } else {
      return '';
    }
  }
}
