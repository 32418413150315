<label class="block text-base font-semibold text-richBlack" [for]="fieldName">
  <span class="is-required">
    {{ label }}
  </span>
  <sami-field-errors [formField]="formField"></sami-field-errors>
</label>
<div class="mt-1">
  <div>
    <input
      class="block w-full font-body rounded-none border-cadetGrey-300 shadow-none focus:border-richBlack focus:ring-richBlack sm:text-sm"
      [name]="fieldName"
      [id]="fieldName"
      (input)="onChange( $event )"
      (blur)="touched()"
      [value]="value"
      [disabled]="isDisabled"
      [ngClass]="{
        'has-error':
        formField.invalid
          && formField.dirty
      }"
      [type]="passwordFieldType"
      >

    <button type="button" class="toggle-password-visibility-button"
          [title]="passwordFieldType === 'text'
        ? 'Hide password'
        : 'Show password'"
      (click)="togglePasswordVisible()">
      <!-- <sami-icon
        [name]="passwordFieldType === 'text'
          ? 'add'
          : 'arrow-left'">
      </sami-icon> -->
    </button>
  </div>
</div>
