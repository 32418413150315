import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GetDownloadURLPipeModule } from '@angular/fire/compat/storage';
import { RouterModule } from '@angular/router';
import { StartsWithPipe } from '../../pipes/startsWith.pipe';
import { StoragePipe } from '../../pipes/storage.pipe';
import { ImagePipe } from '../../pipes/transformImage.pipe';
import { InstructorCardComponent } from './instructor-card.component';

@NgModule({
  declarations: [InstructorCardComponent],
  imports: [
    CommonModule,
    RouterModule,
    GetDownloadURLPipeModule,
    StartsWithPipe,
    ImagePipe,
    StoragePipe
  ],
  exports: [InstructorCardComponent]
})
export class InstructorCardModule {}
