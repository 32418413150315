import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
})
export class EmptyStateComponent {
  @Input() text: string | undefined = '';
  @Input() button:
    | {
        text: string;
        link?: string;
      }
    | undefined;

  @Output() buttonClick: EventEmitter<any> = new EventEmitter();
}
