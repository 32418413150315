<mat-stepper
  linear
  #stepper
  class="offboarding_stepper bg-culturedWhite relative p-3 sm:p-6 font-sans"
  *ngIf="auth.user$ | async as user"
>
  <mat-step>
    <div
      class="bg-culturedWhite flex flex-col justify-start items-start space-y-3 sm:space-y-8"
    >
      <h1 class="font-semibold text-xl sm:text-3xl">
        Are you sure you want to leave?
      </h1>
      <div
        class="flex flex-col justify-start items-start space-y-2 sm:space-y-4"
      >
        <h2 class="text-base sm:text-xl font-semibold text-richBlack">
          Are you experiencing any issues with our learning/training content or
          the platform itself?
        </h2>
        <span class="font-body text-sm sm:text-base">
          Please contact us and we help you out!
        </span>
        <div class="flex flex-row justify-start items-center space-x-6">
          <a
            href="mailto:online@sami-x.com"
            class="px-2 py-2 sm:px-6 sm:py-3 text-sm sm:text-lg font-semibold hover:bg-cadetGrey-900 bg-richBlack rounded-none text-white font-base"
          >
            Contact us
          </a>
          <a
            class="underline hover:text-cadetGrey-900 font-body text-sm sm:text-base"
            href="https://help.sami-x.com"
            target="_blank"
            >Open Help Center</a
          >
        </div>
      </div>

      <div
        class="bg-white p-4 flex flex-col sm:flex-row justify-start sm:items-center sm:space-y-0 space-y-2 sm:space-x-6"
      >
        <svg
          class="h-8 w-8 sm:w-12 sm:h-12 flex-shrink-0"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_2790_20792)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M24 0C24.7575 0 25.4501 0.428005 25.7889 1.10557L47.7889 45.1056C48.0988 45.7255 48.0657 46.4618 47.7013 47.0515C47.3369 47.6411 46.6932 48 46 48H2C1.30685 48 0.663114 47.6411 0.298701 47.0515C-0.0657127 46.4618 -0.0988407 45.7255 0.211148 45.1056L22.2111 1.10557C22.5499 0.428005 23.2425 0 24 0ZM5.23607 44H42.7639L24 6.47214L5.23607 44Z"
              fill="#990800"
            />
            <path d="M26 18H22V32H26V18Z" fill="#990800" />
            <path d="M26 36H22V40H26V36Z" fill="#990800" />
          </g>
          <defs>
            <clipPath id="clip0_2790_20792">
              <rect width="48" height="48" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <div class="flex flex-col justify-center items-start space-y-1">
          <h3 class="text-fightingRed-800 font-semibold text-lg sm:text-xl">
            Before you cancel your membership, please know this:
          </h3>
          <p class="text-sm sm:text-base">
            All your progress, your archievements, your training history and all
            other data (except official certifications)
            <strong>will be permanently deleted</strong> at the end of your
            current billing period on
            <strong>{{
              data.subscription?.nextBillingDate?.toDate() | date : 'MMMM d, y'
            }}</strong
            >. You are not allowed to participate in exams and get certified.
          </p>
        </div>
      </div>

      <div
        class="flex flex-col sm:flex-row w-full justify-start sm:justify-between sm:items-center sm:space-y-0 space-y-2 sm:space-x-6"
      >
        <button
          mat-dialog-close
          class="px-2 py-2 sm:px-4 sm:py-2 text-sm sm:text-base font-semibold border-2 border-richBlack bg-white rounded-none text-richBlack font-base"
        >
          No, I want to stay
        </button>

        <button
          matStepperNext
          class="px-2 py-2 sm:px-4 sm:py-2 text-sm sm:text-base font-semibold border-2 border-richBlack hover:bg-cadetGrey-900 bg-richBlack rounded-none text-white font-base"
        >
          Yes, continue cancellation
        </button>
      </div>
    </div>
  </mat-step>

  <mat-step>
    <div
      class="bg-culturedWhite flex flex-col justify-start items-start space-y-3 sm:space-y-8"
    >
      <h1 class="font-semibold text-xl sm:text-3xl">
        Please let us know why you want to leave us
      </h1>
      <div
        class="flex flex-col justify-start items-start space-y-2 sm:space-y-4 w-full"
      >
        <span class="font-body text-base">
          Please select your main reason for cancelling your membership:
        </span>
        <form
          [formGroup]="offboardingForm"
          class="space-y-2 sm:space-y-4 w-full pl-1"
        >
          <fieldset>
            <div class="space-y-2 sm:space-y-3">
              <div class="relative flex items-start">
                <div class="flex h-5 items-center">
                  <input
                    formControlName="reason"
                    value="unused"
                    id="unused"
                    aria-describedby="unused"
                    name="reason"
                    type="radio"
                    class="h-4 w-4 border-gray-300 text-richBlack focus:ring-richBlack"
                  />
                </div>
                <div class="ml-3 text-sm sm:text-base font-body">
                  <label for="unused" class="text-richBlack"
                    >I don’t have enough time.</label
                  >
                </div>
              </div>

              <div class="relative flex items-start">
                <div class="flex h-5 items-center">
                  <input
                    formControlName="reason"
                    value="too_expensive"
                    id="too_expensive"
                    aria-describedby="too_expensive"
                    name="reason"
                    type="radio"
                    class="h-4 w-4 border-gray-300 text-richBlack focus:ring-richBlack"
                  />
                </div>
                <div class="ml-3 text-sm sm:text-base font-body">
                  <label for="too_expensive" class="text-richBlack"
                    >It’s too expensive.</label
                  >
                </div>
              </div>

              <div class="relative flex items-start">
                <div class="flex h-5 items-center">
                  <input
                    formControlName="reason"
                    value="missing_features"
                    id="missing_features"
                    aria-describedby="missing_features"
                    name="reason"
                    type="radio"
                    class="h-4 w-4 border-gray-300 text-richBlack focus:ring-richBlack"
                  />
                </div>
                <div class="ml-3 text-sm sm:text-base font-body">
                  <label for="missing_features" class="text-richBlack"
                    >There is not enough content/features.</label
                  >
                </div>
              </div>

              <div class="relative flex items-start">
                <div class="flex h-5 items-center">
                  <input
                    formControlName="reason"
                    value="low_quality"
                    id="low_quality"
                    aria-describedby="low_quality"
                    name="reason"
                    type="radio"
                    class="h-4 w-4 border-gray-300 text-richBlack focus:ring-richBlack"
                  />
                </div>
                <div class="ml-3 text-sm sm:text-base font-body">
                  <label for="low_quality" class="text-richBlack"
                    >The quality of the content is less than expected.</label
                  >
                </div>
              </div>

              <div class="relative flex items-start">
                <div class="flex h-5 items-center">
                  <input
                    formControlName="reason"
                    value="switched_service"
                    id="switched_service"
                    aria-describedby="switched_service"
                    name="reason"
                    type="radio"
                    class="h-4 w-4 border-gray-300 text-richBlack focus:ring-richBlack"
                  />
                </div>
                <div class="ml-3 text-sm sm:text-base font-body">
                  <label for="switched_service" class="text-richBlack"
                    >I continue training at a SAMI center.</label
                  >
                </div>
              </div>

              <div class="relative flex items-start">
                <div class="flex h-5 items-center">
                  <input
                    formControlName="reason"
                    id="other"
                    aria-describedby="other"
                    name="reason"
                    type="radio"
                    class="h-4 w-4 border-gray-300 text-richBlack focus:ring-richBlack"
                  />
                </div>
                <div class="ml-3 text-sm sm:text-base font-body">
                  <label for="other" class="text-richBlack">Other</label>
                </div>
              </div>
            </div>
          </fieldset>

          <div>
            <span class="text-sm sm:text-base font-body text-richBlack">
              Your feedback matters. Is there anything elso you’d like us to
              know?
            </span>

            <textarea
              formControlName="text"
              class="h-12 sm:h-32 mt-2 border border-cadetGrey-300 rounded-none focus:border-richBlack w-full p-1 sm:p-2"
              name="feedback"
              id="feedback"
              cols="30"
              rows="10"
            ></textarea>
          </div>
        </form>
      </div>

      <div class="flex flex-row w-full justify-between items-center space-x-6">
        <button
          matStepperPrevious
          class="px-2 py-2 sm:px-4 sm:py-2 text-sm sm:text-base font-semibold border-2 border-richBlack bg-white rounded-none text-richBlack font-base"
        >
          Back
        </button>

        <button
          matStepperNext
          class="px-2 py-2 sm:px-4 sm:py-2 text-sm sm:text-base font-semibold border-2 border-richBlack hover:bg-cadetGrey-900 bg-richBlack rounded-none text-white font-base"
        >
          Next
        </button>
      </div>
    </div>
  </mat-step>

  <mat-step>
    <div
      class="bg-culturedWhite flex flex-col justify-start items-start space-y-3 sm:space-y-8"
    >
      <h1 class="font-semibold text-xl sm:text-3xl">Final confirmation</h1>
      <div class="bg-white p-4 flex flex-col justify-start space-y-3 w-full">
        <p class="prose-sm sm:prose max-w-none italic">
          Hi!<br class="hidden sm:block" />
          Sad to see you go! I hope you enjoyed learning and training with
          us!<br />
          If you’re leaving your fighting journey - even a little - stronger,
          smarter and more confident than you started, my mission was a
          success.<br />
          The whole SAMI team and I wish you all the best for the future and
          we’re looking forward to seeing you again.<br />
          Yours,<br />
          Peter
        </p>
        <div class="flex flex-row justify-start items-center pt-2 space-x-2">
          <picture>
            <source
              type="image/webp"
              [srcset]="
                'https://a.storyblok.com/f/110844/746x746/ce13ffe89a/p1433740-neu-1-1.png'
                  | transformImg
                    : 'storyblok'
                    : 'fit-in/0x100/smart/filters:format(webp)'
              "
            />
            <source
              type="image/png"
              [srcset]="
                'https://a.storyblok.com/f/110844/746x746/ce13ffe89a/p1433740-neu-1-1.png'
                  | transformImg
                    : 'storyblok'
                    : 'fit-in/0x100/smart/filters:format(png)'
              "
            />
            <img
              loading="lazy"
              class="inline-block h-8 w-8 sm:h-12 sm:w-12 rounded-full"
              [src]="
                'https://a.storyblok.com/f/110844/746x746/ce13ffe89a/p1433740-neu-1-1.png'
                  | transformImg : 'storyblok' : 'fit-in/0x100/smart'
              "
              [alt]="'Peter Weckauf'"
            />
          </picture>

          <div class="flex flex-col justify-start items-start">
            <span class="text-lg font-semibold"> Peter Weckauf </span>
            <span class="text-sm font-body"> Founder of SAMI </span>
          </div>
        </div>
      </div>
      <div class="flex flex-col justify-start items-start space-y-1 w-full">
        <span class="font-body text-sm sm:text-base">
          Please click <strong>“Finish Cancellation”</strong> to cancel your
          membership.
        </span>
        <span class="font-body text-sm sm:text-base">
          Cancellation will be effective at the end of your current billing
          period on
          <strong>{{
            data.subscription?.nextBillingDate?.toDate() | date : 'MMMM d, y'
          }}</strong
          >.
        </span>
      </div>

      <div
        class="flex flex-col sm:flex-row w-full justify-start sm:justify-between sm:items-center sm:space-y-0 space-y-2 sm:space-x-6"
      >
        <button
          matStepperPrevious
          class="px-2 py-2 sm:px-4 sm:py-2 text-sm sm:text-base font-semibold border-2 border-richBlack bg-white rounded-none text-richBlack font-base"
        >
          Back
        </button>

        <button
          (click)="onCancelMembership(stepper, user)"
          [disabled]="loading"
          class="px-2 py-2 sm:px-4 flex flex-row justify-center items-center space-x-3 sm:py-2 text-sm sm:text-base font-semibold border-2 border-richBlack hover:bg-cadetGrey-900 bg-richBlack rounded-none text-white font-base"
        >
          <svg
            *ngIf="loading"
            role="status"
            class="animate-spin w-4 h-4 fill-richBlack"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span>
            Finish Cancellation
          </span>
        </button>
      </div>
    </div>
  </mat-step>

  <mat-step>
    <div
      class="bg-culturedWhite flex flex-col justify-start items-start space-y-3 sm:space-y-8"
    >
      <h1 class="font-semibold text-xl sm:text-3xl">Cancellation successful</h1>
      <svg
        width="96"
        height="96"
        viewBox="0 0 96 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M82.2641 20L35.0601 66.4L12.9521 44.52L7.32812 50.208L32.2361 74.864C32.9847 75.6028 33.9942 76.0172 35.0461 76.0172C36.098 76.0172 37.1075 75.6028 37.8561 74.864L87.8561 25.696L82.2641 20Z"
          fill="#048F3C"
        />
      </svg>
      <div class="flex flex-col justify-start items-start space-y-1 w-full">
        <span class="font-body text-sm sm:text-base">
          Your cancellation was successful! Your SAMI-X account will be
          automatically cancelled at the end of your current billing period on
          <strong>{{
            data.subscription?.nextBillingDate?.toDate() | date : 'MMMM d, y'
          }}</strong
          >.
        </span>
      </div>

      <div class="flex flex-row w-full justify-center items-center">
        <button
          mat-dialog-close
          class="px-2 py-2 sm:px-4 sm:py-2 text-sm sm:text-base font-semibold border-2 border-richBlack hover:bg-cadetGrey-900 bg-richBlack rounded-none text-white font-base"
        >
          Close
        </button>
      </div>
    </div>
  </mat-step>
</mat-stepper>
