import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';

import {
  AuthProvider,
  FacebookAuthProvider,
  getAuth, GoogleAuthProvider,
  signInWithCredential,
  OAuthProvider,
  signInWithPopup,
  UserCredential,
} from '@angular/fire/auth';
import { getStorage, ref, uploadBytes, UploadResult } from '@angular/fire/storage';

import { combineLatest, lastValueFrom, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { SamiPublicProfile, SamiSubscription, SamiSubscriptionDigest, SamiUser, SamiUserBadge, SamiUserExtended, SamiUserPurchase, UserNote } from 'interfaces';

import { isPlatformBrowser } from '@angular/common';

declare const window: any;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authUser$: Observable<any>;
  user$: Observable<any>;
  verificationPending$: Observable<boolean>;

  userId: string | undefined | null;
  emailVerified: boolean | undefined | null;

  userPurchases$: Observable<any[]>;
  userBadges$: Observable<SamiUserBadge[]>;
  userSubscriptions$: Observable<SamiSubscription[]>;
  hasActiveSubscription$: Observable<boolean>;

  constructor(
    public afa: AngularFireAuth,
    private afs: AngularFirestore,
    public router: Router,
    private analytics: AngularFireAnalytics,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.afa.onAuthStateChanged(async (authUser) => {
      this.userId = authUser ? authUser.uid : null;
      this.emailVerified = authUser ? authUser.emailVerified : null;
      if (authUser) {
        const user = await lastValueFrom(this.user$.pipe(take(1)));
        if (user) {
          await this.updateUser({
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || null,
          });
        }
      } else {
        // this.router.navigateByUrl('/');
      }
    });

    this.authUser$ = this.afa.user;

    this.verificationPending$ = this.afa.user.pipe(map((authUser: any) => (authUser ? !authUser.emailVerified : false)));

    this.user$ = this.afa.user.pipe(
      tap((authUser) => {
        if (isPlatformBrowser(this.platformId)) {
          if (window && window.aa) {
            window.aa('setUserToken', authUser?.uid);
          }
        }
      }),
      switchMap((authUser: any) => authUser?.uid
        ? combineLatest([
          this.afs.doc<SamiUser>(`users/${authUser.uid}`).valueChanges(),
          this.afs.doc<SamiSubscriptionDigest>(`users/${authUser.uid}/subscriptions/digest`).valueChanges(),
          this.afs.doc('users/' + authUser.uid + '/onboarding/general').valueChanges(),
        ]).pipe(
          map(([user, subscriptionDigest, onboarding]) => ({
            ...user,
            subscriptionDigest,
            isCenterInstructor: user && user.center && user.center.role === 'Instructor',
            isCenterAdmin: user && user.center && user.center.role === 'Owner',
            onboarding: onboarding || {},
          })),
        )
        : of(null)
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.userPurchases$ = this.afa.user.pipe(
      switchMap((authUser: any) => authUser?.uid
        ? this.afs.collection<SamiSubscription>(`users/${authUser.uid}/purchases`, (ref) => ref.orderBy('createdAt', 'desc')).valueChanges({ idField: '_id' })
        : of([])
      )
    );

    this.userBadges$ = this.afa.user.pipe(
      switchMap((authUser: any) => authUser?.uid
        ? this.afs.collection<SamiUserBadge>(`users/${authUser.uid}/badges`, (ref) => ref.orderBy('createdAt', 'desc')).valueChanges({ idField: '_id' })
        : of([])
      )
    );

    this.userSubscriptions$ = this.afa.user.pipe(
      switchMap((authUser: any) => authUser?.uid
        ? this.afs.collection<SamiSubscription>(`users/${authUser.uid}/subscriptions`, (ref) => ref.orderBy('createdAt', 'desc')).valueChanges({ idField: '_id' })
        : of([])
      )
    );

    this.hasActiveSubscription$ = this.userSubscriptions$.pipe(
      map((subscriptions: SamiSubscription[]) =>
        subscriptions.filter((sub: SamiSubscription) => sub.status === 'active' || sub.status === 'trialing').length > 0
      )
    );
  }

  /// E-Mail Verification ///
  public async sendEmailVerification(): Promise<void> {
    try {
      const user = await this.afa.currentUser;
      if (user) {
        await user.sendEmailVerification();
      }
    } catch (error) {
      console.error(error);
    }
  }

  /// Login ///
  public async loginWithPassword(creds: { email: string; password: string }): Promise<unknown> {
    return this.afa.signInWithEmailAndPassword(creds.email, creds.password);
  }

  public async loginWithCustomToken(token: string): Promise<any> {
    return this.afa.signInWithCustomToken(token);
  }

  private async _oAuthLogin(provider: AuthProvider): Promise<UserCredential> {
    const auth = getAuth();
    return signInWithPopup(auth, provider);
  }

  public async googleLogin(): Promise<UserCredential> {
    const provider = new GoogleAuthProvider();
    await this.analytics.logEvent('login', { method: 'Google' });
    return this._oAuthLogin(provider);
  }

  public async facebookLogin(): Promise<UserCredential | void> {
    const provider = new FacebookAuthProvider();
    await this.analytics.logEvent('login', {
      method: 'Facebook',
    });
    return this._oAuthLogin(provider);
  }

  public async appleLogin(): Promise<UserCredential> {
    const provider = new OAuthProvider('apple.com');
    await this.analytics.logEvent('login', {
      method: 'Apple',
    });
    return this._oAuthLogin(provider);
  }

  /// Logout ///
  public async logout(redirect = false): Promise<void> {
    try {
      await this.afa.signOut();
      if (redirect) {
        this.router.navigateByUrl('/');
      }
    } catch (error) {
      console.error(error);
    }
  }

  /// Helpers ///
  public async forgotPassword(email: string): Promise<void> {
    return this.afa.sendPasswordResetEmail(email);
  }

  public getUser$(userId: string): Observable<SamiUserExtended | undefined | null> {
    return combineLatest([
      this.afs.doc<SamiUser>(`users/${userId}`).valueChanges({ idField: '_id' }),
      this.afs.doc<any>(`users/${userId}/subscriptions/digest`).valueChanges({ idField: '_id' }),
    ]).pipe(
      map(([user, subscriptionDigest]) => ({
        ...user,
        subscriptionDigest,
        isCenterInstructor: user && user.center && user.center.role === 'Instructor',
        isCenterAdmin: user && user.center && user.center.role === 'Owner',
      })),
    )
  }

  public async updateUser(data: Partial<SamiUser>, userId = this.userId): Promise<void> {
    if (!userId) {
      return Promise.reject('No User to update!');
    }
    return this.afs.doc(`users/${userId || this.userId}`).set(data, { merge: true });
  }

  public async setUserOnboarding(topic: string, userId = this.userId): Promise<void> {
    if (!userId) {
      return Promise.reject('No User to update!');
    }
    return this.afs.doc(`users/${userId || this.userId}/onboarding/general`).set({
      [topic]: true,
    }, { merge: true });
  }

  public getUserPublicProfile$(userId = this.userId): Observable<SamiPublicProfile & { _id: string } | undefined> {
    return userId ? this.afs.doc<SamiPublicProfile & { _id: string }>(`users/${userId}/publicProfiles/${userId}`).valueChanges({ idField: '_id' }) : of(undefined);
  }

  public getInternalNote$(userId: string, mode: 'admin' | 'center' | 'instructor'): Observable<UserNote | undefined> {
    return this.afs.doc<UserNote>(`users/${userId}/internalNotes/${mode}`).valueChanges();
  }

  public async saveInternalNote$(userId: string, mode: 'admin' | 'center' | 'instructor', note: UserNote): Promise<void> {
    return this.afs.doc<UserNote>(`users/${userId}/internalNotes/${mode}`).set(note);
  }

  public getUserPurchases$(contentType: 'systemsClips' | 'guidedTrainings' | 'liveTrainings' | 'tracks'): Observable<SamiUserPurchase[]> {
    return this.afa.user.pipe(
      switchMap((authUser) => authUser?.uid
        ? this.afs.collection<SamiUserPurchase>(`users/${authUser.uid}/purchases_${contentType}`).valueChanges({ idField: '_id' })
        : of([])
      ),
      shareReplay(1)
    );
  }

  public getAllUserPurchases$(contentType?: 'systemsClips' | 'guidedTrainings' | 'liveTrainings' | 'tracks'): Observable<SamiUserPurchase[]> {
    return this.afa.user.pipe(
      switchMap((authUser) => authUser?.uid
        ? contentType ? this.afs.collection<SamiUserPurchase>(`users/${authUser.uid}/purchases`, ref => ref.where('contentType', '==', contentType).orderBy('createdAt', 'desc')).valueChanges({ idField: '_id' }) : this.afs.collection<SamiUserPurchase>(`users/${authUser.uid}/purchases`, ref => ref.orderBy('createdAt', 'desc')).valueChanges({ idField: '_id' })
        : of([])
      )
    );
  }


  // User Image
  async uploadUserImage(images: FileList): Promise<UploadResult | false> {
    const file = images.item(0);

    if (!file) {
      return false;
    }

    if (file.type.split('/')[0] !== 'image') {
      return Promise.reject('Not an image');
    }

    if (file.size >= 5 * 1024 * 1024) {
      return Promise.reject('Too big, max 5MB.');
    }

    const path = `user/${this.userId}/${new Date().getTime()}_${file.name}`;

    const customMetadata: any = {
      createThumb: 'true',
      userId: this.userId,
      folder: 'user',
    };

    return uploadBytes(ref(getStorage(), path), file, customMetadata);
  }
}
