import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-bottom-sheet-certification-wrapper',
  templateUrl: './bottom-sheet-certification-wrapper.component.html',
  styleUrls: ['./bottom-sheet-certification-wrapper.component.css'],
})
export class BottomSheetCertificationWrapperComponent {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public bottomSheetRef: MatBottomSheetRef<BottomSheetCertificationWrapperComponent>,
  ) {}

  deleteHistoryEntry(entry: any) {
    this.bottomSheetRef.dismiss({
      deleteHistoryEntry: true,
      entry: entry
    });
  }
}
