export interface SamiMediaItem {
  name?: string;
  mediaFormat?: 'MEDIA_FORMAT_UNSPECIFIED' | 'PHOTO' | 'VIDEO';
  googleUrl?: string;
  thumbnailUrl?: string;
  createTime?: Date | any;
  locationAssociation?: {
    category?:
      | 'CATEGORY_UNSPECIFIED'
      | 'COVER'
      | 'PROFILE'
      | 'LOGO'
      | 'EXTERIOR'
      | 'INTERIOR'
      | 'PRODUCT'
      | 'AT_WORK'
      | 'COMMON_AREA'
      | 'TEAMS'
      | 'ADDITIONAL';
    priceListItemId?: string;
  };
  dimensions?: {
    widthPixels?: number;
    heightPixels?: number;
  };
  insights?: {
    viewCount: number;
  };
  attribution?: {
    profileName?: string;
    profilePhotoUrl?: string;
    takedownUrl?: string;
    profileUrl?: string;
  };
  description?: string;

  // Union field data can be only one of the following:
  sourceUrl?: string;
  dataRef?: {
    resourceName?: string;
  };
  // End of list of possible types for union field data.
}
