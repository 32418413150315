<div [ngClass]="displayHint ? 'md:grid-cols-2 md:gap-8' : ''" class="grid grid-cols-1 gap-4 font-sans" class="grid grid-cols-1 gap-4 font-sans" *ngIf="user">
  <form [formGroup]="userForm" class="font-sans flex flex-col justify-start space-y-4">
    <div class="flex flex-col justify-start space-y-2">
      <div class="grid grid-cols-2 gap-6">
        <div>
          <label
            for="firstName"
            class="block text-sm font-medium text-gray-700"
          >
            First Name
          </label>
          <div class="mt-1 rounded-none shadow-none flex">
            <input
              type="text"
              name="firstName"
              id="firstName"
              autocomplete="given-name"
              formControlName="firstName"
              class="py-2 px-3 focus:ring-light-blue-500 focus:border-light-blue-500 flex-grow block w-full min-w-0 rounded-none sm:text-sm border border-gray-300"
              value="lisamarie"
            />
          </div>
        </div>
        <div>
          <label
            for="lastName"
            class="block text-sm font-medium text-gray-700"
          >
            Last Name
          </label>
          <div class="mt-1 rounded-none shadow-none flex">
            <input
              type="text"
              name="lastName"
              id="lastName"
              autocomplete="family-name"
              formControlName="lastName"
              class="py-2 px-3 focus:ring-light-blue-500 focus:border-light-blue-500 flex-grow block w-full min-w-0 rounded-none sm:text-sm border border-gray-300"
              value="lisamarie"
            />
          </div>
        </div>
      </div>

      <div class="mt-1 grid grid-cols-4 gap-6">
        <div class="col-span-4 sm:col-span-2">
          <label
            for="phoneNumber"
            class="block text-sm font-medium text-gray-700"
            >Phone Number</label
          >
          <input
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            formControlName="phoneNumber"
            autocomplete="cc-phoneNumber"
            class="mt-1 block w-full border border-gray-300 rounded-none shadow-none py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm"
          />
        </div>

        <div class="col-span-4 sm:col-span-2">
          <label
            for="email"
            class="block text-sm font-medium text-gray-700"
            >E-Mail Address</label
          >
          <input
            type="text"
            formControlName="email"
            name="email"
            id="email"
            autocomplete="cc-email"
            class="mt-1 block w-full border border-gray-300 rounded-none shadow-none py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm"
          />
        </div>
      </div>

      <div class="mt-1 grid grid-cols-4 gap-6">
        <div class="col-span-4 sm:col-span-2">
          <label
            for="gender"
            class="block text-sm font-medium text-gray-700"
            >Gender</label
          >
          <select
            formControlName="gender"
            id="gender"
            name="gender"
            autocomplete="gender"
            class="mt-1 block w-full bg-white border border-gray-300 rounded-none shadow-none py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm"
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="non-binary">Non-Binary</option>
          </select>
        </div>

        <div class="col-span-4 sm:col-span-2">
          <label
            for="dateOfBirth"
            class="block text-sm font-medium text-gray-700"
            >Date of Birth</label
          >
          <input
            type="date"
            formControlName="dateOfBirth"
            name="dateOfBirth"
            id="dateOfBirth"
            class="mt-1 block w-full border border-gray-300 rounded-none shadow-none py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm"
          />
        </div>
      </div>

      <div class="mt-1 grid grid-cols-4 gap-6">
        <div class="col-span-4 sm:col-span-2">
          <label
            for="preferredLanguage"
            class="block text-sm font-medium text-gray-700"
            >Preferred Language</label
          >
          <select
            formControlName="preferredLanguage"
            id="preferredLanguage"
            name="preferredLanguage"
            autocomplete="language"
            class="mt-1 block w-full bg-white border border-gray-300 rounded-none shadow-none py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm"
          >
            <option value="en">English</option>
            <option value="de">German (Coming Soon)</option>
          </select>
        </div>
      </div>
    </div>
    <sami-button text="Save" [disabled]="userForm.invalid" (onClick)="onUpdateUser()"></sami-button>
  </form>
  <p *ngIf="displayHint" class="text-sm font-body text-cadetGrey-600">
    Please use your real first and last name. This information will be used on your diplomas and certifications.
  </p>
</div>