import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, tap } from 'rxjs';
import { SamiUser } from 'interfaces';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UpdateProfileComponent } from '../../forms/update-profile/update-profile.component';
import { UpdateProfileImageComponent } from '../../forms/update-profile-image/update-profile-image.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-edit-profile-modal',
  standalone: true,
  imports: [
    CommonModule,
    UpdateProfileComponent,
    UpdateProfileImageComponent,
    MatSnackBarModule
  ],
  templateUrl: './edit-profile-modal.component.html'
})
export class EditProfileModalComponent {
  customer$: Observable<(SamiUser & { uid: string; }) | undefined>;
  customerId: string | undefined;

  constructor(
    public dialogRef: MatDialogRef<EditProfileModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private afs: AngularFirestore
  ) {
    this.customerId = data.customerId;
    this.customer$ = this.afs.doc<(SamiUser & { uid: string; }) | undefined>(`users/${data.customerId}`).valueChanges({ idField: 'uid' });
  }
}
