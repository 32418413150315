import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { arrayRemove, arrayUnion } from '@angular/fire/firestore';
import { UiService } from '@sami/features';
import { SamiCenter } from 'interfaces';

@Component({
  selector: 'app-combat-system-selection',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './combat-system-selection.component.html',
})
export class CombatSystemSelectionComponent implements OnChanges {
  @Input() instructors: any | undefined = undefined;
  @Input() center: SamiCenter | undefined = undefined;
  combatSystems: string[] = [];

  constructor(public ui: UiService, private afs: AngularFirestore) {}

  ngOnChanges(): void {
    let systems: string[] = [];
    if (this.instructors && this.instructors.length > 0) {
      this.instructors.forEach((instructor: any) => {
        const s = instructor.styleExams.map(
          (styleExam: any) => styleExam.style.identifier
        );
        systems.push(...s);
      });
    }

    this.combatSystems = Array.from(new Set(systems));
  }

  async updateBlacklistedSystems(id: string) {
    if (this.center && this.center.id) {
      if (
        this.center?.blacklistedSystemIdentifiers &&
        this.center.blacklistedSystemIdentifiers.includes(id)
      ) {
        this.afs.doc(`centers/${this.center.id}`).update({
          blacklistedSystemIdentifiers: arrayRemove(id),
        });
      } else {
        this.afs.doc(`centers/${this.center.id}`).update({
          blacklistedSystemIdentifiers: arrayUnion(id),
        });
      }
    }
  }
}
