import { Component, Inject } from '@angular/core';
import {
  MatLegacySnackBarRef as MatSnackBarRef,
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
} from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-error-snackbar',
  templateUrl: './error-snackbar.component.html',
})
export class ErrorSnackbarComponent {
  title: string | undefined;
  subtitle: string | undefined;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBar: MatSnackBarRef<ErrorSnackbarComponent>
  ) {
    if (this.data.title) {
      this.title = this.data.title;
    }

    if (this.data.subtitle) {
      this.subtitle = this.data.subtitle;
    }
  }

  closeSnackBar() {
    this.snackBar.dismiss();
  }
}
