import { Component, forwardRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FieldErrorsComponent } from '../field-errors/field-errors.component';

@Component({
  selector: 'sami-select',
  standalone: true,
  imports: [CommonModule, FieldErrorsComponent ],
  templateUrl: './select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SamiSelectComponent), multi: true,
    }
  ]
})
export class SamiSelectComponent implements ControlValueAccessor {

  @Input() parentForm!: FormGroup;
  @Input() fieldName!: string;
  @Input() label!: string;
  @Input() hint: string | undefined;
  @Input() displayLabel = false;
  @Input() options: { key: string | number, value: string }[] = [];

  public value: string = '';
  public changed: (value: string) => void = () => {};
  public touched: () => void = () => {};
  public isDisabled: boolean = false;

  get formField (): FormControl {
    return this.parentForm.get(this.fieldName) as FormControl;
  }

  constructor() { }

  writeValue(obj: any): void {
    this.value = obj;
  }

  public onChange( event: Event ): void {
    const value: string = (event.target as HTMLInputElement).value;
    this.changed( value );
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  get validator() {
    if (this.formField.validator) {
      const validator = this.formField.validator({} as AbstractControl);
      if (validator && validator['required']) {
        return true;
      }
    }
    return false;
  }
}
