import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { firstValueFrom } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-write-with-ai',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './write-with-ai.component.html'
})
export class WriteWithAiComponent {
  @Input() prompt!: string;
  @Output() promptChange: EventEmitter<string> = new EventEmitter();

  loading = false;

  constructor(
    private aff: AngularFireFunctions
  ) {}

  async generate() {
    if (!this.prompt) {
      return;
    }

    this.loading = true;
    try {

      const options = {
        model: "gpt-3.5-turbo",
        temperature: 0.8,
        max_tokens: 3000,
      };

      console.log(this.prompt);
      const result = await firstValueFrom(
        this.aff.httpsCallable('getChatGPTAnswer')({
          messages: [
            { role: "user", content: this.prompt },
          ],
          options,
        })
      );

      this.promptChange.emit(result);

    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }
}
