import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { lastValueFrom, take } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '@sami/features';

@Component({
  selector: 'app-downgrade-dialog',
  templateUrl: './downgrade-dialog.component.html',
})
export class DowngradeDialogComponent implements OnInit {
  form: FormGroup;
  loading = false;
  success = false;

  constructor(
    public dialogRef: MatDialogRef<DowngradeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public auth: AuthService,
    private fb: FormBuilder,
    private aff: AngularFireFunctions
  ) {
    this.form = this.fb.group({
      system: this.fb.control('kravMaga', Validators.required),
    });
  }

  ngOnInit(): void {}

  async downgrade() {
    if (this.form.invalid) {
      return;
    }
    try {
      this.loading = true;
      await lastValueFrom(
        this.aff
          .httpsCallable('updateSubscription')({
            subscriptionId: this.data.subscription.subscriptionId,
            priceId: this.data.priceId,
            mode: 'downgrade',
            systems: this.form.get('system')?.value,
          })
          .pipe(take(1))
      );
      this.loading = false;
      this.success = true;
    } catch (error) {
      this.loading = false;
      console.error(error);
    }
  }
}
