export interface SamiCompany {
  [key: string]: any;
  id?: string;
  name?: string;
  email?: string;
  stripeCustomerId?: string;
  memberCount?: number;
  assignedLicenses?: number;
  ci?: {
    colors?: {
      primary?: string;
      secondary?: string;
    };
    logoUrl?: string;
    logoUrlSmDark?: string;
    logoUrlSmLight?: string;
  };
}
