<div
  [ngClass]="backgroundColor === 'white' ? 'bg-white' : 'bg-culturedWhite'"
  class="p-3 flex flex-row justify-start rounded-md overflow-hidden shadow-sm space-x-4 h-full"
  *ngIf="type === 'badge'"
>
  <div class="w-24 p-2 flex flex-row justify-center items-center">
    <ng-container
      *ngIf="
        systemIdentifier === 'kravMaga' ||
        systemIdentifier === 'sds' ||
        systemIdentifier === 'defender' ||
        systemIdentifier === 'womenkravmaga' ||
        systemIdentifier === 'core' ||
        systemIdentifier === 'kidskravmaga'
      "
    >
      <svg
        class="w-24"
        version="1.1"
        id="Ebene_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 96 96"
        style="enable-background: new 0 0 96 96"
        xml:space="preserve"
      >
        <style type="text/css">
          .df0 {
            fill: transparent;
          }
          .df1 {
            fill: #0e1625;
            stroke: #77825e;
            stroke-width: 5;
            stroke-miterlimit: 10;
          }
          .df2 {
            fill: none;
          }
          .df3 {
            fill: #edf2ef;
          }
          .df5 {
            font-size: 40px;
          }
        </style>
        <rect y="0" class="df0" width="96" height="96" />
        <path
          class="df1"
          d="M48,84c19.9,0,36-16.1,36-36S67.9,12,48,12S12,28.1,12,48S28.1,84,48,84z"
        />
        <path
          class="df0"
          d="M21.8,51.6c1.7,0,3.1-1.4,3.1-3.1c0-1.7-1.4-3.1-3.1-3.1s-3.1,1.4-3.1,3.1C18.7,50.2,20.1,51.6,21.8,51.6z"
        />
        <path
          class="df0"
          d="M75.1,51.6c1.7,0,3.1-1.4,3.1-3.1c0-1.7-1.4-3.1-3.1-3.1S72,46.8,72,48.5C72,50.2,73.4,51.6,75.1,51.6z"
        />
        <g>
          <rect x="12" y="12" class="df2" width="72.1" height="72.1" />
        </g>
        <rect x="12" y="12" class="df2" width="72.1" height="72" />
        <text transform="matrix(1 0 0 1 39.5298 62.0001)" class="df3 df4 df5">
          {{ level }}
        </text>
      </svg>
    </ng-container>

    <ng-container
      *ngIf="
        systemIdentifier === 'panantukan' ||
        systemIdentifier === 'knife' ||
        systemIdentifier === 'axe' ||
        systemIdentifier === 'stick' ||
        systemIdentifier === 'karambit'
      "
    >
      <svg
        class="w-24"
        version="1.1"
        id="Ebene_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 96 97"
        style="enable-background: new 0 0 96 97"
        xml:space="preserve"
      >
        <style type="text/css">
          .fa0 {
            fill: #ffffff;
          }
          .fa1 {
            fill: #0e1625;
            stroke: #b31917;
            stroke-width: 5;
            stroke-miterlimit: 10;
          }
          .fa2 {
            fill: none;
          }
          .fa3 {
            fill: #edf2ef;
          }
          .fa5 {
            font-size: 40px;
          }
        </style>
        <rect y="0" class="fa0" width="96" height="96.2" />
        <path class="fa1" d="M48,10.3l-42.7,74h85.4L48,10.3z" />
        <path class="fa0" d="M68.5,56.4l-4,7h8.1L68.5,56.4z" />
        <path class="fa0" d="M28.3,56.4l-4,7h8.1L28.3,56.4z" />
        <g>
          <rect x="5.3" y="37.4" class="fa2" width="85.4" height="46.9" />
        </g>
        <rect x="5.3" y="35.5" class="fa2" width="85.4" height="48.8" />
        <text transform="matrix(1 0 0 1 39.48 73.9117)" class="fa3 fa4 fa5">
          {{ level }}
        </text>
      </svg>
    </ng-container>

    <ng-container
      *ngIf="systemIdentifier === 'kapap' || systemIdentifier === 'ebc' || systemIdentifier === 'weaponProtection' || systemIdentifier === 'securitykravmaga'"
    >
      <svg
        class="w-24"
        version="1.1"
        id="Ebene_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 96 96"
        style="enable-background: new 0 0 96 96"
        xml:space="preserve"
      >
        <style type="text/css">
          .p0 {
            fill: #ffffff;
          }
          .p1 {
            fill: #0e1625;
            stroke: #f2bb09;
            stroke-width: 5;
            stroke-miterlimit: 10;
          }
          .p2 {
            fill: none;
          }
          .p3 {
            fill: #edf2ef;
          }
          .p5 {
            font-size: 40px;
          }
        </style>
        <rect y="0" class="p0" width="96" height="96" />
        <path
          class="p1"
          d="M79,11.6H17v41.7c0,17.1,13.9,31,31,31s31-13.9,31-31V11.6z"
        />
        <path class="p0" d="M73.2,51.6c-2.2,0-4-1.8-4-4s1.8-4,4-4V51.6z" />
        <path class="p0" d="M22,43.6c2.2,0,4,1.8,4,4s-1.8,4-4,4V43.6z" />
        <g>
          <rect x="17" y="11.6" class="p2" width="62" height="72.7" />
        </g>
        <rect x="17" y="11.6" class="p2" width="62" height="70.2" />
        <text transform="matrix(1 0 0 1 39.48 60.6887)" class="p3 p4 p5">
          {{ level }}
        </text>
      </svg>
    </ng-container>

    <ng-container *ngIf="!systemIdentifier && badgePath">
      <img
        *ngIf="badgePath"
        class="w-20"
        [src]="badgePath | getDownloadURL"
        [alt]="title"
      />
    </ng-container>
  </div>
  <div class="flex font-sans flex-col justify-center items-start">
    <span class="text-lg text-richBlack font-semibold tracking-tight">{{
      title
    }}</span>
  </div>
</div>

<div [ngClass]="backgroundColor === 'white' ? 'bg-white' : 'bg-culturedWhite'" class="p-2 h-full shadow-sm rounded-md overflow-hidden relative" *ngIf="type === 'certification'">
  <div
    class="flex flex-row justify-start space-x-4 p-1 border-2 border-richBlack h-full rounded-md"
  >
    <div class="w-24 p-2 flex flex-row justify-center items-center">
      <ng-container
        *ngIf="
          systemIdentifier === 'kravMaga' ||
          systemIdentifier === 'sds' ||
          systemIdentifier === 'defender' ||
          systemIdentifier === 'womenkravmaga'
        "
      >
        <svg
          class="w-24"
          version="1.1"
          id="Ebene_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 96 96"
          style="enable-background: new 0 0 96 96"
          xml:space="preserve"
        >
          <style type="text/css">
            .df0 {
              fill: transparent;
            }
            .df1 {
              fill: #0e1625;
              stroke: #77825e;
              stroke-width: 5;
              stroke-miterlimit: 10;
            }
            .df2 {
              fill: none;
            }
            .df3 {
              fill: #edf2ef;
            }
            .df5 {
              font-size: 40px;
            }
          </style>
          <rect y="0" class="df0" width="96" height="96" />
          <path
            class="df1"
            d="M48,84c19.9,0,36-16.1,36-36S67.9,12,48,12S12,28.1,12,48S28.1,84,48,84z"
          />
          <path
            class="df0"
            d="M21.8,51.6c1.7,0,3.1-1.4,3.1-3.1c0-1.7-1.4-3.1-3.1-3.1s-3.1,1.4-3.1,3.1C18.7,50.2,20.1,51.6,21.8,51.6z"
          />
          <path
            class="df0"
            d="M75.1,51.6c1.7,0,3.1-1.4,3.1-3.1c0-1.7-1.4-3.1-3.1-3.1S72,46.8,72,48.5C72,50.2,73.4,51.6,75.1,51.6z"
          />
          <g>
            <rect x="12" y="12" class="df2" width="72.1" height="72.1" />
          </g>
          <rect x="12" y="12" class="df2" width="72.1" height="72" />
          <text transform="matrix(1 0 0 1 39.5298 62.0001)" class="df3 df4 df5">
            {{ level }}
          </text>
        </svg>
      </ng-container>

      <ng-container
        *ngIf="
          systemIdentifier === 'panantukan' ||
          systemIdentifier === 'knife' ||
          systemIdentifier === 'axe' ||
          systemIdentifier === 'stick' ||
          systemIdentifier === 'karambit'
        "
      >
        <svg
          class="w-24"
          version="1.1"
          id="Ebene_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 96 97"
          style="enable-background: new 0 0 96 97"
          xml:space="preserve"
        >
          <style type="text/css">
            .fa0 {
              fill: transparent;
            }
            .fa1 {
              fill: #0e1625;
              stroke: #b31917;
              stroke-width: 5;
              stroke-miterlimit: 10;
            }
            .fa2 {
              fill: none;
            }
            .fa3 {
              fill: #edf2ef;
            }
            .fa5 {
              font-size: 40px;
            }
          </style>
          <rect y="0" class="fa0" width="96" height="96.2" />
          <path class="fa1" d="M48,10.3l-42.7,74h85.4L48,10.3z" />
          <path class="fa0" d="M68.5,56.4l-4,7h8.1L68.5,56.4z" />
          <path class="fa0" d="M28.3,56.4l-4,7h8.1L28.3,56.4z" />
          <g>
            <rect x="5.3" y="37.4" class="fa2" width="85.4" height="46.9" />
          </g>
          <rect x="5.3" y="35.5" class="fa2" width="85.4" height="48.8" />
          <text transform="matrix(1 0 0 1 39.48 73.9117)" class="fa3 fa4 fa5">
            {{ level }}
          </text>
        </svg>
      </ng-container>

      <ng-container
        *ngIf="systemIdentifier === 'kapap' || systemIdentifier === 'ebc' || systemIdentifier === 'weaponProtection'"
      >
        <svg
          class="w-24"
          version="1.1"
          id="Ebene_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 96 96"
          style="enable-background: new 0 0 96 96"
          xml:space="preserve"
        >
          <style type="text/css">
            .p0 {
              fill: transparent;
            }
            .p1 {
              fill: #0e1625;
              stroke: #f2bb09;
              stroke-width: 5;
              stroke-miterlimit: 10;
            }
            .p2 {
              fill: none;
            }
            .p3 {
              fill: #edf2ef;
            }
            .p5 {
              font-size: 40px;
            }
          </style>
          <rect y="0" class="p0" width="96" height="96" />
          <path
            class="p1"
            d="M79,11.6H17v41.7c0,17.1,13.9,31,31,31s31-13.9,31-31V11.6z"
          />
          <path class="p0" d="M73.2,51.6c-2.2,0-4-1.8-4-4s1.8-4,4-4V51.6z" />
          <path class="p0" d="M22,43.6c2.2,0,4,1.8,4,4s-1.8,4-4,4V43.6z" />
          <g>
            <rect x="17" y="11.6" class="p2" width="62" height="72.7" />
          </g>
          <rect x="17" y="11.6" class="p2" width="62" height="70.2" />
          <text transform="matrix(1 0 0 1 39.48 60.6887)" class="p3 p4 p5">
            {{ level }}
          </text>
        </svg>
      </ng-container>
    </div>
    <div class="flex flex-col justify-center font-sans items-start">
      <span class="uppercase text-sm font-semibold text-cadetGrey-400"
        >{{
          examType && examType === 'student' ? 'student' : 'instructor'
        }}
        diploma</span
      >
      <span class="text-lg text-richBlack font-semibold tracking-tight">{{
        title
      }}</span>
      <div class="flex flex-row justify-start items-end space-x-2">
        <span class="text-sm font-body text-richBlack" *ngIf="lastExam && lastExam.date">
          {{ lastExam && lastExam.date.toDate() | date: 'dd/MM/yyyy' }}
        </span>
      </div>

      <div class="flex flex-row text-sm justify-start items-center space-x-1">
        <button
          (click)="generateCertificate()"
          class="text-left underline text-cadetGrey-500 text-sm font-body"
        >
          Latest Diploma
        </button>
        <div class="text-cadetGrey-500 font-body" *ngIf="history">
          •
        </div>
        <button *ngIf="history" (click)="viewHistory()" class="underline text-left text-cadetGrey-500 font-body">
          View History
        </button>
      </div>
    </div>
  </div>

  <div
    class="absolute inset-0 w-full h-full bg-richBlack/80 flex flex-row justify-center space-x-2 items-center"
    *ngIf="loading"
  >
    <svg
      role="status"
      class="w-5 h-5 text-gray-200 animate-spin fill-white"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>
    <span class="text-xs font-semibold text-white w-32">
      We are generating the PDF for you. Please wait...
    </span>
  </div>
</div>
