import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SamiButtonComponent } from '../button/button.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-notification',
  standalone: true,
  imports: [ CommonModule, SamiButtonComponent ],
  templateUrl: './notification.component.html'
})
export class NotificationComponent {
  @Input() text: { text?: string, html?: string, callToAction?: { text?: string }} | undefined;
  @Input() type: 'success' | 'error' | 'info' | 'warning' = 'info';
  @Input() subtitle: string | undefined;
  @Input() title: string | undefined;
  @Input() button: { text: string } | undefined;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() shadow = true;
  @Input() showCloseButton = true;

  @Output() buttonClick: EventEmitter<void> = new EventEmitter();
  @Output() callToActionClick: EventEmitter<void> = new EventEmitter();
  @Output() hideNotification: EventEmitter<void> = new EventEmitter();

  getNotificationTheme() {
    let c = this.getNotificationColors();

    if (this.shadow) {
      c += ' shadow-lg';
    }

    if (this.size === 'small') {
      c += ' p-2 space-x-2';
    } else if (this.size === 'medium') {
      c += ' p-4 space-x-2';
    } else {
      c += ' p-4 space-x-4';
    }

    return c;
  }

  getNotificationColors() {
    switch (this.type) {
      case 'success':
        return 'bg-statusColors-lightGreen border-statusColors-green text-statusColors-darkGreen';
      case 'error':
        return 'bg-statusColors-lightRed border-statusColors-red text-statusColors-darkRed';
      case 'info':
        return 'bg-culturedWhite border-cadetGrey-600 text-cadetGrey-800';
      case 'warning':
        return 'bg-statusColors-lightYellow border-statusColors-yellow text-statusColors-darkYellow';
      default:
        return 'bg-statusColors-lightYellow border-statusColors-yellow text-statusColors-darkYellow';
    }
  }

  getCloseButtonColor() {
    switch (this.type) {
      case 'success':
        return 'text-statusColors-darkGreen';
      case 'error':
        return 'text-statusColors-darkRed';
      case 'info':
        return 'text-cadetGrey-800';
      case 'warning':
        return 'text-statusColors-darkYellow';
      default:
        return 'text-statusColors-darkYellow';
    }
  }

  getIconSize() {
    switch (this.size) {
      case 'small':
        return 'w-5 h-5';
      case 'medium':
        return 'w-6 h-6';
      case 'large':
        return 'w-8 h-8';
    }
  }

  getTitleFontSize() {
    switch (this.size) {
      case 'small':
        return 'text-base';
      case 'medium':
        return 'text-xl';
      case 'large':
        return 'text-3xl';
    }
  }

  getSubtitleFontSize() {
    switch (this.size) {
      case 'small':
        return 'text-sm';
      case 'medium':
        return 'text-base';
      case 'large':
        return 'text-lg';
    }
  }

  getTextFontSize() {
    switch (this.size) {
      case 'small':
        return 'text-sm';
      case 'medium':
        return 'text-base';
      case 'large':
        return 'text-base';
    }
  }


}
