import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { UploaderComponent } from '../uploader/uploader.component';
import { NotificationComponent } from '../notification/notification.component';
import { InstructorAgreement, SamiUser } from 'interfaces';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sami-upload-agreement',
  standalone: true,
  imports: [CommonModule, UploaderComponent, NotificationComponent],
  templateUrl: './upload-agreement.component.html'
})
export class UploadAgreementComponent {
  @Input() user: SamiUser | undefined;
  @Input() instructorAgreement: InstructorAgreement | undefined;
  @Input() location: any;
  @Input() hint = 'Please upload the filled-out and signed instructor agreement before you pay for your instructor license.';

  constructor(
    private afs: AngularFirestore,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  getNotificationType(instructorAgreement: InstructorAgreement) {
    switch (instructorAgreement.status) {
      case 'accepted':
        return 'success';
      case 'pending':
        return 'warning';
      case 'rejected':
        return 'error';
      default:
        return 'warning'
    }
  }

  getNotificationTitle(instructorAgreement: InstructorAgreement) {
    switch (instructorAgreement.status) {
      case 'accepted':
        return 'Agreement accepted';
      case 'pending':
        return 'Agreement pending';
      case 'rejected':
        return 'Agreement rejected';
      default:
        return 'Agreement pending'
    }
  }

  getNotificationMessage(instructorAgreement: InstructorAgreement) {
    switch (instructorAgreement.status) {
      case 'accepted':
        return 'Your instructor agreement was accepted.';
      case 'pending':
        return 'Your instructor agreement is pending. It will be reviewed by our team. You will be notified by email.';
      case 'rejected':
        return 'Your instructor agreement was rejected. Please upload a new version.';
      default:
        return 'Your instructor agreement is pending. It will be reviewed by our team. You will be notified by email.'
    }
  }

  async onFinishedUpload(user: SamiUser, location: any) {
    if (isPlatformBrowser(this.platformId)) {
      try {
        await this.afs
          .collection('users/' + user.uid + '/instructorAgreement')
          .doc(user.uid)
          .set(
            {
              version: '1.0',
              date: new Date(),
              status: 'pending',
              ip: location.ip,
              userAgent: window.navigator.userAgent,
            },
            { merge: true }
          );
      } catch (error) {
        console.error(error);
      }
    }
  }
}
