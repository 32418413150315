import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocationService } from '@sami/features';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  constructor(
    public location: LocationService,
    private router: Router,
    private viewportScroller: ViewportScroller
  ) {}

  ngOnInit(): void {}

  changeLanguage(oldValue: string, newValue: string) {
    this.location.setLanguage(newValue);
    this.router.navigateByUrl(this.router.url.replace(oldValue, newValue));
    this.viewportScroller.scrollToPosition([0, 0]);
  }
}
