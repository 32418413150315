import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Router } from '@angular/router';
import { AuthService, UiService } from '@sami/features';
import { Subscription } from 'rxjs';
import * as branch from 'branch-sdk';
import { isPlatformBrowser } from '@angular/common';

declare var window: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  userSubscription: Subscription | undefined;

  loading = true;

  constructor(
    public authService: AuthService,
    public ui: UiService,
    public router: Router,
    private analytics: AngularFireAnalytics,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    if (isPlatformBrowser(this.platformId)) {
      window.storyblok.init();
      window.storyblok.on(['change', 'published'], function () {
        location.reload();
      });

      import('branch-sdk').then(branch => {
        branch.init('key_live_fAhFKbP2Aqu3X1LUDV94gkjjuzh4sAUk', this.branchInitCallback.bind(this));
      });

      setTimeout(() => {
        this.loading = false;
      }, 1000);

      window.addEventListener(
        'CookiebotOnAccept',
        () => {
          this.manageCookies();
        },
        false
      );

      window.addEventListener(
        'CookiebotOnConsentReady',
        () => {
          this.manageCookies();
        },
        false
      );
    }

    this.ui.currentSite$.next('sami-international');
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (window && window.Cookiebot) {
        if (!window.Cookiebot.hasResponse) {
          window.Cookiebot.show();
        }
      }
    }
  }

  branchInitCallback(err: any, data: { [x: string]: any; }) {
    if (err) {
      console.error('Branch SDK initialization error:', err);
    } else {
      if (data && data['+clicked_branch_link'] && data['target_url']) {
        const targetUrl = data['target_url'];
        this.router.navigateByUrl(targetUrl);
      }
    }
  }

  manageCookies() {
    if (isPlatformBrowser(this.platformId)) {
      // Enable Statistics Cookies
      if (window.Cookiebot.consent.statistics) {
        this.analytics.setAnalyticsCollectionEnabled(true);
      } else {
        this.analytics.setAnalyticsCollectionEnabled(false);
      }

      // Enable Marketing Cookies
      if (window.Cookiebot.consent.marketing) {
        if (window && window.fbq) {
          window.fbq('consent', 'grant');
        }
      } else {
        if (window && window.fbq) {
          window.fbq('consent', 'revoke');
        }
      }
    }
  }
}
