import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { CONTAINER_DATA } from '../menu-button/menu-button.component';

interface SamiSubmenuItem {
  title: string;
  route: string;
  description: string;
  image?: string;
  isFeatured?: boolean;
}

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html'
})
export class SubmenuComponent {
  submenu: {
    cols?: number;
    items: SamiSubmenuItem[];
    featured?: SamiSubmenuItem;
  } | undefined;
  lang: string | undefined;

  @Output() backdropClick: EventEmitter<any> = new EventEmitter();

  constructor(@Inject(CONTAINER_DATA) public data: any) {
    if (data) {
      this.submenu = {
        cols: data.cols || 2,
        items: data.items,
        featured: data.featured
      };

      this.lang = data.lang;
    }
  }
}
