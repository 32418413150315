<div class="flex flex-row justify-start bg-white px-4 py-3 relative">
  <div
    class="w-8 flex flex-row justify-center items-start pt-1 mr-3 text-richBlack"
  >
    <svg
      class="w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 48 48"
    >
      <g transform="matrix(2,0,0,2,0,0)">
        <path
          stroke="currentColor"
          stroke-linejoin="round"
          stroke-width="2"
          d="M23 23H1L12 1L23 23Z"
        ></path>
        <path fill="currentColor" d="M13 9H11V16H13V9Z"></path>
        <path fill="currentColor" d="M13 18H11V20H13V18Z"></path>
      </g>
    </svg>
  </div>
  <div class="flex flex-col justify-start items-start space-y-0">
    <span class="text-base font-semibold tracking-tight text-richBlack">
      {{ title }}
    </span>
    <span class="text-sm font-body text-cadetGrey-600">
      {{ subtitle }}
    </span>
  </div>

  <button (click)="closeSnackBar()" class="absolute top-2 right-2">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6C0 2.68629 2.68629 0 6 0H14C17.3137 0 20 2.68629 20 6V14C20 17.3137 17.3137 20 14 20H6C2.68629 20 0 17.3137 0 14V6Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L10 8.58579L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L11.4142 10L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L10 11.4142L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L8.58579 10L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z"
        fill="#9CA3AF"
      />
    </svg>
  </button>
</div>
