import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SamiEvent, SamiExam } from 'interfaces';
import { ErrorSnackbarComponent, SuccessSnackbarComponent } from '@sami/ui';
import { CountdownConfig } from 'ngx-countdown';
import { firstValueFrom, take } from 'rxjs';

@Component({
  selector: 'app-attendance-buttons',
  templateUrl: './attendance-buttons.component.html',
})
export class AttendanceButtonsComponent implements OnInit {
  @Input() element: SamiEvent | SamiExam | null = null;
  @Input() mode: 'exam' | 'liveTraining' = 'liveTraining';
  @Input() id: string | null = null;
  @Input() userId: string | null = null;
  @Input() layout: 'row' | 'col' = 'row';
  @Input() hasAccess = false;
  status: 'canceled' | 'attendee' | null = null;
  loading = false;

  openingDate: Date | null = null;
  secondsUntilOpening = 0;

  config: CountdownConfig | null = null;

  constructor(
    private snackbar: MatSnackBar,
    private aff: AngularFireFunctions,
  ) { }

  ngOnInit(): void {
    if (
      this.userId &&
      this.element &&
      this.element['attendeesList']?.includes(this.userId)
    ) {
      this.status = 'attendee';
      this.hasAccess = true;
    } else {
      // Student is not in the attendees list
      this.status = null;

      // Get Track Progress of student if mode is exam
      if (this.mode === 'exam' && this.userId && this.id) {
        this.checkIfUserIsAllowedToAttendExam();
      }
    }

    if (this.element && this.element.startDate) {
      // The start date is 10 minutes before the actual start date
      this.openingDate = new Date(this.element.startDate.toDate() - 10 * 60 * 1000);

      // get the seconds until the opening date
      this.secondsUntilOpening = Math.floor(
        (this.openingDate.getTime() - new Date().getTime()) / 1000
      );

      if (this.secondsUntilOpening && this.secondsUntilOpening > 0) {
        this.config = {
          leftTime: this.secondsUntilOpening,
          formatDate: ({ date, formatStr, timezone }) => {
            const s = this.secondsToDhm(date / 1000);
            return s;
          },
        }
      }
    }
  }

  async checkIfUserIsAllowedToAttendExam() {
    this.loading = true;
    try {
      const result = await firstValueFrom(this.aff
        .httpsCallable('checkIfUserIsAllowedToRegisterForAnExam')({
          userId: this.userId,
          examId: this.id,
        })
        .pipe(take(1)));

      this.loading = false;
      if (result) {
        this.hasAccess = true;
      } else {
        this.hasAccess = false;
      }
    } catch (error) {
      this.loading = false;
      this.hasAccess = false;
    }
  }

  secondsToDhm(seconds: number | null) {
    seconds = Number(seconds);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor(seconds % (3600 * 24) / 3600);
    const m = Math.floor(seconds % 3600 / 60);
    const s = Math.floor(seconds % 60);

    const dDisplay = d > 0 ? d + "d " : "0d ";
    const hDisplay = h > 0 ? h + "h " : "0h ";
    const mDisplay = m > 0 ? m + "m " : "0m ";
    const sDisplay = s > 0 ? s + "s " : "0s ";

    return dDisplay + hDisplay + mDisplay + sDisplay;
  }

  onUpdateSecondsUntilOpening(event: any) {
    if (event.action == 'done') {
      this.secondsUntilOpening = -1;
    }
  }

  async setAttendee(status: 'attending' | 'canceled', id: string) {
    this.loading = true;
    try {
      const res = await firstValueFrom(
        this.aff.httpsCallable('setAttendance')({
          id,
          status,
          mode: this.mode
        })
      );
      this.loading = false;
      if (res) {
        if (status === 'attending') {
          this.snackbar.openFromComponent(SuccessSnackbarComponent, {
            data: {
              title: 'Yeah, great that you are attending this class!',
            },
            panelClass: 'sami-snackbar',
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 5000,
          });
          this.status = 'attendee';
        } else {
          this.snackbar.openFromComponent(SuccessSnackbarComponent, {
            data: {
              title: 'No problem, see you next time!',
            },
            panelClass: 'sami-snackbar',
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 5000,
          });
          this.status = 'canceled';
        }
      } else {
        this.snackbar.openFromComponent(ErrorSnackbarComponent, {
          data: {
            title: 'Oups, something did not work! Please try again later',
          },
          panelClass: 'sami-snackbar',
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 5000,
        });
      }
    } catch (error) {
      this.snackbar.openFromComponent(ErrorSnackbarComponent, {
        data: {
          title: 'Oups, something did not work! Please try again later',
        },
        panelClass: 'sami-snackbar',
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: 5000,
      });
    }
  }

  openIntercom() {

  }
}
