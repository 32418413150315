<ng-container *ngIf="menuService.menuItems$ | async as menuItems">
  <div [ngClass]="{ 'border-t border-t-cadetGrey-900': !first && backgroundColor !== 'white', 'border-t border-t-cadetGrey-100': !first && backgroundColor === 'white' }" class="py-1.5" *ngFor="let section of menuItems; let first = first">
    <div class="py-1 px-4" *ngIf="section.title">
      <span [ngClass]="{
        'text-cadetGrey-700': backgroundColor === 'white',
        'text-cadetGrey-800': backgroundColor !== 'white'
      }" class="tracking-wide font-semibold font-sans text-sm uppercase">
        {{ section.title }}
      </span>
    </div>

    <div class="flex flex-col justify-start" *ngFor="let menuItem of section.items">
      <div
        *ngIf="menuItem.active"
        [ngClass]="{
          'text-cadetGrey-800': backgroundColor === 'white',
          'text-cadetGrey-400': backgroundColor !== 'white'
        }"
        class="flex flex-row w-full items-center">
        <app-sidenav-button
          class="w-full"
          [currentSite]="currentSite"
          [menuItem]="menuItem"
          [backgroundColor]="backgroundColor"
          [isMobile]="isMobile"
          [lang]="lang"
          [isSmartphone]="isSmartphone"
          [user]="user"
          (toggleSidenav)="toggleSidenav.emit()"
        ></app-sidenav-button>
      </div>
    </div>
  </div>
</ng-container>